import React from 'react';
import ReactDOM from 'react-dom';

import { Link } from 'react-router-dom';
import { Accordion, Tab, Input, Card, Form, Select, Radio, Label, Container, Divider, Dropdown, Grid, Header, Image, List, Menu, Segment, Button, Icon, Table, Popup } from 'semantic-ui-react'

import { voiceOptions, calendarOptions, uptimeOptions, responseOptions, resolveOptions, popupMerchant, popupPersonal, popupCampaign, popupModule, sessionOptions, lockedOptions, resultOptions, limitOptions, delimiterOptions, cdrOptions, transactionOptions, factorOptions, sequenceOptions, periodOptions, fieldTargetOptions, fieldCdrOptions, fieldMdrOptions, fieldTrxOptions, gatewayOptions, dispatchOptions, ratioOptions, attemptOptions, achievedOptions, queuedOptions, calldelayOptions, addonsOptions, paramOptions, hourOptions, passwordOptions, accountOptions, processOptions, backupOptions, restoreOptions, housekeepingOptions, haOptions, watchdogOptions, disasterOptions, privilegeOptions, groupOptions, widgetOptions, yesnoOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'
import MenuTabular from '../common/MenuTabular'
import ModalContainer from '../common/ModalContainer'
import { imagePath, urlUpload } from '../common/_lib'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { ImageUploadField, AudioUploadField, InputField, LabelInputField, CheckboxField, SelectField, RadioField, TextAreaField, WysiwygField, NumberField, SliderField, LabelNumberField, LabelSelectField } from '../validation/componentrsui'
import { required, phoneNumber, number, minValue, email, phoneNumberID } from '../validation/validation'

import { connect } from "react-redux"
import { listParams } from "../../actions/halo/masterAction"
import { resetSetting, getSetting, updateSetting } from "../../actions/halo/settingAction"
import Omniwidget from '../omniwidget/Omniwidget';
import { rateOmniwidget } from '../../actions/flow/omniwidgetAction';

//CONNECT REDUX STORE
const selector = formValueSelector('SettingForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    settingStore: state.settingStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    listParams:(objParam) => dispatch(listParams(objParam)),

    resetSetting:(objParam) => dispatch(resetSetting(objParam)),
    getSetting:(objParam) => dispatch(getSetting(objParam)),
    updateSetting:(objParam) => dispatch(updateSetting(objParam)),
  }
};

const panesModule = (param)=>{
  if (param && param.flow) {
    return([
      {
        menuItem: { key: '1', icon: 'id badge outline', content: 'Visitor' },
        render: () => 
        <></>
      },
      {
        menuItem: { key: '2', icon: 'address card', content: 'Customer' },
        render: () => 
        <></>
      },
    ])

  } else {
    return([
      {
        menuItem: { key: '1', icon: 'closed captioning outline', content: 'Collection' },
        render: () => 
        <></>
      },
      {
        menuItem: { key: '2', icon: 'cubes', content: 'Restructuring' },
        render: () => 
        <></>
      },
      {
        menuItem: { key: '3', icon: 'add to cart', content: 'Telesales' },
        render: () => 
        <></>
      },
      {
        menuItem: { key: '4', icon: 'lab', content: 'Verification' },
        render: () => 
        <></>
      },
      // {
      //   menuItem: { key: '5', icon: 'stethoscope', content: 'Insurance' },
      //   render: () => 
      //   <></>
      // },
      // {
      //   menuItem: { key: '6', icon: 'hotel', content: 'Tourism' },
      //   render: () => 
      //   <></>
      // },
    ])

  }
};
const panesChannel = (param)=>{
  const channels= [];
  param.svcChannels && param.svcChannels.map(item=>{
    channels.push(item.value)
  })
  return([
    ...(param.group=='admin' || channels.indexOf('Voice')>=0) ? [{
      menuItem: { key: '0', icon: 'microphone', content: 'Voice' },
      render: () => 
      <></>
    }] : [],
    ...(param.group=='admin' || channels.indexOf('IVR')>=0) ? [{
      menuItem: { key: '1', icon: 'volume down', content: 'IVR' },
      render: () => 
      <></>
    }] : [],
    ...(param.group=='admin' || channels.indexOf('SMS')>=0) ? [{
      menuItem: { key: '2', icon: 'sticky note outline', content: 'SMS' },
      render: () => 
      <></>
    }] : [],
    ...(param.group=='admin' || channels.indexOf('Email')>=0) ? [{
      menuItem: { key: '3', icon: 'envelope outline', content: 'Email' },
      render: () => 
      <></>
    }] : [],
    ...(param.group=='admin' || channels.indexOf('Whatsapp')>=0) ? [{
      menuItem: { key: '7', icon: 'whatsapp', content: 'Whatsapp' },
      render: () => 
      <></>
    }] : [],
    // (param.group=='admin' || channels.indexOf('Telegram')>=0) && {
    //   menuItem: { key: '8', icon: 'telegram', content: 'Telegram' },
    //   render: () => 
    //   <></>
    // },
    ...(param.group=='admin' || channels.indexOf('Twitter')>=0) ? [{
      menuItem: { key: '4', icon: 'twitter', content: 'Twitter' },
      render: () => 
      <></>
    }] : [],
    ...(param.group=='admin' || channels.indexOf('Facebook')>=0) ? [{
      menuItem: { key: '5', icon: 'facebook', content: 'Facebook' },
      render: () => 
      <></>
    }] : [],
    ...(param.group=='admin' || channels.indexOf('Instagram')>=0) ? [{
      menuItem: { key: '6', icon: 'instagram', content: 'Instagram' },
      render: () => 
      <></>
    }] : [],
  ])
};
const panesReport = (param)=>{
  return([
    {
      menuItem: { key: '1', icon: 'file outline', content: 'Call Data Record' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '2', icon: 'file outline', content: 'Messaging Data Record' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '3', icon: 'file outline', content: 'Transaction Data Record' },
      render: () => 
      <></>
    },
  ])
};

class SettingForm extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      menu: 'halopagi', //security, halopagi, ovisone
      module: 'channel',
      showSetting: 'channel',

      tabChannel: 0,
      tabReport: 0,
      tabModule: 0,

      settingId: 1,
      
      showSegment: null,
      showHandling: null,

      mapHandlings: new Map(),
      mapSegments: new Map(),

      param: null,
      value: null,
    }
  }

  componentDidMount() {
    //console.log('!!! DID MOUNT PROPS: ', this.props)
    
    this.props.resetSetting();
    this.props.getSetting({settingId: this.props.settingId})

    this.props.listParams({clientId: this.props.settingId})
  }

  componentDidUpdate(prevProps) {
    const { settingId, mapHandlings, mapSegments }= this.state
    var isUpdated= false;

    const { data }= (this.props.settingStore.getSetting!=undefined ? this.props.settingStore.getSetting : {})
    if (settingId!=this.props.settingId) {
      mapHandlings.clear();
      mapSegments.clear();

      // console.log(data.settingId, this.props.settingId)
      this.props.getSetting({settingId: this.props.settingId})
      this.props.listParams({clientId: this.props.settingId})

      isUpdated= true;
    }

    if (data && data.settingId==this.props.settingId && data.ticketHandlings && data.ticketHandlings.length>0 && mapHandlings.size==0) {
      data.ticketHandlings.map((handling, i)=>{
        if (!mapHandlings.get(i)) {
          mapHandlings.set(i, new Map());
        }
        handling.params && handling.params.map(item=>{
          mapHandlings.get(i).set(item.key, item.value)
        })
      })
      isUpdated= true;
    }

    if (data && data.settingId==this.props.settingId && data.ticketSegments && data.ticketSegments.length>0 && mapSegments.size==0) {
      data.ticketSegments.map((segment, i)=>{
        if (!mapSegments.get(i)) {
          mapSegments.set(i, new Map());
        }
        segment.params && segment.params.map(item=>{
          mapSegments.get(i).set(item.key, item.value)
        })
      })
      isUpdated= true;
    }

    if (isUpdated) {
      this.setState({
        settingId: this.props.settingId,
        mapHandlings: mapHandlings,
        mapSegments: mapSegments,
      })
    }
    
  }

  resetForm() {
    const { reset, mode } = this.props
    reset();
  }

  onSubmit(values) {
    const { mode} = this.props
    const {mapHandlings, mapSegments}= this.state;
    const ticketHandlingParams= [], ticketSegmentParams= [];

    mapHandlings.forEach((value, key, map)=>{
      const params= []
      mapHandlings.get(key) && mapHandlings.get(key).forEach((value1, key1, map1)=>{
        params.push({
          key: key1,
          value: value1,
        })
      })

      ticketHandlingParams[key]= params;
    })

    mapSegments.forEach((value, key, map)=>{
      const params= []
      mapSegments.get(key) && mapSegments.get(key).forEach((value1, key1, map1)=>{
        params.push({
          key: key1,
          value: value1,
        })
      })

      ticketSegmentParams[key]= params;
    })

    if(mode==2) { //UPDATE
      this.props.updateSetting({...values, ...{
        ticketHandlingParams: ticketHandlingParams,
        ticketSegmentParams: ticketSegmentParams,
      }})
    }
  }

  setMode(v, e, d) {
    // console.log(v, e, d)
    if (v=='channel') {
      this.setState({tabChannel: d.panes[d.activeIndex].menuItem.key})
    } else if (v=='report') {
      this.setState({tabReport: d.activeIndex})
    } else {
      this.setState({tabModule: d.activeIndex})
    }
  }

  showMode(v) {
    this.setState({
      showSetting: this.state.showSetting!=v ? v : '',
    })
  }

  feedbackRatings(qty, kind) {
    var ratings= this.props.formValues!=undefined && this.props.formValues[kind +'Ratings']!=undefined ? this.props.formValues[kind +'Ratings'] : []
    var items= [];
    for (var i=0; i<qty; i++) {
      items[i]= {
        item: ratings[i]!=undefined ? ratings[i].item : null,
      };
    }

    return (
      <>
        {
          items.map((item, index)=>{
            return(
              <Table.Row key={index}>
                <Table.Cell>{(index+1)}.</Table.Cell>
                <Table.Cell>
                  <Form.Group widths='equal'>
                    <Field name={kind +'Topic_'+ (index+1)} component={InputField}
                    placeholder='Topic of minus feedback' />
                  </Form.Group>
                </Table.Cell>
                <Table.Cell>
                  <Form.Group widths='equal'>
                    <Field name={kind +'Descript_'+ (index+1)} component={InputField}
                    placeholder='Description of minus feedback' />
                  </Form.Group>
                </Table.Cell>
              </Table.Row>
            )
          })
        }
      </>
    )
  }

  progressStages(qty, kind) {
    var stages= this.props.formValues!=undefined && this.props.formValues[kind +'Stages']!=undefined ? this.props.formValues[kind +'Stages'] : []
    var items= [];
    for (var i=0; i<qty; i++) {
      items[i]= {
        item: stages[i]!=undefined ? stages[i].item : null,
      };
    }

    return (
      <>
        {
          items.map((item, index)=>{
            return(
              <Table.Row key={index}>
                <Table.Cell>{(index+1)}.</Table.Cell>
                <Table.Cell>
                  <Form.Group widths='equal'>
                    <Field name={kind +'Stage_'+ (index+1)} component={SelectField}
                    options={resultOptions(kind)}
                    placeholder='Remarks' />
                  </Form.Group>
                </Table.Cell>
                <Table.Cell>
                  <Form.Group widths='equal'>
                    <Field name={kind +'Progress_'+ (index+1)} component={SliderField}
                    placeholder='Completeness' />
                  </Form.Group>
                </Table.Cell>
              </Table.Row>
            )
          })
        }
      </>
    )
  }
  
  saveParams(id, kind) {    
    const {mapHandlings, mapSegments, param, value}= this.state;
    // alert(id +' - '+ param +' - '+ value +' - '+ kind)

    if (kind=='handling') {
      if (mapHandlings.get(id)) {
        mapHandlings.get(id).set(param, value); 
      } else {
        mapHandlings.set(id, new Map().set(param, value));
      }
      this.setState({
        mapHandlings: mapHandlings,
      })
    } else if (kind=='segment') {
      if (mapSegments.get(id)) {
        mapSegments.get(id).set(param, value); 
      } else {
        mapSegments.set(id, new Map().set(param, value));
      }
      this.setState({
        mapSegments: mapSegments,
      })
    }
    this.closeModal();
  }
  removeParams(id, param, kind) {
    const {mapHandlings, mapSegments}= this.state;

    if (kind=='handling') {
      if (mapHandlings.get(id)) {
        mapHandlings.get(id).delete(param); 
      }
      this.setState({
        mapHandlings: mapHandlings,
      })
    } else if (kind=='segment') {
      if (mapSegments.get(id)) {
        mapSegments.get(id).delete(param); 
      }
      this.setState({
        mapSegments: mapSegments,
      })
    }
  }

  incrRowQty(isIncrease, kind) {
    if (isIncrease) {
      if (this.props[kind]<25) {
        this.props.dispatch(change('SettingForm', kind, ((isNaN(this.props[kind]) ? 0 : parseInt(this.props[kind])) +1)))
      }
    } else {
      if (this.props[kind]>1) {
        this.props.dispatch(change('SettingForm', kind, ((isNaN(this.props[kind]) ? 0 : parseInt(this.props[kind])) -1)))
      }
    }
  }

  oauthClick(v) {
    // console.log(v)
    const { oauth }= this.state; 

    switch (v) {
      case 'gmail':
        // window.open('http://localhost:1115/oauth/api/v1/google?token='+ localStorage.getItem('tokenAuth'));
        window.open('https://crm.halopagi.com/proxy/oauth/api/v1/google?token='+ localStorage.getItem('tokenAuth'));
      break;
      case 'twitter':
        // window.open('http://localhost:1115/oauth/api/v1/twitter?token='+ localStorage.getItem('tokenAuth'));
        window.open('https://crm.halopagi.com/proxy/oauth/api/v1/twitter?token='+ localStorage.getItem('tokenAuth'));
      break;
      case 'facebook':
        // window.open('http://localhost:1115/oauth/api/v1/facebook?token='+ localStorage.getItem('tokenAuth'));
        window.open('https://crm.halopagi.com/proxy/oauth/api/v1/facebook?token='+ localStorage.getItem('tokenAuth'));
      break;
      case 'instagram':
        // window.open('http://localhost:1115/oauth/api/v1/instagram?token='+ localStorage.getItem('tokenAuth'));
        window.open('https://crm.halopagi.com/proxy/oauth/api/v1/instagram?token='+ localStorage.getItem('tokenAuth'));
      break;
      default:
    }
  }

  setModule(v) {
    this.setState({
      module: v,
      showSetting: (v=='channel' && 'channel') || (v=='flow' && 'trunk') || (v=='talk' && 'trunk') || (v=='ring' && 'trunk') || (v=='api' && 'api') || '',
      tabChannel: 0,
      tabReport: 0,
      tabModule: 0,
    })
  }
  setMenu(v) {
    this.setState({
      menu: v,
      module: (v=='security' && 'security') || (v=='halopagi' && 'channel') || (v=='ovisone' && 'collection') || '',
      showSetting: (v=='security' && 'security') || (v=='halopagi' && 'channel') || (v=='ovisone' && 'performance') || '',
    })
  }

  openModal(i, v) {
    if (v=='handling') {
      this.setState({
        showHandling: i,
      })
    } else if (v=='segment') {
      this.setState({
        showSegment: i,
      })
    }
  }

  closeModal() {
    this.setState({
      showHandling: null,
      showSegment: null,
    })
  }

  onChangeInput(e, v) {
    // console.log(e, v.value)
    this.setState({
      param: v.value,
    })
  }

  onChangeSelect(e, v) {
    // console.log(e, v)
    this.setState({
      value: v.value,
    })
  }

  render() {
    const { menu, tabChannel, tabReport, tabModule, showSetting, module }= this.state
    const { error, handleSubmit, pristine, reset, submitting, group, mode, screenWidth, screenHeight }=this.props
    const { data }= (this.props.settingStore.getSetting!=undefined ? this.props.settingStore.getSetting : {})
    const { progress, code, text }= (this.props.settingStore.updateSetting!=undefined ? this.props.settingStore.updateSetting : {})
    
    const { svcCampaigns, svcRatios, svcChannels }= this.props.masterStore.listParams && this.props.masterStore.listParams.data ? this.props.masterStore.listParams.data : {}

    return(<>
      <div style={{marginTop: '-1em'}}>
        <div>
          <Menu compact>
            <Popup content='Communication Channel Preferences' position='bottom left' inverted
            trigger={
              <Menu.Item as='a' onClick={this.setModule.bind(this, 'channel')} style={module=='channel' ? {background: '#fff', fontWeight: 'bold'} : {}} >
                <Icon color={(module=='channel' && 'red') || null} name='microphone' /> Channel
              </Menu.Item>
            } />
            <Popup content='Outbound Manual and Predictive Dialer.' position='bottom left' inverted
            trigger={
              <Menu.Item as='a' onClick={this.setModule.bind(this, 'stream')} style={module=='stream' ? {background: '#fff', fontWeight: 'bold'} : {}} >
                <Icon color={(module=='stream' && 'red') || null} name='arrow circle left' /> Stream!
              </Menu.Item>
            } />
            <Popup content='Inbound and IVR Menu / Automation.' position='bottom left' inverted
            trigger={
              <Menu.Item as='a' onClick={this.setModule.bind(this, 'flow')} style={module=='flow' ? {background: '#fff', fontWeight: 'bold'} : {}} >
                <Icon color={(module=='flow' && 'red') || null} name='arrow circle right' /> Flow!
              </Menu.Item>
            } />
            <Popup content='In-App Call Audio / Video Conference.' position='bottom left' inverted
            trigger={
              <Menu.Item as='a' onClick={this.setModule.bind(this, 'talk')} style={module=='talk' ? {background: '#fff', fontWeight: 'bold'} : {}} >
                <Icon color={(module=='talk' && 'red') || null} name='mobile' /> Talk!
              </Menu.Item>
            } />
            <Popup content='Office / Extension Phone.' position='bottom left' inverted
            trigger={
              <Menu.Item as='a' onClick={this.setModule.bind(this, 'ring')} style={module=='ring' ? {background: '#fff', fontWeight: 'bold'} : {}} >
                <Icon color={(module=='ring' && 'red') || null} name='text telephone' /> Ring!
              </Menu.Item>
            } />
            <Popup content='Api and Webhook.' position='bottom left' inverted
            trigger={
              <Menu.Item as='a' onClick={this.setModule.bind(this, 'api')} style={module=='api' ? {background: '#fff', fontWeight: 'bold'} : {}} >
                <Icon color={(module=='api' && 'red') || null} name='rocket' /> API
              </Menu.Item>
            } />
            <Popup content='Security and Privilege.' position='bottom left' inverted
            trigger={
              <Menu.Item as='a' onClick={this.setModule.bind(this, 'security')} style={module=='security' ? {background: '#fff', fontWeight: 'bold'} : {}} >
                <Icon color={(module=='security' && 'red') || null} name='shield' /> Security
              </Menu.Item>
            } />
          </Menu>
        </div>

        <Divider />
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          {/* <Segment>
            <Form.Group widths='16'>
              <Field name='appName' width={8} component={InputField}
              readOnly= {group!='admin'}
              label='Application'
              placeholder='Application' />
              <Field name='appVersion' width={4} component={InputField}
              readOnly= {group!='admin'}
              label='Version'
              placeholder='Version' />
              <Field name='appRelease' width={4} component={InputField}
              readOnly= {group!='admin'}
              label='Release'
              placeholder='Release' />
            </Form.Group>
          </Segment> */}

          {module=='channel' && <>
            <div style={{display: 'flex', marginBottom: '1em'}}>
              <div style={{flex: '1'}}>
                <Header as='h4' style={{fontWeight: 'bold', marginTop: '1em'}} color={(showSetting=='channel' && 'blue') || null}>
                  <Header.Content>
                    Channel / Media Delivery
                    <Header.Subheader>Channel stream configuration</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{marginTop: '1.5em', marginLeft: '1em'}}>
                <Icon name={(showSetting=='channel' && 'toggle on') || 'toggle off'} color={(showSetting=='channel' && 'blue') || 'grey'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.showMode.bind(this, 'channel')} />
              </div>
            </div>
            <Divider />
            {showSetting=='channel' && <>
              <MenuTabular panes={panesChannel({svcChannels: svcChannels, group: group})} style={{width: '100%', marginBottom: '-1.2em'  }} renderActiveOnly={true} onTabChange={this.setMode.bind(this, 'channel')} />
              { tabChannel==0 && <>
                {group=='admin' && <>
                  {/* <Segment>
                    <Header as='h5'>
                      Stasis App (Media Server Identification / Asterisk Only)
                    </Header>
                    <Form.Group widths='equal'>
                      <Field name='voiceStasisApp' component={InputField}
                      label='Stasis Application'
                      placeholder='Stasis Application' />
                      <Field name='voiceStasisArgs' component={InputField}
                      label='Stasis Arguments'
                      placeholder='Stasis Arguments' />
                      <Field name='voiceTrunk' component={InputField}
                      label='Trunk ID'
                      placeholder='Trunk ID' />
                      <Field name='voiceTrunkBackup' component={InputField}
                      label='Trunk ID Backup'
                      placeholder='Trunk ID Backup' />
                    </Form.Group>
                    <Form.Group widths='16'>
                      <Field name='voiceHost' component={InputField}
                      width={4}
                      label='Voice Host'
                      placeholder='Voice Host' />
                      <Field name='voicePort' component={InputField}
                      width={4}
                      label='Voice Port'
                      placeholder='Voice Port' />
                      <Field name='voiceUser' component={InputField}
                      width={4}
                      label='Voice Account'
                      placeholder='Voice Account' />
                      <Field name='voicePasswd' component={InputField}
                      width={4}
                      label='Voice Password'
                      placeholder='Voice Password' />
                    </Form.Group>
                  </Segment> */}

                  <Segment>
                    <Header as='h5'>
                      Voice Gateway
                    </Header>
                    <Form.Group widths='16'>
                      <Field name='voiceParking' component={InputField}
                      width={4}
                      label='Parking'
                      placeholder='Parking' />
                      <Field name='voiceActiveGateway' component={SelectField}
                      width={4}
                      options={[
                        {key: '1', value: 'Primary', text: 'Primary'},
                        {key: '2', value: 'Backup', text: 'Backup'},
                      ]}
                      label='Active Gateway'
                      placeholder='Active Gateway' />
                    </Form.Group>
                    <Form.Group widths='16'>
                      <Field name='voiceGateway' component={InputField}
                      width={4}
                      label='Gateway / Primary'
                      placeholder='Gateway' />
                      <Field name='voiceOriginating' component={InputField}
                      width={4}
                      label='Originating / Primary'
                      placeholder='Originating' />
                      <Field name='voiceDialPrefix' component={InputField}
                      width={4}
                      label={<Popup header='Security !' inverted content='Prefix must have 5 chars length. More or less will be block by Security Addons Service.' trigger={<span>Dial Prefix / Primary <b>!</b></span>} />}
                      placeholder='Dial Custom Prefix' />
                      <Field name='voiceTelcoPrefix' component={InputField}
                      width={4}
                      label='Telco Prefix / Primary !'
                      placeholder='Telco Custom Prefix' />
                    </Form.Group>
                    <Form.Group widths='16'>
                      <Field name='voiceGatewayBackup' component={InputField}
                      width={4}
                      label='Gateway / Backup'
                      placeholder='Gateway' />
                      <Field name='voiceOriginatingBackup' component={InputField}
                      width={4}
                      label='Originating / Backup'
                      placeholder='Originating' />
                      <Field name='voiceDialPrefixBackup' component={InputField}
                      width={4}
                      label={<Popup header='Security !' inverted content='Prefix must have 5 chars length. More or less will be block by Security Addons Service.' trigger={<span>Dial Prefix / Backup <b>!</b></span>} />}
                      placeholder='Dial Custom Prefix' />
                      <Field name='voiceTelcoPrefixBackup' component={InputField}
                      width={4}
                      label='Telco Prefix / Backup !'
                      placeholder='Telco Custom Prefix' />
                    </Form.Group>
                  </Segment>

                  {/* <Segment attached='top'>
                    <Header as='h5'>
                      Agent's Protocol
                    </Header>
                    <Form.Group widths='equal'>
                      <Field name='voiceProtoInternal' component={InputField}
                      label='Softphone Protocol'
                      placeholder='SIP' />
                      <Field name='voicePriorityInternal' component={LabelInputField}
                      type='number'
                      label='1 - 10'
                      caption='Priority'
                      placeholder='Priority' />
                      <Field name='voiceCallerInternal' component={InputField}
                      label='Caller ID'
                      placeholder='Caller ID' />
                      <Field name='voiceTimeoutInternal' component={LabelInputField}
                      type='number'
                      label='Sec'
                      caption='Ringing Timeout'
                      placeholder='Ringing Timeout' />
                    </Form.Group>
                  </Segment> */}
                  
                  <Segment attached='bottom'>
                    <Header as='h5'>
                      Provider's Protocol
                    </Header>
                    <Form.Group widths='equal'>
                      <Field name='voiceProtoExternal' component={InputField}
                      label='External Protocol'
                      placeholder='SIP' />
                      <Field name='voicePriorityExternal' component={LabelInputField}
                      type='number'
                      label='1 - 10'
                      caption='Priority'
                      placeholder='Priority' />
                      <Field name='voiceCallerExternal' component={InputField}
                      label='Caller ID'
                      placeholder='Caller ID' />
                      <Field name='voiceTimeoutExternal' component={LabelInputField}
                      type='number'
                      label='Sec'
                      caption='Ringing Timeout'
                      placeholder='Ringing Timeout' />
                    </Form.Group>
                  </Segment>

                  <Segment>
                    <Header as='h5'>
                      <Header.Content>
                        Call Behaviour
                      </Header.Content>
                    </Header>
                    <Form.Group widths='equal'>
                      <Field name='voiceAttemptMax' component={SelectField}
                      options= {attemptOptions}
                      label='Max Call Attempt'
                      placeholder='Max Call Attempt' />
                      <Field name='voiceAttemptDelay' component={SelectField}
                      options={calldelayOptions}
                      label='Call Delay Attempt'
                      placeholder='Call Delay Attempt' />
                      <Field name='voiceCallRate' component={SelectField}
                      // type='number'
                      options={data && data.userId==0 ? ratioOptions : svcRatios}
                      // caption='Call Rate'
                      label='Default Call Ratio'
                      placeholder='Default Call Ratio' />
                      {/* <Field name='voiceConcurrentCall' component={LabelInputField}
                      type='number'
                      caption='Concurrent Call'
                      label='Call'
                      placeholder='Concurrent Call' /> */}
                      <Field name='voiceCallInterval' component={LabelInputField}
                      // readOnly
                      type='number'
                      label='Sec'
                      caption='Blast Dialer Interval'
                      placeholder='Call Blast Interval' />
                    </Form.Group>
                    <Form.Group widths='16'>
                      {/* <Field name='voiceDispatchMethod' component={SelectField}
                      width={4}
                      options={dispatchOptions('agent')}
                      label='Dispatch Method'
                      placeholder='Dispatch Method' /> */}
                      <Field name='voiceAgentDelay' component={LabelInputField}
                      width={4}
                      type='number'
                      label='Sec'
                      caption='Agent Dispatch Interval'
                      placeholder='Call Dispatch Interval' />
                      <Field name='voiceMaxEngaged' component={SelectField}
                      width={4}
                      options= {limitOptions}
                      label='Max. Duration'
                      placeholder='Call duration limit' />
                      <Field name='voiceTimeoutQueue' component={SelectField}
                      width={4}
                      options= {queuedOptions}
                      label='Queueing Timeout'
                      placeholder='Waiting Queue Timeout' />
                      <Field name='achievedLockedDay' component={SelectField}
                      width={4}
                      options= {achievedOptions}
                      type='number'
                      label='Achieved Locked Day'
                      placeholder='Valid / Commitment Locked Day Period' />
                    </Form.Group>
                  </Segment>
                </>}
                {group!='admin' && <>
                  <Segment>
                    <Header as='h5'>
                      <Header.Content>
                        Call Behaviour
                      </Header.Content>
                    </Header>
                    <Form.Group widths='16'>
                      <Field name='voiceCallRate' component={SelectField}
                      width={4}
                      options={svcRatios}
                      label='Call Ratio'
                      placeholder='Default Call Ratio' />
                      <Field name='voiceAttemptMax' component={SelectField}
                      width={4}
                      options= {attemptOptions}
                      type='number'
                      label='Max. Attempt'
                      placeholder='Max Call Attempt' />
                      <Field name='voiceTimeoutQueue' component={SelectField}
                      width={4}
                      options= {queuedOptions}
                      type='number'
                      label='Queueing Period'
                      placeholder='Waiting Queue' />
                      <Field name='achievedLockedDay' component={SelectField}
                      width={4}
                      options= {achievedOptions}
                      type='number'
                      label='Locked Period'
                      placeholder='Locked Days' />
                    </Form.Group>
                  </Segment>
                </>}
                <Segment attached='bottom'>
                  <Header as='h5'>
                    <Header.Content>
                      Call Experience
                    </Header.Content>
                  </Header>
                  <Form.Group widths='16'>
                    <Field name='voiceRecording' component={LabelSelectField}
                    options={voiceOptions('recording')}
                    width={4}
                    label='MP3'
                    caption='Recording Format'
                    placeholder='Stereo format' />
                    <Field name='voiceAddons' component={LabelSelectField}
                    multiple
                    options={addonsOptions}
                    width={12}
                    label='Modules'
                    caption='Custom Addons Module'
                    placeholder='Additional custom addons service' />
                  </Form.Group>
                  <Form.Group widths='16'>
                    {/* <Field name='voiceMaxEngaged' component={SelectField}
                    width={4}
                    options= {limitOptions}
                    label='Max. Duration'
                    placeholder='Call duration limit' /> */}
                    <Field name='voicePrioritySequences' component={SelectField}
                    width={16}
                    options={sequenceOptions}
                    multiple
                    label='Call Sequence Order'
                    placeholder='Call Sequence Order' />
                  </Form.Group>
                </Segment>

                {group=='admin' && <>
                  <Segment>
                    <Form.Group widths='16'>
                      <Field name='voiceOriginatings' component={TextAreaField}
                      width={16}
                      label='Originating Phone Numbers'
                      placeholder='Allocated originating numbers / caller ID, delimited by ",": 628131000901, 628131000902, . . .' />
                    </Form.Group>
                  </Segment>
                  <Segment>
                    <Form.Group widths='16'>
                      <Field name='voiceBackgroundMusic' component={AudioUploadField}
                      width={16}
                      label='On Hold / Waiting Background Music'
                      placeholder='Background Music' />
                    </Form.Group>
                  </Segment>
                </>}
              </>}

              { tabChannel==1 && <>
              {group=='admin' && <>
              <Segment>
                <Header as='h5'>
                  <Header.Content>
                    IVR Behaviour
                  </Header.Content>
                </Header>
                <Form.Group widths='equal'>
                  <Field name='ivrAttemptMax' component={SelectField}
                  options={attemptOptions}
                  type='number'
                  label='Max Call Attempt'
                  placeholder='Max Call Attempt' />
                  <Field name='ivrAttemptDelay' component={SelectField}
                  options={calldelayOptions}
                  label='Call Delay Attempt'
                  placeholder='Call Delay Attempt' />
                  <Field name='ivrCallRate' component={SelectField}
                  // type='number'
                  options={data && data.userId==0 ? ratioOptions : svcRatios}
                  // caption='Call Rate'
                  label='Default Call Ratio'
                  placeholder='Default Call Ratio' />
                  <Field name='ivrConcurrentCall' component={LabelInputField}
                  type='number'
                  caption='Concurrent Call'
                  label='Call'
                  placeholder='Concurrent Call' />
                </Form.Group>

                <Divider />
                <Form.Group widths='16'>
                  <Field name='ivrMaxEngaged' component={SelectField}
                  width={4}
                  options= {limitOptions}
                  label='Max. Call Duration'
                  placeholder='Call duration limit' />
                  <Field name='ivrPrioritySequences' component={SelectField}
                  width={12}
                  options={sequenceOptions}
                  multiple
                  label='Call Sequence Order'
                  placeholder='Call Sequence Order' />
                </Form.Group>
              </Segment></>}
              {group!='admin' && <>
              <Segment>
                <Header as='h5'>
                  <Header.Content>
                    IVR Behaviour
                  </Header.Content>
                </Header>
                <Form.Group widths='16'>
                  <Field name='ivrCallRate' component={SelectField}
                  width={4}
                  options={svcRatios}
                  label='Call Ratio'
                  placeholder='Default Call Ratio' />
                  <Field name='ivrAttemptMax' component={SelectField}
                  width={4}
                  options= {attemptOptions}
                  type='number'
                  label='Max. Attempt'
                  placeholder='Max Call Attempt' />
                </Form.Group>
                <Form.Group widths='16'>
                  <Field name='ivrMaxEngaged' component={SelectField}
                  width={4}
                  options= {limitOptions}
                  label='Max. Duration'
                  placeholder='Duration Limit' />
                  <Field name='ivrPrioritySequences' component={SelectField}
                  width={12}
                  options={sequenceOptions}
                  multiple
                  label='Call Sequence Order'
                  placeholder='Call Sequence Order' />
                </Form.Group>
              </Segment></>}
              </>}

              { tabChannel==2 && <>
                {group=='admin' && <>
                <Segment>
                  <Header as='h5'>
                    SMS Delivery Method
                  </Header>
                  <Form.Group widths='16'>
                    <Field name='smsGateway' component={SelectField}
                    width={8}
                    options={gatewayOptions('sms')}
                    label='SMS Gateway'
                    placeholder='Gateway' />
                    <Field name='smsCredential' component={InputField}
                    width={8}
                    label='Credential'
                    placeholder='Credential / Secret' />
                  </Form.Group>
                  
                  <Divider hidden />
                  {this.props.smsGateway=='Mobile' && <>
                  <Header as='h5' style={{margin: '0'}}>
                    <Header.Content>
                      Please download our mobile app to set your device as personal sms gateway. It will charge you based on sms rate according to your providers rate, you can set limit or maximum number of sms will be sent, be wise !
                    </Header.Content>
                  </Header>
                  </>}
                  {this.props.smsGateway=='Gateway' && <>
                  <Form.Group widths='equal'>
                    <Field name='smsEndpoint' component={InputField}
                    label='Ext. Gateway Endpoint API'
                    placeholder='Endpoint API' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='smsWebhook' component={InputField}
                    label='Ext. Gateway Webhook / Status API'
                    placeholder='Webhook / Status API' />
                  </Form.Group>
                  </>}
                </Segment>
                </>}
                {group!='admin' && <>
                <Segment style={{paddingBottom: '7em'}}>
                  <Header as='h5'>
                    <Icon name='warning sign' />
                    <Header.Content>
                      Your configuration is handled by system.
                      <Header.Subheader>Currently there is no custom configuration required for SMS delivery.</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Segment>
                </>}
              </>}

              {tabChannel==3 && <>
                {group=='admin' && <>
                  <Segment>
                    <Grid columns={1}>
                      <Grid.Column>
                        <Header as='h5'>
                          <Header.Content>
                            Email Delivery Method
                          </Header.Content>
                        </Header>
                        <Form.Group widths='16'>
                          <Field name='mailGateway' component={SelectField}
                          width={8}
                          options={gatewayOptions('mail')}
                          label='Email Gateway'
                          placeholder='Email Gateway' />
                          {(this.props.mailGateway=='smtp' || this.props.mailGateway=='postal') && 
                          <Field name='mailSender' component={InputField}
                          width={8}
                          label='Sender Address'
                          placeholder='Sender Address Full Name <sender@address.com>' />
                          }
                        </Form.Group>
                        {this.props.mailGateway=='smtp' && 
                          <Form.Group widths='16'>
                            <Field name='smtpUser' component={InputField}
                            width={4}
                            label='User Name'
                            placeholder='User' />
                            <Field name='smtpPasswd' component={InputField}
                            width={4}
                            label='Password'
                            placeholder='Password' />
                            <Field name='smtpHost' component={InputField}
                            width={6}
                            label='Hostname'
                            placeholder='Hostname' />
                            <Field name='smtpPort' component={InputField}
                            width={2}
                            label='Port'
                            placeholder='Port' />
                          </Form.Group>
                        }
                        {this.props.mailGateway=='gmail' && <>
                          <Header as='h5'>
                            <Header.Content>
                              Gmail will be used as email platform for sending and receiving, through open authentication, access token is required to be refreshed in one hour each or it would be expired.
                            </Header.Content>
                          </Header>
                        </>}
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </>}
                {group!='admin' && <>
                  {(this.props.mailGateway=='gmail') &&
                    <Segment>
                      <Grid columns={1}>
                        <Grid.Column>
                          <Header as='h4' style={{fontWeight: 'bold'}}>
                            <Header.Content>
                              Click to integrate to your 
                              <Button type='button' color='red' style={{marginLeft: '1em', textAlign: 'left'}} onClick={this.oauthClick.bind(this, 'gmail')}>
                                <Icon name='google' /> Google
                              </Button>
                              <Header.Subheader>Send your email througn gmail as Email Platform</Header.Subheader>
                            </Header.Content>
                          </Header>
                          <Header as='h5'>
                            <Header.Content>
                              Gmail will be used as email platform for sending and receiving, through open authentication, access token is required to be refreshed in one hour each or it would be expired.
                            </Header.Content>
                          </Header>
                        </Grid.Column>
                      </Grid>
                    </Segment>
                  }
                  {(!this.props.mailGateway || this.props.mailGateway!='gmail') && 
                    <Segment style={{paddingBottom: '7em'}}>
                      <Header as='h5'>
                        <Icon name='warning sign' />
                        <Header.Content>
                          Your configuration is handled by system.
                          <Header.Subheader>Currently there is no custom configuration required for Email delivery.</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Segment>
                  }
                </>}
              
              {false &&
              <Segment>
                <Grid columns={2}>
                  <Grid.Column style={{borderRight: '1px solid #ccc'}}>
                    <Button type='button' color='blue' style={{textAlign: 'left'}} onClick={this.oauthClick.bind(this, 'imap')}>
                      <Header as='h4' inverted>
                        <Icon name='server' />
                        <Header.Content>
                          IMAP Server
                          <Header.Subheader>Corporate Mail Server Platform</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Button>

                    <Header as='h5'>
                      <Header.Content>
                        It is persistent connection through IMAP Server for sending and receiving email, imap service provider is available for common public email or private corporate email server.
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                  <Grid.Column style={{borderRight: '1px solid #fff'}}>
                    
                  </Grid.Column>
                </Grid>
              </Segment>}
              </>}

              { tabChannel==4 && <>
              <Segment>
                <Grid columns={1}>
                  <Grid.Column>
                    <Header as='h4' style={{fontWeight: 'bold'}}>
                      <Header.Content>
                        Click to integrate to your
                        <Button type='button' color='teal' style={{marginLeft: '1em', textAlign: 'left'}} onClick={this.oauthClick.bind(this, 'twitter')} icon>
                          <Icon name='twitter' /> Twitter
                        </Button>
                        <Header.Subheader>Twitter Integrated and Interactive Stream Channel</Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Header as='h5'>
                      <Header.Content>
                        Manage your relationship through twitter channel, direct message and post your twit information from single board interactive messaging platform.
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                </Grid>
              </Segment>
              </>}
              { tabChannel==5 && <>
              <Segment>
                <Grid columns={1}>
                  <Grid.Column>
                    <Header as='h4' style={{fontWeight: 'bold'}}>
                      <Header.Content>
                        Click to integrate to your
                        <Button type='button' color='blue' style={{marginLeft: '1em', textAlign: 'left'}} onClick={this.oauthClick.bind(this, 'facebook')} icon>
                          <Icon name='facebook' /> Facebook
                        </Button>
                        <Header.Subheader>Facebook Integrated and Interactive Stream Channel</Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Header as='h5'>
                      <Header.Content>
                        Manage your relationship through facebook business channel, direct message and post your content from single board interactive messaging platform.
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                </Grid>
              </Segment>
              </>}
              { tabChannel==6 && <>
              <Segment>
                <Grid columns={1}>
                  <Grid.Column>
                    <Header as='h4' style={{fontWeight: 'bold'}}>
                      <Header.Content>
                        Click to integrate to your
                        <Button type='button' color='orange' style={{marginLeft: '1em', textAlign: 'left'}} onClick={this.oauthClick.bind(this, 'instagram')} icon>
                          <Icon name='instagram' /> Instagram
                        </Button>
                        <Header.Subheader>Instagram Integrated and Interactive Stream Channel</Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Header as='h5'>
                      <Header.Content>
                        Manage your relationship through instagram business channel, comment and post your content from single board interactive messaging platform.
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                </Grid>
              </Segment>
              </>}

              { tabChannel==7 && <>
              {group=='admin' && <>
              <Segment>
                <Header as='h5'>
                  Gateway Configuration
                </Header>
                <Form.Group widths='16'>
                  <Field name='waGateway' component={SelectField}
                  width={8}
                  options={gatewayOptions('wa')}
                  label='WA Gateway'
                  placeholder='Gateway' />
                  <Field name='waCredential' component={InputField}
                  width={8}
                  label='WA Credential'
                  placeholder='Credential / Secret' />
                </Form.Group>
                {this.props.waGateway=='Mobile' && <>
                  <Form.Group widths='equal'>
                    <Field name='waAttemptMax' component={LabelInputField}
                    type= 'number'
                    label='Sent'
                    caption='Sent Attempt'
                    placeholder='Max. attempt for sending message' />
                    <Field name='waSenderDelay' component={LabelInputField}
                    type='number'
                    label='Sec'
                    caption='Sender Delay'
                    placeholder='Delay interval foreach senders between messages' />
                    <Field name='waTargetInterval' component={LabelInputField}
                    type='number'
                    label='Day'
                    caption='Target Interval'
                    placeholder='Unique Target Interval' />
                    {/* <Field name='waSentInterval' component={LabelInputField}
                    type='number'
                    label='Sec'
                    caption='Sending Interval'
                    placeholder='Message Sending Interval' /> */}
                    <Field name='waClearInterval' component={LabelInputField}
                    type='number'
                    label='Sec'
                    caption='Clearing Interval'
                    placeholder='Clearing Interval' />
                  </Form.Group>

                  <Form.Group widths='equal' style={{marginTop: '3em'}}>
                    <Field name='waMobile_1' component={InputField}
                    label='Mobile Sender #1'
                    validate= {[phoneNumberID]}
                    placeholder='Mobile number as sender' />
                    <Field name='waMobile_2' component={InputField}
                    label='Mobile Sender #2'
                    validate= {[phoneNumberID]}
                    placeholder='[Optional] Mobile number as sender' />
                    <Field name='waMobile_3' component={InputField}
                    label='Mobile Sender #3'
                    validate= {[phoneNumberID]}
                    placeholder='[Optional] Mobile number as sender' />
                    <Field name='waMobile_4' component={InputField}
                    label='Mobile Sender #4'
                    validate= {[phoneNumberID]}
                    placeholder='[Optional] Mobile number as sender' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='waMobile_5' component={InputField}
                    label='Mobile Sender #5'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_6' component={InputField}
                    label='Mobile Sender #6'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_7' component={InputField}
                    label='Mobile Sender #7'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_8' component={InputField}
                    label='Mobile Sender #8'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='waMobile_9' component={InputField}
                    label='Mobile Sender #9'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_10' component={InputField}
                    label='Mobile Sender #10'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_11' component={InputField}
                    label='Mobile Sender #11'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_12' component={InputField}
                    label='Mobile Sender #12'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='waMobile_13' component={InputField}
                    label='Mobile Sender #13'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_14' component={InputField}
                    label='Mobile Sender #14'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_15' component={InputField}
                    label='Mobile Sender #15'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_16' component={InputField}
                    label='Mobile Sender #16'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='waMobile_17' component={InputField}
                    label='Mobile Sender #17'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_18' component={InputField}
                    label='Mobile Sender #18'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_19' component={InputField}
                    label='Mobile Sender #19'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_20' component={InputField}
                    label='Mobile Sender #20'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                  </Form.Group>

                  <Divider hidden />
                  <Form.Group widths='equal'>
                    <Field name='waMobileFarmServer' component={TextAreaField}
                    label='Mobile Sender Farm Server (6281390001001, 6281390001002, ...)'
                    placeholder='[Optional] Mobile number as sender from Halo Pagi! farm server, delimited by comma' />
                  </Form.Group>
                </>}
                {this.props.waGateway=='Gateway' && 
                <Form.Group widths='equal'>
                  <Field name='waEndpoint' component={InputField}
                  label='Provider Endpoint'
                  placeholder='Provider Endpoint' />
                  <Field name='waWebhook' component={InputField}
                  label='Webhook Endpoint'
                  placeholder='Webhook Endpoint' />
                </Form.Group>}
              </Segment>
              </>}
              {group!='admin' && <>
              <Segment style={{paddingBottom: '7em'}}>
                <Header as='h5'>
                  <Icon name='warning sign' />
                  <Header.Content>
                    Your configuration is handled by system.
                    <Header.Subheader>Currently there is no custom configuration required for Whatsapp delivery.</Header.Subheader>
                  </Header.Content>
                </Header>
              </Segment>
              </>}
              </>}

              { tabChannel==8 && <>
              <Segment>
                <Header as='h5'>
                  TELEGRAM GATEWAY
                </Header>
              </Segment>

              <Segment>
                <Header as='h5'>
                  TELEGRAM BEHAVIOUR
                </Header>
              </Segment>
            </>}
            </>}
          </>}

          {module=='stream' && <>
            {group=='admin' &&
              <Segment>
                <Header as='h5'>
                  <Header.Content>
                    Agent Behaviour
                  </Header.Content>
                </Header>
                <Form.Group widths='16'>
                  <Field name='agentDispatchOutbound' component={SelectField}
                  width={4} 
                  options= {dispatchOptions('agent')}
                  label='Dispatch Method'
                  placeholder='Agent Dispatch Method' />
                </Form.Group>
                <Form.Group widths='16'>
                  <Field name='agentSessionMode' width={4} component={SelectField}
                  options= {sessionOptions()}
                  label='Agent Session Mode'
                  placeholder='Starting sesion mode' />
                  <Field name='agentSessionCountdown' width={4} component={InputField}
                  type= 'number'
                  label='Countdown on Starting (sec)'
                  placeholder='Duration or countdown timer before session starts' />
                  <Field name='agentLockedMode' width={4} component={SelectField}
                  options= {lockedOptions}
                  label='Agent Locked Mode'
                  placeholder='Features access mode' />
                  <Field name='agentLockedCountdown' width={4} component={InputField}
                  type= 'number'
                  label='Countdown on Locked (sec)'
                  placeholder='Duration or countdown timer as locked allowed' />
                </Form.Group>
              </Segment>
            }

            {(group=='admin' || true ) && <>
              <div style={{display: 'flex', marginBottom: '1em'}}>
                <div style={{flex: '1'}}>
                  <Header as='h4' style={{fontWeight: 'bold', marginTop: '1em'}} color={showSetting=='module' ? 'blue' : null}>
                    <Header.Content>
                      Module Configuration
                      <Header.Subheader>Campaign's remark and feedback progress</Header.Subheader>
                    </Header.Content>
                  </Header>    
                </div>
                <div style={{marginTop: '1.5em', marginLeft: '1em'}}>
                  <Icon name={(showSetting=='module' && 'toggle on') || 'toggle off'} color={(showSetting=='module' && 'blue') || 'grey'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.showMode.bind(this, 'module')} />
                </div>
              </div>
              <Divider />
              {showSetting=='module' && <>
                <MenuTabular panes={panesModule({})} style={{width: '100%', marginBottom: '-1.2em'  }} renderActiveOnly={true} onTabChange={this.setMode.bind(this, 'module')} />
                { data && tabModule==0 && <>
                <Segment>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: '1'}}>
                      <Header as='h5'>
                        Customer Remarks
                        <Header.Subheader>Feedback or remarks which customer confirmed on engagement, 100% completeness will not reach customer.</Header.Subheader>
                      </Header>
                    </div>
                    <div>
                      <Button type='button' icon='minus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this,false, 'collectionStageQty')} />
                    </div>
                    <div>
                        <Field name='collectionStageQty' component={InputField} size='small' style={{height: '2.2em', width: '3em', borderRadius: '0'}} />
                    </div>
                    <div>
                      <Button type='button' icon='plus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this,true, 'collectionStageQty')} />
                    </div>
                  </div>

                  <Table unstackable selectable columns='16' celled striped basic='very'>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell width='6'>
                          <Header as='h5' color='blue'>
                            REMARKS
                          </Header>
                        </Table.HeaderCell>
                        <Table.HeaderCell width='10'>
                          <Header as='h5' color='blue'>
                            COMPLETENESS
                          </Header>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        this.progressStages(this.props.collectionStageQty, 'collection')
                      }
                    </Table.Body>
                  </Table>
                </Segment></>}
                { data && tabModule==1 && <>
                <Segment>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: '1'}}>
                      <Header as='h5'>
                        Customer Remarks
                        <Header.Subheader>Feedback or remarks which customer confirmed on engagement, 100% completeness will not reach customer.</Header.Subheader>
                      </Header>
                    </div>
                    <div>
                      <Button type='button' icon='minus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this,false, 'restructuringStageQty')} />
                    </div>
                    <div>
                        <Field name='restructuringStageQty' component={InputField} size='small' style={{height: '2.2em', width: '3em', borderRadius: '0'}} />
                    </div>
                    <div>
                      <Button type='button' icon='plus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this,true, 'restructuringStageQty')} />
                    </div>
                  </div>
                  <Table unstackable selectable columns='16' celled striped basic='very'>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell width='6'>
                          <Header as='h5' color='blue'>
                            REMARKS
                          </Header>
                        </Table.HeaderCell>
                        <Table.HeaderCell width='10'>
                          <Header as='h5' color='blue'>
                            COMPLETENESS
                          </Header>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        this.progressStages(this.props.restructuringStageQty, 'restructuring')
                      }
                    </Table.Body>
                  </Table>
                </Segment></>}
                { data && tabModule==2 && <>
                  <Segment>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: '1'}}>
                      <Header as='h5'>
                        Customer Remarks
                        <Header.Subheader>Feedback or remarks which customer confirmed on engagement, 100% completeness will not reach customer.</Header.Subheader>
                      </Header>
                    </div>
                    <div>
                      <Button type='button' icon='minus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this,false, 'telesaleStageQty')} />
                    </div>
                    <div>
                        <Field name='telesaleStageQty' component={InputField} size='small' style={{height: '2.2em', width: '3em', borderRadius: '0'}} />
                    </div>
                    <div>
                      <Button type='button' icon='plus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this,true, 'telesaleStageQty')} />
                    </div>
                  </div>
                  <Table unstackable selectable columns='16' celled striped basic='very'>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell width='6'>
                          <Header as='h5' color='blue'>
                            REMARKS
                          </Header>
                        </Table.HeaderCell>
                        <Table.HeaderCell width='10'>
                          <Header as='h5' color='blue'>
                            COMPLETENESS
                          </Header>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        this.progressStages(this.props.telesaleStageQty, 'telesale')
                      }
                    </Table.Body>
                  </Table>
                </Segment></>}
                { data && tabModule==3 && <>
                  <Segment>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: '1'}}>
                      <Header as='h5'>
                        Customer Remarks
                        <Header.Subheader>Feedback or remarks which customer confirmed on engagement, 100% completeness will not reach customer.</Header.Subheader>
                      </Header>
                    </div>
                    <div>
                      <Button type='button' icon='minus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this,false, 'verificationStageQty')} />
                    </div>
                    <div>
                        <Field name='verificationStageQty' component={InputField} size='small' style={{height: '2.2em', width: '3em', borderRadius: '0'}} />
                    </div>
                    <div>
                      <Button type='button' icon='plus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this,true, 'verificationStageQty')} />
                    </div>
                  </div>
                  <Table unstackable selectable columns='16' celled striped basic='very'>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell width='6'>
                          <Header as='h5' color='blue'>
                            REMARKS
                          </Header>
                        </Table.HeaderCell>
                        <Table.HeaderCell width='10'>
                          <Header as='h5' color='blue'>
                            COMPLETENESS
                          </Header>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        this.progressStages(this.props.verificationStageQty, 'verification')
                      }
                    </Table.Body>
                  </Table>
                </Segment></>}
                { data && tabModule==4 && <>
                <Segment>
                  <Header as='h5'>
                    INSURANCE
                  </Header>
                </Segment></>}
                { data && tabModule==5 && <>
                <Segment>
                  <Header as='h5'>
                    TOURISM
                  </Header>
                </Segment></>}
              </>}
            </>}
            {(group=='admin' || true) && <>
              <div style={{display: 'flex', marginBottom: '1em'}}>
                <div style={{flex: '1'}}>
                  <Header as='h4' style={{fontWeight: 'bold', marginTop: '1em'}} color={(showSetting=='format' && 'blue') || 'black'}>
                    <Header.Content>
                      Import / Reporting Format
                      <Header.Subheader>File attribute / column formats</Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div style={{marginTop: '1.5em', marginLeft: '1em'}}>
                  <Icon name={(showSetting=='format' && 'toggle on') || 'toggle off'} color={(showSetting=='format' && 'blue') || 'grey'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.showMode.bind(this, 'format')} />
                </div>
              </div>

              <Divider />
              {showSetting=='format' && <>
                <Segment>
                  <Header as='h5'>
                    {/* <Icon name='window restore outline' style={{fontSize: '1em'}} /> */}
                    <Header.Content>
                      Contact's Field Mapping
                    </Header.Content>
                  </Header>
                  {/* <Form.Group widths='16'>
                    <Field name='fileDelimiter' component={SelectField}
                    width={4}
                    options={delimiterOptions}
                    label='File Delimiter'
                    placeholder='Upload File Delimiter' />
                  </Form.Group> */}
                  <Form.Group widths='16'>
                    <Field name='fileFormat' component={SelectField}
                    width={16}
                    options={fieldTargetOptions}
                    multiple
                    label='Upload File Format'
                    placeholder='Upload File Format' />
                  </Form.Group>
                </Segment>

                <Divider hidden />
                {/* <MenuTabular panes={panesReport({})} style={{width: '100%', marginBottom: '-1.2em'  }} renderActiveOnly={true} onTabChange={this.setMode.bind(this, 'report')} /> */}
                {tabReport==0 &&
                  <Segment>
                    <Header as='h5'>
                      {/* <Icon name='microphone' style={{fontSize: '1em'}} /> */}
                      <Header.Content>
                        Voice / Call Data Record
                      </Header.Content>
                    </Header>
                    {/* <Form.Group widths='16'>
                      <Field name='reportCdrDelimiter' component={SelectField}
                      width={4}
                      options={delimiterOptions}
                      label='File Delimiter'
                      placeholder='Report Delimiter' />
                      <Field name='reportCdrPeriod' component={SelectField}
                      width={2}
                      options={periodOptions()}
                      label='Period'
                      placeholder='Period' />
                      <Field name='reportCdrTransaction' component={SelectField}
                      width={10}
                      options={cdrOptions}
                      multiple
                      label='Transaction Type'
                      placeholder='Transaction which included' />
                    </Form.Group> */}
                    <Form.Group widths='16'>
                      <Field name='reportCdrFormat' component={SelectField}
                      width={16}
                      options={fieldCdrOptions}
                      multiple
                      label='Report File Format'
                      placeholder='Report File Format' />
                    </Form.Group>
                  </Segment>
                }
                {tabReport==1 &&
                  <Segment>
                    <Header as='h5'>
                      {/* <Icon name='file alternate outline' style={{fontSize: '1em'}} /> */}
                      <Header.Content>
                        Messaging Data Record
                      </Header.Content>
                    </Header>
                    {/* <Form.Group widths='16'>
                      <Field name='reportMdrDelimiter' component={SelectField}
                      width={4}
                      options={delimiterOptions}
                      label='File Delimiter'
                      placeholder='Report Delimiter' />
                      <Field name='reportMdrPeriod' component={SelectField}
                      width={2}
                      options={periodOptions()}
                      label='Period'
                      placeholder='Period' />
                      <Field name='reportMdrTransaction' component={SelectField}
                      width={10}
                      options={cdrOptions}
                      multiple
                      label='Transaction Type'
                      placeholder='Transaction which included' />
                    </Form.Group> */}
                    <Form.Group widths='16'>
                      <Field name='reportMdrFormat' component={SelectField}
                      width={16}
                      options={fieldMdrOptions}
                      multiple
                      label='Report File Format'
                      placeholder='Report File Format' />
                    </Form.Group>
                  </Segment>
                }
                {tabReport==2 &&
                  <Segment>
                    <Header as='h5'>
                      {/* <Icon name='id badge outline' style={{fontSize: '1em'}} /> */}
                      <Header.Content>
                        Customer / Transaction Activity
                      </Header.Content>
                    </Header>
                    {/* <Form.Group widths='16'>
                      <Field name='reportTrxDelimiter' component={SelectField}
                      width={4}
                      options={delimiterOptions}
                      label='File Delimiter'
                      placeholder='Report Delimiter' />
                      <Field name='reportTrxPeriod' component={SelectField}
                      width={2}
                      options={periodOptions()}
                      label='Period'
                      placeholder='Daily / Monthly Basis' />
                      <Field name='reportTrxTransaction' component={SelectField}
                      width={10}
                      options={transactionOptions()}
                      multiple
                      label='Transaction Type'
                      placeholder='Transaction which included' />
                    </Form.Group> */}
                    <Form.Group widths='16'>
                      <Field name='reportTrxFormat' component={SelectField}
                      width={16}
                      options={fieldTrxOptions}
                      multiple
                      label='Report File Format'
                      placeholder='Report File Format' />
                    </Form.Group>
                  </Segment>
                }
              </>}
            </>}
            {group=='admin' && <>
              <div style={{display: 'flex', marginBottom: '1em'}}>
                <div style={{flex: '1'}}>
                  <Header as='h4' style={{fontWeight: 'bold', marginTop: '1em'}} color={showSetting=='agent' ? 'blue' : null}>
                    <Header.Content>
                      Agent's Data Presentation
                      <Header.Subheader>Available information on popup conversation for agents.</Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div style={{marginTop: '1.5em', marginLeft: '1em'}}>
                  <Icon name={(showSetting=='agent' && 'toggle on') || 'toggle off'} color={(showSetting=='agent' && 'blue') || 'grey'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.showMode.bind(this, 'agent')} />
                </div>
              </div>
              <Divider />
              {showSetting=='agent' && <>
                <Segment>
                  <Header as='h5'>
                    <Header.Content>
                      Module's Information
                    </Header.Content>
                  </Header>
                  <Form.Group widths='16'>
                    <Field name='popupCollection' component={SelectField}
                    width={16}
                    options={popupModule('collection')}
                    multiple
                    label='Collection / Restructuring'
                    placeholder='Available information to show' />
                  </Form.Group>
                  <Form.Group widths='16'>
                    <Field name='popupTelesales' component={SelectField}
                    width={16}
                    options={popupModule('telesales')}
                    multiple
                    label='Telesales / Telemarketing'
                    placeholder='Available information to show' />
                  </Form.Group>
                  <Form.Group widths='16'>
                    <Field name='popupVerification' component={SelectField}
                    width={16}
                    options={popupModule('verification')}
                    multiple
                    label='Verification / Survey  Feedback'
                    placeholder='Available information to show' />
                  </Form.Group>

                  <Divider hidden />
                  <Header as='h5'>
                    <Header.Content>
                      Target's Information
                    </Header.Content>
                  </Header>
                  <Form.Group widths='16'>
                    <Field name='popupCampaign' component={SelectField}
                    width={16}
                    options={popupCampaign}
                    multiple
                    label='Campaign Data'
                    placeholder='Available information to show' />
                  </Form.Group>
                  <Form.Group widths='16'>
                    <Field name='popupPersonal' component={SelectField}
                    width={16}
                    options={popupPersonal}
                    multiple
                    label='Personal / Customer Data'
                    placeholder='Available information to show' />
                  </Form.Group>
                  <Form.Group widths='16'>
                    <Field name='popupMerchant' component={SelectField}
                    width={16}
                    options={popupMerchant}
                    multiple
                    label='Merchant / Retailer Data'
                    placeholder='Available information to show' />
                  </Form.Group>
                </Segment>
              </>}
            </>}
          </>}

          {module=='flow' && <>
            {false && group!='admin' && <>
              <Segment style={{paddingBottom: '7em'}}>
                <Header as='h5'>
                  <Icon name='warning sign' />
                  <Header.Content>
                    Your configuration is handled by system.
                    <Header.Subheader>Currently no configuration related to Omni Channel process is currently handled by client.</Header.Subheader>
                  </Header.Content>
                </Header>
              </Segment>
            </>}

            {group=='admin' && <>
              <Segment>
                <Header as='h5'>
                  <Header.Content>
                    Agent Behaviour
                  </Header.Content>
                </Header>
                <Form.Group widths='16'>
                  <Field name='agentDispatchInbound' component={SelectField}
                  width={4} 
                  options= {dispatchOptions('agent')}
                  label='Dispatch Method'
                  placeholder='Agent Dispatch Method' />
                </Form.Group>
                <Form.Group widths='16'>
                  <Field name='agentSessionMode' width={4} component={SelectField}
                  options= {sessionOptions()}
                  label='Agent Session Mode'
                  placeholder='Starting sesion mode' />
                  <Field name='agentSessionCountdown' width={4} component={InputField}
                  type= 'number'
                  label='Countdown on Starting (sec)'
                  placeholder='Duration or countdown timer before session starts' />
                  <Field name='agentLockedMode' width={4} component={SelectField}
                  options= {lockedOptions}
                  label='Agent Locked Mode'
                  placeholder='Features access mode' />
                  <Field name='agentLockedCountdown' width={4} component={InputField}
                  type= 'number'
                  label='Countdown on Locked (sec)'
                  placeholder='Duration or countdown timer as locked allowed' />
                </Form.Group>
              </Segment>
              <Segment>
                <Form.Group widths='equal'>
                  <Field name='voiceCallCenter' component={InputField}
                  label='Call Center Number'
                  placeholder='Call Center Number, if multiples split by comma' />
                </Form.Group>
              </Segment>
            </>}
            
            {(group=='admin' || true ) && <>
              <div style={{display: 'flex', marginBottom: '1em'}}>
                <div style={{flex: '1'}}>
                  <Header as='h4' style={{fontWeight: 'bold', marginTop: '1em'}} color={showSetting=='module' ? 'blue' : null}>
                    <Header.Content>
                      Module Configuration
                      <Header.Subheader>Campaign's remark and feedback progress</Header.Subheader>
                    </Header.Content>
                  </Header>    
                </div>
                <div style={{marginTop: '1.5em', marginLeft: '1em'}}>
                  <Icon name={(showSetting=='module' && 'toggle on') || 'toggle off'} color={(showSetting=='module' && 'blue') || 'grey'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.showMode.bind(this, 'module')} />
                </div>
              </div>
              <Divider />
              {showSetting=='module' && <>
                <MenuTabular panes={panesModule({flow: true})} style={{width: '100%', marginBottom: '-1.2em'  }} renderActiveOnly={true} onTabChange={this.setMode.bind(this, 'module')} />
                { data && tabModule==0 && <>
                <Segment>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: '1'}}>
                      <Header as='h5'>
                        Visitor Remarks / Feedbacks
                        <Header.Subheader>Feedback or remarks from inbound guest / visitor.</Header.Subheader>
                      </Header>
                    </div>
                    <div>
                      <Button type='button' icon='minus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this,false, 'visitorStageQty')} />
                    </div>
                    <div>
                        <Field name='visitorStageQty' component={InputField} size='small' style={{height: '2.2em', width: '3em', borderRadius: '0'}} />
                    </div>
                    <div>
                      <Button type='button' icon='plus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this,true, 'visitorStageQty')} />
                    </div>
                  </div>

                  <Table unstackable selectable columns='16' celled striped basic='very'>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell width='6'>
                          <Header as='h5' color='blue'>
                            REMARKS
                          </Header>
                        </Table.HeaderCell>
                        <Table.HeaderCell width='10'>
                          <Header as='h5' color='blue'>
                            COMPLETENESS
                          </Header>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        this.progressStages(this.props.visitorStageQty, 'visitor')
                      }
                    </Table.Body>
                  </Table>
                </Segment></>}
                { data && tabModule==1 && <>
                <Segment>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: '1'}}>
                      <Header as='h5'>
                        Customer Remarks / Feedbacks
                        <Header.Subheader>Feedback or remarks from inbound customer.</Header.Subheader>
                      </Header>
                    </div>
                    <div>
                      <Button type='button' icon='minus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this,false, 'customerStageQty')} />
                    </div>
                    <div>
                        <Field name='customerStageQty' component={InputField} size='small' style={{height: '2.2em', width: '3em', borderRadius: '0'}} />
                    </div>
                    <div>
                      <Button type='button' icon='plus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this,true, 'customerStageQty')} />
                    </div>
                  </div>
                  <Table unstackable selectable columns='16' celled striped basic='very'>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell width='6'>
                          <Header as='h5' color='blue'>
                            REMARKS
                          </Header>
                        </Table.HeaderCell>
                        <Table.HeaderCell width='10'>
                          <Header as='h5' color='blue'>
                            COMPLETENESS
                          </Header>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        this.progressStages(this.props.customerStageQty, 'customer')
                      }
                    </Table.Body>
                  </Table>
                </Segment></>}
                { data && tabModule==2 && <>
                  <Segment>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: '1'}}>
                      <Header as='h5'>
                        Customer Remarks
                        <Header.Subheader>Feedback or remarks which customer confirmed on engagement, 100% completeness will not reach customer.</Header.Subheader>
                      </Header>
                    </div>
                    <div>
                      <Button type='button' icon='minus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this,false, 'telesaleStageQty')} />
                    </div>
                    <div>
                        <Field name='telesaleStageQty' component={InputField} size='small' style={{height: '2.2em', width: '3em', borderRadius: '0'}} />
                    </div>
                    <div>
                      <Button type='button' icon='plus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this,true, 'telesaleStageQty')} />
                    </div>
                  </div>
                  <Table unstackable selectable columns='16' celled striped basic='very'>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell width='6'>
                          <Header as='h5' color='blue'>
                            REMARKS
                          </Header>
                        </Table.HeaderCell>
                        <Table.HeaderCell width='10'>
                          <Header as='h5' color='blue'>
                            COMPLETENESS
                          </Header>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        this.progressStages(this.props.telesaleStageQty, 'telesale')
                      }
                    </Table.Body>
                  </Table>
                </Segment></>}
                { data && tabModule==3 && <>
                  <Segment>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: '1'}}>
                      <Header as='h5'>
                        Customer Remarks
                        <Header.Subheader>Feedback or remarks which customer confirmed on engagement, 100% completeness will not reach customer.</Header.Subheader>
                      </Header>
                    </div>
                    <div>
                      <Button type='button' icon='minus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this,false, 'verificationStageQty')} />
                    </div>
                    <div>
                        <Field name='verificationStageQty' component={InputField} size='small' style={{height: '2.2em', width: '3em', borderRadius: '0'}} />
                    </div>
                    <div>
                      <Button type='button' icon='plus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this,true, 'verificationStageQty')} />
                    </div>
                  </div>
                  <Table unstackable selectable columns='16' celled striped basic='very'>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell width='6'>
                          <Header as='h5' color='blue'>
                            REMARKS
                          </Header>
                        </Table.HeaderCell>
                        <Table.HeaderCell width='10'>
                          <Header as='h5' color='blue'>
                            COMPLETENESS
                          </Header>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        this.progressStages(this.props.verificationStageQty, 'verification')
                      }
                    </Table.Body>
                  </Table>
                </Segment></>}
                { data && tabModule==4 && <>
                <Segment>
                  <Header as='h5'>
                    INSURANCE
                  </Header>
                </Segment></>}
                { data && tabModule==5 && <>
                <Segment>
                  <Header as='h5'>
                    TOURISM
                  </Header>
                </Segment></>}
              </>}
            </>}
            <div style={{display: 'flex', marginBottom: '1em'}}>
              <div style={{flex: '1'}}>
                <Header as='h4' style={{fontWeight: 'bold', marginTop: '1em'}} color={(showSetting=='widget' && 'blue') || 'black'}>
                  <Header.Content>
                    Widget Phone Theme
                    <Header.Subheader>Set widget phone user interface experience.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{marginTop: '1.5em', marginLeft: '1em'}}>
                <Icon name={(showSetting=='widget' && 'toggle on') || 'toggle off'} color={(showSetting=='widget' && 'blue') || 'grey'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.showMode.bind(this, 'widget')} />
              </div>
            </div>
            <Divider />
            {showSetting=='widget' && <>
                <Segment>
                  <Form.Group widths={16}>
                    <Field name='widgetLanguage' component={LabelSelectField}
                    width={4}
                    options={widgetOptions('language')}
                    label='Lang'
                    caption= 'Widget Language'
                    placeholder='Language' />
                  </Form.Group>
                  <Form.Group widths={16}>
                    <Field name='widgetTheme' component={LabelSelectField}
                    width={4}
                    options={widgetOptions('theme')}
                    label='Theme'
                    caption= 'Widget Theme'
                    placeholder='Thematic' />
                    <Field name='widgetWidth' component={LabelSelectField}
                    width={4}
                    options={widgetOptions('width')}
                    label='Pixel'
                    caption= 'Widget Width'
                    placeholder='Width Size' />
                    <Field name='widgetSize' component={LabelSelectField}
                    width={4}
                    options={widgetOptions('size')}
                    label='Size'
                    caption= 'Initial Size'
                    placeholder='Initial size' />
                    <Field name='widgetPosition' component={LabelSelectField}
                    width={4}
                    options={widgetOptions('position')}
                    label='Pos.'
                    caption= 'Layout Position'
                    placeholder='Position' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Form.Field>
                      <Field name='widgetBackground' component={ImageUploadField}
                      width={300} 
                      height={300} 
                      aspectRatio= '3:3'/>
                    </Form.Field>
                    <Form.Field>
                      <Field name='widgetBanner' component={ImageUploadField}
                      width={300} 
                      height={300} 
                      aspectRatio= '3:3'/>
                    </Form.Field>
                  </Form.Group>

                  <Omniwidget dummy params={{
                    style: {
                      theme: this.props.widgetTheme || 'dark',
                      width: this.props.widgetWidth || 275, 
                      margin: (this.props.widgetPosition=='left' && '1.5em auto 0 0') || (this.props.widgetPosition=='center' && '1.5em auto') || '1.5em 0 0 auto',
                    },
                    size: this.props.widgetSize || 'mini',

                    banner: this.props.widgetBanner ? this.props.widgetBanner : null,
                    background: this.props.widgetBackground ? this.props.widgetBackground : null,
                  }} />
                </Segment>
                <Segment>
                  <Header as='h5' style={{fontWeight: 'bold', marginTop: '1em'}} color={(showSetting=='widget' && 'blue') || 'black'}>
                    <Header.Content>
                      Embedded Code
                      <Header.Subheader>Copy code and paste to your website/page for widget phone appearance.</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Segment>
            </>}

            <div style={{display: 'flex', marginBottom: '1em'}}>
              <div style={{flex: '1'}}>
                <Header as='h4' style={{fontWeight: 'bold', marginTop: '1em'}} color={(showSetting=='feedback' && 'blue') || 'black'}>
                  <Header.Content>
                    Conversation Feedbacks
                    <Header.Subheader>Define conversation feedback when rating is bad or worst.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{marginTop: '1.5em', marginLeft: '1em'}}>
                <Icon name={(showSetting=='feedback' && 'toggle on') || 'toggle off'} color={(showSetting=='feedback' && 'blue') || 'grey'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.showMode.bind(this, 'feedback')} />
              </div>
            </div>
            <Divider />
            {showSetting=='feedback' && <>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Header as='h5'>
                    Worst/Bad Feedback Rating
                    <Header.Subheader>Detail feedback from customer when rating is below Good Rating.</Header.Subheader>
                  </Header>
                </div>
                <div>
                  <Button type='button' icon='minus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this,false, 'feedbackRatingQty')} />
                </div>
                <div>
                    <Field name='feedbackRatingQty' component={InputField} size='small' style={{height: '2.2em', width: '3em', borderRadius: '0'}} />
                </div>
                <div>
                  <Button type='button' icon='plus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this,true, 'feedbackRatingQty')} />
                </div>
              </div>

              <Table unstackable selectable columns='16' celled striped basic='very'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>#</Table.HeaderCell>
                    <Table.HeaderCell width='6'>
                      <Header as='h5' color='blue'>
                        FEEDBACK POINT
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='10'>
                      <Header as='h5' color='blue'>
                        DETAIL / DESCRIPTION
                      </Header>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {
                    this.feedbackRatings(this.props.feedbackRatingQty, 'feedback')
                  }
                </Table.Body>
              </Table>
            </>}
          </>}

          {module=='talk' && <>
            {group!='admin' && <>
              <Segment style={{paddingBottom: '7em'}}>
                <Header as='h5'>
                  <Icon name='warning sign' />
                  <Header.Content>
                    Your configuration is handled by system.
                    <Header.Subheader>Currently no configuration related to Talk In-App Call is currently handled by client.</Header.Subheader>
                  </Header.Content>
                </Header>
              </Segment>
            </>}
            {group=='admin' &&
              <Segment>
                <Header as='h5'>
                  <Header.Content>
                    Agent Behaviour
                  </Header.Content>
                </Header>
                <Form.Group widths='16'>
                  <Field name='agentDispatchInapp' component={SelectField}
                  width={4} 
                  options= {dispatchOptions('agent')}
                  label='Dispatch Method'
                  placeholder='Agent Dispatch Method' />
                </Form.Group>
                <Form.Group widths='16'>
                  <Field name='agentSessionMode' width={4} component={SelectField}
                  options= {sessionOptions()}
                  label='Agent Session Mode'
                  placeholder='Starting sesion mode' />
                  <Field name='agentSessionCountdown' width={4} component={InputField}
                  type= 'number'
                  label='Countdown on Starting (sec)'
                  placeholder='Duration or countdown timer before session starts' />
                  <Field name='agentLockedMode' width={4} component={SelectField}
                  options= {lockedOptions}
                  label='Agent Locked Mode'
                  placeholder='Features access mode' />
                  <Field name='agentLockedCountdown' width={4} component={InputField}
                  type= 'number'
                  label='Countdown on Locked (sec)'
                  placeholder='Duration or countdown timer as locked allowed' />
                </Form.Group>
              </Segment>
            }
            {group=='admin' && <>
              <Segment>
                <Form.Group widths='equal'>
                  <Field name='inappGateway' component={InputField}
                  label='In-App Gateway'
                  placeholder='In-App Gateway' />
                  <Field name='inappOriginating' component={InputField}
                  label='In-App Originating'
                  placeholder='In-App Originating' />
                  {/* <Field name='inappParking' component={InputField}
                  label='In-App Parking'
                  placeholder='Sip Parking' /> */}
                </Form.Group>
              </Segment>
            </>}
          </>}

          {module=='ring' && <>
            {group!='admin' && <>
              <Segment style={{paddingBottom: '7em'}}>
                <Header as='h5'>
                  <Icon name='warning sign' />
                  <Header.Content>
                    Your configuration is handled by system.
                    <Header.Subheader>Currently no configuration related to Office Phone Ring! is currently handled by client.</Header.Subheader>
                  </Header.Content>
                </Header>
              </Segment>
            </>}
            {group=='admin' && <>
              <Segment>
                <Header as='h5'>
                  <Header.Content>
                    Dialing Command / Prefix
                  </Header.Content>
                </Header>
                <Form.Group widths='equal'>
                  <Field name='dialExtension' component={InputField}
                  label='Extension Dial'
                  placeholder='Prefix for dialing extension' />
                  <Field name='dialGroup' component={InputField}
                  label='Group Dial'
                  placeholder='Prefix for dialing group extension' />
                  <Field name='dialDomestic' component={InputField}
                  label='Domestic Dial'
                  placeholder='Keys command for domestic / telco' />
                  <Field name='dialInternational' component={InputField}
                  label='International Dial'
                  placeholder='Keys command for international dial' />
                </Form.Group>
              </Segment>
              <Segment>
                <Header as='h5'>
                  <Header.Content>
                    Conversation Command / Prefix
                  </Header.Content>
                </Header>
                <Form.Group widths='equal'>
                  <Field name='callForwarding' component={InputField}
                  label='Forwarding Call'
                  placeholder='Keys command for forwarding' />
                  <Field name='callMute' component={InputField}
                  label='Mute/Unmute Call'
                  placeholder='Keys command for mute/unmute' />
                  <Field name='callHold' component={InputField}
                  label='Hold/Unhold Call'
                  placeholder='Keys command for hold/unhold' />
                  <Field name='callInvite' component={InputField}
                  label='Invite New Call'
                  placeholder='Keys command for call invitation' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='conferenceCreate' component={InputField}
                  label='Create Conference'
                  placeholder='Keys command creating conference' />
                  <Field name='conferenceAccess' component={InputField}
                  label='Access Conference'
                  placeholder='Keys command accessing conference' />
                  <Field name='conferenceKick' component={InputField}
                  label='Kick From Conference'
                  placeholder='Keys command kicking member' />
                  <Field name='conferenceInvite' component={InputField}
                  label='Invite To Conference'
                  placeholder='Keys command conference invitation' />
                </Form.Group>
              </Segment>
            </>}
          </>}

          {module=='api' && <>
            {(this.props.group=='admin' || this.props.apiKey!=null) && 
            <>
              <Segment>
                <Header as='h5'>
                  <Header.Content>API Integration</Header.Content>
                </Header>
                <Form.Group widths='equal'>
                  <Field name='apiKey' component={InputField}
                  readOnly={this.props.group!='admin'}
                  label='API Key' />
                  <Field name='apiSecret' component={InputField}
                  readOnly
                  label='API Secret' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='apiOrigins' component={TextAreaField}
                  readOnly={this.props.group!='admin'}
                  label='API Origin Hosts / Servers' 
                  placeholder='IP Address delimited by ",": IPServer_1, IPServer_2, . . .'/>
                </Form.Group>
              </Segment>
              <Segment>
                <Header as='h5'>
                  <Header.Content>API Behavior</Header.Content>
                </Header>
                <Form.Group widths='equal'>
                  <Field name='apiBackend' component={InputField}
                  readOnly={this.props.group!='admin'}
                  label= 'Api Backend' 
                  placeholder=' https://base_url/ [relay/] [backend/] [callback/]' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='apiFrontend' component={InputField}
                  readOnly={this.props.group!='admin'}
                  label= 'Api Frontend' 
                  placeholder=' https://base_url/ [customer/] [collection/] [verification/]' />
                </Form.Group>
                {group=='admin' && <>
                  <Form.Group widths='equal'>
                    <Field name='isPopup' component={CheckboxField}
                    readOnly={this.props.group!='admin'}
                    label='Popup Data as Local Relay on Agent Dashboard'/>
                    <Field name='isCallback' component={CheckboxField}
                    readOnly={this.props.group!='admin'}
                    label='Callback Event (Dial, Pickedup, Hangup)'/>
                    <Field name='isIntegrated' component={CheckboxField}
                    readOnly={this.props.group!='admin'}
                    label='Check When Client API Was Integrated'/>
                  </Form.Group>
                </>}
              </Segment>
              
              <div style={{display: 'flex', margin: '2em 0 1em 0'}}>
                <div style={{flex: '1'}}>
                  <Header as='h4' style={{fontWeight: 'bold', marginTop: '1em'}} color={showSetting=='widget' ? 'blue' : null}>
                    <Header.Content>
                      Widget Secret
                      <Header.Subheader>Widget secret token.</Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div style={{marginTop: '1.5em', marginLeft: '1em'}}>
                  <Icon name={(showSetting=='widget' && 'toggle on') || 'toggle off'} color={(showSetting=='widget' && 'blue') || 'grey'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.showMode.bind(this, 'widget')} />
                </div>
              </div>
              <Divider />
              {showSetting=='widget' && <>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='androidSecret' component={LabelInputField}
                    readOnly={this.props.group=='admin' ? false : true}
                    icon='android'
                    label='Token'
                    caption='Android Secret' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='iosSecret' component={LabelInputField}
                    readOnly={this.props.group=='admin' ? false : true}
                    icon='apple'
                    label='Token'
                    caption='IOS / Iphone Secret' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='webSecret' component={LabelInputField}
                    readOnly={this.props.group=='admin' ? false : true}
                    icon='chrome'
                    label='Token'
                    caption='Web Secret' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='webOrigins' component={TextAreaField}
                    label= 'Referral Domains / Origins' />
                  </Form.Group>
                </Segment>
              </>}
            </>}
            
            {(this.props.group!='admin' && this.props.apiKey==null) && 
            <Segment>
              <Header as='h3' color='red'>
                <Icon name='warning sign'/>
                <Header.Content>API Connect Not Available!
                  <Header.Subheader>Whether your Admin is not allowed or configuration has not been granted!</Header.Subheader>
                </Header.Content>
              </Header>
            </Segment>
            }
          </>}

          {module=='security' && <>
            {this.props.group=='admin' && <>
              <Segment>
                <Form.Group widths='equal'>
                  <Field name='uniqueSession' component={LabelSelectField}
                  options={sessionOptions('token')}
                  label='Token'
                  caption='Single User Session' />
                  <Field name='loggingSession' component={LabelSelectField}
                  options={sessionOptions('activity')}
                  label='Level'
                  caption='Logging User Activity' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='originatingHost' component={TextAreaField}
                  rows={6}
                  label='Orginating Host / IP Address' />
                </Form.Group>
              </Segment>
              <Segment>
                <Form.Group widths='equal'>
                  <Field name='loadDistribution' component={TextAreaField}
                  placeholder='https://host1.halopagi.com, https://host2.halopagi.com'
                  label='Load Distribution - Backend Service' />
                </Form.Group>
              </Segment>
            </>}
            {this.props.group!='admin' && 
              <Segment>
                <Header as='h3' color='red'>
                  <Icon name='warning sign'/>
                  <Header.Content>No Config Required!
                    <Header.Subheader>Whether your Admin is not allowed or configuration has not been granted!</Header.Subheader>
                  </Header.Content>
                </Header>
              </Segment>
            }
          </>}

          <Segment>
              {(progress==1 || progress==2)  && <DismissableMessage hide='auto' style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Setting' : 'Insert Setting') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={code + ' ' + text} />}
              
              <div style={{textAlign: 'right'}}>
                <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
                <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
              </div>
          </Segment>
        </Form >
      </div>
    </>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.settingStore.getSetting!=undefined && state.settingStore.getSetting.progress==1 ? state.settingStore.getSetting.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      userId: selector(state, 'userId'),
      apiKey: selector(state, 'apiKey'),
      isPopup: selector(state, 'isPopup'),
      isCallback: selector(state, 'isCallback'),
      isIntegrated: selector(state, 'isIntegrated'),
      
      collectionStageQty: selector(state, 'collectionStageQty'),
      restructuringStageQty: selector(state, 'restructuringStageQty'),
      telesaleStageQty: selector(state, 'telesaleStageQty'),
      verificationStageQty: selector(state, 'verificationStageQty'),

      visitorStageQty: selector(state, 'visitorStageQty'),
      customerStageQty: selector(state, 'customerStageQty'),

      reportCdrPeriod: selector(state, 'reportCdrPeriod'),
      reportMdrPeriod: selector(state, 'reportMdrPeriod'),
      reportTrxPeriod: selector(state, 'reportTrxPeriod'),
      
      smsGateway: selector(state, 'smsGateway'),
      waGateway: selector(state, 'waGateway'),
      mailGateway: selector(state, 'mailGateway'),

      widgetTheme: selector(state, 'widgetTheme'),
      widgetWidth: selector(state, 'widgetWidth'),
      widgetSize: selector(state, 'widgetSize'),
      widgetPosition: selector(state, 'widgetPosition'),
      widgetBackground: selector(state, 'widgetBackground'),
      widgetBanner: selector(state, 'widgetBanner'),
      
      feedbackRatingQty: selector(state, 'feedbackRatingQty') || '0',
    }),
    { load: getSetting }
  )
  (reduxForm({
    form: 'SettingForm',	// a unique identifier for this form
  })(SettingForm)
  )
)
