import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Dropdown } from 'semantic-ui-react'

export default class MenuLeftDashboard extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      isSubMenu: 'service', //service addon systen
      isExpand: 'real-time',
    };
  }

  expandMenu(v) {
    this.setState({
      isExpand: this.state.isExpand==v ? '' : v
    })
  }

  expandSubMenu(v) {
    this.setState({
      isSubMenu: this.state.isSubMenu==v ? '' : v
    })
  }

  render() {
    const { isExpand, isSubMenu }= this.state
    const { module, screenWidth,  screenHeight, mini }= this.props
    
    return (
      <div style={{position: 'fixed', zIndex: 999, display: 'flex', flexDirection: 'column', marginLeft: '.2em', marginTop: '10em', borderRadius: '3em', padding: '2em .2em', background: 'linear-gradient(0deg, rgb(186, 168, 255) 0%, rgb(87, 197, 255) 65%, rgb(128 188 255) 100%)', textAlign: 'center'}}>
        <div style={{flex: '1'}}>
          <Divider clearing/>
          <Popup position= 'right center' inverted content='Trend/Historical Dashboard'
            trigger= {
              <Button size='tiny' circular icon onClick={this.props.openWindow.bind(this, 'historical', 'service')} color={this.props.mode=='historical' ? 'purple' : null} style={{padding: '0 .5em 0 .5em'}}>
                <Icon name='chart area' style={{fontSize: '1.15em'}}/>
              </Button>
            } 
          />
          <Divider />
          <Popup position= 'right center' inverted content='Agent & Frontliner'
            trigger= {<div>
              <Button disabled size='tiny' circular icon onClick={this.props.openWindow.bind(this, 'agent', 'service')} color={this.props.mode=='agent' ? 'purple' : null} style={{padding: '0 .5em 0 .5em', margin: '0'}}>
                <Icon name='detective' />
              </Button>
            </div>} 
          />
          <Divider />
          <Popup position= 'right center' inverted content='Support & Backend'
            trigger= {<div>
              <Button disabled size='tiny' circular icon onClick={this.props.openWindow.bind(this, 'backend', 'service')} color={this.props.mode=='backend' ? 'purple' : null} style={{padding: '0 .5em 0 .5em', margin: '0'}}>
                <Icon name='user md' />
              </Button>
            </div>} 
          />
          <Divider />
          <Popup position= 'right center' inverted content='Service / Product'
            trigger= {<div>
              <Button disabled size='tiny' circular icon onClick={this.props.openWindow.bind(this, 'product', 'service')} color={this.props.mode=='product' ? 'purple' : null} style={{padding: '0 .5em 0 .5em', margin: '0'}}>
                <Icon name='cube' />
              </Button>
            </div>} 
          />
          <Divider />
          <Popup position= 'right center' inverted content='Vendor / 3rd Parties'
            trigger= {<div>
              <Button disabled size='tiny' circular icon onClick={this.props.openWindow.bind(this, 'vendor', 'service')} color={this.props.mode=='vendor' ? 'purple' : null} style={{padding: '0 .5em 0 .5em', margin: '0'}}>
                <Icon name='travel' />
              </Button>
            </div>} 
          />
          <Divider clearing/>
        </div>
      </div>
    );
  }
}
