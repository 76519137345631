import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'
import ModalContainer from '../../libraries/common/ModalContainer';

import MenuLeft from '../../libraries/service/MenuLeftReports'
// import RptTicketPerformanceBody from './RptTicketPerformanceBody';
// import RptTicketActivityBody from './RptTicketActivityBody';
// import RptTicketTimelineBody from './RptTicketTimelineBody';
// import RptTicketClassificationBody from './RptTicketClassificationBody';
// import RptTicketServiceBody from './RptTicketServiceBody';
// import RptTicketResolutionBody from './RptTicketResolutionBody';

import RptOJKBody from './RptOJKBody';
import RptCustomerBody from './RptCustomerBody';
import RptCsatBody from './RptCsatBody';
import RptSlaBody from './RptSlaBody';
import RptPeopleBody from './RptPeopleBody';
import RptTicketBody from './RptTicketBody';

class ServiceReportsBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { mode, module, screenWidth, screenHeight, mini, modal, params }= this.props

    return (
      <div>
        <div style={{marginTop: '3em'}}>
          <div style={{display: 'flex'}}>
            {!mini && <div style={{background: '#eaeaea', width: 225, paddingTop: '0em'}}>
              <MenuLeft openWindow={this.props.openWindow} mode={this.props.mode} screenHeight={screenHeight} screenWidth={screenWidth} module={module} mini={mini}/>
            </div>}
            <div style={{flex: '1', marginRight: '2px',padding: '1em .5em'}}>
              {mode && mode.substring(0,6)==='ticket' && <RptTicketBody menu={mode.substring(7)} module={module} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
              {mode && mode.substring(0,6)==='people' && <RptPeopleBody menu={mode.substring(7)} module={module} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
              {mode && mode.substring(0,8)==='customer' && <RptCustomerBody menu={mode.substring(9)} module={module} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
              {mode && mode.substring(0,4)==='csat' && <RptCsatBody menu={mode.substring(5)} module={module} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
              {mode && mode.substring(0,3)==='sla' && <RptSlaBody menu={mode.substring(4)} module={module} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
              {mode && mode.substring(0,3)==='ojk' && <RptOJKBody menu={mode.substring(4)} module={module} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
              {/* <p>
                <b>{this.props.mode}</b>
                <br />
                REPORT LIST DOWNLOAD AND SAVE AS EXCEL, Agent's Workload | Customer Satisfaction | Request Deflected | Request Resolved | Time To Response | Time To Resolution | SLA Success Rate | Support Request / Customer Request by Category | Bug Report | Create New Custom Report
              </p> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ServiceReportsBody