import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import ReportingBrowse from '../../libraries/service/ReportingBrowse'
import ReportingForm from '../../libraries/service/ReportingForm'
import ReportingSearch from '../../libraries/service/ReportingSearch'



class ReportingBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        reportId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, reportId: id, clientId: clientId})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        reportId: 0,
      });
    }

    render() {
      const {mode, reportId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div>
          <ReportingSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
        
          {<ReportingBrowse mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} openModal={this.props.openModal} closeModal={this.closeModal.bind(this)} />}
          {/* {mode==2 && <ReportingForm mode={mode} reportId={reportId} clientId={clientId} setMode={this.setMode.bind(this)} />}
          {mode==3 && <ReportingForm mode={mode} reportId={0} clientId={0} setMode={this.setMode.bind(this)} />} */}
          {mode==2 && <ModalContainer headless size='large' content={<ReportingForm mode={mode} reportId={reportId} clientId={clientId} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
          {mode==3 && <ModalContainer headless size='large' content={<ReportingForm mode={mode} reportId={0} clientId={0} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
        </div>
      )
    }
}

export default ReportingBody
