
import React from 'react';
import ReactDOM from 'react-dom';

import { Link } from 'react-router-dom';
import { Accordion, Tab, Input, Card, Form, Select, Radio, Label, Container, Divider, Dropdown, Grid, Header, Image, List, Menu, Segment, Button, Icon, Table, Popup } from 'semantic-ui-react'

import { calendarOptions, uptimeOptions, responseOptions, resolveOptions, popupMerchant, popupPersonal, popupCampaign, popupModule, sessionOptions, lockedOptions, resultOptions, limitOptions, delimiterOptions, cdrOptions, transactionOptions, factorOptions, sequenceOptions, periodOptions, fieldTargetOptions, fieldCdrOptions, fieldMdrOptions, fieldTrxOptions, gatewayOptions, dispatchOptions, ratioOptions, attemptOptions, achievedOptions, queuedOptions, calldelayOptions, addonsOptions, paramOptions, hourOptions, passwordOptions, accountOptions, processOptions, backupOptions, restoreOptions, housekeepingOptions, haOptions, watchdogOptions, disasterOptions, privilegeOptions, groupOptions, widgetOptions, yesnoOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'
import MenuTabular from '../common/MenuTabular'
import ModalContainer from '../common/ModalContainer'
import { imagePath, urlUpload } from '../common/_lib'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { ImageUploadField, AudioUploadField, InputField, LabelInputField, CheckboxField, SelectField, RadioField, TextAreaField, WysiwygField, NumberField, SliderField, LabelNumberField, LabelSelectField } from '../validation/componentrsui'
import { required, phoneNumberID, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { listParams } from "../../actions/halo/masterAction"
import { resetSetting, getSetting, updateSetting } from "../../actions/halo/settingAction"
import Omniwidget from '../omniwidget/Omniwidget';
import { rateOmniwidget } from '../../actions/flow/omniwidgetAction';

//CONNECT REDUX STORE
const selector = formValueSelector('SettingChannelForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    settingStore: state.settingStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    listParams:(objParam) => dispatch(listParams(objParam)),

    resetSetting:(objParam) => dispatch(resetSetting(objParam)),
    getSetting:(objParam) => dispatch(getSetting(objParam)),
    updateSetting:(objParam) => dispatch(updateSetting(objParam)),
  }
};
const panesChannel = (param)=>{
  const channels= [];
  param.svcChannels && param.svcChannels.map(item=>{
    channels.push(item.value)
  })
  return([
    {
      menuItem: { key: '0', icon: 'microphone', content: 'Voice' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '1', icon: 'mobile', content: 'Widget' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '2', icon: 'sticky note outline', content: 'SMS' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '3', icon: 'envelope outline', content: 'Email' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '7', icon: 'whatsapp', content: 'Whatsapp' },
      render: () => 
      <></>
    },
    // (param.group=='admin' || channels.indexOf('Telegram')>=0) && {
    //   menuItem: { key: '8', icon: 'telegram', content: 'Telegram' },
    //   render: () => 
    //   <></>
    // },
    {
      menuItem: { key: '4', icon: 'twitter', content: 'Twitter' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '5', icon: 'facebook', content: 'Facebook' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '6', icon: 'instagram', content: 'Instagram' },
      render: () => 
      <></>
    },
  ])
};

class SettingChannelForm extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      menu: 'halopagi', //security, halopagi, ovisone
      module: 'channel',
      showSetting: 'channel',

      tabChannel: 0,
      tabReport: 0,
      tabModule: 0,

      settingId: 1,
      
      showSegment: null,
      showHandling: null,

      mapHandlings: new Map(),
      mapSegments: new Map(),

      param: null,
      value: null,
    }
  }

  componentDidMount() {
    //console.log('!!! DID MOUNT PROPS: ', this.props)
    
    this.props.resetSetting();
    this.props.getSetting({settingId: this.props.settingId})

    this.props.listParams({clientId: this.props.settingId})
  }

  componentDidUpdate(prevProps) {
    const { settingId, mapHandlings, mapSegments }= this.state
    var isUpdated= false;

    const { data }= (this.props.settingStore.getSetting!=undefined ? this.props.settingStore.getSetting : {})
    if (settingId!=this.props.settingId) {
      mapHandlings.clear();
      mapSegments.clear();

      // console.log(data.settingId, this.props.settingId)
      this.props.getSetting({settingId: this.props.settingId})
      this.props.listParams({clientId: this.props.settingId})

      isUpdated= true;
    }

    if (data && data.settingId==this.props.settingId && data.ticketHandlings && data.ticketHandlings.length>0 && mapHandlings.size==0) {
      data.ticketHandlings.map((handling, i)=>{
        if (!mapHandlings.get(i)) {
          mapHandlings.set(i, new Map());
        }
        handling.params && handling.params.map(item=>{
          mapHandlings.get(i).set(item.key, item.value)
        })
      })
      isUpdated= true;
    }

    if (data && data.settingId==this.props.settingId && data.ticketSegments && data.ticketSegments.length>0 && mapSegments.size==0) {
      data.ticketSegments.map((segment, i)=>{
        if (!mapSegments.get(i)) {
          mapSegments.set(i, new Map());
        }
        segment.params && segment.params.map(item=>{
          mapSegments.get(i).set(item.key, item.value)
        })
      })
      isUpdated= true;
    }

    if (isUpdated) {
      this.setState({
        settingId: this.props.settingId,
        mapHandlings: mapHandlings,
        mapSegments: mapSegments,
      })
    }
    
  }

  resetForm() {
    const { reset, mode } = this.props
    reset();
  }

  onSubmit(values) {
    const { mode} = this.props
    const {mapHandlings, mapSegments}= this.state;
    const ticketHandlingParams= [], ticketSegmentParams= [];

    mapHandlings.forEach((value, key, map)=>{
      const params= []
      mapHandlings.get(key) && mapHandlings.get(key).forEach((value1, key1, map1)=>{
        params.push({
          key: key1,
          value: value1,
        })
      })

      ticketHandlingParams[key]= params;
    })

    mapSegments.forEach((value, key, map)=>{
      const params= []
      mapSegments.get(key) && mapSegments.get(key).forEach((value1, key1, map1)=>{
        params.push({
          key: key1,
          value: value1,
        })
      })

      ticketSegmentParams[key]= params;
    })

    if(mode==2) { //UPDATE
      this.props.updateSetting({...values, ...{
        ticketHandlingParams: ticketHandlingParams,
        ticketSegmentParams: ticketSegmentParams,
      }})
    }
  }

  setMode(v, e, d) {
    // console.log(v, e, d)
    if (v=='channel') {
      this.setState({tabChannel: d.panes[d.activeIndex].menuItem.key})
    } else if (v=='report') {
      this.setState({tabReport: d.activeIndex})
    } else {
      this.setState({tabModule: d.activeIndex})
    }
  }

  oauthClick(v) {
    // console.log(v)
    const { oauth }= this.state; 

    switch (v) {
      case 'gmail':
        // window.open('http://localhost:1115/oauth/api/v1/google?token='+ localStorage.getItem('tokenAuth'));
        window.open('https://crm.halopagi.com/proxy/oauth/api/v1/google?token='+ localStorage.getItem('tokenAuth'));
      break;
      case 'twitter':
        // window.open('http://localhost:1115/oauth/api/v1/twitter?token='+ localStorage.getItem('tokenAuth'));
        window.open('https://crm.halopagi.com/proxy/oauth/api/v1/twitter?token='+ localStorage.getItem('tokenAuth'));
      break;
      case 'facebook':
        // window.open('http://localhost:1115/oauth/api/v1/facebook?token='+ localStorage.getItem('tokenAuth'));
        window.open('https://crm.halopagi.com/proxy/oauth/api/v1/facebook?token='+ localStorage.getItem('tokenAuth'));
      break;
      case 'instagram':
        // window.open('http://localhost:1115/oauth/api/v1/instagram?token='+ localStorage.getItem('tokenAuth'));
        window.open('https://crm.halopagi.com/proxy/oauth/api/v1/instagram?token='+ localStorage.getItem('tokenAuth'));
      break;
      default:
    }
  }

  onChangeInput(e, v) {
    // console.log(e, v.value)
    this.setState({
      param: v.value,
    })
  }

  onChangeSelect(e, v) {
    // console.log(e, v)
    this.setState({
      value: v.value,
    })
  }

  render() {
    const { menu, tabChannel, tabReport, tabModule, showSetting, module }= this.state
    const { error, handleSubmit, pristine, reset, submitting, group, mode, screenWidth, screenHeight }=this.props
    const { data }= (this.props.settingStore.getSetting!=undefined ? this.props.settingStore.getSetting : {})
    const { progress, code, text }= (this.props.settingStore.updateSetting!=undefined ? this.props.settingStore.updateSetting : {})
    
    const { svcCampaigns, svcRatios, svcChannels }= this.props.masterStore.listParams && this.props.masterStore.listParams.data ? this.props.masterStore.listParams.data : {}

    return(<>
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          
            <Header as='h4' style={{fontWeight: 'bold'}} color={(showSetting=='channel' && 'blue') || null}>
              <Header.Content>
                Communication Channel
                <Header.Subheader></Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            {showSetting=='channel' && <>
              <MenuTabular panes={panesChannel({svcChannels: svcChannels, group: group})} style={{width: '100%', marginBottom: '-1.2em'  }} renderActiveOnly={true} onTabChange={this.setMode.bind(this, 'channel')} />
              { tabChannel==0 && <>
                <Segment>
                  <Header as='h5'>
                    <Header.Content>
                      Call Behaviour
                    </Header.Content>
                  </Header>
                  <Form.Group widths='16'>
                    <Field name='voiceCallRate' component={SelectField}
                    width={4}
                    options={svcRatios}
                    label='Call Ratio'
                    placeholder='Default Call Ratio' />
                    <Field name='voiceAttemptMax' component={SelectField}
                    width={4}
                    options= {attemptOptions}
                    type='number'
                    label='Max. Attempt'
                    placeholder='Max Call Attempt' />
                    <Field name='voiceTimeoutQueue' component={SelectField}
                    width={4}
                    options= {queuedOptions}
                    type='number'
                    label='Queueing Period'
                    placeholder='Waiting Queue' />
                    <Field name='achievedLockedDay' component={SelectField}
                    width={4}
                    options= {achievedOptions}
                    type='number'
                    label='Locked Period'
                    placeholder='Locked Days' />
                  </Form.Group>
                </Segment>
              </>}

              { tabChannel==1 && <>
                <Segment>
                  <Header as='h5'>
                    <Header.Content>
                      IVR Behaviour
                    </Header.Content>
                  </Header>
                  <Form.Group widths='equal'>
                    <Field name='ivrAttemptMax' component={SelectField}
                    options={attemptOptions}
                    type='number'
                    label='Max Call Attempt'
                    placeholder='Max Call Attempt' />
                    <Field name='ivrAttemptDelay' component={SelectField}
                    options={calldelayOptions}
                    label='Call Delay Attempt'
                    placeholder='Call Delay Attempt' />
                    <Field name='ivrCallRate' component={SelectField}
                    // type='number'
                    options={data && data.userId==0 ? ratioOptions : svcRatios}
                    // caption='Call Rate'
                    label='Default Call Ratio'
                    placeholder='Default Call Ratio' />
                    <Field name='ivrConcurrentCall' component={LabelInputField}
                    type='number'
                    caption='Concurrent Call'
                    label='Call'
                    placeholder='Concurrent Call' />
                  </Form.Group>

                  <Divider />
                  <Form.Group widths='16'>
                    <Field name='ivrMaxEngaged' component={SelectField}
                    width={4}
                    options= {limitOptions}
                    label='Max. Call Duration'
                    placeholder='Call duration limit' />
                    <Field name='ivrPrioritySequences' component={SelectField}
                    width={12}
                    options={sequenceOptions}
                    multiple
                    label='Call Sequence Order'
                    placeholder='Call Sequence Order' />
                  </Form.Group>
                </Segment>
              </>}

              { tabChannel==2 && <>
                <Segment style={{paddingBottom: '7em'}}>
                  <Header as='h5'>
                    <Icon name='warning sign' />
                    <Header.Content>
                      Your configuration is handled by system.
                      <Header.Subheader>Currently there is no custom configuration required for SMS delivery.</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Segment>
              </>}

              {tabChannel==3 && <>
                <Segment>
                  <Grid columns={1}>
                    <Grid.Column>
                      <Header as='h5'>
                        <Header.Content>
                          Email Delivery Method
                        </Header.Content>
                      </Header>
                      <Form.Group widths='16'>
                        <Field name='mailGateway' component={SelectField}
                        width={8}
                        options={gatewayOptions('mail')}
                        label='Email Gateway'
                        placeholder='Email Gateway' />
                        {(this.props.mailGateway=='smtp' || this.props.mailGateway=='postal') && 
                        <Field name='mailSender' component={InputField}
                        width={8}
                        label='Sender Address'
                        placeholder='Sender Address Full Name <sender@address.com>' />
                        }
                      </Form.Group>
                      {this.props.mailGateway=='smtp' && 
                        <Form.Group widths='16'>
                          <Field name='smtpUser' component={InputField}
                          width={4}
                          label='User Name'
                          placeholder='User' />
                          <Field name='smtpPasswd' component={InputField}
                          width={4}
                          label='Password'
                          placeholder='Password' />
                          <Field name='smtpHost' component={InputField}
                          width={6}
                          label='Hostname'
                          placeholder='Hostname' />
                          <Field name='smtpPort' component={InputField}
                          width={2}
                          label='Port'
                          placeholder='Port' />
                        </Form.Group>
                      }
                      {this.props.mailGateway=='gmail' && <>
                        <Header as='h5'>
                          <Header.Content>
                            Gmail will be used as email platform for sending and receiving, through open authentication, access token is required to be refreshed in one hour each or it would be expired.
                          </Header.Content>
                        </Header>
                      </>}
                    </Grid.Column>
                  </Grid>
                </Segment>
              </>}

              { tabChannel==4 && <>
              <Segment>
                <Grid columns={1}>
                  <Grid.Column>
                    <Header as='h4' style={{fontWeight: 'bold'}}>
                      <Header.Content>
                        Click to integrate to your
                        <Button type='button' color='teal' style={{marginLeft: '1em', textAlign: 'left'}} onClick={this.oauthClick.bind(this, 'twitter')} icon>
                          <Icon name='twitter' /> Twitter
                        </Button>
                        <Header.Subheader>Twitter Integrated and Interactive Stream Channel</Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Header as='h5'>
                      <Header.Content>
                        Manage your relationship through twitter channel, direct message and post your twit information from single board interactive messaging platform.
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                </Grid>
              </Segment>
              </>}
              { tabChannel==5 && <>
              <Segment>
                <Grid columns={1}>
                  <Grid.Column>
                    <Header as='h4' style={{fontWeight: 'bold'}}>
                      <Header.Content>
                        Click to integrate to your
                        <Button type='button' color='blue' style={{marginLeft: '1em', textAlign: 'left'}} onClick={this.oauthClick.bind(this, 'facebook')} icon>
                          <Icon name='facebook' /> Facebook
                        </Button>
                        <Header.Subheader>Facebook Integrated and Interactive Stream Channel</Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Header as='h5'>
                      <Header.Content>
                        Manage your relationship through facebook business channel, direct message and post your content from single board interactive messaging platform.
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                </Grid>
              </Segment>
              </>}
              { tabChannel==6 && <>
              <Segment>
                <Grid columns={1}>
                  <Grid.Column>
                    <Header as='h4' style={{fontWeight: 'bold'}}>
                      <Header.Content>
                        Click to integrate to your
                        <Button type='button' color='orange' style={{marginLeft: '1em', textAlign: 'left'}} onClick={this.oauthClick.bind(this, 'instagram')} icon>
                          <Icon name='instagram' /> Instagram
                        </Button>
                        <Header.Subheader>Instagram Integrated and Interactive Stream Channel</Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Header as='h5'>
                      <Header.Content>
                        Manage your relationship through instagram business channel, comment and post your content from single board interactive messaging platform.
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                </Grid>
              </Segment>
              </>}

              { tabChannel==7 && <>
              {group=='admin' && <>
              <Segment>
                <Header as='h5'>
                  Gateway Configuration
                </Header>
                <Form.Group widths='16'>
                  <Field name='waGateway' component={SelectField}
                  width={8}
                  options={gatewayOptions('wa')}
                  label='WA Gateway'
                  placeholder='Gateway' />
                  <Field name='waCredential' component={InputField}
                  width={8}
                  label='WA Credential'
                  placeholder='Credential / Secret' />
                </Form.Group>
                {this.props.waGateway=='Mobile' && <>
                  <Form.Group widths='equal'>
                    <Field name='waAttemptMax' component={LabelInputField}
                    type= 'number'
                    label='Sent'
                    caption='Sent Attempt'
                    placeholder='Max. attempt for sending message' />
                    <Field name='waSenderDelay' component={LabelInputField}
                    type='number'
                    label='Sec'
                    caption='Sender Delay'
                    placeholder='Delay interval foreach senders between messages' />
                    <Field name='waTargetInterval' component={LabelInputField}
                    type='number'
                    label='Day'
                    caption='Target Interval'
                    placeholder='Unique Target Interval' />
                    {/* <Field name='waSentInterval' component={LabelInputField}
                    type='number'
                    label='Sec'
                    caption='Sending Interval'
                    placeholder='Message Sending Interval' /> */}
                    <Field name='waClearInterval' component={LabelInputField}
                    type='number'
                    label='Sec'
                    caption='Clearing Interval'
                    placeholder='Clearing Interval' />
                  </Form.Group>

                  <Form.Group widths='equal' style={{marginTop: '3em'}}>
                    <Field name='waMobile_1' component={InputField}
                    label='Mobile Sender #1'
                    validate= {[phoneNumberID]}
                    placeholder='Mobile number as sender' />
                    <Field name='waMobile_2' component={InputField}
                    label='Mobile Sender #2'
                    validate= {[phoneNumberID]}
                    placeholder='[Optional] Mobile number as sender' />
                    <Field name='waMobile_3' component={InputField}
                    label='Mobile Sender #3'
                    validate= {[phoneNumberID]}
                    placeholder='[Optional] Mobile number as sender' />
                    <Field name='waMobile_4' component={InputField}
                    label='Mobile Sender #4'
                    validate= {[phoneNumberID]}
                    placeholder='[Optional] Mobile number as sender' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='waMobile_5' component={InputField}
                    readOnly
                    label='Mobile Sender #5'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_6' component={InputField}
                    readOnly
                    label='Mobile Sender #6'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_7' component={InputField}
                    readOnly
                    label='Mobile Sender #7'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_8' component={InputField}
                    readOnly
                    label='Mobile Sender #8'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='waMobile_9' component={InputField}
                    readOnly
                    label='Mobile Sender #9'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_10' component={InputField}
                    readOnly
                    label='Mobile Sender #10'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_11' component={InputField}
                    readOnly
                    label='Mobile Sender #11'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_12' component={InputField}
                    readOnly
                    label='Mobile Sender #12'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='waMobile_13' component={InputField}
                    readOnly
                    label='Mobile Sender #13'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_14' component={InputField}
                    readOnly
                    label='Mobile Sender #14'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_15' component={InputField}
                    readOnly
                    label='Mobile Sender #15'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_16' component={InputField}
                    readOnly
                    label='Mobile Sender #16'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='waMobile_17' component={InputField}
                    readOnly
                    label='Mobile Sender #17'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_18' component={InputField}
                    readOnly
                    label='Mobile Sender #18'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_19' component={InputField}
                    readOnly
                    label='Mobile Sender #19'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                    <Field name='waMobile_20' component={InputField}
                    readOnly
                    label='Mobile Sender #20'
                    validate= {[phoneNumberID]}
                    placeholder='Optional number' />
                  </Form.Group>

                  <Divider hidden />
                  <Form.Group widths='equal'>
                    <Field name='waMobileFarmServer' component={TextAreaField}
                    label='Mobile Sender Farm Server (6281390001001, 6281390001002, ...)'
                    placeholder='[Optional] Mobile number as sender from Halo Pagi! farm server, delimited by comma' />
                  </Form.Group>
                </>}
                {this.props.waGateway=='Gateway' && 
                <Form.Group widths='equal'>
                  <Field name='waEndpoint' component={InputField}
                  label='Provider Endpoint'
                  placeholder='Provider Endpoint' />
                  <Field name='waWebhook' component={InputField}
                  label='Webhook Endpoint'
                  placeholder='Webhook Endpoint' />
                </Form.Group>}
              </Segment>
              </>}
              {group!='admin' && <>
              <Segment style={{paddingBottom: '7em'}}>
                <Header as='h5'>
                  <Icon name='warning sign' />
                  <Header.Content>
                    Your configuration is handled by system.
                    <Header.Subheader>Currently there is no custom configuration required for Whatsapp delivery.</Header.Subheader>
                  </Header.Content>
                </Header>
              </Segment>
              </>}
              </>}

              { tabChannel==8 && <>
              <Segment>
                <Header as='h5'>
                  TELEGRAM GATEWAY
                </Header>
              </Segment>

              <Segment>
                <Header as='h5'>
                  TELEGRAM BEHAVIOUR
                </Header>
              </Segment>
              </>}
            </>}
          

          <Segment>
              {(progress==1 || progress==2)  && <DismissableMessage hide='auto' style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Setting' : 'Insert Setting') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={code + ' ' + text} />}
              
              <div style={{textAlign: 'right'}}>
                <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
                <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
              </div>
          </Segment>
        </Form >
      </div>
    </>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.settingStore.getSetting!=undefined && state.settingStore.getSetting.progress==1 ? state.settingStore.getSetting.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      userId: selector(state, 'userId'),
      apiKey: selector(state, 'apiKey'),
      isPopup: selector(state, 'isPopup'),
      isCallback: selector(state, 'isCallback'),
      isIntegrated: selector(state, 'isIntegrated'),
      
      collectionStageQty: selector(state, 'collectionStageQty'),
      restructuringStageQty: selector(state, 'restructuringStageQty'),
      telesaleStageQty: selector(state, 'telesaleStageQty'),
      verificationStageQty: selector(state, 'verificationStageQty'),

      reportCdrPeriod: selector(state, 'reportCdrPeriod'),
      reportMdrPeriod: selector(state, 'reportMdrPeriod'),
      reportTrxPeriod: selector(state, 'reportTrxPeriod'),
      
      smsGateway: selector(state, 'smsGateway'),
      waGateway: selector(state, 'waGateway'),
      mailGateway: selector(state, 'mailGateway'),

      ticketRemarkQty: selector(state, 'ticketRemarkQty') || '0',
      ticketHandlingQty: selector(state, 'ticketHandlingQty') || '0',
      ticketSegmentQty: selector(state, 'ticketSegmentQty') || '0',

      administrationPrivilegeQty: selector(state, 'administrationPrivilegeQty') || '0',
      repositoryPrivilegeQty: selector(state, 'repositoryPrivilegeQty') || '0',
      collectionPrivilegeQty: selector(state, 'collectionPrivilegeQty') || '0',
      conversationPrivilegeQty: selector(state, 'conversationPrivilegeQty') || '0',
      dashboardPrivilegeQty: selector(state, 'dashboardPrivilegeQty') || '0',

      widgetTheme: selector(state, 'widgetTheme'),
      widgetWidth: selector(state, 'widgetWidth'),
      widgetSize: selector(state, 'widgetSize'),
      widgetPosition: selector(state, 'widgetPosition'),
      widgetBackground: selector(state, 'widgetBackground'),
      widgetBanner: selector(state, 'widgetBanner'),
      
      feedbackRatingQty: selector(state, 'feedbackRatingQty') || '0',

      totalQtyCollected: selector(state, 'totalQtyCollected'),
      percentQtyCollected: selector(state, 'percentQtyCollected'),
      totalAmtCollected: selector(state, 'totalAmtCollected'),
      percentAmtCollected: selector(state, 'percentAmtCollected'),
      quantityAttempted: selector(state, 'quantityAttempted'),
      percentAttempted: selector(state, 'percentAttempted'),
      quantityContacted: selector(state, 'quantityContacted'),
      percentContacted: selector(state, 'percentContacted'),
      quantityPromised: selector(state, 'quantityPromised'),
      percentPromised: selector(state, 'percentPromised'),
      quantityPaid: selector(state, 'quantityPaid'),
      percentPaid: selector(state, 'percentPaid'),

      minIncentiveScore: selector(state, 'minIncentiveScore'),
      calcIncentivePeriod: selector(state, 'calcIncentivePeriod'),
      amountIncentiveFactor: selector(state, 'amountIncentiveFactor'),
      percentIncentiveFactor: selector(state, 'percentIncentiveFactor'),
      fromIncentiveFactor: selector(state, 'fromIncentiveFactor'),

      
    }),
    { load: getSetting }
  )
  (reduxForm({
    form: 'SettingChannelForm',	// a unique identifier for this form
  })(SettingChannelForm)
  )
)
