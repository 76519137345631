import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import MyTicketBrowse from '../../libraries/service/MyTicketBrowse'
import MyTicketSearch from '../../libraries/service/MyTicketSearch'
import MyTicketFormCreate from '../../libraries/service/MyTicketFormCreate'
import MyTicketFormUpdate from '../../libraries/service/MyTicketFormUpdate'



class MyTicketBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        ticketId: 0,
      };
    }

    setMode(mode, id, param) {
      this.setState({mode: mode, ticketId: id, param: param})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        ticketId: 0,
      });
    }

    render() {
      const {mode, ticketId, param}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div>
          <MyTicketSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
        
          {<MyTicketBrowse mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} openModal={this.props.openModal} closeModal={this.closeModal.bind(this)} />}
          {/* {mode==2 && <MyTicketFormCreate mode={mode} ticketId={ticketId} param={param} setMode={this.setMode.bind(this)} />}
          {mode==3 && <MyTicketFormCreate mode={mode} ticketId={0} param={0} setMode={this.setMode.bind(this)} />} */}
          {mode==2 && <ModalContainer size='fullscreen' content={<MyTicketFormCreate mode={mode} ticketId={ticketId} screenWidth={screenWidth} screenHeight={screenHeight-125} param={param} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
          {(this.props.mode==3 || mode==3) && <ModalContainer size='fullscreen' content={<MyTicketFormCreate mode={mode} ticketId={0} screenWidth={screenWidth} screenHeight={screenHeight-125} param={0} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
          {mode==4 && <ModalContainer size='fullscreen' content={<MyTicketFormUpdate mode={mode} ticketId={ticketId} screenWidth={screenWidth} screenHeight={screenHeight-125} param={param} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
          
        </div>
      )
    }
}

export default MyTicketBody
