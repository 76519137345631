import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
} from 'semantic-ui-react'

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { format, parse } from "date-fns"
import {  } from "../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { handlingTicket } from "../../actions/service/ticketAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    ticketStore: state.ticketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handlingTicket:(objParam) => dispatch(handlingTicket(objParam)),
  }
}

class TickethandlingHeader extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remark: false,
      expand: false,
    }
  }

  render() {
    //console.log(this.props.ticketStore.handlingTicket)
    const { expand, remark }= this.state
    const { screenWidth, screenHeight, modal }= this.props
    const { data, search, paging }= (this.props.ticketStore.handlingTicket!==undefined ? this.props.ticketStore.handlingTicket : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    const header= this.props.params;
    // console.log(header)

    if (header && header.ticketNo) {
      return (
        <div style={{padding: '1.5em 1em'}}>
          <Segment style={{background: '#fafafa', padding: '2em 1.5em'}}>
            <Grid columns={16}>
              <Grid.Row style={{padding: '.5em 0'}}>
                <Grid.Column width={10}>
                  <Header as='h5' style={{margin: 0}}>
                    <Header.Content>
                      <Header.Subheader><b>Ticket No.</b></Header.Subheader>
                      <b>{header && header.ticketNo}</b>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header as='h5' style={{margin: 0}}>
                    <Header.Content>
                      <Header.Subheader><b>Priority</b></Header.Subheader>
                      <b>{header && header.priority}</b>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header as='h5' style={{margin: 0}}>
                    <Header.Content>
                      <Header.Subheader><b>Ticket Status</b></Header.Subheader>
                      <b>{header && header.remark}</b>
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{padding: '.5em 0'}}>
                <Grid.Column width={10}>
                  <Header as='h5' style={{margin: '0'}}>
                    <Header.Content>
                      <Header.Subheader><b>Customer</b></Header.Subheader>
                      <b>{header && header.customer && (header.customer.name || header.customer.company)}</b>
                    </Header.Content>
                  </Header>

                  <Divider style={{margin: '.3em 0'}} hidden />
                  <Header as='h5' style={{margin: '0'}}>
                    <Header.Content>
                      <Header.Subheader><b>Subject</b></Header.Subheader>
                      <b>{header && header.subject}</b>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header as='h5' style={{margin: 0}}>
                    <Header.Content>
                      <Header.Subheader><b>Opened by User</b></Header.Subheader>
                      <b>{header.openStamp!=null && format(parse(header.openStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm ')}</b>
                      {header.openStamp!=null && format(parse(header.openStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd/MMM yyyy')}
                    </Header.Content>
                  </Header>

                  <Divider style={{margin: '.3em 0'}} hidden />
                  <Header as='h5' style={{margin: 0}}>
                    <Header.Content>
                      <Header.Subheader><b>Last Action by User</b></Header.Subheader>
                      <b>{header.actionStamp!=null && format(parse(header.actionStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm ')}</b>
                      {header.actionStamp!=null && format(parse(header.actionStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd/MMM yyyy')}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{padding: '.5em 0'}}>
                <Grid.Column width={10}>
                  <Header as='h5' style={{margin: '0'}}>
                    <Header.Content>
                      <Header.Subheader><b>Information</b></Header.Subheader>
                      <b>{header && header.information}</b>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header as='h5' style={{margin: '0'}}>
                    <Header.Content>
                      <Header.Subheader><b>Segmentation</b></Header.Subheader>
                      <b>{header && header.segment}</b>
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </div>
      )
    } else {
      return (<div></div>)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TickethandlingHeader)
