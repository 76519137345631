import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Dropdown, Select } from 'semantic-ui-react'

import { parse, format, endOfMonth } from "date-fns"

import BarRechart from '../../chart/BarRechart';
import LineRechart from '../../chart/LineRechart';
import AreaRechart from '../../chart/AreaRechart';

import { connect } from "react-redux"
import { ticketHistorical } from "../../../actions/service/historicalAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    historicalStore: state.historicalStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ticketHistorical:(objParam) => dispatch(ticketHistorical(objParam)),
  }
}

class TicketHistorical extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      stamp: null,
      grouping: 'monthly', //daily: split by hours, monthly: split by days, yearly: split by month

      chart: 'bar', //bar, line, area, composed, pie, radial, radar, 

      // barData: [
      //   {name: '01', totNew: 1, totOpen: 5, totProgress: 15, totOverdue: 1, totClosed: 5}, 
      //   {name: '02', totNew: 2, totOpen: 6, totProgress: 9, totOverdue: 1, totClosed: 5}, 
      //   {name: '03', totNew: 3, totOpen: 7, totProgress: 5, totOverdue: 1, totClosed: 5}, 
      //   {name: '04', totNew: 4, totOpen: 8, totProgress: 7, totOverdue: 1, totClosed: 5}, 
      // ],
      // barLegend: [
      //   {id: 'totNew', name: 'Tot. New'},
      //   {id: 'totOpen', name: 'Tot. Open'},
      //   {id: 'totProgress', name: 'Tot. Progress'},
      //   {id: 'totEscalate', name: 'Tot. Escalate'},
      //   {id: 'totOverdue', name: 'Tot. Overdue'},
      //   {id: 'totClosed', name: 'Tot. Closed'},
      // ]
    };
  }
  
  componentDidMount() {
    this.props.ticketHistorical();

    if (this.props.chart && this.props.chart!=this.state.chart) {
      this.setState({
        chart: this.props.chart,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const dates=[], barData= [], lineData=[], composedData=[];
    const { data, search }= (this.props.historicalStore.ticketHistorical!==undefined ? this.props.historicalStore.ticketHistorical : {})

    // const eom= search && search.groupingDate ? format(endOfMonth(parse(search.groupingDate.substr(0,11), 'yyyy-MM-dd', new Date())), 'd') : null;
    // const _stamp= (search && search.groupingDate && (search.grouping=='Daily' ? search.grouping +'-'+ search.groupingDate.substr(8,2) : search.grouping +'-'+ search.groupingDate.substr(5,2))) || '';

    if (!prevProps && this.props.chart && this.props.chart!=this.state.chart) {
      this.setState({
        chart: this.props.chart,
      })
    }
    if (data && data.data && data.stamp && (!this.state.stamp || this.state.stamp!=data.stamp || !prevState || prevState.grouping!=this.state.grouping)) {
      const dataMap= new Map();
      data.data.map(item=>{
        Object.keys(item).forEach(e => {
          dataMap.set(e, item[e]);
        })
      })

      if (this.state.grouping=='monthly') {
        var startDate = parse(format(new Date(), 'yyyyMM') +'01', 'yyyyMMdd', new Date()) //parse('20210101', 'yyyyMMdd', new Date())
        var endDate= parse(format(new Date(), 'yyyyMM') + '01', 'yyyyMMdd', new Date()) //parse('20210101', 'yyyyMMdd', new Date())
        endDate.setMonth(endDate.getMonth()+1)
    
        while (startDate<endDate) {
          dates.push(format(startDate, 'dd'))
          startDate.setDate(startDate.getDate() + 1)
        }
    
        dates.map(dd=>{
          barData.push({
            name: dd,
            ...(dataMap.get(dd) || {})
          })
        })
      } else {
        for (var i=0;i<12;i++) {
          barData.push({
            name: i<9 ? '0'+ (i+1) : (i+1),
            ...(dataMap.get(i<9 ? '0'+ (i+1) : (i+1).toString()) || {})
          })
        }
      }

      // console.log(barData, dates, data.data)
      this.setState({
        stamp: data.stamp,
        barData: barData,
        lineData: lineData,
        composedData: composedData,
        legend: data.legend || [],
      });
    }
  }

  onChangeSelect(k,v,x) {
    this.setState({
      grouping: v.value,
    })
  }
  
  render() {

    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <div style={{display: 'flex'}}>
          <div style={{flex: 1}}>
            <Header as='h5'>
              <Icon name='chart bar' style={{fontSize: '1em'}} />
              <Header.Content>
                Ticket Distribution
                <b style={{paddingLeft: '.5em'}}><i>{this.state.stamp && format(new Date(this.state.stamp),'dd/MMM yyyy')}</i></b>
                <Header.Subheader>Ticket distribution in monthly and on daily basis.</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div style={{width: '15em'}}>
          <Select onChange={this.onChangeSelect.bind(this)} defaultValue='monthly' style={{margin: '-.2em 0 0 0', padding: '.5em .8em .3em .8em', minHeight: '2.2em'}}
            placeholder='Data Stream'
            fluid
            selection
            options={[
              {key: 0, value: 'monthly', text: 'Monthly', icon: 'calendar alternate outline'},
              {key: 1, value: 'yearly', text: 'Yearly', icon: 'calendar alternate'},
            ]}
          />
          </div>
        </div>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em', minHeight: this.props.height}}>
        {this.state.chart=='bar' && <BarRechart mode={this.props.mode} height={this.props.height || 250} yAxis={false} dashed={this.props.dashed} label={this.props.label} data={this.state.barData} legend={this.state.legend}/>}
        {this.state.chart=='line' && <LineRechart mode={this.props.mode} height={this.props.height || 250} yAxis={false} dashed={this.props.dashed} label={this.props.label} data={this.state.barData} legend={this.state.legend}/>}
        {this.state.chart=='area' && <AreaRechart mode={this.props.mode} height={this.props.height || 250} yAxis={false} dashed={this.props.dashed} label={this.props.label} data={this.state.barData} legend={this.state.legend}/>}
      </Segment>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TicketHistorical)