export default function pipelineReducer (state = {}, action) {
  switch (action.type) {
    case 'PIPELINE_RESET': {
        return({
            ...state,
            severityPipeline: {},
            updatePipeline: {},
            createPipeline: {},
        })
    }

    case 'PIPELINE_PRIORITY_PENDING': {
      return({...state, priorityPipeline: {progress: 0}})
    }
    case 'PIPELINE_PRIORITY_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, priorityPipeline: {...api.priorityPipeline.response, progress: 1, payload: action.payload}})
            return({...state, priorityPipeline: {progress: 2, payload: action.payload}})
        } else {
            return({...state, priorityPipeline: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PIPELINE_PRIORITY_REJECTED': {
        return({...state, priorityPipeline: {progress: 2, payload: action.payload}})
    }
    
    case 'PIPELINE_SEVERITY_PENDING': {
        return({...state, severityPipeline: {progress: 0}})
    }
    case 'PIPELINE_SEVERITY_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, severityPipeline: {...api.severityPipeline.response, progress: 1, payload: action.payload}})
            return({...state, severityPipeline: {progress: 2, payload: action.payload}})
        } else {
            return({...state, severityPipeline: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PIPELINE_SEVERITY_REJECTED': {
        return({...state, severityPipeline: {progress: 2, payload: action.payload}})
    }

    case 'PIPELINE_SLA_PENDING': {
      return({...state, slaPipeline: {progress: 0}})
    }
    case 'PIPELINE_SLA_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, slaPipeline: {...api.slaPipeline.response, progress: 1, payload: action.payload}})
            return({...state, slaPipeline: {progress: 2, payload: action.payload}})
        } else {
            return({...state, slaPipeline: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PIPELINE_SLA_REJECTED': {
        return({...state, slaPipeline: {progress: 2, payload: action.payload}})
    }

    case 'STATISTIC_PRIORITY_PENDING': {
      return({...state, priorityStatistic: {progress: 0}})
    }
    case 'STATISTIC_PRIORITY_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, priorityStatistic: {...api.priorityStatistic.response, progress: 1, payload: action.payload}})
            return({...state, priorityStatistic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, priorityStatistic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATISTIC_PRIORITY_REJECTED': {
        return({...state, priorityStatistic: {progress: 2, payload: action.payload}})
    }
    
    case 'STATISTIC_SEVERITY_PENDING': {
      return({...state, severityStatistic: {progress: 0}})
    }
    case 'STATISTIC_SEVERITY_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, severityStatistic: {...api.severityStatistic.response, progress: 1, payload: action.payload}})
            return({...state, severityStatistic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, severityStatistic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATISTIC_SEVERITY_REJECTED': {
        return({...state, severityStatistic: {progress: 2, payload: action.payload}})
    }
    
    case 'STATISTIC_SLA_PENDING': {
      return({...state, slaStatistic: {progress: 0}})
    }
    case 'STATISTIC_SLA_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, slaStatistic: {...api.slaStatistic.response, progress: 1, payload: action.payload}})
            return({...state, slaStatistic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, slaStatistic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATISTIC_SLA_REJECTED': {
        return({...state, slaStatistic: {progress: 2, payload: action.payload}})
    }
    
    case 'STATISTIC_REMARK_PENDING': {
      return({...state, remarkStatistic: {progress: 0}})
    }
    case 'STATISTIC_REMARK_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, remarkStatistic: {...api.remarkStatistic.response, progress: 1, payload: action.payload}})
            return({...state, remarkStatistic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, remarkStatistic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATISTIC_REMARK_REJECTED': {
        return({...state, remarkStatistic: {progress: 2, payload: action.payload}})
    }

    case 'STATISTIC_CLUSTER_PENDING': {
      return({...state, clusterStatistic: {progress: 0}})
    }
    case 'STATISTIC_CLUSTER_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, clusterStatistic: {...api.clusterStatistic.response, progress: 1, payload: action.payload}})
            return({...state, clusterStatistic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, clusterStatistic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATISTIC_CLUSTER_REJECTED': {
        return({...state, clusterStatistic: {progress: 2, payload: action.payload}})
    }

    case 'STATISTIC_CATEGORY_PENDING': {
      return({...state, categoryStatistic: {progress: 0}})
    }
    case 'STATISTIC_CATEGORY_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, categoryStatistic: {...api.categoryStatistic.response, progress: 1, payload: action.payload}})
            return({...state, categoryStatistic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, categoryStatistic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATISTIC_CATEGORY_REJECTED': {
        return({...state, categoryStatistic: {progress: 2, payload: action.payload}})
    }
    
    case 'STATISTIC_SERVICE_PENDING': {
      return({...state, serviceStatistic: {progress: 0}})
    }
    case 'STATISTIC_SERVICE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, serviceStatistic: {...api.serviceStatistic.response, progress: 1, payload: action.payload}})
            return({...state, serviceStatistic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, serviceStatistic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATISTIC_SERVICE_REJECTED': {
        return({...state, serviceStatistic: {progress: 2, payload: action.payload}})
    }
    
    case 'STATISTIC_CLASSIFICATION_PENDING': {
      return({...state, classificationStatistic: {progress: 0}})
    }
    case 'STATISTIC_CLASSIFICATION_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, classificationStatistic: {...api.classificationStatistic.response, progress: 1, payload: action.payload}})
            return({...state, classificationStatistic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, classificationStatistic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATISTIC_CLASSIFICATION_REJECTED': {
        return({...state, classificationStatistic: {progress: 2, payload: action.payload}})
    }
    
    case 'STATISTIC_RESOLUTION_PENDING': {
      return({...state, resolutionStatistic: {progress: 0}})
    }
    case 'STATISTIC_RESOLUTION_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, resolutionStatistic: {...api.resolutionStatistic.response, progress: 1, payload: action.payload}})
            return({...state, resolutionStatistic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, resolutionStatistic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATISTIC_RESOLUTION_REJECTED': {
        return({...state, resolutionStatistic: {progress: 2, payload: action.payload}})
    }

    case 'STATISTIC_REASON_PENDING': {
      return({...state, reasonStatistic: {progress: 0}})
    }
    case 'STATISTIC_REASON_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, reasonStatistic: {...api.reasonStatistic.response, progress: 1, payload: action.payload}})
            return({...state, reasonStatistic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, reasonStatistic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATISTIC_REASON_REJECTED': {
        return({...state, reasonStatistic: {progress: 2, payload: action.payload}})
    }

    case 'STATISTIC_INTERACTION_PENDING': {
      return({...state, interactionStatistic: {progress: 0}})
    }
    case 'STATISTIC_INTERACTION_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, interactionStatistic: {...api.interactionStatistic.response, progress: 1, payload: action.payload}})
            return({...state, interactionStatistic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, interactionStatistic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATISTIC_INTERACTION_REJECTED': {
        return({...state, interactionStatistic: {progress: 2, payload: action.payload}})
    }

    case 'STATISTIC_CHANNEL_PENDING': {
      return({...state, channelStatistic: {progress: 0}})
    }
    case 'STATISTIC_CHANNEL_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, channelStatistic: {...api.channelStatistic.response, progress: 1, payload: action.payload}})
            return({...state, channelStatistic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, channelStatistic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATISTIC_CHANNEL_REJECTED': {
        return({...state, channelStatistic: {progress: 2, payload: action.payload}})
    }
    
    case 'STATISTIC_SATISFACTION_PENDING': {
      return({...state, satisfactionStatistic: {progress: 0}})
    }
    case 'STATISTIC_SATISFACTION_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, satisfactionStatistic: {...api.satisfactionStatistic.response, progress: 1, payload: action.payload}})
            return({...state, satisfactionStatistic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, satisfactionStatistic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATISTIC_SATISFACTION_REJECTED': {
        return({...state, satisfactionStatistic: {progress: 2, payload: action.payload}})
    }
    
    case 'STATISTIC_WORKLOAD_PENDING': {
      return({...state, workloadStatistic: {progress: 0}})
    }
    case 'STATISTIC_WORKLOAD_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, workloadStatistic: {...api.workloadStatistic.response, progress: 1, payload: action.payload}})
            return({...state, workloadStatistic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, workloadStatistic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATISTIC_WORKLOAD_REJECTED': {
        return({...state, workloadStatistic: {progress: 2, payload: action.payload}})
    }

    case 'STATISTIC_PERFORMANCE_PENDING': {
      return({...state, performanceStatistic: {progress: 0}})
    }
    case 'STATISTIC_PERFORMANCE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, performanceStatistic: {...api.performanceStatistic.response, progress: 1, payload: action.payload}})
            return({...state, performanceStatistic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, performanceStatistic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATISTIC_PERFORMANCE_REJECTED': {
        return({...state, performanceStatistic: {progress: 2, payload: action.payload}})
    }

    case 'STATISTIC_WORKSPACE_PENDING': {
      return({...state, workspaceStatistic: {progress: 0}})
    }
    case 'STATISTIC_WORKSPACE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, workspaceStatistic: {...api.workspaceStatistic.response, progress: 1, payload: action.payload}})
            return({...state, workspaceStatistic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, workspaceStatistic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATISTIC_WORKSPACE_REJECTED': {
        return({...state, workspaceStatistic: {progress: 2, payload: action.payload}})
    }

    case 'STATISTIC_LIVEFEED_PENDING': {
      return({...state, livefeedStatistic: {progress: 0}})
    }
    case 'STATISTIC_LIVEFEED_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, livefeedStatistic: {...api.livefeedStatistic.response, progress: 1, payload: action.payload}})
            return({...state, livefeedStatistic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, livefeedStatistic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATISTIC_LIVEFEED_REJECTED': {
        return({...state, livefeedStatistic: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}