// @flow
/* eslint-disable no-unused-vars */

import React from 'react';

import SliderProgress from '../common/SliderProgress'
import InputMask from 'react-input-mask';
import InputDatePicker from '../common/InputDatePicker'
import InputNumberFormat from '../common/InputNumberFormat';

import EditorWysiwyg from '../common/EditorWysiwyg'
import ImageUploadFilepond from '../common/ImageUploadFilepond'
import FileUploadFilepond from '../common/FileUploadFilepond'
import DataUploadFilepond from '../common/DataUploadFilepond'
import AudioUploadFilepond from '../common/AudioUploadFilepond'
// import VideoUploadFilepond from '../common/VideoUploadFilepond'


import {
  Icon,
  Header,
  Form,
  Button,
  Label,
  Input as InputComponent,
  Radio as RadioComponent,
  Checkbox as CheckboxComponent,
  Select as SelectComponent,
  TextArea as TextAreaComponent,
  Dropdown as DropdownComponent,
  Search as SearchComponent,
} from 'semantic-ui-react';

/*
semanticFormField.propTypes = {
  as: PropTypes.any,
  input: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object
};

InputProps.propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onDrop: PropTypes.func,
  onDragStart: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.any,
};

FieldProps.propTypes = {
  input: InputProps,
  meta: PropTypes.object,
  required: PropTypes.bool,
  width: PropTypes.string,
  label: PropTypes.string,
  inline: boolean,
  defaultChecked: PropTypes.bool
};


type InputProps = {
  checked?: boolean,
  name: string,
  onBlur: { (eventOrValue: Event | any): void },
  onChange: { (eventOrValue: Event | any): void },
  onDrop: { (event: Event): void },
  onDragStart: { (event: Event): void },
  onFocus: { (event: Event): void },
  value: any
};

type FieldProps = {
  input: InputProps,
  meta: {
    error?: any,
    touched: boolean
  },
  required?: boolean,
  width?: string,
  label?: string,
  inline?: boolean,
  defaultChecked?: boolean
};

type OptionsFieldProps = FieldProps & {
  options: {
    text: string,
    value: string
  }[]
};

type RangeFieldProps = FieldProps & {
  min: number,
  max: number
};
*/

/*BACKEND ONLY*/
export const WysiwygField = (
  {
    input,
    label,
    required,
    width,
    height,
    html,
    inline,
    screenWidth,
    modal,
    meta: { touched, error, warning },
    ...rest
  })=> {
    return(
      <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
        {label && <label>{label}</label>}
        <EditorWysiwyg required={required} width={width} height={height}  {...input} {...rest} screenWidth={screenWidth} modal={modal} html={html} />
        {touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}
      </Form.Field>
    )
  };

export const ImageUploadField = (
  {
    input,
    label,
    required,
    width,
    height,
    ratio,
    fixedRatio,
    aspectRatio,
    srcImage,
    cropImage,
    readOnly,
    style,
    meta: { touched, error, warning },
    ...rest
  })=> {
    return(
      <div style={{margin: 'auto', width: width, borderRadius: '.5em', border: '0px solid rgba(100,100,100,.1)', ...style }}>
        {label && <label>{label}</label>}
        <ImageUploadFilepond readOnly={readOnly} required={required}  {...rest} aspectRatio={aspectRatio} ratio={ratio} width={width} height={height} srcImage={input.value}  {...input} {...rest}/>
        {touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}
      </div>
    )
  };

export const FileUploadField = (
  {
    input,
    label,
    server,
    required,
    width,
    inline,
    meta: { touched, error, warning },
    ...rest
  })=> {
    return(
      <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
        {label && <label>{label}</label>}
        <div style={{fontSize: '1em', color: '#fff', display: 'fixed', padding: '0em .5em', lineHeight: '4em', marginBottom: '-3.5em'}}>{input.value||' None'}</div>
        <FileUploadFilepond required={required} server={server} {...input} {...rest}/>
        <div>{touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}</div>
      </Form.Field>
    )
  };

export const DataUploadField = (
  {
    input,
    label,
    server,
    required,
    width,
    inline,
    meta: { touched, error, warning },
    ...rest
  })=> {
    return(
      <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
        {label && <label>{label}</label>}
        <div style={{fontSize: '1.15em', fontWeight: 'bold', color: '#000', display: 'fixed', padding: '0em 0em', lineHeight: '2em', marginBottom: '-2.5em'}}>{input.value||' None'}</div>
        <DataUploadFilepond required={required} server={server} {...input} {...rest}/>
        {touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}
      </Form.Field>
    )
  };


export const AudioUploadField = (
  {
    input,
    label,
    required,
    server,
    width,
    height,
    inline,
    meta: { touched, error, warning },
    ...rest
  })=> {
    return(
      <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
        {label && <label>{label}</label>}
        <div style={{fontSize: '1.15em', fontWeight: 'bold', color: '#000', display: 'fixed', padding: '0em 0em', lineHeight: '2em', marginBottom: '-2.5em'}}>{input.value||' None'}</div>
        <AudioUploadFilepond required={required} server={server} {...input} {...rest}/>
        {touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}
      </Form.Field>
    )
  };

// export const VideoUploadField = (
//   {
//     input,
//     label,
//     required,
//     width,
//     height,
//     inline,
//     meta: { touched, error, warning },
//     ...rest
//   })=> {
//     return(
//       <div style={{margin: 'auto', minHeight: height, width: width, borderRadius: '.5em', border: '1px solid rgba(100,100,100,.1)' }}>
//         {label && <label>{label}</label>}
//         <VideoUploadFilepond required={required} height= {height} width= {width}  {...input} {...rest}/>
//         {touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}
//       </div>
//     )
//   };

export const SliderField = ({
  input,
  label,
  required,
  width,
  height,
  inline,
  meta: { touched, error, warning },
  ...rest
})=> {
  return(
    <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
      {label && <label >{label}</label>}
      <SliderProgress   {...input} {...rest} defaultValue={input.value}/>
      {touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}
    </Form.Field>
  )
};

export const DatePickerField = (
{
  input,
  label,
  required,
  width,
  height,
  inline,
  meta: { touched, error, warning },
  ...rest
})=> {
  return(
    <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
      {label && <label >{label}</label>}
      <div className='datepickerfield'>
        <InputDatePicker required={required} {...input} {...rest} />
      </div>
      <div>{touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}</div>
    </Form.Field>
  )
};

export const LabelDatePickerField = (
  {
    input,
    label,
    icon,
    required,
    width,
    height,
    inline,
    caption,
    disabled,
    meta: { touched, error, warning },
    ...rest
  })=> {
    return(
      // <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
      //   {label && <label >{label}</label>}
      //   <div className='datepickerfield'>
      //     <InputDatePicker required={required} {...input} {...rest} />
      //   </div>
      //   <div>{touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}</div>
      // </Form.Field>
      <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
        {caption && <label>{caption}</label>}    
        <div className='ui fluid labeled' style={{display: 'flex'}}>
          <div className='ui label' style={{minWidth: '5em', color: disabled ? '#999' : '#555', fontSize: '1em', padding: '.8em .8em 0em'}}>
            {icon && <Icon name={icon} style={{fontSize: '1.3em'}} />} {label}
          </div>
          <div className='datepickerfield' style={{flex: '1', marginLeft: '-.4em'}}>
            <InputDatePicker required={required} {...input} {...rest} />
          </div>
        </div>
        <div>{touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}</div>
      </Form.Field>
    )
  };
  
export const NumberField = ({
  input,
  label,
  required,
  width,
  inline,
  meta: { touched, error, warning },
  ...rest
}) => (
  <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline} >
    {label && <label>{label}</label>}
    <InputNumberFormat {...input} {...rest} />
    {touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}
  </Form.Field>
);

export const LabelNumberField = ({
  input,
  label,
  icon,
  caption,
  disabled,
  required,
  width,

  inline,
  meta: { touched, error, warning },
  ...rest
}) => (
  <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
    {caption && <label>{caption}</label>}    
    <div className='ui fluid labeled input' style={{display: 'flex'}}>
      <div className='ui label' style={{minWidth: '5em', color: disabled ? '#999' : '#555', height: '2.8em'}}>
        {icon && <Icon name={icon} style={{fontSize: '1.3em'}} />} {label}
      </div>
      <InputNumberFormat {...input} {...rest} style={{height: '2.8em'}} disabled={disabled} />
    </div>
    {touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}
    {!caption && !error && !warning && <em>{{...rest}.placeholder}</em>}
  </Form.Field>
  
);


export const MaskedField = ({
  input,
  label,
  required,
  width,
  inline,
  meta: { touched, error, warning },
  ...rest
}) => (
  <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline} >
    {label && <label>{label}</label>}
    <InputMask {...input} {...rest} 
    // mask="+4\9 99 999 99" 
    // maskChar=" "
    />
    <div>
      {touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}
      {input.value && !label && !error && !warning && <em>{{...rest}.placeholder}</em>}
    </div>
  </Form.Field>
);

export const LabelField = ({
  label,
  caption,
  options,
  input,
  width,
}) => {
  return (
  <Form.Field width={width}>
    {label && <label>{label}</label>}
    <Header style={{margin: '0'}}>
      <Header.Content>{options!=undefined  ? options[input.value=='' ? 0 : input.value] : input.value}</Header.Content>
      <Header.Subheader>{caption}</Header.Subheader>
    </Header>
  </Form.Field>
)
};

export const InputField = ({
  complexity,
  input,
  label,
  required,
  width,
  inline,
  options,
  meta: { touched, error, warning },
  ...rest
}) => (
  <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
    {label && <label>{label}</label>}
    <InputComponent required={required} {...input} {...rest} fluid />
    {complexity && <div style={{display: 'flex', margin: '.2em 0'}}>
      <div style={{flex: 1, height: '2px', margin: '0 .1em', background: checkComplexity(input.value)>0 ? '#0063e3' : '#ccc'}} />
      <div style={{flex: 1, height: '2px', margin: '0 .1em', background: checkComplexity(input.value)>1 ? '#0063e3' : '#ccc'}} />
      <div style={{flex: 1, height: '2px', margin: '0 .1em', background: checkComplexity(input.value)>2 ? '#0063e3' : '#ccc'}} />
      <div style={{flex: 1, height: '2px', margin: '0 .1em', background: checkComplexity(input.value)>3 ? '#0063e3' : '#ccc'}} />
    </div>}
    <div>
      {touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}
      {input.value && !label && !error && !warning && <em>{{...rest}.placeholder}</em>}
    </div>
  </Form.Field>
);

export const Input = ({ input, required, meta: { touched, error, warning }, ...rest }) => (
  <InputComponent required={required} {...input} {...rest} fluid />
);

export const LabelInputField = ({input, icon, caption, label, required, width, meta: { touched, error, warning }, ...rest}) => ( //eslint-disable-line
  <Form.Field error={!!(touched && error)} required={required} width={width}>
    {caption && <label style={{paddingBottom: '.5em'}}>{caption}</label>}
    <div className='ui fluid labeled input' style={{display: 'flex'}}>
      <div className='ui label label' style={{height: '2.8em', minWidth: '5em'}}>
        {icon && <Icon name={icon} style={{fontSize: '1.3em'}} />}{label}
      </div>
      <div style={{flex: '1'}}>
        <InputComponent required={required} {...input} {...rest} fluid/>
      </div>
    </div>
    <div>
      {touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}
      {input.value && !caption && !error && !warning && <em>{{...rest}.placeholder}</em>}
    </div>
  </Form.Field>
)


export const TextAreaField = ({
  input,
  label,
  required,
  rows,
  width,
  inline,
  meta: { touched, error, warning },
  ...rest
}) => (
  <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
    {label && <label>{label}</label>}
    <TextAreaComponent required={required} {...input} {...rest} rows={rows || 2} />
    <div>
      {touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}
      {input.value && !label && !error && !warning && <em>{{...rest}.placeholder}</em>}
    </div>
  </Form.Field>
);

export const TextArea = ({ input, required, meta: { touched, error, warning }, ...rest }) => (
  <TextAreaComponent required={required} {...input} {...rest} />
);

export const SelectField = ({
  input,
  label,
  multiple,
  required,
  width,
  inline,
  readOnly,
  options,
  value,
  meta: { touched, error, warning },
  style,
  modal,
  ...rest
}) => (
  <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
    {label && <label>{label}</label>}
    <div style={{display: 'flex'}}>
      <div style={{flex: 1}}>
        <SelectComponent style={{...(multiple ? {minHeight: '2.8em'} : {minHeight: '2.8em'}), ...style}}
          search
          fluid
          clearable={!readOnly}
          disabled={readOnly}
          multiple={multiple}
          // defaultValue={value || []}
          value={input.value || (multiple ? [] : input.value)}
          required={required}
          options={options || []}
          onChange={(event, data) => input.onChange(data.value)}
          {...rest}
        />
      </div>
      {modal && <div style={{paddingLeft: '.2em'}}>
        <Button size='big' type='button' icon={modal.icon || 'plus'} 
          onClick={modal.openModal ? modal.openModal : null}
        />
      </div>}
    </div>
    <div>
      {touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}
      {input.value && !label && !error && !warning && <em>{{...rest}.placeholder}</em>}
    </div>
  </Form.Field>
);

export const LabelSelectField = ({
  input,
  label,
  caption,
  multiple,
  required,
  width,
  inline,
  readOnly,
  options,
  value,
  style,
  icon,
  modal,
  meta: { touched, error, warning },
  ...rest
}) => (
  <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
    {caption&&<label>{caption}</label>}
    <div className='ui fluid labeled input' style={{display: 'flex'}}>
      <div className='ui label label' style={{height: '2.8em', minWidth: '5em'}}>
        {icon && <Icon name={icon} style={{fontSize: '1.3em'}} />} {label}
      </div>
      <div style={{flex: '1'}}>
        <SelectComponent style={{borderLeft: 'none', borderRadius: '0 .3em .3em 0', ...(multiple ? {} : {minHeight: '2.8em'}), ...(style||{})}}
          search
          fluid
          clearable={!readOnly}
          disabled={readOnly}
          multiple={multiple}
          // defaultValue={value || []}
          value={input.value || (multiple ? [] : null)}
          required={required}
          options={options || []}
          onChange={(event, data) => input.onChange(data.value)}
          {...rest}
        />
      </div>
      {modal && <div style={{paddingLeft: '.2em'}}>
        <Button size='big' type='button' icon={modal.icon || 'plus'} 
          onClick={modal.openModal ? modal.openModal : null}
        />
      </div>}
    </div>
    <div>
      {touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}
      {!label && !caption && input.value && !error && !warning && <em>{{...rest}.placeholder}</em>}
    </div>
  </Form.Field>
);

export const Select = ({
  input,
  required,
  options,
  meta: { touched, error, warning },
  ...rest
}) => (
  <SelectComponent
    search
    value={input.value}
    required={required}
    options={options || []}
    onChange={(event, data) => input.onChange(data.value)}
    {...rest}
  />
);

export const ToggleField = ({ input, label, defaultChecked, width }) => (
  <Form.Field
    control={RadioComponent}
    toggle
    label={label}
    checked={!!input.value}
    defaultChecked={defaultChecked}
    onClick={(event, data) => input.onChange(data.checked)}
    width={width}
  />
);

export const Toggle = ({ input, label, defaultChecked }) => (
  <RadioComponent
    toggle
    label={label}
    checked={!!input.value}
    defaultChecked={defaultChecked}
    onClick={(event, data) => input.onChange(data.checked)}
  />
);

export const Radio = ({ input, label, meta: { touched, error, warning }, ...rest }) => (
  <RadioComponent
    label={label}
    checked={!!input.value}
    onClick={(event, data) => input.onChange(data.checked)}
    {...rest}
  />
);

export const RadioField = ({ input, label, width, meta: { touched, error, warning }, ...rest }) => (
  <Form.Field
    control={RadioComponent}
    label={label}
    width={width}
    checked={!!input.value}
    onClick={(event, data) => input.onChange(data.checked)}
    {...rest}
  />
);

export const Checkbox = ({ 
  input, 
  label, 
  readOnly,
  meta: { touched, error, warning }, 
  ...rest 
}) => (
  <div>
    <CheckboxComponent
      label={<label style={{color: (touched && error && '#9f3b38') || 'inherit'}}>{label} {input.value}</label>}
      checked={input.value==1 ? true : false}
      // onClick={(event, data) => input.onChange(data.checked==true ? data.checked : undefined)}
      onClick={readOnly ? null : (event, data) => input.onChange(data.checked==true ? data.checked : false)}
      {...rest}
    />
    <div>{(touched && ((error && <div className='error'><i>! {error}</i></div>) || (warning && <span className='warning'><i>{warning}</i></span>)))}</div>
  </div>
);

export const CheckboxField = ({
  input,
  label,
  width,
  caption,
  inline,
  required,
  readOnly,
  meta: { touched, error, warning },
  ...rest
}) => (
  <Form.Field error={(touched && error)} required={required} width={width} inline={inline}>
    {caption && <label>{caption}</label>}
    <CheckboxComponent
      label={<label style={{color: (touched && error && '#9f3b38') || 'inherit'}}><div>{label}</div></label>}
      readOnly={readOnly}
      width={width}
      checked={input.value==1 ? true : false}
      // onClick={readOnly ? null : (event, data) => input.onChange(data.checked==true ? 1 : 0)}
      onClick={readOnly ? null : (event, data) => input.onChange(data.checked==true ? data.checked : false)}
      {...rest}
    />
    <div>{(touched && ((error && <div className='error'><i>! {error}</i></div>) || (warning && <span className='warning'><i>{warning}</i></span>)))}</div>
  </Form.Field>
);

export const RangeField = ({
  input,
  label,
  width,
  inline,
  min,
  max,
  required,
  meta: { touched, error, warning },
  ...rest
}) => (
  <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
    <label>
      {label} : {input.value}
    </label>
    <input type="range" required={required} min={min} max={max} {...input} {...rest} />
    <div>{touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}</div>
  </Form.Field>
);

export const Range = ({
  input,
  min,
  max,
  required,
  meta: { touched, error, warning },
  ...rest
}) => (
  <input type="range" required={required} min={min} max={max} {...input} {...rest} />
);

export const DropdownField = ({
  input,
  label,
  multiple,
  image,
  icon,
  search,
  allowAdditions,
  isFetching,
  placeholder,
  required,
  width,
  inline,
  options,
  readOnly,
  meta: { touched, error, warning },
  style,
  ...rest
}) => {
  const _options= options || []
  return(
    <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline} style={{...(style||{})}}>
      {label && <label>{label}</label>}
      <DropdownComponent
        fluid
        clearable={!readOnly}
        search
        selection
        allowAdditions={allowAdditions}
        additionLabel={''}
        additionPosition={'top'}
        multiple={multiple}
        placeholder={placeholder}
        //disabled={isFetching}
        //loading={isFetching}
        style={{...(style||{})}}
        options= {Array.isArray(_options) ? _options : []} //{options || []}
        value={input.value}
        required={required}
        onAddItem={(e, { value }) => {
            // _options= [{ text: value, value }, ..._options],
            _options.push({
              key: value, 
              text: value, 
              value: value,
              // ...(icon ? {icon: typeof icon==='string' || icon instanceof String ? icon : 'image'} : {}),
              // ...(image ? {image: { avatar: true, src: typeof image==='string' || image instanceof String ? image : 'https://react.semantic-ui.com/images/avatar/small/elliot.jpg' }} : {}),
            })
        }}
        //onChange={this.handleChange}
        //onSearchChange={this.handleSearchChange}
        onChange={(event, data) => {
          // console.log(data.value)
          input.onChange(data.value)
        }}
        {...rest}
      />
      <div>{touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}</div>
    </Form.Field>
  )
};

export const LabelDropdownField = ({
  input,
  label,
  multiple,
  image,
  icon,
  search,
  allowAdditions,
  isFetching,
  placeholder,
  required,
  width,
  inline,
  caption,
  options,
  readOnly,
  meta: { touched, error, warning },
  style,
  ...rest
}) => {
  const _options= options || []
  return(
    <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
      {caption&&<label>{caption}</label>}
      <div className='ui fluid labeled input' style={{display: 'flex'}}>
        <div className='ui label label' style={{height: '2.8em', minWidth: '5em'}}>
          {label}
        </div>
        <div style={{flex: '1'}}>
          <DropdownComponent style={{...(style||{}), borderLeft: 'none', borderRadius: '0 .3em .3em 0', ...(multiple ? {} : {minHeight: '2.8em'})}}
            fluid
            clearable={!readOnly}
            search
            selection
            allowAdditions={allowAdditions}
            additionLabel={''}
            additionPosition={'top'}
            multiple={multiple}
            placeholder={placeholder}
            //disabled={isFetching}
            //loading={isFetching}
            options= {Array.isArray(_options) ? _options : []} //{options || []}
            value={input.value}
            required={required}
            onAddItem={(e, { value }) => {
                // _options= [{ text: value, value }, ..._options],
                _options.push({
                  key: value, 
                  text: value, 
                  value: value,
                  ...(icon ? {icon: typeof icon==='string' || icon instanceof String ? icon : 'image'} : {}),
                  ...(image ? {image: { avatar: true, src: typeof image==='string' || image instanceof String ? image : 'https://react.semantic-ui.com/images/avatar/small/elliot.jpg' }} : {}),
                })
            }}
            //onChange={this.handleChange}
            //onSearchChange={this.handleSearchChange}
            onChange={(event, data) => input.onChange(data.value)}
            {...rest}
          />
        </div>
      </div>
      <div>{touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}</div>
    </Form.Field>
  )
};

export const InsertableDropdownField = ({
  input,
  label,
  multiple,
  image,
  icon,
  search,

  allowAdditions,
  isFetching,
  queryServer,
  
  placeholder,
  required,
  width,
  inline,
  options,
  readOnly,
  meta: { touched, error, warning },
  style,
  ...rest
}) => {
  const _options= options || []; //default and additional options
  var __options= (input.value && input.value.options) || []; //selected options only
  var _stamp= null;
  return(
    <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline} style={{...(style||{})}}>
      {label && <label>{label}</label>}
      <DropdownComponent
        fluid
        clearable={!readOnly}
        search
        selection
        multiple
        allowAdditions
        additionLabel={''}
        additionPosition={'top'}
        placeholder={placeholder}
        disabled={isFetching}
        loading={isFetching}
        style={{...(style||{})}}
        options= {[...__options, ..._options]} //{options || []}
        value={(input.value && input.value.value) || []}
        // value={input.value || []}
        required={required}
        onAddItem={(e, { value }) => {
            // _options.push({
            //   key: value, 
            //   text: value, 
            //   value: value,
            //   // ...(icon ? {icon: typeof icon==='string' || icon instanceof String ? icon : 'image'} : {}),
            //   // ...(image ? {image: { avatar: true, src: typeof image==='string' || image instanceof String ? image : 'https://react.semantic-ui.com/images/avatar/small/elliot.jpg' }} : {}),
            // })
            __options.push({
              key: value, 
              text: value, 
              value: value,
              // ...(icon ? {icon: typeof icon==='string' || icon instanceof String ? icon : 'image'} : {}),
              // ...(image ? {image: { avatar: true, src: typeof image==='string' || image instanceof String ? image : 'https://react.semantic-ui.com/images/avatar/small/elliot.jpg' }} : {}),
            })
        }}
        onSearchChange={(e, v)=>{
          _stamp= new Date().getTime();

          queryServer && setTimeout(()=>{
            if (new Date().getTime()-_stamp>=1000) {
              // console.log('QUERY TO SERVER', v.searchQuery)
              queryServer({search:{keyword: v.searchQuery}})
            }
          }, 1000)
        }}

        onChange={(event, data) => {
          const __opts= []
          const _keys= new Map()
          __options.map(row=>{
            if (!_keys.get(row.value) && data.value.indexOf(row.value)>=0) {
              _keys.set(row.value, 1)
              __opts.push(row)
            }
          })
          _options.map(row=>{
            if (!_keys.get(row.value) && data.value.indexOf(row.value)>=0) {
              _keys.set(row.value, 1)
              __opts.push(row)
            }
          })
          __options= __opts; // set validated selected options

          input.onChange({
            options: __options,
            value: data.value,
          })
          // input.onChange(data.value)

          // console.log('DATA VALUE', data.value)
          // console.log('OPTIONS VALUE',  _options)
          // console.log('SELECTED OPTIONS VALUE',  __options)
        }}
        {...rest}
      />
      <div>{touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}</div>
    </Form.Field>
  )
};

export const Dropdown = ({
  input,
  required,
  options,
  meta: { touched, error, warning },
  ...rest
}) => (
  <DropdownComponent
    search
    value={input.value}
    required={required}
    options={options || []}
    onChange={(event, data) => input.onChange(data.value)}
    {...rest}
  />
);

export const SearchField = ({
  input,
  icon,
  label,

  isFetching,
  queryServer,
  results,
  
  // onResultSelect CALL BACK FOR DATA SETTING SUCH AS ID
  resultRenderer, //CUSTOM DROPDOWN LIST RENDER

  placeholder,
  required,
  width,
  inline,
  readOnly,
  meta: { touched, error, warning },
  style,
  ...rest
}) => {
  var _stamp= null;
  return(
    <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline} style={{...(style||{})}}>
      {label && <label>{label}</label>}
      <SearchComponent
        results= {null}
        icon= {icon || 'dropdown'}
        placeholder={placeholder}
        // disabled={isFetching}
        // loading={isFetching}
        style={{...(style||{})}}
        value={input.value}
        required={required}
        noResultsMessage= {<div style={{display:'flex'}}>
          <div style={{flex: 1}}>
            <span style={{fontWeight: 'normal', fontSize: '1em'}}>Result is not available, create new ?</span>
          </div>
          <div>
            <Icon name='plus square' color='red' style={{fontSize: '1.3em', cursor: 'pointer', margin: '0'}} />
          </div>
        </div>}

        onSearchChange={(e, v)=>{
          // console.log('onSearchChange', v.value, v)
          input.onChange(v.value)

          // _stamp= new Date().getTime();
          // queryServer && setTimeout(()=>{
          //   if (new Date().getTime()-_stamp>=1000) {
          //     // console.log('QUERY TO SERVER', v.searchQuery)
          //     queryServer({search:{keyword: v.searchQuery}})
          //   }
          // }, 1000)
        }}
        onResultSelect={(e, v)=>{
          // console.log('onResultSelect', v.result)
          input.onChange(v.result.title)
        }}
        onSelectionChange={(e, v)=>{
          console.log('onSelectionChange', v.result)
        }}
        resultRenderer={resultRenderer}
        // resultRenderer={(v)=>{
        //   console.log('resultRenderer', v)
        // }}

        // onChange={(e, v) => { // TIDAK DIPAKAI DI SEARCH
        //   console.log('onChange', e, v)
        //   input.onChange(v.value)
        // }}
        {...rest}
      />
      <div>{touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}</div>
    </Form.Field>
  )
};

export const UploadField = ({
  label,
  input,
  required,
  width,
  inline,
  meta: { touched, error, warning },
  ...rest
}) => {
  delete input.value; //Delete value from input
  return (
    <Form.Field error={touched && error} required={required} width={width} inline={inline}>
      {label && <label>{label}</label>}
      <InputComponent
        type="file"
        {...input}
        {...rest}
      />
      <div>{touched && ((error && <span className='error'><i>! {error}</i></span>) || (warning && <span className='warning'><i>{warning}</i></span>))}</div>
    </Form.Field>
  )
};

export const Upload = ({ input, required, meta: { touched, error, warning }, ...rest }) => {
  delete input.value;
  return(
    <InputComponent
      required={required}
      type="file"
      {...input}
      {...rest}
    />
  )
};

function checkComplexity(v) {
  // if (!v || v.length<10) {
  //   return 0;
  // }
  // if (new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})').test(v)) { //strong password
  //   return 4;
  // } else if (new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))').test(v)) { //medium password
  //   return 3;
  // } else { //weak password
  //   return 1;
  // }

  // Initialize variables
  var strength = 0;
  var tips = "";

  // Check password length
  if (v.length < 8) {
    tips += "Make the password longer. ";
  } else {
    strength += 1;
  }

  // Check for mixed case
  if (v.match(/[a-z]/) && v.match(/[A-Z]/)) {
    strength += 1;
  } else {
    tips += "Use both lowercase and uppercase letters. ";
  }

  // Check for numbers
  if (v.match(/\d/)) {
    strength += 1;
  } else {
    tips += "Include at least one number. ";
  }

  // Check for special characters
  if (v.match(/[^a-zA-Z\d]/)) {
    strength += 1;
  } else {
    tips += "Include at least one special character. ";
  }

  // Return results
  // if (strength===1) {
  //   return "Easy to guess. " + tips;
  // } else if (strength===2) {
  //   return "Medium difficulty. " + tips;
  // } else if (strength===3) {
  //   return "Difficult. " + tips;
  // } else {
  //   return "Extremely difficult. " + tips;
  // }
  return strength;
}