import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Label, Popup } from 'semantic-ui-react'
import ModalContainer from '../../libraries/common/ModalContainer';

import HistoricalSearch from '../../libraries/service/HistoricalSearch'
import HistoricalDashboard from '../../libraries/service/HistoricalDashboard';

class HistoricalBody extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      userId: 0,
    };
  }

  setMode(mode) {
    this.setState({mode: mode})
  }

  closeModal(params) {
    this.setState({mode: ''})
  }

  render() {
    const {mode, modal}= this.state
    const {screenWidth, screenHeight}= this.props
    
    return(
      <div>
        <div style={{display: 'flex', margin: '1.5em 0 0 1em'}}>
          <div style={{padding: '0 .1em 0 0'}}>
            <Popup content='Ticket Trend' position='top left' inverted trigger={
              <Label as='a' onClick={this.props.openWindow.bind(this, 'historical', {mode: 'ticket'})} color={(!this.props.params || (this.props.params && !this.props.params.mode) || (this.props.params && this.props.params.mode=='ticket')) ? 'yellow' : null}>
                TICKET POPULATION
              </Label>
            } />
          </div>
          <div style={{padding: '0 .1em'}}>
            <Popup content='Classification Trend' position='top left' inverted trigger={
              <Label as='a' onClick={this.props.openWindow.bind(this, 'historical', {mode: 'classification'})} color={this.props.params && this.props.params.mode=='classification' ? 'yellow' : null}>
                CLASSIFICATION
              </Label>
            } />
          </div>
          <div style={{padding: '0 .1em'}}>
            <Popup content='Satisfaction Trend' position='top left' inverted trigger={
              <Label as='a' onClick={this.props.openWindow.bind(this, 'historical', {mode: 'csat'})} color={this.props.params && this.props.params.mode=='csat' ? 'yellow' : null}>
                SATISFACTION
              </Label>
            } />
          </div>
          <div style={{padding: '0 .1em'}}>
            <Popup content='Customer Trend' position='top left' inverted trigger={
              <Label as='a' onClick={this.props.openWindow.bind(this, 'historical', {mode: 'cluster'})} color={this.props.params && this.props.params.mode=='cluster' ? 'yellow' : null}>
                CUSTOMER CLUSTER
              </Label>
            } />
          </div>
          <div style={{flex: 1, padding: '0 0 0 0.1em'}}>
            
          </div>
        </div>

        <HistoricalSearch stream setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />
        <HistoricalDashboard  dashboard={this.state.dashboard} mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={this.props.params} />

      </div>
    )
  }
}

export default HistoricalBody
