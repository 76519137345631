export default function slaReducer (state = {}, action) {
  switch (action.type) {
    case 'SLA_RESET': {
        return({
            ...state,
            getSla: {},
            updateSla: {},
            createSla: {},
        })
    }

    case 'SLA_BROWSE_PENDING': {
      return({...state, browseSla: {progress: 0}})
    }
    case 'SLA_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseSla: {...api.browseSla.response, progress: 1, payload: action.payload}})
            return({...state, browseSla: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseSla: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SLA_BROWSE_REJECTED': {
        return({...state, browseSla: {progress: 2, payload: action.payload}})
    }
    
    case 'SLA_GET_PENDING': {
        return({...state, getSla: {progress: 0}})
    }
    case 'SLA_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getSla: {...api.getSla.response, progress: 1, payload: action.payload}})
            return({...state, getSla: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getSla: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SLA_GET_REJECTED': {
        return({...state, getSla: {progress: 2, payload: action.payload}})
    }

    case 'SLA_STATUS_PENDING': {
      return({...state, statusSla: {progress: 0}})
    }
    case 'SLA_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusSla: {...api.statusSla.response, progress: 1, payload: action.payload}})
            return({...state, statusSla: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusSla: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SLA_STATUS_REJECTED': {
        return({...state, statusSla: {progress: 2, payload: action.payload}})
    }

    case 'SLA_CREATE_PENDING': {
      return({...state, createSla: {progress: 0}})
    }
    case 'SLA_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createSla: {...api.createSla.response, progress: 1, payload: action.payload}})
            return({...state, createSla: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createSla: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SLA_CREATE_REJECTED': {
        return({...state, createSla: {progress: 2, payload: action.payload}})
    }

    case 'SLA_UPDATE_PENDING': {
      return({...state, updateSla: {progress: 0}})
    }
    case 'SLA_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateSla: {...api.updateSla.response, progress: 1, payload: action.payload}})
            return({...state, updateSla: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateSla: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SLA_UPDATE_REJECTED': {
        return({...state, updateSla: {progress: 2, payload: action.payload}})
    }

    case 'SLA_REMOVE_PENDING': {
      return({...state, removeSla: {progress: 0}})
    }
    case 'SLA_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeSla: {...api.removeSla.response, progress: 1, payload: action.payload}})
            return({...state, removeSla: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeSla: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SLA_REMOVE_REJECTED': {
        return({...state, removeSla: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}