
import React from 'react';
import ReactDOM from 'react-dom';

import { Link } from 'react-router-dom';
import { Accordion, Tab, Input, Card, Form, Select, Radio, Label, Container, Divider, Dropdown, Grid, Header, Image, List, Menu, Segment, Button, Icon, Table, Popup } from 'semantic-ui-react'

import { calendarOptions, uptimeOptions, responseOptions, resolveOptions, popupMerchant, popupPersonal, popupCampaign, popupModule, sessionOptions, lockedOptions, resultOptions, limitOptions, delimiterOptions, cdrOptions, transactionOptions, factorOptions, sequenceOptions, periodOptions, fieldTargetOptions, fieldCdrOptions, fieldMdrOptions, fieldTrxOptions, gatewayOptions, dispatchOptions, ratioOptions, attemptOptions, achievedOptions, queuedOptions, calldelayOptions, addonsOptions, paramOptions, hourOptions, passwordOptions, accountOptions, processOptions, backupOptions, restoreOptions, housekeepingOptions, haOptions, watchdogOptions, disasterOptions, privilegeOptions, groupOptions, widgetOptions, yesnoOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'
import MenuTabular from '../common/MenuTabular'
import ModalContainer from '../common/ModalContainer'
import { imagePath, urlUpload } from '../common/_lib'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { ImageUploadField, AudioUploadField, InputField, LabelInputField, CheckboxField, SelectField, RadioField, TextAreaField, WysiwygField, NumberField, SliderField, LabelNumberField, LabelSelectField } from '../validation/componentrsui'
import { required, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { listParams } from "../../actions/halo/masterAction"
import { resetSetting, getSetting, updateSetting } from "../../actions/halo/settingAction"
import Omniwidget from '../omniwidget/Omniwidget';
import { rateOmniwidget } from '../../actions/flow/omniwidgetAction';

//CONNECT REDUX STORE
const selector = formValueSelector('SettingAutomationForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    settingStore: state.settingStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    listParams:(objParam) => dispatch(listParams(objParam)),

    resetSetting:(objParam) => dispatch(resetSetting(objParam)),
    getSetting:(objParam) => dispatch(getSetting(objParam)),
    updateSetting:(objParam) => dispatch(updateSetting(objParam)),
  }
};
const panesChannel = (param)=>{
  const channels= [];
  param.svcChannels && param.svcChannels.map(item=>{
    channels.push(item.value)
  })
  return([
    {
      menuItem: { key: '0', icon: 'microphone', content: 'Voice' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '1', icon: 'mobile', content: 'Widget' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '2', icon: 'sticky note outline', content: 'SMS' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '3', icon: 'envelope outline', content: 'Email' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '7', icon: 'whatsapp', content: 'Whatsapp' },
      render: () => 
      <></>
    },
    // (param.group=='admin' || channels.indexOf('Telegram')>=0) && {
    //   menuItem: { key: '8', icon: 'telegram', content: 'Telegram' },
    //   render: () => 
    //   <></>
    // },
    {
      menuItem: { key: '4', icon: 'twitter', content: 'Twitter' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '5', icon: 'facebook', content: 'Facebook' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '6', icon: 'instagram', content: 'Instagram' },
      render: () => 
      <></>
    },
  ])
};

class SettingAutomationForm extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      menu: 'halopagi', //security, halopagi, ovisone
      module: 'channel',
      showSetting: 'channel',

      tabChannel: 0,
      tabReport: 0,
      tabModule: 0,

      settingId: 1,
      
      showSegment: null,
      showHandling: null,

      mapHandlings: new Map(),
      mapSegments: new Map(),

      param: null,
      value: null,
    }
  }

  componentDidMount() {
    //console.log('!!! DID MOUNT PROPS: ', this.props)
    
    this.props.resetSetting();
    this.props.getSetting({settingId: this.props.settingId})

    this.props.listParams({clientId: this.props.settingId})
  }

  componentDidUpdate(prevProps) {
    const { settingId, mapHandlings, mapSegments }= this.state
    var isUpdated= false;

    const { data }= (this.props.settingStore.getSetting!=undefined ? this.props.settingStore.getSetting : {})
    if (settingId!=this.props.settingId) {
      mapHandlings.clear();
      mapSegments.clear();

      // console.log(data.settingId, this.props.settingId)
      this.props.getSetting({settingId: this.props.settingId})
      this.props.listParams({clientId: this.props.settingId})

      isUpdated= true;
    }

    if (data && data.settingId==this.props.settingId && data.ticketHandlings && data.ticketHandlings.length>0 && mapHandlings.size==0) {
      data.ticketHandlings.map((handling, i)=>{
        if (!mapHandlings.get(i)) {
          mapHandlings.set(i, new Map());
        }
        handling.params && handling.params.map(item=>{
          mapHandlings.get(i).set(item.key, item.value)
        })
      })
      isUpdated= true;
    }

    if (data && data.settingId==this.props.settingId && data.ticketSegments && data.ticketSegments.length>0 && mapSegments.size==0) {
      data.ticketSegments.map((segment, i)=>{
        if (!mapSegments.get(i)) {
          mapSegments.set(i, new Map());
        }
        segment.params && segment.params.map(item=>{
          mapSegments.get(i).set(item.key, item.value)
        })
      })
      isUpdated= true;
    }

    if (isUpdated) {
      this.setState({
        settingId: this.props.settingId,
        mapHandlings: mapHandlings,
        mapSegments: mapSegments,
      })
    }
    
  }

  resetForm() {
    const { reset, mode } = this.props
    reset();
  }

  onSubmit(values) {
    const { mode} = this.props
    const {mapHandlings, mapSegments}= this.state;
    
    if(mode==2) { //UPDATE
      this.props.updateSetting({...values, ...{}})
    }
  }

  setMode(v, e, d) {
    // console.log(v, e, d)
    if (v=='channel') {
      this.setState({tabChannel: d.panes[d.activeIndex].menuItem.key})
    } else if (v=='report') {
      this.setState({tabReport: d.activeIndex})
    } else {
      this.setState({tabModule: d.activeIndex})
    }
  }

  onChangeInput(e, v) {
    // console.log(e, v.value)
    this.setState({
      param: v.value,
    })
  }

  onChangeSelect(e, v) {
    // console.log(e, v)
    this.setState({
      value: v.value,
    })
  }

  render() {
    const { menu, tabChannel, tabReport, tabModule, showSetting, module }= this.state
    const { error, handleSubmit, pristine, reset, submitting, group, mode, screenWidth, screenHeight }=this.props
    const { data }= (this.props.settingStore.getSetting!=undefined ? this.props.settingStore.getSetting : {})
    const { progress, code, text }= (this.props.settingStore.updateSetting!=undefined ? this.props.settingStore.updateSetting : {})
    
    const { svcCampaigns, svcRatios, svcChannels }= this.props.masterStore.listParams && this.props.masterStore.listParams.data ? this.props.masterStore.listParams.data : {}

    return(<>
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Header as='h4' style={{margin: '0 0 2em 0', fontWeight: 'bold'}} color={(showSetting=='channel' && 'blue') || null}>
            <Header.Content>
              Process Automation
              <Header.Subheader></Header.Subheader>
            </Header.Content>
          </Header>

          <Segment>
            <Header as='h5'>
              Automation Policy
              <Header.Subheader>Manage access and automation privilege based on user roles.</Header.Subheader>
            </Header>
          </Segment>

          <Segment>
              {(progress==1 || progress==2)  && <DismissableMessage hide='auto' style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Setting' : 'Insert Setting') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={code + ' ' + text} />}
              
              <div style={{textAlign: 'right'}}>
                <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
                <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
              </div>
          </Segment>
        </Form >
      </div>
    </>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.settingStore.getSetting!=undefined && state.settingStore.getSetting.progress==1 ? state.settingStore.getSetting.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      userId: selector(state, 'userId'),
    }),
    { load: getSetting }
  )
  (reduxForm({
    form: 'SettingAutomationForm',	// a unique identifier for this form
  })(SettingAutomationForm)
  )
)
