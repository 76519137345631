import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table, Feed, Label } from 'semantic-ui-react'

import DismissableMessage from '../../common/DismissableMessage'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, LabelSelectField } from '../../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../../validation/validation'

import { connect } from "react-redux"
import { checkToken } from "../../../actions/auth/authAction"
import { resetTicket, getTicket,updateTicket, createTicket } from "../../../actions/service/ticketAction"
import { resetInteraction, getInteraction,updateInteraction, createInteraction } from "../../../actions/service/interactionAction"
import { listUser, listWorkgroup } from "../../../actions/service/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('CommentsBox')

const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
    masterStore: state.masterStore,
    ticketStore: state.ticketStore,
    interactionStore: state.interactionStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),

    listUser:(objParam) => dispatch(listUser(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),

    resetTicket:(objParam) => dispatch(resetTicket(objParam)),
    getTicket:(objParam) => dispatch(getTicket(objParam)),
    createTicket:(objParam) => dispatch(createTicket(objParam)),
    updateTicket:(objParam) => dispatch(updateTicket(objParam)),

    resetInteraction:(objParam) => dispatch(resetInteraction(objParam)),
    getInteraction:(objParam) => dispatch(getInteraction(objParam)),
    createInteraction:(objParam) => dispatch(createInteraction(objParam)),
    updateInteraction:(objParam) => dispatch(updateInteraction(objParam)),
  }
}

class CommentsBox extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount() {
    console.log('!!! DID MOUNT', this.props.action)

    this.props.resetInteraction(this.props.mode)
    this.props.listUser()
    this.props.listWorkgroup()
  }

  componentDidUpdate() {
    const { progress, code, text }= (this.props.interactionStore.createInteraction!=undefined ? this.props.interactionStore.createInteraction : {})

    if (progress==1 && code=='00') {
      // setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    values['action']= 'comment';
    values['owner']= this.checkRole();
    values['privacy']= this.state.privacy;
    values['ticketId']= (this.props.action && this.props.action.ticketId)

    this.props.createInteraction({...values})
  }

  checkRole() {
    const token= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken.data : {}
    const ticket= this.props.ticket
    if (ticket) {
      //requesterUserId, requesterWorkgroupId, dispatcherUserId, assigneeUserId, assigneeWorkgroupId, assigneeUsers, assigneeWorkgroups, 
      // escalationUserId, escalationWorkgroupId, escalationUsers,escalationWorkgroups, mentionUsers, mentionWorkgroups
      return(
        (ticket.escalationUserId==token.userId && 'escalation') ||  
        (ticket.escalationUsers && ticket.escalationUsers.indexOf(token.userId)>=0 && 'escalation') || 
        (ticket.assigneeUserId==token.userId && 'assignee') || 
        (ticket.requesterUserId==token.userId && 'requester') || 
        (ticket.dispatcherUserId==token.userId && 'dispatcher') || 
        (ticket.mentionUsers && ticket.mentionUsers.indexOf(token.userId)>=0 && 'follower') || 'follower'
      )
    }
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (this.props.interactionStore.createInteraction!=undefined ? this.props.interactionStore.createInteraction : {})
    
    const token= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken.data : {}
    const listUser= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])
    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    
    const users= new Map();
    const workgroups= new Map();

    listUser.map(item=>{
      users.set(item.value, item)
    })
    listWorkgroup.map(item=>{
      workgroups.set(item.value, item)
    })

    return (
      <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
        <Header as='h5'>
            <Icon name='comments outline' circular inverted color='blue' style={{fontSize: '1.1em'}} />
            <Header.Content>
              Comments Conversation
              <Header.Subheader>Side conversation on certain ticket update.</Header.Subheader>
            </Header.Content>
          </Header>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                By <b>{this.props.action.user && this.props.action.user.name}</b>
                <Header.Subheader>As <b>{this.props.action.owner}</b> at <b>{this.props.action.updatedStamp ? format(parse(this.props.action.updatedStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm, dd/MMM yyyy') : 'unknown time'}</b></Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <div style={{display: 'flex', border: '1px solid #ccc', borderRadius: '.3em'}}>
              <div style={{padding: '.5em .5em', borderRight: '1px solid #ccc', fontWeight: 'bold', fontSize: '1.1em'}}>
                To:
              </div>
              <div style={{flex: 1, padding: '.5em .5em 0 .5em'}}>
                <Label.Group>
                  {this.props.action.mentionUsers && this.props.action.mentionUsers.map(item=>{
                    return(
                      <Label>{(users.get(item)||{}).text}</Label>
                    )
                  })}
                </Label.Group>
              </div>
            </div>
            <Divider />
            <b style={{textTransform: 'capitalize'}}>{this.props.action.privacy}</b>
            <div style={{fontSize: '1.3em'}}>
              {this.props.action.activity}
            </div>
            <Divider />
            <div style={{display:'flex'}}>
              <div style={{width: '10em', paddingRight: '1em', borderRight: '1px solid #ccc', textAlign: 'center'}}>
                <Label color={'black'} size='big' className='fluid'>
                  {(this.props.action.remark) || 'Progress'}
                </Label>
              </div>
              <div style={{flex: 1, paddingLeft: '1em'}}>
                <Header as='h4'>
                  <Header.Content>
                    {(this.props.action.resolution && this.props.action.resolution.name) || 'No Resolution'}
                    <Header.Subheader style={{fontWeight: 'bold', textTransform: 'uppercase'}}>Action: {this.props.action.action}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
          </Segment>

          <Segment>
            <Header as='h5' color='blue'>
              <Header.Content>
                <b>Appreciate by Comments</b>
                <Header.Subheader>Recent comments, expand to load previous comment.</Header.Subheader>
              </Header.Content>
            </Header>
            {/* <Divider /> */}
            <div style={{display: 'flex'}}>
              <div style={{flex: 1, borderRadius: '.5em'}}>
                <Field name='activity' component={TextAreaField}
                // label='Activity'
                rows={6}
                placeholder='Type comment here ...' />
              </div>
              <div style={{padding: '.5em 0 0 .5em'}}>
                <Popup content='Send Emoticon' position='left center' inverted trigger={
                  <Icon name='smile outline' style={{cursor: 'pointer', margin: '0'}} disabled />
                } />
                <Divider style={{margin: '.3em 0'}} />
                <Popup content='Upload Image' position='left center' inverted trigger={
                  <Icon name='image' style={{cursor: 'pointer', margin: '0'}} disabled />
                } />
                <Divider style={{margin: '.3em 0'}} />
                <Popup content='Upload Document' position='left center' inverted trigger={
                  <Icon name='cloud upload' style={{cursor: 'pointer', margin: '0'}} disabled />
                } />
              </div>
            </div>
            <div style={{textAlign: 'right', paddingTop: '.8em'}}>
            <Button color='blue' size='small'> Send </Button>
            </div>
          </Segment>

        {/* <Segment>
          {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Ticket' : 'Insert Ticket') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
          
          <div style={{textAlign: 'right'}}>
            <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
            <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
          </div>
        </Segment> */}

      </Form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.ticketStore.getTicket!=undefined && state.ticketStore.getTicket.progress==1 ? state.ticketStore.getTicket.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      openStamp: selector(state, 'openStamp'),
      remark: selector(state, 'remark'),

      segmentId: selector(state, 'segmentId'),
      segmentParams: selector(state, 'segmentParams'),

      dispositions: selector(state, 'dispositions'),
    }),
    { load: getTicket }
  )
  (reduxForm({
    form: 'CommentsBox',	// a unique identifier for this form
  })(CommentsBox)
  )
)
