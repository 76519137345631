import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import AccountBrowse from '../../libraries/halo/AccountBrowse'
import AccountForm from '../../libraries/halo/AccountForm'
import AccountSearch from '../../libraries/halo/AccountSearch'


class AccountBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        userId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, userId: id})
    }

    closeModal() {
      this.setState({
        mode: 1,
        userId: 0,
      });
      this.props.closeModal() 
    }

    render() {
      const {mode, userId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
            <AccountSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {<AccountBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
              {/* {mode==2 && <AccountForm mode={mode} userId={userId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <AccountForm mode={mode} userId={0} setMode={this.setMode.bind(this)} />} */}
              {mode==2 && <ModalContainer size='large' content={<><AccountSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} /><AccountForm mode={mode} userId={userId} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} /></>} closeModal={this.closeModal.bind(this)} /> }
              {mode==3 && <ModalContainer size='large' content={<><AccountSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} /><AccountForm mode={mode} userId={0} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} /></>} closeModal={this.closeModal.bind(this)} /> }
            </div>
        </Container>
      )
    }
}

export default AccountBody
