import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Dropdown } from 'semantic-ui-react'

import { parse, format, endOfMonth } from "date-fns"
import BarRechart from '../../chart/BarRechart';

import { connect } from "react-redux"
import { ticketMonitoring } from "../../../actions/service/monitoringAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    monitoringStore: state.monitoringStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ticketMonitoring:(objParam) => dispatch(ticketMonitoring(objParam)),
  }
}

class OccupancyWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      stamp: '',
      barData: [
        {
          name: 'Consumer support', 
          totDispatch: 5, totDisposition: 4, totEscalation: 15, totResponse: 1, totAction: 5, totResolve: 9
        },
        {
          name: 'Enterprise support', 
          totDispatch: 1, totDisposition: 5, totEscalation: 15, totResponse: 1, totAction: 5, totResolve: 6
        },
      ],
      barLegend: [
        {id: 'totDispatch', name: 'Dispatch'},
        {id: 'totDisposition', name: 'Disposition'},
        {id: 'totEscalation', name: 'Escalation'},
        {id: 'totResponse', name: 'Response'},
        {id: 'totAction', name: 'Action'},
        {id: 'totResolve', name: 'Resolve'},
      ]
    };
  }
  
  componentDidUpdate() {
    const { barData, stamp}= this.state
    const { data, search }= (this.props.monitoringStore.ticketMonitoring!==undefined ? this.props.monitoringStore.ticketMonitoring : {})

    const _stamp= (search && search.groupingDate && (search.grouping=='Daily' ? search.grouping +'-'+ search.groupingDate.substr(8,2) : search.grouping +'-'+ search.groupingDate.substr(5,2))) || '';

    const values={};
    
    // console.log(data)
    if (this.state.stamp!=_stamp) {
      if (search && data && data.occupancies) {
        if (search && search.grouping=='Daily') {
          Object.entries(data.occupancies).forEach(([dd, value]) => {
            // console.log('!!! DAILY', search.groupingDate && search.groupingDate.substr(8,2), dd, value)
            if (search.groupingDate && search.groupingDate.substr(8,2)==dd) {
              Object.entries(value).forEach(([id, workgroup]) => {
                values[workgroup.name] ? values[workgroup.name]+= parseInt(workgroup.totOpen) : values[workgroup.name]= parseInt(workgroup.totOpen);
              })
            }
          });
        } else if (search && search.grouping=='Monthly') {
          Object.entries(data.occupancies).forEach(([dd, value]) => {
            // console.log('!!! MONTHLY', dd, value)
            Object.entries(value).forEach(([id, workgroup]) => {
              values[workgroup.name] ? values[workgroup.name]+= parseInt(workgroup.totOpen) : values[workgroup.name]= parseInt(workgroup.totOpen);
            })
          });
        }
      }

      const barData= [];
      Object.entries(values).forEach(([key, value]) => {
        barData.push({
          name: key,
          value: parseInt(value)
        })
      });
      this.setState({
        stamp: _stamp,
        barData: [
          // {name: 'Response OK', value: values.response_OK},
          ...barData,
        ]
      });
    }
  }
  
  render() {
    const { screenWidth,  screenHeight, height}= this.props
    const { barData, barLegend}= this.state
    
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <Header as='h5'>
          <Icon name='chart bar' style={{fontSize: '1em'}} />
          <Header.Content>
            Occupancy Distribution / <b><i>{format(new Date(), 'MMM yyyy')}</i></b>
            <Header.Subheader>Ticket dispatching and disposition load for person / unit in charge.</Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em', minHeight: this.props.height}}>
        {/* {data!=undefined && */}
          <BarRechart mode='simple' height={250} yAxis={false} dashed={true} label={true} data={barData} legend={barLegend}/>
        {/* } */}
      </Segment>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OccupancyWidget)