import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Label } from 'semantic-ui-react'

export default class MenuLeftSetting extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      page: 1,
    };
  }

  setPage(v) {
    this.setState({
      page: v,
    })
  }

  render() {
    const { isExpand, isSubMenu, page }= this.state
    const { module, screenWidth,  screenHeight, mini }= this.props
    
    return (
      <div style={{display: 'flex', flexDirection: 'column', minHeight: screenHeight, height: '100%'}}>
        <div style={{flex: '1', padding: '2.5em .5em 1.5em 1em', }}>
          <div style={{float: 'right', marginTop: '-.5em', paddingRight: '.5em'}}>
            <Button.Group size='tiny'>
              <Popup content='1 of 2'  position= 'bottom center'
              trigger={
                <Button color={page==1 ? 'grey' : null} type='button' style={{padding: '.4em .2em', height: '1.8em', width: '3em'}} icon onClick={this.setPage.bind(this, 1)}><Icon name='arrow left' /></Button>
              }
              inverted />
              <Button.Or></Button.Or>
              <Popup content='2 of 2'  position= 'bottom center'
              trigger={
                <Button color={page==2 ? 'grey' : null} type='button' style={{padding: '.4em .2em', height: '1.8em', width: '3em'}} icon onClick={this.setPage.bind(this, 2)}><Icon name='arrow right' /></Button>
              }
              inverted />
            </Button.Group>
          </div>
          {page==1 && <>
            <Header as='h5' style={{fontWeight: 'bold', margin: '0 0 .5em 0'}}  color='black'>
              <Header.Content>
                <b>People</b>
              </Header.Content>
            </Header>
            <Popup position= 'right center' inverted content='People and Relations' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'people')}
                color={this.props.mode=='people' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='people' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='users' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='people' ? 'black' : 'grey'}/></Label> People
                  </Header.Content>
                  {this.props.mode=='people' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            <Popup position= 'right center' inverted content='Organizations' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'organization')}
                color={this.props.mode=='organization' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='organization' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='sitemap' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='organization' ? 'black' : 'grey'}/></Label> Organization
                  </Header.Content>
                  {this.props.mode=='organization' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            <Popup position= 'right center' inverted content='Team, Workgroup Management' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'workgroup')}
                color={this.props.mode=='workgroup' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='workgroup' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='object ungroup' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='workgroup' ? 'black' : 'grey'}/></Label> Team / Workgroup
                  </Header.Content>
                  {this.props.mode=='workgroup' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            
            <Header as='h5' style={{fontWeight: 'bold', margin: '2em 0 0 0'}}>
              <Header.Content>
                <b>Workspaces</b>
              </Header.Content>
            </Header>
            <Popup position= 'right center' inverted content='Ticket Bucket / Views' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'bucket')}
                color={this.props.mode=='bucket' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='bucket' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='archive' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='bucket' ? 'black' : 'grey'}/></Label> Bucket View
                  </Header.Content>
                  {this.props.mode=='bucket' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            <Popup position= 'right center' inverted content='Macro Automation Template, Content, Actions, Attributes' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'macro')}
                color={this.props.mode=='macro' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='macro' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='code' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='macro' ? 'black' : 'grey'}/></Label> Dynamic Macro
                  </Header.Content>
                  {this.props.mode=='macro' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />

            <Divider style={{margin: '.5em 0'}} />
            <Popup position= 'right center' inverted content='Custom Form and Selected Field Attribute' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'forms')}
                color={this.props.mode=='forms' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='forms' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='window maximize outline' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='forms' ? 'black' : 'grey'}/></Label> Custom Forms
                  </Header.Content>
                  {this.props.mode=='forms' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            <Popup position= 'right center' inverted content='Custom Fields and Attributes' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'fields')}
                color={this.props.mode=='fields' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='fields' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='columns' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='fields' ? 'black' : 'grey'}/></Label> Fields / Attributes
                  </Header.Content>
                  {this.props.mode=='fields' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            
            <Header as='h5' style={{fontWeight: 'bold', margin: '2em 0 0 0'}}>
              <Header.Content>
                <b>Objects & Rules</b>
              </Header.Content>
            </Header>
            <Popup position= 'right center' inverted content='Service / Product' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'product')}
                color={this.props.mode=='product' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='product' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='cube' style={{fontSize: '1.4em', margin: '0 0 0 -.1em'}} color={this.props.mode=='product' ? 'black' : 'grey'}/></Label> Service / Product
                  </Header.Content>
                  {this.props.mode=='product' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            <Divider style={{margin: '.5em 0'}} />
            <Popup position= 'right center' inverted content='Ticket Status' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'status')}
                color={this.props.mode=='status' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='status' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='indent' style={{fontSize: '1.4em', margin: '0 0 0 -.1em', transform: 'rotate(90deg)'}} color={this.props.mode=='status' ? 'black' : 'grey'}/></Label> Ticket Status
                  </Header.Content>
                  {this.props.mode=='status' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            <Divider style={{margin: '.5em 0'}} />
            <Popup position= 'right center' inverted content='Ticket Reasoning Type' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'reasoning')}
                color={this.props.mode=='reasoning' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='reasoning' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='clipboard outline' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='reasoning' ? 'black' : 'grey'}/></Label> Ticket Reasoning
                  </Header.Content>
                  {this.props.mode=='reasoning' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            <Popup position= 'right center' inverted content='Ticket Resolution Type' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'resolution')}
                color={this.props.mode=='resolution' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='resolution' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='bookmark' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='resolution' ? 'black' : 'grey'}/></Label> Ticket Resolution
                  </Header.Content>
                  {this.props.mode=='resolution' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            <Popup position= 'right center' inverted content='Ticket / issue grouping and categorization' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'classification')}
                color={this.props.mode=='classification' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='classification' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='folder' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='classification' ? 'black' : 'grey'}/></Label> Ticket Classification
                  </Header.Content>
                  {this.props.mode=='classification' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            
            <Divider style={{margin: '.5em 0'}} />
            <Popup position= 'right center' inverted content='Ticket Dispatch and Routing' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'routing')}
                color={this.props.mode=='routing' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='routing' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='random' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='routing' ? 'black' : 'grey'}/></Label> Dispatch & Routing
                  </Header.Content>
                  {this.props.mode=='routing' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            <Popup position= 'right center' inverted content='SLA Policy, Ticking Timer, Service and Customer Segment' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'sla')}
                color={this.props.mode=='sla' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='sla' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='clock' style={{fontSize: '1.5em', margin: '0'}} color={this.props.mode=='sla' ? 'black' : 'grey'}/></Label> Service Level Policy
                  </Header.Content>
                  {this.props.mode=='sla' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            <Popup position= 'right center' inverted content='Trigger and Automation Process' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'trigger')}
                color={this.props.mode=='trigger' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='trigger' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='sync alternate' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='trigger' ? 'black' : 'grey'}/></Label> Trigger & Automations
                  </Header.Content>
                  {this.props.mode=='trigger' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            
            <Divider style={{margin: '.5em 0'}} />
            <Popup position= 'right center' inverted content='Customer Rating / Feedback' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'csat')}
                color={this.props.mode=='csat' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='csat' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='star outline' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='csat' ? 'black' : 'grey'}/></Label> Cust. Statisfaction
                  </Header.Content>
                  {this.props.mode=='csat' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
          </>}
          
          {page==2 && <>
            <Header as='h5' style={{fontWeight: 'bold', margin: '0 0 0 0'}}>
              <Header.Content>
                <b>Security</b>
              </Header.Content>
            </Header>
            <Popup position= 'right center' inverted content='Access Logger / Audit Trail' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'logger')}
                color={this.props.mode=='logger' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='logger' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='paw' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='logger' ? 'black' : 'grey'}/></Label> Audit Trail
                  </Header.Content>
                  {this.props.mode=='logger' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            <Divider style={{margin: '.5em 0'}} />
            <Popup position= 'right center' inverted content='Account Management' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'account')}
                color={this.props.mode=='account' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='account' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='user circle' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='account' ? 'black' : 'grey'}/></Label> User / Account
                  </Header.Content>
                  {this.props.mode=='account' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            {/* <Popup position= 'right center' inverted content='Privilege and Permission' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'permission')}
                color={this.props.mode=='permission' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='permission' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='clipboard list' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='permission' ? 'black' : 'grey'}/></Label> Access Permission
                  </Header.Content>
                  {this.props.mode=='permission' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } /> */}

            <Header as='h5' style={{fontWeight: 'bold', margin: '2em 0 0 0'}}>
              <Header.Content>
                <b>Preferences</b>
              </Header.Content>
            </Header>
            <Popup position= 'right center' inverted content='Communication Channel' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'channel')}
                color={this.props.mode=='channel' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='channel' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='comments outline' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='channel' ? 'black' : 'grey'}/></Label> Comm. Channel
                  </Header.Content>
                  {this.props.mode=='channel' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            {/* <Popup position= 'right center' inverted content='Notification Event' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'notification')}
                color={this.props.mode=='notification' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='notification' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='envelope open outline' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='notification' ? 'black' : 'grey'}/></Label> Event Notification
                  </Header.Content>
                  {this.props.mode=='notification' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } /> */}
            {/* <Popup position= 'right center' inverted content='Customer Rating / Feedback' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'feedback')}
                color={this.props.mode=='feedback' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='feedback' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='star outline' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='feedback' ? 'black' : 'grey'}/></Label> Feedback / Rating
                  </Header.Content>
                  {this.props.mode=='feedback' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } /> */}
            <Divider style={{margin: '.5em 0'}} />
            <Popup position= 'right center' inverted content='Localization setting / preferences' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'localization')}
                color={this.props.mode=='localization' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='localization' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='language' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='localization' ? 'black' : 'grey'}/></Label> Time & Language
                  </Header.Content>
                  {this.props.mode=='localization' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            <Divider style={{margin: '.5em 0'}} />
            <Popup position= 'right center' inverted content='Business Hours Schedule and Calendars' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'calendar')}
                color={this.props.mode=='calendar' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='calendar' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='calendar alternate outline' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='calendar' ? 'black' : 'grey'}/></Label> Schedule & Calendar
                  </Header.Content>
                  {this.props.mode=='calendar' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />

            {/* <Header as='h5' style={{fontWeight: 'bold', margin: '2em 0 0 0'}}>
              <Header.Content>
                <b>Business Process</b>
              </Header.Content>
            </Header>
            <Popup position= 'right center' inverted content='Business Process / Workflow' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'workflow')}
                color={this.props.mode=='workflow' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='workflow' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='object ungroup outline' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='workflow' ? 'black' : 'grey'}/></Label> Process Workflow
                  </Header.Content>
                  {this.props.mode=='workflow' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            <Popup position= 'right center' inverted content='Process Automation' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'automation')}
                color={this.props.mode=='automation' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='automation' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='magic' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='automation' ? 'black' : 'grey'}/></Label> Process Automation
                  </Header.Content>
                  {this.props.mode=='automation' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } /> */}

            {/* <Header as='h5' style={{fontWeight: 'bold', margin: '2em 0 0 0'}}>
              <Header.Content>
                <b>Service Level Policy</b>
              </Header.Content>
            </Header>
            <Popup position= 'right center' inverted content='Service Level Timer and Events Notification' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'sla_timer')}
                color={this.props.mode=='sla_timer' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='sla_timer' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='clock' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='sla_timer' ? 'black' : 'grey'}/></Label> SLA Timer
                  </Header.Content>
                  {this.props.mode=='sla_timer' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            <Divider style={{margin: '.5em 0'}} />
            <Popup position= 'right center' inverted content='Service Level Metric of Product' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'sla_service')}
                color={this.props.mode=='sla_service' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='sla_service' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='cube' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='sla_service' ? 'black' : 'grey'}/></Label> SLA Service
                  </Header.Content>
                  {this.props.mode=='sla_service' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            <Popup position= 'right center' inverted content='Service Level of Customer Segment' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'sla_customer')}
                color={this.props.mode=='sla_customer' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='sla_customer' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='address card' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='sla_customer' ? 'black' : 'grey'}/></Label> SLA Customer
                  </Header.Content>
                  {this.props.mode=='sla_customer' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } /> */}

            {/* <Header as='h5' style={{fontWeight: 'bold', margin: '2em 0 0 0'}}>
              <Header.Content>
                <b>Data Reference</b>
              </Header.Content>
            </Header>
            <Popup position= 'right center' inverted content='Priority level of ticket / issue' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'priority')}
                color={this.props.mode=='priority' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='priority' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='sort numeric up' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='priority' ? 'black' : 'grey'}/></Label> Level of Priority
                  </Header.Content>
                  {this.props.mode=='priority' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            <Popup position= 'right center' inverted content='Severity level of service ticket / issue' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'severity')}
                color={this.props.mode=='severity' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='severity' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='sort amount down' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='severity' ? 'black' : 'grey'}/></Label> Level of Severity
                  </Header.Content>
                  {this.props.mode=='severity' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } /> */}

            <Header as='h5' style={{fontWeight: 'bold', margin: '2em 0 0 0'}}>
              <Header.Content>
                <b>Analytics & Reportings</b>
              </Header.Content>
            </Header>
            <Popup position= 'right center' inverted content='Widget Composer' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'widget')}
                color={this.props.mode=='widget' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='widget' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='chart area' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='widget' ? 'black' : 'grey'}/></Label> Widget Composer
                  </Header.Content>
                  {this.props.mode=='widget' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            <Popup position= 'right center' inverted content='Compose or Use Template Analytic Reporting' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'reporting')}
                color={this.props.mode=='reporting' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='reporting' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='cloud download' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='reporting' ? 'black' : 'grey'}/></Label> Reporting Composer
                  </Header.Content>
                  {this.props.mode=='reporting' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />
            <Divider style={{margin: '.5em 0'}} />
            <Popup position= 'right center' inverted content='Dashboard, Widget and Analytic' trigger= {
              <Header as='h5'
                onClick={this.props.openWindow.bind(this,'explorer')}
                color={this.props.mode=='explorer' ? null : 'grey'}
                style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='explorer' ? '600' : '100'}}>
                  <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                    <Icon name='gamepad' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='explorer' ? 'black' : 'grey'}/></Label> Dashboard Composer
                  </Header.Content>
                  {this.props.mode=='explorer' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
              </Header>
            } />

          </>}
        </div>
      </div>
    );
  }
}
