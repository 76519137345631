import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetRouting(objParam) {
  return {
      type: 'ROUTING_RESET',
      payload: objParam
  }
}

export function getRouting(objParam) {
  return {
      type: 'ROUTING_GET',
      payload:
        axios.post(
          urlAPI + 'service/routing/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusRouting(objParam) {
  return {
      type: 'ROUTING_STATUS',
      payload:
        axios.post(
          urlAPI + 'service/routing/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseRouting(objParam) {
  return {
      type: 'ROUTING_BROWSE',
      payload:
        axios.post(
          urlAPI + 'service/routing/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createRouting(objParam) {
  return {
      type: 'ROUTING_CREATE',
      payload:
        axios.post(
          urlAPI + 'service/routing/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateRouting(objParam) {
  return {
      type: 'ROUTING_UPDATE',
      payload:
        axios.post(
          urlAPI + 'service/routing/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeRouting(objParam) {
  return {
      type: 'ROUTING_REMOVE',
      payload:
        axios.post(
          urlAPI + 'service/routing/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
