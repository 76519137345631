import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'

import { groupOptions, explorerOptions }  from '../../modules/service/StaticMaster'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector, getFormValues } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, SearchField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetReporting, getReporting,updateReporting, createReporting } from "../../actions/service/reportingAction"
import { listUser, listWorkgroup } from "../../actions/service/masterAction"


//CONNECT REDUX STORE
const selector = formValueSelector('ReportingForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    reportingStore: state.reportingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listUser:(objParam) => dispatch(listUser(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),

    resetReporting:(objParam) => dispatch(resetReporting(objParam)),
    getReporting:(objParam) => dispatch(getReporting(objParam)),
    createReporting:(objParam) => dispatch(createReporting(objParam)),
    updateReporting:(objParam) => dispatch(updateReporting(objParam)),
  }
}

class ReportingForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount(){
    this.props.listUser()
    this.props.listWorkgroup()

    this.props.resetReporting(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getReporting({reportId: this.props.reportId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.reportingStore.updateReporting!=undefined ? this.props.reportingStore.updateReporting : {}) : (this.props.reportingStore.createReporting!=undefined ? this.props.reportingStore.createReporting : {}))

    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateReporting({...values, columnReportQty: this.props.columnReportQty, metricReportQty: this.props.metricReportQty, groupReportQty: this.props.groupReportQty, drillReportQty: this.props.drillReportQty}) : this.props.createReporting({...values, columnReportQty: this.props.columnReportQty, metricReportQty: this.props.metricReportQty, groupReportQty: this.props.groupReportQty, drillReportQty: this.props.drillReportQty})
  }

  incrRowQty(isIncrease, kind) {
    console.log(kind, this.props[kind +'ReportQty'])
    if (isIncrease) {
      if (this.props[kind +'ReportQty']<10) {
        this.props.dispatch(change('ReportingForm', kind +'ReportQty', ((isNaN(this.props[kind +'ReportQty']) ? 0 : parseInt(this.props[kind +'ReportQty'])) +1)))
      }
    } else {
      if (this.props[kind +'ReportQty']>1) {
        this.props.dispatch(change('ReportingForm', kind +'ReportQty', ((isNaN(this.props[kind +'ReportQty']) ? 0 : parseInt(this.props[kind +'ReportQty'])) -1)))
      }
    }
  }

  drawReports(qty=1, kind) {
    const forms= this.props[kind +'Reports'] || []
    
    // console.log('!!! CONDITIONS DRAW', conditionsQty)
    const items= [];
    for (var i=0; i<qty; i++) {
      items[i]= {
        item: forms[i] ? forms[i].item : null,
      };
    }

    if (kind=='column') {
      return (
        items.map((item, i)=>{
          return(<>
            <Form.Group key={i} widths='16'>
              <Field name={'columnTable_'+ i} component={SelectField}
              width={8}
              options={explorerOptions('source')}
              validate= {[required]}
              placeholder='Data Source' />
            </Form.Group>
            <Form.Group key={i} widths='equal'>
              <Field name={'columnSource_'+ i} component={SelectField}
              multiple
              options={explorerOptions('column', (this.props.formValues && this.props.formValues['columnTable_'+ i]))}
              validate= {[required]}
              placeholder='Column Data Source' />
            </Form.Group>
          </>)
        })
      )
    } else if (kind=='metric') {
      return (<>
        {
          items.map((item, i)=>{
            return(<>
              <Form.Group key={i} widths='16'>
                <Field name={'metricTable_'+ i} component={SelectField}
                width={4}
                options={explorerOptions('source')}
                validate= {[required]}
                placeholder='Data Source' />
                <Field name={'metricSource_'+ i} component={SelectField}
                width={6}
                options={explorerOptions('metric', (this.props.formValues && this.props.formValues['metricTable_'+ i]))}
                validate= {[required]}
                placeholder='Metric Data Source' />
                <Field name={'metricAggregate_'+ i} component={SelectField}
                width={6}
                options={explorerOptions('metric-function')}
                validate= {[required]}
                placeholder='Summary Function' />
              </Form.Group>
            </>)
          })
        }
      </>)
    } else if (kind=='group') {
      return (
        items.map((item, i)=>{
          return(<>
            <Form.Group key={i} widths='16'>
              <Field name={'groupTable_'+ i} component={SelectField}
              width={8}
              options={explorerOptions('source')}
              validate= {[required]}
              placeholder='Data Source' />
            </Form.Group>
            <Form.Group key={i} widths='equal'>
              <Field name={'groupSource_'+ i} component={SelectField}
              multiple
              options={explorerOptions('groupby', (this.props.formValues && this.props.formValues['groupTable_'+ i]))}
              validate= {[required]}
              placeholder='Column Data Source' />
            </Form.Group>
          </>)
        })
      )
    } else {
      return (<>
        <>
          {
            items.map((item, i)=>{
              return(<>
                <Form.Group key={i} widths='16'>
                  <Field name={'drillTable_'+ i} component={SelectField}
                  width={8}
                  options={explorerOptions('source')}
                  validate= {[required]}
                  placeholder='Data Source' />
                </Form.Group>
                <Form.Group key={i} widths='equal'>
                  <Field name={'drillSource_'+ i} component={SelectField}
                  multiple
                  options={explorerOptions('drill', (this.props.formValues && this.props.formValues['drillTable_'+ i]))}
                  validate= {[required]}
                  placeholder='Drill Data Source' />
                </Form.Group>
              </>)
            })
          }
        </>
      </>)
    }
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.reportingStore.getReporting!=undefined && this.props.reportingStore.getReporting.progress=='1' ? this.props.reportingStore.getReporting : {})
    const { progress, code, text }= (mode==2 ? (this.props.reportingStore.updateReporting!=undefined ? this.props.reportingStore.updateReporting : {}) : (this.props.reportingStore.createReporting!=undefined ? this.props.reportingStore.createReporting : {}))
    
    const listUser= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])
    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])

    return (
      <div style={{padding: '1em 1.5em'}}>
        <div style={{position: 'absolute', marginTop: '-3em', right: '1.7em', display: 'inline-block', background: '#222', padding: '.2em .5em .6em .6em', borderRadius: '.5em'}}><Icon name='window close' color='yellow' style={{cursor: 'pointer', padding: '0', margin: '0', fontSize: '2em'}} onClick={this.props.closeModal.bind(this, pristine)}/></div>
      
        <Header as='h4' style={{margin: '0 0 2em 0'}}>
          <Icon name='cloud download' style={{fontSize: '1em'}} />
          <Header.Content>
            Reporting Composer / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
          </Header.Content>
        </Header>

        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Grid stackable doubling>
            <Grid.Row columns={16}>
              <Grid.Column width={6}>
                <Header as='h5'>
                  <Header.Content>
                    Report Title
                    <Header.Subheader>Short description of reporting scheme.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='name' component={InputField}
                    validate= {[required]}
                    label='Name'
                    placeholder='Name' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='descript' component={TextAreaField}
                    label='Description'
                    placeholder='Additional information' />
                  </Form.Group>
                </Segment>

                <Header as='h5'>
                  <Header.Content>
                    Availability & Access
                    <Header.Subheader>Applied dashboard scheme on certain privilege.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='workgroups' component={SelectField}
                    multiple
                    options={listWorkgroup}
                    // validate= {[required]}
                    label='Teams / Workgroups'
                    placeholder='Available for certain teams' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='groups' component={SelectField}
                    multiple
                    options={groupOptions()}
                    // validate= {[required]}
                    label='Roles / Groups'
                    placeholder='Available for certain groups' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='users' component={SelectField}
                    multiple
                    options={listUser}
                    // validate= {[required]}
                    label='Users / Accounts'
                    placeholder='Available for certain accounts' />
                  </Form.Group>
                </Segment>
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as='h5'>
                  <Header.Content>
                    Reporting Layout
                    <Header.Subheader>Set field / attribute for current report.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='16'>
                    <Field name='rowType' component={SelectField}
                    width={5}
                    options={explorerOptions('rowtype')}
                    validate= {[required]}
                    label='Data Format'
                    placeholder='Data Presentation' />
                    <Field name='period' component={SelectField}
                    width={5}
                    options={explorerOptions('period')}
                    validate= {[required]}
                    label='Active Period'
                    placeholder='Active report period' />
                    <Field name='exportings' component={SelectField}
                    width={6}
                    options={explorerOptions('export')}
                    multiple
                    label='Exporting Files'
                    placeholder='Supported exporting formats' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='searchings' component={SelectField}
                    multiple
                    options={explorerOptions('filter')}
                    label='Searching / Filters'
                    placeholder='Searching filter reporting data' />
                  </Form.Group>
                </Segment>

                {this.props.rowType=='sheet' && <>
                  <Header as='h5'>
                    <Header.Content>
                      Columns / Attributes
                      <Header.Subheader>Set columns attribute for certain widget.</Header.Subheader>
                    </Header.Content>
                  </Header>
                  <Segment>
                    {this.drawReports(this.props.columnReportQty, 'column')}

                    <div style={{textAlign: 'center', marginTop: '2em'}}>
                      <Button.Group size='mini'>
                        <Popup content='Remove / less columns'  position= 'bottom center'
                        trigger={
                          <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, false, 'column')}><Icon color='red' name='arrow up' size='large' /></Button>
                        }
                        inverted />
                        <Button.Or></Button.Or>
                        <Popup content='Add more columns'  position= 'bottom center'
                        trigger={
                          <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, true, 'column')}><Icon color='blue' name='arrow down' size='large' /></Button>
                        }
                        inverted />
                      </Button.Group>
                    </div>
                  </Segment>
                </>}

                {this.props.rowType=='summary' && <>
                  <Header as='h5'>
                    <Header.Content>
                      Metrics Calculation
                      <Header.Subheader>Set aggregate function as metric calculation.</Header.Subheader>
                    </Header.Content>
                  </Header>
                  <Segment>
                    {this.drawReports(this.props.metricReportQty, 'metric')}

                    <div style={{textAlign: 'center', marginTop: '2em'}}>
                      <Button.Group size='mini'>
                        <Popup content='Remove / less metrics'  position= 'bottom center'
                        trigger={
                          <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, false, 'metric')}><Icon color='red' name='arrow up' size='large' /></Button>
                        }
                        inverted />
                        <Button.Or></Button.Or>
                        <Popup content='Add more metrics'  position= 'bottom center'
                        trigger={
                          <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, true, 'metric')}><Icon color='blue' name='arrow down' size='large' /></Button>
                        }
                        inverted />
                      </Button.Group>
                    </div>
                  </Segment>

                  <Header as='h5'>
                    <Header.Content>
                      Grouping / Rows On Metrics
                      <Header.Subheader>Grouping row data presentation.</Header.Subheader>
                    </Header.Content>
                  </Header>
                  <Segment>
                    {this.drawReports(this.props.groupReportQty, 'group')}

                    <div style={{textAlign: 'center', marginTop: '2em'}}>
                      <Button.Group size='mini'>
                        <Popup content='Remove / less drill columns'  position= 'bottom center'
                        trigger={
                          <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, false, 'group')}><Icon color='red' name='arrow up' size='large' /></Button>
                        }
                        inverted />
                        <Button.Or></Button.Or>
                        <Popup content='Add more drill columns'  position= 'bottom center'
                        trigger={
                          <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, true, 'group')}><Icon color='blue' name='arrow down' size='large' /></Button>
                        }
                        inverted />
                      </Button.Group>
                    </div>
                  </Segment>

                  <Header as='h5'>
                    <Header.Content>
                      Data Drill On Metrics
                      <Header.Subheader>Drilling detail data and information.</Header.Subheader>
                    </Header.Content>
                  </Header>
                  <Segment>
                    {this.drawReports(this.props.drillReportQty, 'drill')}

                    <div style={{textAlign: 'center', marginTop: '2em'}}>
                      <Button.Group size='mini'>
                        <Popup content='Remove / less drill columns'  position= 'bottom center'
                        trigger={
                          <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, false, 'drill')}><Icon color='red' name='arrow up' size='large' /></Button>
                        }
                        inverted />
                        <Button.Or></Button.Or>
                        <Popup content='Add more drill columns'  position= 'bottom center'
                        trigger={
                          <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, true, 'drill')}><Icon color='blue' name='arrow down' size='large' /></Button>
                        }
                        inverted />
                      </Button.Group>
                    </div>
                  </Segment>
                </>}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Reporting' : 'Insert Reporting') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.reportingStore.getReporting!=undefined && state.reportingStore.getReporting.progress==1 ? state.reportingStore.getReporting.data : {columnReportQty: 1, metricReportQty: 1, groupReportQty: 1, drillReportQty: 1}),
      formValues: getFormValues('ReportingForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      rowType: selector(state, 'rowType'),

      columnReportQty: selector(state, 'columnReportQty'),
      metricReportQty: selector(state, 'metricReportQty'),
      groupReportQty: selector(state, 'groupReportQty'),
      drillReportQty: selector(state, 'drillReportQty'),
    }),
    { load: getReporting }
  )
  (reduxForm({
    form: 'ReportingForm',	// a unique identifier for this form
  })(ReportingForm)
  )
)
