export default function routingReducer (state = {}, action) {
  switch (action.type) {
    case 'ROUTING_RESET': {
        return({
            ...state,
            getRouting: {},
            updateRouting: {},
            createRouting: {},
        })
    }

    case 'ROUTING_BROWSE_PENDING': {
      return({...state, browseRouting: {progress: 0}})
    }
    case 'ROUTING_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseRouting: {...api.browseRouting.response, progress: 1, payload: action.payload}})
            return({...state, browseRouting: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseRouting: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ROUTING_BROWSE_REJECTED': {
        return({...state, browseRouting: {progress: 2, payload: action.payload}})
    }
    
    case 'ROUTING_GET_PENDING': {
        return({...state, getRouting: {progress: 0}})
    }
    case 'ROUTING_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getRouting: {...api.getRouting.response, progress: 1, payload: action.payload}})
            return({...state, getRouting: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getRouting: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ROUTING_GET_REJECTED': {
        return({...state, getRouting: {progress: 2, payload: action.payload}})
    }

    case 'ROUTING_STATUS_PENDING': {
      return({...state, statusRouting: {progress: 0}})
    }
    case 'ROUTING_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusRouting: {...api.statusRouting.response, progress: 1, payload: action.payload}})
            return({...state, statusRouting: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusRouting: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ROUTING_STATUS_REJECTED': {
        return({...state, statusRouting: {progress: 2, payload: action.payload}})
    }

    case 'ROUTING_CREATE_PENDING': {
      return({...state, createRouting: {progress: 0}})
    }
    case 'ROUTING_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createRouting: {...api.createRouting.response, progress: 1, payload: action.payload}})
            return({...state, createRouting: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createRouting: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ROUTING_CREATE_REJECTED': {
        return({...state, createRouting: {progress: 2, payload: action.payload}})
    }

    case 'ROUTING_UPDATE_PENDING': {
      return({...state, updateRouting: {progress: 0}})
    }
    case 'ROUTING_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateRouting: {...api.updateRouting.response, progress: 1, payload: action.payload}})
            return({...state, updateRouting: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateRouting: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ROUTING_UPDATE_REJECTED': {
        return({...state, updateRouting: {progress: 2, payload: action.payload}})
    }

    case 'ROUTING_REMOVE_PENDING': {
      return({...state, removeRouting: {progress: 0}})
    }
    case 'ROUTING_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeRouting: {...api.removeRouting.response, progress: 1, payload: action.payload}})
            return({...state, removeRouting: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeRouting: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ROUTING_REMOVE_REJECTED': {
        return({...state, removeRouting: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}