import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Label } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'
import { syseventOptions, channelOptions, durationOptions }  from '../common/StaticMasterData'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetAcknowledge, getAcknowledge,updateAcknowledge, createAcknowledge } from "../../actions/service/acknowledgeAction"
import { listSegment } from "../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('AcknowledgeForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,

    acknowledgeStore: state.acknowledgeStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listSegment:(objParam) => dispatch(listSegment(objParam)),

    resetAcknowledge:(objParam) => dispatch(resetAcknowledge(objParam)),
    getAcknowledge:(objParam) => dispatch(getAcknowledge(objParam)),
    createAcknowledge:(objParam) => dispatch(createAcknowledge(objParam)),
    updateAcknowledge:(objParam) => dispatch(updateAcknowledge(objParam)),
  }
}

class AcknowledgeForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remarkResult: null,
    }
  }

  componentDidMount(){
    this.props.resetAcknowledge(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getAcknowledge({automationId: this.props.automationId})
    }

    this.props.listSegment({search: {clientId: this.props.clientId}})
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.acknowledgeStore.updateAcknowledge!=undefined ? this.props.acknowledgeStore.updateAcknowledge : {}) : (this.props.acknowledgeStore.createAcknowledge!=undefined ? this.props.acknowledgeStore.createAcknowledge : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateAcknowledge({...values}) : this.props.createAcknowledge({...values})
    
  }

  onChangeResult(v) {
    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})

    this.props.dispatch(change('AcknowledgeForm', 'progress', listRemark.progress[v]));
  }
  
  insertVariable(v) {
    // console.log(v)
    if (this.props.media!='Email') {
      this.props.dispatch(change('AcknowledgeForm', 'script', (this.props.script||'') +' '+ v +' '));
    } else {
      navigator.clipboard.writeText(v)
    }
    // e.target.focus()
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.acknowledgeStore.getAcknowledge!=undefined && this.props.acknowledgeStore.getAcknowledge.progress=='1' ? this.props.acknowledgeStore.getAcknowledge : {})
    const { progress, code, text }= (mode==2 ? (this.props.acknowledgeStore.updateAcknowledge!=undefined ? this.props.acknowledgeStore.updateAcknowledge : {}) : (this.props.acknowledgeStore.createAcknowledge!=undefined ? this.props.acknowledgeStore.createAcknowledge : {}))

    const listSegment= (this.props.masterStore.listSegment!=undefined && this.props.masterStore.listSegment.progress=='1' ? this.props.masterStore.listSegment.data : [])
    
    return (
      <div style={{padding: '1em 1em', ...style}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            {/* <Form.Group widths='16'>
              <Form.Field width={8} />
              <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' />
            </Form.Group> */}

            <Form.Group widths='16'>
              <Field name='segments' component={SelectField}
              width={16}
              multiple
              options={listSegment}
              label='Segmentation'
              placeholder='Segmentation' />
            </Form.Group>
          </Segment>
          <Segment>
            <Form.Group widths='16'>
              <Field name='event' component={SelectField}
              width={10}
              options={syseventOptions('ticket')}
              label='Ticket Event Type'
              placeholder='Event Type' />
              <Field name='delay' component={SelectField}
              width={6}
              options={channelOptions()}
              label='Delay Timer'
              placeholder='Delay Timer' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='channels' component={SelectField}
              width={16}
              multiple
              options={channelOptions()}
              label='Channel Delivery'
              placeholder='Channel Delivery' />
            </Form.Group>
          </Segment>

          <Segment>
            {/* <Header color='blue' as='h5'>
              <Icon name='code' style={{fontSize: '1em'}}/>
              <Header.Content>Script / Code Snippet</Header.Content>
            </Header> */}
            <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Form.Group widths='16'>
                  <Field name='script' component={TextAreaField}
                  width='16'
                  rows='8'
                  validate={[required]}
                  label='Content Script' />
                </Form.Group>
              </div>
              <div className='variable' style={{width: '250px', marginTop: '1.6em', marginLeft: '1.3em', padding: '1em 1.5em', background: '#f5f5f5', border: '1px solid #ddd'}}>
                <Label.Group>
                  <Popup header='{stampId}' content='Unique Stamp Id' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{stampId}')}>Stamp Id</Label> 
                  } />
                  <Popup header='{custInfo}' content='Customer or target name' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{custInfo}')}>Customer Information</Label> 
                  } />
                  <Popup header='{billAmount}' content='Bill amount which must paid' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{ticketInfo}')}>Ticket Information</Label>
                  } />
                  <Popup header='{overdueAmount}' content='Penalty or fine amount' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{handlingInfo}')}>Handling Information</Label>
                  } />
                  <Popup header='{overdueDay}' content='Payment was late in days' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{responseMessage}')}>Response Message</Label> 
                  } />
                </Label.Group>
                <Divider style={{margin: '.5em 0'}}/>
                <Header as='h6' style={{margin: '0'}}>
                  <Header.Content>
                    Click to copied and paste your selected variable if not added automaticaly.
                  </Header.Content>
                </Header>
              </div>
            </div>
          </Segment>
          
          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Acknowledge' : 'Insert Acknowledge') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.acknowledgeStore.getAcknowledge!=undefined && state.acknowledgeStore.getAcknowledge.progress==1 ? state.acknowledgeStore.getAcknowledge.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
      
      script: selector(state, 'script'),
    }),
    { load: getAcknowledge }
  )
  (reduxForm({
    form: 'AcknowledgeForm',	// a unique identifier for this form
  })(AcknowledgeForm)
  )
)
