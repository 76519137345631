import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'
import ModalContainer from '../../libraries/common/ModalContainer';
import MenuLeft from '../../libraries/service/MenuLeftDashboard';

import HistoricalBody from '../service/HistoricalBody'
import DashboardAgentBody from './DashboardAgentBody';
import DashboardBackendBody from './DashboardBackendBody';
import DashboardProductBody from './DashboardProductBody';
import DashboardVendorBody from './DashboardVendorBody';


class ServiceDashboardBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { mode, module, screenWidth, screenHeight, mini, modal, params }= this.props

    return (
      <div style={{marginTop: '3em'}}>
        <div style={{display: 'flex'}}>
          {!mini && <div style={{width: '3em', textAlign: 'center'}}>
            <MenuLeft mode={this.props.mode} screenHeight={screenHeight} screenWidth={screenWidth} module={module} mini={mini} openModule={this.props.openModule} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}/>
          </div>}
          <div style={{flex: '1', padding: '1em .5em'}}>
            {(!mode || mode=='historical') && <HistoricalBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params}/>}
            {mode=='agent' && <DashboardAgentBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} />}
            {mode=='backend' && <DashboardBackendBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} />}
            {mode=='product' && <DashboardProductBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} />}
            {mode=='vendor' && <DashboardVendorBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} />}
          </div>
        </div>
      </div>
    )
  }
}

export default ServiceDashboardBody