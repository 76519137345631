import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetCsat(objParam) {
  return {
      type: 'CSAT_RESET',
      payload: objParam
  }
}

export function getCsat(objParam) {
  return {
      type: 'CSAT_GET',
      payload:
        axios.post(
          urlAPI + 'service/csat/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusCsat(objParam) {
  return {
      type: 'CSAT_STATUS',
      payload:
        axios.post(
          urlAPI + 'service/csat/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseCsat(objParam) {
  return {
      type: 'CSAT_BROWSE',
      payload:
        axios.post(
          urlAPI + 'service/csat/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createCsat(objParam) {
  return {
      type: 'CSAT_CREATE',
      payload:
        axios.post(
          urlAPI + 'service/csat/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateCsat(objParam) {
  return {
      type: 'CSAT_UPDATE',
      payload:
        axios.post(
          urlAPI + 'service/csat/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeCsat(objParam) {
  return {
      type: 'CSAT_REMOVE',
      payload:
        axios.post(
          urlAPI + 'service/csat/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
