import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
  Feed,
  Placeholder
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse, addSeconds } from "date-fns"
import {  } from "../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { menuBucket, resetBucket } from "../../actions/service/bucketAction"
import { listBucket } from "../../actions/service/masterAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    bucketStore: state.bucketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listBucket:(objParam) => dispatch(listBucket(objParam)),
    
    resetBucket:(objParam) => dispatch(resetBucket(objParam)),
    menuBucket:(objParam) => dispatch(menuBucket(objParam)),
  }
}

class MyBucketMenu extends Component {
  constructor(props) {
    super(props)
    this.state= {
      page: 1,
    }
  }

  componentDidMount() {
    this.props.resetBucket()
    this.props.listBucket()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps || (prevProps && prevProps.bucket && this.props.bucket && prevProps.bucket.value!=this.props.bucket.value)) {
      this.props.menuBucket({bucketId: this.props.bucket.value})
    }
  }
  componentWillUnmount() {
    this.props.resetBucket()
  }

  setPage(v) {
    this.setState({
      page: v,
    })
  }

  drawTicketBucket(v) {
    const listBucket = (this.props.masterStore.listBucket!==undefined && this.props.masterStore.listBucket.progress=='1' ? this.props.masterStore.listBucket.data : null)
    const menuBucket = (this.props.bucketStore.menuBucket!==undefined && this.props.bucketStore.menuBucket.progress=='1' ? this.props.bucketStore.menuBucket.data : null)

    if (menuBucket) {
      if (v=='user') {
        return(<Feed style={{margin: '0 0 2em 0em'}}>
          {menuBucket.userMenu && menuBucket.userMenu.map((item, i)=>{
            return(
              <Popup key={i} content={item.hint} position='right center' inverted
                trigger={
                  <Feed.Event onClick={this.props.setFilter.bind(this, item, 'user')}
                  style={{margin: '.6em 0', background: this.props.filter && this.props.filter.title==item.title ? 'rgb(178 255 255)' : '#fafafa', border: this.props.filter && this.props.filter.title==item.title ? '1px solid rgb(137 228 255)' : '1px solid #e0e0e0', borderRadius: '2em', padding: '.2em 0'}}>
                    <Feed.Label style={{paddingTop: '0em'}}>
                      <Icon color='grey' name={
                        (item.title=='New' && 'circle outline') ||
                        (item.title=='Open' && 'plus circle') ||
                        (item.title=='Pending' && 'clock outline') ||
                        (item.title=='On Hold' && 'clock') ||
                        (item.title=='Solved' && 'check circle outline') ||
                        (item.title=='Unsolved' && 'circle notch') ||
                        (item.title=='Closed' && 'stop circle outline') ||
                        (item.title=='Warning' && 'exclamation circle') ||
                        (item.title=='Assigned' && 'arrow alternate circle right outline') ||
                        (item.title=='Unassigned' && 'circle outline') ||
                        (item.title=='SLA Breach' && 'exclamation circle') ||
                        (item.title=='SLA Complied' && 'exclamation circle') ||
                        'circle notched'
                      } /></Feed.Label>
                    <Feed.Content style={{margin: '.2em 0 .2em 0'}}>
                      <Feed.Summary style={{fontWeight: 'normal'}}>
                        <Feed.User style={{fontSize: '1.1em', paddingTop: '.1em'}}>{item.title}</Feed.User> ~ Tickets
                        {<Label size='tiny' circular color={this.props.filter && this.props.filter.title==item.title ? 'grey' : null} style={{float: 'right', margin: '.2em .7em 0 0', minWidth: '2.2em'}}>{item.totTicket || '0'}</Label>}
                      </Feed.Summary>
                      {/* <Feed.Meta>
                        <Feed.Date>1 Hour Ago</Feed.Date>
                      </Feed.Meta> */}
                    </Feed.Content>
                  </Feed.Event>
                }
              /> 
            )
          })}
        </Feed>)
      } else if (v=='group') {
        return(<Feed style={{margin: '0 0 2em -.5em'}}>
          {menuBucket.groupMenu && menuBucket.groupMenu.map(item=>{
            return(
              <Popup content={item.hint} position='right center' inverted
                trigger={
                  <Feed.Event style={{margin: '.5em 0', background: this.props.filter && this.props.filter.title==item.title ? '#f3f3f3' : null, border: this.props.filter && this.props.filter.title==item.title ? '1px solid #eaeaea' : '1px solid #f0f0f0', borderRadius: '2em', padding: '.2em 0'}}>
                    <Feed.Label style={{paddingTop: '0em'}}>
                      <Icon name={
                        (item.title=='New' && 'circle outline') ||
                        (item.title=='Open' && 'plus circle') ||
                        (item.title=='Pending' && 'clock outline') ||
                        (item.title=='On Hold' && 'clock') ||
                        (item.title=='Solved' && 'check circle outline') ||
                        (item.title=='Unsolved' && 'circle notch') ||
                        (item.title=='Closed' && 'stop circle outline') ||
                        (item.title=='Warning' && 'exclamation circle') ||
                        (item.title=='Assigned' && 'arrow alternate circle right outline') ||
                        (item.title=='Unassigned' && 'circle outline') ||
                        (item.title=='SLA Breach' && 'exclamation circle') ||
                        (item.title=='SLA Complied' && 'exclamation circle') ||
                        'circle notched'
                      } />
                    </Feed.Label>
                    <Feed.Content style={{margin: '.2em 0 .2em 0'}}>
                      <Feed.Summary>
                        <Feed.User onClick={this.props.setFilter.bind(this, item, 'group')}>{item.title}</Feed.User> Tickets
                        {<Label size='tiny' circular color={this.props.filter && this.props.filter.title==item.title ? 'grey' : null} style={{float: 'right', margin: '.3em .7em 0 0', minWidth: '2.15em'}}>{item.totTicket || '0'}</Label>}
                      </Feed.Summary>
                      {/* <Feed.Meta>
                        <Feed.Date>1 Hour Ago</Feed.Date>
                      </Feed.Meta> */}
                    </Feed.Content>
                  </Feed.Event>
                }
              /> 
            )
          })}
        </Feed>)
      }
    } else {
      if (!this.props.bucket && listBucket && listBucket.length>0) {
        this.props.setBucket(listBucket[0])
      } else if (this.props.bucket && !this.props.bucketStore.menuBucket) {
        this.props.menuBucket({bucketId: this.props.bucket.value})
        return(<>GET MENU BUCKET ON {this.props.bucket && this.props.bucket.text}</>)
      }
      //DEFAULT MENU BUCKET
      if (v=='user') {
        return(
          <Placeholder>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
          </Placeholder>
        )
      } else {
        return(
          <Placeholder>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
          </Placeholder>
        )
      }
    }
  }

  setBucket(v) {
    this.props.setBucket(v);
    this.props.menuBucket({bucketId: v.value})
  }

  render() {
    const { page }= this.state
    const { screenWidth, screenHeight }= this.props
    const listBucket = (this.props.masterStore.listBucket!==undefined && this.props.masterStore.listBucket.progress=='1' ? this.props.masterStore.listBucket.data : null)
    
    return (
      <div style={{minHeight: screenHeight}}>
        {page==1 && <>
          <div style={{display: 'flex', padingTop: '1em'}}>
            <div style={{flex: 1, marginTop: '-.03em'}}>
              <div style={{display: 'flex'}}>
                <div style={{padding: '.3em 0 0 .2em', background: '#eee', borderRadius: '.2em', borderRight: '1px solid #55555555'}}>
                  <Dropdown icon='angle double down' inline style={{fontSize: '1em', color: '#321'}}>
                    <Dropdown.Menu style={{margin: '.5em 0 1em 0', background: '#fafafa', border: '1px solid #ccc'}}>
                      {listBucket && listBucket.map(item=>{
                        return(
                          <Dropdown.Item icon={this.props.bucket && this.props.bucket.value==item.value ? 'folder' : 'folder outline'} text={item.text} onClick={this.setBucket.bind(this, item)} /> 
                        )
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div style={{borderLeft: '1px solid #ffffff88'}}>
                  <Label color='yellow' size='medium' style={{_borderRadius: '0 .5em .5em 0', padding: '.4em 1em .2em .7em', fontSize: '1em'}}>
                    My Ticket
                  </Label>
                </div>
              </div>
            </div>
            <div>
              <Button.Group size='tiny'>
                <Popup content='My Ticket'  position= 'bottom center'
                trigger={
                  <Button color={page==1 ? 'grey' : null} type='button' style={{padding: '.4em .2em', height: '1.8em', width: '3em'}} icon onClick={this.setPage.bind(this, 1)}><Icon name='arrow left' /></Button>
                }
                inverted />
                <Button.Or></Button.Or>
                <Popup content='My Group'  position= 'bottom center'
                trigger={
                  <Button color={page==2 ? 'grey' : null} type='button' style={{padding: '.4em .2em', height: '1.8em', width: '3em'}} icon onClick={this.setPage.bind(this, 2)}><Icon name='arrow right' /></Button>
                }
                inverted />
              </Button.Group>
            </div>
          </div>

          <Divider />
            {this.drawTicketBucket('user')}
          {/* <Divider /> */}
          
        </>}
        
        {page==2 && <>
          <div style={{display: 'flex', padingTop: '1em'}}>
            <div style={{flex: 1, marginTop: '-.03em'}}>
              <div style={{display: 'flex'}}>
                <div style={{padding: '.3em 0 0 .2em', background: '#eee', borderRadius: '.2em', borderRight: '1px solid #55555555'}}>
                  <Dropdown icon='angle double down' inline style={{fontSize: '1em', color: '#321'}}>
                    <Dropdown.Menu style={{margin: '.5em 0 1em 0', background: '#fafafa', border: '1px solid #ccc'}}>
                      {listBucket && listBucket.map(item=>{
                        return(
                          <Dropdown.Item icon={this.props.bucket && this.props.bucket.value==item.value ? 'folder' : 'folder outline'} text={item.text} onClick={this.setBucket.bind(this, item)} /> 
                        )
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div style={{flex: '1', borderLeft: '1px solid #ffffff88'}}>
                  <Label color='orange' size='medium' style={{_borderRadius: '0 .5em .5em 0', padding: '.4em 1em .2em .7em', fontSize: '1em'}}>
                    My Group
                  </Label>
                </div>
              </div>
            </div>
            <div>
              <Button.Group size='tiny'>
                <Popup content='My Ticket'  position= 'bottom center'
                trigger={
                  <Button color={page==1 ? 'grey' : null} type='button' style={{padding: '.4em .2em', height: '1.8em', width: '3em'}} icon onClick={this.setPage.bind(this, 1)}><Icon name='arrow left' /></Button>
                }
                inverted />
                <Button.Or></Button.Or>
                <Popup content='My Group'  position= 'bottom center'
                trigger={
                  <Button color={page==2 ? 'grey' : null} type='button' style={{padding: '.4em .2em', height: '1.8em', width: '3em'}} icon onClick={this.setPage.bind(this, 2)}><Icon name='arrow right' /></Button>
                }
                inverted />
              </Button.Group>
            </div>
          </div>

          <Divider />
            {this.drawTicketBucket('group')}
          {/* <Divider /> */}
        </>}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyBucketMenu)
