import React, { Component } from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Card, Image, Label } from 'semantic-ui-react'

class AdminFooter extends Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }
  
  render() {
    const { module }= this.props

    return (
      <div style={{padding: '1em 1.5em .5em 1.5em', background: '#fafafa'}}>
        <div style={{display: 'flex'}}>
          <div>
            <Header as='h5'>
              <b>Ovisone!</b> Digital Workplace 2.3.01
              <Header.Subheader style={{fontSize: '.7em'}}>Trademarks and Copyrights by @Ovisone! 2023</Header.Subheader>
            </Header>
          </div>
        </div>
        <div style={{flex: '1'}}>
          
        </div>
      </div>
    )
  }
}

export default AdminFooter