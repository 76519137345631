import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { groupOptions, conditionOptions, actionOptions, ticketOptions }  from '../../modules/service/StaticMaster'

import { SubmissionError, Field, reduxForm, change, formValueSelector, getFormValues } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, SearchField, LabelNumberField, InsertableDropdownField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetRouting, getRouting,updateRouting, createRouting } from "../../actions/service/routingAction"
import { listUser, listAgent, listWorkgroup, listOrganization, listProduct, listForm, listMacro, listRemark, listChannel } from "../../actions/service/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('RoutingForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    routingStore: state.routingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listUser:(objParam) => dispatch(listUser(objParam)),
    listAgent:(objParam) => dispatch(listAgent(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    listOrganization:(objParam) => dispatch(listOrganization(objParam)),
    listProduct:(objParam) => dispatch(listProduct(objParam)),
    listForm:(objParam) => dispatch(listForm(objParam)),
    listMacro:(objParam) => dispatch(listMacro(objParam)),
    listRemark:(objParam) => dispatch(listRemark(objParam)),
    listChannel:(objParam) => dispatch(listChannel(objParam)),
    
    resetRouting:(objParam) => dispatch(resetRouting(objParam)),
    getRouting:(objParam) => dispatch(getRouting(objParam)),
    createRouting:(objParam) => dispatch(createRouting(objParam)),
    updateRouting:(objParam) => dispatch(updateRouting(objParam)),
  }
}

class RoutingForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount(){
    this.props.listAgent({groupId: 'Agent'})
    this.props.listUser()
    this.props.listWorkgroup()
    this.props.listOrganization()
    this.props.listProduct()
    this.props.listForm()
    this.props.listMacro()
    this.props.listRemark()
    this.props.listChannel()

    this.props.resetRouting(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getRouting({routingId: this.props.routingId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.routingStore.updateRouting!=undefined ? this.props.routingStore.updateRouting : {}) : (this.props.routingStore.createRouting!=undefined ? this.props.routingStore.createRouting : {}))

    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateRouting({...values}) : this.props.createRouting({...values})
  }

  onChangeSelect(v) {
    this.props.dispatch(change('RoutingForm', v.replace('{{PARAM}}', 'Operator'), null));
    this.props.dispatch(change('RoutingForm', v.replace('{{PARAM}}', 'Value'), null));
  }

  incrRowQty(isIncrease, kind) {
    console.log(kind, this.props[kind +'RoutingQty'])
    if (isIncrease) {
      if (this.props[kind +'RoutingQty']<10) {
        this.props.dispatch(change('RoutingForm', kind +'RoutingQty', ((isNaN(this.props[kind +'RoutingQty']) ? 0 : parseInt(this.props[kind +'RoutingQty'])) +1)))
      }
    } else {
      if (this.props[kind +'RoutingQty']>1) {
        this.props.dispatch(change('RoutingForm', kind +'RoutingQty', ((isNaN(this.props[kind +'RoutingQty']) ? 0 : parseInt(this.props[kind +'RoutingQty'])) -1)))
      }
    }
  }

  drawRouting(qty=1, kind, components, options) {
    const routings= this.props[kind +'Routings'] || []
    
    // console.log('!!! CONDITIONS DRAW', conditionsQty)
    const items= [];
    for (var i=0; i<qty; i++) {
      items[i]= {
        item: routings[i] ? routings[i].item : null,
      };
    }
    return (<>
      {
        items.map((item, i)=>{
          return(<Segment>
            <Form.Group widths='16'>
              <Field name={'routingParam_'+ i} component={SelectField} onChange={this.onChangeSelect.bind(this, 'routing{{PARAM}}_'+ i)}
              options={conditionOptions('routing-param')}
              width={6}
              placeholder='Routing Param / Attribute' />
              <Field name={'routingOperator_'+ i} component={SelectField}
              options={conditionOptions('routing-ops', (this.props.formValues && components && (components.get(this.props.formValues['routingParam_'+ i])||{}).input))}
              width={4}
              placeholder='Operator' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name={'routingValue_'+ i} component={
                (this.props.formValues && components && (components.get(this.props.formValues['routingParam_'+ i])||{}).input=='select' && SelectField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['routingParam_'+ i])||{}).input=='selects' && SelectField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['routingParam_'+ i])||{}).input=='contain' && SelectField) ||
                (this.props.formValues && components && (components.get(this.props.formValues['routingParam_'+ i])||{}).input=='contains' && SelectField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['routingParam_'+ i])||{}).input=='duration' && LabelNumberField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['routingParam_'+ i])||{}).input=='taggings' && InsertableDropdownField) || 
                InputField
              }
              label={(this.props.formValues && components && (components.get(this.props.formValues['routingParam_'+ i])||{}).input=='duration' && NumberField) && 'Minutes'}
              multiple={(((this.props.formValues && components && (components.get(this.props.formValues['routingParam_'+ i])||{}).input=='selects') || (this.props.formValues && components && (components.get(this.props.formValues['routingParam_'+ i])||{}).input=='contains')) && SelectField)}
              options={options && options[(this.props.formValues && components && (components.get(this.props.formValues['routingParam_'+ i])||{}).options)]}
              placeholder='Routing Value' />
            </Form.Group>
          </Segment>)
        })
      }
    </>)
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.routingStore.getRouting!=undefined && this.props.routingStore.getRouting.progress=='1' ? this.props.routingStore.getRouting : {})
    const { progress, code, text }= (mode==2 ? (this.props.routingStore.updateRouting!=undefined ? this.props.routingStore.updateRouting : {}) : (this.props.routingStore.createRouting!=undefined ? this.props.routingStore.createRouting : {}))

    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    const listUser= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])
    const listAgent= (this.props.masterStore.listAgent!=undefined && this.props.masterStore.listAgent.progress=='1' ? this.props.masterStore.listAgent.data : [])

    const components= new Map();
    const options= {};

    (conditionOptions('routing-param')||[]).map(item=>{
      item.value && components.set(item.value, item);
    })
    options['privacyOptions']= ticketOptions('privacy');
    options['categoryOptions']= ticketOptions('category');
    options['priorityOptions']= ticketOptions('priority');
    options['severityOptions']= ticketOptions('severity');
    options['groupOptions']= groupOptions();

    options['listWorkgroup']= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    options['listUser']= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])
    options['listOrganization']= (this.props.masterStore.listOrganization!=undefined && this.props.masterStore.listOrganization.progress=='1' ? this.props.masterStore.listOrganization.data : [])
    options['listProduct']= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])
    options['listForm']= (this.props.masterStore.listForm!=undefined && this.props.masterStore.listForm.progress=='1' ? this.props.masterStore.listForm.data : [])
    options['listMacro']= (this.props.masterStore.listMacro!=undefined && this.props.masterStore.listMacro.progress=='1' ? this.props.masterStore.listMacro.data : [])
    options['listRemark']= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : [])
    options['listChannel']= (this.props.masterStore.listChannel!=undefined && this.props.masterStore.listChannel.progress=='1' ? this.props.masterStore.listChannel.data : [])

    return (
      <div style={{padding: '1em 1.5em'}}>
        <div style={{position: 'absolute', marginTop: '-3em', right: '1.7em', display: 'inline-block', background: '#222', padding: '.2em .5em .6em .6em', borderRadius: '.5em'}}><Icon name='window close' color='yellow' style={{cursor: 'pointer', padding: '0', margin: '0', fontSize: '2em'}} onClick={this.props.closeModal.bind(this, pristine)}/></div>

        <Header as='h4' style={{margin: '0 0 2em 0'}}>
          <Icon name='random' style={{fontSize: '1em'}}/>
          <Header.Content>
            Dispatch & Routing / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
          </Header.Content>
        </Header>

        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Grid stackable doubling>
            <Grid.Row columns={16}>
              <Grid.Column width={6}>
                <Header as='h5'>
                  <Header.Content>
                    Routing Title
                    <Header.Subheader>Ticket routing automation profile.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='name' component={InputField}
                    validate= {[required]}
                    label='Name'
                    placeholder='Name' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='descript' component={TextAreaField}
                    label='Description'
                    placeholder='Additional information' />
                  </Form.Group>
                </Segment>
                
                <Header as='h5'>
                  <Header.Content>
                    Destination
                    <Header.Subheader>Human or AI Bot dispatch destination.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='workgroups' component={SelectField}
                    multiple
                    options={listWorkgroup}
                    label='Route to: Teams / Workgroups'
                    placeholder='Dispatch to selected teams' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='agents' component={SelectField}
                    options={listAgent}
                    multiple
                    label='Route to: Agents / Frontliners'
                    placeholder='Dispatch to selected agents' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='groups' component={SelectField}
                    multiple
                    options={groupOptions()}
                    label='Route to: Roles / Groups'
                    placeholder='Dispatch to selected groups' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='users' component={SelectField}
                    multiple
                    options={listUser}
                    label='Route to: Users / Accounts'
                    placeholder='Dispatch to selected users' />
                  </Form.Group>
                </Segment>
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as='h5'>
                  <Header.Content>
                    Variables and Components
                    <Header.Subheader>Set conditions and terms of ticket routing.</Header.Subheader>
                  </Header.Content>
                </Header>
                
                {this.drawRouting(this.props.conditionRoutingQty, 'condition', components, options)}

                <div style={{textAlign: 'center', marginTop: '2em'}}>
                  <Button.Group size='mini'>
                    <Popup content='Remove / less attributes'  position= 'bottom center'
                    trigger={
                      <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, false, 'condition')}><Icon color='red' name='arrow up' size='large' /></Button>
                    }
                    inverted />
                    <Button.Or></Button.Or>
                    <Popup content='Add more attributes'  position= 'bottom center'
                    trigger={
                      <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, true, 'condition')}><Icon color='blue' name='arrow down' size='large' /></Button>
                    }
                    inverted />
                  </Button.Group>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Routing' : 'Insert Routing') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.routingStore.getRouting!=undefined && state.routingStore.getRouting.progress==1 ? state.routingStore.getRouting.data : {conditionRoutingQty: 1}),
      formValues: getFormValues('RoutingForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      conditionRoutingQty: selector(state, 'conditionRoutingQty'),
    }),
    { load: getRouting }
  )
  (reduxForm({
    form: 'RoutingForm',	// a unique identifier for this form
  })(RoutingForm)
  )
)
