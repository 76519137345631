import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import DismissableMessage from '../common/DismissableMessage'
import { personalOptions, groupOptions, conditionOptions, topicOptions }  from '../../modules/service/StaticMaster'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, SearchField, LabelSelectField, InsertableDropdownField, WysiwygField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email, phoneNumberID } from '../validation/validation'

import { connect } from "react-redux"
import { resetRequester, getRequester,updateRequester, createRequester } from "../../actions/service/requesterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('RequesterForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    requesterStore: state.requesterStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetRequester:(objParam) => dispatch(resetRequester(objParam)),
    getRequester:(objParam) => dispatch(getRequester(objParam)),
    createRequester:(objParam) => dispatch(createRequester(objParam)),
    updateRequester:(objParam) => dispatch(updateRequester(objParam)),
  }
}

class RequesterForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount(){
    this.props.resetRequester(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getRequester({requesterId: this.props.requesterId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.requesterStore.updateRequester!=undefined ? this.props.requesterStore.updateRequester : {}) : (this.props.requesterStore.createRequester!=undefined ? this.props.requesterStore.createRequester : {}))

    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateRequester({...values}) : this.props.createRequester({...values})
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.requesterStore.getRequester!=undefined && this.props.requesterStore.getRequester.progress=='1' ? this.props.requesterStore.getRequester : {})
    const { progress, code, text }= (mode==2 ? (this.props.requesterStore.updateRequester!=undefined ? this.props.requesterStore.updateRequester : {}) : (this.props.requesterStore.createRequester!=undefined ? this.props.requesterStore.createRequester : {}))

    return (
      <div style={{padding: '1em 1.5em'}}>
        <div style={{position: 'absolute', marginTop: '-3em', right: '1.7em', display: 'inline-block', background: '#222', padding: '.2em .5em .6em .6em', borderRadius: '.5em'}}><Icon name='window close' color='yellow' style={{cursor: 'pointer', padding: '0', margin: '0', fontSize: '2em'}} onClick={this.props.closeModal.bind(this, pristine)}/></div>
        
        <Header as='h4' style={{margin: '0 0 2em 0'}}>
          <Icon name='chess queen' style={{fontSize: '1em'}}/>
          <Header.Content>
            Requester / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
          </Header.Content>
        </Header>

        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Grid stackable doubling>
            <Grid.Row columns={16}>
              <Grid.Column width={6}>
                <Header as='h5'>
                  <Header.Content>
                    Personal
                    <Header.Subheader>Personal information.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='name' component={InputField}
                    label='Name'
                    placeholder='Full Name' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='gender' component={SelectField}
                    options={personalOptions('gender')}
                    placeholder='Gender' />
                    <Field name='occupation' component={SelectField}
                    options={personalOptions('occupation')}
                    placeholder='Occupation' />
                  </Form.Group>
                  {/* <Form.Group widths='equal'>
                    <Field name='gender' component={SelectField}
                    label='Gender'
                    placeholder='L/P' />
                    <Field name='marital' component={SelectField}
                    label='Marital'
                    placeholder='Marital' />
                  </Form.Group> */}
                  {/* <Form.Group widths='equal'>
                    <Field name='children' component={InputField}
                    label='Children'
                    placeholder='Children' />
                    <Field name='birthDate' component={DatePickerField}
                    label='Birth Date'
                    placeholder='Birth Date' />
                  </Form.Group> */}
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='address' component={InputField}
                    label='Address'
                    placeholder='Address' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='city' component={InputField}
                    placeholder='City' />
                    <Field name='province' component={InputField}
                    placeholder='Province' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='country' component={InputField}
                    placeholder='Country' />
                    <Field name='zipcode' component={InputField}
                    placeholder='Zipcode' />
                  </Form.Group>
                </Segment>

                <Header as='h5'>
                  <Header.Content>
                    Keywords
                    <Header.Subheader>Tags / keywords information.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='16'>
                    <Field name='tags' component={InsertableDropdownField}
                    multiple
                    width={16}
                    // validate= {[required]}
                    label='Tags / Taggers'
                    placeholder='Related keywords' />
                  </Form.Group>
                </Segment>
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as='h5'>
                  <Header.Content>
                    Corporate
                    <Header.Subheader>Corporate Information.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='corporate' component={InputField}
                    label='Corporate'
                    placeholder='Corporate' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='brand' component={InputField}
                    placeholder='Brand' />
                    <Field name='business' component={InputField}
                    placeholder='Business Stream' />
                  </Form.Group>
                </Segment>

                <Header as='h5'>
                  <Header.Content>
                    Phone Call
                    <Header.Subheader>Telephony contact.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='mobile' component={InputField}
                    validate={phoneNumberID}
                    label='Mobile'
                    placeholder='Handphone' />
                    <Form.Field />
                    <Form.Field />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='homePhone' component={InputField}
                    validate={phoneNumberID}
                    label='Home Phone'
                    placeholder='Home Phone' />
                    <Field name='officePhone' component={InputField}
                    validate={phoneNumberID}
                    label='Office Phone'
                    placeholder='Office Phone' />
                    <Field name='emergencyPhone' component={InputField}
                    validate={phoneNumberID}
                    label='Emergency Phone'
                    placeholder='Emergency Phone' />
                  </Form.Group>
                </Segment>
                <Header as='h5'>
                  <Header.Content>
                    Messaging
                    <Header.Subheader>Messaging contact.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='whatsapp' component={InputField}
                    validate={phoneNumberID}
                    label='Whatsapp'
                    placeholder='Whatsapp' />
                    <Form.Field />
                    <Form.Field />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='email' component={InputField}
                    validate={email}
                    label='Email'
                    placeholder='Email' />
                  </Form.Group>
                </Segment>

                <Header as='h5'>
                  <Header.Content>
                    Social Media
                    <Header.Subheader>Social media contact.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='twitter' component={InputField}
                    label='Twitter'
                    placeholder='Twitter' />
                    <Field name='instagram' component={InputField}
                    label='Instagram'
                    placeholder='Instagram' />
                    <Field name='facebook' component={InputField}
                    label='Facebook'
                    placeholder='Facebook' />
                  </Form.Group>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Requester' : 'Insert Requester') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.requesterStore.getRequester!=undefined && state.requesterStore.getRequester.progress==1 ? state.requesterStore.getRequester.data : {requesterTopicQty: 1}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      color: selector(state, 'color'),
      requesterTopicQty: selector(state, 'requesterTopicQty'),
    }),
    { load: getRequester }
  )
  (reduxForm({
    form: 'RequesterForm',	// a unique identifier for this form
  })(RequesterForm)
  )
)
