import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetMonitoring(objParam) {
  return {
      type: 'MONITORING_RESET',
      payload: objParam
  }
}

export function ticketMonitoring(objParam) {
  return {
      type: 'MONITORING_TICKET',
      payload:
        axios.post(
          urlAPI + 'service/monitoring/ticket',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function satisfactionMonitoring(objParam) {
  return {
      type: 'MONITORING_SATISFACTION',
      payload:
        axios.post(
          urlAPI + 'service/monitoring/satisfaction',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function complianceMonitoring(objParam) {
  return {
      type: 'MONITORING_COMPLIANCE',
      payload:
        axios.post(
          urlAPI + 'service/monitoring/compliance',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function occupancyMonitoring(objParam) {
  return {
      type: 'MONITORING_OCCUPANCY',
      payload:
        axios.post(
          urlAPI + 'service/monitoring/occupancy',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function segmentationMonitoring(objParam) {
  return {
      type: 'MONITORING_SEGMENTATION',
      payload:
        axios.post(
          urlAPI + 'service/monitoring/segmentation',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function populationMonitoring(objParam) {
  return {
      type: 'MONITORING_POPULATION',
      payload:
        axios.post(
          urlAPI + 'service/monitoring/population',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
