import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { format, parse } from "date-fns"
import {  } from "../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { browseAcknowledge, statusAcknowledge } from "../../actions/service/acknowledgeAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    acknowledgeStore: state.acknowledgeStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseAcknowledge:(objParam) => dispatch(browseAcknowledge(objParam)),
    statusAcknowledge:(objParam) => dispatch(statusAcknowledge(objParam)),
  }
}

class AcknowledgeBrowse extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remark: false,
      expand: false,
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.acknowledgeStore.browseAcknowledge!==undefined ? this.props.acknowledgeStore.browseAcknowledge : {})
    this.props.browseAcknowledge(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.acknowledgeStore.browseAcknowledge!==undefined ? this.props.acknowledgeStore.browseAcknowledge : {})
    this.props.browseAcknowledge({search: {...search, keyword: undefined, clientId: undefined, ticketId: undefined, status: undefined}, paging: paging})
  }

  onChangeStatus(id, status, e) {
    // console.log(id, status, e)
    this.props.statusAcknowledge({automationId:id, isEnabled: status})
  }
  setExpand() {
    const { expand }= this.state
    this.setState({
      expand: !expand
    })
  }
  setRemark() {
    const { remark }= this.state
    this.setState({
      remark: !remark
    })
  }

  render() {
    //console.log(this.props.acknowledgeStore.browseAcknowledge)
    const { expand, remark }= this.state
    const { screenWidth, screenHeight, modal }= this.props
    const { data, search, paging }= (this.props.acknowledgeStore.browseAcknowledge!==undefined ? this.props.acknowledgeStore.browseAcknowledge : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1', padding: '0'}}>
          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell width={6}>
                  <Header as='h5' color='blue'>INFORMATION</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={4}>
                  <Header as='h5' color='blue'>DELIVER TO</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  <Header as='h5' color='blue'>SENT</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  <Header as='h5' color='blue'>READ</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  <Header as='h5' color='blue'>FEEDBACK</Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      <Table.Cell textAlign='center'>
                        <Popup position= 'top left' inverted content='Edit'
                          trigger= {<Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.automationId, item.clientId)}/>}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.event}
                            <Header.Subheader>{item.company}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            <Header.Subheader>{item.segments}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            <Header.Subheader>{item.script}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={5}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AcknowledgeBrowse)
