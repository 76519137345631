import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetAcknowledge(objParam) {
  return {
      type: 'ACKNOWLEDGE_RESET',
      payload: objParam
  }
}

export function getAcknowledge(objParam) {
  return {
      type: 'ACKNOWLEDGE_GET',
      payload:
        axios.post(
          urlAPI + 'service/automation/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusAcknowledge(objParam) {
  return {
      type: 'ACKNOWLEDGE_STATUS',
      payload:
        axios.post(
          urlAPI + 'service/automation/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseAcknowledge(objParam) {
  return {
      type: 'ACKNOWLEDGE_BROWSE',
      payload:
        axios.post(
          urlAPI + 'service/automation/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createAcknowledge(objParam) {
  return {
      type: 'ACKNOWLEDGE_CREATE',
      payload:
        axios.post(
          urlAPI + 'service/automation/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateAcknowledge(objParam) {
  return {
      type: 'ACKNOWLEDGE_UPDATE',
      payload:
        axios.post(
          urlAPI + 'service/automation/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeAcknowledge(objParam) {
  return {
      type: 'ACKNOWLEDGE_REMOVE',
      payload:
        axios.post(
          urlAPI + 'service/automation/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
