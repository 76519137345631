
import React from "react"
import {ResponsiveContainer,
  Legend, Tooltip, Cell,
  RadarChart,PolarGrid,PolarRadiusAxis,PolarAngleAxis,Radar, } from 'recharts'

const themes= [
  ['#F279B1', '#775566', '#7F3F5D', '#992255', '#961D55', '#4C3D44', '#A8004D', '#881144', '#2B2B2B', '#660033'],
  ['#AD5AD8', '#799CF2', '#8899AA', '#149EC2', '#557766', '#118866', '#0085A8', '#3F527F', '#116688', '#3D494C'],
  ['#8b7a32', '#8e9431', '#566727', '#1d4504', '#092700', '#543a00', '#afb34a', '#80842b', '#393b00', '#4f5200'],
  ['#ffe6c8', '#e5c298', '#c39762', '#ae7f47', '#9a6c35', '#6d5800', '#a78700', '#d2aa00', '#ecbf00', '#ffd522'],
  ['#829b90', '#6f8a89', '#676d73', '#7c7a8f', '#6a6275', '#d4d7c1', '#8e988a', '#756a58', '#ac9e9e', '#958282'],
  ['#fd776c', '#cc6e6e', '#7b5a6d', '#6d7b5a', '#c8d794', '#6c7481', '#e28274', '#f4d2c8', '#fca4cb', '#b8c85e'],
  ['#69c6a5', '#81a0aa', '#468499', '#aed7e5', '#2f5866', '#72a49f', '#67737b', '#424e6a', '#bac4a4', '#738697'],
  ['#AD5AD8', '#799CF2', '#8899AA', '#149EC2', '#557766', '#118866', '#0085A8', '#3F527F', '#116688', '#3D494C'],
  ['#5FBA68', '#7A9CF2', '#8a99AA', '#1A9EC2', '#5a7766', '#1A8866', '#0085A8', '#3a527F', '#116688', '#3a494C'],
  ['#5DCAD8', '#795CF2', '#8859AA', '#149AC2', '#557566', '#118566', '#0045A8', '#3e527F', '#116088', '#3D494a'],
]

const dataDummy = [
  {name: 'Group A', value: 400, amount: 100, qty: 150}, 
  {name: 'Group B', value: 300, amount: 100, qty: 150},
  {name: 'Group C', value: 300, amount: 100, qty: 150}, 
  {name: 'Group D', value: 200, amount: 100, qty: 150}
]
const legendDummy = [
  {id: 'value', name: 'Value'}, 
  {id: 'amount', name: 'Amount'}, 
  {id: 'qty', name: 'Qty'}, 
]

class RadarRechart extends React.Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }
  
	render () {
    const { data, legend, width, height, mode, fill } = this.props
    const colors_fill= themes[Math.floor((Math.random() * 10))]
    const colors_stroke= themes[Math.floor((Math.random() * 10))]
    let i= 0

  	return (
      <div>
        <div style={{ width: width || 'auto', height: height || 250, margin: '1em auto' }}>
          <ResponsiveContainer>
            <RadarChart outerRadius={(height || 250)/2-40} data={data || dataDummy}>
              <PolarGrid />
              <PolarAngleAxis dataKey="name" />
              <PolarRadiusAxis  angle={30} domain={[0, 150]} />
              {
                (legend || legendDummy).map((value, i)=><Radar key={++i} dataKey={value.id} stroke={colors_stroke[++i]} fill={colors_fill[++i]} fillOpacity={0.5} />)
              }
              <Legend />
              <Tooltip />
            </RadarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

export default RadarRechart;
