import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'
import ModalContainer from '../../libraries/common/ModalContainer';

//SYSTEM
import MenuLeft from '../../libraries/service/MenuLeftMypage'
import CoworkerWidget from '../../libraries/widget/service/CoworkerWidget';
import SuperiorWidget from '../../libraries/widget/service/SuperiorWidget';
import TeamWidget from '../../libraries/widget/service/TeamWidget';
import AssignedWidget from '../../libraries/widget/service/AssignedWidget';

import PipelineBody from './PipelineBody';
import StatisticBody from './StatisticBody';

import MyBucketBody from './MyBucketBody';
import MyTicketBody from './MyTicketBody';

import ActionBody from './ActionBody';

import AssignmentBody from './AssignmentBody';
import UpdatedBody from './UpdatedBody';
import RequesterBody from './RequesterBody';
import KnowledgeBody from './KnowledgeBody';


class ServiceWorkspaceBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { mode, module, screenWidth, screenHeight, mini, modal, params }= this.props

    return (
      <div style={{marginTop: '3em'}}>
        <div style={{display: 'flex'}}>
          {!mini && <div style={{width: '3em', textAlign: 'center'}}>
            <MenuLeft token={this.props.token} mode={this.props.mode} screenHeight={screenHeight} screenWidth={screenWidth} module={module} mini={mini} openModule={this.props.openModule} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}/>
          </div>}
          <div style={{flex: '1', padding: '1em .5em'}}>
            {(!mode || mode=='pipeline') && <PipelineBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} params={params}/>}
            {mode==='dashboard' && <StatisticBody module={module} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
            {mode==='bucket' && <MyBucketBody module={module} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}

            {mode==='myticket' && <MyTicketBody module={module} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={params.mode || mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
            {mode==='updated' && <UpdatedBody module={module} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}

            {mode==='requester' && <RequesterBody module={module} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
            {mode==='knowledge' && <KnowledgeBody module={module} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}

            {/* Recent Work | Recent Viewed | Assigned To Me | Starred | Ticket Queues: Assigned To Me, All Open, Unassigned Issue, Bugs, Open Support Issue, Feature Suggestion, Starred | Create Issue / Ticket */}
          </div>
          {/* <div style={{background: '#f8f8f8', padding: '2em 1.5em', width: 350}}>
            <AssignedWidget />
            
            <Divider hidden />
            <TeamWidget />
            <CoworkerWidget />
            <SuperiorWidget />
          </div> */}
        </div>
        {modal && modal.substring(0, 8)==='assigned' && <ModalContainer size='small' modal={modal && modal.substring(8)} content={<AssignmentBody screenWidth={screenWidth} screenHeight={screenHeight} params={params} closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
        {modal && modal.substring(0, 6)==='action' && <ModalContainer size='large' modal={modal && modal.substring(7)} content={<ActionBody screenWidth={screenWidth} screenHeight={screenHeight}  params={params} closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
      </div>
    )
  }
}

export default ServiceWorkspaceBody