export default function explorerReducer (state = {}, action) {
  switch (action.type) {
    case 'EXPLORER_RESET': {
        return({
            ...state,
            getExplorer: {},
            updateExplorer: {},
            createExplorer: {},
        })
    }

    case 'EXPLORER_BROWSE_PENDING': {
      return({...state, browseExplorer: {progress: 0}})
    }
    case 'EXPLORER_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseExplorer: {...api.browseExplorer.response, progress: 1, payload: action.payload}})
            return({...state, browseExplorer: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseExplorer: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'EXPLORER_BROWSE_REJECTED': {
        return({...state, browseExplorer: {progress: 2, payload: action.payload}})
    }
    
    case 'EXPLORER_GET_PENDING': {
        return({...state, getExplorer: {progress: 0}})
    }
    case 'EXPLORER_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getExplorer: {...api.getExplorer.response, progress: 1, payload: action.payload}})
            return({...state, getExplorer: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getExplorer: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'EXPLORER_GET_REJECTED': {
        return({...state, getExplorer: {progress: 2, payload: action.payload}})
    }

    case 'EXPLORER_STATUS_PENDING': {
      return({...state, statusExplorer: {progress: 0}})
    }
    case 'EXPLORER_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusExplorer: {...api.statusExplorer.response, progress: 1, payload: action.payload}})
            return({...state, statusExplorer: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusExplorer: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'EXPLORER_STATUS_REJECTED': {
        return({...state, statusExplorer: {progress: 2, payload: action.payload}})
    }

    case 'EXPLORER_CREATE_PENDING': {
      return({...state, createExplorer: {progress: 0}})
    }
    case 'EXPLORER_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createExplorer: {...api.createExplorer.response, progress: 1, payload: action.payload}})
            return({...state, createExplorer: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createExplorer: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'EXPLORER_CREATE_REJECTED': {
        return({...state, createExplorer: {progress: 2, payload: action.payload}})
    }

    case 'EXPLORER_UPDATE_PENDING': {
      return({...state, updateExplorer: {progress: 0}})
    }
    case 'EXPLORER_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateExplorer: {...api.updateExplorer.response, progress: 1, payload: action.payload}})
            return({...state, updateExplorer: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateExplorer: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'EXPLORER_UPDATE_REJECTED': {
        return({...state, updateExplorer: {progress: 2, payload: action.payload}})
    }

    case 'EXPLORER_REMOVE_PENDING': {
      return({...state, removeExplorer: {progress: 0}})
    }
    case 'EXPLORER_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeExplorer: {...api.removeExplorer.response, progress: 1, payload: action.payload}})
            return({...state, removeExplorer: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeExplorer: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'EXPLORER_REMOVE_REJECTED': {
        return({...state, removeExplorer: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}