import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
  Popup
} from 'semantic-ui-react'



class OmniwidgetDial extends Component {
  constructor(props){
  	super(props)
  	this.state = {
      keypad: false,
      dtmf: '',
      lastChange: null,
      value: '',
    }
  }

  onChangeText(e, v) {
    // const parent= this
    // this.setState({
    //   lastChange: new Date().getTime(),
    //   dtmf: v.value
    // })
    
    // setTimeout(()=>{
    //   if (new Date().getTime()-parent.state.lastChange>=500) {
    //     this.props.dtmfSend(this.state.dtmf);
    //     parent.setState({
    //       dtmf: null,
    //     })
    //   }
    // }, 500)
  }

  showKeypad() {
    this.setState({
      keypad: !this.state.keypad
    })
  }

  pressKeypad(v) {
    const dtmf= this.state.dtmf + v;
    const value= this.state.value + v;

    // console.log('!!! KEYPAD', v)

    this.setState({
      lastChange: new Date().getTime(),
      dtmf: dtmf,
      value: dtmf,
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-this.state.lastChange>=1000) {
        console.log('!!! DTMF', dtmf)
        dtmf!='' && this.props.dtmfSend(dtmf);
        this.setState({
          dtmf: '',
          keypad: false,
        })
      }
    }, 1000)
  }

  render() {
    return (
      <>
        <div style={{padding: '1em .5em', border: 'none', textAlign: 'center'}}>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1', marginRight: '.5em'}}>
              <Popup position= 'top left' inverted
                trigger={
                    <Input size='small' value={this.state.dtmf=='' ? this.state.value : this.state.dtmf} className='normal' label={<Button type='button' color={this.state.keypad ? null : 'yellow'} onClick={this.showKeypad.bind(this)}><Icon name='th' style={{margin: '0'}}/></Button>} readOnly fluid placeholder='Dialed number' style={{marginLeft: '.3em', fontSize: '1.2em', textAlign: 'left'}} /> 
                }
                content='Click to show keypad.'
                on={['hover']}
              />
            </div>
            {/* <div>
              <Button icon style={{padding: '1em .5em 1em .5em'}} onClick={this.props.dtmfSend.bind(this, dtmf)}><Icon style={{fontSize: '1.3em'}} name='train' /></Button>
            </div> */}
          </div>
        </div>
        {this.state.keypad &&
        <div style={{position: 'fixed', width: 222, background: 'rgba(255, 255, 255, .75)', zIndex: 9999}}>
          {/* <Divider style={{margin: '.3em 0 1.5em 0'}}/> */}
          <div style={{display: 'flex', textAlign: 'center', margin: '.5em 0'}}>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '1')}>1</Button>
            </div>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '2')}>2</Button>
            </div>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '3')}>3</Button>
            </div>
          </div>
          <div style={{display: 'flex', textAlign: 'center', margin: '.5em 0'}}>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '4')}>4</Button>
            </div>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '5')}>5</Button>
            </div>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '6')}>6</Button>
            </div>
          </div>
          <div style={{display: 'flex', textAlign: 'center', margin: '.5em 0'}}>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '7')}>7</Button>
            </div>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '8')}>8</Button>
            </div>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '9')}>9</Button>
            </div>
          </div>
          <div style={{display: 'flex', textAlign: 'center', margin: '.5em 0'}}>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '*')}>*</Button>
            </div>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '0')}>0</Button>
            </div>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '#')}>#</Button>
            </div>
          </div>
        </div>
        }
      </>
    )
  }
}

export default OmniwidgetDial