import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Dropdown, Statistic, Label } from 'semantic-ui-react'

import { parse, format, endOfMonth } from "date-fns"
import PieRechart from '../../chart/PieRechart';

import { connect } from "react-redux"
import { ticketMonitoring } from "../../../actions/service/monitoringAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    monitoringStore: state.monitoringStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ticketMonitoring:(objParam) => dispatch(ticketMonitoring(objParam)),
  }
}

class TeamWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      stamp: '',
      pieData: [
        {name: 'Response OK', value: 1},
        {name: 'Response NOK', value: 2},
        {name: 'Resolve OK', value: 3},
        {name: 'Resolve NOK', value: 4},
      ],
    };
  }
  
  componentDidUpdate() {
    const { pieData, stamp}= this.state
    const { data, search }= (this.props.monitoringStore.ticketMonitoring!==undefined ? this.props.monitoringStore.ticketMonitoring : {})
  }
  
  render() {
    const { screenWidth,  screenHeight}= this.props
    const { pieData}= this.state
    
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <Header as='h5'>
          <Icon name='fire' style={{fontSize: '1em'}} />
          <Header.Content>
            <b><i>Dispatch !</i></b> Team Member
            {/* <Header.Subheader>Ticket response and resolve time based on SLA.</Header.Subheader> */}
          </Header.Content>
        </Header>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em'}}>
        <Label.Group>
          <Label image as='a' size='medium' color='teal'>
            <img src='https://react.semantic-ui.com/images/avatar/small/ade.jpg' />
            Vika
            <Icon name='delete' />
          </Label>
          <Label image as='a' size='medium' color='teal'>
            <img src='https://react.semantic-ui.com/images/avatar/small/zoe.jpg' />
            Aisha
            <Icon name='delete' />
          </Label>
          <Label image as='a' size='medium' color='teal'>
            <img src='https://react.semantic-ui.com/images/avatar/small/nan.jpg' />
            Javier
            <Icon name='delete' />
          </Label>
        </Label.Group>
      </Segment>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TeamWidget)