import React, { Component } from 'react';
import { 
  Menu,
  Icon,
  Header,
  Popup,
 } from 'semantic-ui-react'

 const options= [
  {
    key: '1',
    icon: 'user outline',
    caption: 'Monitoring',
  },
  {
    key: '2',
    icon: 'clock outline',
    caption: 'Campaign',
  },
  {
    key: '3',
    icon: 'user circle outline',
    caption: 'Agent',
  },
]

const stylePopup = {
  borderRadius: '0',
  opacity: 1,
  padding: '.5em .8em',
  fontSize: '1em',
  background: '#000',
  color: '#fff',
  margin: '-.3em 0 0 .5em',
}

class MenuHorizontal extends Component {
  constructor(props) {
    super(props);
    this.state= {
      activeItem: 1
    }
  }
  
  componentDidMount() {
    this.props.activeItem && this.setState({
      activeItem: this.props.activeItem,
    })
  }

  handleItemClick(key) {
    if (this.props.onClick!=undefined)
      this.props.onClick(key)
    this.setState({ activeItem: key })
  }
  
  render() {
    const items= (this.props.options!=undefined ? this.props.options : options)
    const { activeItem } = this.state
    const { style, color, inverted,attached, simple, screenWidth } = this.props

    //console.log(this.props)
    if (items.length==0) {
      return(<></>)
    } else {
      return (
        <div style={{display: 'flex'}}>
          {!simple && 
          <div style={{padding: '.5em .4em 1.2em 1.2em'}}>
            <Header as='h3' color='grey'><Icon name='ellipsis horizontal' style={{fontSize: '.9em'}} color='red'/></Header>
          </div>}
          <div style={{flex: '1'}}>
            <Menu color={color} inverted={inverted} attached={attached} style={{display: 'inline-flex', textAlign: 'center', border: 'none',  borderRadius: '0', }}>
              { items.map((item, idx)=>{
                  if (item.hint) {
                    return(
                      <Popup key={item.key} style={stylePopup} header={item.hint.header} content={item.hint.content} position= 'bottom left' inverted
                      trigger={
                        <Menu.Item key={item.key} active={activeItem==item.key} onClick={this.handleItemClick.bind(this, item.key)}>
                          <Header as='h5' color={activeItem==item.key ? (color || 'blue') : 'black'}>
                            <Icon name={item.icon} style={{fontSize: '1.1em'}}/>
                            <Header.Content style={{textDecoration: activeItem==item.key ? 'underline' : 'none', fontWeight: activeItem==item.key ? '600' : '400'}}>{screenWidth<800 ? '' : item.caption}</Header.Content>
                          </Header>
                        </Menu.Item>
                      } />
                    )
                  } else {
                    return(
                      <Menu.Item key={item.key} active={activeItem==item.key} onClick={this.handleItemClick.bind(this, item.key)}>
                        <Header as='h5' color={activeItem==item.key ? (color || 'blue') : 'black'}>
                          <Icon name={item.icon} style={{fontSize: '1.1em'}}/>
                          <Header.Content style={{textDecoration: activeItem==item.key ? 'underline' : 'none', fontWeight: activeItem==item.key ? '600' : '400'}}>{item.caption}</Header.Content>
                        </Header>
                      </Menu.Item>
                    )
                  }
                })
              }
            </Menu>
          </div>
        </div>
      );
    }
  }
}

export default MenuHorizontal;