import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Label } from 'semantic-ui-react'

import { connect } from "react-redux"
import { listReport } from "../../actions/service/masterAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listReport:(objParam) => dispatch(listReport(objParam)),
  }
}


class MenuLeftReports extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      page: 1,
    };
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.masterStore.listReport!==undefined ? this.props.masterStore.listReport : {})
    this.props.listReport()
  }

  setPage(v) {
    this.setState({
      page: v,
    })
  }

  render() {
    const { page }= this.state
    const { screenWidth,  screenHeight }= this.props

    const listReport= (this.props.masterStore.listReport!=undefined && this.props.masterStore.listReport.progress=='1' ? this.props.masterStore.listReport.data : [])
    
    return (
      <div style={{display: 'flex', flexDirection: 'column', minHeight: screenHeight, height: '100%'}}>
        <div style={{padding: '2.5em .5em 1.5em 1em', }}>
          <div style={{float: 'right', marginTop: '-.5em', paddingRight: '.5em'}}>
            <Button.Group size='tiny'>
              <Popup content='1 of 2'  position= 'bottom center'
              trigger={
                <Button color={page==1 ? 'grey' : null} type='button' style={{padding: '.4em .2em', height: '1.8em', width: '3em'}} icon onClick={this.setPage.bind(this, 1)}><Icon name='arrow left' /></Button>
              }
              inverted />
              <Button.Or></Button.Or>
              <Popup content='2 of 2'  position= 'bottom center'
              trigger={
                <Button color={page==2 ? 'grey' : null} type='button' style={{padding: '.4em .2em', height: '1.8em', width: '3em'}} icon onClick={this.setPage.bind(this, 2)}><Icon name='arrow right' /></Button>
              }
              inverted />
            </Button.Group>
          </div>
          
          {page==1 && <>
            <Header as='h5' style={{fontWeight: 'bold', margin: '0 0 .5em 0'}}  color='black'>
              <Header.Content>
                <b>Ticket</b>
              </Header.Content>
            </Header>
            <Divider style={{margin: '.5em 0'}} />
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'ticket-performance')}
              color={this.props.mode=='ticket-performance' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='ticket-performance' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='ticket-performance' ? 'black' : 'grey'}/> Performance
                </Header.Content>
                {this.props.mode=='ticket-performance' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'ticket-activity')}
              color={this.props.mode=='ticket-activity' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='ticket-activity' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='ticket-activity' ? 'black' : 'grey'}/> Recent Activity
                </Header.Content>
                {this.props.mode=='ticket-activity' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'ticket-timeline')}
              color={this.props.mode=='ticket-timeline' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='ticket-timeline' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='ticket-timeline' ? 'black' : 'grey'}/> Timeline Log
                </Header.Content>
                {this.props.mode=='ticket-timeline' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
            <Divider style={{margin: '.5em 0'}} />
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'ticket-classification')}
              color={this.props.mode=='ticket-classification' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='ticket-classification' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='ticket-classification' ? 'black' : 'grey'}/> Classification
                </Header.Content>
                {this.props.mode=='ticket-classification' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'ticket-service')}
              color={this.props.mode=='ticket-service' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='ticket-service' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='ticket-service' ? 'black' : 'grey'}/> Service & Product
                </Header.Content>
                {this.props.mode=='ticket-service' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'ticket-resolution')}
              color={this.props.mode=='ticket-resolution' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='ticket-resolution' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='ticket-resolution' ? 'black' : 'grey'}/> Closing & Resolution
                </Header.Content>
                {this.props.mode=='ticket-resolution' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
            
            <Header as='h5' style={{fontWeight: 'bold', margin: '1.5em 0 0 0'}}  color='black'>
              <Header.Content>
                <b>People</b>
              </Header.Content>
            </Header>
            <Divider style={{margin: '.5em 0'}} />
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'people-workload')}
              color={this.props.mode=='people-workload' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='people-workload' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='people-workload' ? 'black' : 'grey'}/> Workload
                </Header.Content>
                {this.props.mode=='people-workload' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'people-performance')}
              color={this.props.mode=='people-performance' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='people-performance' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='people-performance' ? 'black' : 'grey'}/> Performance
                </Header.Content>
                {this.props.mode=='people-performance' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
            <Divider style={{margin: '.5em 0'}} />
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'people-responses')}
              color={this.props.mode=='people-responses' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='people-responses' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='people-responses' ? 'black' : 'grey'}/> Responses
                </Header.Content>
                {this.props.mode=='people-responses' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'people-resolves')}
              color={this.props.mode=='people-resolves' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='people-resolves' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='people-resolves' ? 'black' : 'grey'}/> Updates & Resolves
                </Header.Content>
                {this.props.mode=='people-resolves' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>

            <Header as='h5' style={{fontWeight: 'bold', margin: '1.5em 0 0 0'}}  color='black'>
              <Header.Content>
                <b>Customer</b>
              </Header.Content>
            </Header>
            <Divider style={{margin: '.5em 0'}} />
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'customer-customer')}
              color={this.props.mode=='customer-customer' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='customer-customer' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='customer-customer' ? 'black' : 'grey'}/> Customer
                </Header.Content>
                {this.props.mode=='customer-customer' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'customer-cluster')}
              color={this.props.mode=='customer-cluster' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='customer-cluster' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='customer-cluster' ? 'black' : 'grey'}/> Segment & Cluster
                </Header.Content>
                {this.props.mode=='customer-cluster' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'customer-corporate')}
              color={this.props.mode=='customer-corporate' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='customer-corporate' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='customer-corporate' ? 'black' : 'grey'}/> Coporate & Organization
                </Header.Content>
                {this.props.mode=='customer-corporate' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>

            <Header as='h5' style={{fontWeight: 'bold', margin: '1.5em 0 0 0'}}  color='black'>
              <Header.Content>
                <b>SLA & CSAT</b>
              </Header.Content>
            </Header>
            <Divider style={{margin: '.5em 0'}} />
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'csat-satisfaction')}
              color={this.props.mode=='csat-satisfaction' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='csat-satisfaction' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='csat-satisfaction' ? 'black' : 'grey'}/> CSAT Rating
                </Header.Content>
                {this.props.mode=='csat-satisfaction' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'csat-feedbacks')}
              color={this.props.mode=='csat-feedbacks' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='csat-feedbacks' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='csat-feedbacks' ? 'black' : 'grey'}/> CSAT Feedbacks
                </Header.Content>
                {this.props.mode=='csat-feedbacks' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
            <Divider style={{margin: '.5em 0'}} />
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'sla-performance')}
              color={this.props.mode=='sla-performance' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='sla-performance' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='sla-performance' ? 'black' : 'grey'}/> SLA Performance
                </Header.Content>
                {this.props.mode=='sla-performance' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'sla-breach')}
              color={this.props.mode=='sla-breach' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='sla-breach' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='sla-breach' ? 'black' : 'grey'}/> SLA Alert & Breach
                </Header.Content>
                {this.props.mode=='sla-breach' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
          </>}
          {page==2 && <>
            <Header as='h5' style={{fontWeight: 'bold', margin: '0 0 .5em 0'}}  color='black'>
              <Header.Content>
                <b>OJK Reports</b>
              </Header.Content>
            </Header>
            <Divider style={{margin: '.5em 0'}} />
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'ojk-product')}
              color={this.props.mode=='ojk-product' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='ojk-product' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='ojk-product' ? 'black' : 'grey'}/> Product Classification
                </Header.Content>
                {this.props.mode=='ojk-product' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'ojk-channel')}
              color={this.props.mode=='ojk-channel' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='ojk-channel' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='ojk-channel' ? 'black' : 'grey'}/> Channel Classification
                </Header.Content>
                {this.props.mode=='ojk-channel' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'ojk-reasoning')}
              color={this.props.mode=='ojk-reasoning' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='ojk-reasoning' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='ojk-reasoning' ? 'black' : 'grey'}/> Reasoning Classification
                </Header.Content>
                {this.props.mode=='ojk-reasoning' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'ojk-duration')}
              color={this.props.mode=='ojk-duration' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='ojk-duration' ? '600' : '100'}}>
                <Header.Content>
                  <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={this.props.mode=='ojk-duration' ? 'black' : 'grey'}/> Solving/Closing Duration
                </Header.Content>
                {this.props.mode=='ojk-duration' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
          </>}
        </div>
        <div style={{padding: '1.5em .5em 1.5em 1em' }}>
          {page==2 && <>
            <Header as='h5' style={{fontWeight: 'bold', margin: '0 0 0 0'}}  color='blue'>
              <Header.Content>
                <b>Report & Analytic</b>
                <Header.Subheader>Custom report would be shown here.</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider style={{margin: '.5em 0'}} />

            {listReport && listReport.map(item=>{
              return(
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this,'custom-'+ item.value, item.metadata)}
                  color={(this.props.mode=='custom-'+ item.value) ? null : 'grey'}
                  style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: (this.props.mode=='custom-'+ item.value) ? '600' : '100'}}>
                  <Header.Content>
                    <Icon name='file alternate outline' style={{fontSize: '.8em', margin: '0 .5em 0 0'}} color={(this.props.mode=='custom-'+ item.value) ? 'black' : 'grey'}/> {item.text}
                  </Header.Content>
                  {(this.props.mode=='custom-'+ item.value) && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
                </Header>
              )
            })}
          </>}
          {/*             
          <Header as='h5' style={{fontWeight: 'bold', margin: '0'}}  color='black'>
            <Header.Content>
              <b>Agent / Frontliner</b>
            </Header.Content>
          </Header>
          <Popup position= 'right center' inverted content='Workload Based Performance' trigger= {
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'workload')}
              color={this.props.mode=='workload' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='workload' ? '600' : '100'}}>
                <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                  <Icon name='file alternate outline' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='workload' ? 'black' : 'grey'}/></Label> Agent's Workload
                </Header.Content>
                {this.props.mode=='workload' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
          } />
          <Divider style={{margin: '.5em 0'}} />
          <Popup position= 'right center' inverted content='Ticket Handling Performance' trigger= {
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'performance')}
              color={this.props.mode=='performance' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='performance' ? '600' : '100'}}>
                <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                  <Icon name='file alternate outline' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='performance' ? 'black' : 'grey'}/></Label> Agent's Performance
                </Header.Content>
                {this.props.mode=='performance' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
          } />

          <Header as='h5' style={{fontWeight: 'bold', margin: '2em 0 0 0'}}  color='black'>
            <Header.Content>
              <b>Support & Backend</b>
            </Header.Content>
          </Header>
          <Popup position= 'right center' inverted content='Support & Backend Performance' trigger= {
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'satisfaction')}
              color={this.props.mode=='satisfaction' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='satisfaction' ? '600' : '100'}}>
                <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                  <Icon name='file alternate outline' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='satisfaction' ? 'black' : 'grey'}/></Label> Internal Support
                </Header.Content>
                {this.props.mode=='satisfaction' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
          } />
          <Divider style={{margin: '.5em 0'}} />
          <Popup position= 'right center' inverted content='Vendor & Partner Performance' trigger= {
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'satisfaction')}
              color={this.props.mode=='satisfaction' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='satisfaction' ? '600' : '100'}}>
                <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                  <Icon name='file alternate outline' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='satisfaction' ? 'black' : 'grey'}/></Label> Vendor Support
                </Header.Content>
                {this.props.mode=='satisfaction' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
          } />
          

          <Header as='h5' style={{fontWeight: 'bold', margin: '2em 0 0 0'}}  color='black'>
            <Header.Content>
              <b>Service Delivery</b>
            </Header.Content>
          </Header>
          <Popup position= 'right center' inverted content='Customer Satisfaction Scoring' trigger= {
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'satisfaction')}
              color={this.props.mode=='satisfaction' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='satisfaction' ? '600' : '100'}}>
                <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                  <Icon name='file alternate outline' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='satisfaction' ? 'black' : 'grey'}/></Label> Cust. Satisfaction
                </Header.Content>
                {this.props.mode=='satisfaction' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
          } />
          <Divider style={{margin: '.5em 0'}} />
          <Popup position= 'right center' inverted content='SLA Based Performance' trigger= {
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'delivery')}
              color={this.props.mode=='delivery' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='delivery' ? '600' : '100'}}>
                <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                  <Icon name='file alternate outline' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='delivery' ? 'black' : 'grey'}/></Label> SLA Performance
                </Header.Content>
                {this.props.mode=='delivery' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
          } />
          <Divider style={{margin: '.5em 0'}} />
          <Popup position= 'right center' inverted content='Ticket Resolution Summary' trigger= {
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'resolution')}
              color={this.props.mode=='resolution' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='resolution' ? '600' : '100'}}>
                <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                  <Icon name='file alternate outline' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='resolution' ? 'black' : 'grey'}/></Label> Ticket Resolution
                </Header.Content>
                {this.props.mode=='resolution' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
          } />
          <Popup position= 'right center' inverted content='Ticket Categorisation/Segmentation Summary' trigger= {
            <Header as='h5'
              onClick={this.props.openWindow.bind(this,'categorization')}
              color={this.props.mode=='categorization' ? null : 'grey'}
              style={{margin: '.5em 0 0 0', cursor: 'pointer', fontWeight: this.props.mode=='categorization' ? '600' : '100'}}>
                <Header.Content><Label style={{width: '2.5em', padding: '.4em .3em', background: 'rgba(255, 255, 255, .15)', textAlign: 'center', border: '1px solid rgba(100, 100, 100, .5)', margin: '0'}}>
                  <Icon name='file alternate outline' style={{fontSize: '1.4em', margin: '0'}} color={this.props.mode=='categorization' ? 'black' : 'grey'}/></Label> Ticket Categorization
                </Header.Content>
                {this.props.mode=='categorization' && <div className='arrowmenuadmin1' style={{right: '-.8em'}} />}
            </Header>
          } /> 
          */}
        </div>
        <div style={{flex: '1', padding: '1.5em .5em 1.5em 1em' }}>

        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuLeftReports)