import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { groupOptions, conditionOptions, ticketOptions, bucketOptions }  from '../../modules/service/StaticMaster'

import { SubmissionError, Field, reduxForm, change, formValueSelector, getFormValues } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, LabelNumberField, DatePickerField, MaskedField, InsertableDropdownField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetBucket, getBucket,updateBucket, createBucket } from "../../actions/service/bucketAction"
import { listUser, listWorkgroup, listOrganization, listProduct, listForm, listMacro, listRemark, listChannel } from "../../actions/service/masterAction"


//CONNECT REDUX STORE
const selector = formValueSelector('BucketForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    bucketStore: state.bucketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listUser:(objParam) => dispatch(listUser(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    listOrganization:(objParam) => dispatch(listOrganization(objParam)),
    listProduct:(objParam) => dispatch(listProduct(objParam)),
    listForm:(objParam) => dispatch(listForm(objParam)),
    listMacro:(objParam) => dispatch(listMacro(objParam)),
    listRemark:(objParam) => dispatch(listRemark(objParam)),
    listChannel:(objParam) => dispatch(listChannel(objParam)),

    resetBucket:(objParam) => dispatch(resetBucket(objParam)),
    getBucket:(objParam) => dispatch(getBucket(objParam)),
    createBucket:(objParam) => dispatch(createBucket(objParam)),
    updateBucket:(objParam) => dispatch(updateBucket(objParam)),
  }
}

class BucketForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      // userConditionsQty: {}, groupConditionsQty: {},//temporary conditions mapping qty {1: 0, 2: 10}
    }
  }

  componentDidMount() {
    this.props.listUser()
    this.props.listWorkgroup()
    this.props.listOrganization()
    this.props.listProduct()
    this.props.listForm()
    this.props.listMacro()
    this.props.listRemark()
    this.props.listChannel()

    this.props.resetBucket(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getBucket({bucketId: this.props.bucketId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.bucketStore.updateBucket!=undefined ? this.props.bucketStore.updateBucket : {}) : (this.props.bucketStore.createBucket!=undefined ? this.props.bucketStore.createBucket : {}))

    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateBucket({
      ...values,
      userConditionsQty: this.state.userConditionsQty,
      groupConditionsQty: this.state.groupConditionsQty,
    }) : this.props.createBucket({
      ...values,
      userConditionsQty: this.state['userConditionsQty'],
      groupConditionsQty: this.state['groupConditionsQty'],
    })
  }

  incrRowQty(isIncrease, kind, i) {
    // console.log(isIncrease, kind, i)

    if (i!=undefined) { //conditions
      const conditionsQty= this.state[kind +'ConditionsQty'] || this.props[kind +'ConditionsQty'] || [];

      if (isIncrease) {
        if (conditionsQty[i] && conditionsQty[i]<10) {
          conditionsQty[i]= ((isNaN(conditionsQty[i]) ? 1 : parseInt(conditionsQty[i])) +1)
        } else {
          conditionsQty[i]= 1;
        }
      } else {
        if (conditionsQty[i] && conditionsQty[i]>0) {
          conditionsQty[i]= ((isNaN(conditionsQty[i]) ? 0 : parseInt(conditionsQty[i])) -1)
        } else {
          conditionsQty[i]= 0;
        }
      }
      // console.log('!!! CONDITIONS INCR', conditionsQty)
      const state= {};
      this.props.dispatch(change('BucketForm', kind +'conditionsQty', conditionsQty))
      state[kind +'ConditionsQty']= conditionsQty;
      this.setState({
        ...state
      })
    } else {
      if (isIncrease) {
        if (this.props[kind +'LayoutQty']<10) {
          this.props.dispatch(change('BucketForm', kind +'LayoutQty', ((isNaN(this.props[kind +'LayoutQty']) ? 0 : parseInt(this.props[kind +'LayoutQty'])) +1)))
        }
      } else {
        if (this.props[kind +'LayoutQty']>1) {
          this.props.dispatch(change('BucketForm', kind +'LayoutQty', ((isNaN(this.props[kind +'LayoutQty']) ? 0 : parseInt(this.props[kind +'LayoutQty'])) -1)))
        }
      }
    }
  }
  drawLayouts(qty=1, kind, components, options) {
    const layouts= this.props[kind +'Layouts'] || []
    const conditions= this.props[kind +'Conditions'] || []
    const conditionsQty= this.state[kind +'ConditionsQty'] || this.props[kind +'ConditionsQty'] || [];
    
    // console.log('!!! CONDITIONS DRAW', conditionsQty)
    const items= [],  conds= [];
    for (var i=0; i<qty; i++) {
      items[i]= {
        item: layouts[i] ? layouts[i].item : null,
      };

      const condqty= conditionsQty[i] || 0;
      for (var j=0; j<condqty; j++) {
        if (conds[i]) {
          conds[i].push((conditions[j] && conditions[j].item) || {})
        } else {
          conds[i]= [((conditions[j] && conditions[j].item) || {})]
        }
      }
    }

    return (<>
      <>
        {
          items.map((item, i)=>{
            return(<Segment key={i}>
              <Form.Group widths='16'>
                <Field name={kind +'Title_'+ i} component={SelectField}
                options={bucketOptions(kind)}
                width={6}
                validate= {[required]}
                label='View Title'
                placeholder='Title' />
                <Field name={kind +'Hint_'+ i} component={InputField}
                width={10}
                validate= {[required]}
                label='Short Desc'
                placeholder='Hint / Description' />
              </Form.Group>

              <Header as='h5' color='blue' style={{margin: '0 0 .3em 0'}}>
                <Header.Content>
                  Conditions
                </Header.Content>
              </Header>
              {!conds[i] && <><Divider /><b>No conditional policy defined, default will be applied.</b><Divider /></>}
              {conds[i] && conds[i].map((item, j)=>{ //conditions
                // console.log(components)
                return(
                  <Form.Group widths='16'>
                    <Field name={kind +'CondParam_'+ i +'_'+ j} component={SelectField} onChange={this.onChangeSelect.bind(this, kind +'{{PARAM}}_'+ i +'_'+ j)}
                    width={6}
                    options={conditionOptions('bucket-param')}
                    validate= {[required]}
                    // label='Action Type'
                    placeholder='Filter Param' />
                    <Field name={kind +'CondOperator_'+ i +'_'+ j} component={SelectField}
                    width={4}
                    options={conditionOptions('ops', (this.props.formValues && components && (components.get(this.props.formValues[kind +'CondParam_'+ i +'_'+ j])||{}).input))}
                    validate= {[required]}
                    // label='Action Type'
                    placeholder='Operator' />
                    <Field name={kind +'CondValue_'+ i +'_'+ j} component={
                      (this.props.formValues && components && (components.get(this.props.formValues[kind +'CondParam_'+ i +'_'+ j])||{}).input=='select' && SelectField) || 
                      (this.props.formValues && components && (components.get(this.props.formValues[kind +'CondParam_'+ i +'_'+ j])||{}).input=='selects' && SelectField) || 
                      (this.props.formValues && components && (components.get(this.props.formValues[kind +'CondParam_'+ i +'_'+ j])||{}).input=='contain' && SelectField) ||
                      (this.props.formValues && components && (components.get(this.props.formValues[kind +'CondParam_'+ i +'_'+ j])||{}).input=='contains' && SelectField) || 
                      (this.props.formValues && components && (components.get(this.props.formValues[kind +'CondParam_'+ i +'_'+ j])||{}).input=='duration' && LabelNumberField) || 
                      (this.props.formValues && components && (components.get(this.props.formValues[kind +'CondParam_'+ i +'_'+ j])||{}).input=='taggings' && InsertableDropdownField) || 
                      InputField
                    }
                    label={(this.props.formValues && components && (components.get(this.props.formValues[kind +'CondParam_'+ i +'_'+ j])||{}).input=='duration' && NumberField) && 'Minutes'}
                    multiple={(((this.props.formValues && components && (components.get(this.props.formValues[kind +'CondParam_'+ i +'_'+ j])||{}).input=='selects') || (this.props.formValues && components && (components.get(this.props.formValues[kind +'CondParam_'+ i +'_'+ j])||{}).input=='contains')) && SelectField)}
                    options={options && options[(this.props.formValues && components && (components.get(this.props.formValues[kind +'CondParam_'+ i +'_'+ j])||{}).options)]}
                    width={6}
                    validate= {[required]}
                    // label='Default Value'
                    placeholder='Filter Value' />
                  </Form.Group>
                )
              })}
              <div style={{textAlign: 'center', marginTop: '2em'}}>
                <Button.Group size='mini'>
                  <Popup content='Remove / less conditions'  position= 'bottom center'
                  trigger={
                    <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, false, kind, i)}><Icon color='red' name='arrow up' size='large' /></Button>
                  }
                  inverted />
                  <Button.Or></Button.Or>
                  <Popup content='Add more conditions'  position= 'bottom center'
                  trigger={
                    <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, true, kind, i)}><Icon color='blue' name='arrow down' size='large' /></Button>
                  }
                  inverted />
                </Button.Group>
              </div>
            </Segment>)
          })
        }
      </>
    </>)
  }

  onChangeSelect(v) {
    this.props.dispatch(change('BucketForm', v.replace('{{PARAM}}', 'CondOperator'), null));
    this.props.dispatch(change('BucketForm', v.replace('{{PARAM}}', 'CondValue'), null));
  }
  
  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.bucketStore.getBucket!=undefined && this.props.bucketStore.getBucket.progress=='1' ? this.props.bucketStore.getBucket : {})
    const { progress, code, text }= (mode==2 ? (this.props.bucketStore.updateBucket!=undefined ? this.props.bucketStore.updateBucket : {}) : (this.props.bucketStore.createBucket!=undefined ? this.props.bucketStore.createBucket : {}))

    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    const listUser= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])

    const components= new Map();
    const options= {};

    (conditionOptions('bucket-param')||[]).map(item=>{
      item.value && components.set(item.value, item);
    })
    options['privacyOptions']= ticketOptions('privacy');
    options['categoryOptions']= ticketOptions('category');
    options['priorityOptions']= ticketOptions('priority');
    options['severityOptions']= ticketOptions('severity');
    options['groupOptions']= groupOptions();

    options['listWorkgroup']= [
      // {key: 'xx1', value: 'NULL', text: 'NULL', description: 'NULL Value'}, {key: 'xx2', value: 'SELF', text: 'MY GROUP', description: 'SELF Value'}, 
      ...(this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    ]
    options['listUser']= [
      // {key: 'xx1', value: 'NULL', text: 'NULL', description: 'NULL Value'}, {key: 'xx2', value: 'SELF', text: 'MY ACCOUNT', description: 'SELF Value'}, 
      ...(this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])
    ]
    options['listOrganization']= (this.props.masterStore.listOrganization!=undefined && this.props.masterStore.listOrganization.progress=='1' ? this.props.masterStore.listOrganization.data : [])
    options['listProduct']= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])
    options['listForm']= (this.props.masterStore.listForm!=undefined && this.props.masterStore.listForm.progress=='1' ? this.props.masterStore.listForm.data : [])
    options['listMacro']= (this.props.masterStore.listMacro!=undefined && this.props.masterStore.listMacro.progress=='1' ? this.props.masterStore.listMacro.data : [])
    options['listRemark']= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : [])
    options['listChannel']= (this.props.masterStore.listChannel!=undefined && this.props.masterStore.listChannel.progress=='1' ? this.props.masterStore.listChannel.data : [])


    return (
      <div style={{padding: '1em 1.5em'}}>
        <div style={{position: 'absolute', marginTop: '-3em', right: '1.7em', display: 'inline-block', background: '#222', padding: '.2em .5em .6em .6em', borderRadius: '.5em'}}><Icon name='window close' color='yellow' style={{cursor: 'pointer', padding: '0', margin: '0', fontSize: '2em'}} onClick={this.props.closeModal.bind(this, pristine)}/></div>
        
        <Header as='h4' style={{margin: '0 0 2em 0'}}>
          <Icon name='archive' style={{fontSize: '1em'}}/>
          <Header.Content>
            Bucket / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
          </Header.Content>
        </Header>

        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Grid stackable doubling>
            <Grid.Row columns={16}>
              <Grid.Column width={5}>
                <Header as='h5'>
                  <Header.Content>
                    Bucket Title
                    <Header.Subheader>Short description of bucket.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='name' component={InputField}
                    validate= {[required]}
                    label='Name'
                    placeholder='Name' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='descript' component={TextAreaField}
                    validate= {[required]}
                    label='Description'
                    placeholder='Additional information' />
                  </Form.Group>
                </Segment>

                <Header as='h5'>
                  <Header.Content>
                    Availability & Access
                    <Header.Subheader>Applied bucket on certain privilege.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='workgroups' component={SelectField}
                    multiple
                    options={listWorkgroup}
                    label='Teams / Workgroups'
                    placeholder='Available for certain teams' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='groups' component={SelectField}
                    multiple
                    options={groupOptions()}
                    label='Roles / Groups'
                    placeholder='Available for certain groups' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='users' component={SelectField}
                    multiple
                    options={listUser}
                    label='Users / Accounts'
                    placeholder='Available for certain accounts' />
                  </Form.Group>
                </Segment>
              </Grid.Column>
              <Grid.Column width={11}>
                <div style={{display: 'flex', marginTop: '-.07em'}}>
                  <div style={{flex: '1'}}>
                    <Header as='h5'>
                      <Header.Content>
                        My Ticket Layout
                        <Header.Subheader>Set layout for widget and certain menu.</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div>
                    <Button type='button' icon='minus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this, false, 'user', null)} />
                  </div>
                  <div className='increment'>
                      <Field name='userLayoutQty' component={InputField} size='small' style={{height: '2.2em', width: '3.3em', borderRadius: '0'}} />
                  </div>
                  <div>
                    <Button type='button' icon='plus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this, true, 'user', null)} />
                  </div>
                </div>
                {this.drawLayouts(this.props.userLayoutQty, 'user', components, options)}
                    
                <div style={{display: 'flex', marginTop: '2em'}}>
                  <div style={{flex: '1'}}>
                    <Header as='h5'>
                      <Header.Content>
                        Team / Workgroup Ticket Layout
                        <Header.Subheader>Set layout for widget and certain menu.</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div>
                    <Button type='button' icon='minus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this, false, 'group', null)} />
                  </div>
                  <div className='increment'>
                      <Field name='groupLayoutQty' component={InputField} size='small' style={{height: '2.2em', width: '3.3em', borderRadius: '0'}} />
                  </div>
                  <div>
                    <Button type='button' icon='plus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrRowQty.bind(this, true, 'group', null)} />
                  </div>
                </div>
                
                {this.drawLayouts(this.props.groupLayoutQty, 'group', components, options)}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Bucket' : 'Insert Bucket') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={pristine || submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.bucketStore.getBucket!=undefined && state.bucketStore.getBucket.progress==1 ? state.bucketStore.getBucket.data : {userLayoutQty: 1, groupLayoutQty: 1}),
      formValues: getFormValues('BucketForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      userLayoutQty: selector(state, 'userLayoutQty'),
      groupLayoutQty: selector(state, 'groupLayoutQty'),
      userConditions: selector(state, 'userConditions'),
      groupConditions: selector(state, 'groupConditions'),
      userConditionsQty: selector(state, 'userConditionsQty'),
      groupConditionsQty: selector(state, 'groupConditionsQty'),
    }),
    { load: getBucket }
  )
  (reduxForm({
    form: 'BucketForm',	// a unique identifier for this form
  })(BucketForm)
  )
)
