export default function organizationReducer (state = {}, action) {
  switch (action.type) {
    case 'ORGANIZATION_RESET': {
        return({
            ...state,
            getOrganization: {},
            updateOrganization: {},
            createOrganization: {},
        })
    }

    case 'ORGANIZATION_BROWSE_PENDING': {
      return({...state, browseOrganization: {progress: 0}})
    }
    case 'ORGANIZATION_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseOrganization: {...api.browseOrganization.response, progress: 1, payload: action.payload}})
            return({...state, browseOrganization: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseOrganization: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ORGANIZATION_BROWSE_REJECTED': {
        return({...state, browseOrganization: {progress: 2, payload: action.payload}})
    }
    
    case 'ORGANIZATION_GET_PENDING': {
        return({...state, getOrganization: {progress: 0}})
    }
    case 'ORGANIZATION_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getOrganization: {...api.getOrganization.response, progress: 1, payload: action.payload}})
            return({...state, getOrganization: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getOrganization: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ORGANIZATION_GET_REJECTED': {
        return({...state, getOrganization: {progress: 2, payload: action.payload}})
    }

    case 'ORGANIZATION_STATUS_PENDING': {
      return({...state, statusOrganization: {progress: 0}})
    }
    case 'ORGANIZATION_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusOrganization: {...api.statusOrganization.response, progress: 1, payload: action.payload}})
            return({...state, statusOrganization: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusOrganization: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ORGANIZATION_STATUS_REJECTED': {
        return({...state, statusOrganization: {progress: 2, payload: action.payload}})
    }

    case 'ORGANIZATION_CREATE_PENDING': {
      return({...state, createOrganization: {progress: 0}})
    }
    case 'ORGANIZATION_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createOrganization: {...api.createOrganization.response, progress: 1, payload: action.payload}})
            return({...state, createOrganization: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createOrganization: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ORGANIZATION_CREATE_REJECTED': {
        return({...state, createOrganization: {progress: 2, payload: action.payload}})
    }

    case 'ORGANIZATION_UPDATE_PENDING': {
      return({...state, updateOrganization: {progress: 0}})
    }
    case 'ORGANIZATION_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateOrganization: {...api.updateOrganization.response, progress: 1, payload: action.payload}})
            return({...state, updateOrganization: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateOrganization: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ORGANIZATION_UPDATE_REJECTED': {
        return({...state, updateOrganization: {progress: 2, payload: action.payload}})
    }

    case 'ORGANIZATION_REMOVE_PENDING': {
      return({...state, removeOrganization: {progress: 0}})
    }
    case 'ORGANIZATION_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeOrganization: {...api.removeOrganization.response, progress: 1, payload: action.payload}})
            return({...state, removeOrganization: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeOrganization: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ORGANIZATION_REMOVE_REJECTED': {
        return({...state, removeOrganization: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}