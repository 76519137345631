import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Popup,
} from 'semantic-ui-react'

class ConfirmationModal extends Component {
  constructor(props) {
    super(props)
    this.state= {
      searchMode: true,
    }
  }
  
  render() {
    const { screenHeight, screenWidth }=this.props
    
    return (
      <div style={{padding: '3em 3em'}}>
        <div style={{minHeight: 200, margin: '0 auto'}}>
          <Header as='h3'>
            <Header.Content>
              <b><i>Closing!</i></b> Confirmation
              <Header.Subheader>Click to cancel for reopen previous active workspace.</Header.Subheader>
            </Header.Content>
          </Header>
          <Divider style={{marginBottom: '3em'}} />
          <Header as='h4' color='blue'>
            <Icon name='warning sign' color='black' style={{fontSize: '3.5em', paddingRight: '.3em'}}/>
            <Header.Content>
              Read This!
              <Header.Subheader style={{fontSize: '.95em'}}>
                Closing your current workingspace, cause leaving data unchanged and any updates will be ignored. <br /><br />Please be aware of your next action.
              </Header.Subheader>
            </Header.Content>
          </Header>
          
          <Divider style={{marginTop: '3em'}}/>
          <div style={{textAlign: 'right'}}>
            <Button type='button' size='large' content=' Cancel ' color='grey' onClick={this.props.closeModal.bind(this, false)} />
            <Button type='button' size='large' color='red' content=' Leave / Close ' onClick={this.props.closeModal.bind(this, true)} />
          </div>
        </div>
      </div>
    )
  }
}

export default ConfirmationModal