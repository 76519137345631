export default function requesterReducer (state = {}, action) {
  switch (action.type) {
    case 'REQUESTER_RESET': {
        return({
            ...state,
            getRequester: {},
            updateRequester: {},
            createRequester: {},
        })
    }

    case 'REQUESTER_BROWSE_PENDING': {
      return({...state, browseRequester: {progress: 0}})
    }
    case 'REQUESTER_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseRequester: {...api.browseRequester.response, progress: 1, payload: action.payload}})
            return({...state, browseRequester: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseRequester: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REQUESTER_BROWSE_REJECTED': {
        return({...state, browseRequester: {progress: 2, payload: action.payload}})
    }
    
    case 'REQUESTER_GET_PENDING': {
        return({...state, getRequester: {progress: 0}})
    }
    case 'REQUESTER_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getRequester: {...api.getRequester.response, progress: 1, payload: action.payload}})
            return({...state, getRequester: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getRequester: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REQUESTER_GET_REJECTED': {
        return({...state, getRequester: {progress: 2, payload: action.payload}})
    }

    case 'REQUESTER_STATUS_PENDING': {
      return({...state, statusRequester: {progress: 0}})
    }
    case 'REQUESTER_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusRequester: {...api.statusRequester.response, progress: 1, payload: action.payload}})
            return({...state, statusRequester: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusRequester: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REQUESTER_STATUS_REJECTED': {
        return({...state, statusRequester: {progress: 2, payload: action.payload}})
    }

    case 'REQUESTER_CREATE_PENDING': {
      return({...state, createRequester: {progress: 0}})
    }
    case 'REQUESTER_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createRequester: {...api.createRequester.response, progress: 1, payload: action.payload}})
            return({...state, createRequester: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createRequester: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REQUESTER_CREATE_REJECTED': {
        return({...state, createRequester: {progress: 2, payload: action.payload}})
    }

    case 'REQUESTER_UPDATE_PENDING': {
      return({...state, updateRequester: {progress: 0}})
    }
    case 'REQUESTER_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateRequester: {...api.updateRequester.response, progress: 1, payload: action.payload}})
            return({...state, updateRequester: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateRequester: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REQUESTER_UPDATE_REJECTED': {
        return({...state, updateRequester: {progress: 2, payload: action.payload}})
    }

    case 'REQUESTER_REMOVE_PENDING': {
      return({...state, removeRequester: {progress: 0}})
    }
    case 'REQUESTER_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeRequester: {...api.removeRequester.response, progress: 1, payload: action.payload}})
            return({...state, removeRequester: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeRequester: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REQUESTER_REMOVE_REJECTED': {
        return({...state, removeRequester: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}