import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import WorkgroupBrowse from '../../libraries/service/WorkgroupBrowse'
import WorkgroupForm from '../../libraries/service/WorkgroupForm'
import WorkgroupSearch from '../../libraries/service/WorkgroupSearch'
import ConfirmationModal from '../../libraries/service/ConfirmationModal';


class WorkgroupBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        workgroupId: 0,
      };
    }

    setMode(mode, id, param) {
      this.setState({mode: mode, workgroupId: id, param: param})
    }

    closeModal(pristine) { //true: no data change, false: data been changed
      if (pristine===false) {
        console.log(pristine)
        this.setState({
          modal: this.state.modal===1 ? 0 : 1, //1: confirmation
        });
      } else {
        this.setState({
          mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
          workgroupId: 0,

          modal: 0,
        });
      }
    }

    render() {
      const {mode, workgroupId, param, modal}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div>
          <WorkgroupSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
        
          {<WorkgroupBrowse mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} openModal={this.props.openModal} closeModal={this.closeModal.bind(this)} />}
          {/* {mode==2 && <WorkgroupForm mode={mode} workgroupId={workgroupId} param={param} setMode={this.setMode.bind(this)} />}
          {mode==3 && <WorkgroupForm mode={mode} workgroupId={0} param={param} setMode={this.setMode.bind(this)} />} */}
          {mode==2 && <ModalContainer headless size='small' content={<WorkgroupForm mode={mode} workgroupId={workgroupId} param={param} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
          {mode==3 && <ModalContainer headless size='small' content={<WorkgroupForm mode={mode} workgroupId={0} param={param} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}

          {modal==1 && <ModalContainer headless style={{marginTop: screenHeight/4}} size='tiny' content={<ConfirmationModal screenWidth={screenWidth} screenHeight={screenHeight-125} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
        </div>
      )
    }
}

export default WorkgroupBody
