import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import TicketBrowse from '../../libraries/service/TicketBrowse'
import TicketForm from '../../libraries/service/TicketForm'
import TicketSearch from '../../libraries/service/TicketSearch'



class SeverityBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        ticketId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, ticketId: id, clientId: clientId})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        ticketId: 0,
      });
    }

    render() {
      const {mode, ticketId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div>
          <TicketSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
        
          {<TicketBrowse mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} openModal={this.props.openModal} closeModal={this.closeModal.bind(this)} />}
          {/* {mode==2 && <TicketForm mode={mode} ticketId={ticketId} clientId={clientId} setMode={this.setMode.bind(this)} />}
          {mode==3 && <TicketForm mode={mode} ticketId={0} clientId={0} setMode={this.setMode.bind(this)} />} */}
          {mode==2 && <ModalContainer headless size='small' content={<TicketForm mode={mode} ticketId={ticketId} clientId={clientId} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
          {mode==3 && <ModalContainer headless size='small' content={<TicketForm mode={mode} ticketId={0} clientId={0} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
        </div>
      )
    }
}

export default SeverityBody
