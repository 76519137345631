import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { groupOptions, conditionOptions, ticketOptions, fieldOptions }  from '../../modules/service/StaticMaster'

import { SubmissionError, Field, reduxForm, change, formValueSelector, getFormValues } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, LabelInputField, LabelNumberField, LabelSelectField, InsertableDropdownField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetForms, getForms,updateForms, createForms } from "../../actions/service/formsAction"
import { listUser, listWorkgroup, listOrganization, listProduct, listForm, listField, listMacro, listRemark, listChannel } from "../../actions/service/masterAction"


//CONNECT REDUX STORE
const selector = formValueSelector('FormsForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    formsStore: state.formsStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listUser:(objParam) => dispatch(listUser(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    listOrganization:(objParam) => dispatch(listOrganization(objParam)),
    listProduct:(objParam) => dispatch(listProduct(objParam)),
    listForm:(objParam) => dispatch(listForm(objParam)),
    listField:(objParam) => dispatch(listField(objParam)),
    listMacro:(objParam) => dispatch(listMacro(objParam)),
    listRemark:(objParam) => dispatch(listRemark(objParam)),
    listChannel:(objParam) => dispatch(listChannel(objParam)),

    resetForms:(objParam) => dispatch(resetForms(objParam)),
    getForms:(objParam) => dispatch(getForms(objParam)),
    createForms:(objParam) => dispatch(createForms(objParam)),
    updateForms:(objParam) => dispatch(updateForms(objParam)),
  }
}

class FormsForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remarkResult: null,

      tab: 0,
    }
  }

  componentDidMount(){
    this.props.listUser()
    this.props.listWorkgroup()
    this.props.listOrganization()
    this.props.listProduct()
    this.props.listForm()
    this.props.listMacro()
    this.props.listRemark()
    this.props.listChannel()
    this.props.listField()

    this.props.resetForms(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getForms({formId: this.props.formId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.formsStore.updateForms!=undefined ? this.props.formsStore.updateForms : {}) : (this.props.formsStore.createForms!=undefined ? this.props.formsStore.createForms : {}))

    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateForms({...values}) : this.props.createForms({...values})
  }

  onChangeSelect(v) {
    this.props.dispatch(change('FormsForm', v.replace('{{PARAM}}', 'Operator'), null));
    this.props.dispatch(change('FormsForm', v.replace('{{PARAM}}', 'Value'), null));
  }

  incrRowQty(isIncrease, kind) {
    console.log(kind, this.props[kind +'FormQty'])
    if (isIncrease) {
      if (this.props[kind +'FormQty']<10) {
        this.props.dispatch(change('FormsForm', kind +'FormQty', ((isNaN(this.props[kind +'FormQty']) ? 0 : parseInt(this.props[kind +'FormQty'])) +1)))
      }
    } else {
      if (this.props[kind +'FormQty']>0) {
        this.props.dispatch(change('FormsForm', kind +'FormQty', ((isNaN(this.props[kind +'FormQty']) ? 0 : parseInt(this.props[kind +'FormQty'])) -1)))
      }
    }
  }

  drawForms(qty=1, kind, components, options) {
    const forms= this.props[kind +'Forms'] || []

    const listField= (this.props.masterStore.listField!=undefined && this.props.masterStore.listField.progress=='1' ? this.props.masterStore.listField.data : [])
    
    // console.log('!!! CONDITIONS DRAW', conditionsQty)
    const items= [];
    for (var i=0; i<qty; i++) {
      items[i]= {
        item: forms[i] ? forms[i].item : null,
      };
    }

    if (kind=='condition') {
      return (
        items.map((item, i)=>{
          return(
            <Form.Group key={i} widths='16'>
              <Field name={'condField_'+ i} component={SelectField} onChange={this.onChangeSelect.bind(this, 'cond{{PARAM}}_'+ i)}
              width={6}
              options={conditionOptions('forms-param')}
              validate= {[required]}
              placeholder='Attribute / Field' />
              <Field name={'condOperator_'+ i} component={SelectField}
              width={4}
              // options={conditionOptions('ops')}
              // options={conditionOptions('ops', (this.props.formValues && components && (components.get(this.props.formValues['condField_'+ i])||{}).input))}
              options={conditionOptions('forms-ops', (this.props.formValues && components && (components.get(this.props.formValues['condField_'+ i])||{}).input))}
              validate= {[required]}
              placeholder='Operator' />
              <Field name={'condValue_'+ i} component={
                (this.props.formValues && components && (components.get(this.props.formValues['condField_'+ i])||{}).input=='select' && SelectField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['condField_'+ i])||{}).input=='selects' && SelectField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['condField_'+ i])||{}).input=='contain' && SelectField) ||
                (this.props.formValues && components && (components.get(this.props.formValues['condField_'+ i])||{}).input=='contains' && SelectField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['condField_'+ i])||{}).input=='duration' && LabelNumberField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['condField_'+ i])||{}).input=='taggings' && InsertableDropdownField) || 
                InputField
              }
              label={(this.props.formValues && components && (components.get(this.props.formValues['condField_'+ i])||{}).input=='duration' && NumberField) && 'Minutes'}
              multiple={(((this.props.formValues && components && (components.get(this.props.formValues['condField_'+ i])||{}).input=='selects') || (this.props.formValues && components && (components.get(this.props.formValues['condField_'+ i])||{}).input=='contains')) && SelectField)}
              options={options && options[(this.props.formValues && components && (components.get(this.props.formValues['condField_'+ i])||{}).options)]}
              width={6}
              validate= {[required]}
              placeholder='Conditional Value' />
            </Form.Group>
          )
        })
      )
    } else if (kind=='primary') {
      return (<>
        <>
          {
            items.map((item, i)=>{
              return(<>
                <Form.Group widths='16'>
                  <Field name={'primaryGrouping_'+ i} component={LabelSelectField}
                  width={8}
                  options={fieldOptions('grouping')}
                  label='UI Block'
                  placeholder='UI Grouping Name' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name={'primaryField_'+ i} component={SelectField}
                  multiple
                  options={[...fieldOptions('primary'), ...[<Divider key='ijk' style={{}} />, <Header key='vke' as='h5'><Icon name='sort alphabet up' /> CUSTOM FIELD</Header>, <Divider key='xyz' style={{}} />], ...listField]}
                  validate= {[required]}
                  placeholder='Selected primary form attributes / fields' />
                </Form.Group>
              </>)
            })
          }
        </>
      </>)
    } else {
      return (<>
        <>
          {
            items.map((item, i)=>{
              return(<>
                <Form.Group widths='16'>
                  <Field name={'secondaryGrouping_'+ i} component={LabelSelectField}
                  width={8}
                  options={fieldOptions('grouping')}
                  label='UI Block'
                  placeholder='UI Grouping Name' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name={'secondaryField_'+ i} component={SelectField}
                  multiple
                  options={[...fieldOptions('secondary'), ...[<Divider key='ijk' style={{}} />, <Header key='vke' as='h5'><Icon name='sort alphabet up' /> CUSTOM FIELD</Header>, <Divider key='xyz' style={{}} />], ...listField]}
                  validate= {[required]}
                  placeholder='Selected secondary form attributes / fields' />
                </Form.Group>
              </>)
            })
          }
        </>
      </>)
    }
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.formsStore.getForms!=undefined && this.props.formsStore.getForms.progress=='1' ? this.props.formsStore.getForms : {})
    const { progress, code, text }= (mode==2 ? (this.props.formsStore.updateForms!=undefined ? this.props.formsStore.updateForms : {}) : (this.props.formsStore.createForms!=undefined ? this.props.formsStore.createForms : {}))

    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    const listUser= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])
    const listProduct= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])

    const components= new Map();
    const options= {};

    (conditionOptions('forms-param')||[]).map(item=>{
      item.value && components.set(item.value, item);
    })
    options['privacyOptions']= ticketOptions('privacy');
    options['categoryOptions']= ticketOptions('category');
    options['priorityOptions']= ticketOptions('priority');
    options['severityOptions']= ticketOptions('severity');
    options['groupOptions']= groupOptions();

    options['listWorkgroup']= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    options['listUser']= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])
    options['listOrganization']= (this.props.masterStore.listOrganization!=undefined && this.props.masterStore.listOrganization.progress=='1' ? this.props.masterStore.listOrganization.data : [])
    options['listProduct']= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])
    options['listForm']= (this.props.masterStore.listForm!=undefined && this.props.masterStore.listForm.progress=='1' ? this.props.masterStore.listForm.data : [])
    options['listMacro']= (this.props.masterStore.listMacro!=undefined && this.props.masterStore.listMacro.progress=='1' ? this.props.masterStore.listMacro.data : [])
    options['listRemark']= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : [])
    options['listChannel']= (this.props.masterStore.listChannel!=undefined && this.props.masterStore.listChannel.progress=='1' ? this.props.masterStore.listChannel.data : [])


    return (
      <div style={{padding: '1em 1.5em'}}>
        <div style={{position: 'absolute', marginTop: '-3em', right: '1.7em', display: 'inline-block', background: '#222', padding: '.2em .5em .6em .6em', borderRadius: '.5em'}}><Icon name='window close' color='yellow' style={{cursor: 'pointer', padding: '0', margin: '0', fontSize: '2em'}} onClick={this.props.closeModal.bind(this, pristine)}/></div>
        
        <Header as='h4' style={{margin: '0 0 2em 0'}}>
          <Icon name='window maximize outline' style={{fontSize: '1em'}}/>
          <Header.Content>
            Custom Forms / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
          </Header.Content>
        </Header>

        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Grid stackable doubling>
            <Grid.Row columns={16}>
              <Grid.Column width={6}>
                <Header as='h5'>
                  <Header.Content>
                    Form Title
                    <Header.Subheader>Short description of forms.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='name' component={InputField}
                    validate= {[required]}
                    label='Name'
                    placeholder='Name' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='descript' component={TextAreaField}
                    label='Description'
                    placeholder='Additional information' />
                  </Form.Group>
                </Segment>

                <Header as='h5'>
                  <Header.Content>
                    Access & Association
                    <Header.Subheader>Applied forms on certain products and groups.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='products' component={SelectField}
                    multiple
                    options={listProduct}
                    // validate= {[required]}
                    label='Product / Services'
                    placeholder='Associated product / service brand' />
                  </Form.Group>
                </Segment>

                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='workgroups' component={SelectField}
                    multiple
                    options={listWorkgroup}
                    label='Teams / Workgroups'
                    placeholder='Available for certain teams' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='groups' component={SelectField}
                    multiple
                    options={groupOptions()}
                    label='Roles / Groups'
                    placeholder='Available for certain groups' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='users' component={SelectField}
                    multiple
                    options={listUser}
                    label='Users / Accounts'
                    placeholder='Available for certain accounts' />
                  </Form.Group>
                </Segment>
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as='h5'>
                  <Header.Content>
                    Primary Form
                    <Header.Subheader>Set specific field as form attribute components.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Header as='h5'>
                    <Header.Content>
                      Fields / Attributes
                      <Header.Subheader>Set primary form attributes / fields.</Header.Subheader>
                    </Header.Content>
                  </Header>
                  {this.drawForms(this.props.primaryFormQty, 'primary', components, options)}

                  <div style={{textAlign: 'center', marginTop: '2em'}}>
                    <Button.Group size='mini'>
                      <Popup content='Remove / less attributes'  position= 'bottom center'
                      trigger={
                        <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, false, 'primary')}><Icon color='red' name='arrow up' size='large' /></Button>
                      }
                      inverted />
                      <Button.Or></Button.Or>
                      <Popup content='Add more attributes'  position= 'bottom center'
                      trigger={
                        <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, true, 'primary')}><Icon color='blue' name='arrow down' size='large' /></Button>
                      }
                      inverted />
                    </Button.Group>
                  </div>
                </Segment>

                <Header as='h5'>
                  <Header.Content>
                    Secondary / Conditional Form
                    <Header.Subheader>Set conditional form based on ref. fields value.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Header as='h5'>
                    <Header.Content>
                      Conditional Rule
                      <Header.Subheader>Forms will be shown when value meet these conditions.</Header.Subheader>
                    </Header.Content>
                  </Header>
                  {this.drawForms(this.props.conditionFormQty, 'condition', components, options)}

                  <div style={{textAlign: 'center', marginTop: '2em'}}>
                    <Button.Group size='mini'>
                      <Popup content='Remove / less attributes'  position= 'bottom center'
                      trigger={
                        <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, false, 'condition')}><Icon color='red' name='arrow up' size='large' /></Button>
                      }
                      inverted />
                      <Button.Or></Button.Or>
                      <Popup content='Add more attributes'  position= 'bottom center'
                      trigger={
                        <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, true, 'condition')}><Icon color='blue' name='arrow down' size='large' /></Button>
                      }
                      inverted />
                    </Button.Group>
                  </div>
                </Segment>

                <Segment>
                  <Header as='h5'>
                    <Header.Content>
                      Secondary Fields / Attributes
                      <Header.Subheader>Set secondary form attributes / fields.</Header.Subheader>
                    </Header.Content>
                  </Header>
                  {this.drawForms(this.props.secondaryFormQty, 'secondary', components, options)}

                  <div style={{textAlign: 'center', marginTop: '2em'}}>
                    <Button.Group size='mini'>
                      <Popup content='Remove / less attributes'  position= 'bottom center'
                      trigger={
                        <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, false, 'secondary')}><Icon color='red' name='arrow up' size='large' /></Button>
                      }
                      inverted />
                      <Button.Or></Button.Or>
                      <Popup content='Add more attributes'  position= 'bottom center'
                      trigger={
                        <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, true, 'secondary')}><Icon color='blue' name='arrow down' size='large' /></Button>
                      }
                      inverted />
                    </Button.Group>
                  </div>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Forms' : 'Insert Forms') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.formsStore.getForms!=undefined && state.formsStore.getForms.progress==1 ? state.formsStore.getForms.data : {primaryFormQty: 0, secondaryFormQty: 0, conditionFormQty: 0}),
      formValues: getFormValues('FormsForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      primaryFormQty: selector(state, 'primaryFormQty'),
      secondaryFormQty: selector(state, 'secondaryFormQty'),
      conditionFormQty: selector(state, 'conditionFormQty'),
    }),
    { load: getForms }
  )
  (reduxForm({
    form: 'FormsForm',	// a unique identifier for this form
  })(FormsForm)
  )
)
