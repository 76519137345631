import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table, TextArea, Label } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'
import MenuTabular from '../common/MenuTabular'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { calendarOptions }  from '../../modules/service/StaticMaster'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetCalendar, getCalendar,updateCalendar, createCalendar } from "../../actions/service/calendarAction"
import { listAgent, listWorkgroup } from "../../actions/service/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('CalendarForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    calendarStore: state.calendarStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listAgent:(objParam) => dispatch(listAgent(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),

    resetCalendar:(objParam) => dispatch(resetCalendar(objParam)),
    getCalendar:(objParam) => dispatch(getCalendar(objParam)),
    createCalendar:(objParam) => dispatch(createCalendar(objParam)),
    updateCalendar:(objParam) => dispatch(updateCalendar(objParam)),
  }
}

class CalendarForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount(){
    this.props.resetCalendar(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getCalendar({calendarId: this.props.calendarId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.calendarStore.updateCalendar!=undefined ? this.props.calendarStore.updateCalendar : {}) : (this.props.calendarStore.createCalendar!=undefined ? this.props.calendarStore.createCalendar : {}))

    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateCalendar({...values}) : this.props.createCalendar({...values})
  }

  incrRowQty(isIncrease, kind) {
    console.log(kind, this.props[kind +'CalendarQty'])
    if (isIncrease) {
      if (this.props[kind +'CalendarQty']<30) {
        this.props.dispatch(change('CalendarForm', kind +'CalendarQty', ((isNaN(this.props[kind +'CalendarQty']) ? 0 : parseInt(this.props[kind +'CalendarQty'])) +1)))
      }
    } else {
      if (this.props[kind +'CalendarQty']>1) {
        this.props.dispatch(change('CalendarForm', kind +'CalendarQty', ((isNaN(this.props[kind +'CalendarQty']) ? 0 : parseInt(this.props[kind +'CalendarQty'])) -1)))
      }
    }
  }

  drawCalendars(qty=1, kind) {
    const actions= this.props[kind +'Calendars'] || []
    
    // console.log('!!! CONDITIONS DRAW', conditionsQty)
    const items= [];
    for (var i=0; i<qty; i++) {
      items[i]= {
        item: actions[i] ? actions[i].item : null,
      };
    }

    return (<>
      {
        items.map((item, i)=>{
          return(<Segment key={i}>
            <Form.Group widths='16'>
              <Field name={'dateHoliday_'+ i} component={DatePickerField}
              width={6}
              validate= {[required]}
              placeholder='Date Calendar' />
              <Field name={'nameHoliday_'+ i} component={InputField}
              width={10}
              validate= {[required]}
              placeholder='Holiday Name / Title' />
            </Form.Group>
          </Segment>)
        })
      }
    </>)        
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.calendarStore.getCalendar!=undefined && this.props.calendarStore.getCalendar.progress=='1' ? this.props.calendarStore.getCalendar : {})
    const { progress, code, text }= (mode==2 ? (this.props.calendarStore.updateCalendar!=undefined ? this.props.calendarStore.updateCalendar : {}) : (this.props.calendarStore.createCalendar!=undefined ? this.props.calendarStore.createCalendar : {}))

    const days= calendarOptions('day-array');
    return (
      <div style={{padding: '1em 1.5em'}}>
        <div style={{position: 'fixed', marginTop: '-3em', right: '1.7em', display: 'inline-block', background: '#222', padding: '.2em .5em .6em .6em', borderRadius: '.5em'}}><Icon name='window close' color='yellow' style={{cursor: 'pointer', padding: '0', margin: '0', fontSize: '2em'}} onClick={this.props.closeModal.bind(this, pristine)}/></div>
      
        <Header as='h4' style={{margin: '0 0 2em 0'}}>
          <Icon name='calendar alternate outline' style={{fontSize: '1em'}}/>
          <Header.Content>
            Calendar / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
          </Header.Content>
        </Header>

        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Grid stackable doubling>
            <Grid.Row columns={16}>
              <Grid.Column width={6}>
                <Header as='h5'>
                  <Header.Content>
                    Calendar Title
                    <Header.Subheader>Short description of calendar.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='name' component={InputField}
                    validate= {[required]}
                    label='Name'
                    placeholder='Name' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='descript' component={TextAreaField}
                    label='Description'
                    placeholder='Additional information' />
                  </Form.Group>
                </Segment>

                <Header as='h5'>
                  <Header.Content>
                    Business Hours
                    <Header.Subheader>Office and business hours.</Header.Subheader>
                  </Header.Content>
                </Header>
                {this.props.businessDays && <Segment>
                  <Header as='h5' color='blue'>
                    <Header.Content>
                      Business Hours
                    </Header.Content>
                  </Header>
                  {(this.props.businessDays || []).map((item, i)=>{
                    return(
                      <Form.Group widths='16' key={i}>
                        <Form.Field width={6}>
                          <Label color={item>=6 ? 'red' : 'teal'} size='big' className='fluid' style={{fontSize: '1.1em', height: '2.6em', padding: '.8em 1em .8em 1em'}}>{days[item-1]}</Label>
                        </Form.Field>
                        <Field name={'openHour_'+ item} component={SelectField}
                        width={5} 
                        options={calendarOptions('hour')}
                        // label='Open'
                        placeholder='Open' />
                        <Field name={'closeHour_'+ item} component={SelectField}
                        width={5}
                        options={calendarOptions('hour')}
                        // label='Closed'
                        placeholder='Closed' />
                      </Form.Group>
                    )
                  })}
                  <Segment>
                    <Form.Group widths='equal'>
                      <Field name='businessDays' component={SelectField}
                      multiple
                      options={calendarOptions('day')}
                      label='Business Days'
                      placeholder='Business Days' />
                    </Form.Group>
                  </Segment>
                </Segment>}
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as='h5'>
                  <Header.Content>
                    Holiday / Day Off Calendars
                    <Header.Subheader>Set specific date as day off or holiday.</Header.Subheader>
                  </Header.Content>
                </Header>
                {this.drawCalendars(this.props.holidayCalendarQty, 'holiday')}

                <div style={{textAlign: 'center', marginTop: '2em'}}>
                  <Button.Group size='mini'>
                    <Popup content='Remove / less holidays'  position= 'bottom center'
                    trigger={
                      <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, false, 'holiday')}><Icon color='red' name='arrow up' size='large' /></Button>
                    }
                    inverted />
                    <Button.Or></Button.Or>
                    <Popup content='Add more holidays'  position= 'bottom center'
                    trigger={
                      <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, true, 'holiday')}><Icon color='blue' name='arrow down' size='large' /></Button>
                    }
                    inverted />
                  </Button.Group>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Calendar' : 'Insert Calendar') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.calendarStore.getCalendar!=undefined && state.calendarStore.getCalendar.progress==1 ? state.calendarStore.getCalendar.data : {holidayCalendarQty: 1}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      businessDays: selector(state, 'businessDays'),
      holidayCalendarQty: selector(state, 'holidayCalendarQty'),
    }),
    { load: getCalendar }
  )
  (reduxForm({
    form: 'CalendarForm',	// a unique identifier for this form
  })(CalendarForm)
  )
)
