export default function fieldsReducer (state = {}, action) {
  switch (action.type) {
    case 'FIELDS_RESET': {
        return({
            ...state,
            getFields: {},
            updateFields: {},
            createFields: {},
        })
    }

    case 'FIELDS_BROWSE_PENDING': {
      return({...state, browseFields: {progress: 0}})
    }
    case 'FIELDS_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseFields: {...api.browseFields.response, progress: 1, payload: action.payload}})
            return({...state, browseFields: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseFields: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'FIELDS_BROWSE_REJECTED': {
        return({...state, browseFields: {progress: 2, payload: action.payload}})
    }
    
    case 'FIELDS_GET_PENDING': {
        return({...state, getFields: {progress: 0}})
    }
    case 'FIELDS_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getFields: {...api.getFields.response, progress: 1, payload: action.payload}})
            return({...state, getFields: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getFields: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'FIELDS_GET_REJECTED': {
        return({...state, getFields: {progress: 2, payload: action.payload}})
    }

    case 'FIELDS_STATUS_PENDING': {
      return({...state, statusFields: {progress: 0}})
    }
    case 'FIELDS_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusFields: {...api.statusFields.response, progress: 1, payload: action.payload}})
            return({...state, statusFields: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusFields: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'FIELDS_STATUS_REJECTED': {
        return({...state, statusFields: {progress: 2, payload: action.payload}})
    }

    case 'FIELDS_CREATE_PENDING': {
      return({...state, createFields: {progress: 0}})
    }
    case 'FIELDS_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createFields: {...api.createFields.response, progress: 1, payload: action.payload}})
            return({...state, createFields: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createFields: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'FIELDS_CREATE_REJECTED': {
        return({...state, createFields: {progress: 2, payload: action.payload}})
    }

    case 'FIELDS_UPDATE_PENDING': {
      return({...state, updateFields: {progress: 0}})
    }
    case 'FIELDS_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateFields: {...api.updateFields.response, progress: 1, payload: action.payload}})
            return({...state, updateFields: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateFields: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'FIELDS_UPDATE_REJECTED': {
        return({...state, updateFields: {progress: 2, payload: action.payload}})
    }

    case 'FIELDS_REMOVE_PENDING': {
      return({...state, removeFields: {progress: 0}})
    }
    case 'FIELDS_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeFields: {...api.removeFields.response, progress: 1, payload: action.payload}})
            return({...state, removeFields: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeFields: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'FIELDS_REMOVE_REJECTED': {
        return({...state, removeFields: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}