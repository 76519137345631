import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';
import ConfirmationModal from '../../libraries/service/ConfirmationModal';

import ProductBrowse from '../../libraries/service/ProductBrowse'
import ProductForm from '../../libraries/service/ProductForm'
import ProductSearch from '../../libraries/service/ProductSearch'


class ProductBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        productId: 0,
      };
    }

    setMode(mode, id, param) {
      this.setState({mode: mode, productId: id, param: param})
    }

    closeModal(pristine) { //true: no data change, false: data been changed
      if (pristine===false) {
        console.log(pristine)
        this.setState({
          modal: this.state.modal===1 ? 0 : 1, //1: confirmation
        });
      } else {
        this.setState({
          mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
          productId: 0,

          modal: 0,
        });
      }
    }

    render() {
      const {mode, productId, param, modal}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div>
            <ProductSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
          
            {<ProductBrowse mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} openModal={this.props.openModal} closeModal={this.closeModal.bind(this)} />}
            {/* {mode==2 && <ProductForm mode={mode} productId={productId} param={param} setMode={this.setMode.bind(this)} />}
            {mode==3 && <ProductForm mode={mode} productId={0} param={param} setMode={this.setMode.bind(this)} />} */}
            {mode==2 && <ModalContainer headless size='small' content={<ProductForm mode={mode} productId={productId} param={param} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
            {mode==3 && <ModalContainer headless size='small' content={<ProductForm mode={mode} productId={0} param={param} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}

            {modal==1 && <ModalContainer headless style={{marginTop: screenHeight/4}} size='tiny' content={<ConfirmationModal screenWidth={screenWidth} screenHeight={screenHeight-125} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
          </div>
        </Container>
      )
    }
}

export default ProductBody
