import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed } from 'semantic-ui-react'

import numeral from "numeral"

import { connect } from "react-redux"
import { statAsset } from "../../../actions/halo/statisticAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    statisticStore: state.statisticStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    statAsset:(objParam) => dispatch(statAsset(objParam)),
  }
}


class StatisticWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {};
  }

  componentDidMount() {
    this.props.statAsset() 
  }
  
  render() {
    const { screenWidth,  screenHeight}= this.props
    const statAsset= (this.props.statisticStore.statAsset!=undefined && this.props.statisticStore.statAsset.progress=='1' ? this.props.statisticStore.statAsset.data : {})
    
    return (
      <div style={{display: 'flex'}}>
        <div style={{flex: '1'}}>
          <Segment attached='top' style={{padding: '1em .5em', background: '#eaeaea'}}>
            <Header as='h5'>
              <Icon name='chart bar outline' style={{fontSize: '1em'}} />
              <Header.Content>
                Ticket Statistic
              </Header.Content>
            </Header>
          </Segment>
          <Segment attached='bottom' style={{padding: '1em', background: '#f5f5f5'}}>
            <Header as='h3' style={{display: 'block'}}>
              <Icon name='tag' style={{fontSize: '1.7em'}} />
              <Header.Content>
                {numeral((statAsset.revenues && statAsset.revenues.totBillAmount) || 0).format('0a')}
                <Header.Subheader><b>Open / New</b></Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <Header as='h3' style={{display: 'block'}}>
              <Icon name='train' style={{fontSize: '1.7em'}} />
              <Header.Content>
                {numeral((statAsset.revenues && statAsset.revenues.totDiscountAmount + statAsset.revenues.totVoucherAmount) || 0).format('0a')}
                <Header.Subheader><b>On Progress</b></Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <Header as='h3' style={{display: 'block'}}>
              <Icon name='shopping bag' style={{fontSize: '1.7em'}} />
              <Header.Content>
                {numeral((statAsset.revenues && statAsset.revenues.totNettAmount) || 0).format('0a')}
                <Header.Subheader><b>Closed / Done</b></Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <Header as='h3' style={{display: 'block'}}>
              <Icon color='red' name='calendar times outline' style={{fontSize: '1.7em'}} />
              <Header.Content>
                {numeral((statAsset.revenues && statAsset.revenues.totNettAmount) || 0).format('0a')}
                <Header.Subheader><b>Overdue / Late</b></Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <Header as='h3' style={{display: 'block'}}>
              <Icon color='red' name='warning sign' style={{fontSize: '1.7em'}} />
              <Header.Content>
                {numeral((statAsset.revenues && statAsset.revenues.totNettAmount) || 0).format('0a')}
                <Header.Subheader><b>Warning / Alert</b></Header.Subheader>
              </Header.Content>
            </Header>
          </Segment>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticWidget)