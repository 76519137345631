import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Label } from 'semantic-ui-react'

export default class MenuLeftCustomer extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      isExpand: 'collection',
      isSubMenu: 'service', //service system
    };
  }

  expandMenu(v) {
    this.setState({
      isExpand: this.state.isExpand==v ? '' : v
    })
  }

  expandSubMenu(v) {
    this.setState({
      isSubMenu: this.state.isSubMenu==v ? '' : v
    })
  }

  render() {
    const { isExpand, isSubMenu }= this.state
    const { module, screenWidth,  screenHeight, mini }= this.props
    
    if (mini) {
      return(
        <></>
      )
    } else {
      return (
        <div style={{display: 'flex', flexDirection: 'column', minHeight: screenHeight, height: '100%'}}>
          <div style={{flex: '1'}}>
            <Popup position= 'right center' inverted content='Customer Data' size='tiny'
              trigger= {
                <Button circular icon onClick={this.props.openWindow.bind(this, 'customer', 'service')}>
                  <Icon name='gem' color={this.props.mode=='customer' ? 'red' : 'black'} style={{transform: 'rotate(220deg)'}}/>
                </Button>
              } 
            />
            <Divider />
            <Popup position= 'right center' inverted content='Customer Interaction' size='tiny'
              trigger= {
                <Button circular icon onClick={this.props.openWindow.bind(this, 'interaction', 'service')}>
                  <Icon name='magnet' color={this.props.mode=='interaction' ? 'red' : 'black'} />
                </Button>
              } 
            />
            <Divider />
            <Popup position= 'right center' inverted content='Customer Perception' size='tiny'
              trigger= {
                <Button circular icon onClick={this.props.openWindow.bind(this, 'segment', 'service')}>
                  <Icon name='thumbs up' color={this.props.mode=='segment' ? 'red' : 'black'} />
                </Button>
              } 
            />
            <Divider />
            <Popup position= 'right center' inverted content='Ticket Segmentation' size='tiny'
              trigger= {
                <Button circular icon onClick={this.props.openWindow.bind(this, 'acknowledge', 'service')}>
                  <Icon name='golf ball' color={this.props.mode=='acknowledge' ? 'red' : 'black'} />
                </Button>
              } 
            />
          </div>
          <div style={{padding: '0 0'}}>
            
          </div>
        </div>
      );
    }
  }
}
