import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse, addSeconds, differenceInSeconds, differenceInMinutes } from "date-fns"

import { personalOptions, ticketOptions }  from '../../modules/service/StaticMaster'

import { connect } from "react-redux"
import { ticketBucket } from "../../actions/service/bucketAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    bucketStore: state.bucketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ticketBucket:(objParam) => dispatch(ticketBucket(objParam)),
  }
}

class MyBucketOpen extends Component {
  constructor(props) {
    super(props)
    this.state= {
      expand: false,
    }
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.bucketStore.ticketBucket!==undefined ? this.props.bucketStore.ticketBucket : {})
    this.props.ticketBucket({search: {...search}, paging: paging, param: this.props.filter})
  }

  componentDidUpdate(prevProps) {
    const { search, paging }= (this.props.bucketStore.ticketBucket!==undefined ? this.props.bucketStore.ticketBucket : {})
    if (this.props.stamp!=prevProps.stamp) {
      this.props.ticketBucket({search: {...search}, paging: paging, param: this.props.filter})
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.bucketStore.ticketBucket!==undefined ? this.props.bucketStore.ticketBucket : {})
    this.props.ticketBucket(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  setExpand() {
    const { expand }= this.state
    this.setState({
      expand: !expand
    })
  }

  formatSeconds(seconds) {
    // console.log('!!! SECONDS', seconds)
    const date= addSeconds(new Date(0, 0, 0, 0, 0, 0), seconds);
    // console.log('!!! DATE', date)
    return (Math.floor(seconds/(24 * 60 * 60)) +'D / '+ format(date, 'HH:mm'));
  }

  render() {
    //console.log(this.props.bucketStore.ticketBucket)
    const { expand }= this.state
    const { screenWidth, screenHeight, style }= this.props
    const { data, search, paging }= (this.props.bucketStore.ticketBucket!==undefined ? this.props.bucketStore.ticketBucket : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    const priority= ticketOptions('priority', true);
    const severity= ticketOptions('severity', true);

    return (
      <div style={{display: 'flex', padding: '0', flexDirection: 'column', minHeight: screenHeight, ...style}}>
        <div style={{flex: '1', padding: '0'}}>
          <Table unstackable selectable columns='16' celled striped style={{borderRadius: '.5em'}}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                {/* <Table.HeaderCell></Table.HeaderCell> */}
                <Table.HeaderCell width={5}>
                  <Header as='h5' color='blue'>SUBJECT</Header>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Header as='h5' color='blue'>SLA</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={4}>
                  <Header as='h5' color='blue'>REQUESTER</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  <Header as='h5' color='blue'>
                    <Header.Content>CREATED</Header.Content>
                  </Header>
                </Table.HeaderCell>
                {/* <Table.HeaderCell width={5}>
                  <Header as='h5' color='blue'><Header.Content>ESCALATION</Header.Content></Header>
                </Table.HeaderCell> */}
                <Table.HeaderCell width={5}>
                  <Header as='h5' color='blue'><Header.Content>DESCRIPTION</Header.Content></Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>
                        <Label circular>{seq}</Label>
                      </Table.Cell>
                      {/* <Table.Cell textAlign='center'>
                        <Popup position= 'top left' inverted content='Edit'
                          trigger= {<Icon style={{margin: '0', cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2', item.ticketId, item.clientId)}/>}
                        />
                      </Table.Cell> */}
                      <Table.Cell>
                        <Popup position= 'top left' inverted content={item.subject || 'No subject'}
                          trigger={
                            <div style={{display: 'flex', cursor: 'pointer'}} onClick={this.props.setMode.bind(this, '4', item.ticketId, item)}>
                              <div style={{marginTop: '.2em'}}>
                                <Label size='small' circular color={(item.remark_ && item.remark_.color) || 'orange'} style={{
                                  marginRight: '.5em', minWidth: '4em', 
                                // padding: '.7em .5em .8em .5em', borderRadius: '2em', 
                                textAlign: 'center'}}><Icon name={item.isChild ? 'tags' : 'tag'} style={{margin: '0 .3em 0 0'}} /> {(item.remark || 'New').toUpperCase()}</Label>
                              </div>
                              <div style={{flex: 1}}>
                                <Header as='h5'>
                                  <Header.Content>
                                    <b>{item.subject.substring(0,20) +' ...'}</b>
                                    <Header.Subheader><b>{item.code || '-'}</b> / {(priority.get(item.priority)||{}).text}, {(severity.get(item.severity)||{}).text}</Header.Subheader>
                                  </Header.Content>
                                </Header>
                              </div>
                            </div>
                          } 
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Popup position= 'top left' inverted header={(item.nextSLAType || '-')} content={'Maximum at '+ (item.nextSLAStamp!=null && format(parse(item.nextSLAStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm, dd/MMM yyyy'))}
                          trigger={
                            <Label color={
                              (item.nextSLAStamp && differenceInMinutes(parse(item.nextSLAStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), new Date())<0 && 'red') || 
                              (item.nextSLAStamp && differenceInMinutes(parse(item.nextSLAStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), new Date())==0 && 'orange') || 
                              (item.nextSLAStamp && differenceInMinutes(parse(item.nextSLAStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), new Date())<15 && 'pink') || 
                              (item.nextSLAStamp && differenceInMinutes(parse(item.nextSLAStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), new Date())<30 && 'yellow') || 
                              'blue'
                            } size='large' style={{borderRadius: '.3em', margin: '0', padding: '.5em .5em', whiteSpace: 'nowrap'}}>
                              {/* {item.nextSLAStamp && item.nextSLAStamp.substring(11, 16)} */}
                              {item.nextSLAStamp && this.formatSeconds(differenceInSeconds(parse(item.nextSLAStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), new Date()))}
                              {/* {item.nextSLAStamp && Math.floor(differenceInSeconds(parse(item.nextSLAStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), new Date())/60)}:{item.nextSLAStamp && Math.abs(Math.floor(differenceInSeconds(parse(item.nextSLAStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), new Date())%60))} */}
                            </Label>
                        } />
                      </Table.Cell>
                      <Table.Cell>
                        <Popup position= 'top left' inverted content={(item.channelType || 'Walk In') +' ~ '+ (item.channelMethod || 'Input by Agent')}
                          trigger={
                            <Header as='h5'>
                              <Icon name={
                                (item.channelType=='Voice' && 'microphone') || (item.channelType=='IVR' && 'volume down') || (item.channelType=='SMS' && 'sticky note outline')
                                || (item.channelType=='Email' && 'envelope outline') || (item.channelType=='Whatsapp' && 'whatsapp') || (item.channelType=='Telegram' && 'telegram plane')
                                || (item.channelType=='Twitter' && 'twitter') || (item.channelType=='Facebook' && 'facebook') || (item.channelType=='Instagram' && 'instagram') || 'share alternate'
                                } />
                              <Header.Content>
                                <b>{item.name || 'No Name'}</b>
                                <Header.Subheader><b>{item.channelType || 'Walk In'} - {item.channelMethod || 'Input by Agent'}</b></Header.Subheader>
                              </Header.Content>
                            </Header>
                          } 
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.createStamp!=null && format(parse(item.createStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm')}
                            <Header.Subheader>{item.createStamp!=null && format(parse(item.createStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd/MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        {item.descript}
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={5}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyBucketOpen)
