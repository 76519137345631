import React from 'react'
import bg_home  from '../assets/ovisone-bg.svg'

import AuthenticateOvisoneHeader from "./AuthenticateOvisoneHeader"
import AuthenticateOvisoneFooter from "./AuthenticateOvisoneFooter"
// import AuthenticateOvisoneBody from "./AuthenticateOvisoneBody"
import AuthenticateOvisoneBody from "./service/AuthenticateBody"

class AuthenticateOvisone extends React.Component {
    constructor(props) {
      super(props)
      this.state= {
        screenHeight: 800,
        screenWidth: 1024,
      }
      this.updateDimension = this.updateDimension.bind(this)
    }
  
    componentDidMount() {
      const { match }= this.props
      
      this.updateDimension()
      window.addEventListener('resize', this.updateDimension)

      const { token }= match.params
      if (token) {
        localStorage.setItem('tokenAuth', token);
        this.props.history.push('/realm');
      }
    }
    
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimension)
    }
    
    updateDimension() {
      this.setState({ ...this.state, screenWidth: window.innerWidth, screenHeight: window.innerHeight })
    }
    
    render() {
      const {screenHeight, screenWidth}= this.state

      return(
        <div>
          <div style={{minHeight: screenHeight, _backgroundImage: 'url('+ bg_home +')', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom center', backgroundColor: '#37b9ff', backgroundSize: 'cover'}}>
            <AuthenticateOvisoneBody screenHeight={screenHeight-80} screenWidth={screenWidth}/>
            <AuthenticateOvisoneFooter screenHeight={screenHeight} screenWidth={screenWidth}/>
          </div>
        </div>
      )
    }
} export default AuthenticateOvisone
