export default function resolutionReducer (state = {}, action) {
  switch (action.type) {
    case 'RESOLUTION_RESET': {
        return({
            ...state,
            getResolution: {},
            updateResolution: {},
            createResolution: {},
        })
    }

    case 'RESOLUTION_BROWSE_PENDING': {
      return({...state, browseResolution: {progress: 0}})
    }
    case 'RESOLUTION_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseResolution: {...api.browseResolution.response, progress: 1, payload: action.payload}})
            return({...state, browseResolution: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseResolution: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'RESOLUTION_BROWSE_REJECTED': {
        return({...state, browseResolution: {progress: 2, payload: action.payload}})
    }
    
    case 'RESOLUTION_GET_PENDING': {
        return({...state, getResolution: {progress: 0}})
    }
    case 'RESOLUTION_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getResolution: {...api.getResolution.response, progress: 1, payload: action.payload}})
            return({...state, getResolution: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getResolution: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'RESOLUTION_GET_REJECTED': {
        return({...state, getResolution: {progress: 2, payload: action.payload}})
    }

    case 'RESOLUTION_STATUS_PENDING': {
      return({...state, statusResolution: {progress: 0}})
    }
    case 'RESOLUTION_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusResolution: {...api.statusResolution.response, progress: 1, payload: action.payload}})
            return({...state, statusResolution: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusResolution: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'RESOLUTION_STATUS_REJECTED': {
        return({...state, statusResolution: {progress: 2, payload: action.payload}})
    }

    case 'RESOLUTION_CREATE_PENDING': {
      return({...state, createResolution: {progress: 0}})
    }
    case 'RESOLUTION_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createResolution: {...api.createResolution.response, progress: 1, payload: action.payload}})
            return({...state, createResolution: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createResolution: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'RESOLUTION_CREATE_REJECTED': {
        return({...state, createResolution: {progress: 2, payload: action.payload}})
    }

    case 'RESOLUTION_UPDATE_PENDING': {
      return({...state, updateResolution: {progress: 0}})
    }
    case 'RESOLUTION_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateResolution: {...api.updateResolution.response, progress: 1, payload: action.payload}})
            return({...state, updateResolution: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateResolution: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'RESOLUTION_UPDATE_REJECTED': {
        return({...state, updateResolution: {progress: 2, payload: action.payload}})
    }

    case 'RESOLUTION_REMOVE_PENDING': {
      return({...state, removeResolution: {progress: 0}})
    }
    case 'RESOLUTION_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeResolution: {...api.removeResolution.response, progress: 1, payload: action.payload}})
            return({...state, removeResolution: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeResolution: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'RESOLUTION_REMOVE_REJECTED': {
        return({...state, removeResolution: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}