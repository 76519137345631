import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Card, Image } from 'semantic-ui-react'
import ModalContainer from '../../libraries/common/ModalContainer';

import MenuLeft from '../../libraries/service/MenuLeftSetting'
import SettingBody from './SettingBody';
import MacroBody from './MacroBody';
import CsatBody from './CsatBody';
import ReasoningBody from './ReasoningBody';
import ResolutionBody from './ResolutionBody';
import ClassificationBody from './ClassificationBody';
import PriorityBody from './PriorityBody';
import SeverityBody from './SeverityBody';
import CalendarBody from './CalendarBody';
import PeopleBody from './PeopleBody';
import OrganizationBody from './OrganizationBody';
import WorkgroupBody from './WorkgroupBody';
import BucketBody from './BucketBody';
import FormsBody from './FormsBody';
import FieldsBody from './FieldsBody';
import StatusBody from './StatusBody';
import RoutingBody from './RoutingBody';
import SlaBody from './SlaBody';
import AutomationBody from './AutomationBody';
import ExplorerBody from './ExplorerBody';
import ReportingBody from './ReportingBody';
import WidgetBody from './WidgetBody';
import AudittrailBody from './AudittrailBody';
import AccountBody from './AccountBody';
import ProductBody from './ProductBody';


class AdminSettingBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { mode, module, screenWidth, screenHeight, mini, modal, params }= this.props

    return (
      <div style={{marginTop: '3em'}}>
        <div style={{display: 'flex'}}>
          {!mini && <div style={{background: '#eaeaea', width: 225, paddingTop: '0em'}}>
            <MenuLeft openWindow={this.props.openWindow} mode={this.props.mode} screenHeight={screenHeight} screenWidth={screenWidth} module={module} mini={mini}/>
          </div>}
          <div style={{flex: '1', marginRight: '2px',padding: '1em .5em'}}>
            {mode=='people' && <PeopleBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }
            {mode=='organization' && <OrganizationBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }
            {mode=='workgroup' && <WorkgroupBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }

            {mode=='bucket' && <BucketBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }
            
            {mode=='csat' && <CsatBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }
            {mode=='resolution' && <ResolutionBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }
            {mode=='reasoning' && <ReasoningBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }
            {mode=='classification' && <ClassificationBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }
            {mode=='priority' && <PriorityBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }
            {mode=='severity' && <SeverityBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }

            {mode=='macro' && <MacroBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }
            {mode=='forms' && <FormsBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }
            {mode=='fields' && <FieldsBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }
            {mode=='status' && <StatusBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }
            {mode=='routing' && <RoutingBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }
            {mode=='sla' && <SlaBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }
            {mode=='trigger' && <AutomationBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }

            {mode=='widget' && <WidgetBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }
            {mode=='explorer' && <ExplorerBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }
            {mode=='reporting' && <ReportingBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }

            {mode=='logger' && <AudittrailBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }
            {mode=='account' && <AccountBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }

            {mode=='product' && <ProductBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }
            
            <SettingBody module={module} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />

            {mode=='calendar' && <CalendarBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} /> }
            
            {/* <p>
              <b>{this.props.mode}</b>
              <br />
              Channel | Customer Access / Permission | Language Support | Customer Notification | Widget | Chat Slack , Ms Teams | Feedback Rating | SLA Type Time To First Response, Time To Resolution | Automation Workflow | Workflow | Ticket Status | Ticket Resolution | Ticket Priorities | Security Scheme (Issue, Permission, Notification)
            </p> */}
          </div>
        </div>
      </div>
    )
  }
}

export default AdminSettingBody