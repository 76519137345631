import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetPeople(objParam) {
  return {
      type: 'PEOPLE_RESET',
      payload: objParam
  }
}

export function getPeople(objParam) {
  return {
      type: 'PEOPLE_GET',
      payload:
        axios.post(
          urlAPI + 'service/people/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusPeople(objParam) {
  return {
      type: 'PEOPLE_STATUS',
      payload:
        axios.post(
          urlAPI + 'service/people/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browsePeople(objParam) {
  return {
      type: 'PEOPLE_BROWSE',
      payload:
        axios.post(
          urlAPI + 'service/people/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createPeople(objParam) {
  return {
      type: 'PEOPLE_CREATE',
      payload:
        axios.post(
          urlAPI + 'service/people/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updatePeople(objParam) {
  return {
      type: 'PEOPLE_UPDATE',
      payload:
        axios.post(
          urlAPI + 'service/people/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removePeople(objParam) {
  return {
      type: 'PEOPLE_REMOVE',
      payload:
        axios.post(
          urlAPI + 'service/people/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
