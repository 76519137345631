import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Placeholder,
} from 'semantic-ui-react'

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

import ServiceHeader from "./ServiceHeader"
import ServiceFooter from "./ServiceFooter"
import ServiceBody from "./ServiceBody"

import { connect } from "react-redux"
import { checkToken } from "../../actions/auth/authAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),
  }
}

class Service extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      screenHeight: 800,
      screenWidth: 1024,
      modal: '',
      mode: '',
      module: 'dashboard', //home dashboard ticket mypage reports setting profile

      openDrawer: true,
    }
    this.updateDimension = this.updateDimension.bind(this)
  }

  componentDidMount() {
    this.props.checkToken();
    this.updateDimension()
    window.addEventListener('resize', this.updateDimension)
    // window.addEventListener('error', this.debugApp)
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimension)
    // window.removeEventListener('error', this.debugApp)
    this.context.socket && this.context.socket.close();
  }
  
  updateDimension() {
    this.setState({ ...this.state, screenWidth: window.innerWidth, screenHeight: window.innerHeight })
  }
  
  debugApp(error) {
    const info={
      error: error,

      // documentUrl: document.URL,
      // userAgent: navigator.userAgent,
      // vendor: navigator.vendor,
      // userid: localStorage.userid,
      // language: navigator.langauge,
      // cookiesEnabled: navigator.cookiesEnabled
    }
    alert('ERROR JSON.stringify '+ JSON.stringify(info), console.error)
  }

  openModal(value, params) {
    // console.log(value, params)
    this.setState({
      modal: value,
      params: params,
    })
  }

  closeModal(params) {
    // console.log(params)
    this.setState({
      modal: null,
      params: params,
    })
  }

  openWindow(mode, params) {
    this.setState({
      mode: mode,
      params: params,
      openDrawer: true
    })
  }

  openModule(v) {
    this.setState({
      openDrawer: true,
      module: v,
      mode: '',
      params: null,
    })
  }
  
  openDrawer() {
    const { openDrawer }= this.state
    this.setState({
      openDrawer: !openDrawer
    })
  }

  render() {
    const {screenHeight, screenWidth, mode, openDrawer, module, modal, params }= this.state;
    const token= this.props.authStore && this.props.authStore.checkToken && this.props.authStore.checkToken.progress==1 ? this.props.authStore.checkToken.data : null;

    try{
    return(
      <Container fluid style={{background: '#fafafa'}}>
        <div style={{display: 'flex', flexDirection: 'column', minHeight: screenHeight}}>
          <div style={{flex: '1'}}>
            {token && <ServiceHeader token={token} creenHeight={screenHeight} screenWidth={screenWidth} openModule={this.openModule.bind(this)} openWindow={this.openWindow.bind(this)} openModal={this.openModal.bind(this)} closeModal={this.closeModal.bind(this)} mode={mode} openDrawer={this.openDrawer.bind(this)} modal={modal}  mini={!openDrawer} module={module} params={params}/>}
            {token && <ServiceBody token={token} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.openModule.bind(this)} openWindow={this.openWindow.bind(this)} openModal={this.openModal.bind(this)} closeModal={this.closeModal.bind(this)} mode={mode} openDrawer={this.openDrawer.bind(this)} modal={modal}  mini={!openDrawer} module={module} params={params}/>}
            {!token && <div style={{marginTop: screenHeight/3, marginLeft: screenWidth/5, marginRight: screenWidth/5, textAlign: 'center'}}>
              <Grid columns={3} stackable>
                <Grid.Column>
                  <Segment raised>
                    <Placeholder>
                      <Placeholder.Header image>
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Header>
                      <Placeholder.Paragraph>
                        <Placeholder.Line length='medium' />
                        <Placeholder.Line length='short' />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment raised>
                    <Placeholder>
                      <Placeholder.Header image>
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Header>
                      <Placeholder.Paragraph>
                        <Placeholder.Line length='medium' />
                        <Placeholder.Line length='short' />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment raised>
                    <Placeholder>
                      <Placeholder.Header image>
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Header>
                      <Placeholder.Paragraph>
                        <Placeholder.Line length='medium' />
                        <Placeholder.Line length='short' />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </Segment>
                </Grid.Column>
              </Grid>
              <Segment>
                <Header as='h4'>
                  <Icon name='ellipsis horizontal' size='big' loading />
                  <Header.Content>
                    <b>System is loading</b> . . . , wait for a few seconds.
                  </Header.Content>
                </Header>
              </Segment>
            </div>}
          </div>
          <div>
            <ServiceFooter token={token} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.openModule.bind(this)} openWindow={this.openWindow.bind(this)} module={module} params={params}/>
          </div>
        </div>
      </Container>
    )
    }
    catch(error) {
      return(<>ERROR APP: {error}</>)
    }
  }
} 

Service.contextType= WebSocketContext;
export default connect(mapStateToProps, mapDispatchToProps)(Service)