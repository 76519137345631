import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Dropdown } from 'semantic-ui-react'

import { parse, format, endOfMonth } from "date-fns"
import PieRechart from '../../chart/PieRechart';

import { connect } from "react-redux"
import { ticketMonitoring } from "../../../actions/service/monitoringAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    monitoringStore: state.monitoringStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ticketMonitoring:(objParam) => dispatch(ticketMonitoring(objParam)),
  }
}

class SegmentationWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      stamp: '',
      pieData: [
        {name: 'Server Error', value: 1}, 
        {name: 'User Denied', value: 2}, 
        {name: 'Network Error', value: 3}, 
        {name: 'Bug Application', value: 4}, 
      ],
    };
  }
  
  componentDidUpdate() {
    const { pieData, stamp}= this.state
    const { data, search }= (this.props.monitoringStore.ticketMonitoring!==undefined ? this.props.monitoringStore.ticketMonitoring : {})

    const _stamp= (search && search.groupingDate && (search.grouping=='Daily' ? search.grouping +'-'+ search.groupingDate.substr(8,2) : search.grouping +'-'+ search.groupingDate.substr(5,2))) || '';

    const values={};
    
    // console.log(data)
    if (this.state.stamp!=_stamp) {
      if (search && data && data.segmentations) {
        if (search && search.grouping=='Daily') {
          Object.entries(data.segmentations).forEach(([dd, value]) => {
            // console.log('!!! DAILY', search.groupingDate && search.groupingDate.substr(8,2), dd, value)
            if (search.groupingDate && search.groupingDate.substr(8,2)==dd) {
              Object.entries(value).forEach(([id, segment]) => {
                values[segment.name] ? values[segment.name]+= parseInt(segment.totOpen) : values[segment.name]= parseInt(segment.totOpen);
              })
            }
          });
        } else if (search && search.grouping=='Monthly') {
          Object.entries(data.segmentations).forEach(([dd, value]) => {
            // console.log('!!! MONTHLY', dd, value)
            Object.entries(value).forEach(([id, segment]) => {
              values[segment.name] ? values[segment.name]+= parseInt(segment.totOpen) : values[segment.name]= parseInt(segment.totOpen);
            })
          });
        }
      }

      const piedata= [];
      Object.entries(values).forEach(([key, value]) => {
        piedata.push({
          name: key,
          value: parseInt(value)
        })
      });
      this.setState({
        stamp: _stamp,
        pieData: [
          // {name: 'Response OK', value: values.response_OK},
          ...piedata,
        ]
      });
    }
  }
  
  render() {
    const { screenWidth,  screenHeight}= this.props
    const { pieData}= this.state
    
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
          <Header as='h5'>
            <Icon name='chart bar' style={{fontSize: '1em'}} />
            <Header.Content>
              Ticket Segmentation / <b><i>{format(new Date(), 'MMM yyyy')}</i></b>
              <Header.Subheader>Distribution of ticket based segmentation.</Header.Subheader>
            </Header.Content>
          </Header>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em', minHeight: this.props.height}}>
        {/* {data!=undefined && */}
          <PieRechart mode='simple' height={250} yAxis={false} dashed={true} label={true} data={pieData} />
        {/* } */}
      </Segment>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SegmentationWidget)