import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import RptRequesterSearch from '../../libraries/service/RptRequesterSearch'
import RptRequesterCustomer from '../../libraries/service/RptRequesterCustomer';
import RptRequesterCluster from '../../libraries/service/RptRequesterCluster';
import RptRequesterCorporate from '../../libraries/service/RptRequesterCorporate';

class RptCustomerBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        peopleId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, peopleId: id, clientId: clientId})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        peopleId: 0,
      });
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <RptRequesterSearch setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} menu={this.props.menu} mode={mode}/>

          {this.props.menu==='customer' && <RptRequesterCustomer setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
          {this.props.menu==='cluster' && <RptRequesterCluster setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
          {this.props.menu==='corporate' && <RptRequesterCorporate setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
        </Container>
      )
    }
}

export default RptCustomerBody
