import React, { Component } from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Label, Dropdown } from 'semantic-ui-react'

import { Link } from 'react-router-dom'
import {withRouter} from "react-router-dom"

import { connect } from "react-redux"
import { signOut } from "../../actions/auth/authAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut:(objParam) => dispatch(signOut(objParam))
  }
}

const styleButton={
  margin: '.3em .1em',
  border: 'none',
}

class AdminHeader extends Component {
  constructor(props) {
    super(props);
    this.state={
      
    }
  }

  openDrawer(v) {
    this.props.openDrawer()
  }

  doSignOut(values) {
    this.props.signOut(values)
    this.props.history.push('/signin')
  }

  openWindow(value) {
    const { screenWidth,  screenHeight, mini }= this.props

    this.props.openWindow(value)
    if (!mini) {
      this.props.openDrawer()
    }
  }

  componentDidMount() {
    const tokenAuth= localStorage.getItem('tokenAuth');
    if (tokenAuth==undefined || tokenAuth=='') {
      this.props.history.push('/signin')
    }
  }

  componentDidUpdate() {
    if (localStorage.getItem('tokenAuth')=='') {
      this.props.history.push('/signin');
    }
  }
  
  render() {
    const { screenWidth,  screenHeight, mini, module }= this.props
    
    return (
      <div>
        <Menu fixed='top'  style={{padding: '0', border: 'none', boxShadow: 'none'}}>
          <div style={{display: 'flex', width: '100%', background : 'linear-gradient(-40deg, rgb(186, 168, 255) 0%, rgb(87, 197, 255) 65%, rgb(72 165 254) 100%)'}}>
            <div style={{padding: '.3em .3em .3em .3em', textAlign: 'center'}}>
              <Label as='a' style={{border: 'none', background: 'none', padding: '.2 .75em', border: '0px solid rgba(100, 100, 100, .3)'}} onClick={this.openDrawer.bind(this)}>
                <Icon name='bars' style={{fontSize: '1.75em', margin: '0'}} color={mini==true ? 'blue' : 'black'} />
              </Label>
            </div>
            <div style={{padding: '1em 0 0 1em'}}>
              <Header as='h5'>
                <Header.Content>
                  <b>Service Mgt.</b>
                </Header.Content>
              </Header>
            </div>
            {this.props.token.groupId=='Client' && <>
              <div style={{margin: '0 0 0 1.5em', borderRight: '1px solid rgba(50, 50, 50, .2)'}}>
                <Popup position= 'bottom left' 
                  trigger={
                    <Label style={{padding: '0 1em 0 -.5em', margin: '.5em .5em', height: '2.25em', width: '2.25em'}} as='a' onClick={this.props.openModule.bind(this, 'dashboard')} circular color={module=='dashboard' ? 'blue' : null}>
                      <Icon name='home' style={{margin: '0', fontSize: '1.1em'}} />
                    </Label>
                  }
                  content='Home'
                  inverted
                  on={['hover']}
                />
              </div>
              <div style={{margin: '0 0', borderRight: '1px solid rgba(50, 50, 50, .2)', borderLeft: '1px solid rgba(255, 255, 255, .1)'}}>
                <Popup position= 'bottom left' 
                  trigger={
                    <Label style={{margin: '.5em .5em', height: '2.25em', width: '2.25em'}} as='a' onClick={this.props.openModule.bind(this, 'mypage')} circular color={module=='mypage' ? 'blue' : null}>
                      <Icon name='rocket' style={{margin: '0', fontSize: '1.1em'}} />
                    </Label>
                  }
                  content='Workspace'
                  inverted
                  on={['hover']}
                />
              </div>
              <div style={{margin: '0 0', borderRight: '1px solid rgba(50, 50, 50, .2)', borderLeft: '1px solid rgba(255, 255, 255, .1)'}}>
                <Popup position= 'bottom left' 
                  trigger={
                    <Label style={{margin: '.5em .5em', height: '2.25em', width: '2.25em'}} as='a' onClick={this.props.openModule.bind(this, 'reports')} circular color={module=='reports' ? 'blue' : null}>
                      <Icon name='file alternate' style={{margin: '0', fontSize: '1.1em'}} />
                    </Label>
                  }
                  content='Reports'
                  inverted
                  on={['hover']}
                />
              </div>
              <div style={{margin: '0 0', borderLeft: '1px solid rgba(255, 255, 255, .1)'}}>
                <Popup position= 'bottom left' 
                  trigger={
                    <Label style={{margin: '.5em .5em', height: '2.25em', width: '2.25em'}} as='a' onClick={this.props.openModule.bind(this, 'setting')} circular color={module=='setting' ? 'blue' : null}>
                      <Icon name='setting' style={{margin: '0', fontSize: '1.1em'}} />
                    </Label>
                  }
                  content='Setting'
                  inverted
                  on={['hover']}
                />
              </div>
            </>}
            {(this.props.token.groupId=='Supervisor' || this.props.token.groupId=='Leader')  && <>
              <div style={{margin: '0 0 0 1.5em', borderRight: '1px solid rgba(50, 50, 50, .2)'}}>
                <Popup position= 'bottom left' 
                  trigger={
                    <Label style={{padding: '0 1em 0 -.5em', margin: '.5em .5em', height: '2.25em', width: '2.25em'}} as='a' onClick={this.props.openModule.bind(this, 'dashboard')} circular color={module=='dashboard' ? 'blue' : null}>
                      <Icon name='home' style={{margin: '0', fontSize: '1.1em'}} />
                    </Label>
                  }
                  content='Home'
                  inverted
                  on={['hover']}
                />
              </div>
              <div style={{margin: '0 0', borderRight: '1px solid rgba(50, 50, 50, .2)', borderLeft: '1px solid rgba(255, 255, 255, .1)'}}>
                <Popup position= 'bottom left' 
                  trigger={
                    <Label style={{margin: '.5em .5em', height: '2.25em', width: '2.25em'}} as='a' onClick={this.props.openModule.bind(this, 'mypage')} circular color={module=='mypage' ? 'blue' : null}>
                      <Icon name='rocket' style={{margin: '0', fontSize: '1.1em'}} />
                    </Label>
                  }
                  content='Workspace'
                  inverted
                  on={['hover']}
                />
              </div>
              <div style={{margin: '0 0', borderRight: '1px solid rgba(50, 50, 50, .2)', borderLeft: '1px solid rgba(255, 255, 255, .1)'}}>
                <Popup position= 'bottom left' 
                  trigger={
                    <Label style={{margin: '.5em .5em', height: '2.25em', width: '2.25em'}} as='a' onClick={this.props.openModule.bind(this, 'reports')} circular color={module=='reports' ? 'blue' : null}>
                      <Icon name='file alternate' style={{margin: '0', fontSize: '1.1em'}} />
                    </Label>
                  }
                  content='Reports'
                  inverted
                  on={['hover']}
                />
              </div>
            </>}
            {(this.props.token.groupId=='Agent')  && <>
            </>}
            {/* <div style={{margin: '0 0', borderLeft: '1px solid rgba(255, 255, 255, .1)'}}>
              <Popup position= 'bottom left' 
                trigger={
                  <Label style={{margin: '.5em .5em', height: '2.25em', width: '2.25em'}} as='a' onClick={this.props.openModule.bind(this, 'customer')} circular color={module=='customer' ? 'blue' : 'teal'}>
                    <Icon name='chess queen' style={{margin: '0', fontSize: '1.1em'}} />
                  </Label>
                }
                content='Customer Data'
                inverted
                on={['hover']}
              />
            </div>
            <div style={{margin: '0 0'}}>
              <Popup position= 'bottom left' 
                trigger={
                  <Label style={{margin: '.5em .5em', height: '2.25em', width: '2.25em'}} as='a' onClick={this.props.openModule.bind(this, 'knowledge')} circular color={module=='knowledge' ? 'blue' : 'teal'}>
                    <Icon name='folder' style={{margin: '0', fontSize: '1.1em'}} />
                  </Label>
                }
                content='Knowledge'
                inverted
                on={['hover']}
              />
            </div> */}
            <div style={{flex: '1', textAlign: 'right', paddingTop: '.25em', marginRight: '1em'}}>
              <Popup  position= 'bottom right' 
                trigger={<Button onClick={this.props.openModal.bind(this,'profile')} basic={this.props.modal!='profile'} color={this.props.modal=='profile'? 'olive' : null} style={styleButton} icon><Icon name='user outline' style={{color: this.props.modal=='profile' ? '#000' : '#fff'}}/></Button>}
                content='About Me !'
                inverted
                on={['hover']}
              />
              {/* <Popup position= 'bottom right' 
                trigger={<Button onClick={this.props.openModal.bind(this,'account')} basic={this.props.modal!='account'} color={this.props.modal=='account'? 'olive' : null} style={styleButton} icon><Icon name='user circle outline' style={{fontSize: '1.2em', color: this.props.modal=='account' ? '#000' : '#fff'}}/></Button>}
                content='Account'
                inverted
                on={['hover']}
              /> */}
              <Popup position= 'bottom right' 
                trigger={<Button onClick={this.props.openModal.bind(this,'message')} basic={this.props.modal!='message'} color={this.props.modal=='message'? 'olive' : null} style={styleButton} icon><Icon name='bell outline' style={{fontSize: '1.15em', color: this.props.modal=='message' ? '#000' : '#fff'}} /></Button>}
                content='Notification'
                inverted
                on={['hover']}
              />
            </div>
            <div style={{paddingTop: '.25em', marginRight: '1em'}}>
              <Popup position= 'bottom right' 
                trigger={<Button onClick={()=>this.doSignOut()} basic={this.props.modal!='signout'}  color={this.props.modal=='signout'? 'olive' : null} style={styleButton} icon><Icon name='sign-out' style={{fontSize: '1.2em', color: this.props.modal=='signout' ? '#000' : '#fff'}} /></Button>}
                content='Signout'
                inverted
                on={['hover']}
              />
            </div>
          </div>
        </Menu>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminHeader))