import React from 'react';
import { Container, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Accordion, Divider, Segment, Label, Menu } from 'semantic-ui-react'

import AuthenticateSignin from '../../libraries/service/AuthenticateSignin'

class AuthenticateBody extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      key: 'signin'
    }
  }

  openMenu(key) {
    this.setState({
      key: key
    })
  }
  
  render() {
    const { key }= this.state
    const { screenHeight }= this.props

    return(<>
      <div style={{minHeight: Math.round(this.props.screenHeight)}}>
        <Container style={{height: screenHeight, paddingTop: screenHeight/6}}>
          {key==='signin' && <AuthenticateSignin screenHeight={this.props.screenHeight} screenWidth={this.props.screenWidth} openMenu={this.openMenu.bind(this)} />}
        </Container>
      </div>
    </>);
  }
} export default AuthenticateBody
