// export const staticDomainPath= "http://localhost:3000/";
import React, { Component } from 'react'
import {
  Icon,
  Rating,
  Header,
  Divider,
} from 'semantic-ui-react'
import { format, parse } from "date-fns"

export const whatsappOptions = [ 
  { key: '1', value: 'chat', text: 'Chat' },
  { key: '2', value: 'otp', text: 'OTP' },
]
export const sessionOptions = (v)=>{
  if (v=='token') {
    return(
      [ 
        { key: '1', value: 'single', text: 'Single Session' },
        { key: '2', value: 'multiple', text: 'Multiple Session' },
      ]
    )
  } else if (v=='activity') {
    return(
      [ 
        { key: '1', value: '1', text: '(1) Session Activity' },
        { key: '2', value: '2', text: '(2) Navigation Access Activity' },
        // { key: '3', value: '3', text: '(3) Command Access Activity' },
        { key: '4', value: '4', text: '(4) Data Change/Access Activity' },
      ]
    )
  } else {
    return(
      [ 
        { key: '1', value: 'Auto', text: 'Auto Start' },
        { key: '2', value: 'Manual', text: 'Manual / By Agent' },
      ]
    )
  }
}
export const lockedOptions = [ 
  { key: '2', value: 'Modal', text: 'Modal Dialog' },
  { key: '1', value: 'Confirmation', text: 'Confirmation Dialog' },
]
export const importOptions =(v)=>{

  if (v=='Enterprise') {
    return([ 
      { key: '2', value: '2', text: 'Import Contacts', icon: 'address card outline' },
      { key: '0', value: '0', text: 'Upload File', icon: 'file alternate outline' },
      { key: '1', value: '1', text: 'Previous Campaign', icon: 'recycle' },
      { key: '3', value: '-1', text: 'Input Data Manual', icon: 'keyboard outline' },
    ])
  } else {
    return([ 
      { key: '2', value: '2', text: 'Import Contacts', icon: 'address card outline' },
      { key: '0', value: '0', text: 'Upload File', icon: 'file alternate outline' },
      { key: '1', value: '1', text: 'Previous Campaign', icon: 'recycle' },
      { key: '3', value: '-1', text: 'Input Data Manual', icon: 'keyboard outline' },
    ])
  }
} 

export const syseventOptions = (v)=>{
  if (v=='ticket') {
    return(
      [ 
        { key: '0', value: 'Ticket Auto Notification - Logging Event', text: 'Ticket Auto Notification - Logging Event' },
        { key: '1', value: 'Ticket Auto Notification - Dispatching Event', text: 'Ticket Auto Notification - Dispatching Event' },
        { key: '2', value: 'Ticket Auto Notification - Disposition Event', text: 'Ticket Auto Notification - Disposition Event' },
        { key: '3', value: 'Ticket Auto Notification - Escalation Event', text: 'Ticket Auto Notification - Escalation Event' },
        { key: '4', value: 'Ticket Auto Notification - Closing Event', text: 'Ticket Auto Notification - Closing Event' },
        <Divider style={{margin: '0 0'}} key='x' />,
        { key: '5', value: 'Ticket Auto Notification - Late Response', text: 'Ticket Auto Notification - Late Response' },
        { key: '6', value: 'Ticket Auto Notification - Late Resolve', text: 'Ticket Auto Notification - Late Resolve' },
      ]
    )  
  } else {
    return(
      [ 
        
      ]
    )
  }
} 

export const customerOptions= (v)=>{
  return(
    [ 
      { key: '0', value: 'Personal', text: 'Personal' },
      { key: '1', value: 'Corporate', text: 'Corporate' },
      { key: '2', value: 'Merchant', text: 'Merchant' },
    ]
  )
}
export const valueOptions =(v)=>{

  if (v=='prospect') {
    return([ 
      { key: '0', value: '<100M', text: '< 100M', icon: 'star half outline' },
      { key: '1', value: '100M - 1B', text: '100M - 1B', icon: 'star outline' },
      { key: '3', value: '>1B', text: '> 1B', icon: 'star' },
    ])
  } else {
    return([ 
      
    ])
  }
} 

export const attemptOptions = [ 
  { key: '1', value: '1', text: '1' },
  { key: '2', value: '2', text: '2' },
  { key: '3', value: '3', text: '3' },
  { key: '4', value: '4', text: '4' },
  { key: '5', value: '5', text: '5' },
  { key: '6', value: '6', text: '6' },
  { key: '7', value: '7', text: '7' },
  { key: '8', value: '8', text: '8' },
  { key: '9', value: '9', text: '9' },
  { key: '10', value: '10', text: '10' },
  { key: '15', value: '15', text: '15' },
  { key: '20', value: '20', text: '20' },
]
export const queuedOptions = [ 
  { key: '1', value: '5', text: '5 Seconds' },
  { key: '2', value: '10', text: '10 Seconds' },
  { key: '3', value: '15', text: '15 Seconds' },
]
export const achievedOptions = [ 
  { key: '1', value: 1, text: '1 Day' },
  { key: '2', value: 3, text: '3 Days' },
  { key: '3', value: 5, text: '5 Days' },
  { key: '4', value: 7, text: '7 Days' },
]
export const calldelayOptions = [ 
  // { key: '1', value: '60', text: '1 Minute' },
  { key: '2', value: '120', text: '2 Minutes' },
  { key: '3', value: '180', text: '3 Minutes' },
  { key: '4', value: '300', text: '5 Minutes' },
  { key: '5', value: '600', text: '10 Minutes' },
  { key: '6', value: '900', text: '15 Minutes' },
]


export const flowOptions = [ 
  { key: '1', value: 'Inbound', text: 'Inbound' },
  { key: '2', value: 'Outbound', text: 'Outbound' },
]

export const businessOptions = [
  { key: '1', value: 'Fintech', text: 'Fintech' },
  { key: '2', value: 'Banking', text: 'Banking' },
  { key: '3', value: 'Insurance', text: 'Insurance' },
  { key: '4', value: 'Financing', text: 'Financing' },
  { key: '5', value: 'Electronic', text: 'Electronic' },
  { key: '6', value: 'Automotive', text: 'Automotive' },
  { key: '7', value: 'Food / Beverage', text: 'Food / Beverage' },
  { key: '8', value: 'Consumer Good', text: 'Consumer Good' },
  { key: '9', value: 'Apparel', text: 'Fashion / Apparel' },
  { key: '10', value: 'Restaurant', text: 'Restaurant / Cafe' },
  { key: '11', value: 'Retailer', text: 'Retailer' },
  { key: '12', value: 'Wholesaler', text: 'Wholesaler' },
  { key: '13', value: 'Distributor', text: 'Distributor' },
  { key: '14', value: 'Manufacturer', text: 'Manufacturer' },
  { key: '15', value: 'Travel', text: 'Travel Agency' },
  { key: '16', value: 'Property', text: 'Property / Developer' },
]

export const accessOptions = [ 
  { key: '1', value: 'Web', text: 'Web' },
  { key: '2', value: 'Mobile', text: 'Mobile' },
  { key: '3', value: 'Android', text: 'Android' },
]

export const viewOptions= [
  { key: '0', value: 'Today', text: 'Today' },
  { key: '1', value: 'Monthly', text: 'Monthly' },
  { key: '2', value: 'Daily', text: 'Daily' },
]
export const prepaidOptions = [ 
  { key: '1', value: 'active', text: 'Active' },
  { key: '2', value: 'graced', text: 'Graced Period' },
  { key: '3', value: 'expired', text: 'Expired' },
]

export const hourOptions=(v)=>{
  if (v=='cutoff') {
    return([
      { key: '0', value: '21:00', text: '21:00' },
      { key: '1', value: '22:00', text: '22:00' },
      { key: '2', value: '23:00', text: '23:00' },
      { key: '3', value: '24:00', text: '24:00' },
      { key: '4', value: '01:00', text: '01:00' },
      { key: '5', value: '02:00', text: '02:00' },
      { key: '6', value: '03:00', text: '03:00' },
      { key: '7', value: '04:00', text: '04:00' },
      { key: '8', value: '05:00', text: '05:00' },
    ])
  } else {
    return([
      { key: '0', value: '07:00', text: '07:00' },
      { key: '1', value: '08:00', text: '08:00' },
      { key: '2', value: '09:00', text: '09:00' },
      { key: '3', value: '10:00', text: '10:00' },
      { key: '4', value: '11:00', text: '11:00' },
      { key: '5', value: '13:00', text: '13:00' },
      { key: '6', value: '14:00', text: '14:00' },
      { key: '7', value: '15:00', text: '15:00' },
      { key: '8', value: '16:00', text: '16:00' },
      { key: '9', value: '17:00', text: '17:00' },
      { key: '10', value: '18:00', text: '18:00' },
      { key: '11', value: '19:00', text: '19:00' },
      { key: '12', value: '20:00', text: '20:00' },
      // { key: '13', value: '21:00', text: '21:00' },
    ])
  }
} 
export const monthlyOptions = ()=> {
  let msec= new Date().getTime();
  let months= [];

  for(let i=0; i<12; i++) {
    let d= new Date();
    d.setMonth(d.getMonth() - i);
    months.push({
      // key: i, value: format(new Date(msec - (i * 1000 * 3600 * 24 * 28)), 'yyyyMM'), text: format(new Date(msec - (i * 1000 * 3600 * 24 * 28)), 'yyyy / MMM')
      key: i, value: format(d, 'yyyyMM'), text: format(d, 'yyyy / MMM')
    })
  }
  return months;
}

export const impactOptions = (v)=>{
  if (v=='ticket') {
    return([ 
      { key: '0', value: 'Downtime', text: 'Downtime' },
      { key: '1', value: 'Intermitten', text: 'Intermitten' },
      { key: '2', value: 'Error', text: 'Error Result' },
      { key: '3', value: 'Warning', text: 'Warning Only' },
    ])
  } else {
    return([
    ])
  }
}
export const scopeOptions = (v)=>{
  if (v=='ticket') {
    return([ 
      { key: '0', value: 'Local', text: 'Local' },
      { key: '1', value: 'Global', text: 'Global / All' },
    ])
  } else {
    return([ 
      { key: '0', value: 'Stream! Outbound', text: 'Stream! Outbound' },
      { key: '1', value: 'Flow! Omni-Channel', text: 'Flow! Omni-Channel' },
      { key: '2', value: 'Talk! In-App Call', text: 'Talk! In-App Call' },
      { key: '3', value: 'Ring! Office Phone', text: 'Ring! Office Phone' },
    ])
  }
}

export const addonsOptions = [ 
  { key: '0', value: 'AI Agents', text: 'AI Agents' },
  { key: '1', value: 'Mailbox Detection', text: 'Mailbox Detection' },
  { key: '2', value: 'Silent Callback', text: 'Silent Callback' },
]

export const ratioOptions = [ 
  { key: '1', value: 1, text: '1' },
  { key: '2', value: 2, text: '2' },
  { key: '3', value: 3, text: '3' },
  { key: '4', value: 4, text: '4' },
  { key: '5', value: 5, text: '5' },
  { key: '6', value: 6, text: '6' },
  { key: '7', value: 7, text: '7' },
  { key: '8', value: 8, text: '8' },
  { key: '9', value: 9, text: '9' },
  { key: '10', value: 10, text: '10' },
  { key: '11', value: 11, text: '11' },
  { key: '12', value: 12, text: '12' },
  { key: '13', value: 13, text: '13' },
  { key: '14', value: 14, text: '14' },
  { key: '15', value: 15, text: '15' },
  { key: '16', value: 16, text: '16' },
  { key: '17', value: 17, text: '17' },
  { key: '18', value: 18, text: '18' },
  { key: '19', value: 19, text: '19' },
  { key: '20', value: 20, text: '20' },
]
export const packageOptions = [ 
  { key: '0', value: 'Freemium', text: 'Freemium' },
  { key: '1', value: 'Enterprise', text: 'Enterprise' },
]
export const billingOptions = [ 
  { key: '0', value: 'prepaid', text: 'Prepaid / Deposit' },
  { key: '1', value: 'postpaid', text: 'Postpaid' },
]
export const channelOptions = (v)=>{
  if (v=='payment') {
    return(
      [ 
        { key: '0', value: 'OVO', text: 'OVO' },
        { key: '1', value: 'Gopay', text: 'Gopay' },
        { key: '2', value: 'LinkAja', text: 'LinkAja' },
        <Divider style={{margin: '0 0'}} key= '3' />,
        { key: '4', value: 'Alfamart', text: 'Alfamart' },
        { key: '5', value: 'Indomaret', text: 'Indomaret' },
        <Divider style={{margin: '0 0'}} key= '6' />,
        { key: '7', value: 'CC', text: 'Kartu Kredit' },
        { key: '8', value: 'Transfer Bank', text: 'Transfer Bank' },
        { key: '9', value: 'Virtual Account', text: 'Virtual Account' },
      ]
    )  
  } else if (v=='voice') {
    return(
      [ 
        { key: '0', value: 'Voice', text: 'Voice / Call' },
        { key: '1', value: 'IVR', text: 'IVR / Text To Speech' },
      ]
    )  
  } else if (v=='messaging') {
    return(
      [ 
        { key: '2', value: 'SMS', text: 'SMS' },
        { key: '3', value: 'Email', text: 'Email' },
        { key: '4', value: 'WA', text: 'Whatsapp' },
        { key: '5', value: 'Chat', text: 'Chat' },
        // { key: '6', value: 'Telegram', text: 'Telegram' },
      ]
    )
  } else if (v=='social') {
    return(
      [ 
        { key: '6', value: 'Twitter', text: 'Twitter' },
        { key: '7', value: 'Facebook', text: 'Facebook' },
        { key: '8', value: 'Instagram', text: 'Instagram' },
      ]
    )
  } else if (v=='ticket') {
    return(
      [ 
        { key: '0', value: 'Voice', text: 'Voice / Call' },
        { key: '1', value: 'Chat', text: 'Chat' },
        { key: '2', value: 'SMS', text: 'SMS' },
        { key: '3', value: 'Email', text: 'Email' },
        { key: '4', value: 'Whatsapp', text: 'Whatsapp' },
        { key: '5', value: 'Telegram', text: 'Telegram' },
        { key: '6', value: 'Twitter', text: 'Twitter' },
        { key: '7', value: 'Facebook', text: 'Facebook' },
        { key: '8', value: 'Instagram', text: 'Instagram' },
      ]
    )
  }  else if (v=='channel') {
    return(
      [ 
        { key: '1', value: 'Audio', text: 'Audio' },
        { key: '2', value: 'Video', text: 'Video' },
      ]
    )
  } else if (v=='otp')  {
    return(
      [ 
        { key: '0', value: 'Voice', text: 'Voice / Call' },
        // { key: '1', value: 'SMS', text: 'SMS' },
        // { key: '2', value: 'Email', text: 'Email' },
        // { key: '3', value: 'Whatsapp', text: 'Whatsapp' },
      ]
    )
  } else if (v=='comm')  {
    return(
      [ 
        { key: '0', value: 'Telephony', text: 'Telephony' },
        // { key: '1', value: 'Messaging', text: 'Messaging' },
        // { key: '2', value: 'Socmed', text: 'Social Media' },
      ]
    )
  } else if (v=='Telephony') {
    return(
      [ 
        { key: '0', value: 'Voice', text: 'Voice / Call' },
        // { key: '1', value: 'IVR', text: 'IVR / Text To Speech' },
      ]
    )  
  } else if (v=='Messaging') {
    return(
      [ 
        { key: '0', value: 'SMS', text: 'SMS' },
        { key: '1', value: 'Email', text: 'Email' },
        { key: '2', value: 'Whatsapp', text: 'Whatsapp' },
        // { key: '3', value: 'Telegram', text: 'Telegram' },
      ]
    )  
  } else if (v=='Socmed') {
    return(
      [ 
        { key: '0', value: 'Twitter', text: 'Twitter' },
      ]
    )  
  } else {
    return(
      [ 
        { key: '0', value: 'Voice', text: 'Voice / Call' },
        { key: '1', value: 'IVR', text: 'IVR / Text To Speech' },
        { key: '2', value: 'SMS', text: 'SMS' },
        { key: '3', value: 'Email', text: 'Email' },
        { key: '4', value: 'Whatsapp', text: 'Whatsapp' },
        { key: '5', value: 'Telegram', text: 'Telegram' },
        { key: '6', value: 'Twitter', text: 'Twitter' },
        { key: '7', value: 'Facebook', text: 'Facebook' },
        { key: '8', value: 'Instagram', text: 'Instagram' },
      ]
    )
  }
} 
export const gatewayOptions=(v)=>{
  if (v=='wa') {
    return([
      { key: '0', value: 'Mobile', text: 'My Mobile Number', icon: 'mobile alternate' },
      { key: '1', value: 'Gateway', text: 'External WA Gateway', icon: 'server' },
    ])
  } else if (v=='sms') {
    return([
      { key: '0', value: 'Mobile', text: 'My Mobile Device', icon: 'mobile alternate' },
      { key: '1', value: 'Gateway', text: 'External SMS Gateway', icon: 'server' },
    ])
  } else if (v=='mail') {
    return([
      { key: '0', value: 'postal', text: 'SMTP Relay by Halo Pagi!', icon: 'rocket' },
      // { key: '1', value: 'gmail', text: 'Gmail SSO', icon: 'google' },
      // { key: '2', value: 'smtp', text: 'SMTP Relay', icon: 'server' },
    ])
  } else if (v=='direction') {
    return([
      { key: '0', value: 'inbound', text: 'Inbound' },
      // { key: '1', value: 'outbound', text: 'Outbound' },
      { key: '2', value: 'broadcast', text: 'Broadcast' },
    ])
  } else {
    return([
    ])
  }
}
export const paidOptions = [ 
  { key: '0', value: '0', text: 'Unpaid' },
  { key: '1', value: '1', text: 'Paid' },
  { key: '2', value: '2', text: 'Partial' },
]
export const contractOptions = [ 
  { key: '0', value: '0', text: 'Disabled' },
  { key: '1', value: '1', text: 'Enabled' },
  { key: '2', value: '-1', text: 'Expired' },
]
export const deliverOptions = [ 
  { key: '0', value: '0', text: 'Pending' },
  { key: '1', value: '1', text: 'Sent' },
  { key: '2', value: '3', text: 'View' },
]
export const campaignOptions = [ 
  { key: '0', value: 'Collection', text: 'Collection' },
  { key: '1', value: 'Restructuring', text: 'Restructuring' },
  { key: '2', value: 'Telesales', text: 'Telesales' },
  { key: '3', value: 'Verification', text: 'Verification' },
  // { key: '3', value: 'Insurance', text: 'Insurance' },
  // { key: '4', value: 'Tourism', text: 'Tourism' },
]
export const redistOptions = [ 
  { key: '0', value: 'Delivered', text: 'Delivered' },
  { key: '1', value: 'Read', text: 'Read' },
  { key: '2', value: 'Talk', text: 'Talk' },
  { key: '3', value: 'Clicked', text: 'Clicked' },
  { key: '4', value: 'Valid', text: 'Valid' },
  { key: '5', value: 'Invalid', text: 'Invalid' },
  { key: '6', value: 'Error', text: 'Error' },
]
export const assignmenOptions = [ 
  { key: '0', value: 'Evenly', text: 'Evenly / Auto Distribution' },
  { key: '1', value: 'Manual', text: 'Manual / Custom Number Setup' },
]



export const occupationOptions = [ 
  { key: '0', value: 'Wiraswasta', text: 'Wiraswasta' }, 
  { key: '1', value: 'Pengusaha', text: 'Pengusahar' }, 
]
export const revenueOptions = [ 
  { key: '0', value: '5-10', text: '5Jt s.d. 10 Jt' }, 
  { key: '1', value: '10>', text: '10Jt >>' }, 
]
export const expenseOptions = [ 
  { key: '0', value: '5-10', text: '5Jt s.d. 10 Jt' }, 
  { key: '1', value: '10>', text: '10Jt >>' }, 
]
export const homestayOptions = [ 
  { key: '0', value: 'Sewa', text: 'Sewa' }, 
  { key: '1', value: 'Beli', text: 'Beli' }, 
]
export const vehicleOptions = [ 
  { key: '0', value: 'Mobil', text: 'Mobil' }, 
  { key: '1', value: 'Motor', text: 'Motor' }, 
]

export const competencyOptions = [ 
  { key: '0', value: '', text: '-' }, 
  { key: '1', value: 'Ekonomi', text: 'Ekonomi' }, 
  { key: '2', value: 'Sosial', text: 'Sosial' }, 
  { key: '3', value: 'Teknologi', text: 'Teknologi' }, 
  { key: '4', value: 'Keuangan', text: 'Keuangan' }, 
  { key: '5', value: 'Psikologi', text: 'Psikologi' }, 
  { key: '6', value: 'Pengetahuan Umum', text: 'Pengetahuan Umum' }, 
]

export const educationOptions = [ 
  { key: '0', value: '', text: '-' }, 
  { key: '1', value: 'SMP', text: 'SMP' }, 
  { key: '2', value: 'SMA/SMK', text: 'SMA / SMK' }, 
  { key: '3', value: 'S1', text: 'S1' }, 
  { key: '4', value: 'S2', text: 'S2' }, 
  { key: '5', value: 'S3', text: 'S3' }, 
]

export const expertiseOptions = [ 
  { key: '1', value: 'Assistant', text: 'Assistant' }, 
  { key: '2', value: 'Senior', text: 'Senior' }, 
  { key: '3', value: 'Professional', text: 'Professional' }, 
  { key: '4', value: 'Expert', text: 'Expert' }, 
]

export const priorityOptions = (v)=> {
  if (v=='customer') {
    return([ 
      { key: '1', value: 1, text: 'Gold' }, 
      { key: '2', value: 5, text: 'Silver' }, 
      { key: '3', value: 10, text: 'Bronze' }, 
    ])
  } else {
    return([ 
      { key: '1', value: 1, text: 'Urgent' }, 
      { key: '2', value: 3, text: 'Important' }, 
      { key: '3', value: 5, text: 'Normal' }, 
      { key: '4', value: 10, text: 'Low' }, 
    ])
  }
}

export const maritalOptions = [ 
  { key: '1', value: '-', text: '-' }, 
  { key: '2', value: 'Single', text: 'Single' }, 
  { key: '3', value: 'Maried', text: 'Nikah' }, 
]

export const identificationOptions = [ 
  { key: '1', value: 'NIK', text: 'NIK' }, 
  { key: '2', value: 'NPWP', text: 'NPWP' }, 
]

export const genderOptions = [ 
  { key: '0', value: '', text: '-' }, 
  { key: '1', value: 'L', text: 'Laki-Laki' }, 
  { key: '2', value: 'P', text: 'Perempuan' }, 
]

export const calculationOptions= (v) =>{
  return[
    { key: '1', value: 'Fixed', text: 'Fixed' }, 
    { key: '2', value: 'Floating', text: 'Floating' }, 
  ]
}

export const yesnoOptions=(mod)=>{
  if (mod=='verified') {
    return([ 
      { key: '0', value: 0, text: 'Invalid' },
      { key: '1', value: 1, text: 'Verified' },
      { key: '2', value: -1, text: 'Suspect' },
    ])
  } else if (mod=='billing') {
    return([ 
      { key: '0', value: 1, text: 'Paid' },
      { key: '1', value: 2, text: 'Partial Paid' },
      { key: '2', value: 0, text: 'Unpaid' },
      // { key: '3', value: '1', text: 'Valid' },
      { key: '4', value: -1, text: 'Expired' },
    ])
  } else if (mod=='payment') {
    return([ 
      { key: '0', value: '2', text: 'Receive Payment' },
      // { key: '1', value: '2', text: 'Partial Paid' },
      { key: '1', value: '0', text: 'No Payment' },
      { key: '2', value: '1', text: 'Verified / Settled' },
    ])
  } else if (mod=='enabled') {
    return([ 
      { key: '1', value: '1', text: 'Enabled' },
      { key: '0', value: '0', text: 'Disabled' },
    ])
  } else if (mod=='available') {
    return([ 
      { key: '1', value: 1, text: 'Available', 
      content: (<><Icon name='check' color='blue'/> Available </>) ,
      },
      { key: '0', value: 0, text: 'Nope', 
      content: (<><Icon name='close' color='red' /> Nope </>) ,
      },
    ])
  }  else if (mod=='ivr') {
    return([ 
      { key: '1', value: '1', text: 'No'},
      { key: '0', value: '0', text: 'Yes'},
    ])
  } else if (mod=='ok') {
    return([ 
      { key: '1', value: '1', text: 'OK' },
      { key: '0', value: '0', text: 'NOK' },
    ])
  } else {
    return([ 
      { key: '1', value: '1', text: 'Yes' },
      { key: '0', value: '0', text: 'No' },
    ])
  }
}

export const durationOptions= (v)=>{
  if (v=='ticket') {
    return([
      { key: '1', value: 1, text: '1 Hour' },
      { key: '2', value: 2, text: '2 Hours' },
      { key: '3', value: 3, text: '3 Hours' },
      { key: '4', value: 4, text: '4 Hours' },
      { key: '5', value: 5, text: '5 Hours' },
      { key: '6', value: 6, text: '6 Hours' },
      { key: '7', value: 7, text: '7 Hours' },
      { key: '8', value: 8, text: '8 Hours' },
    ])
  } else {
    return([ 
      { key: '0', value: 1, text: '1 Month' },
      { key: '1', value: 12, text: '12 Months' },
      { key: '2', value: 24, text: '24 Months' },
    ])
  }
}

export const probabilityOptions = [ 
  { key: '0', value: 30, text: '30%' },
  { key: '1', value: 50, text: '50%' },
  { key: '2', value: 75, text: '75%' },
  { key: '3', value: 90, text: '90%' },
]
export const periodOptions=(v)=>{
  if (v=='contract') {
    return(
      [
        { key: '1', value: '3', text: '3 Months' },
        { key: '2', value: '6', text: '6 Months' },
        { key: '3', value: '12', text: '12 Months' },
        { key: '4', value: '24', text: '24 Months' },
      ]
    )
  } else if (v=='billing') {
    return(
      [
        { key: '1', value: 'Monthly', text: 'Monthly' },
        { key: '2', value: 'Yearly', text: 'Yearly' },
      ]
    )
  } else if (v=='dashboard') {
    return(
      [
        { key: '1', value: 'Monthly', text: 'Monthly' },
        { key: '2', value: 'Yearly', text: 'Yearly' },
      ]
    )
  } else if (v=='ticket') {
    return(
      [
        { key: '1', value: 'Daily', text: 'Daily' },
        { key: '2', value: 'Monthly', text: 'Monthly' },
        { key: '3', value: 'Yearly', text: 'Yearly' },
      ]
    )
  } else {
    return(
      [
        { key: '1', value: 'Daily', text: 'Daily' },
        { key: '2', value: 'Monthly', text: 'Monthly' },
        // { key: '3', value: 'Yearly', text: 'Yearly' },
      ]
    )
  }
}

export const ratingOptions = (v)=>{
  if (v=='quality') {
    return(
      [ 
        { key: '1', value: 1, text: 'Worst',},
        { key: '1', value: 2, text: 'Bad',},
        { key: '2', value: 3, text: 'Average',},
        { key: '3', value: 4, text: 'Good',},
        { key: '4', value: 5, text: 'Awesome',},
      ]
    )
  } else {
    return(
      [ 
        { key: '1', value: 1, text: '1 Star', 
        content: (<><Rating icon='star' defaultRating={1} maxRating={5} disabled/> 1 Star </>) ,
        },
        { key: '2', value: 2, text: '2 Star', 
        content: (<><Rating icon='star' defaultRating={2} maxRating={5} disabled/> 2 Star </>) ,
        },
        { key: '3', value: 3, text: '3 Star', 
        content: (<><Rating icon='star' defaultRating={3} maxRating={5} disabled/> 3 Star </>) ,
        },
        { key: '4', value: 4, text: '4 Star', 
        content: (<><Rating icon='star' defaultRating={4} maxRating={5} disabled/> 4 Star </>) ,
        },
        { key: '5', value: 5, text: '5 Star', 
        content: (<><Rating icon='star' defaultRating={5} maxRating={5} disabled/> 5 Star </>) ,
        },
      ]
    )
  }
}

export const protoOptions = [
  { key: '1', text: 'wss - Websocket', value: 'wss' },
  { key: '2', text: 'udp - Connectionless / Reliable but no QOS', value: 'udp' },
  { key: '3', text: 'tcp - Connection Oriented / Reliable With QOS', value: 'tcp' },
  { key: '4', text: 'any - Any supported protocol', value: 'any' },
]
export const voipOptions = (v)=>{
  if (v=='mau-talk') {
    return(
      [
        { key: '3', text: 'Customer (In-App Call)', value: 'Customer' },
      ]
    )
  } else if (v=='mau-ring') {
    return(
      [
        { key: '3', text: 'Extension (Office Phone)', value: 'Extension' },
      ]
    )
  } else  if (v=='mau') {
    return(
      [
        // { key: '1', text: 'Agent', value: 'Agent' },
        // { key: '2', text: 'Public', value: 'Public' },
        { key: '3', text: '70[6-9]XXXXXXX - Cust.In-App', value: 'Customer' },
        { key: '4', text: '70[4-5]XXXXXXX - Ext. Office', value: 'Extension' },
      ]
    )
  } else if (v=='voip') {
    return(
      [
        { key: '1', text: '700000XXXX - Agent', value: 'Agent' },
        { key: '2', text: '70[2-3]XXXXXXX - Public', value: 'Public' },
        { key: '3', text: '70[6-9]XXXXXXX - Cust. In-App', value: 'Customer' },
        { key: '3', text: '70[4-5]XXXXXXX - Ext. Office', value: 'Extension' },
      ]
    )
  } else {
    return(
      [
        { key: '1', text: '700000XXXX - Agent', value: 'Agent' },
        { key: '2', text: '70[2-3]XXXXXXX - Public', value: 'Public' },
        { key: '3', text: '70[6-9]XXXXXXX - Cust. In-App', value: 'Customer' },
        { key: '3', text: '70[4-5]XXXXXXX - Ext. Office', value: 'Extension' },
      ]
    )
  }
}

export const cardOptions = [
  { key: '1', text: 'KTP', value: 'KTP' },
  { key: '2', text: 'SIM', value: 'SIM' },
  { key: '3', text: 'PASSPOR', value: 'Passpor' },
]

export const months= [
  {value: '01',name: 'January'},
  {value: '02',name: 'February'},
  {value: '03',name: 'March'},
  {value: '04',name: 'April'},
  {value: '05',name: 'May'},
  {value: '06',name: 'June'},
  {value: '07',name: 'July'},
  {value: '08',name: 'August'},
  {value: '09',name: 'September'},
  {value: '10',name: 'October'},
  {value: '11',name: 'November'},
  {value: '12',name: 'December'},
]

export const cdrOptions = [ 
  { key: '1', value: 'Attempt', text: 'Attempt' }, 
  { key: '2', value: 'Delivered', text: 'Delivered' },
]
export const transactionOptions = (v) =>{
  if (v=='purchase') {
    return([ 
      { key: '1', value: 'Purchase', text: 'Purchase' },
    ])
  } else if (v=='subscribe') {
    return([ 
      { key: '1', value: 'Subscribe', text: 'Subscribe / Monthly' }, 
      { key: '2', value: 'Subscribe / Yearly', text: 'Subscribe / Yearly' }, 
    ])
  } else if (v=='prospect') {
    return([ 
      { key: '1', value: 'Purchase', text: 'Purchase' },
      { key: '2', value: 'Subscribe', text: 'Subscribe / Monthly' }, 
      { key: '3', value: 'Subscribe / Yearly', text: 'Subscribe / Yearly' }, 
    ])
  } else {
    return([ 
      { key: '1', value: 'Prospect', text: 'Prospect' },
      { key: '2', value: 'Engaged', text: 'Engaged' }, 
      { key: '3', value: 'Achieved', text: 'Achieved' }, 
      { key: '4', value: 'Confirmed', text: 'Confirmed' },
    ])
  }
}
export const delimiterOptions = [ 
  // { key: '0', value: '', text: '' }, 
  // { key: '1', value: '\t', text: 'Tab (\t)' }, 
  { key: '2', value: ',', text: 'CSV (,)' }, 
  { key: '3', value: ';', text: 'CSV (;)' }, 
  // { key: '3', value: '&&', text: 'Double Ampersand (&&)' }, 
  // { key: '4', value: '##', text: 'Double Sharp (##)' }, 
  // { key: '5', value: ';;', text: 'Double Semicolon (;;)' }, 
]

export const discountOptions = [ 
  { key: '0', value: 'Amount', text: 'Amount' }, 
  { key: '1', value: 'Percent', text: 'Percent' }, 
  { key: '2', value: 'Voucher', text: 'Voucher' }, 
]
export const usageOptions = [ 
  { key: '0', value: 'Used', text: 'Used' }, 
  { key: '1', value: 'Unused', text: 'Unused' }, 
]


export const fieldTargetOptions = [ 
  { key: '00', text: 'Contact - Mobile', value: 'mobile' },
  { key: '01', text: 'Contact - Home Phone', value: 'homePhone' },
  { key: '02', text: 'Contact - Office Phone', value: 'officePhone' },
  { key: '03', text: 'Contact - Whatsapp', value: 'whatsapp' },
  { key: '04', text: 'Contact - Telegram', value: 'telegram' },
  { key: '05', text: 'Contact - Twitter', value: 'twitter' },
  { key: '06', text: 'Contact - Facebook', value: 'facebook' },
  { key: '07', text: 'Contact - Instagram', value: 'instagram' },


  { key: '08', text: 'Contact - Emergency Phone', value: 'emergencyPhone' },
  { key: '09', text: 'Contact - Emergency Name', value: 'emergencyName' },
  { key: '10', text: 'Contact - Emergency Relationship', value: 'emergencyRelationship' },

  { key: '11', text: 'Contact - Other Phone', value: 'otherPhone' },
  { key: '12', text: 'Contact - Other Name', value: 'otherName' },
  { key: '13', text: 'Contact - Other Relationship', value: 'otherRelationship' },

  //COLLECTIONS
  { key: '20', text: 'Collection - Bill Amount', value: 'billAmount' },
  { key: '21', text: 'Collection - Bill Date', value: 'billDate' },
  { key: '22', text: 'Collection - Due Date', value: 'dueDate' },
  { key: '23', text: 'Collection - Overdue Day', value: 'overdueDay' },
  { key: '24', text: 'Collection - Overdue Amount', value: 'overdueAmount' },

  { key: '25', text: 'Collection - Principal Amount', value: 'principalAmount' },
  { key: '26', text: 'Collection - Interest Amount', value: 'interestAmount' },
  { key: '27', text: 'Collection - Service Fee', value: 'servicefeeAmount' },
  { key: '28', text: 'Collection - Penalty Fee', value: 'penaltyfeeAmount' },
  { key: '29', text: 'Collection - Discount Amount', value: 'discountAmount' },

  { key: '30', text: 'Collection - Max Days Past Due', value: 'maxDPD' },
  { key: '31', text: 'Collection - Min. Payment Amount', value: 'paymentAmount' },
  { key: '32', text: 'Collection - Service / Product', value: 'service' },
  { key: '33', text: 'Collection - Bucket Info', value: 'bucket' },

  //TELESALES / VERIFICATION
  { key: '40', text: 'Telesales/Survey - Product', value: 'product' },
  { key: '41', text: 'Telesales/Survey - Valuation', value: 'valuation' },
  { key: '42', text: 'Telesales/Survey - Information', value: 'information' },

  //CONTACT TARGET
  { key: '60', text: 'Target - Priority', value: 'priority' },
  { key: '61', text: 'Target - Tagger', value: 'tagger' },
  
  { key: '62', text: 'Target - Project', value: 'project' },
  { key: '64', text: 'Target - Data Source', value: 'dataOrigin' },
  { key: '63', text: 'Target - Data Maturity', value: 'dataMaturity' },

  { key: '65', text: 'Target - Name', value: 'name' },
  { key: '87', text: 'Target - Information', value: 'descript' },

  { key: '66', text: 'Target - Cust. Ref.', value: 'custRef' },
  { key: '67', text: 'Target - Contract Ref.', value: 'contractRef' },
  { key: '68', text: 'Target - Status', value: 'custStatus' },
  { key: '69', text: 'Target - Gender', value: 'gender' },
  { key: '70', text: 'Target - Birth Date', value: 'birthDate' },
  { key: '71', text: 'Target - Marital.', value: 'marital' },
  { key: '72', text: 'Target - Children', value: 'children' },
  { key: '73', text: 'Target - Address', value: 'address' },
  { key: '74', text: 'Target - City', value: 'city' },
  { key: '75', text: 'Target - Province', value: 'province' },
  { key: '76', text: 'Target - Country', value: 'country' },
  { key: '77', text: 'Target - Zipcode', value: 'zipcode' },

  { key: '78', text: 'Target - Occupation', value: 'occupation' },
  { key: '79', text: 'Target - Job Title', value: 'jobTitle' },
  { key: '80', text: 'Target - Department', value: 'jobDepartment' },
  { key: '81', text: 'Target - Company', value: 'jobCompany' },
  { key: '82', text: 'Target - Home Ownership', value: 'homestay' },
  { key: '83', text: 'Target - Vehicle Ownership', value: 'vehicle' },
  { key: '84', text: 'Target - Revenue', value: 'revenue' },
  { key: '85', text: 'Target - Expense', value: 'expense' },
  { key: '86', text: 'Target - Credit Card', value: 'creditcard' },

  //MERCHANT REFERENCE
  { key: '90', text: 'Merchant - Code', value: 'merchantCode' },
  { key: '91', text: 'Merchant - Name', value: 'merchantName' },
  { key: '92', text: 'Merchant - Warehouse', value: 'merchantWarehouse' },
  { key: '93', text: 'Merchant - Acquisition', value: 'merchantAcquisition' },
  { key: '94', text: 'Merchant - Prev. AOV', value: 'previousAov' },
  { key: '95', text: 'Merchant - Prev. Freq. Order', value: 'previousFreqOrder' },
  { key: '96', text: 'Merchant - Curr. AOV', value: 'currentAov' },
  { key: '97', text: 'Merchant - Curr. Freq. Order', value: 'currentFreqOrder' },

  { key: '98', text: 'Merchant - Limit Maximum', value: 'limitMaximum' },
  { key: '99', text: 'Merchant - Limit Remaining', value: 'limitRemaining' },
  { key: '101', text: 'Merchant - Prev. Month Trx.', value: 'previousMonthTrx' },
  { key: '102', text: 'Merchant - Curr. Month Trx.', value: 'currentMonthTrx' },
  
  { key: '103', text: 'Merchant - Retention GMV Status', value: 'retentionGmvStatus' },
  { key: '104', text: 'Merchant - Retention Order Status', value: 'retentionOrderStatus' },
  { key: '105', text: 'Merchant - Retention App Status', value: 'retentionAppStatus' },
  { key: '106', text: 'Merchant - Retention Physical Segment', value: 'retentionPhysicalSegment' },
  { key: '108', text: 'Merchant - Retention Journey', value: 'retentionJourney' },
  { key: '109', text: 'Merchant - Retention Access', value: 'retentionAccess' },
  { key: '110', text: 'Merchant - Retention Segment', value: 'retentionSegment' },
  { key: '111', text: 'Merchant - Retention Count Trx.', value: 'retentionCountTrx' },
  { key: '112', text: 'Merchant - Retention GMV Accumulated', value: 'retentionGmvAccumulated' },
  { key: '113', text: 'Merchant - Retention Trx. Ungroup', value: 'retentionCountTrxUngroup' },
  { key: '114', text: 'Merchant - Retention Uninstall Status', value: 'retentionUninstallStatus' },
  { key: '115', text: 'Merchant - Retention App Engine', value: 'retentionAppEngine' },
  { key: '116', text: 'Merchant - Retention Outbound Call', value: 'retentionOutboundCall' },
  { key: '117', text: 'Merchant - Retention RFM', value: 'retentionRfm' },
  { key: '118', text: 'Merchant - Retention Churn Progress', value: 'retentionChurnProgress' },
]

export const fieldCdrOptions = [ 
  { key: '00', text: 'Contact - Mobile', value: 'mobile' },
  { key: '01', text: 'Contact - Home Phone', value: 'homePhone' },
  { key: '02', text: 'Contact - Office Phone', value: 'officePhone' },
  // { key: '03', text: 'Contact - Whatsapp', value: 'whatsapp' },
  // { key: '04', text: 'Contact - Telegram', value: 'telegram' },
  // { key: '05', text: 'Contact - Twitter', value: 'twitter' },
  // { key: '06', text: 'Contact - Facebook', value: 'facebook' },
  // { key: '07', text: 'Contact - Instagram', value: 'instagram' },


  { key: '08', text: 'Contact - Emergency Phone', value: 'emergencyPhone' },
  { key: '09', text: 'Contact - Emergency Name', value: 'emergencyName' },
  { key: '10', text: 'Contact - Emergency Relationship', value: 'emergencyRelationship' },

  { key: '11', text: 'Contact - Other Phone', value: 'otherPhone' },
  { key: '12', text: 'Contact - Other Name', value: 'otherName' },
  { key: '13', text: 'Contact - Other Relationship', value: 'otherRelationship' },


  //COLLECTIONS
  { key: '20', text: 'Collection - Bill Amount', value: 'billAmount' },
  { key: '21', text: 'Collection - Bill Date', value: 'billDate' },
  { key: '22', text: 'Collection - Due Date', value: 'dueDate' },
  { key: '23', text: 'Collection - Overdue Day', value: 'overdueDay' },
  { key: '24', text: 'Collection - Overdue Amount', value: 'overdueAmount' },

  { key: '25', text: 'Collection - Principal Amount', value: 'principalAmount' },
  { key: '26', text: 'Collection - Interest Amount', value: 'interestAmount' },
  { key: '27', text: 'Collection - Service Fee', value: 'servicefeeAmount' },
  { key: '28', text: 'Collection - Penalty Fee', value: 'penaltyfeeAmount' },
  { key: '29', text: 'Collection - Discount Amount', value: 'discountAmount' },

  { key: '30', text: 'Collection - Max Days Past Due', value: 'maxDPD' },
  { key: '31', text: 'Collection - Min. Payment Amount', value: 'paymentAmount' },
  { key: '32', text: 'Collection - Service / Product', value: 'service' },
  { key: '33', text: 'Collection - Bucket Info', value: 'bucket' },

  //TELESALES / VERIFICATION
  { key: '40', text: 'Telesales/Survey - Product', value: 'product' },
  { key: '41', text: 'Telesales/Survey - Valuation', value: 'valuation' },
  { key: '42', text: 'Telesales/Survey - Information', value: 'information' },
  
  //AGENT
  { key: '201', text: 'Agent - Name', value: 'agentName' },
  { key: '202', text: 'Agent - Account', value: 'agentAccount' },
  { key: '203', text: 'Agent - Tagger', value: 'agentTagger' },


  //CALL REMARK
  { key: '219', text: 'Call - Remark', value: 'callRemark' }, 
  { key: '220', text: 'Call - Remark Reason', value: 'callRemarkReason' }, 
  { key: '221', text: 'Call - Progress', value: 'callProgress' },
  
  //CALL INFORMATION
  { key: '222', text: 'Call - Flow', value: 'flow' },
  { key: '223', text: 'Call - Originating', value: 'originating' },
  { key: '224', text: 'Call - Terminating', value: 'terminating' },
  { key: '225', text: 'Call - Status', value: 'callStatus' },
  { key: '226', text: 'Call - Reason', value: 'callReason' },
  { key: '227', text: 'Call - Channel', value: 'channelType' },
  
  // { key: '26', text: 'Call - Exten', value: 'exten' },
  // { key: '27', text: 'Call - Recording URL', value: 'recordingUri' },

  { key: '228', text: 'Call - Seq. Attempt', value: 'seqAttempt' },
  { key: '229', text: 'Call - Dial Mode', value: 'dialBy' },
  { key: '230', text: 'Call - Hangup By', value: 'hangupBy' },

  { key: '231', text: 'Call - Dial Stamp', value: 'dialStamp' },
  { key: '232', text: 'Call - Answer Stamp', value: 'callStamp' },
  { key: '233', text: 'Call - Queue Stamp', value: 'queueStamp' },
  { key: '234', text: 'Call - Requeue Stamp', value: 'requeueStamp' },
  { key: '235', text: 'Call - Talk Stamp', value: 'talkStamp' },
  { key: '236', text: 'Call - Drop Stamp', value: 'dropStamp' },
  { key: '237', text: 'Call - Hangup Stamp', value: 'hangupStamp' },

  { key: '238', text: 'Call - Ring Duration', value: 'ringDuration' },
  { key: '239', text: 'Call - Queue Duration', value: 'queueDuration' },
  { key: '240', text: 'Call - Call Duration', value: 'callDuration' },
  { key: '241', text: 'Call - Talk Duration', value: 'talkDuration' },

  { key: '242', text: 'Call - Audio Recording', value: 'recording' },

  //CAMPAIGN
  { key: '50', text: 'Campaign - Title', value: 'campaign' },
  { key: '51', text: 'Campaign - Type', value: 'campaignType' },
  { key: '52', text: 'Campaign - Start Date', value: 'startDate' },
  { key: '53', text: 'Campaign - End Date', value: 'endDate' },
  { key: '54', text: 'Campaign - Start Time', value: 'startTime' },
  { key: '55', text: 'Campaign - End Time', value: 'endTime' },

  
  //CONTACT TARGET
  { key: '57', text: 'Target - Remark', value: 'custRemark' },
  { key: '58', text: 'Target - Progress', value: 'custProgress' },
  { key: '59', text: 'Target - Tot. Attempt', value: 'custTotAttempt' },

  { key: '60', text: 'Target - Priority', value: 'priority' },
  { key: '61', text: 'Target - Tagger', value: 'custTagger' },
  
  { key: '62', text: 'Target - Project', value: 'project' },
  { key: '64', text: 'Target - Data Source', value: 'dataOrigin' },
  { key: '63', text: 'Target - Data Maturity', value: 'dataMaturity' },

  { key: '65', text: 'Target - Name', value: 'custName' },
  { key: '87', text: 'Target - Information', value: 'descript' },

  { key: '66', text: 'Target - Cust. Ref.', value: 'custRef' },
  { key: '67', text: 'Target - Contract Ref.', value: 'contractRef' },
  { key: '68', text: 'Target - Status', value: 'custStatus' },
  
  { key: '69', text: 'Target - Gender', value: 'gender' },
  { key: '70', text: 'Target - Birth Date', value: 'birthDate' },
  { key: '71', text: 'Target - Marital.', value: 'marital' },
  { key: '72', text: 'Target - Children', value: 'children' },
  { key: '73', text: 'Target - Address', value: 'address' },
  { key: '74', text: 'Target - City', value: 'city' },
  { key: '75', text: 'Target - Province', value: 'province' },
  { key: '76', text: 'Target - Country', value: 'country' },
  { key: '77', text: 'Target - Zipcode', value: 'zipcode' },

  { key: '78', text: 'Target - Occupation', value: 'occupation' },
  { key: '79', text: 'Target - Job Title', value: 'jobTitle' },
  { key: '80', text: 'Target - Department', value: 'jobDepartment' },
  { key: '81', text: 'Target - Company', value: 'jobCompany' },
  { key: '82', text: 'Target - Home Ownership', value: 'homestay' },
  { key: '83', text: 'Target - Vehicle Ownership', value: 'vehicle' },
  { key: '84', text: 'Target - Revenue', value: 'revenue' },
  { key: '85', text: 'Target - Expense', value: 'expense' },
  { key: '86', text: 'Target - Credit Card', value: 'creditcard' },

  //MERCHANT REFERENCE
  { key: '90', text: 'Merchant - Code', value: 'merchantCode' },
  { key: '91', text: 'Merchant - Name', value: 'merchantName' },
  { key: '92', text: 'Merchant - Warehouse', value: 'merchantWarehouse' },
  { key: '93', text: 'Merchant - Acquisition', value: 'merchantAcquisition' },
  { key: '94', text: 'Merchant - Prev. AOV', value: 'previousAov' },
  { key: '95', text: 'Merchant - Prev. Freq. Order', value: 'previousFreqOrder' },
  { key: '96', text: 'Merchant - Curr. AOV', value: 'currentAov' },
  { key: '97', text: 'Merchant - Curr. Freq. Order', value: 'currentFreqOrder' },

  { key: '98', text: 'Merchant - Limit Maximum', value: 'limitMaximum' },
  { key: '99', text: 'Merchant - Limit Remaining', value: 'limitRemaining' },
  { key: '101', text: 'Merchant - Prev. Month Trx.', value: 'previousMonthTrx' },
  { key: '102', text: 'Merchant - Curr. Month Trx.', value: 'currentMonthTrx' },
  
  { key: '103', text: 'Merchant - Retention GMV Status', value: 'retentionGmvStatus' },
  { key: '104', text: 'Merchant - Retention Order Status', value: 'retentionOrderStatus' },
  { key: '105', text: 'Merchant - Retention App Status', value: 'retentionAppStatus' },
  { key: '106', text: 'Merchant - Retention Physical Segment', value: 'retentionPhysicalSegment' },
  { key: '108', text: 'Merchant - Retention Journey', value: 'retentionJourney' },
  { key: '109', text: 'Merchant - Retention Access', value: 'retentionAccess' },
  { key: '110', text: 'Merchant - Retention Segment', value: 'retentionSegment' },
  { key: '111', text: 'Merchant - Retention Count Trx.', value: 'retentionCountTrx' },
  { key: '112', text: 'Merchant - Retention GMV Accumulated', value: 'retentionGmvAccumulated' },
  { key: '113', text: 'Merchant - Retention Trx. Ungroup', value: 'retentionCountTrxUngroup' },
  { key: '114', text: 'Merchant - Retention Uninstall Status', value: 'retentionUninstallStatus' },
  { key: '115', text: 'Merchant - Retention App Engine', value: 'retentionAppEngine' },
  { key: '116', text: 'Merchant - Retention Outbound Call', value: 'retentionOutboundCall' },
  { key: '117', text: 'Merchant - Retention RFM', value: 'retentionRfm' },
  { key: '118', text: 'Merchant - Retention Churn Progress', value: 'retentionChurnProgress' },
]
export const fieldMdrOptions = [
  { key: '9', text: 'Contract Ref.', value: 'contractRef' },
  { key: '7', text: 'Cust. Status', value: 'custStatus' },
  { key: '8', text: 'Cust. Ref', value: 'custRef' },

  { key: '60', text: 'Mesg. Progress', value: 'progress' },
  { key: '61', text: 'Mesg. Remark', value: 'remark' }, 

  { key: '20', text: 'Mobile', value: 'mobile' },
  // { key: '21', text: 'Home Phone', value: 'homePhone' },
  // { key: '22', text: 'Office Phone', value: 'officePhone' },
  { key: '23', text: 'Email', value: 'email' },
  { key: '24', text: 'Whatsapp', value: 'whatsapp' },
  // { key: '25', text: 'Telegram', value: 'telegram' },
  { key: '26', text: 'Twitter', value: 'twitter' },
  // { key: '27', text: 'Facebook', value: 'facebook' },
  // { key: '28', text: 'Instagram', value: 'instagram' },

  { key: '62', text: 'Mesg. Status', value: 'mesgStatus' },
  { key: '63', text: 'Mesg. Reason', value: 'mesgReason' },

  { key: '41', text: 'Channel Type', value: 'channelType' },
  { key: '42', text: 'Mesg. Flow', value: 'flow' },
  
  // { key: '43', text: 'Exten', value: 'exten' },
  { key: '44', text: 'Originating', value: 'originating' },
  { key: '45', text: 'Terminating', value: 'terminating' },
  // { key: '46', text: 'Recording URL', value: 'recordingUri' },

  { key: '49', text: 'Sent Stamp', value: 'sentStamp' },
  { key: '50', text: 'Delivered Stamp', value: 'deliveredStamp' },
  { key: '51', text: 'Read Stamp', value: 'readStamp' },
  { key: '53', text: 'Click Stamp', value: 'clickStamp' },
  { key: '54', text: 'Invalid Stamp', value: 'invalidStamp' },
  { key: '55', text: 'Spammed Stamp', value: 'spammedStamp' },

  { key: '56', text: 'Total. Read', value: 'totRead' },
  { key: '57', text: 'Total Click', value: 'totClick' },

  { key: '1', text: 'Campaign', value: 'campaign' },
  { key: '2', text: 'Campaign Type', value: 'campaignType' },
  { key: '3', text: 'Campaign Start Date', value: 'startDate' },
  { key: '4', text: 'Campaign End Date', value: 'endDate' },
  { key: '5', text: 'Campaign Start Time', value: 'startTime' },
  { key: '6', text: 'Campaign End Time', value: 'endTime' },

  { key: '11', text: 'Name', value: 'name' },

  { key: '12', text: 'Gender', value: 'gender' },
  { key: '13', text: 'Birth Date', value: 'birthDate' },
  { key: '14', text: 'Marital', value: 'marital' },
  { key: '15', text: 'Children', value: 'children' },

  { key: '29', text: 'Occupation', value: 'occupation' },
  { key: '30', text: 'Position', value: 'jobTitle' },
  { key: '31', text: 'Department', value: 'jobDepartment' },
  { key: '32', text: 'Company', value: 'jobCompany' },
  { key: '33', text: 'Home Ownership', value: 'homestay' },
  { key: '34', text: 'Vehicle Ownership', value: 'vehicle' },
  { key: '35', text: 'Salary', value: 'revenue' },
  { key: '36', text: 'Expense', value: 'expense' },
  { key: '37', text: 'Credit Card', value: 'creditcard' },

  { key: '16', text: 'Address', value: 'address' },
  { key: '17', text: 'City', value: 'city' },
  { key: '18', text: 'Province', value: 'province' },
  { key: '19', text: 'Country', value: 'country' },
]
export const fieldTrxOptions = [ 
  { key: '1', text: 'Contract Ref.', value: 'contractRef' },
  { key: '2', text: 'Cust. Status', value: 'custStatus' },
  { key: '3', text: 'Cust. Ref', value: 'custRef' },
  { key: '4', text: 'Cust. Tagger', value: 'custTagger' },

  { key: '5', text: 'Cust. Progress', value: 'progress' },
  { key: '6', text: 'Cust. Remark', value: 'remark' }, 

  { key: '7', text: 'Trx. Type', value: 'transactType' },
  { key: '8', text: 'Trx. Stamp', value: 'transactStamp' },
  { key: '9', text: 'Trx. Expired', value: 'expired' },

  { key: '10', text: 'Mobile', value: 'mobile' },
  { key: '11', text: 'Home Phone', value: 'homePhone' },
  { key: '12', text: 'Office Phone', value: 'officePhone' },

  { key: '13', text: 'Email', value: 'email' },
  { key: '14', text: 'Whatsapp', value: 'whatsapp' },
  // { key: '15', text: 'Telegram', value: 'telegram' },
  { key: '16', text: 'Twitter', value: 'twitter' },
  // { key: '17', text: 'Facebook', value: 'facebook' },
  // { key: '18', text: 'Instagram', value: 'instagram' },

  { key: '19', text: 'Channel Type', value: 'channelType' },
  { key: '20', text: 'Trx. Flow', value: 'flow' },

  { key: '21', text: 'Call Status', value: 'callStatus' },
  { key: '22', text: 'Call Reason', value: 'callReason' },

  { key: '23', text: 'Mesg. Status', value: 'mesgStatus' },
  { key: '24', text: 'Mesg. Reason', value: 'mesgReason' },

  { key: '25', text: 'Campaign', value: 'campaign' },
  { key: '26', text: 'Campaign Type', value: 'campaignType' },
  { key: '27', text: 'Campaign Start Date', value: 'startDate' },
  { key: '28', text: 'Campaign End Date', value: 'endDate' },
  { key: '29', text: 'Campaign Start Time', value: 'startTime' },
  { key: '30', text: 'Campaign End Time', value: 'endTime' },

  { key: '31', text: 'Agent Account', value: 'agentAccount' },
  { key: '32', text: 'Agent Tagger', value: 'agentTagger' },
  { key: '33', text: 'Agent Name', value: 'agentName' },

  { key: '34', text: 'Name', value: 'name' },

  { key: '35', text: 'Gender', value: 'gender' },
  { key: '36', text: 'Birth Date', value: 'birthDate' },
  { key: '37', text: 'Marital', value: 'marital' },
  { key: '38', text: 'Children', value: 'children' },

  { key: '39', text: 'Occupation', value: 'occupation' },
  { key: '40', text: 'Position', value: 'jobTitle' },
  { key: '41', text: 'Department', value: 'jobDepartment' },
  { key: '42', text: 'Company', value: 'jobCompany' },
  { key: '43', text: 'Home Ownership', value: 'homestay' },
  { key: '44', text: 'Vehicle Ownership', value: 'vehicle' },
  { key: '45', text: 'Salary', value: 'revenue' },
  { key: '46', text: 'Expense', value: 'expense' },
  { key: '47', text: 'Credit Card', value: 'creditcard' },

  { key: '48', text: 'Address', value: 'address' },
  { key: '49', text: 'City', value: 'city' },
  { key: '50', text: 'Province', value: 'province' },
  { key: '51', text: 'Country', value: 'country' },
]

export const popupModule=(mod)=>{
  if (mod=='collection') {
    return(
      [
        { key: '10', text: 'Collection - Bill Amount', value: 'billAmount' },
        { key: '21', text: 'Collection - Payment Amount', value: 'paymentAmount' },
        { key: '11', text: 'Collection - Bill Date', value: 'billDate' },
        { key: '12', text: 'Collection - Due Date', value: 'dueDate' },

        { key: '13', text: 'Collection - Overdue Day', value: 'overdueDay' },
        { key: '14', text: 'Collection - Overdue Amount', value: 'overdueAmount' },
        { key: '20', text: 'Collection - Max Days Past Due', value: 'maxDPD' },
        { key: '18', text: 'Collection - Penalty Fee', value: 'penaltyfeeAmount' },
        
        { key: '15', text: 'Collection - Principal Amount', value: 'principalAmount' },
        { key: '16', text: 'Collection - Interest Amount', value: 'interestAmount' },
        { key: '17', text: 'Collection - Service Fee', value: 'servicefeeAmount' },
        { key: '19', text: 'Collection - Discount Amount', value: 'discountAmount' },

        { key: '22', text: 'Collection - Service / Product', value: 'service' },
        { key: '23', text: 'Collection - Bucket Info', value: 'bucket' },
      ]
    )
  } else if (mod=='telesales') {
    return(
      [
        { key: '20', text: 'Telesales - Product', value: 'product' },
        { key: '21', text: 'Telesales - Valuation', value: 'valuation' },
        { key: '22', text: 'Telesales - Information', value: 'information' },
      ]
    )
  } else if (mod=='verification') {
    return(
      [
        { key: '20', text: 'Verification - Product', value: 'product' },
        { key: '21', text: 'Verification - Valuation', value: 'valuation' },
        { key: '22', text: 'Verification - Information', value: 'information' },
      ]
    )
  } else {
    return([

    ])
  }
}
export const popupCampaign= [
  { key: '50', text: 'Campaign - Title', value: 'campaign' },
  { key: '51', text: 'Campaign - Type', value: 'campaignType' },
  { key: '52', text: 'Campaign - Start Date', value: 'startDate' },
  { key: '53', text: 'Campaign - End Date', value: 'endDate' },
  { key: '54', text: 'Campaign - Start Time', value: 'startTime' },
  { key: '55', text: 'Campaign - End Time', value: 'endTime' },
]
export const popupPersonal= [
  { key: '60', text: 'Target - Project', value: 'project' },
  { key: '61', text: 'Target - Tagger', value: 'custTagger' },
  { key: '63', text: 'Target - Data Source', value: 'dataOrigin' },
  { key: '62', text: 'Target - Data Maturity', value: 'dataMaturity' },
  
  // { key: '64', text: 'Target - Name', value: 'name' }, => BY DEFAULT IS VISIBLE
  { key: '86', text: 'Target - Information', value: 'descript' },
  
  { key: '65', text: 'Target - Cust. Ref.', value: 'custRef' },
  { key: '66', text: 'Target - Contract Ref.', value: 'contractRef' },
  { key: '67', text: 'Target - Status', value: 'custStatus' },
  { key: '68', text: 'Target - Gender', value: 'gender' },
  { key: '69', text: 'Target - Birth Date', value: 'birthDate' },
  { key: '70', text: 'Target - Marital.', value: 'marital' },
  { key: '71', text: 'Target - Children', value: 'children' },
  { key: '72', text: 'Target - Address', value: 'address' },
  { key: '73', text: 'Target - City', value: 'city' },
  { key: '74', text: 'Target - Province', value: 'province' },
  { key: '75', text: 'Target - Country', value: 'country' },
  { key: '76', text: 'Target - Zipcode', value: 'zipcode' },

  { key: '77', text: 'Target - Occupation', value: 'occupation' },
  { key: '78', text: 'Target - Job Title', value: 'jobTitle' },
  { key: '79', text: 'Target - Department', value: 'jobDepartment' },
  { key: '80', text: 'Target - Company', value: 'jobCompany' },
  { key: '81', text: 'Target - Home Ownership', value: 'homestay' },
  { key: '82', text: 'Target - Vehicle Ownership', value: 'vehicle' },
  { key: '83', text: 'Target - Revenue', value: 'revenue' },
  { key: '84', text: 'Target - Expense', value: 'expense' },
  { key: '85', text: 'Target - Credit Card', value: 'creditcard' },
]
export const popupMerchant= [
  { key: '90', text: 'Merchant - Code', value: 'merchantCode' },
  { key: '91', text: 'Merchant - Name', value: 'merchantName' },
  { key: '92', text: 'Merchant - Warehouse', value: 'merchantWarehouse' },
  { key: '93', text: 'Merchant - Acquisition', value: 'merchantAcquisition' },
  { key: '94', text: 'Merchant - Prev. AOV', value: 'previousAov' },
  { key: '95', text: 'Merchant - Prev. Freq. Order', value: 'previousFreqOrder' },
  { key: '96', text: 'Merchant - Curr. AOV', value: 'currentAov' },
  { key: '97', text: 'Merchant - Curr. Freq. Order', value: 'currentFreqOrder' },

  { key: '98', text: 'Merchant - Limit Maximum', value: 'limitMaximum' },
  { key: '99', text: 'Merchant - Limit Remaining', value: 'limitRemaining' },
  { key: '101', text: 'Merchant - Prev. Month Trx.', value: 'previousMonthTrx' },
  { key: '102', text: 'Merchant - Curr. Month Trx.', value: 'currentMonthTrx' },
  
  // { key: '103', text: 'Merchant - Retention GMV Status', value: 'retentionGmvStatus' },
  // { key: '104', text: 'Merchant - Retention Order Status', value: 'retentionOrderStatus' },
  // { key: '105', text: 'Merchant - Retention App Status', value: 'retentionAppStatus' },
  // { key: '106', text: 'Merchant - Retention Physical Segment', value: 'retentionPhysicalSegment' },
  // { key: '108', text: 'Merchant - Retention Journey', value: 'retentionJourney' },
  // { key: '109', text: 'Merchant - Retention Access', value: 'retentionAccess' },
  // { key: '110', text: 'Merchant - Retention Segment', value: 'retentionSegment' },
  // { key: '111', text: 'Merchant - Retention Count Trx.', value: 'retentionCountTrx' },
  // { key: '112', text: 'Merchant - Retention GMV Accumulated', value: 'retentionGmvAccumulated' },
  // { key: '113', text: 'Merchant - Retention Trx. Ungroup', value: 'retentionCountTrxUngroup' },
  // { key: '114', text: 'Merchant - Retention Uninstall Status', value: 'retentionUninstallStatus' },
  // { key: '115', text: 'Merchant - Retention App Engine', value: 'retentionAppEngine' },
  // { key: '116', text: 'Merchant - Retention Outbound Call', value: 'retentionOutboundCall' },
  // { key: '117', text: 'Merchant - Retention RFM', value: 'retentionRfm' },
  // { key: '118', text: 'Merchant - Retention Churn Progress', value: 'retentionChurnProgress' },
]
export const taggerOptions = [ 
  { key: '0', value: 'W1', text: 'W1' },
  { key: '1', value: 'W2', text: 'W2' },
  { key: '2', value: 'W3', text: 'W3' },
  { key: '3', value: 'W4', text: 'W4' },
  { key: '4', value: 'W5', text: 'W5' },
  { key: '5', value: 'W6', text: 'W6' },
  { key: '6', value: 'W7', text: 'W7' },
  { key: '7', value: 'W8', text: 'W8' },
  { key: '8', value: 'W9', text: 'W9' },
  { key: '9', value: 'G1', text: 'G1' },
  { key: '10', value: 'G2', text: 'G2' },
  { key: '11', value: 'G3', text: 'G3' },
  { key: '12', value: 'G4', text: 'G4' },
  { key: '13', value: 'G5', text: 'G5' },
  { key: '14', value: 'G6', text: 'G6' },
  { key: '15', value: 'G7', text: 'G7' },
  { key: '16', value: 'G8', text: 'G8' },
  { key: '17', value: 'G9', text: 'G9' },
]
export const limitOptions = [ 
  { key: '0', value: 300, text: '5 Minutes' },
  { key: '1', value: 600, text: '10 Minutes' },
  { key: '2', value: 900, text: '15 Minutes' },
  { key: '3', value: 1200, text: '20 Minutes' },
  { key: '4', value: 1300, text: '25 Minutes' },
  { key: '5', value: 3000, text: '30 Minutes' },
]

export const sequenceOptions = [ 
  { key: '0', value: 'priority', text: 'Priority' },
  { key: '1', value: 'totAttempt', text: 'Attempt' },
  { key: '2', value: 'progress', text: 'Progress' },
  { key: '3', value: 'contractRef', text: 'Contract' },
]
export const dispatchOptions= (v)=>{
  if (v=='campaign') {
    return(
      [ 
        { key: '0', value: 'Locking', text: 'Locking' },
        { key: '1', value: 'Floating', text: 'Floating' },
      ]
    )
  } else if (v=='inhouse') {
    return(
      [ 
        { key: '0', value: 'NPL', text: 'Non Performing Loan' },
        { key: '1', value: 'ARM', text: 'Asset Recovery' },
      ]
    )
  } else { //agent
    return(
      [ 
        { key: '0', value: 'idle', text: 'Idle Time' },
        { key: '1', value: 'call', text: 'Call Quantitity' },
        { key: '2', value: 'time', text: 'Time Occupation' },
      ]
    )
  }
}

export const reportOptions= [
  { key: '0', value: '0', text: 'Progress' },
  { key: '1', value: '1', text: 'OK' },
  { key: '2', value: '-1', text: 'NOK' },
]
export const dialingOptions= [
  { key: '0', value: 'Preview', text: 'Preview Dialing' },
  { key: '1', value: 'Predictive', text: 'Predictive Dialing' },
]
export const voiceOptions= (v)=>{
  if (v=='recording') {
    return([
      { key: '0', value: 'mono', text: 'Stereo - Split' },
      { key: '1', value: 'stereo', text: 'Stereo - Blended' },
    ])
  } else {
    return([
      { key: '0', value: '0', text: 'Dialing' },
      { key: '1', value: '1', text: 'Answered' },
      { key: '2', value: '-1', text: '! Ignored' },
      { key: '3', value: '-2', text: '! Rejected' },
      { key: '4', value: '-3', text: '! Invalid' },
      { key: '5', value: '-4', text: '! Voice Mailbox' },
      { key: '6', value: '-9', text: '! Other Error' },
    ])
  }
}
export const conversationOptions=(v)=>{
  return([
    { key: '0', value: 'Missed', text: 'Missed' },
    { key: '1', value: 'Responsed', text: 'Responsed' },
    { key: '2', value: 'Automated', text: 'Automated / AI' },
  ])
}
export const voiceStatus= {
  '0': 'Dialing',
  '1': 'Answered',
  '-1': '! Ignored',
  '-2': '! Rejected',
  '-3': '! Invalid',
  '-4': '! Voice Mailbox',
  '-9': '! Other Error',
}
export const targetStatus= {
  '0': 'Prepared',
  '1': 'Delivered',
  '-1': '! Error Messaging / Ignored Call',
  '-2': '! Rejected',
  '-3': '! Invalid',
  '-4': '! Voice Mailbox',
  '-9': '! Other Error',
}
export const messagingOptions=(v)=>{
  if (v=='Whatsapp') {
    return(
      [
        { key: '0', value: 'sent', text: 'Sent' },
        { key: '1', value: 'delivered', text: 'Delivered' },
        { key: '2', value: 'read', text: 'Read' },
        { key: '3', value: 'clicked', text: 'Clicked' },
        <Divider style={{margin: '0 0'}} key='x' />,
        { key: '4', value: 'open', text: 'Open' },
        { key: '5', value: 'progress', text: 'On Progress' },
        { key: '6', value: 'closed', text: 'Closed' },
        <Divider style={{margin: '0 0'}} key='y' />,
        { key: '7', value: 'error', text: '! Error' },
      ]
    )
  } else {
    return(
      [
        { key: '0', value: 'sent', text: 'Sent' },
        { key: '1', value: 'delivered', text: 'Delivered' },
        { key: '2', value: 'read', text: 'Read' },
        { key: '3', value: 'clicked', text: 'Clicked' },
        <Divider style={{margin: '0 0'}} key='x' />,
        { key: '4', value: 'error', text: '! Error' },
      ]
    )
  }
}
export const messagingStatus= {
  '0': 'Progress',
  '1': 'Succeed',
  '-1': '! Error',
}
export const socialOptions= [
  { key: '0', value: '0', text: 'Progress' },
  { key: '1', value: '1', text: 'Delivered' },
  { key: '2', value: '-1', text: '! Error' },
]
export const socialStatus= {
  '0': 'Progress',
  '1': 'Delivered',
  '-1': '! Error',
}
export const statusOptions= [
  // { key: '2', value: '-3', text: 'Invalid', icon: <Icon color='red' name='square full' style={{}} /> },
  { key: '0', value: '0', text: 'Progress', icon: <Icon color='green' name='square full' style={{}} /> },
  { key: '1', value: '1', text: 'Delivered', icon: <Icon color='blue' name='square full' style={{}} /> },
  { key: '3', value: '-1', text: 'Undelivered', icon: <Icon color='orange' name='square full' style={{}} /> },
]
export const responsedOptions= [
  { key: '0', value: 'Agent', text: 'Agent' },
  { key: '1', value: 'Machine', text: 'Machine' },
]
export const timeOptions= [
  { key: '0', value: 'Pagi', text: 'Pagi, 08:00 - 12:00' },
  { key: '1', value: 'Siang', text: 'Siang, 12:00 - 15:00' },
  { key: '2', value: 'Sore', text: 'Sore, 15:00-18:00' },
  { key: '3', value: 'Malam', text: 'Malam, 18:00-20:00' },
]
export const reasonOptions=(v)=> {
  return([
    { key: '0', icon: 'warning', value: 'Pengajuan Keringanan', text: 'Pengajuan Keringanan' },
    { key: '1', icon: 'warning', value: 'Lupa Tgl Pembayaran', text: 'Lupa Tgl Pembayaran' },
    { key: '2', icon: 'warning', value: 'Jobless / Bangkrut', text: 'Jobless / Bangkrut' },
    { key: '3', icon: 'warning', value: 'Pembayaran Gagal', text: 'Pembayaran Gagal' },
    { key: '4', icon: 'warning', value: 'Sakit Kronis', text: 'Sakit Kronis' },
    { key: '5', icon: 'warning', value: 'Bencana Alam', text: 'Bencana Alam' },
    { key: '6', icon: 'warning', value: 'Tidak Bisa Buka App', text: 'Tidak Bisa Buka App' },
    { key: '7', icon: 'warning', value: 'Tidak Mau Membayar', text: 'Tidak Mau Membayar' },
    { key: '8', icon: 'warning', value: 'Beda Status Kerja / Tgl Gajian', text: 'Beda Status Kerja / Tgl Gajian' },
    { key: '9', icon: 'warning', value: 'Ada Kebutuhan Mendesak', text: 'Ada Kebutuhan Mendesak' },
    { key: '10', icon: 'warning', value: 'Penghasilan / Bisnis Menurun', text: 'Penghasilan / Bisnis Menurun' },
    { key: '11', icon: 'warning', value: 'Banyak Cicilan / Pinjaman', text: 'Banyak Cicilan / Pinjaman' },
    { key: '12', icon: 'warning', value: 'Peminjam/Orang Terdekat terkana musibah / kecelekaan', text: 'Peminjam/Orang Terdekat terkana musibah / kecelekaan' },
    { key: '13', icon: 'warning', value: 'Lainnya', text: 'Lainnya' },
  ])
}
export const actionOptions=(v)=> {
  const globals=[ //CALL RESULT
    
  ]
  const tickets=[ //TICKET
    { key: '15', icon: 'check', value: 'Dispatch', text: 'Dispatch' },
    { key: '16', icon: 'check', value: 'Dispose', text: 'Forward / Dispose' },
    { key: '17', icon: 'check', value: 'Escalate', text: 'Escalate' },
    <Divider style={{margin: '0 0'}} key={'x'} />,
    { key: '18', icon: 'fire extinguisher', value: 'Handling', text: 'Handling' },
    { key: '19', icon: 'paper plane outline', value: 'Response', text: 'Response to Customer' },

  ]
  const arm=[ //INHOUSE COLLECTION
    { key: '25', icon: 'map marker alternate', value: 'Home Visit', text: 'Home Visit' },
    { key: '26', icon: 'map marker alternate', value: 'Office Visit', text: 'Office Visit' },
    { key: '27', icon: 'map marker alternate', value: 'Relative Visit', text: 'Relative Visit' },
    <Divider style={{margin: '0 0'}} key={'z'} />,
    { key: '29', icon: 'fax', value: 'Calling', text: 'Calling' },
    { key: '28', icon: 'comments', value: 'Texting', text: 'Texting' },
    { key: '29', icon: 'envelope outline', value: 'Emailing', text: 'Emailing' },
    <Divider style={{margin: '0 0'}} key={'y'} />,
    { key: '30', icon: 'edit outline', value: 'Data Updating', text: 'Data Updating' },

  ]
  if (v=='ticket') {
    return(globals.concat(tickets))
  } else if (v=='arm') {
    return(globals.concat(arm))
  } else {
    return(globals)
  }
}
export const resultOptions=(mode)=> {
  const globals=[ //CALL RESULT
    { key: '0', icon: 'close', value: 'Failed', text: 'Failed' },
    { key: '1', icon: 'close', value: 'Invalid', text: 'Invalid' },
    { key: '2', icon: 'close', value: 'Mailbox', text: 'Mailbox' },
    { key: '3', icon: 'close', value: 'Inactive', text: 'Inactive' },
    { key: '4', icon: 'close', value: 'Rejected', text: 'Rejected' },
    { key: '5', icon: 'close', value: 'Hangup', text: 'Hangup' },
    { key: '6', icon: 'close', value: 'No Response', text: 'No Response' },
    { key: '7', icon: 'close', value: 'Unconcerned', text: 'Unconcerned' },
    { key: '8', icon: 'close', value: 'No Contact', text: 'No Contact' },
    { key: '9', icon: 'close', value: 'Canceled', text: 'Canceled' },
    { key: '10', icon: 'check', value: 'Rescheduled', text: 'Rescheduled' },
    { key: '11', icon: 'check', value: 'Negotiation', text: 'Negotiation' },
    { key: '12', icon: 'check', value: 'Recalculated', text: 'Recalculated' },
    { key: '13', icon: 'check', value: 'Leave Message', text: 'Leave Message' },
  ]
  const collections=[ //COLLECTION
    { key: '15', icon: 'check', value: 'Promised', text: 'Promised' },
    { key: '16', icon: 'check', value: 'Paid', text: 'Paid' },
    { key: '17', icon: 'check', value: 'Stop Collection', text: 'Stop Collection' },

    { key: '18', icon: 'check', value: 'RPC - REGULAR', text: 'RPC - REGULAR' },
    { key: '19', icon: 'check', value: 'RPC - PTP', text: 'RPC - PTP' },
    { key: '20', icon: 'check', value: 'RPC - HTP', text: 'RPC - HTP' },
    { key: '21', icon: 'check', value: 'RPC - BROKEN PROMISE', text: 'RPC - BROKEN PROMISE' },
    { key: '22', icon: 'check', value: 'RPC - CALLBACK', text: 'RPC - CALLBACK' },
    { key: '23', icon: 'check', value: 'WPC - REGULER', text: 'WPC - REGULER' },
    { key: '24', icon: 'check', value: 'WPC - LEFT MESSAGE', text: 'WPC - LEFT MESSAGE' },
  ]
  const restructurings=[ //RESTRUCTURING / REMEDIAL
    { key: '20', icon: 'check', value: 'Restructured', text: 'Restructured' },
  ]
  const telesales= [ //TELESALE
    { key: '31', icon: 'check', value: 'Interested', text: 'Interested' },
    { key: '32', icon: 'check', value: 'Presentation', text: 'Presentation' },
    { key: '33', icon: 'check', value: 'Subscribed', text: 'Subscribed' },
    { key: '34', icon: 'check', value: 'Purchased', text: 'Purchased' },
    { key: '35', icon: 'check', value: 'Ordered', text: 'Ordered' },

    { key: '36', icon: 'dont', value: 'Got a loan from another channel', text: 'Got a loan from another channel' },
    { key: '37', icon: 'dont', value: 'Applied before the call', text: 'Applied before the call' },
    { key: '38', icon: 'dont', value: 'Applied but rejected by system', text: 'Applied but rejected by system' },
    { key: '39', icon: 'dont', value: 'Refuse without specific reason', text: 'Refuse without specific reason' },
    // { key: '40', icon: 'dont', value: 'No need for a loan = unconcerned', text: 'No need for a loan = unconcerned' },
    { key: '41', icon: 'dont', value: 'Interest rate is too high', text: 'Interest rate is too high' },
    { key: '42', icon: 'dont', value: 'Disbursement deduction is too much', text: 'Disbursement deduction is too much' },
    { key: '43', icon: 'dont', value: 'Not eligible', text: 'Not eligible' },
    { key: '44', icon: 'dont', value: 'Number belongs to someone else', text: 'Number belongs to someone else' },
    { key: '45', icon: 'dont', value: 'Credit limit is too small', text: 'Credit limit is too small' },
    { key: '46', icon: 'dont', value: 'Tenor doesnt suit the needs', text: 'Tenor doesnt suit the needs' },
    { key: '47', icon: 'dont', value: 'Refuse marketing', text: 'Refuse marketing' },
    { key: '48', icon: 'dont', value: 'Refuse online loan platform', text: 'Refuse online loan platform' },
    { key: '49', icon: 'dont', value: 'Call ended by customer', text: 'Call ended by customer' },
    // { key: '50', icon: 'dont', value: 'No response', text: 'No response' },
    { key: '51', icon: 'dont', value: 'Will consider later', text: 'Will consider later' },
    { key: '52', icon: 'dont', value: 'Busy at the moment', text: 'Busy at the moment' },
    { key: '53', icon: 'dont', value: 'Call picked up by someone else', text: 'Call picked up by someone else' },
  ]
  const verification=[ //VERIFICATION
    { key: '440', icon: 'check', value: 'Verified', text: 'Verified' },
    { key: '441', icon: 'check', value: 'Confirmed', text: 'Confirmed' },
    { key: '442', icon: 'check', value: 'Resolved', text: 'Resolved' },
  ]

  const visitor=[ //VISITOR
    { key: '80', icon: 'check', value: 'Open Lead', text: 'Open Lead' },
    { key: '81', icon: 'check', value: 'Open Prospect', text: 'Open Prospect' },
    { key: '82', icon: 'check', value: 'Meetup Appointment', text: 'Meetup Appointment' },
    { key: '83', icon: 'check', value: 'Service Information', text: 'Service Information' },
  ]

  const customer=[ //CUSTOMER
    { key: '90', icon: 'check', value: 'Customer Data', text: 'Update Information' },
    { key: '91', icon: 'check', value: 'Customer Survey', text: 'Survey / Insight' },
    { key: '92', icon: 'check', value: 'Customer Complain', text: 'Service Complains' },
    { key: '93', icon: 'check', value: 'Meetup Appointment', text: 'Meetup Appointment' },
  ]

  const prospect=[ //PROSPECT
    { key: '20', icon: 'check', value: 'Lead', text: 'Lead' },
    { key: '21', icon: 'check', value: 'Prospect', text: 'Prospect' },
    { key: '22', icon: 'check', value: 'Sales Opportunity', text: 'Sales Opportunity' },
  ]
  const closing=[ //CLOSING
    { key: '30', icon: 'close', value: 'Canceled', text: 'Canceled' },
    { key: '31', icon: 'calendar alternate outline', value: 'Rescheduled', text: 'Rescheduled / Pending' },
    { key: '32', icon: 'handshake outline', value: 'Resolved', text: 'Resolved' },
    { key: '33', icon: 'close', value: 'Failed', text: 'Failed' },
  ]

  const ticket= [ //TICKET REMARK
    { key: '0', value: 'Open', text: 'Open' },
    { key: '1', value: 'Re-Open', text: 'Re-Open' },
    <Divider style={{margin: '0 0'}} key='x1' />,
    { key: '2', value: 'Assessed', text: 'Assessed' },
    { key: '3', value: 'Investigated', text: 'Investigated' },
    { key: '4', value: 'Resolved', text: 'Resolved' },
    { key: '5', value: 'Unresolved', text: 'Unresolved' },
    <Divider style={{margin: '0 0'}} key='x2' />,
    { key: '6', value: 'Confirmed', text: 'Confirmed' },
    { key: '7', value: 'Technical Closed', text: 'Technical Closed' },
    { key: '8', value: 'Customer Closed', text: 'Customer Closed' },
    { key: '9', value: 'Closed', text: 'Closed' },
  ]

  const handling= [ //TICKET HANDLING
    { key: '1', value: 'Investigate', text: 'Investigate' },
    { key: '2', value: 'Progress', text: 'Progress' },
    { key: '3', value: 'Resolve', text: 'Resolve' },
    { key: '4', value: 'Unresolve', text: 'Unresolve' },
    
    { key: '5', value: 'Dispatch', text: 'Dispatch' },
    { key: '6', value: 'Disposition', text: 'Disposition' },
    { key: '7', value: 'Escalation', text: 'Escalation' },
  ]
  if (mode=='visitor') {
    return(visitor)
  } else if (mode=='customer') {
    return(customer)
  } else if (mode=='collection') {
    return(globals.concat(collections))
  } else if (mode=='restructuring') {
    return(globals.concat(restructurings))
  } else if (mode=='telesale') {
    return(globals.concat(telesales))
  } else if (mode=='verification') {
    return(globals.concat(verification))
  } else if (mode=='all') {
    return(globals.concat(collections).concat(restructurings).concat(telesales).concat(verification))
  
  } else if (mode=='prospect') {
    return(prospect)
  } else if (mode=='closing') {
    return(closing)
  } else if (mode=='ticket') {
    return(ticket)
  } else if (mode=='handling') {
    return(handling)
  } else {
    return(globals)
  }
}

//HELPDESK / TICKET MANAGEMENT
export const calendarOptions= [
  { key: '0', value: '8', text: '8/17 Office Hours' },
  { key: '1', value: '5', text: '24/5 Weekdays' },
  { key: '2', value: '7', text: '24/7 Calendars' },
]
export const uptimeOptions= [
  { key: '1', value: '90', text: '90% Uptime' },
  { key: '2', value: '95', text: '95% Uptime' },
  { key: '3', value: '99', text: '99% Uptime' },
  { key: '4', value: '99.5', text: '99.5% Uptime' },
  { key: '5', value: '99.9', text: '99.9% Uptime' },
  { key: '6', value: '99.99', text: '99.99% Uptime' },
]
export const responseOptions= [
  { key: '1', value: '15', text: '15 Minutes' },
  { key: '2', value: '30', text: '30 Minutes' },
  { key: '3', value: '60', text: '60 Minutes' },
]
export const resolveOptions= [
  { key: '1', value: '60', text: '1 Hour' },
  { key: '2', value: '120', text: '2 Hours' },
  { key: '3', value: '180', text: '3 Hours' },
  { key: '4', value: '360', text: '6 Hours' },
  { key: '5', value: '480', text: '1 Working Day' },
  { key: '6', value: '960', text: '2 Working Day' },
]
export const ticketOptions= [
  { key: '1', value: 'Opened', text: 'Opened' },
  { key: '2', value: 'Progress', text: 'Progress' },
  { key: '3', value: 'Resolve', text: 'Resolve' },
  { key: '4', value: 'Unresolve', text: 'Unresolve' },
  { key: '5', value: 'Closed', text: 'Closed' },
  // { key: '5', value: 'Dispatched', text: 'Dispatched' },
  // { key: '6', value: 'Dispositioned', text: 'Dispositioned' },
  // { key: '7', value: 'Escalated', text: 'Escalated' },
]
export const progressOptions= [
  { key: '1', value: '20', text: '20%' },
  { key: '2', value: '30', text: '30%' },
  { key: '3', value: '45', text: '45%' },
  { key: '4', value: '50', text: '50%' },
  { key: '5', value: '65', text: '65%' },
  { key: '6', value: '70', text: '70%' },
  { key: '7', value: '75', text: '75%' },
  { key: '8', value: '80', text: '80%' },
  { key: '9', value: '90', text: '90%' },
  { key: '10', value: '100', text: '100 %' },
]
export const paramOptions = [
  {
    key: '1',
    value: 'Y/N',
    text: 'Yes / No',
  },
  {
    key: '2',
    value: 'Scoring',
    text: 'Scoring',
  },
  {
    key: '3',
    value: 'Value',
    text: 'Value / Free Text',
  },
]

export const privilegeOptions=(v)=>{
  if (v=='so_administration') {
    return(
      [
        { key: '0', text: 'Audit Trail', value: 'Audit Trail' },
        { key: '1', text: 'User Account', value: 'User Account' },
        { key: '2', text: 'Voip Account', value: 'Voip Account' },
        { key: '3', text: 'Voip Network', value: 'Voip Network' },
        <Divider style={{margin: '0 0'}} key='x1' />,
        { key: '4', text: 'Service Rate', value: 'Service Rate' },
        { key: '5', text: 'Setting/Configuration', value: 'Setting/Configuration' },
        <Divider style={{margin: '0 0'}} key='x2' />,
        { key: '6', text: 'Scheduler', value: 'Scheduler' },
        { key: '7', text: 'Watchdog', value: 'Watchdog' },
        { key: '8', text: 'Disaster Recovery', value: 'Disaster Recovery' },
        { key: '9', text: 'Error/Bug Control', value: 'Error/Bug Control' },
      ]
    )
  } else if (v=='so_repository') {
    return(
      [
        { key: '0', text: 'Workflow', value: 'Workflow' },
        <Divider style={{margin: '0 0'}} key='x1' />,
        { key: '1', text: 'Api Logger', value: 'Api Logger' },
        { key: '2', text: 'Backend Node', value: 'Backend Node' },
      ]
    )
  } else if (v=='so_collection') {
    return(
      [
        { key: '0', text: 'Monitoring', value: 'Monitoring' },
        <Divider style={{margin: '0 0'}} key='x1' />,
        { key: '1', text: 'Customer', value: 'Customer' },
        <Divider style={{margin: '0 0'}} key='x2' />,
        { key: '2', text: 'Bucket', value: 'Bucket' },
        { key: '3', text: 'Allocation', value: 'Allocation' },
        { key: '4', text: 'Collector', value: 'Collector' },
        <Divider style={{margin: '0 0'}} key='x3' />,
        { key: '5', text: 'Performance', value: 'Performance' },
        { key: '6', text: 'Commission', value: 'Commission' },
        <Divider style={{margin: '0 0'}} key='x4' />,
        { key: '7', text: 'In-House', value: 'In-House' },
      ]
    )

  } else if (v=='so_conversation') {
    return(
      [
        { key: '0', text: 'Statistic', value: 'Statistic' },
        { key: '1', text: 'Performance', value: 'Performance' },
        { key: '2', text: 'Reporting', value: 'Reporting' },
        <Divider style={{margin: '0 0'}} key='x1' />,
        { key: '3', text: 'Telephony', value: 'Telephony' },
        { key: '4', text: 'Messaging', value: 'Messaging' },
        { key: '5', text: 'Social Media', value: 'Social Media' },
        <Divider style={{margin: '0 0'}} key='x2' />,
        { key: '6', text: 'Collection', value: 'Collection' },
        { key: '7', text: 'Restructuring', value: 'Restructuring' },
        { key: '8', text: 'Telesales', value: 'Telesales' },
        { key: '9', text: 'Verification', value: 'Verification' },
        <Divider style={{margin: '0 0'}} key='x3' />,
        { key: '10', text: 'Campaign', value: 'Campaign' },
        { key: '11', text: 'Audience', value: 'Audience' },
        { key: '12', text: 'Assignment', value: 'Assignment' },
      ]
    )
  } else if (v=='so_dashboard') {
    return(
      [
        { key: '0', text: 'Customer Statistic', value: 'Customer Statistic' },
        <Divider style={{margin: '0 0'}} key='x1' />,
        { key: '1', text: 'Bucket Performance', value: 'Bucket Performance' },
        { key: '2', text: 'Campaign Performance', value: 'Campaign Performance' },
        <Divider style={{margin: '0 0'}} key='x2' />,
        { key: '3', text: 'Agent Performance', value: 'Agent Performance' },
        { key: '4', text: 'Workgroup Performance', value: 'Workgroup Performance' },
        <Divider style={{margin: '0 0'}} key='x3' />,
        { key: '5', text: 'Collection Process Reporting', value: 'Collection Process Reporting' },
        
      ]
    )
  } else if (v=='access') {
    return(
      [
        { key: '0', text: 'Blocked', value: 'Blocked' },
        { key: '1', text: 'Allowed', value: 'Allowed' },
      ]
    )
  } else if (v=='action') {
    return(
      [
        { key: '0', text: 'Returned', value: 'Returned' },
        { key: '1', text: 'Canceled', value: 'Canceled' },
        { key: '2', text: 'Terminated', value: 'Terminated' },
      ]
    )
  } else if (v=='timer') {
    return(
      [
        { key: '0', text: '1', value: '1' },
        { key: '1', text: '2', value: '2' },
        { key: '2', text: '3', value: '3' },
        { key: '3', text: '4', value: '4' },
        { key: '4', text: '5', value: '5' },
      ]
    )
  } else {
    return(
      [
        { key: '0', text: 'Desk Collection', value: 'Desk Collection' },
        { key: '1', text: 'In-House Collection', value: 'In-House Collection' },
      ]
    )
  }
}
export const bucketOptions=(v)=>{
  return(
    [
      { key: '0', text: 'Early Bucket', value: 'Early Bucket' },
      { key: '1', text: 'Daily Bucket', value: 'Daily Bucket' },
      { key: '2', text: 'XDays Bucket', value: 'XDays Bucket' },
    ]
  )
}
export const billOptions=(v)=>{
  return(
    [
      { key: '0', text: 'Bill', value: 'Bill / Invoice' },
      { key: '1', text: 'Pay Later', value: 'Pay Later' },
      { key: '2', text: 'Loan', value: 'Loan' },
      { key: '3', text: 'Financing', value: 'Financing' },
    ]
  )
}
export const recurringOptions=(v)=>{
  return(
    [
      { key: '0', text: 'Daily', value: 'Daily' },
      { key: '1', text: 'Weekly', value: 'Weekly' },
      { key: '2', text: 'Monthly', value: 'Monthly' },
      <Divider style={{margin: '0 0'}} key='x1' />,
      { key: '3', text: 'Extend 3 Days', value: 'Extend 3 Days' },
      { key: '4', text: 'Extend 7 Days', value: 'Extend 7 Days' },
      { key: '5', text: 'Extend 10 Days', value: 'Extend 10 Days' },
    ]
  )
}
export const opsOptions=(v)=>{
  return(
    [
      { key: '0', text: '=', value: 'Equal To' },
      { key: '1', text: '>=', value: 'Bigger or Equal Than' },
      { key: '2', text: '<=', value: 'Less or Equal Than' },
    ]
  )
}
export const allocationOptions=(v)=>{
  return(
    [
      { key: '0', text: 'Manual', value: 'Manual' },
      { key: '1', text: 'Fixed (Tagger/Workgroup)', value: 'Fixed' },
      { key: '2', text: 'Dynamic (as Promised/Paid)', value: 'Dynamic' },
    ]
  )
}

export const passwordOptions=(v)=>{
  if (v=='pattern') {
    return(
      [
        { key: '0', text: 'Simple Passoword', value: 'Simple' },
        { key: '1', text: 'Moderate Password', value: 'Moderate' },
        { key: '2', text: 'Complicated Password', value: 'Complicated' },
      ]
    )
  } else if (v=='initiation') {
    return(
      [
        { key: '0', text: 'No Action', value: 'No Action' },
        { key: '1', text: 'Change Password', value: 'Change Password' },
      ]
    )
  } else if (v=='reusability') {
    return(
      [
        { key: '0', text: 'Always', value: 0 },
        { key: '1', text: 'Next 5 Changes', value: 5 },
        { key: '2', text: 'Never Reused', value: '-1' },
      ]
    )
  } else {
    return([
    ])
  }
}

export const accountOptions=(v)=>{
  if (v=='dormant') {
    return([ 
      { key: '1', value: 'Suspend Account', text: 'Suspend Account' },
    ])
  } else if (v=='inactive') {
    return([ 
      { key: '1', value: 'Delete Account', text: 'Delete Account' },
    ])
  } else if (v=='retry') {
    return([ 
      { key: '1', value: 'Suspend Account', text: 'Suspend Account' },
    ])
  } else if (v=='affirmation') {
    return([ 
      { key: '1', value: 'Show Dialog', text: 'Show Dialog' },
      { key: '2', value: 'Do Nothing', text: 'Do Nothing' },
    ])
  } else if (v=='repository') {
    return([ 
      { key: '1', value: 'Local Database', text: 'Local Database' },
      { key: '2', value: 'Active Directory', text: 'Active Directory' },
    ])
  } else {
    return([ 
      { key: '1', value: 'available', text: 'Available' },
      { key: '2', value: 'disabled', text: 'Disabled' },
      { key: '3', value: 'suspended', text: 'Suspended' },
      { key: '4', value: 'deleted', text: 'Deleted' },
    ])
  }
}
export const processOptions=(v)=>{
  if (v=='checkpoint') {
    return([ 
      { key: '0', value: 'Restart At Beginning', text: 'Restart At Beginning' },
      { key: '1', value: 'Restart At Checkpoint', text: 'Restart At Checkpoint' },
    ])
  } else if (v=='service') {
    return([ 
      { key: '0', value: 'File Loader', text: 'File Loader' },
      { key: '1', value: 'Hot Billing', text: 'Hot Billing' },
      <Divider style={{margin: '0 0'}} key='x1' />,
      { key: '2', value: 'Credential Renewal', text: 'Credential Renewal' },
      { key: '3', value: 'Dashboard Statistic', text: 'Dashboard Statistic' },
      <Divider style={{margin: '0 0'}} key='x2' />,
      { key: '4', value: 'Voice Dialer', text: 'Voice Dialer' },
      { key: '5', value: 'Voice Cleansing', text: 'Voice Cleansing' },
      { key: '6', value: 'Voice Dispatching', text: 'Voice Dispatching' },
    ])
  } else {
    return([])
  }
}
export const backupOptions=(v)=>{
  if (v=='media') {
    return([
      { key: '0', value: 'Tape', text: 'Tape' },
      { key: '1', value: 'Network Storage', text: 'Network Storage' },
      { key: '2', value: 'Cloud Storage', text: 'Cloud Storage' },
    ])
  } else if (v=='object') {
    return([
      { key: '0', value: 'Data Schema', text: 'Data Schema' },
      { key: '1', value: 'Master Data', text: 'Master Data' },
      { key: '2', value: 'Transaction Data', text: 'Transaction Data' },
      <Divider style={{margin: '0 0'}} key='x1' />,
      { key: '3', value: 'Media/Asset Files', text: 'Media/Asset Files' },
    ])
  } else if (v=='frequency') {
    return([
      { key: '0', value: 'Daily', text: 'Daily' },
      { key: '1', value: 'Weekly', text: 'Weekly' },
      { key: '2', value: 'Monthly', text: 'Monthly' },
    ])
  } else if (v=='availability') {
    return([
      { key: '0', value: 'Manual', text: 'Manual' },
      { key: '1', value: 'Continuous', text: 'Continuous' },
    ])
  } else {
    return([])
  }
}
export const restoreOptions=(v)=>{
  if (v=='media') {
    return([])
  } else if (v=='object') {
    return([])
  } else if (v=='frequency') {
    return([])
  } else if (v=='event') {
    return([])
  } else {
    return([])
  }
}
export const housekeepingOptions=(v)=>{
  if (v=='frequency') {
    return([
      { key: '0', value: 'Daily', text: 'Daily' },
      { key: '1', value: 'Weekly', text: 'Weekly' },
      { key: '2', value: 'Monthly', text: 'Monthly' },
    ])
  } else if (v=='period') {
    return([
      { key: '0', value: '1', text: '1' },
      { key: '1', value: '2', text: '2' },
      { key: '2', value: '3', text: '3' },
      { key: '3', value: '4', text: '4' },
      { key: '4', value: '5', text: '5' },
    ])
  } else if (v=='object') {
    return([
      { key: '0', value: 'Data Schema', text: 'Data Schema' },
      { key: '1', value: 'Master Data', text: 'Master Data' },
      { key: '2', value: 'Transaction Data', text: 'Transaction Data' },
      <Divider style={{margin: '0 0'}} key='x1' />,
      { key: '3', value: 'Media/Asset Files', text: 'Media/Asset Files' },
    ])
  } else {
    return([])
  }
}
export const haOptions=(v)=>{
  if (v=='scheme') {
    return([
      { key: '0', value: 'Single Node', text: 'Single Node' },
      { key: '1', value: 'Active-Active', text: 'Active-Active' },
      { key: '2', value: 'Active-Passive', text: 'Active-Passive' },
    ])
  } else if (v=='object') {
    return([
      { key: '0', value: 'Database', text: 'Database' },
      { key: '1', value: 'Media Server', text: 'Media Server' },
      { key: '2', value: 'Backend Application', text: 'Backend Application' },
      { key: '3', value: 'Frontend Application', text: 'Frontend Application' },
    ])
  } else if (v=='node') {
    return([
      { key: '0', value: 'Primary', text: 'Primary' },
      { key: '1', value: 'Secondary', text: 'Secondary' },
    ])
  } else {
    return([])
  }
}
export const watchdogOptions=(v)=>{
  if (v=='scheme') {
    return([
      { key: '0', value: 'Heartbeat', text: 'Heartbeat' },
      // { key: '1', value: 'Dummy Command', text: 'Dummy Command' },
    ])
  } else if (v=='object') {
    return([
      { key: '0', value: 'Database', text: 'Database' },
      { key: '1', value: 'Media Server', text: 'Media Server' },
      { key: '2', value: 'Backend Application', text: 'Backend Application' },
      { key: '3', value: 'Frontend Application', text: 'Frontend Application' },
    ])
  } else if (v=='frequency') {
    return([
      { key: '0', value: '0', text: 'Continous' },
      { key: '1', value: '1', text: '1' },
      { key: '2', value: '5', text: '5' },
      { key: '3', value: '10', text: '10' },
      { key: '4', value: '15', text: '15' },
      { key: '5', value: '30', text: '30' },
      { key: '6', value: '60', text: '60' },
    ])
  } else {
    return([])
  }
}
export const disasterOptions=(v)=>{
  if (v=='scheme') {
    return([
      { key: '0', value: 'Manual', text: 'Manual' },
      { key: '1', value: 'Continous', text: 'Continous' },
    ])
  } else if (v=='patching') {
    return([
      { key: '0', value: 'Manual', text: 'Manual Patch' },
      { key: '1', value: 'Autonomous', text: 'Autonomous Patch' },
    ])
  } else if (v=='trigger') {
    return([
      { key: '0', value: 'Manual', text: 'Manual Switch' },
      { key: '1', value: 'Continous', text: 'Continous Switch' },
    ])
  } else if (v=='node') {
    return([
      { key: '0', value: 'Production', text: 'Production' },
      { key: '1', value: 'Disaster', text: 'Disaster' },
    ])
  } else {
    return([])
  }
}

export const groupOptions=(v)=>{
  if (v=='ovisone') {
    return(
      [
        { key: '0', text: 'Admin', value: 'Admin' },
        { key: '1', text: 'Client', value: 'Client' }, // Admin Client
        { key: '2', text: 'Collection Mgr', value: 'Collection Mgr' },
        { key: '3', text: 'Conversation Mgr', value: 'Conversation Mgr' },
        { key: '4', text: 'Repository Mgr', value: 'Repository Mgr' },
        { key: '5', text: 'Dashboard Mgr', value: 'Dashboard Mgr' },
        { key: '6', text: 'Business Ops', value: 'Business Ops' },
        { key: '7', text: 'Supervisor', value: 'Supervisor' },
        { key: '8', text: 'Team Leader', value: 'Team Leader' },
        { key: '9', text: 'Desk Agent', value: 'Desk Agent' },
        { key: '10', text: 'Field Agent', value: 'Field Agent' },
      ]
    )
  } else if (v=='halopagi') {
    return(
      [
        { key: '0', text: 'Admin', value: 'Admin' },
        { key: '1', text: 'Client', value: 'Client' }, // Admin Client
        // { key: '2', text: 'Manager', value: 'Manager' },
        // { key: '3', text: 'Quality Control', value: 'Controller' },
        { key: '4', text: 'Supervisor', value: 'Supervisor' },
        { key: '5', text: 'Team Leader', value: 'Team Leader' },
        { key: '6', text: 'Agent', value: 'Agent' },
      ]  
    )
  } else if (v=='realm_halopagi') {
    return(
      [
        // { key: '1', text: 'Client', value: 'Client' },
        // { key: '2', text: 'Manager', value: 'Manager' },
        { key: '3', text: 'Quality Control', value: 'Controller' },
        { key: '4', text: 'Supervisor', value: 'Supervisor' },
        { key: '5', text: 'Team Leader', value: 'Team Leader' },
        { key: '6', text: 'Agent', value: 'Agent' },
      ]  
    )
  }
}

export const widgetOptions=(v)=>{
  if (v=='language') {
    return([
      { key: '1', value: 'ID', text: 'Bahasa' },
      { key: '2', value: 'EN', text: 'English' },
    ])
  } else if (v=='theme') {
    return([
      { key: '1', value: 'dark', text: 'Dark Theme' },
      { key: '2', value: 'light', text: 'Light Theme' },
      // <Divider style={{margin: '0 0'}} key='x1' />,
      // { key: '3', value: 'blue', text: 'Blue Theme' },
      // { key: '4', value: 'red', text: 'Red Theme' },
      // { key: '5', value: 'gold', text: 'Gold  Theme' },
    ])
  } else if (v=='size') {
    return([
      { key: '1', value: 'mini', text: 'Mini' },
      { key: '2', value: 'restore', text: 'Restore' },
    ])
  } else if (v=='width') {
    return([
      { key: '1', value: 275, text: '275' },
      { key: '2', value: 300, text: '300' },
      { key: '3', value: 325, text: '325' },
      { key: '4', value: 350, text: '350' },
    ])
  } else if (v=='position') {
    return([
      { key: '1', value: 'left', text: 'Left' },
      { key: '3', value: 'right', text: 'Right' },
    ])
  }
}

export const loggerOptions=(v)=>{
  if (v=='api') {
    return([
      { key: '0', value: 'API', text: 'API' },
      <Divider style={{margin: '0 0'}} key='x1' />,
      { key: '1', value: 'Webhook', text: 'Webhook' },
    ])
  } else if (v=='scope') {
    return([
      { key: '0', value: 'API', text: 'API' },
      { key: '1', value: 'Backend', text: 'Backend' },
      { key: '2', value: 'Frontend', text: 'Frontend' },
      <Divider style={{margin: '0 0'}} key='x1' />,
      { key: '3', value: 'Webhook', text: 'Webhook' },
      <Divider style={{margin: '0 0'}} key='x2'/>,
      { key: '4', value: 'Service', text: 'Service' },
      { key: '5', value: 'Watchdog', text: 'Watchdog' },
      { key: '6', value: 'Background', text: 'Background' },
      <Divider style={{margin: '0 0'}} key='x3'/>,
      { key: '7', value: 'HA-Agent', text: 'HA-Agent' },
      { key: '8', value: 'DRP-Agent', text: 'DRP-Agent' },
    ])
  } else if (v=='event') {
    return([
      { key: '0', value: 'On-Init', text: 'On-Init' },
      { key: '1', value: 'On-Terminate', text: 'On-Terminate' },
      { key: '2', value: 'On-Connect', text: 'On-Connect' },
      { key: '3', value: 'On-Disconnect', text: 'On-Disconnect' },
      <Divider style={{margin: '0 0'}} key='x1' />,
      { key: '4', value: 'On-SignIn', text: 'On-SignIn' },
      { key: '5', value: 'On-SignOut', text: 'On-SignOut' },
      <Divider style={{margin: '0 0'}} key='x2' />,
      { key: '6', value: 'On-View', text: 'On-View' },
      { key: '7', value: 'On-Create', text: 'On-Create' },
      { key: '8', value: 'On-Update', text: 'On-Update' },
      { key: '9', value: 'On-Delete', text: 'On-Delete' },
      <Divider style={{margin: '0 0'}} key='x3' />,
      { key: '10', value: 'On-Check', text: 'On-Check' },
      { key: '11', value: 'On-Approve', text: 'On-Approve' },
      { key: '12', value: 'On-Timeout', text: 'On-Timeout' },
      { key: '13', value: 'On-Transmit', text: 'On-Transmit' },
      { key: '14', value: 'On-Receive', text: 'On-Receive' },
      <Divider style={{margin: '0 0'}} key='x4' />,
      { key: '15', value: 'On-Request', text: 'On-Request' },
    ])
  } else {
    return([])
  }
}

export const runningOptions=(v)=>{
  if (v=='middleware') {
    return([
      { key: '0', value: 'Live', text: 'Live' },
      // { key: '1', value: 'Sandbox', text: 'Sandbox' },
    ])
  } else {
    return([
      { key: '0', value: 'Debugging', text: 'Debugging' },
      { key: '1', value: 'Production', text: 'Production' },
    ])
  }
}

export const otpOptions=(v)=>{
  if (v=='middleware') {
    return([
      { key: '0', value: 'Live', text: 'Live' },
      { key: '1', value: 'Sandbox', text: 'Sandbox' },
    ])
  } else {
    return([
      { key: '0', value: 'Flash Call', text: 'Flash Call' },
      { key: '1', value: 'Over Voice', text: 'Over Voice' },
    ])
  }
}

export const errorOptions=(v)=>{
  if (v=='level') {
    return([
      { key: '0', value: 'Error', text: 'Error' },
      { key: '1', value: 'Warning', text: 'Warning' },
      { key: '2', value: 'Info', text: 'Info' },
      { key: '3', value: 'Debug', text: 'Debug' },
    ])
  } else if (v=='scope') {
    return([
      { key: '0', value: 'API', text: 'API' },
      { key: '1', value: 'Backend', text: 'Backend' },
      { key: '2', value: 'Frontend', text: 'Frontend' },
      <Divider style={{margin: '0 0'}} key='x1' />,
      { key: '3', value: 'Service', text: 'Service' },
      { key: '4', value: 'Watchdog', text: 'Watchdog' },
      { key: '5', value: 'Background', text: 'Background' },
      <Divider style={{margin: '0 0'}} key='x2' />,
      { key: '6', value: 'HA-Agent', text: 'HA-Agent' },
      { key: '7', value: 'DRP-Agent', text: 'DRP-Agent' },
    ])
  } else if (v=='event') {
    return([
      { key: '0', value: 'On-Init', text: 'On-Init' },
      { key: '1', value: 'On-Terminate', text: 'On-Terminate' },
      { key: '2', value: 'On-Connect', text: 'On-Connect' },
      { key: '3', value: 'On-Disconnect', text: 'On-Disconnect' },
      <Divider style={{margin: '0 0'}} key='x1' />,
      { key: '4', value: 'On-SignIn', text: 'On-SignIn' },
      { key: '5', value: 'On-SignOut', text: 'On-SignOut' },
      <Divider style={{margin: '0 0'}} key='x2' />,
      { key: '6', value: 'On-View', text: 'On-View' },
      { key: '7', value: 'On-Create', text: 'On-Create' },
      { key: '8', value: 'On-Update', text: 'On-Update' },
      { key: '9', value: 'On-Delete', text: 'On-Delete' },
      <Divider style={{margin: '0 0'}} key='x3' />,
      { key: '10', value: 'On-Check', text: 'On-Check' },
      { key: '11', value: 'On-Approve', text: 'On-Approve' },
      { key: '12', value: 'On-Timeout', text: 'On-Timeout' },
      { key: '13', value: 'On-Transmit', text: 'On-Transmit' },
      { key: '14', value: 'On-Receive', text: 'On-Receive' },
    ])
  } else {
    return([])
  }
}

export const scheduleOptions =(v)=>{
  return([ 
    { key: '0', value: 'running', text: 'Running' },
    { key: '1', value: 'upcoming', text: 'Upcoming' },
    { key: '2', value: 'expired', text: 'Expired' },
    { key: '3', value: 'disabled', text: 'Disabled' },
    { key: '4', value: 'completed', text: 'Completed' },
  ])
}
export const schedulerOptions =(v)=>{
  if (v=='service') {
    return([
      { key: '0', text: 'Statistic', value: 'Statistic' },
      { key: '1', text: 'Performance', value: 'Performance' },
      { key: '2', text: 'Reporting', value: 'Reporting' },
    ])
  } else if (v=='type') {
    return([
      { key: '0', value: 'Service', text: 'Service' },
      { key: '1', value: 'Watchdog', text: 'Watchdog' },
      <Divider style={{margin: '0 0'}} key='x1' />,
      { key: '2', value: 'HA-agent', text: 'HA-agent' },
      { key: '3', value: 'DRP-agent', text: 'DRP-agent' },
    ])
  } else if (v=='frequency') {
    return([
      { key: '0', value: 'Continues', text: 'Continues' },
      <Divider style={{margin: '0 0'}} key='x2' />,
      { key: '1', value: 'Daily', text: 'Daily' },
      { key: '2', value: 'Weekly', text: 'Weekly' },
      { key: '3', value: 'Monthly', text: 'Monthly' },
    ])
  } else if ('time') {
    return(
      [
        { key: '0', value: 'Now', text: 'Now' },
        { key: '1', value: '00:00', text: '00:00' },
        { key: '2', value: '01:00', text: '01:00' },
        { key: '3', value: '02:00', text: '02:00' },
        { key: '4', value: '03:00', text: '03:00' },
      ]
    )
  } else {
    return([])
  }
}

export const backendOptions =(v)=>{
  if (v=='type') {
    return([
      { key: '0', value: 'CRM', text: 'CRM' },
      { key: '1', value: 'Mediation', text: 'Mediation' },
      { key: '2', value: 'Core Banking', text: 'Core Banking' },
      { key: '3', value: 'Billing System', text: 'Billing System' },
      <Divider style={{margin: '0 0'}} key='x1' />,
      { key: '4', value: 'Watchdog', text: 'Watchdog' },
    ])
  } else if (v=='frequency') {
    return([
      { key: '0', value: 'Continues', text: 'Continues' },
      <Divider style={{margin: '0 0'}} key='x2' />,
      { key: '1', value: 'Daily', text: 'Daily' },
      { key: '2', value: 'Weekly', text: 'Weekly' },
      { key: '3', value: 'Monthly', text: 'Monthly' },
    ])
  } else if ('time') {
    return(
      [
        { key: '0', value: 'Now', text: 'Now' },
        { key: '1', value: '00:00', text: '00:00' },
        { key: '2', value: '01:00', text: '01:00' },
        { key: '3', value: '02:00', text: '02:00' },
        { key: '4', value: '03:00', text: '03:00' },
      ]
    )
  } else {
    return([])
  }
}

export const factorOptions =(v)=>{
  if (v=='collection_incentive') {
    return([
      { key: '0', value: 'Collected Amount', text: 'Collected Amount' },
    ])
  } else {
    return([])
  }
}

export const modulesOptions =(v)=>{
  if (v=='halopagi') {
    return([
      { key: '0', icon: 'arrow left', value: 'Stream', text: 'Stream Outbound' },
      { key: '1', icon: 'arrow right', value: 'Flow', text: 'Flow Inbound' },
      { key: '2', icon: 'mobile', value: 'Talk', text: 'Talk In-App Call' },
      { key: '3', icon: 'phone volume', value: 'Ring', text: 'Ring Office Phone' },
      { key: '4', icon: 'code', value: 'Middleware', text: 'API / Middleware' },
      { key: '5', icon: 'tv', value: 'Agent Mode', text: 'Agent UX Mode' },
    ])
  } else if (v=='ovisone') {
    return([
      { key: '0', icon: 'calculator', value: 'Collection', text: 'CMS / Collection' },
      { key: '1', icon: 'address card', value: 'Customer', text: 'CRM / Customer' },
      { key: '2', icon: 'ticket', value: 'Ticketing', text: 'TMS / Ticketing' },
      { key: '3', icon: 'window restore', value: 'Knowledge', text: 'KMS / Knowledge' },
    ])
  } else {
    return([])
  }
}

export const conferenceOptions =(v)=>{
  if (v=='talk') {
    return([
      { key: '0', value: 'Push Talk', text: 'Push Talk' },
      { key: '1', value: 'Opt-In Talk', text: 'Opt-In Talk' },
    ])
  } else if (v=='method') {
    return([
      { key: '0', value: 'Two Parties', text: 'Two Parties' },
      // { key: '1', value: 'Multi Parties', text: 'Multi Parties' },
    ])
  } else if (v=='audience') {
    return([
      { key: '0', value: 'Limited', text: 'Limited' },
      { key: '1', value: 'Unlimited', text: 'Unlimited' },
    ])
  } else if (v=='channel') {
    return([
      // { key: '0', value: 'Chat', text: 'Chat' },
      { key: '1', value: 'Audio', text: 'Audio' },
      { key: '2', value: 'Video', text: 'Video' },
    ])
  } else{
    return([])
  }
}

export const linkOptions=(v)=>{
  if (v=='callink') {
    return([
      { key: '0', value: 'Booked', text: 'Booked' },
      { key: '1', value: 'Occupied', text: 'Occupied' },
      { key: '2', value: 'Expired', text: 'Expired' },
    ])
  } else{
    return([])
  }
}

export const contactOptions=(v)=>{
  if (v=='voice') {
    return([
      { key: '0', text: 'Mobile', value: 'mobile' },
      { key: '1', text: 'Home Phone', value: 'homePhone' },
      { key: '2', text: 'Office Phone', value: 'officePhone' },
      { key: '3', text: 'Emergency Phone', value: 'emergencyPhone' },
      { key: '4', text: 'Other Phone', value: 'otherPhone' },
    ])
  } else{
    return([
      { key: '0', text: 'Mobile', value: 'mobile' },
      { key: '1', text: 'Home Phone', value: 'homePhone' },
      { key: '2', text: 'Office Phone', value: 'officePhone' },
      { key: '3', text: 'Emergency Phone', value: 'emergencyPhone' },
      { key: '4', text: 'Other Phone', value: 'otherPhone' },

      { key: '5', text: 'Whatsapp', value: 'whatsapp' },
      { key: '6', text: 'Telegram', value: 'telegram' },
      { key: '7', text: 'Twitter', value: 'twitter' },
      { key: '8', text: 'Facebook', value: 'facebook' },
      { key: '9', text: 'Instagram', value: 'instagram' },
    ])
  }
}