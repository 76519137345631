export default function macroReducer (state = {}, action) {
  switch (action.type) {
    case 'MACRO_RESET': {
        return({
            ...state,
            getMacro: {},
            updateMacro: {},
            createMacro: {},
        })
    }

    case 'MACRO_BROWSE_PENDING': {
      return({...state, browseMacro: {progress: 0}})
    }
    case 'MACRO_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseMacro: {...api.browseMacro.response, progress: 1, payload: action.payload}})
            return({...state, browseMacro: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseMacro: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MACRO_BROWSE_REJECTED': {
        return({...state, browseMacro: {progress: 2, payload: action.payload}})
    }
    
    case 'MACRO_GET_PENDING': {
        return({...state, getMacro: {progress: 0}})
    }
    case 'MACRO_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getMacro: {...api.getMacro.response, progress: 1, payload: action.payload}})
            return({...state, getMacro: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getMacro: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MACRO_GET_REJECTED': {
        return({...state, getMacro: {progress: 2, payload: action.payload}})
    }

    case 'MACRO_STATUS_PENDING': {
      return({...state, statusMacro: {progress: 0}})
    }
    case 'MACRO_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusMacro: {...api.statusMacro.response, progress: 1, payload: action.payload}})
            return({...state, statusMacro: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusMacro: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MACRO_STATUS_REJECTED': {
        return({...state, statusMacro: {progress: 2, payload: action.payload}})
    }

    case 'MACRO_CREATE_PENDING': {
      return({...state, createMacro: {progress: 0}})
    }
    case 'MACRO_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createMacro: {...api.createMacro.response, progress: 1, payload: action.payload}})
            return({...state, createMacro: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createMacro: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MACRO_CREATE_REJECTED': {
        return({...state, createMacro: {progress: 2, payload: action.payload}})
    }

    case 'MACRO_UPDATE_PENDING': {
      return({...state, updateMacro: {progress: 0}})
    }
    case 'MACRO_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateMacro: {...api.updateMacro.response, progress: 1, payload: action.payload}})
            return({...state, updateMacro: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateMacro: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MACRO_UPDATE_REJECTED': {
        return({...state, updateMacro: {progress: 2, payload: action.payload}})
    }

    case 'MACRO_REMOVE_PENDING': {
      return({...state, removeMacro: {progress: 0}})
    }
    case 'MACRO_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeMacro: {...api.removeMacro.response, progress: 1, payload: action.payload}})
            return({...state, removeMacro: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeMacro: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MACRO_REMOVE_REJECTED': {
        return({...state, removeMacro: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}