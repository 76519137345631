export default function calendarReducer (state = {}, action) {
  switch (action.type) {
    case 'CALENDAR_RESET': {
        return({
            ...state,
            getCalendar: {},
            updateCalendar: {},
            createCalendar: {},
        })
    }

    case 'CALENDAR_BROWSE_PENDING': {
      return({...state, browseCalendar: {progress: 0}})
    }
    case 'CALENDAR_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseCalendar: {...api.browseCalendar.response, progress: 1, payload: action.payload}})
            return({...state, browseCalendar: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseCalendar: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CALENDAR_BROWSE_REJECTED': {
        return({...state, browseCalendar: {progress: 2, payload: action.payload}})
    }
    
    case 'CALENDAR_GET_PENDING': {
        return({...state, getCalendar: {progress: 0}})
    }
    case 'CALENDAR_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getCalendar: {...api.getCalendar.response, progress: 1, payload: action.payload}})
            return({...state, getCalendar: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getCalendar: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CALENDAR_GET_REJECTED': {
        return({...state, getCalendar: {progress: 2, payload: action.payload}})
    }

    case 'CALENDAR_STATUS_PENDING': {
      return({...state, statusCalendar: {progress: 0}})
    }
    case 'CALENDAR_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusCalendar: {...api.statusCalendar.response, progress: 1, payload: action.payload}})
            return({...state, statusCalendar: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusCalendar: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CALENDAR_STATUS_REJECTED': {
        return({...state, statusCalendar: {progress: 2, payload: action.payload}})
    }

    case 'CALENDAR_CREATE_PENDING': {
      return({...state, createCalendar: {progress: 0}})
    }
    case 'CALENDAR_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createCalendar: {...api.createCalendar.response, progress: 1, payload: action.payload}})
            return({...state, createCalendar: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createCalendar: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CALENDAR_CREATE_REJECTED': {
        return({...state, createCalendar: {progress: 2, payload: action.payload}})
    }

    case 'CALENDAR_UPDATE_PENDING': {
      return({...state, updateCalendar: {progress: 0}})
    }
    case 'CALENDAR_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateCalendar: {...api.updateCalendar.response, progress: 1, payload: action.payload}})
            return({...state, updateCalendar: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateCalendar: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CALENDAR_UPDATE_REJECTED': {
        return({...state, updateCalendar: {progress: 2, payload: action.payload}})
    }

    case 'CALENDAR_REMOVE_PENDING': {
      return({...state, removeCalendar: {progress: 0}})
    }
    case 'CALENDAR_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeCalendar: {...api.removeCalendar.response, progress: 1, payload: action.payload}})
            return({...state, removeCalendar: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeCalendar: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CALENDAR_REMOVE_REJECTED': {
        return({...state, removeCalendar: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}