import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table, TextArea } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { groupOptions, actionOptions, ticketOptions }  from '../../modules/service/StaticMaster'

import { SubmissionError, Field, reduxForm, change, formValueSelector, getFormValues } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, LabelNumberField, InsertableDropdownField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetMacro, getMacro,updateMacro, createMacro } from "../../actions/service/macroAction"
import { listUser, listWorkgroup, listOrganization, listProduct, listForm, listMacro, listRemark, listChannel } from "../../actions/service/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('MacroForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    macroStore: state.macroStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listUser:(objParam) => dispatch(listUser(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    listOrganization:(objParam) => dispatch(listOrganization(objParam)),
    listProduct:(objParam) => dispatch(listProduct(objParam)),
    listForm:(objParam) => dispatch(listForm(objParam)),
    listMacro:(objParam) => dispatch(listMacro(objParam)),
    listRemark:(objParam) => dispatch(listRemark(objParam)),
    listChannel:(objParam) => dispatch(listChannel(objParam)),
    
    resetMacro:(objParam) => dispatch(resetMacro(objParam)),
    getMacro:(objParam) => dispatch(getMacro(objParam)),
    createMacro:(objParam) => dispatch(createMacro(objParam)),
    updateMacro:(objParam) => dispatch(updateMacro(objParam)),
  }
}

class MacroForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount(){
    this.props.listUser()
    this.props.listWorkgroup()
    this.props.listOrganization()
    this.props.listProduct()
    this.props.listForm()
    this.props.listMacro()
    this.props.listRemark()
    this.props.listChannel()

    this.props.resetMacro(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getMacro({macroId: this.props.macroId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.macroStore.updateMacro!=undefined ? this.props.macroStore.updateMacro : {}) : (this.props.macroStore.createMacro!=undefined ? this.props.macroStore.createMacro : {}))

    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateMacro({...values}) : this.props.createMacro({...values})
  }

  incrRowQty(isIncrease, kind) {
    console.log(kind, this.props[kind +'ActionQty'])
    if (isIncrease) {
      if (this.props[kind +'ActionQty']<10) {
        this.props.dispatch(change('MacroForm', kind +'ActionQty', ((isNaN(this.props[kind +'ActionQty']) ? 0 : parseInt(this.props[kind +'ActionQty'])) +1)))
      }
    } else {
      if (this.props[kind +'ActionQty']>1) {
        this.props.dispatch(change('MacroForm', kind +'ActionQty', ((isNaN(this.props[kind +'ActionQty']) ? 0 : parseInt(this.props[kind +'ActionQty'])) -1)))
      }
    }
  }

  drawActions(qty=1, kind, components, options) {
    const actions= this.props[kind +'Actions'] || []
    
    // console.log('!!! CONDITIONS DRAW', conditionsQty)
    const items= [];
    for (var i=0; i<qty; i++) {
      items[i]= {
        item: actions[i] ? actions[i].item : null,
      };
    }

    return (<>
      {
        items.map((item, i)=>{
          return(<Segment key={i}>
            <Form.Group widths='16'>
              <Field name={'actionType_'+ i} component={SelectField} onChange={this.onChangeSelect.bind(this, 'actionValue_'+ i)}
              width={7}
              options={actionOptions('macro-param')}
              validate= {[required]}
              // label='Action Type'
              placeholder='Action Type' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name={'actionValue_'+ i} component={
                (this.props.formValues && components && (components.get(this.props.formValues['actionType_'+ i])||{}).input=='select' && SelectField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['actionType_'+ i])||{}).input=='selects' && SelectField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['actionType_'+ i])||{}).input=='contain' && SelectField) ||
                (this.props.formValues && components && (components.get(this.props.formValues['actionType_'+ i])||{}).input=='contains' && SelectField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['actionType_'+ i])||{}).input=='duration' && LabelNumberField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['actionType_'+ i])||{}).input=='taggings' && InsertableDropdownField) || 
                InputField
              }
              label={(this.props.formValues && components && (components.get(this.props.formValues['actionType_'+ i])||{}).input=='duration' && NumberField) && 'Minutes'}
              multiple={(((this.props.formValues && components && (components.get(this.props.formValues['actionType_'+ i])||{}).input=='selects') || (this.props.formValues && components && (components.get(this.props.formValues['actionType_'+ i])||{}).input=='contains')) && SelectField)}
              options={options && options[(this.props.formValues && components && (components.get(this.props.formValues['actionType_'+ i])||{}).options)]}
              width={16}
              validate= {[required]}
              // label='Default Value'
              placeholder='Default Value' />
            </Form.Group>
          </Segment>)
        })
      }
    </>)        
  }

  onChangeSelect(v) {
    this.props.dispatch(change('MacroForm', v, null));
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.macroStore.getMacro!=undefined && this.props.macroStore.getMacro.progress=='1' ? this.props.macroStore.getMacro : {})
    const { progress, code, text }= (mode==2 ? (this.props.macroStore.updateMacro!=undefined ? this.props.macroStore.updateMacro : {}) : (this.props.macroStore.createMacro!=undefined ? this.props.macroStore.createMacro : {}))

    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    const listUser= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])

    const components= new Map();
    const options= {};

    (actionOptions('macro-param')||[]).map(item=>{
      item.value && components.set(item.value, item);
    })
    options['privacyOptions']= ticketOptions('privacy');
    options['categoryOptions']= ticketOptions('category');
    options['priorityOptions']= ticketOptions('priority');
    options['severityOptions']= ticketOptions('severity');
    options['groupOptions']= groupOptions();

    options['listWorkgroup']= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    options['listUser']= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])
    options['listOrganization']= (this.props.masterStore.listOrganization!=undefined && this.props.masterStore.listOrganization.progress=='1' ? this.props.masterStore.listOrganization.data : [])
    options['listProduct']= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])
    options['listForm']= (this.props.masterStore.listForm!=undefined && this.props.masterStore.listForm.progress=='1' ? this.props.masterStore.listForm.data : [])
    options['listMacro']= (this.props.masterStore.listMacro!=undefined && this.props.masterStore.listMacro.progress=='1' ? this.props.masterStore.listMacro.data : [])
    options['listRemark']= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : [])
    options['listChannel']= (this.props.masterStore.listChannel!=undefined && this.props.masterStore.listChannel.progress=='1' ? this.props.masterStore.listChannel.data : [])

    return (
      <div style={{padding: '1em 1.5em'}}>
        <div style={{position: 'fixed', marginTop: '-3em', right: '1.7em', display: 'inline-block', background: '#222', padding: '.2em .5em .6em .6em', borderRadius: '.5em'}}><Icon name='window close' color='yellow' style={{cursor: 'pointer', padding: '0', margin: '0', fontSize: '2em'}} onClick={this.props.closeModal.bind(this, pristine)}/></div>
      
        <Header as='h4' style={{margin: '0 0 2em 0'}}>
          <Icon name='code' style={{fontSize: '1em'}}/>
          <Header.Content>
            Macro / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
          </Header.Content>
        </Header>

        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Grid stackable doubling>
            <Grid.Row columns={16}>
              <Grid.Column width={6}>
                <Header as='h5'>
                  <Header.Content>
                    Macro Title
                    <Header.Subheader>Short description of macro.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='name' component={InputField}
                    validate= {[required]}
                    label='Name'
                    placeholder='Name' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='descript' component={TextAreaField}
                    label='Description'
                    placeholder='Additional information' />
                  </Form.Group>
                </Segment>

                <Header as='h5'>
                  <Header.Content>
                    Availability & Access
                    <Header.Subheader>Applied macro on certain event and privilege.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='workgroups' component={SelectField}
                    multiple
                    options={listWorkgroup}
                    label='Teams / Workgroups'
                    placeholder='Available for certain teams' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='events' component={SelectField}
                    options={ticketOptions('event')}
                    label='Applied Macro on Events'
                    placeholder='Event when macro executed' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='groups' component={SelectField}
                    multiple
                    options={groupOptions()}
                    label='Roles / Groups'
                    placeholder='Available for certain groups' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='users' component={SelectField}
                    multiple
                    options={listUser}
                    label='Users / Accounts'
                    placeholder='Available for certain accounts' />
                  </Form.Group>
                </Segment>
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as='h5'>
                  <Header.Content>
                    Actions To Do
                    <Header.Subheader>Set specific actions when macro is triggered.</Header.Subheader>
                  </Header.Content>
                </Header>
                {this.drawActions(this.props.todoActionQty, 'todo', components, options)}

                <div style={{textAlign: 'center', marginTop: '2em'}}>
                  <Button.Group size='mini'>
                    <Popup content='Remove / less actions'  position= 'bottom center'
                    trigger={
                      <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, false, 'todo')}><Icon color='red' name='arrow up' size='large' /></Button>
                    }
                    inverted />
                    <Button.Or></Button.Or>
                    <Popup content='Add more actions'  position= 'bottom center'
                    trigger={
                      <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, true, 'todo')}><Icon color='blue' name='arrow down' size='large' /></Button>
                    }
                    inverted />
                  </Button.Group>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Macro' : 'Insert Macro') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.macroStore.getMacro!=undefined && state.macroStore.getMacro.progress==1 ? state.macroStore.getMacro.data : {todoActionQty: 1}),
      formValues: getFormValues('MacroForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      todoActionQty: selector(state, 'todoActionQty'),
    }),
    { load: getMacro }
  )
  (reduxForm({
    form: 'MacroForm',	// a unique identifier for this form
  })(MacroForm)
  )
)
