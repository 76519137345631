import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetRequester(objParam) {
  return {
      type: 'REQUESTER_RESET',
      payload: objParam
  }
}

export function getRequester(objParam) {
  return {
      type: 'REQUESTER_GET',
      payload:
        axios.post(
          urlAPI + 'service/requester/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusRequester(objParam) {
  return {
      type: 'REQUESTER_STATUS',
      payload:
        axios.post(
          urlAPI + 'service/requester/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseRequester(objParam) {
  return {
      type: 'REQUESTER_BROWSE',
      payload:
        axios.post(
          urlAPI + 'service/requester/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createRequester(objParam) {
  return {
      type: 'REQUESTER_CREATE',
      payload:
        axios.post(
          urlAPI + 'service/requester/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateRequester(objParam) {
  return {
      type: 'REQUESTER_UPDATE',
      payload:
        axios.post(
          urlAPI + 'service/requester/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeRequester(objParam) {
  return {
      type: 'REQUESTER_REMOVE',
      payload:
        axios.post(
          urlAPI + 'service/requester/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
