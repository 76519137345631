import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Label, Modal, Form, Input, Grid, Card, Icon, Table } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"
import { targetStatus } from "../../libraries/common/StaticMasterData"

import { connect } from "react-redux"
import { contactOmniphone } from "../../actions/stream/omniphoneAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    omniphoneStore: state.omniphoneStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    contactOmniphone:(objParam) => dispatch(contactOmniphone(objParam)),
  }
}

class EngagementBrowse extends Component {
  constructor(props) {
    super(props)
    this.state= {
      expandAppointment: false,
      expandConversation: true,
    }
  }

  setExpand(v) {
    const { expandAppointment, expandConversation }= this.state

    if (v==0) {
      this.setState({
        expandAppointment: !expandAppointment,
        expandConversation: false,
      })
    } else {
      this.setState({
        expandConversation: !expandConversation,
        expandAppointment: false,
      })
    }
  }
  render() {
    const { style }= this.props
    const { expandAppointment, expandConversation }= this.state
    const { target }= (this.props.omniphoneStore.contactOmniphone!=undefined && this.props.omniphoneStore.contactOmniphone.progress=='1' && this.props.omniphoneStore.contactOmniphone.data ? this.props.omniphoneStore.contactOmniphone.data : {})
    
    return (
      <div style={{...style}}>
        {false && target && target.appointments && target.appointments.length>0 && <>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
            <Label color='orange' style={{marginLeft: '-1.05em'}}>APPOINTMENT</Label>
            </div>
            <div>
              <Button icon color={expandAppointment ? 'grey' : 'orange'} onClick= {this.setExpand.bind(this, 0)}>
                {target.appointments.length} <Icon name={expandAppointment ? 'angle double up' : 'angle double down'} />
              </Button>
            </div>
          </div>
          {expandAppointment &&
          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell width='4'>
                  <Header as='h5' color='blue'>
                    Appointment
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='6'>
                  <Header as='h5' color='blue'>
                    Participant
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='6'>
                  <Header as='h5' color='blue'>
                    Content / Discussion
                  </Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {target.appointments.map((item, i)=>{
                if (i<3) {
                  return(
                    <Table.Row key={++i}>
                      <Table.Cell>{i}</Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          {/* <Icon color='grey' name={(item.channelType=='Voice' && 'microphone') || (item.channelType=='IVR' && 'volume down') || (item.channelType=='SMS' && 'sticky note outline')
                           || (item.channelType=='Email' && 'envelope outline') || (item.channelType=='Whatsapp' && 'whatsapp') || (item.channelType=='Telegram' && 'telegram plane')
                           || (item.channelType=='Twitter' && 'twitter') || (item.channelType=='Facebook' && 'facebook') || (item.channelType=='Instagram' && 'instagram') || 'share alternate'}
                           /> */}
                          <Header.Content>
                            <Header.Subheader>{item.channelType  || 'Voice'} / {item.script || 'No template'}</Header.Subheader>
                            {item.topic}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Subheader>{item.session} {item.startTime}, {format(parse(item.startEvent, 'yyyy-MM-dd', new Date()), 'dd/MMM, yyyy')}, {item.venue  || 'Online meeting only'}</Header.Subheader>
                          <mark style={{fontWeight: 'bold'}}>{item.target && item.target.name}</mark>, {item.participants || 'No participant'}
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            <Header.Subheader>By {item.client || 'Unknown'}</Header.Subheader>
                            {item.content}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  )
                }
              })}
            </Table.Body>
          </Table>}
          <Divider hidden />
        </>}

        {target && target.voices && target.voices.length>0 && <>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              <Header as='h5' inverted style={{padding: '.25em .4em .2em .15em',margin: '0 0 0 -.9em', background: '#0e8d87', display: 'inline-block'}}>
                <Icon name='angle double down' style={{fontSize: '1em'}}/>
                <Header.Content>
                  CONVERSATION
                </Header.Content>
              </Header>
              {/* <Label color='teal' style={{marginLeft: '-1.05em'}}>CONVERSATION</Label> */}
            </div>
            <div>
              {/* <Button icon color={(expandConversation || !target.appointments || target.appointments.length==0) ? 'grey' : 'orange'} onClick= {this.setExpand.bind(this, 1)}>
                {target.voices.length} <Icon name={(expandConversation || !target.appointments || target.appointments.length==0) ? 'angle double up' : 'angle double down'} />
              </Button> */}
            </div>
          </div>
          
          {(expandConversation  || !target.appointments || target.appointments.length==0) &&
          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell width='4'><Header color='blue' as='h5'>Channel</Header></Table.HeaderCell>
                <Table.HeaderCell width='4'><Header color='blue' as='h5'>Agent</Header></Table.HeaderCell>
                <Table.HeaderCell width='5'><Header color='blue' as='h5'>Remarks</Header></Table.HeaderCell>
                <Table.HeaderCell width='3'><Header color='blue' as='h5'>Result</Header></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {target.voices.map((item, i)=>{
                const terminating= (item.terminating.substring(0, 5) +'xxxx'+ item.terminating.substring(item.terminating.length-4, item.terminating.length))
                if (i<5) {
                  return(
                    <Table.Row key={10 + i++}>
                      <Table.Cell>{i}.</Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          {/* <Icon color='grey' name={(item.channelType=='Voice' && 'microphone') || (item.channelType=='IVR' && 'volume down') || (item.channelType=='SMS' && 'sticky note outline')
                           || (item.channelType=='Email' && 'envelope outline') || (item.channelType=='Whatsapp' && 'whatsapp') || (item.channelType=='Telegram' && 'telegram plane')
                           || (item.channelType=='Twitter' && 'twitter') || (item.channelType=='Facebook' && 'facebook') || (item.channelType=='Instagram' && 'instagram') || 'share alternate'}
                           /> */}
                          <Header.Content>
                            {terminating}
                            <Header.Subheader>
                              <b>{format(parse(item.dialStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm')}</b> {format(parse(item.dialStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd/MMM, yyyy')}
                            </Header.Subheader>
                            <Header.Subheader>
                              <b>
                                {/* {item.channelType || 'Voice'} -  */}
                              {target && (
                                (terminating==target.mobile && target.name) || 
                                (terminating==target.officePhone && (target.name +' / '+ target.jobCompany)) || 
                                (terminating==target.homePhone && target.name) || 
                                (terminating==target.emergencyPhone && target.emergencyName) || 
                                (terminating==target.otherPhone && target.otherName)
                                || 'Self')
                              } / {target && (
                                (terminating==target.mobile && 'Mobile') || 
                                (terminating==target.officePhone && 'Office') || 
                                (terminating==target.homePhone && 'Home') || 
                                (terminating==target.emergencyPhone && (target.emergencyRelationship || 'Emergency')) || 
                                (terminating==target.otherPhone && (target.otherRelationship || 'Other'))
                                || 'Self')
                              }</b>
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          {item.user!=null ? item.user.name : 'No agent info'}
                          <Header.Subheader>Tags: <b>{item.user!=null ? item.user.tagger || 'null' : ''}</b></Header.Subheader>
                          <Header.Subheader>Account: <b>{item.user!=null ? item.user.account || '-' : ''}</b></Header.Subheader>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5' style={{marginBottom: '0'}}>
                          <div style={{background: (item.status=='1' && '#84dbff') || (item.status=='0' && '#ffb3b3') || '#ddd', textAlign: 'right', display: 'inline-block', width: '4em', padding: '.5em', marginRight: '.5em'}}>{item.progress} %</div>
                          <Header.Content>
                            {item.remark || 'No remark !'}
                            <Header.Subheader>on <i>{item.expired!=null ? format(parse(item.expired, 'yyyy-MM-dd', new Date()), 'dd/MMM yyyy') : 'none'}</i></Header.Subheader>
                          </Header.Content>
                          <div style={{fontSize: '.9em'}}>{item.remark ? item.reason : 'No additional information for certain remark'}</div>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {(item.dropStamp && ' Dropped') || targetStatus[item.status] || 'No result'}
                            <Header.Subheader>{item.reason || 'No info'}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  )
                }
              })}
            </Table.Body>
          </Table>}
        </>}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EngagementBrowse)