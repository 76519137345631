import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import 'semantic-ui-css/semantic.min.css'
import 'filepond/dist/filepond.min.css'
import "react-datepicker/dist/react-datepicker.css";
import './styles/styles.css'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

// import axios from "axios"
// import socketIOClient from "socket.io-client";

import { Provider } from 'react-redux'
import store from './actions/Store'
import { BrowserRouter, Switch, Route, useParams } from 'react-router-dom'

import Authenticate from './pages/AuthenticateOvisone'
// import Ovis from './pages/ovis/AdminContainer'
// import Collection from './pages/ovis/CollectionContainer'
// import Cdp from './pages/ovis/CdpContainer'
// import Crm from './pages/ovis/CrmContainer'
import Service from './pages/service/ServiceContainer'
// import Knowledge from './pages/ovis/KnowledgeContainer'

// import Sapa from './pages/sapa/AdminContainer'


// import Authenticate from './pages/Authenticate'
// import Admin from './pages/halo/AdminContainer'
// import Agent from './pages/agent/AgentContainer';
// import Responsive from './pages/responsive/AgentContainer';

// import Realm from './pages/realm/halo/RealmContainer'
// import Supervisor from './pages/supervisor/SupervisorContainer'

// import Emulator from './pages/emulator/Emulator'

// import Whatsapp from './pages/whatsapp/WhatsappContainer'

// const socket = socketIOClient('http://127.0.0.1:4001');

class Root extends React.Component {
	render() {
		try {
			//intercept response code
			// axios.interceptors.response.use(response => {
			// 	//console.log(response)
			// 	return (response)
			// }, error => {
			// 	if (error.response===undefined || error.response.status === 401) {
			// 		console.log(error.response.status)
			// 		localStorage.setItem('tokenAuth','')
			// 	}
			// });
			
			
			return(
				<BrowserRouter>
					<Switch>
						
						<Route exact path='/' component={Authenticate} />
						<Route exact path='/signin' component={Authenticate} />
						<Route path='/signin/token=:token' component={Authenticate} />
						
						{/* <Route exact path='/ovis' component={Ovis} /> */}
						{/* <Route exact path='/collect' component={Collection} /> */}
						{/* <Route exact path='/cdp' component={Cdp} /> */}
						{/* <Route exact path='/crm' component={Crm} /> */}
						{/* <Route exact path='/kms' component={Knowledge} /> */}
						<Route exact path='/service' component={Service} />
						
						{/* <Route exact path='/sapa' component={Sapa} /> */}
						
						{/* <Route exact path='/admin' component={Admin} /> */}
						{/* <Route exact path='/realm' component={Realm} /> */}
						{/* <Route exact path='/spv' component={Supervisor} /> */}
						{/* <Route exact path='/agent' component={isMobile ? Responsive : Agent} /> */}
						{/* <Route exact path='/responsive' component={Responsive} /> */}
						{/* <Route exact path='/emulator' component={Emulator} /> */}

						{/* <Route exact path='/wasap' component={Whatsapp} /> */}

					</Switch>
				</BrowserRouter>
			)
		} catch(error) {
			console.log(error)
			return(<div>ERROR !</div>)
		}
	}
}

ReactDOM.render(
<Provider store={store}>
		<Root />
</Provider>, document.getElementById('root'))
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
