export default function classificationReducer (state = {}, action) {
  switch (action.type) {
    case 'CLASSIFICATION_RESET': {
        return({
            ...state,
            getClassification: {},
            updateClassification: {},
            createClassification: {},
        })
    }

    case 'CLASSIFICATION_BROWSE_PENDING': {
      return({...state, browseClassification: {progress: 0}})
    }
    case 'CLASSIFICATION_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseClassification: {...api.browseClassification.response, progress: 1, payload: action.payload}})
            return({...state, browseClassification: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseClassification: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CLASSIFICATION_BROWSE_REJECTED': {
        return({...state, browseClassification: {progress: 2, payload: action.payload}})
    }
    
    case 'CLASSIFICATION_GET_PENDING': {
        return({...state, getClassification: {progress: 0}})
    }
    case 'CLASSIFICATION_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getClassification: {...api.getClassification.response, progress: 1, payload: action.payload}})
            return({...state, getClassification: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getClassification: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CLASSIFICATION_GET_REJECTED': {
        return({...state, getClassification: {progress: 2, payload: action.payload}})
    }

    case 'CLASSIFICATION_STATUS_PENDING': {
      return({...state, statusClassification: {progress: 0}})
    }
    case 'CLASSIFICATION_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusClassification: {...api.statusClassification.response, progress: 1, payload: action.payload}})
            return({...state, statusClassification: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusClassification: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CLASSIFICATION_STATUS_REJECTED': {
        return({...state, statusClassification: {progress: 2, payload: action.payload}})
    }

    case 'CLASSIFICATION_CREATE_PENDING': {
      return({...state, createClassification: {progress: 0}})
    }
    case 'CLASSIFICATION_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createClassification: {...api.createClassification.response, progress: 1, payload: action.payload}})
            return({...state, createClassification: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createClassification: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CLASSIFICATION_CREATE_REJECTED': {
        return({...state, createClassification: {progress: 2, payload: action.payload}})
    }

    case 'CLASSIFICATION_UPDATE_PENDING': {
      return({...state, updateClassification: {progress: 0}})
    }
    case 'CLASSIFICATION_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateClassification: {...api.updateClassification.response, progress: 1, payload: action.payload}})
            return({...state, updateClassification: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateClassification: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CLASSIFICATION_UPDATE_REJECTED': {
        return({...state, updateClassification: {progress: 2, payload: action.payload}})
    }

    case 'CLASSIFICATION_REMOVE_PENDING': {
      return({...state, removeClassification: {progress: 0}})
    }
    case 'CLASSIFICATION_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeClassification: {...api.removeClassification.response, progress: 1, payload: action.payload}})
            return({...state, removeClassification: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeClassification: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CLASSIFICATION_REMOVE_REJECTED': {
        return({...state, removeClassification: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}