import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import FieldsBrowse from '../../libraries/service/FieldsBrowse'
import FieldsForm from '../../libraries/service/FieldsForm'
import FieldsSearch from '../../libraries/service/FieldsSearch'
import ConfirmationModal from '../../libraries/service/ConfirmationModal';


class FieldsBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        fieldsId: 0,
      };
    }

    setMode(mode, id, param) {
      this.setState({mode: mode, fieldsId: id, param: param})
    }

    closeModal(pristine) { //true: no data change, false: data been changed
      if (pristine===false) {
        console.log(pristine)
        this.setState({
          modal: this.state.modal===1 ? 0 : 1, //1: confirmation
        });
      } else {
        this.setState({
          mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
          fieldsId: 0,

          modal: 0,
        });
      }
    }

    render() {
      const {mode, fieldsId, param, modal}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div>
          <FieldsSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
        
          {<FieldsBrowse mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} openModal={this.props.openModal} closeModal={this.closeModal.bind(this)} />}
          {/* {mode==2 && <FieldsForm mode={mode} fieldsId={fieldsId} param={param} setMode={this.setMode.bind(this)} />}
          {mode==3 && <FieldsForm mode={mode} fieldsId={0} param={param} setMode={this.setMode.bind(this)} />} */}
          {mode==2 && <ModalContainer headless size='large' content={<FieldsForm mode={mode} fieldsId={fieldsId} param={param} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
          {mode==3 && <ModalContainer headless size='large' content={<FieldsForm mode={mode} fieldsId={0} param={param} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}

          {modal==1 && <ModalContainer headless style={{marginTop: screenHeight/4}} size='tiny' content={<ConfirmationModal screenWidth={screenWidth} screenHeight={screenHeight-125} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
        </div>
      )
    }
}

export default FieldsBody
