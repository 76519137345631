import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Label, Dropdown, Popup } from 'semantic-ui-react'

import StatisticSearch from '../../libraries/service/StatisticSearch';
import WorkspaceWidget from '../../libraries/widget/service/WorkspaceWidget';
import SeverityWidget from '../../libraries/widget/service/SeverityWidget';
import PriorityWidget from '../../libraries/widget/service/PriorityWidget';
import ClassificationWidget from '../../libraries/widget/service/ClassificationWidget';
import ResolutionWidget from '../../libraries/widget/service/ResolutionWidget';
import ServiceWidget from '../../libraries/widget/service/ServiceWidget';
import ClusterWidget from '../../libraries/widget/service/ClusterWidget';
import ChannelWidget from '../../libraries/widget/service/ChannelWidget';
import RemarkWidget from '../../libraries/widget/service/RemarkWidget';
import ComplianceWidget from '../../libraries/widget/service/ComplianceWidget';


import numeral from "numeral"
import { format, parse, addSeconds } from "date-fns"

import { personalOptions, statisticOptions }  from '../../modules/service/StaticMaster'

import { connect } from "react-redux"
import { listDashboard } from "../../actions/service/masterAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listDashboard:(objParam) => dispatch(listDashboard(objParam)),
  }
}

class StatisticDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      statisticId: 0,
    };
  }
  
  componentDidMount() {
    this.props.listDashboard();
  }

  setMode(mode, id, params) {
    this.setState({mode: mode, statisticId: id, params})
  }

  setDashboard(v) {
    this.props.setDashboard(v);
  }

  drawDashboard() {

  }

  render() {
    const {mode, statisticId, params}= this.state
    const {screenWidth, screenHeight}= this.props
    
    const listDashboard = (this.props.masterStore.listDashboard!==undefined && this.props.masterStore.listDashboard.progress=='1' ? this.props.masterStore.listDashboard.data : null)

    return(
      <div style={{padding: '2em 1.5em 1.5em'}}>
        <Grid columns={16}>
          <Grid.Row>
            <Grid.Column width={6} style={{padding: '.5em'}}>
              <div style={{display: 'flex'}}>
                <div style={{padding: '0 .2em 0 0', height: 20}}>
                  <Dropdown icon='ellipsis vertical' inline style={{fontSize: '1.5em', margin: '.2em 0 0 -.3em', color: '#c31'}}>
                    <Dropdown.Menu>
                      {listDashboard && listDashboard.map(item=>{
                        return(
                          <Dropdown.Item icon={this.props.dashboard && this.props.dashboard.value==item.value ? 'folder' : 'folder outline'} text={item.text} onClick={this.setDashboard.bind(this, item)} /> 
                        )
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div style={{padding: '0 .1em 0 0'}}>
                  <Popup content='Switch to Pipeline' position='top left' inverted trigger={
                    <Label as='a' onClick={this.props.openWindow.bind(this, 'pipeline', 'mypage')} >
                      SHOW PIPELINE
                    </Label>
                  } />
                </div>
                <div style={{padding: '0 .1em'}}>
                  <Popup content='Create New Ticket' position='top left' inverted trigger={
                    <Label as='a' onClick={this.props.openWindow.bind(this, 'myticket', {mode: 3})}>
                      CREATE TICKET
                    </Label>
                  } />
                </div>
                <div style={{padding: '0 .1em'}}>
                  <Popup content='Find Existing Ticket' position='top left' inverted trigger={
                    <Label as='a' onClick={this.props.openWindow.bind(this, 'myticket', {mode: 1})}>
                      FIND TICKET
                    </Label>
                  } />
                </div>
                <div style={{flex: 1, padding: '0 0 0 0.1em'}}>
                  <Popup content='Find Agent, Backend, Executive' position='top left' inverted trigger={
                    <Label as='a' onClick={this.props.openWindow.bind(this, 'people', {mode: 1})}>
                      FIND PEOPLE
                    </Label>
                  } />
                </div>
              </div>
              <StatisticSearch height={80} />
            </Grid.Column>
            <Grid.Column width={10} style={{padding: '.5em'}}>
              <WorkspaceWidget height={120} />
            </Grid.Column>
          </Grid.Row>


          <Grid.Row>
            <Grid.Column width={11} style={{padding: '.5em'}}>
              <ChannelWidget chart='bar' height={250}/>
            </Grid.Column>
            <Grid.Column width={5} style={{padding: '.5em'}}>
              <ComplianceWidget chart='pie' height={250}/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5} style={{padding: '.5em'}}>
              <SeverityWidget mode='active' chart='pie' width={350} />
            </Grid.Column>
            <Grid.Column width={6} style={{padding: '.5em'}}>
              <RemarkWidget mode='tiny' chart='bar' width={350} />
            </Grid.Column>
            <Grid.Column width={5} style={{padding: '.5em'}}>
              <PriorityWidget mode='active' chart='pie' width={350} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8} style={{padding: '.5em'}}>
              <ClassificationWidget mode='tiny' chart='bar' width={350} />
            </Grid.Column>
            <Grid.Column width={8} style={{padding: '.5em'}}>
              <ResolutionWidget mode='tiny' chart='bar' width={350} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8} style={{padding: '.5em'}}>
              <ServiceWidget mode='tiny' chart='bar' width={350} />
            </Grid.Column>
            <Grid.Column width={8} style={{padding: '.5em'}}>
              <ClusterWidget mode='tiny' chart='bar' width={350} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticDashboard)