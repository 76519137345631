import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Label } from 'semantic-ui-react'

export default class MenuLeftTicket extends Component {
  constructor(props){
  	super(props);
  	this.state = {
    };
  }

  render() {
    const { isExpand, isSubMenu }= this.state
    const { module, screenWidth,  screenHeight, mini }= this.props
  
    return (
      // <div style={{position: 'fixed', zIndex: 999, display: 'flex', flexDirection: 'column', marginLeft: '.2em', marginTop: '10em', borderRadius: '3em', padding: '2em .2em 2em .3em', background: '#76d8f5', textAlign: 'center'}}>
      <div style={{position: 'fixed', zIndex: 999, display: 'flex', flexDirection: 'column', marginLeft: '.2em', marginTop: '10em', borderRadius: '3em', padding: '2em .3em', background: 'linear-gradient(0deg, rgb(186, 168, 255) 0%, rgb(87, 197, 255) 65%, rgb(128 188 255) 100%)', textAlign: 'center'}}>
        <div style={{flex: '1'}}>
          <Divider clearing/>
          <Popup position= 'right center' inverted content='Dashboard'
            trigger= {
              <Button size='tiny' circular icon onClick={this.props.openWindow.bind(this, 'dashboard', 'mypage')} style={{padding: '.2em .2em 0 0', margin: '0'}} color={(!this.props.mode || this.props.mode=='dashboard' || this.props.mode=='pipeline') ? 'purple' : null}>
                <Icon name='chart line' style={{margin: '0', fontSize: '1.15em'}} />
              </Button>
            } 
          />
          <Divider />
          <Popup position= 'right center' inverted content='Ticket Bucket'
            trigger= {
              <Button size='tiny' circular icon onClick={this.props.openWindow.bind(this, 'bucket', 'bucket')} style={{padding: '0 .1em 0 0', margin: '0'}} color={this.props.mode=='bucket' ? 'purple' : null} >
                <Icon name='archive'/>
              </Button>
            } 
          />
          <Divider />
          <Popup position= 'right center' inverted content='Ticket Repository'
            trigger= {
              <Button size='tiny' circular icon onClick={this.props.openWindow.bind(this, 'myticket', 'mypage')} style={{padding: '.2em 0 0 0', margin: '0'}} color={this.props.mode=='myticket' ? 'purple' : null}>
                <Icon name='ticket' style={{transform: 'rotate(90deg)'}}/>
              </Button>
            } 
          />
          {this.props.token.groupId!='Agent' && <>
            <Divider style={{margin: '.3em 0'}} hidden />
            <Popup position= 'right center' inverted content='Updated Activity'
              trigger= {
                <Button size='tiny' circular icon onClick={this.props.openWindow.bind(this, 'updated', 'mypage')} style={{padding: '0 .05em 0 0', margin: '0'}} color={this.props.mode=='updated' ? 'purple' : null}>
                  <Icon name='bell' />
                </Button>
              } 
            />
          </>}
          {/* <Divider />
          <Popup position= 'right center' inverted content='Report & Analytic'
            trigger= {
              <Button size='tiny' circular icon onClick={this.props.openWindow.bind(this, 'report', 'mypage')} style={{padding: '0 .05em 0 0', margin: '0'}} color={this.props.mode=='report' ? 'purple' : null}>
                <Icon name='file alternate outline' />
              </Button>
            } 
          /> */}
          <Divider />
          <Popup position= 'right center' inverted content='Knowledge Management'
            trigger= {
              <Button size='tiny' circular icon onClick={this.props.openWindow.bind(this, 'knowledge', 'mypage')} style={{padding: '0 .05em 0 0', margin: '0'}} color={this.props.mode=='knowledge' ? 'purple' : null}>
                <Icon name='lightbulb' />
              </Button>
            } 
          />
          {this.props.token.groupId!='Agent' && <>
            <Divider style={{margin: '.3em 0'}} hidden />
            <Popup position= 'right center' inverted content='Requester Data'
              trigger= {
                <Button size='tiny' circular icon onClick={this.props.openWindow.bind(this, 'requester', 'mypage')} style={{padding: '0 .05em 0 0', margin: '0'}} color={this.props.mode=='requester' ? 'purple' : null}>
                  <Icon name='chess queen' />
                </Button>
              } 
            />
          </>}
          <Divider clearing/>
        </div>
        <div style={{padding: '0 0'}}>
          
        </div>
      </div>
    );
  }
}
