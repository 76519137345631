export default function reportReducer (state = {}, action) {
  switch (action.type) {
    case 'REPORT_RESET': {
        return({
            ...state,
            getReport: {},
            slaReport: {},
            requesterReport: {},
        })
    }

    case 'REPORT_TICKET_PENDING': {
      return({...state, ticketReport: {progress: 0}})
    }
    case 'REPORT_TICKET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, ticketReport: {...api.ticketReport.response, progress: 1, payload: action.payload}})
            return({...state, ticketReport: {progress: 2, payload: action.payload}})
        } else {
            return({...state, ticketReport: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REPORT_TICKET_REJECTED': {
        return({...state, ticketReport: {progress: 2, payload: action.payload}})
    }

    case 'REPORT_PEOPLE_PENDING': {
      return({...state, peopleReport: {progress: 0}})
    }
    case 'REPORT_PEOPLE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, peopleReport: {...api.peopleReport.response, progress: 1, payload: action.payload}})
            return({...state, peopleReport: {progress: 2, payload: action.payload}})
        } else {
            return({...state, peopleReport: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REPORT_PEOPLE_REJECTED': {
        return({...state, peopleReport: {progress: 2, payload: action.payload}})
    }

    case 'REPORT_REQUESTER_PENDING': {
      return({...state, requesterReport: {progress: 0}})
    }
    case 'REPORT_REQUESTER_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, requesterReport: {...api.requesterReport.response, progress: 1, payload: action.payload}})
            return({...state, requesterReport: {progress: 2, payload: action.payload}})
        } else {
            return({...state, requesterReport: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REPORT_REQUESTER_REJECTED': {
        return({...state, requesterReport: {progress: 2, payload: action.payload}})
    }

    case 'REPORT_SLA_PENDING': {
      return({...state, slaReport: {progress: 0}})
    }
    case 'REPORT_SLA_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, slaReport: {...api.slaReport.response, progress: 1, payload: action.payload}})
            return({...state, slaReport: {progress: 2, payload: action.payload}})
        } else {
            return({...state, slaReport: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REPORT_SLA_REJECTED': {
        return({...state, slaReport: {progress: 2, payload: action.payload}})
    }

    case 'REPORT_CSAT_PENDING': {
      return({...state, csatReport: {progress: 0}})
    }
    case 'REPORT_CSAT_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, csatReport: {...api.csatReport.response, progress: 1, payload: action.payload}})
            return({...state, csatReport: {progress: 2, payload: action.payload}})
        } else {
            return({...state, csatReport: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REPORT_CSAT_REJECTED': {
        return({...state, csatReport: {progress: 2, payload: action.payload}})
    }

    case 'REPORT_OJK_PENDING': {
      return({...state, ojkReport: {progress: 0}})
    }
    case 'REPORT_OJK_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, ojkReport: {...api.ojkReport.response, progress: 1, payload: action.payload}})
            return({...state, ojkReport: {progress: 2, payload: action.payload}})
        } else {
            return({...state, ojkReport: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REPORT_OJK_REJECTED': {
        return({...state, ojkReport: {progress: 2, payload: action.payload}})
    }

    case 'REPORT_CUSTOM_PENDING': {
      return({...state, customReport: {progress: 0}})
    }
    case 'REPORT_CUSTOM_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, customReport: {...api.customReport.response, progress: 1, payload: action.payload}})
            return({...state, customReport: {progress: 2, payload: action.payload}})
        } else {
            return({...state, customReport: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REPORT_CUSTOM_REJECTED': {
        return({...state, customReport: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}