import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'

import { groupOptions, conditionOptions, fieldOptions }  from '../../modules/service/StaticMaster'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, SearchField, LabelInputField, LabelSelectField, InsertableDropdownField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetFields, getFields,updateFields, createFields } from "../../actions/service/fieldsAction"
import { listAgent, listWorkgroup } from "../../actions/service/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('FieldsForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    fieldsStore: state.fieldsStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetFields:(objParam) => dispatch(resetFields(objParam)),
    getFields:(objParam) => dispatch(getFields(objParam)),
    createFields:(objParam) => dispatch(createFields(objParam)),
    updateFields:(objParam) => dispatch(updateFields(objParam)),
  }
}

class FieldsForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remarkResult: null,

      tab: 0,
    }
  }

  componentDidMount(){
    this.props.resetFields(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getFields({fieldsId: this.props.fieldsId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.fieldsStore.updateFields!=undefined ? this.props.fieldsStore.updateFields : {}) : (this.props.fieldsStore.createFields!=undefined ? this.props.fieldsStore.createFields : {}))

    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateFields({...values}) : this.props.createFields({...values})
  }

  getFieldName(e, v) {
    // console.log(e, v)
    var sysname= '__';
    const names= v && v.toLowerCase().split(' ');
    (names || []).map((item, i)=>{
      sysname+= (i==0 ? item : (item ? (item[0].toUpperCase() + item.substring(1)) : ''));
    })

    this.props.dispatch(change('FieldsForm', '_name', sysname));
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.fieldsStore.getFields!=undefined && this.props.fieldsStore.getFields.progress=='1' ? this.props.fieldsStore.getFields : {})
    const { progress, code, text }= (mode==2 ? (this.props.fieldsStore.updateFields!=undefined ? this.props.fieldsStore.updateFields : {}) : (this.props.fieldsStore.createFields!=undefined ? this.props.fieldsStore.createFields : {}))

    return (
      <div style={{padding: '1em 1.5em'}}>
        <div style={{position: 'absolute', marginTop: '-3em', right: '1.7em', display: 'inline-block', background: '#222', padding: '.2em .5em .6em .6em', borderRadius: '.5em'}}><Icon name='window close' color='yellow' style={{cursor: 'pointer', padding: '0', margin: '0', fontSize: '2em'}} onClick={this.props.closeModal.bind(this, pristine)}/></div>

        <Header as='h4' style={{margin: '0 0 2em 0'}}>
          <Icon name='columns' style={{fontSize: '1em'}}/>
          <Header.Content>
            Fields or Attributes / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
          </Header.Content>
        </Header>

        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Grid stackable doubling>
            <Grid.Row columns={16}>
              <Grid.Column width={6}>
                <Header as='h5'>
                  <Header.Content>
                    Custom Field
                    <Header.Subheader>Short description of fields.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='16'>
                    <Field name='dataType' component={LabelSelectField}
                    width={16}
                    options={fieldOptions('type')}
                    validate= {[required]}
                    label='Type'
                    placeholder='Field data type' />
                  </Form.Group>
                  <Form.Group widths='16'>
                    <Field name='grouping' component={LabelSelectField}
                    width={16}
                    options={fieldOptions('grouping')}
                    validate={required}
                    label='UI Block'
                    placeholder='UI Block / Grouping' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='name' component={InputField} onChange={this.getFieldName.bind(this)}
                    validate= {[required]}
                    label='Name'
                    placeholder='Field Name' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='_name' component={InputField}
                    readOnly
                    // validate= {[required]}
                    // label='_Name'
                    placeholder='Internal / System Name' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='descript' component={TextAreaField}
                    label='Description'
                    placeholder='Additional information' />
                  </Form.Group>
                </Segment>
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as='h5'>
                  <Header.Content>
                    Input Validation
                    <Header.Subheader>Applied validations on input / submission.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='validations' component={SelectField}
                    multiple
                    options={fieldOptions('validation')}
                    validate= {[required]}
                    label='Field Validations'
                    placeholder='Validations on input / submission' />
                  </Form.Group>
                </Segment>
                {this.props.validations && this.props.validations.indexOf('regex')>=0 &&
                  <Segment>
                    <Form.Group widths='equal'>
                      <Field name='regex' component={InputField}
                      // validate= {[required]}
                      label='Custom Validation / Regex'
                      placeholder='Regex based expression validation [a-zA-Z0-9]' />
                    </Form.Group>
                  </Segment>
                }

                <Header as='h5'>
                  <Header.Content>
                    Input Value Reference
                    <Header.Subheader>Set options value reference when required.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='defaultValue' component={InputField}
                    label='Default Value'
                    placeholder='Default value for current field' />
                  </Form.Group>
                </Segment>

                {(this.props.dataType=='select' || this.props.dataType=='multiple') && <>
                  {!this.props.lookupValue && <Segment>
                    <Form.Group widths='equal'>
                      <Field name='optionValue' component={InsertableDropdownField}
                      label='List Of Options'
                      placeholder='Reference list of values' />
                    </Form.Group>
                  </Segment>}

                  {!this.props.optionValue && <Segment>
                    <Form.Group widths='equal'>
                      <Field name='lookupValue' component={TextAreaField}
                      rows={9}
                      label='Look Up Value Script'
                      placeholder='Lookup value script' />
                    </Form.Group>
                  </Segment>}
                </>}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Fields' : 'Insert Fields') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.fieldsStore.getFields!=undefined && state.fieldsStore.getFields.progress==1 ? state.fieldsStore.getFields.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      name: selector(state, 'name'),
      _name: selector(state, '_name'),
      validations: selector(state, 'validations'),
      
      dataType: selector(state, 'dataType'),
      optionValue: selector(state, 'optionValue'),
      lookupValue: selector(state, 'lookupValue'),
    }),
    { load: getFields }
  )
  (reduxForm({
    form: 'FieldsForm',	// a unique identifier for this form
  })(FieldsForm)
  )
)
