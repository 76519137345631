import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Popup,
} from 'semantic-ui-react'

import { ticketOptions }  from '../common/StaticMasterData'
import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, SelectField, DatePickerField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { ticketReport } from "../../actions/service/reportAction"
import { listUser, listWorkgroup } from "../../actions/service/masterAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    reportStore: state.reportStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listUser:(objParam) => dispatch(listUser(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    ticketReport:(objParam) => dispatch(ticketReport(objParam)),
  }
}


class RptTicketSearch extends Component {
  constructor(props) {
    super(props)
    this.state= {
      searchMode: true,
    }
  }

  componentDidMount() {
    this.props.listUser()
    this.props.listWorkgroup()
  }

  submitForm(values) {
    const { search }= this.state
    this.props.ticketReport({search: {...values, ...search}})
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeDate(key, v) {
    const { search }= (this.props.reportStore.ticketReport!==undefined ? this.props.reportStore.ticketReport : {})
    var values= []
    values[key]= v
    this.props.ticketReport({search: {...search, ...values}})
  }

  onChangeInput(e, v) {
    const { search }= (this.props.reportStore.ticketReport!==undefined ? this.props.reportStore.ticketReport : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        parent.props.ticketReport({search: {...search, keyword: v}})
      }
    }, 500)
  }

  onChangeSelect(e, v) {
    const { search }= (this.props.reportStore.ticketReport!==undefined ? this.props.reportStore.ticketReport : {})
    this.props.ticketReport({search: {...search, status: (v=='' ? null : v)}})
  }
  
  render() {
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode }=this.props
    
    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    const listUser= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])
    
    return (
      <div style={{position: 'static', width: '100%', zIndex: '1000'}}>
        <div style={{padding: '1em 1em', background: 'rgba(230, 230, 230, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
          <div style={{display: 'flex', padingTop: '1em'}}>
            <div style={{flex: '1'}}>
              {/* <Label color='blue' style={{borderRadius: '2em', padding: '0.1em 1em 0.5em'}}> */}
                <Header as='h5' style={{marginTop: '.25em'}} color='blue' >
                  <Icon name='file alternate outline' style={{fontSize: '1em'}}/>
                  <Header.Content>
                    {this.props.menu=='performance' && 'Ticket Performance'}
                    {this.props.menu=='activity' && 'Ticket Activity'}
                    {this.props.menu=='timeline' && 'Ticket Timeline'}
                    
                    {this.props.menu=='service' && 'Ticket by Service / Product'}
                    {this.props.menu=='classification' && 'Ticket by Classification'}
                    {this.props.menu=='resolution' && 'Ticket by Resolution'}
                  </Header.Content>
                </Header>
              {/* </Label> */}
            </div>
            <div style={{paddingRight: '1em'}}>
              <Popup position= 'top right' inverted content='Excel'
                trigger= {<Button size='mini' color='teal' onClick={this.props.setMode.bind(this,1)} icon style={{padding: '.5em'}}><Icon name='file excel' /></Button>} 
                />
              <Popup position= 'top right' inverted content='PDF'
                trigger= {<Button size='mini' color='orange' onClick={this.props.setMode.bind(this,1)} icon style={{padding: '.5em'}}><Icon name='file pdf outline' /></Button>} 
              />
              <Popup position= 'top right' inverted content='CSV'
                trigger= {<Button size='mini' color='green' onClick={this.props.setMode.bind(this,1)} icon style={{padding: '.5em'}}><Icon name='file alternate outline' /></Button>} 
              />
            </div>
            <div>
              {mode !=1 && <Popup position= 'top right' inverted content='Back'
                trigger= {<Button size='mini' color='blue' onClick={this.props.setMode.bind(this,1)} icon style={{padding: '.5em'}}><Icon name='arrow left' /></Button>}
              />}
              {/* {mode !=2 && mode !=3 &&
              <Button size='mini' color='red' onClick={this.props.setMode.bind(this,3)} icon style={{padding: '.5em'}}><Icon name='plus' /></Button>} */}
              {mode ==1 && <Popup position= 'top right' inverted content='Show / Hide'
                trigger= {<Button size='mini' color='grey' onClick={this.showSearch.bind(this)} icon style={{padding: '.5em'}}><Icon name={searchMode==true? 'angle up' : 'angle down'} /></Button>}
              />}
            </div>
          </div>

          {mode==1 && searchMode && 
          <div style={{margin: '1em 0 0 0', border: '0px solid rgba(100,100,100,.2)'}}>
            <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Form.Group widths='16'>
                    <Field name='keyword' component={InputField} onChange= {this.onChangeInput.bind(this)}
                    width={7} 
                    placeholder= 'Search report ... <ENTER>' />
                    <Field name='workgroup' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'workgroup')}
                    width={3} 
                    options={listWorkgroup}
                    placeholder= 'Team / Workgroup' />
                    <Field name='startDate' component={DatePickerField} onChange= {this.onChangeDate.bind(this, 'startDate')}
                    width={3} 
                    type='date'
                    placeholder= 'Start Date' />
                    <Field name='endDate' component={DatePickerField} onChange= {this.onChangeDate.bind(this, 'endDate')}
                    width={3} 
                    type='date'
                    placeholder= 'End Date' />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.reportStore.ticketReport!=undefined && state.reportStore.ticketReport.progress==1 ? state.reportStore.ticketReport.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'RptTicketSearch',	// a unique identifier for this form
  })(RptTicketSearch)
  )
)