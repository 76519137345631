import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, TextArea, Form } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse, addSeconds } from "date-fns"

import Draggable, {DraggableCore} from 'react-draggable';
import OmniphoneContentOutbound from '../agent/OmniphoneContentOutbound';

import bg_home  from '../../assets/widget-bg.svg'
// import bg_banner  from '../../assets/stream-logo.svg'

import JsSIP from 'jssip'

import dialtone from '../../sound/tone.mp3'
import busytone from '../../sound/busy.mp3'
import ringtone from '../../sound/ring.mp3'
import jointone from '../../sound/join.mp3'

import OmniwidgetCall from './OmniwidgetCall';
import OmniwidgetChat from './OmniwidgetChat';
import OmniwidgetMenu from './OmniwidgetMenu';
import OmniwidgetRate from './OmniwidgetRate';
import OmniwidgetHome from './OmniwidgetHome';
import OmniwidgetBusy from './OmniwidgetBusy';
import OmniwidgetMessage from './OmniwidgetMessage';

import { connect } from "react-redux"
import { resetOmniwidget, navigationOmniwidget, bookOmniwidget, paramOmniwidget } from "../../actions/flow/omniwidgetAction"

const bg_banner= 'https://img.freepik.com/premium-vector/service-24-7-concept-call-center-support-vector-illustration-flat_186332-1024.jpg';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    omniwidgetStore: state.omniwidgetStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetOmniwidget:(objParam) => dispatch(resetOmniwidget(objParam)),
    navigationOmniwidget:(objParam) => dispatch(navigationOmniwidget(objParam)),
    bookOmniwidget:(objParam) => dispatch(bookOmniwidget(objParam)),
    paramOmniwidget:(objParam) => dispatch(paramOmniwidget(objParam)),
  }
}

var ua;
var session;
var sessions= new Map(), localVideoStream, remoteVideoStream;

const dial= new Audio(dialtone);
const busy= new Audio(busytone);
const ring= new Audio(ringtone);


class DialTone {
  play() {
    dial.loop= true
    dial.muted= false;
    dial.play();  
  }
  
  stop() {
    dial.pause();
  }

  mute() {
    dial.muted= true;
  }
}
class BusyTone {
  play() {
    busy.muted= false;
    busy.play();
  }
  
  stop() {
    busy.pause();
  }

  mute() {
    busy.muted= true;
  }
}
class RingTone {
  play() {
    ring.loop= true;
    ring.muted= false;
    ring.play();  
  }
  
  stop() {
    ring.muted= true;
    ring.pause();
  }
}

class Omniwidget extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sipAccount: null,
      // sipAccount: '7000000005',
      // sipPasswd: '111111',
      // sipHost: 'call.halopagi.com:4063',
      // sipRealm: 'halopagi',

      channel: 'Audio',

      statusUA: 0, //0: disconnected, 1: connecting, 2: connected, 3: registered, 4: no access / account not valid / FAILED
      phoneState: 'offline', //offline, connecting, online, incoming, dialing, oncall
      text: null,

      size: 'mini',
      mode: 'home',
      chats: [
        // {
        //   stamp: '2019-01-01 20:00',
        //   sender: 'cust',
        //   message: 'content message',
        // },
        // {
        //   stamp: '2019-01-01 20:00',
        //   sender: 'agent',
        //   message: 'content message',
        // },
      ],
      variable: null, //'CONTACT',
      command: null,
    };


    this.dial= new DialTone();
    this.busy= new BusyTone();
    this.ring= new RingTone();
  }

  sipRegister(account, passwd, host, realm) {
    var sipAccount, sipPasswd, sipHost, sipRealm;

    if (account!=null && passwd!=null && host!=null && realm!=null) {
      sipAccount= account
      sipPasswd= passwd
      sipHost= host
      sipRealm= realm
    } else {
      return;
      // sipAccount= this.state.sipAccount
      // sipPasswd= this.state.sipPasswd
      // sipHost= this.state.sipHost
      // sipRealm= this.state.sipRealm
    }

    this.setState({
      uiMode: [],
      statusUA: 1,
      chats: [],

      variable: null,
      command: null,
    })

    var socket = new JsSIP.WebSocketInterface("wss://"+ sipHost +"/");
    socket.via_transport = "udp";

    var configuration = {
      sockets  : [ socket ],
      uri      : "sip:"+ sipAccount +"@"+sipHost,
      password : sipPasswd,
      session_timers: false,
      iceServers: [],
      use_preloaded_route: true,
    };
    
    if (ua!=undefined) {
      ua.stop();
    } 

    ua = new JsSIP.UA(configuration);
    // console.log('!!! UA', ua)
    
    ua.start();
    this.sipEventHandler(ua)
  }
  
  sipUnregister() {
    // console.log('!!! UA', ua.isRegistered(), ua.isConnected(), ua)
    if (ua!=undefined) {
      // console.log('!!! UA', 'STOP')
      ua.stop();
      ua= undefined;
    }
  }

  sipEventHandler(ua) {
    const parent= this;

    //CONNECTION EVENT
    ua.on("connecting", function(data){
      console.log("!!! UA ON CONNECTING", data);
      parent.setState({
        statusUA: 1,
        phoneState: 'connecting',
      })
    });
    ua.on("connected", function(data){
      console.log("!!! UA ON CONNECTED", data);
      parent.setState({
        statusUA: 2,
        phoneState: 'connected',
      })
    });
    ua.on("disconnected", function(data){
      console.log("!!! UA ON DISCONNECTED", data);
      parent.setState({
        statusUA: 0,
        phoneState: 'offline',
      })
    });
    
    //CALL EVENT
    ua.on("newRTCSession", function(data){
      // console.log("!!! RTC SESSION EVENT ", data.session);
      // incoming call here
      session= data.session;
      // sessions.set(session.id, session)

      if (session.direction === "incoming") {
        console.log("!!! RTC SESSION INCOMING ");
        
        session.on("progress",function(e){
          // the call has answered
          console.log("!!! UA ON INCOMING - RTC PROGRESS");
          parent.setState({
            phoneState: 'incoming',
          })
          parent.ring.play()
          setTimeout(()=>{
            parent.callAnswer();
          }, 1000)
        });
        session.on("accepted",function(e){
            // the call has answered
            console.log("!!! UA ON INCOMING - RTC ACCEPTED");
            parent.setState({
              phoneState: 'oncall',
            })
        });
        session.on("confirmed",function(){
            // this handler will be called for incoming calls too
            console.log("!!! UA ON INCOMING - RTC CONFIRMED");
            parent.setState({
              phoneState: 'oncall',
            })
            parent.ring.stop();
        });
        session.on("peerconnection", function(e) {
          console.log('!!! UA ON INCOMING - RTC PEERCONNECTION: ', e)

          parent.addStream();
        });
        session.on("icecandidate", function(e) {
          if (e.Last) {
            console.log('!!! UA ON INCOMING - RTC ICE CANDIDATE: ', e)
            e.ready();
          }
        });
        session.on("sdp", function(e) {
          console.log('!!! UA ON INCOMING - RTC SDP: ', e)
        });
        session.on("newInfo", function(e) {
          console.log('!!! UA ON INCOMING - RTC INFO: ', e)
        });
        session.on("newDTMF", function(e) {
          console.log('!!! UA ON INCOMING - RTC DTMF: ', e)
        });
        session.on("failed",function(e){
            // unable to establish the call
            console.log("!!! UA ON INCOMING - RTC FAILED", e);
            if (e.originator=='remote' && e.cause=="Canceled") {
              parent.setState({
                statusUA: 2,
                phoneState: 'online',
              })
            } else if (e.originator=='local' && e.cause=="Rejected") {
              parent.setState({
                statusUA: 2,
                phoneState: 'online',
              })
            }
            // parent.setState({
            //   // phoneState: 'online',
            // })
            session= undefined;
            parent.ring.stop();

            remoteVideoStream= null;
            localVideoStream= null;
        });
        session.on("ended",function(){
            // the call has ended
            console.log("!!! UA ON INCOMING - RTC ENDED");
            parent.setState({
              phoneState: 'online',
            })
            session= undefined
            parent.ring.stop();
            parent.busy.play();

            remoteVideoStream= null;
            localVideoStream= null;
        });
      } else if (session.direction === "outgoing") {
        console.log("!!! RTC SESSION OUTGOING ");
        console.log("!!! UA ON OUTGOING - ADD STREAM");
        
        parent.addStream();
      }
    });

    //MESSAGE EVENT
    ua.on("newMessage", function(data){
      // console.log("!!! TEXTING EVENT", data.message.direction);
      if (data.message.direction=='incoming') { //INCOMING
        parent.textReceive(data.message, data.request);
      } else { //OUTGOING

      }
    });

    //SIP EVENT
    ua.on("sipEvent", function(data){
      console.log("!!! UA ON SIP EVENT", data);
    });

    //REGISTERED EVENT
    ua.on("registered", function(){
      console.log("!!! UA ON REGISTERED");
      parent.setState({
        statusUA: 3,
        phoneState: 'online',
      })
    });
    ua.on("unregistered", function(data){
      console.log("!!! UA ON UNREGISTERED", data);
      parent.setState({
        statusUA: 0,
        phoneState: 'offline',
      })
    });
    ua.on("registrationFailed", function(data){
      console.log("!!! UA ON REGISTRATION FAILED", data);
      parent.setState({
        statusUA: 4,
        phoneState: 'offline',
      })
    });
  }

  textSend(message, type) {
    const parent= this
    const { chats }= this.state
    const { agentExten, agentId, clientId }= (this.props.omniwidgetStore.bookOmniwidget!=undefined && this.props.omniwidgetStore.bookOmniwidget.progress=='1' ? this.props.omniwidgetStore.bookOmniwidget.data : {})

    // console.log('!!! TEXT SEND', message, agentExten, agentId, clientId)

    var textingEventHandlers = {
      'succeeded': function(e){
        console.log('!!! UA TEXTING ON SUCCEEDED: ',e);
        chats.push({
          stamp_: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), //'2019-01-01 20:00',
          stamp: format(new Date(), 'HH:mm:ss'),
          sender: 'cust',
          message: message,
          status: '1',
        })
        parent.setState({
          chats: chats,
        })
      },
      'failed':    function(e){ 
        console.log('!!! UA TEXTING ON FAILED: ',e);
        chats.push({
          stamp_: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), //'2019-01-01 20:00',
          stamp: format(new Date(), 'HH:mm:ss'),
          sender: 'cust',
          message: message,
          status: '-1',
        })
        parent.setState({
          chats: chats,
        })
      }
    };

    var textingOptions = {
      'eventHandlers': textingEventHandlers
    };
    // chats.push({
    //   stamp_: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), //'2019-01-01 20:00',
    //   stamp: format(new Date(), 'HH:mm:ss'),
    //   sender: 'cust',
    //   message: message,
    //   status: '0',
    // })
    // this.setState({
    //   chats: chats,
    // })
    // ua.sendMessage(terminating +'@174.138.19.28', text, textingOptions);
    const jsonMessage= {
      type: '0x01', //0x01 => plain, 0x02=> plain automation, 0X03=>variable,
      session: '',
      body: message,
    }
    
    ua && ua.sendMessage(agentExten, JSON.stringify(jsonMessage), textingOptions);
  }

  textReceive(message, request) {
    const { chats }= this.state
    const mesg= JSON.parse(request.body);
    // console.log('!!! TEXT RECEIVE', mesg)
    
    if (mesg.type=='0x01') { //0x01 => plain, 0x02=> plain automation, 0X03=>variable, 0x04=>command
      chats.push({
        stamp_: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), //'2019-01-01 20:00',
        stamp: format(new Date(), 'HH:mm:ss'),
        sender: 'agent',
        message: mesg.body,
        status: '1',
      })
      this.setState({
        chats: chats,
      })
    } else if (mesg.type=='0x03') {
      chats.push({
        stamp_: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), //'2019-01-01 20:00',
        stamp: format(new Date(), 'HH:mm:ss'),
        sender: 'agent',
        message: mesg.descript,
        status: '1',
      })
      this.setState({
        variable: mesg.body,
        chats: chats,
      })
    } else if (mesg.type=='0x04') {
      chats.push({
        stamp_: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), //'2019-01-01 20:00',
        stamp: format(new Date(), 'HH:mm:ss'),
        sender: 'agent',
        message: mesg.descript,
        status: '1',
      })
      this.setState({
        command: mesg.body,
        chats: chats,
      })
    } else {

    }
  }

  dtmfSend(tone) {
    // var tones = '1234#';
    // console.log('!!! DTMF', tone);

    if (session && tone && tone!='') {
      session.sendDTMF(tone, {
        'duration': 160,
        'interToneGap': 1200,
        'extraHeaders': [ 'X-Foo: foo', 'X-Bar: bar' ]
      })
    }
  }

  variableSend(values, variable, isCanceled) {
    if (isCanceled) {
      //reset
      //set variable to null
      this.setState({
        variable: null,
      })
      this.textSend('No thanks, not sending '+ variable);
    } else {
      this.textSend('Sending '+ variable);
      this.setState({
        variable: null,
      })
    }
  }

  addStream() {
    // session.connection.onaddstream = function(e) {
    session.connection.addEventListener('addstream',function(e) {
      // console.log("!!! CALL ADD STREAM");
      const remoteAudio = new window.Audio()
      remoteAudio.srcObject = e.stream
      remoteAudio.play();

      // const localView = document.getElementById('localView');
      // const remoteView = document.getElementById('remoteView');
      
      // if (remoteView) {
      //   remoteView.srcObject = (e.stream);
      // }
      // if (localView) {
      //   localView.srcObject = (session.connection.getLocalStreams()[0]);
      // }

      remoteVideoStream= (e.stream);
      localVideoStream= (session.connection.getLocalStreams()[0]);
    })
  }

  callAnswer() {
    const callOptions = {
      extraHeaders: [],
      // mediaConstraints: { audio: true, video: false },
      mediaConstraints : { audio: true, video: this.state.channel=='Video' },
      // mediaConstraints : { audio: true, video: true, screen: true },
    }
    
    try {
      if (session && !session.isEnded())  {
        session.answer(callOptions)
        // session.answer()
        this.setState({
          phoneState: 'oncall',
        })
        console.log('!!! INCOMING - SESSION ANSWER')
      } else {
        this.setState({
          phoneState: 'online',
        })
      }
    }
    catch(err) {
      console.log('ERROR ANSWER CALL', err)
    }
  }
  
  callDial(terminating) {
    const parent= this;

    //CALL TERMINATING NUMBER
    this.dial.play()

    const callEventHandlers = {
      'connecting': function(e) {
        console.log('!!! UA ON OUTGOING - CONNECTING: ', e);
        parent.setState({
          phoneState: 'connecting',
        })
      },
      'progress': function(e) {
        console.log('!!! UA ON OUTGOING - PROGRESS: ', e);
        parent.setState({
          phoneState: 'dialing',
        })
      },
      'confirmed': function(e) {
        console.log('!!! UA ON OUTGOING - CONFIRMED: ', e);
        parent.setState({
          phoneState: 'oncall',
        })

        parent.dial.stop()
      },
      'icecandidate': function(e) {
        console.log('!!! UA ON OUTGOING - ICE CANDIDATE: ', e)
        // n(e.candidate.candidate);
        // if (e.Last) {
        //   e.ready();
        // }
      },
      'sdp': function(e) {
        console.log('!!! UA ON OUTGOING - SDP: ', e)
        // n(e.candidate.candidate);
        // if (e.Last) {
        //   e.ready();
        // }
      },
      'newInfo': function(e) {
        console.log('!!! UA ON OUTGOING - NEW INFO: ', e)
        // n(e.candidate.candidate);
        // if (e.Last) {
        //   e.ready();
        // }
      },
      'newDTMF': function(e) {
        console.log('!!! UA ON OUTGOING - NEW DTMF: ', e)
        // n(e.candidate.candidate);
        // if (e.Last) {
        //   e.ready();
        // }
      },
      'ended': function(e) {
        console.log('!!! UA ON OUTGOING - ENDED: ', e);
        parent.setState({
          phoneState: 'online',
        })
        session= undefined;
        parent.ring.stop();
        parent.busy.play();

        remoteVideoStream= null;
        localVideoStream= null;
      },
      'failed': function(e) {
        console.log('!!! UA ON OUTGOING - FAILED: ', e);
        if (e.originator=='remote' && (e.cause && e.cause.toLowerCase()=="canceled")) {
          parent.setState({
            statusUA: 2,
            phoneState: 'online',
          })
        } else if (e.originator=='local' && (e.cause && e.cause.toLowerCase()=="rejected")) {
          parent.setState({
            statusUA: 2,
            phoneState: 'online',
          })
        } else {
          // parent.setState({
          //   statusUA: 2,
          //   phoneState: 'online',
          // })
        }
        
        session= undefined
        parent.dial.mute();
        parent.busy.play();

        remoteVideoStream= null;
        localVideoStream= null;
      },
    };
    const callOptions = {
      eventHandlers: callEventHandlers,
      // mediaConstraints: { audio: true, video: false },
      mediaConstraints : { audio: true, video: true },
      // mediaConstraints : { audio: true, video: true, screen: true },
    }
    
    // session = ua.call('sip:'+ terminating +'@174.138.19.28:5060', callOptions);
    // session = ua.call('sip:'+ terminating +'@202.52.48.75:5160', callOptions);
    session = ua.call('sip:'+ terminating , callOptions);
  }

  callHangup() {
    if (session) {
      session.terminate();
    } else {
      this.setState({
        phoneState: 'online',
      })
    }
    // sessions.forEach((session, key)=>{
    //   session.terminate();
    // })
  }

  setMode(mode, level, navigations) {
    console.log('!!! SET MODE', mode,'LEVEL', level, 'NAVIGATION', navigations)
    const {phoneState }= this.state 

    if (((!mode && this.state.mode && (this.state.mode=='chat' || this.state.mode=='call')) || (mode && (mode=='chat' || mode=='call'))) && phoneState=='offline') {
      this.props.bookOmniwidget({
        // apiKey: '1111-3434-9085-3227',
        level: level || this.state.level || null,
        
        responsed: (navigations && navigations.responsed) || (this.state.navigations && this.state.navigations.responsed) || null,
        workgroups: (navigations && navigations.dispatches) || (this.state.navigations && this.state.navigations.dispatches) || null,
      });

      this.setState({
        mode: mode || this.state.mode, 

        level: (level ? level : this.state.level), 
        navigations: (navigations ? navigations : this.state.navigations),
      })
    } else if (mode=='rate') {
      this.setState({
        mode: mode,

        level: null,
        navigations: null,

        sipAccount: null, 
        sipPasswd: null,
        sipHost: null,
        sipRealm: null,
      })
      this.sipUnregister();
    } else if (mode=='home') {
      this.setState({
        mode: mode,

        level: null,
        navigations: null,

        sipAccount: null, 
        sipPasswd: null,
        sipHost: null,
        sipRealm: null,
      })
      this.props.resetOmniwidget();
      this.sipUnregister();
    } else {
      this.setState({
        mode: mode || 'home',

        level: null,
        navigations: null,

        sipAccount: null, 
        sipPasswd: null,
        sipHost: null,
        sipRealm: null,
      })
      this.props.resetOmniwidget();
      this.sipUnregister();
    }
  }

  setSize() {
    this.setState({
      size: this.state.size=='mini' ? 'restore' : 'mini',
    })
  }

  chatClose() {
    this.sipUnregister();
  }

  componentDidMount() {
    this.setState({
      size: (this.props.params && this.props.params.size) || 'mini',
    })

    this.props.paramOmniwidget({
      // apiKey: '1111-3434-9085-3227'
    })

    this.props.resetOmniwidget();
    this.props.navigationOmniwidget({
      // apiKey: '1111-3434-9085-3227'
    })
  }
  
  componentDidUpdate(prevProps) {
    const {sipAccount, sipPasswd,sipHost,sipRealm }= this.state 
    const { data }= (this.props.omniwidgetStore.bookOmniwidget!=undefined && this.props.omniwidgetStore.bookOmniwidget.progress=='1' ? this.props.omniwidgetStore.bookOmniwidget : {})

    if (prevProps.params && this.props.params && prevProps.params.size!=this.props.params.size) {
      this.setState({
        size: this.props.params.size || 'mini',
      })
    }
    if (sipAccount==null && data && data.sipAccount!=null) {
      // console.log('SIP ACCOUNT', data)
      this.setState({
        sipAccount: data.sipAccount,
        sipPasswd: data.sipPasswd, 
        sipHost: data.sipHost, 
        sipRealm: data.sipRealm,
        sipPort: data.sipPort,
        // sipAccount: '62999333002',
        // sipPasswd: '222222',
        // sipHost: 'call.halopagi.com:4063',
        // sipRealm: 'halopagi',
      })

      // this.sipRegister('62999333002', '222222', 'call.halopagi.com:4063', 'halopagi');
      this.sipRegister(data.sipAccount, data.sipPasswd, data.sipHost +':'+ data.sipPort, data.sipRealm);
    }
  }
  
  componentWillUnmount() {
    // console.log('!!! WILLUNMOUNT')
    this.sipUnregister();
  }
    
  // 1. PICK A CALL OR MESSAGE
  // 2. IF RESPONSED BY MACHINE THEN NO AGENT IS REQUIRED 
  // 2. IF RESPONSED BY AGENT CHECK IF AGENT IS AVAILABLE
  // 3. IF AGENT IS NOT AVAILABLE, CUSTOMER COULD POST MESSAGE AND GIVE OPTIONS FOR CONTACT CHANNELS (VOICE, SMS, WA, SOCMED)
  // 4. IF AGENT IS AVAILABLE, PICK A DIRECT CALL OR DIRECT MESSAGE, WHEN CALL OR MESSAGE SENT DISPATCH TO AGENT
  // 5. IF DISPATCHED AGENT IS BUSY NOTIFY CUSTOMER TO SEND MESSAGE AND GIVE OPTIONS FOR CONTACT CHANNELS (VOICE, SMS, WA, SOCMED)
  // SECUTIRY CONCERN FOR CONTACT'S FLOODING PREVENTIVE
  render() {
    const { mode, size , chats, phoneState, variable, command}= this.state
    const { screenWidth, screenHeight }= this.props
    
    const params= (this.props.omniwidgetStore.paramOmniwidget!=undefined && this.props.omniwidgetStore.paramOmniwidget.progress=='1' ? this.props.omniwidgetStore.paramOmniwidget.data : null)
    const { data, progress }= (this.props.omniwidgetStore.bookOmniwidget!=undefined && this.props.omniwidgetStore.bookOmniwidget.progress=='1' ? this.props.omniwidgetStore.bookOmniwidget : {})

    // console.log('!!!! CHATS', chats, params)
    
    return(
      <div style={{...((this.props.params && this.props.params.style) || {}), ...(!this.props.dummy ? {width: (params && params.widgetWidth) || this.props.params.width || 275, ...(params && params.widgetPosition=='left' ? {left: 0} : {right: 0})} : {})}}>
        {size=='restore' &&
          <div style={{display: 'flex', flexDirection: 'column', borderRadius:'2em 2em 0 0', minHeight: (mode=='home' ? 500 : 250), backgroundImage: 'url('+ ((params && params.widgetBackground) || (this.props.params && this.props.params.background) || bg_home) +')', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundColor: '#ffd400', backgroundSize: 'cover'}}>
            <div style={{flex: '1', padding: '.7em'}}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                {mode!='home' && <Button circular type='button' style={{padding: '.5em'}} onClick={this.setMode.bind(this, 'home')}><Icon name='arrow left' style={{margin: '0'}} /></Button>}
                </div>
                <div style={{flex: '1', textAlign: 'right'}}>
                  {/* <Button circular type='button' style={{padding: '.5em'}} onClick={this.setMode.bind(this, 'home')}><Icon name='ellipsis vertical' style={{margin: '0'}} /></Button> */}
                  {mode=='home' && <Button circular type='button' style={{padding: '.5em'}} onClick={this.setSize.bind(this)}><Icon name={size=='mini' ? 'window maximize outline' : 'minus'} style={{margin: '0'}} /></Button>}
                </div>
              </div>
            </div>
            {mode=='home' && <div style={{background: 'rgba(90, 90, 90, .9)', borderRadius: '1em 1em 0 0'}}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{background: 'rgba(255, 255, 255, .95)', padding: '0 1em', margin: '-10em 1em 0 1em', borderRadius: '3em 3em 0  0'}}>
                  <div style={{flex: '1', padding: '1em', textAlign: 'center'}}>
                    <Header as='h4' icon style={{margin: '0'}}>
                    {(params && params.avatar && <Image size='big' circular src={params.avatar} />) || <Icon name='user circle' />}
                      <Header.Content>
                        {(params && params.brand) || 'CORPORATE BRAND'}
                        <Header.Subheader>{(params && params.descript) || 'Corporate description / tagline'}</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                </div>
                <div style={{background: 'rgba(255, 255, 255, .95)', margin: '0 1em 1em 1em', borderRadius: '0 0 2em 2em'}}>
                  <div style={{margin: '1em', height: '20em', borderRadius: '1em', background: '#eee', backgroundImage: 'url('+ ((params && params.widgetBanner) || (this.props.params && this.props.params.banner) || bg_banner) +')', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover'}} />
                </div>
              </div>
            </div>}
          </div>
        }
        {size=='mini' && 
          <div style={{display: 'flex', flexDirection: 'column', borderRadius:'2em 2em 0 0', minHeight: (mode=='home' ? 175 : 250), backgroundImage: 'url('+ ((params && params.widgetBackground) || (this.props.params && this.props.params.background) || bg_home) +')', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundColor: '#ffd400', backgroundSize: 'cover'}}>
            <div style={{flex: '1', padding: '.7em'}}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                {mode!='home' && <Button circular type='button' style={{padding: '.5em'}} onClick={this.setMode.bind(this, 'home')}><Icon name='arrow left' style={{margin: '0'}} /></Button>}
                </div>
                <div style={{flex: '1', textAlign: 'right'}}>
                  {/* <Button circular type='button' style={{padding: '.5em'}} onClick={this.setMode.bind(this, 'home')}><Icon name='ellipsis vertical' style={{margin: '0'}} /></Button> */}
                  {mode=='home' && <Button circular type='button' style={{padding: '.5em'}} onClick={this.setSize.bind(this)}><Icon name={size=='mini' ? 'window maximize outline' : 'minus'} style={{margin: '0'}} /></Button>}
                </div>
              </div>
            </div>
            {mode=='home' && <div style={{background: 'rgba(90, 90, 90, .9)', borderRadius: '1em 1em 0 0'}}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{background: 'rgba(255, 255, 255, .95)', padding: '0 1em', margin: '-3em 1em 0 1em', borderRadius: '1em 1em 0  0'}}>
                  <div style={{flex: '1', padding: '1em .5em', textAlign: 'center'}}>
                    <Header as='h4' icon style={{margin: '0'}}>
                      {/* <Icon name='user circle' /> */}
                      <Header.Content>
                        {(params && params.brand) || 'CORPORATE BRAND'}
                        <Header.Subheader>{(params && params.descript) || 'Corporate description / tagline'}</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        }

        <div style={{background: '#52504b', padding: '1em', borderRadius: '0 0 2em 2em'}}>
          <div style={{}}>
            {mode=='home' && <OmniwidgetHome 
              screenWidth={screenWidth} screenHeight={screenHeight}
              setMode={this.setMode.bind(this)} params={params} 
            />}
            {mode=='menu' && <OmniwidgetMenu 
              screenWidth={screenWidth} screenHeight={screenHeight}
              setMode={this.setMode.bind(this)} params={params} 
            />}
            
            {mode=='chat' && 
            progress==1 && data && data.sipAccount && (data.agentId>0 || data.responsed=='Machine') && 
            <OmniwidgetChat 
              screenWidth={screenWidth} screenHeight={screenHeight}
              level={this.state.level} chats={chats} phoneState={phoneState} textSend={this.textSend.bind(this)} variableSend={this.variableSend.bind(this)} chatClose={this.chatClose.bind(this)} booked={data} variable={variable} command={command} setMode={this.setMode.bind(this)} 
            />}
            {mode=='call' && 
            progress==1 && data && data.sipAccount && (data.agentId>0 || data.responsed=='Machine') && 
            <OmniwidgetCall
              channel= {this.state.channel}
              remoteVideoStream= {remoteVideoStream} localVideoStream={localVideoStream}
              screenWidth={screenWidth} screenHeight={screenHeight}
              level={this.state.level} phoneState={phoneState} callDial={this.callDial.bind(this)} callHangup={this.callHangup.bind(this)} dtmfSend={this.dtmfSend.bind(this)} booked={data} setMode={this.setMode.bind(this)} 
            />}

            {mode=='message' && <OmniwidgetMessage 
              screenWidth={screenWidth} screenHeight={screenHeight}
              params={params || this.props.params} reason={(data && !data.sipAccount && 'Guest account is limited') || (data && data.agentId==0 && 'Agent is not availabe')} level={this.state.level} phoneState={phoneState} booked={data} setMode={this.setMode.bind(this)} 
            />}
            
            {mode=='rate' && <OmniwidgetRate 
              params={params || this.props.params} booked={data} setMode={this.setMode.bind(this)} 
            />}
            
            {((mode=='chat' || mode=='call') && progress==1 && data && (!data.sipAccount || (data.agentId==0 && data.responsed!='Machine'))) && 
            <OmniwidgetBusy 
              screenWidth={screenWidth} screenHeight={screenHeight}
              setMode={this.setMode.bind(this)} 
            />}
            {mode=='busy' && <OmniwidgetBusy 
              screenWidth={screenWidth} screenHeight={screenHeight}
              params={params || this.props.params} setMode={this.setMode.bind(this)} 
            />}
            
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Omniwidget)