import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetAutomation(objParam) {
  return {
      type: 'AUTOMATION_RESET',
      payload: objParam
  }
}

export function getAutomation(objParam) {
  return {
      type: 'AUTOMATION_GET',
      payload:
        axios.post(
          urlAPI + 'service/automation/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusAutomation(objParam) {
  return {
      type: 'AUTOMATION_STATUS',
      payload:
        axios.post(
          urlAPI + 'service/automation/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseAutomation(objParam) {
  return {
      type: 'AUTOMATION_BROWSE',
      payload:
        axios.post(
          urlAPI + 'service/automation/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createAutomation(objParam) {
  return {
      type: 'AUTOMATION_CREATE',
      payload:
        axios.post(
          urlAPI + 'service/automation/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateAutomation(objParam) {
  return {
      type: 'AUTOMATION_UPDATE',
      payload:
        axios.post(
          urlAPI + 'service/automation/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeAutomation(objParam) {
  return {
      type: 'AUTOMATION_REMOVE',
      payload:
        axios.post(
          urlAPI + 'service/automation/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
