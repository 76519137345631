import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'

import { groupOptions, conditionOptions }  from '../../modules/service/StaticMaster'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, SearchField, LabelNumberField, LabelSelectField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetSla, getSla,updateSla, createSla } from "../../actions/service/slaAction"
import { listUser, listWorkgroup, listTemplate, listOrganization, listProduct, listChannel, listClassification, listOfficeHour } from "../../actions/service/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('SlaForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    slaStore: state.slaStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listUser:(objParam) => dispatch(listUser(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),

    listTemplate:(objParam) => dispatch(listTemplate(objParam)),
    listOrganization:(objParam) => dispatch(listOrganization(objParam)),
    listProduct:(objParam) => dispatch(listProduct(objParam)),
    listChannel:(objParam) => dispatch(listChannel(objParam)),
    listClassification:(objParam) => dispatch(listClassification(objParam)),
    listOfficeHour:(objParam) => dispatch(listOfficeHour(objParam)),
    
    resetSla:(objParam) => dispatch(resetSla(objParam)),
    getSla:(objParam) => dispatch(getSla(objParam)),
    createSla:(objParam) => dispatch(createSla(objParam)),
    updateSla:(objParam) => dispatch(updateSla(objParam)),
  }
}

class SlaForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount(){
    this.props.listUser()
    this.props.listWorkgroup()

    this.props.listTemplate()
    this.props.listOrganization()
    this.props.listProduct()
    this.props.listChannel()
    this.props.listClassification()
    this.props.listOfficeHour()
    
    this.props.resetSla(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getSla({slaId: this.props.slaId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.slaStore.updateSla!=undefined ? this.props.slaStore.updateSla : {}) : (this.props.slaStore.createSla!=undefined ? this.props.slaStore.createSla : {}))

    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateSla({
      ...values,
      targetSlaQty: this.props.targetSlaQty,
    }) : this.props.createSla({
      ...values, 
      targetSlaQty: this.props.targetSlaQty,
    })
  }

  incrRowQty(isIncrease, kind) {
    console.log(kind, this.props[kind +'SlaQty'])
    if (isIncrease) {
      if (this.props[kind +'SlaQty']<10) {
        this.props.dispatch(change('SlaForm', kind +'SlaQty', ((isNaN(this.props[kind +'SlaQty']) ? 0 : parseInt(this.props[kind +'SlaQty'])) +1)))
      }
    } else {
      if (this.props[kind +'SlaQty']>1) {
        this.props.dispatch(change('SlaForm', kind +'SlaQty', ((isNaN(this.props[kind +'SlaQty']) ? 0 : parseInt(this.props[kind +'SlaQty'])) -1)))
      }
    }
  }

  drawSla(qty=1, kind) {
    const slas= this.props[kind +'Slas'] || []
    
    // console.log('!!! CONDITIONS DRAW', conditionsQty)
    const items= [];
    for (var i=0; i<qty; i++) {
      items[i]= {
        item: slas[i] ? slas[i].item : null,
      };
    }
    return (<>
      {
        items.map((item, i)=>{
          return(<Segment>
            <Form.Group widths='16'>
              <Field name={'slaType_'+ i} component={LabelSelectField}
              width={16}
              validate= {[required]}
              options={conditionOptions('sla-param')}
              label='SLA'
              placeholder='SLA target type / param' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name={'slaUrgent_'+ i} component={LabelNumberField}
              validate= {[required]}
              label='Mins'
              placeholder='Urgent' />
              <Field name={'slaHigh_'+ i} component={LabelNumberField}
              validate= {[required]}
              label='Mins'
              placeholder='High' />
              <Field name={'slaNormal_'+ i} component={LabelNumberField}
              validate= {[required]}
              label='Mins'
              placeholder='Normal' />
              <Field name={'slaLow_'+ i} component={LabelNumberField}
              validate= {[required]}
              label='Mins'
              placeholder='Low' />
            </Form.Group>
          </Segment>)
        })
      }
    </>)
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.slaStore.getSla!=undefined && this.props.slaStore.getSla.progress=='1' ? this.props.slaStore.getSla : {})
    const { progress, code, text }= (mode==2 ? (this.props.slaStore.updateSla!=undefined ? this.props.slaStore.updateSla : {}) : (this.props.slaStore.createSla!=undefined ? this.props.slaStore.createSla : {}))

    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    const listUser= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])

    const listTemplate= (this.props.masterStore.listTemplate!=undefined && this.props.masterStore.listTemplate.progress=='1' ? this.props.masterStore.listTemplate.data : [])
    const listOrganization= (this.props.masterStore.listOrganization!=undefined && this.props.masterStore.listOrganization.progress=='1' ? this.props.masterStore.listOrganization.data : [])
    const listProduct= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])
    const listChannel= (this.props.masterStore.listChannel!=undefined && this.props.masterStore.listChannel.progress=='1' ? this.props.masterStore.listChannel.data : [])
    const listClassification= (this.props.masterStore.listClassification!=undefined && this.props.masterStore.listClassification.progress=='1' ? this.props.masterStore.listClassification.data : [])
    const listOfficeHour= (this.props.masterStore.listOfficeHour!=undefined && this.props.masterStore.listOfficeHour.progress=='1' ? this.props.masterStore.listOfficeHour.data : [])

    return (
      <div style={{padding: '1em 1.5em'}}>
        <div style={{position: 'absolute', marginTop: '-3em', right: '1.7em', display: 'inline-block', background: '#222', padding: '.2em .5em .6em .6em', borderRadius: '.5em'}}><Icon name='window close' color='yellow' style={{cursor: 'pointer', padding: '0', margin: '0', fontSize: '2em'}} onClick={this.props.closeModal.bind(this, pristine)}/></div>
        
        <Header as='h4' style={{margin: '0 0 2em 0'}}>
          <Icon name='time' style={{fontSize: '1em'}}/>
          <Header.Content>
            Service Level / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
          </Header.Content>
        </Header>

        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Grid stackable doubling>
            <Grid.Row columns={16}>
              <Grid.Column width={6}>
                <Header as='h5'>
                  <Header.Content>
                    SLA Title
                    <Header.Subheader>Short description of SLA target.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='name' component={InputField}
                    validate= {[required]}
                    label='Name'
                    placeholder='Name' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='descript' component={TextAreaField}
                    label='Description'
                    placeholder='Additional information' />
                  </Form.Group>
                </Segment>

                <Header as='h5'>
                  <Header.Content>
                    Service Association
                    <Header.Subheader>Related service / product / brand and segmentation.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='products' component={SelectField}
                    multiple
                    options={listProduct}
                    label='Associated Brand'
                    placeholder='Related brand, service or product' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='channels' component={SelectField}
                    multiple
                    options={listChannel}
                    label='Comm. Chanel / Stream'
                    placeholder='Channel contact socmed, phone etc' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='classifications' component={SelectField}
                    multiple
                    options={listClassification}
                    label='Classification'
                    placeholder='Ticket classification' />
                  </Form.Group>
                  {/* <Form.Group widths='equal'>
                    <Field name='segments' component={SelectField}
                    multiple
                    label='Customer Segment'
                    placeholder='Customer segmentations / clusters' />
                  </Form.Group> */}
                  <Form.Group widths='equal'>
                    <Field name='businessHour' component={SelectField}
                    options={listOfficeHour}
                    label='Office / Business Hours'
                    placeholder='Operation / support business hours' />
                  </Form.Group>
                </Segment>

                {false && <>
                  <Header as='h5'>
                    <Header.Content>
                      SLA On Breach Actions !
                      <Header.Subheader>SLA on breach escalation action. to certain users or teams.</Header.Subheader>
                    </Header.Content>
                  </Header>
                  <Segment>
                    <Form.Group widths='equal'>
                      <Field name='scriptId' component={SelectField}
                      options={listTemplate}
                      label='Send Content Notification'
                      placeholder='Content template script' />
                    </Form.Group>
                    {this.props.scriptId && <Form.Group widths='equal'>
                      <Field name='scriptChannels' component={SelectField}
                      multiple
                      options={listChannel}
                      // label='Notification Channel'
                      validate= {[required]}
                      placeholder='Send notification through these channels' />
                    </Form.Group>}
                  </Segment>
                  {this.props.scriptId && <>
                    <Segment>
                      <Form.Group widths='equal'>
                        <Field name='workgroups' component={SelectField}
                        multiple
                        options={listWorkgroup}
                        label='Send to: Teams / Workgroups'
                        placeholder='Certain list of teams' />
                      </Form.Group>
                    </Segment>
                    <Segment>
                      <Form.Group widths='equal'>
                        <Field name='groups' component={SelectField}
                        multiple
                        options={groupOptions()}
                        label='Send to: Roles / Groups'
                        placeholder='Certain list of roles / groups' />
                      </Form.Group>
                      <Form.Group widths='equal'>
                        <Field name='users' component={SelectField}
                        multiple
                        options={listUser}
                        label='Send to: Users / Accounts'
                        placeholder='Certain list of users / accounts' />
                      </Form.Group>
                    </Segment>
                  </>}
                </>}
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as='h5'>
                  <Header.Content>
                    SLA Param Definitions 
                    <Header.Subheader>Set service level definitions and terms of ticket.</Header.Subheader>
                  </Header.Content>
                </Header>
                {this.drawSla(this.props.targetSlaQty, 'target')}

                <div style={{textAlign: 'center', marginTop: '2em'}}>
                  <Button.Group size='mini'>
                    <Popup content='Remove / less attributes'  position= 'bottom center'
                    trigger={
                      <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, false, 'target')}><Icon color='red' name='arrow up' size='large' /></Button>
                    }
                    inverted />
                    <Button.Or></Button.Or>
                    <Popup content='Add more attributes'  position= 'bottom center'
                    trigger={
                      <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, true, 'target')}><Icon color='blue' name='arrow down' size='large' /></Button>
                    }
                    inverted />
                  </Button.Group>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Sla' : 'Insert Sla') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.slaStore.getSla!=undefined && state.slaStore.getSla.progress==1 ? state.slaStore.getSla.data : {targetSlaQty: 1}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      targetSlaQty: selector(state, 'targetSlaQty'),
      scriptId: selector(state, 'scriptId'),
    }),
    { load: getSla }
  )
  (reduxForm({
    form: 'SlaForm',	// a unique identifier for this form
  })(SlaForm)
  )
)
