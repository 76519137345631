
import React from "react"
import {ResponsiveContainer,
  Legend, Tooltip, Cell,
  ComposedChart,
  PieChart, Pie,
  Line, Bar, Area,CartesianGrid, XAxis, YAxis, } from 'recharts'

const scheme= ['#0476E9', '#039AE2', '#008631', '#30B45D', '#a78100', '#F59D00', '#FA5D00', '#F101A8', '#C6D029', '#EE8223']

const dataDummy = [
  {name: 'Group A', value: 400, amount: 120, qty: 150}, 
  {name: 'Group B', value: 300, amount: 100, qty: 150},
  {name: 'Group C', value: 300, amount: 170, qty: 150}, 
  {name: 'Group D', value: 200, amount: 100, qty: 150}
]
const legendDummy= {
  areas: [
    {id: 'value', name: 'Value'}, 
  ],
  lines: [
    {id: 'amount', name: 'Amount'}, 
  ],
  bars: [
    {id: 'qty', name: 'Quantity'}, 
  ]
}

class ComposedRechart extends React.Component {
	render () {
    const { data, legend, width, height, yAxis, mode, fill } = this.props
  	return (
      <div>
        <div style={{ width: width || 'auto', height: height || 250, margin: '1em auto' }}>
          <ResponsiveContainer>
            <ComposedChart data={data || dataDummy} height={ height || 250} margin={{top: 5, right: 30, left: yAxis!==false ? 5 : 30, bottom: 20}}>
              <XAxis dataKey='name'  scale="point" />
                {(yAxis!==false) && <YAxis/>}
              <Tooltip />
              <Legend iconSize={10} />
              <CartesianGrid stroke="#eee" />
              {
                (legend || legendDummy).areas!=undefined && (legend || legendDummy).areas.map((value, i)=><Area dataKey={value.id} fill={scheme[i]} stroke='#aaa' />)
              }
              {
                (legend || legendDummy).bars!=undefined && (legend || legendDummy).bars.map((value, i)=><Bar dataKey={value.id} barSize={60} fill={scheme[3+i]} stroke='#aaa' />)
              }
              {
                (legend || legendDummy).lines!=undefined && (legend || legendDummy).lines.map((value, i)=><Line dataKey={value.id} stroke={scheme[5+i]} dot={{r: 10}} activeDot={{r: 15}} strokeWidth={5} padding={[10, 10]}/>)
              }
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

export default ComposedRechart;
