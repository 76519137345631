import React from 'react'

import { Link } from 'react-router-dom'
import { Form, Select, Radio, Label, Container, Divider, Dropdown, Grid, Header, Image, List, Menu, Segment, Button } from 'semantic-ui-react'

import BrandLogo from '../libraries/common/BrandLogo'

class AuthenticateOvisoneHeader extends React.Component {
    constructor(props) {
      super(props)
      this.state= {
        
      }
    }

    render() {
      return(
        <div className='header-container'>
          <Menu style={{boxShadow: 'none'}}>
            <Container style={{height: '3em'}}>
              <Grid style={{width: '100%', margin: '0 0 0 0'}}>
                <Grid.Row columns='16' style={{padding: '.4em 0 0 0'}}>
                  <Grid.Column width='3' style={{padding: '0 0 0 0'}}>
                    <BrandLogo />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Menu>
        </div>
      )
    }
} export default AuthenticateOvisoneHeader
