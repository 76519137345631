import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Popup,
} from 'semantic-ui-react'

import { groupOptions }  from '../../modules/service/StaticMaster'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, SelectField, DatePickerField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { browseForms } from "../../actions/service/formsAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    formsStore: state.formsStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseForms:(objParam) => dispatch(browseForms(objParam)),
  }
}


class FormsSearch extends Component {
  constructor(props) {
    super(props)
    this.state= {
      searchMode: true,
    }
  }

  submitForm(values) {
    const { search }= this.state
    this.props.browseForms({search: {...values, ...search}})
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeDate(key, v) {
    const { search }= (this.props.formsStore.browseForms!==undefined ? this.props.formsStore.browseForms : {})
    var values= []
    values[key]= v
    this.props.browseForms({search: {...search, ...values}})
  }

  onChangeInput(e, v) {
    const { search }= (this.props.formsStore.browseForms!==undefined ? this.props.formsStore.browseForms : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        parent.props.browseForms({search: {...search, keyword: v}})
      }
    }, 500)
  }

  onChangeSelect(e, v) {
    const { search }= (this.props.formsStore.browseForms!==undefined ? this.props.formsStore.browseForms : {})
    this.props.browseForms({search: {...search, status: (v=='' ? null : v)}})
  }
  
  render() {
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode }=this.props
    
    return (
      <div style={{position: 'static', width: '100%', zIndex: '1000'}}>
        <div style={{padding: '1em 1em', background: 'rgba(230, 230, 230, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
          <div style={{display: 'flex', padingTop: '1em'}}>
            <div style={{flex: '1'}}>
              <Header as='h4' style={{marginTop: '.25em'}}>
                <Icon name='window maximize outline' style={{fontSize: '1em'}}/>
                <Header.Content>
                  Custom Forms / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
                </Header.Content>
              </Header>
            </div>
            <div>
              {mode !=1 &&
              <Popup position= 'top right' inverted content='Back'
                trigger= {<Button size='mini' color='blue' onClick={this.props.setMode.bind(this,1)} icon style={{padding: '.5em'}}><Icon name='arrow left' /></Button>} 
              />}
              {mode !=2 && mode !=3 &&
              <Popup position= 'top right' inverted content='New Forms'
                trigger= {<Button size='mini' color='red' onClick={this.props.setMode.bind(this,3)} icon style={{padding: '.5em'}}><Icon name='plus' /></Button>} 
              />}
              {mode ==1 &&
              <Popup position= 'top right' inverted content='Show / Hide'
                trigger= {<Button size='mini' color='grey' onClick={this.showSearch.bind(this)} icon style={{padding: '.5em'}}><Icon name={searchMode==true? 'angle up' : 'angle down'} /></Button>} 
              />}
            </div>
          </div>

          {mode==1 && searchMode && 
          <div style={{margin: '1em 0 0 0', border: '0px solid rgba(100,100,100,.2)'}}>
            <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Form.Group widths='16'>
                    <Field name='keyword' component={InputField} onChange= {this.onChangeInput.bind(this)}
                    width={12} 
                    placeholder= 'Search custom form ... <ENTER>' />
                    <Field name='groupId' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'groupId')}
                    options={groupOptions()}
                    width={4} 
                    placeholder= 'Role / Group' />
                    {/* <Field name='formsDate' component={DatePickerField} onChange= {this.onChangeDate.bind(this, 'formsDate')}
                    width={4} 
                    type='date'
                    placeholder= 'Trx. Date' /> */}
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.formsStore.browseForms!=undefined && state.formsStore.browseForms.progress==1 ? state.formsStore.browseForms.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'FormsSearch',	// a unique identifier for this form
  })(FormsSearch)
  )
)