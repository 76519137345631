import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
} from 'semantic-ui-react'

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { format, parse, addSeconds } from "date-fns"
import {  } from "../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { requesterReport } from "../../actions/service/reportAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    reportStore: state.reportStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requesterReport:(objParam) => dispatch(requesterReport(objParam)),
  }
}

class RptRequesterCorporate extends Component {
  constructor(props) {
    super(props)
    this.state= {
      expand: false,
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.reportStore.requesterReport!==undefined ? this.props.reportStore.requesterReport : {})
    this.props.requesterReport(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.reportStore.requesterReport!==undefined ? this.props.reportStore.requesterReport : {})
    this.props.requesterReport({search: {...search}, paging: paging})
  }

  onChangeStatus(id, status, e) {
    // console.log(id, status, e)
    this.props.statusTicket({ticketId:id, isEnabled: status})
  }
  
  setExpand() {
    const { expand }= this.state
    this.setState({
      expand: !expand
    })
  }

  formatSeconds(seconds) {
    // console.log('!!! SECONDS', seconds)
    const date= addSeconds(new Date(0, 0, 0, 0, 0, 0), seconds);
    // console.log('!!! DATE', date)
    return (Math.floor(seconds/(24 * 60 * 60)) +'D / '+ format(date, 'HH:mm'));
  }

  render() {
    //console.log(this.props.reportStore.requesterReport)
    const { expand }= this.state
    const { screenWidth, screenHeight, style }= this.props
    const { data, search, paging }= (this.props.reportStore.requesterReport!==undefined ? this.props.reportStore.requesterReport : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight, ...style}}>
        <div className='noscroll' style={{flex: '1', padding: '0', overflowX: 'auto', width: screenWidth-270}}>
          <div>
            <Table unstackable selectable columns='16' celled striped basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell width='10'>
                    <Header as='h5' color='blue' style={{width: '30em'}}>CORPORATE / ORGANIZATION</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue' style={{width: '10em'}}>TOT. SOLVED</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue' style={{width: '10em'}}>TOT. UNSOLVED</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue' style={{width: '10em'}}>TOT. CLOSED</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue' style={{width: '10em'}}>TOT. SLA OK</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue' style={{width: '10em'}}>TOT. SLA NOK</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue' style={{width: '10em'}}>TOT. TICKET</Header>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
                { data!==undefined ? data.map((item,key)=>{
                    return(
                      <Table.Row key={++seq}>
                        <Table.Cell>{seq}</Table.Cell>
                        <Table.Cell>
                          
                        </Table.Cell>
                      </Table.Row>
                    )
                  }) : <Table.Row><Table.Cell colSpan={5}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
                }
              </Table.Body>
            </Table>
          </div>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RptRequesterCorporate)
