import React,{Component} from 'react'
import { Container, Segment, Header, Form, Icon, Divider, Button, Image } from 'semantic-ui-react'

import { ReactSVG } from 'react-svg'
import stream_logo  from '../../assets/stream-logo.svg'

import DismissableMessage from '../common/DismissableMessage'
import {withRouter} from "react-router-dom"

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'
// import { lang } from '../language/lang'

import { connect } from "react-redux"
import { signIn, resetForm } from "../../actions/auth/authAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn:(objParam) => dispatch(signIn(objParam)),
    resetForm:(objParam) => dispatch(resetForm(objParam)),
  }
}

class AuthenticateSignin extends Component {
  constructor(props) {
    super(props)
    this.state= {
      prevProgress: 0,
    }
    
  }

  componentDidMount() {
    this.props.resetForm("signIn")
  }
  
  resetForm() {
    const { reset } = this.props
    reset()
    //this.props.onClose()
    this.props.resetForm("signIn")
  }

  submitForm(values) {
    // console.log('!!! SIGN IN SUBMIT VALUES: ', values)
    const { reset } = this.props
    reset()
    this.props.signIn(values)
    if (this.props.onSubmit!=undefined) {
      this.props.onSubmit(values)
    }
  }

  componentDidUpdate() {
    const tokenAuth= localStorage.getItem('tokenAuth');
    const { code, text, progress, data }= this.props.authStore.signIn ? this.props.authStore.signIn : {}
    
    if (progress==1 && code!='00') {
      setTimeout(()=>{
        this.props.resetForm("signIn")
      }, 3000)
    } else {
      if (progress!=undefined && progress==1 && data.groupId=='Admin' && tokenAuth!='') {
         setTimeout(()=>{this.props.history.push('/service')}, 1000)
      } else if (progress!=undefined && progress==1 && data.groupId=='Agent' && tokenAuth!='') {
        setTimeout(()=>{this.props.history.push('/service')}, 1000)
      } else if (progress!=undefined && progress==1 && data.groupId=='Client' && tokenAuth!='') {
        setTimeout(()=>{this.props.history.push('/service')}, 1000)
      } else if (progress!=undefined && progress==1 && (data.groupId=='Supervisor' || data.groupId=='Team Leader') && tokenAuth!='') {
        setTimeout(()=>{this.props.history.push('/service')}, 1000)
      }
    }
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, screenWidth, screenHeight } = this.props
    const { code, text, progress, data }= this.props.authStore.signIn ? this.props.authStore.signIn : {}

    return (
      <div className="signin-form" style={{padding: '0 2em'}}>
        <div style={{width: screenWidth>840 ? 350 : 325, margin: 'auto', padding: '4em 3em', border: '1px solid rgb(37 157 212)', borderRadius: '.5em', background: 'linear-gradient(0deg, rgb(216 211 255) 0%, rgb(114, 232, 255) 92%, rgb(83, 181, 238) 100%)'}}>
          <div>
            <div style={{textAlign: 'center'}}>
              {/* <Image src='https://img.freepik.com/premium-vector/password-code-verification-security-protection-authorization-notice-mobile-phone-digital-secure-access-pus-notification-message-cellphone-vector-flat_212005-111.jpg' wrapped /> */}
              <Image size='small' src='https://png.pngtree.com/png-vector/20220831/ourmid/pngtree-banyan-tree-logo-design-vector-png-image_6131481.png' wrapped />
            </div>
            <div>
              <div style={{margin: '1em 0 1em 0'}}>
                <Header as='h3' color='blue'> 
                  <Header.Content style={{fontWeight: '400'}}>
                    Sign In
                    <Header.Subheader>Valid email and mobile phone is required for further transaction validation.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
                <Form.Group widths='equal'>
                  <Field name='account' component={InputField}
                  // label= 'Handphone / Email'
                  icon='mobile alternate'
                  placeholder='No. Handphone / Email'
                  validate={[required]} />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='passwd' component={InputField}
                  type='password'
                  icon='unlock alternate'
                  // label='Password'
                  placeholder='Password'
                  validate={required} />
                </Form.Group>

                {progress==1 ? <DismissableMessage style={{boxShadow: 'none', color: code=="00" ? '#fff' : '#fff', background: code=="00" ? '#0a717c' : '#b32800', textAlign: 'center'}} header={'Signin ' + (code=="00" ?  'OK' : 'NOK')} content={text} /> : null}
                {progress==2 ? <DismissableMessage style={{boxShadow: 'none', color: '#fff', background: 'rgb(253, 81, 27)', textAlign: 'center'}} header='Error' content='Network connection failures.' /> : null}

                <Divider hidden />
                <Form.Group widths='equal'>
                  <Form.Field style={{textAlign: 'right'}}>
                    <Button color='blue' size='large' id='btnSignIn' icon={progress==0 ? true : false}> {progress==0 ? <Icon name='notch circle' loading /> : ''} Sign In</Button>
                  </Form.Field>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)
(reduxForm({
  form: 'AuthenticateSignin',	// a unique identifier for this form
})(AuthenticateSignin)))
