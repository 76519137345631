import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Statistic } from 'semantic-ui-react'

import numeral from "numeral"

import { connect } from "react-redux"
import { workspaceStatistic } from "../../../actions/service/statisticAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    statisticStore: state.statisticStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    workspaceStatistic:(objParam) => dispatch(workspaceStatistic(objParam)),
  }
}


class WorkspaceWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {};
  }

  componentDidMount() {
    this.props.workspaceStatistic() 
  }
  
  render() {
    const { screenWidth,  screenHeight}= this.props
    const { data }= (this.props.statisticStore.workspaceStatistic && this.props.statisticStore.workspaceStatistic.progress=='1') ? this.props.statisticStore.workspaceStatistic : {data: {}}
    
    return (
      <div style={{display: 'flex'}}>
        <div style={{flex: '1'}}>
          <Segment style={{padding: '1em', height: this.props.height}}>
            <Header as='h5'>
              <Icon name='fire' style={{fontSize: '1em'}} />
              <Header.Content>
                <b><i>Today</i></b> Statistic
              </Header.Content>
            </Header>
            <div style={{display: 'flex', textAlign: 'center'}}>
              <div style={{flex: 1}}>
                <Statistic size='tiny'>
                  <Statistic.Value>{data.waiting || Math.floor(Math.random() * 100)}</Statistic.Value>
                  <Statistic.Label>WAITING</Statistic.Label>
                </Statistic>
              </div>
              <div style={{flex: 1}}>
                <Statistic size='tiny'>
                  <Statistic.Value>{data.assigned || Math.floor(Math.random() * 100)}</Statistic.Value>
                  <Statistic.Label>NEW</Statistic.Label>
                </Statistic>
              </div>
              <div style={{flex: 1}}>
                <Statistic size='tiny'>
                  <Statistic.Value>{data.progress || Math.floor(Math.random() * 100)}</Statistic.Value>
                  <Statistic.Label>PROGRESS</Statistic.Label>
                </Statistic>
              </div>
              <div style={{flex: 1}}>
                <Statistic size='tiny'>
                  <Statistic.Value>{data.solved || Math.floor(Math.random() * 100)}</Statistic.Value>
                  <Statistic.Label>SOLVED</Statistic.Label>
                </Statistic>
              </div>
              <div style={{flex: 1}}>
                <Statistic size='tiny'>
                  <Statistic.Value>{data.closed || Math.floor(Math.random() * 100)}</Statistic.Value>
                  <Statistic.Label>CLOSED</Statistic.Label>
                </Statistic>
              </div>
            </div>
          </Segment>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceWidget)