import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';
import ActionBody from './ActionBody'

import TicketHeader from '../../libraries/service/TicketHeader'
import AssignmentBrowse from '../../libraries/service/AssignmentBrowse'
import AssignmentForm from '../../libraries/service/AssignmentForm'
import AssignmentSearch from '../../libraries/service/AssignmentSearch'



class AssignmentBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        ticketId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, ticketId: id, clientId: clientId})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        ticketId: 0,
      });
    }

    render() {
      const {mode, ticketId, clientId}= this.state
      const {screenWidth, screenHeight, params}= this.props
      
      return(
        <Container fluid>
            <TicketHeader modal setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} ticketId={this.props.ticketId} params={params}/>
            <AssignmentSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} params={params} />
          
            {(mode==1 || mode==4) && <AssignmentBrowse setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} params={params} />}
            {mode==2 && <AssignmentForm mode={mode} ticketId={ticketId} clientId={clientId} setMode={this.setMode.bind(this)} params={params} />}
            {mode==3 && <AssignmentForm mode={mode} ticketId={0} clientId={0} setMode={this.setMode.bind(this)} params={params} />}
            {mode==4 && <ModalContainer headless size='large' content={<ActionBody screenWidth={screenWidth}  closeModal={this.closeModal.bind(this)} ticketId={ticketId} clientId={clientId} />} closeModal={this.closeModal.bind(this)} params={params} />}
        </Container>
      )
    }
}

export default AssignmentBody
