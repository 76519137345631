export default function widgetReducer (state = {}, action) {
  switch (action.type) {
    case 'WIDGET_RESET': {
        return({
            ...state,
            getWidget: {},
            updateWidget: {},
            createWidget: {},
        })
    }

    case 'WIDGET_BROWSE_PENDING': {
      return({...state, browseWidget: {progress: 0}})
    }
    case 'WIDGET_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseWidget: {...api.browseWidget.response, progress: 1, payload: action.payload}})
            return({...state, browseWidget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseWidget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WIDGET_BROWSE_REJECTED': {
        return({...state, browseWidget: {progress: 2, payload: action.payload}})
    }
    
    case 'WIDGET_GET_PENDING': {
        return({...state, getWidget: {progress: 0}})
    }
    case 'WIDGET_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getWidget: {...api.getWidget.response, progress: 1, payload: action.payload}})
            return({...state, getWidget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getWidget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WIDGET_GET_REJECTED': {
        return({...state, getWidget: {progress: 2, payload: action.payload}})
    }

    case 'WIDGET_STATUS_PENDING': {
      return({...state, statusWidget: {progress: 0}})
    }
    case 'WIDGET_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusWidget: {...api.statusWidget.response, progress: 1, payload: action.payload}})
            return({...state, statusWidget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusWidget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WIDGET_STATUS_REJECTED': {
        return({...state, statusWidget: {progress: 2, payload: action.payload}})
    }

    case 'WIDGET_CREATE_PENDING': {
      return({...state, createWidget: {progress: 0}})
    }
    case 'WIDGET_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createWidget: {...api.createWidget.response, progress: 1, payload: action.payload}})
            return({...state, createWidget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createWidget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WIDGET_CREATE_REJECTED': {
        return({...state, createWidget: {progress: 2, payload: action.payload}})
    }

    case 'WIDGET_UPDATE_PENDING': {
      return({...state, updateWidget: {progress: 0}})
    }
    case 'WIDGET_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateWidget: {...api.updateWidget.response, progress: 1, payload: action.payload}})
            return({...state, updateWidget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateWidget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WIDGET_UPDATE_REJECTED': {
        return({...state, updateWidget: {progress: 2, payload: action.payload}})
    }

    case 'WIDGET_REMOVE_PENDING': {
      return({...state, removeWidget: {progress: 0}})
    }
    case 'WIDGET_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeWidget: {...api.removeWidget.response, progress: 1, payload: action.payload}})
            return({...state, removeWidget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeWidget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WIDGET_REMOVE_REJECTED': {
        return({...state, removeWidget: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}