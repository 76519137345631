
import React, { Component } from "react"
import PropTypes from 'prop-types';
import {ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell } from 'recharts'

const schemes= [
  ['#F279B1', '#775566', '#7F3F5D', '#992255', '#961D55', '#4C3D44', '#A8004D', '#881144', '#2B2B2B', '#660033'],
  ['#AD5AD8', '#799CF2', '#8899AA', '#149EC2', '#557766', '#118866', '#0085A8', '#3F527F', '#116688', '#3D494C'],
  ['#8bAa32', '#8e94A1', '#A66727', '#1d4504', '#09A700', '#543aC0', '#afb3Ca', '#80A4Cb', '#393bE0', '#Af52D0'],
  ['#ffe6c8', '#e5c298', '#c39762', '#ae7f47', '#9a6c35', '#6d5800', '#a78700', '#d2aa00', '#ecbf00', '#ffd522'],
  ['#829b90', '#6f8a89', '#676d73', '#7c7a8f', '#6a6275', '#d4d7c1', '#8e988a', '#756a58', '#ac9e9e', '#958282'],
  ['#fd776c', '#cc6e6e', '#7b5a6d', '#6d7b5a', '#c8d794', '#6c7481', '#e28274', '#f4d2c8', '#fca4cb', '#b8c85e'],
  ['#69c6a5', '#81a0aa', '#468499', '#aed7e5', '#2f5866', '#72a49f', '#67737b', '#424e6a', '#bac4a4', '#738697'],
  ['#AD5AD8', '#799CF2', '#8899AA', '#149EC2', '#557766', '#118866', '#0085A8', '#3F527F', '#116688', '#3D494C'],
  ['#5FBA68', '#7A9CF2', '#8a99AA', '#1A9EC2', '#5a7766', '#1A8866', '#0085A8', '#3a527F', '#116688', '#3a494C'],
  ['#5DCAD8', '#795CF2', '#8859AA', '#149AC2', '#557566', '#118566', '#0045A8', '#3e527F', '#116088', '#3D494a'],
]

const dataDummy = [
  {name: 'Group A', value: 200, amount: 100, qty: 200}, 
  {name: 'Group B', value: 200, amount: 100, qty: 200},
  {name: 'Group C', value: 100, amount: 200, qty: 200}, 
  {name: 'Group D', value: 200, amount: 100, qty: 200}
]
const legendDummy = [
  {id: 'value', name: 'Value'}, 
  {id: 'amount', name: 'Amount'}, 
  {id: 'qty', name: 'Qty'}, 
]

const toPercent = (decimal, fixed = 0) => {
	return `${(decimal * 100).toFixed(fixed)}%`;
};

const getPercent = (value, total) => {
	const ratio = total > 0 ? value / total : 0;
  
  return toPercent(ratio, );
};

const renderTooltipContent = (o) => {
	const { payload, label } = o;
  const total = payload.reduce((result, entry) => (result + entry.value), 0);
  
  return (
  	<div style={{background: 'rgba(255,255,255,1)', border: '1px solid rgba(0,50,50,.8)', padding: '1em'}}>
    	<p>{`${label} / Tot. ${total}`}</p>
      {
        payload.map((entry, index) => (
          <div key={`item-${index}`} style={{color: entry.color}}>
            {entry.name}: {entry.value} / <b>{getPercent(entry.value, total)}</b>
          </div>
        ))
      }
    </div>
  );
};

class CustomizedLabel extends React.Component {
  constructor(props) {
    super(props)
    this.state={}
  }
  render () {
    // console.log(this.props)
    const {x, y, height, width, value} = this.props;
   	return <>
     <text x={x} y={y} dy={value<0 ? -15 : 20} fill={'#000'} fontSize={12} textAnchor="middle">{value}</text>
     </>
  }
};
class CustomizedLabelPercent extends React.Component {
  constructor(props) {
    super(props)
    this.state={}
  }
  render () {
    // console.log(this.props)
    const {x, y, height, width, value} = this.props;
   	return <>
     <text x={x} y={y} dy={value<0 ? -15 : 20} fill={'#000'} fontSize={12} textAnchor="middle">{(value * 100).toFixed(0)+'%'}</text>
     </>
  }
};

class CustomizedAxisTick extends React.Component{
  constructor(props) {
    super(props)
    this.state={}
  }

  render () {
    const {x, y, stroke, payload} = this.props;
   	return (
    	<g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-65)">{payload.value}</text>
      </g>
    );
  }
};

class AreaRechart extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
	render () {
    const { data, dataKey, legend, width, height, mode, fill, label, yAxis }= this.props
    const colors= schemes[Math.floor((Math.random() * 10))]

    let i= 0;
    if (mode=='tiny') { 
      return(
        <div>
          <div style={{ width: width || 'auto', height: height || 250, margin: '1em auto' }}>
            <ResponsiveContainer>
              <AreaChart data={data || dataDummy} width={width || 150} height={ height || 50} >
                <XAxis dataKey="name"/>
                <Tooltip />
                {
                  (legend || legendDummy).map((value, i)=>{
                    return(
                      <Area key={i} type='monotone' dataKey={value.id} name={value.name} fill={colors[i++]}/>
                    )
                  })
                }
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    } else if (mode=='stack') { 
      return(
        <div>
          <div style={{ width: width || 'auto', height: height || 250, margin: '1em auto' }}>
            <ResponsiveContainer>
              <AreaChart data={data || dataDummy} width={width || 150} height={ height || 250} margin={{top: 5, right: 30, left: yAxis!==false ? 5 : 30, bottom: 20}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name" tick={<CustomizedAxisTick/>}/>
                {(yAxis!==false) && <YAxis/>}
                <Tooltip/>
                <Legend />
                {
                  (legend || legendDummy).map((value, i)=>{
                    return(
                      <Area key={i} type='monotone' dataKey={value.id} stackId='1' name={value.name} activeDot={{r: 6}} fill={colors[i++]} label={label && <CustomizedLabel />}/>
                    )
                  })
                }
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    } else if (mode=='percent') { 
      return(
        <div>
          <div style={{ width: width || 'auto', height: height || 250, margin: '1em auto' }}>
            <ResponsiveContainer>
              <AreaChart data={data || dataDummy} stackOffset="expand" width={width || 150} height={ height || 250} margin={{top: 5, right: 30, left: yAxis!==false ? 5 : 30, bottom: 20}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name" tick={<CustomizedAxisTick/>}/>
                {(yAxis!==false) && <YAxis tickFormatter={toPercent}/>}
                <Tooltip content={renderTooltipContent}/>
                <Legend />
                {
                  (legend || legendDummy).map((value, i)=>{
                    return(
                      <Area key={i} type='monotone' dataKey={value.id} stackId="1" name={value.name} activeDot={{r: 6}} fill={colors[i++]} label={label && <CustomizedLabelPercent />}/>
                    )
                  })
                }
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    } else { 
      return(
        <div>
          <div style={{ width: width || 'auto', height: height || 250, margin: '1em auto' }}>
            <ResponsiveContainer>
              <AreaChart data={data || dataDummy} width={width || 150} height={ height || 250} margin={{top: 5, right: 30, left: yAxis!==false ? 0 : 30, bottom: 20}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name" tick={<CustomizedAxisTick/>}/>
                {(yAxis!==false) && <YAxis/>}
                <Tooltip/>
                <Legend />
                {
                  (legend || legendDummy).map((value, i)=>{
                    return(
                      <Area key={i} type='monotone' dataKey={value.id} name={value.name} activeDot={{r: 6}} fill={colors[i++]} label={label && <CustomizedLabel />}/>
                    )
                  })
                }
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    }
  }
}

export default AreaRechart;
