import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Statistic, Card } from 'semantic-ui-react'
import ModalContainer from '../../libraries/common/ModalContainer';

import TicketBody from '../service/TicketBody'
import HandlingBody from '../service/HandlingBody'
import SegmentBody from '../service/SegmentBody'
import AcknowledgeBody from '../service/AcknowledgeBody'

import MenuLeft from '../../libraries/service/MenuLeftCustomer'
import TicketWidget from '../../libraries/widget/service/TicketWidget';
import SLAWidget from '../../libraries/widget/service/SLAWidget';
import PipelineBody from './PipelineBody';


class ServiceCustomerBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { mode, module, screenWidth, screenHeight, mini, modal, params, groupId }= this.props

    return (
      <div>
        <div style={{marginTop: '3.2em'}}>
          <div style={{display: 'flex'}}>
            <div style={{background: 'linear-gradient(0deg, rgb(186, 168, 255) 0%, rgb(87, 197, 255) 65%, rgb(128 188 255) 100%)', width: '3.5em', paddingTop: '5em', textAlign: 'center'}}>
              <MenuLeft mode={this.props.mode} screenHeight={screenHeight-75} screenWidth={screenWidth} module={module} groupId={groupId} openModule={this.props.openModule} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}/>
            </div>
            <div style={{flex: '1', padding: '1em'}}>
              {/* {!mode && <PipelineBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} />} */}
              {mode==='customer' && <TicketBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} />}
              {mode==='interaction' && <HandlingBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} />}
              {mode==='perception' && <AcknowledgeBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} />}
              {mode==='segmentation' && <SegmentBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} />}
            </div>
            <div style={{background: '#f8f8f8', padding: '2em 1.5em'}}>
              <SLAWidget />
              <Divider />
              <TicketWidget />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ServiceCustomerBody