import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetOrganization(objParam) {
  return {
      type: 'ORGANIZATION_RESET',
      payload: objParam
  }
}

export function getOrganization(objParam) {
  return {
      type: 'ORGANIZATION_GET',
      payload:
        axios.post(
          urlAPI + 'service/organization/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusOrganization(objParam) {
  return {
      type: 'ORGANIZATION_STATUS',
      payload:
        axios.post(
          urlAPI + 'service/organization/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseOrganization(objParam) {
  return {
      type: 'ORGANIZATION_BROWSE',
      payload:
        axios.post(
          urlAPI + 'service/organization/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createOrganization(objParam) {
  return {
      type: 'ORGANIZATION_CREATE',
      payload:
        axios.post(
          urlAPI + 'service/organization/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateOrganization(objParam) {
  return {
      type: 'ORGANIZATION_UPDATE',
      payload:
        axios.post(
          urlAPI + 'service/organization/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeOrganization(objParam) {
  return {
      type: 'ORGANIZATION_REMOVE',
      payload:
        axios.post(
          urlAPI + 'service/organization/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
