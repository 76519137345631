import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetReport(objParam) {
  return {
      type: 'REPORT_RESET',
      payload: objParam
  }
}

export function ticketReport(objParam) {
  return {
      type: 'REPORT_TICKET',
      payload:
        axios.post(
          urlAPI + 'service/report/ticket',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function peopleReport(objParam) {
  return {
      type: 'REPORT_PEOPLE',
      payload:
        axios.post(
          urlAPI + 'service/report/people',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function requesterReport(objParam) {
  return {
      type: 'REPORT_REQUESTER',
      payload:
        axios.post(
          urlAPI + 'service/report/requester',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function slaReport(objParam) {
  return {
      type: 'REPORT_SLA',
      payload:
        axios.post(
          urlAPI + 'service/report/sla',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function csatReport(objParam) {
  return {
      type: 'REPORT_CSAT',
      payload:
        axios.post(
          urlAPI + 'service/report/csat',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function ojkReport(objParam) {
  return {
      type: 'REPORT_OJK',
      payload:
        axios.post(
          urlAPI + 'service/report/ojk',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function customReport(objParam) {
  return {
      type: 'REPORT_CUSTOM',
      payload:
        axios.post(
          urlAPI + 'service/report/custom',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
