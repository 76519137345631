import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetExplorer(objParam) {
  return {
      type: 'EXPLORER_RESET',
      payload: objParam
  }
}

export function getExplorer(objParam) {
  return {
      type: 'EXPLORER_GET',
      payload:
        axios.post(
          urlAPI + 'service/explorer/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusExplorer(objParam) {
  return {
      type: 'EXPLORER_STATUS',
      payload:
        axios.post(
          urlAPI + 'service/explorer/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseExplorer(objParam) {
  return {
      type: 'EXPLORER_BROWSE',
      payload:
        axios.post(
          urlAPI + 'service/explorer/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createExplorer(objParam) {
  return {
      type: 'EXPLORER_CREATE',
      payload:
        axios.post(
          urlAPI + 'service/explorer/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateExplorer(objParam) {
  return {
      type: 'EXPLORER_UPDATE',
      payload:
        axios.post(
          urlAPI + 'service/explorer/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeExplorer(objParam) {
  return {
      type: 'EXPLORER_REMOVE',
      payload:
        axios.post(
          urlAPI + 'service/explorer/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
