import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"


export function ticketHistorical(objParam) {
  return {
      type: 'HISTORICAL_TICKET',
      payload:
        axios.post(
          urlAPI + 'service/historical/ticket',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function priorityHistorical(objParam) {
  return {
      type: 'HISTORICAL_PRIORITY',
      payload:
        axios.post(
          urlAPI + 'service/historical/priority',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function severityHistorical(objParam) {
  return {
      type: 'HISTORICAL_SEVERITY',
      payload:
        axios.post(
          urlAPI + 'service/historical/severity',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function slaHistorical(objParam) {
  return {
      type: 'HISTORICAL_SLA',
      payload:
        axios.post(
          urlAPI + 'service/historical/sla',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function remarkHistorical(objParam) {
  return {
      type: 'HISTORICAL_REMARK',
      payload:
        axios.post(
          urlAPI + 'service/historical/remark',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function clusterHistorical(objParam) {
  return {
      type: 'HISTORICAL_CLUSTER',
      payload:
        axios.post(
          urlAPI + 'service/historical/cluster',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function categoryHistorical(objParam) {
  return {
      type: 'HISTORICAL_CATEGORY',
      payload:
        axios.post(
          urlAPI + 'service/historical/category',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function serviceHistorical(objParam) {
  return {
      type: 'HISTORICAL_SERVICE',
      payload:
        axios.post(
          urlAPI + 'service/historical/service',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function classificationHistorical(objParam) {
  return {
      type: 'HISTORICAL_CLASSIFICATION',
      payload:
        axios.post(
          urlAPI + 'service/historical/classification',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function resolutionHistorical(objParam) {
  return {
      type: 'HISTORICAL_RESOLUTION',
      payload:
        axios.post(
          urlAPI + 'service/historical/resolution',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function reasonHistorical(objParam) {
  return {
      type: 'HISTORICAL_REASON',
      payload:
        axios.post(
          urlAPI + 'service/historical/reason',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function interactionHistorical(objParam) {
  return {
      type: 'HISTORICAL_INTERACTION',
      payload:
        axios.post(
          urlAPI + 'service/historical/interaction',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function channelHistorical(objParam) {
  return {
      type: 'HISTORICAL_CHANNEL',
      payload:
        axios.post(
          urlAPI + 'service/historical/channel',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function satisfactionHistorical(objParam) {
  return {
      type: 'HISTORICAL_SATISFACTION',
      payload:
        axios.post(
          urlAPI + 'service/historical/satisfaction',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function workloadHistorical(objParam) {
  return {
      type: 'HISTORICAL_WORKLOAD',
      payload:
        axios.post(
          urlAPI + 'service/historical/workload',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function performanceHistorical(objParam) {
  return {
      type: 'HISTORICAL_PERFORMANCE',
      payload:
        axios.post(
          urlAPI + 'service/historical/performance',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function livefeedHistorical(objParam) {
  return {
      type: 'HISTORICAL_LIVEFEED',
      payload:
        axios.post(
          urlAPI + 'service/historical/livefeed',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
