import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'

import { ticketOptions }  from '../common/StaticMasterData'
import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, SelectField, DatePickerField, LabelDatePickerField, LabelSelectField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { browseTicket } from "../../actions/service/ticketAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    ticketStore: state.ticketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseTicket:(objParam) => dispatch(browseTicket(objParam)),
  }
}


class StatisticSearch extends Component {
  constructor(props) {
    super(props)
    this.state= {
      searchMode: true,
    }
  }

  submitForm(values) {
    const { search }= this.state
    this.props.browseTicket({search: {...values, ...search}})
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeDate(key, v) {
    const { search }= (this.props.ticketStore.browseTicket!==undefined ? this.props.ticketStore.browseTicket : {})
    var values= []
    values[key]= v
    this.props.browseTicket({search: {...search, ...values}})
  }

  onChangeInput(e, v) {
    const { search }= (this.props.ticketStore.browseTicket!==undefined ? this.props.ticketStore.browseTicket : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        parent.props.browseTicket({search: {...search, keyword: v}})
      }
    }, 500)
  }

  onChangeSelect(e, v) {
    const { search }= (this.props.ticketStore.browseTicket!==undefined ? this.props.ticketStore.browseTicket : {})
    this.props.browseTicket({search: {...search, status: (v=='' ? null : v)}})
  }
  
  render() {
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode }=this.props
    
    return (
      <Segment style={{height: this.props.height}}>
          <div style={{margin: '0 0 0 0', border: '0px solid rgba(100,100,100,.2)'}}>
            <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Form.Group widths='16'>
                    <Field name='keyword' component={LabelInputField} onChange= {this.onChangeInput.bind(this)}
                    width={16} 
                    label='Keyword'
                    placeholder= 'Type keyword here ... <ENTER>' />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
      </Segment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.ticketStore.browseTicket!=undefined && state.ticketStore.browseTicket.progress==1 ? state.ticketStore.browseTicket.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'StatisticSearch',	// a unique identifier for this form
  })(StatisticSearch)
  )
)