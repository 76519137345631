
import React from 'react';
import ReactDOM from 'react-dom';

import { Link } from 'react-router-dom';
import { Accordion, Tab, Input, Card, Form, Select, Radio, Label, Container, Divider, Dropdown, Grid, Header, Image, List, Menu, Segment, Button, Icon, Table, Popup } from 'semantic-ui-react'

import { localOptions }  from '../../modules/service/StaticMaster'
import DismissableMessage from '../common/DismissableMessage'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { ImageUploadField, AudioUploadField, InputField, LabelInputField, CheckboxField, SelectField, RadioField, TextAreaField, WysiwygField, NumberField, SliderField, LabelNumberField, LabelSelectField } from '../validation/componentrsui'
import { required, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { listParams } from "../../actions/halo/masterAction"
import { resetSetting, getSetting, updateSetting } from "../../actions/halo/settingAction"

//CONNECT REDUX STORE
const selector = formValueSelector('SettingLocalizationForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    settingStore: state.settingStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    listParams:(objParam) => dispatch(listParams(objParam)),

    resetSetting:(objParam) => dispatch(resetSetting(objParam)),
    getSetting:(objParam) => dispatch(getSetting(objParam)),
    updateSetting:(objParam) => dispatch(updateSetting(objParam)),
  }
};

class SettingLocalizationForm extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount() {
    //console.log('!!! DID MOUNT PROPS: ', this.props)
    
    this.props.resetSetting();
    this.props.getSetting({settingId: this.props.settingId})

    this.props.listParams({clientId: this.props.settingId})
  }

  componentDidUpdate(prevProps) {
    const { settingId, mapHandlings, mapSegments }= this.state
    var isUpdated= false;

    const { data }= (this.props.settingStore.getSetting!=undefined ? this.props.settingStore.getSetting : {})
    if (settingId!=this.props.settingId) {
      mapHandlings.clear();
      mapSegments.clear();

      // console.log(data.settingId, this.props.settingId)
      this.props.getSetting({settingId: this.props.settingId})
      this.props.listParams({clientId: this.props.settingId})

      isUpdated= true;
    }

    if (data && data.settingId==this.props.settingId && data.ticketHandlings && data.ticketHandlings.length>0 && mapHandlings.size==0) {
      data.ticketHandlings.map((handling, i)=>{
        if (!mapHandlings.get(i)) {
          mapHandlings.set(i, new Map());
        }
        handling.params && handling.params.map(item=>{
          mapHandlings.get(i).set(item.key, item.value)
        })
      })
      isUpdated= true;
    }

    if (data && data.settingId==this.props.settingId && data.ticketSegments && data.ticketSegments.length>0 && mapSegments.size==0) {
      data.ticketSegments.map((segment, i)=>{
        if (!mapSegments.get(i)) {
          mapSegments.set(i, new Map());
        }
        segment.params && segment.params.map(item=>{
          mapSegments.get(i).set(item.key, item.value)
        })
      })
      isUpdated= true;
    }

    if (isUpdated) {
      this.setState({
        settingId: this.props.settingId,
        mapHandlings: mapHandlings,
        mapSegments: mapSegments,
      })
    }
    
  }

  resetForm() {
    const { reset, mode } = this.props
    reset();
  }

  onSubmit(values) {
    const { mode} = this.props
    
    if(mode==2) { //UPDATE
      this.props.updateSetting({...values, ...{}})
    }
  }

  onChangeInput(e, v) {
    // console.log(e, v.value)
    this.setState({
      param: v.value,
    })
  }

  onChangeSelect(e, v) {
    // console.log(e, v)
    this.setState({
      value: v.value,
    })
  }

  render() {
    const { menu, tabChannel, tabReport, tabModule, showSetting, module }= this.state
    const { error, handleSubmit, pristine, reset, submitting, group, mode, screenWidth, screenHeight }=this.props
    const { data }= (this.props.settingStore.getSetting!=undefined ? this.props.settingStore.getSetting : {})
    const { progress, code, text }= (this.props.settingStore.updateSetting!=undefined ? this.props.settingStore.updateSetting : {})
    
    return(<>
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Header as='h4' style={{margin: '0 0 1em 0', fontWeight: 'bold'}}>
            <Icon name='language' style={{fontSize: '1.3em'}}/>
            <Header.Content>
              Time & Language, Localization
              <Header.Subheader></Header.Subheader>
            </Header.Content>
          </Header>

          <Segment>
            <Header as='h5'>
              Code Generator
              <Header.Subheader>Code auto generation.</Header.Subheader>
            </Header>

            <Form.Group widths='equal'>
              <Field name='ticketCode' component={InputField}
              // validate= {[required]}
              label='Ticket Code'
              placeholder='Ticket Code Format' />
              <Field name='code' component={InputField}
              label='Organization Code'
              placeholder='Organization Code Format' />
            </Form.Group>
          </Segment>
          

          <Segment>
            <Header as='h5'>
              Time Zone & Duration
              <Header.Subheader>Local preference for time zone.</Header.Subheader>
            </Header>

            <Form.Group widths='16'>
              <Field name='timezone' component={SelectField}
              width={8}
              options={localOptions('zone')}
              validate= {[required]}
              label='Time Zone'
              placeholder='Time Zone' />
              <Field name='timeFormat' component={SelectField}
              width={4}
              options={localOptions('time')}
              label='Time Format'
              placeholder='Time Format' />
              <Field name='dateFormat' component={SelectField}
              width={4}
              options={localOptions('date')}
              label='Date Format'
              placeholder='Date Format' />
            </Form.Group>
          </Segment>

          <Segment>
            <Header as='h5'>
              Numbering Format
              <Header.Subheader>Local preference for time zone.</Header.Subheader>
            </Header>
            <Form.Group widths='16'>
              <Field name='numberCountry' component={SelectField}
              width={4}
              options={localOptions('country')}
              validate= {[required]}
              label='Country Format'
              placeholder='Country Format' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='currency' component={SelectField}
              options={localOptions('currency')}
              validate= {[required]}
              label='Default Currency'
              placeholder='Default Currency' />
              <Field name='thousand' component={SelectField}
              options={localOptions('thousand')}
              validate= {[required]}
              label='Thousand Separator'
              placeholder='Thousand Separator' />
              <Field name='decimal' component={SelectField}
              options={localOptions('decimal')}
              validate= {[required]}
              label='Decimal Separator'
              placeholder='Decimal Separator' />
              <Field name='rounding' component={SelectField}
              options={localOptions('rounding')}
              validate= {[required]}
              label='Decimal Digit'
              placeholder='Decimal Digit' />
            </Form.Group>
          </Segment>

          <Segment>
            <Header as='h5'>
              Language & Dictionary
              <Header.Subheader>Language and format setting.</Header.Subheader>
            </Header>

            <Form.Group widths='16'>
              <Field name='languageCountry' component={SelectField}
              width={4}
              options={localOptions('language')}
              validate= {[required]}
              label='Country Language'
              placeholder='Country Language' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='translation' component={TextAreaField}
              rows={30}
              label='Custom Dictionary / Translation'
              placeholder='Dictionary and translation list words. Do not use reserved chars "," and ":" .' />
            </Form.Group>
          </Segment>

          <Segment>
              {(progress==1 || progress==2)  && <DismissableMessage hide='auto' style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Setting' : 'Insert Setting') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={code + ' ' + text} />}
              
              <div style={{textAlign: 'right'}}>
                <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
                <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
              </div>
          </Segment>
        </Form >
      </div>
    </>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.settingStore.getSetting!=undefined && state.settingStore.getSetting.progress==1 ? state.settingStore.getSetting.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      userId: selector(state, 'userId'),
      localizationRatingQty: selector(state, 'localizationRatingQty') || '0',


      
    }),
    { load: getSetting }
  )
  (reduxForm({
    form: 'SettingLocalizationForm',	// a unique identifier for this form
  })(SettingLocalizationForm)
  )
)
