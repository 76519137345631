export default function segmentReducer (state = {}, action) {
  switch (action.type) {
    case 'SEGMENT_RESET': {
        return({
            ...state,
            getSegment: {},
            updateSegment: {},
            createSegment: {},
        })
    }

    case 'SEGMENT_BROWSE_PENDING': {
      return({...state, browseSegment: {progress: 0}})
    }
    case 'SEGMENT_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseSegment: {...api.browseSegment.response, progress: 1, payload: action.payload}})
            return({...state, browseSegment: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseSegment: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SEGMENT_BROWSE_REJECTED': {
        return({...state, browseSegment: {progress: 2, payload: action.payload}})
    }
    
    case 'SEGMENT_GET_PENDING': {
        return({...state, getSegment: {progress: 0}})
    }
    case 'SEGMENT_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getSegment: {...api.getSegment.response, progress: 1, payload: action.payload}})
            return({...state, getSegment: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getSegment: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SEGMENT_GET_REJECTED': {
        return({...state, getSegment: {progress: 2, payload: action.payload}})
    }

    case 'SEGMENT_STATUS_PENDING': {
      return({...state, statusSegment: {progress: 0}})
    }
    case 'SEGMENT_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusSegment: {...api.statusSegment.response, progress: 1, payload: action.payload}})
            return({...state, statusSegment: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusSegment: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SEGMENT_STATUS_REJECTED': {
        return({...state, statusSegment: {progress: 2, payload: action.payload}})
    }

    case 'SEGMENT_CREATE_PENDING': {
      return({...state, createSegment: {progress: 0}})
    }
    case 'SEGMENT_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createSegment: {...api.createSegment.response, progress: 1, payload: action.payload}})
            return({...state, createSegment: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createSegment: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SEGMENT_CREATE_REJECTED': {
        return({...state, createSegment: {progress: 2, payload: action.payload}})
    }

    case 'SEGMENT_UPDATE_PENDING': {
      return({...state, updateSegment: {progress: 0}})
    }
    case 'SEGMENT_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateSegment: {...api.updateSegment.response, progress: 1, payload: action.payload}})
            return({...state, updateSegment: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateSegment: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SEGMENT_UPDATE_REJECTED': {
        return({...state, updateSegment: {progress: 2, payload: action.payload}})
    }

    case 'SEGMENT_REMOVE_PENDING': {
      return({...state, removeSegment: {progress: 0}})
    }
    case 'SEGMENT_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeSegment: {...api.removeSegment.response, progress: 1, payload: action.payload}})
            return({...state, removeSegment: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeSegment: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SEGMENT_REMOVE_REJECTED': {
        return({...state, removeSegment: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}