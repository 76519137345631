import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import RequesterBrowse from '../../libraries/service/RequesterBrowse'
import RequesterForm from '../../libraries/service/RequesterForm'
import RequesterSearch from '../../libraries/service/RequesterSearch'

import ConfirmationModal from '../../libraries/service/ConfirmationModal';


class RequesterBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        requesterId: 0,
      };
    }

    setMode(mode, id, param) {
      this.setState({mode: mode, requesterId: id, param: param})
    }

    closeModal(pristine) { //true: no data change, false: data been changed
      if (pristine===false) {
        console.log(pristine)
        this.setState({
          modal: this.state.modal===1 ? 0 : 1, //1: confirmation
        });
      } else {
        this.setState({
          mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
          requesterId: 0,

          modal: 0,
        });
      }
    }
    render() {
      const {mode, requesterId, param, modal}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div>
          <div style={{display: 'flex'}}>
            <div style={{flex: 1}}>
              <RequesterSearch mode={mode} screenWidth={screenWidth} setMode={this.setMode.bind(this)} />
              
              {<RequesterBrowse mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} openModal={this.props.openModal} closeModal={this.closeModal.bind(this)} />}
              {/* {mode==2 && <RequesterForm mode={mode} requesterId={requesterId} param={param} setMode={this.setMode.bind(this)} />} */}
              {/* {mode==3 && <RequesterForm mode={mode} requesterId={0} param={param} setMode={this.setMode.bind(this)} />} */}
              {mode==2 && <ModalContainer headless size='large' content={<RequesterForm mode={mode} requesterId={requesterId} param={param} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
              {mode==3 && <ModalContainer headless size='large' content={<RequesterForm mode={mode} requesterId={0} param={param} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}

              {modal==1 && <ModalContainer headless style={{marginTop: screenHeight/4}} size='tiny' content={<ConfirmationModal screenWidth={screenWidth} screenHeight={screenHeight-125} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
            </div>
            {/* <div style={{width: 350}}>
              INDEX AUTO HIDDEN, TITLE AND DESCRIPTION AND SUB TITLE / CHAPTER
            </div> */}
          </div>
        </div>
      )
    }
}

export default RequesterBody
