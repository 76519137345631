import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetCalendar(objParam) {
  return {
      type: 'CALENDAR_RESET',
      payload: objParam
  }
}

export function getCalendar(objParam) {
  return {
      type: 'CALENDAR_GET',
      payload:
        axios.post(
          urlAPI + 'service/calendar/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusCalendar(objParam) {
  return {
      type: 'CALENDAR_STATUS',
      payload:
        axios.post(
          urlAPI + 'service/calendar/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseCalendar(objParam) {
  return {
      type: 'CALENDAR_BROWSE',
      payload:
        axios.post(
          urlAPI + 'service/calendar/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createCalendar(objParam) {
  return {
      type: 'CALENDAR_CREATE',
      payload:
        axios.post(
          urlAPI + 'service/calendar/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateCalendar(objParam) {
  return {
      type: 'CALENDAR_UPDATE',
      payload:
        axios.post(
          urlAPI + 'service/calendar/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeCalendar(objParam) {
  return {
      type: 'CALENDAR_REMOVE',
      payload:
        axios.post(
          urlAPI + 'service/calendar/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
