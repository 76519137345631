import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { groupOptions, conditionOptions }  from '../../modules/service/StaticMaster'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, SearchField, ToggleField, DropdownField, InsertableDropdownField } from '../validation/componentrsui'
import { required, account, userPassword, confirmedPassword, phoneNumber, number, minValue, email, phoneNumberID } from '../validation/validation'

import { connect } from "react-redux"
import { resetPeople, getPeople,updatePeople, createPeople } from "../../actions/service/peopleAction"
import { listOrganization } from "../../actions/service/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('PeopleForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    peopleStore: state.peopleStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listOrganization:(objParam) => dispatch(listOrganization(objParam)),

    resetPeople:(objParam) => dispatch(resetPeople(objParam)),
    getPeople:(objParam) => dispatch(getPeople(objParam)),
    createPeople:(objParam) => dispatch(createPeople(objParam)),
    updatePeople:(objParam) => dispatch(updatePeople(objParam)),
  }
}

class PeopleForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount(){
    this.props.listOrganization()
    
    this.props.resetPeople(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getPeople({peopleId: this.props.peopleId})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.peopleStore.updatePeople!=undefined ? this.props.peopleStore.updatePeople : {}) : (this.props.peopleStore.createPeople!=undefined ? this.props.peopleStore.createPeople : {}))

    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }

    if (this.props.prevMode==4 && this.props.prevMode!=prevProps.prevMode) {
      this.props.listOrganization()
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updatePeople({...values}) : this.props.createPeople({...values})
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.peopleStore.getPeople!=undefined && this.props.peopleStore.getPeople.progress=='1' ? this.props.peopleStore.getPeople : {})
    const { progress, code, text }= (mode==2 ? (this.props.peopleStore.updatePeople!=undefined ? this.props.peopleStore.updatePeople : {}) : (this.props.peopleStore.createPeople!=undefined ? this.props.peopleStore.createPeople : {}))

    const listOrganization= (this.props.masterStore.listOrganization!=undefined && this.props.masterStore.listOrganization.progress=='1' ? this.props.masterStore.listOrganization.data : [])

    return (
      <div style={{padding: '1em 1.5em'}}>
        <div style={{position: 'absolute', marginTop: '-3em', right: '1.7em', display: 'inline-block', background: '#222', padding: '.2em .5em .6em .6em', borderRadius: '.5em'}}><Icon name='window close' color='yellow' style={{cursor: 'pointer', padding: '0', margin: '0', fontSize: '2em'}} onClick={this.props.closeModal.bind(this, pristine)}/></div>
        
        <Header as='h4' style={{margin: '0 0 2em 0'}}>
          <Icon name='users' style={{fontSize: '1em'}}/>
          <Header.Content>
            People / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
          </Header.Content>
        </Header>

        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Grid stackable doubling>
            <Grid.Row columns={16}>
              <Grid.Column width={5}>
                <Header as='h5'>
                  <Header.Content>
                    Credential
                    <Header.Subheader>Account information.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='name' component={InputField}
                    validate= {[required]}
                    label='Full Name'
                    placeholder='Full Name' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='isEnabled' component={ToggleField}
                    validate= {[required]}
                    label='Is Enabled'
                    placeholder='Enabled' />
                  </Form.Group>

                  <Divider />
                  <Form.Group widths='equal'>
                    <Field name='account' component={InputField}
                    validate= {[required, account]}
                    label='Account'
                    placeholder='User Account' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='groupId' component={SelectField}
                    options={groupOptions('service')}
                    validate= {[required]}
                    label='User Group'
                    placeholder='User Group' />
                  </Form.Group>
                </Segment>
              </Grid.Column>
              <Grid.Column width={5}>
                <Header as='h5'>
                  <Header.Content>
                    Personal
                    <Header.Subheader>Contact Information.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='mobile' component={InputField}
                    validate= {[phoneNumberID]}
                    label='Mobile'
                    placeholder='Mobile' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='email' component={InputField}
                    validate= {[email]}
                    // label='Email'
                    placeholder='Email' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='address' component={InputField}
                    label='Address'
                    placeholder='Address' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='city' component={InputField}
                    placeholder='City' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='province' component={InputField}
                    placeholder='Province' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='country' component={InputField}
                    placeholder='Country' />
                    <Field name='zipcode' component={InputField}
                    validate= {[number]}
                    placeholder='Zipcode' />
                  </Form.Group>
                </Segment>
              </Grid.Column>
              <Grid.Column width={6}>
                <Header as='h5'>
                  <Header.Content>
                    Competency Skillset
                    <Header.Subheader>Define people competency skill set.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='competencies' component={InsertableDropdownField}
                    label='Skill Set'
                    placeholder='Set skill set' />
                  </Form.Group>
                </Segment>
                {/* <Segment>
                  <Form.Group widths='equal'>
                    <Field name='advancedSkills' component={InsertableDropdownField}
                    label='Highest Score Skill Set'
                    placeholder='Set highest / advanced score skill set' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='averageSkills' component={SelectField}
                    label='Average Score Skill Set'
                    placeholder='Set average / medium score skill set' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='basicSkills' component={SelectField}
                    label='Basic / Low Score Skill Set'
                    placeholder='Set basic / low score skill set' />
                  </Form.Group>
                </Segment> */}

                <Header as='h5'>
                  <Header.Content>
                    Organization
                    <Header.Subheader>Organization assignment and placement.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='organizationId' component={SelectField}
                    options={listOrganization}
                    validate= {[required]}
                    label='Organization'
                    placeholder='Organization' />
                    <Button type='button' size='large' icon onClick={this.props.setMode.bind(this, 4)}>
                      <Icon name='plus' />
                    </Button>
                  </Form.Group>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update People' : 'Insert People') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.peopleStore.getPeople!=undefined && state.peopleStore.getPeople.progress==1 ? state.peopleStore.getPeople.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      openStamp: selector(state, 'openStamp'),
      remark: selector(state, 'remark'),

      segmentId: selector(state, 'segmentId'),
      segmentParams: selector(state, 'segmentParams'),

      dispositions: selector(state, 'dispositions'),
    }),
    { load: getPeople }
  )
  (reduxForm({
    form: 'PeopleForm',	// a unique identifier for this form
  })(PeopleForm)
  )
)
