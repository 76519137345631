import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import HandlingBrowse from '../../libraries/service/HandlingBrowse'
import HandlingForm from '../../libraries/service/HandlingForm'
import HandlingSearch from '../../libraries/service/HandlingSearch'

import SettingChannelForm from '../../libraries/service/SettingChannelForm'
import SettingNotificationForm from '../../libraries/service/SettingNotificationForm'
import SettingFeedbackForm from '../../libraries/service/SettingFeedbackForm'
import SettingPermissionForm from '../../libraries/service/SettingPermissionForm'
import SettingLocalizationForm from '../../libraries/service/SettingLocalizationForm'

import SettingWorkflowForm from '../../libraries/service/SettingWorkflowForm'
import SettingAutomationForm from '../../libraries/service/SettingAutomationForm'

import SettingSLATimerForm from '../../libraries/service/SettingSLATimerForm'
import SettingSLAServiceForm from '../../libraries/service/SettingSLAServiceForm'
import SettingSLACustomerForm from '../../libraries/service/SettingSLACustomerForm'

import SettingResolutionForm from '../../libraries/service/SettingResolutionForm'
import SettingClassificationForm from '../../libraries/service/SettingClassificationForm'
import SettingSeverityForm from '../../libraries/service/SettingSeverityForm'
import SettingPriorityForm from '../../libraries/service/SettingPriorityForm'

class SettingBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        handlingId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, handlingId: id, clientId: clientId})
    }

    render() {
      const {mode}= this.state
      const {screenHeight, screenWidth}= this.props
      
      return(
        <Container fluid>
          {this.props.mode=='channel' && <SettingChannelForm  screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={this.props.modal}  params={this.props.params} />}
          {this.props.mode=='notification' && <SettingNotificationForm screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={this.props.modal}  params={this.props.params} />}
          {/* {this.props.mode=='feedback' && <SettingFeedbackForm screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={this.props.modal}  params={this.props.params} />} */}
          {this.props.mode=='permission' && <SettingPermissionForm screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={this.props.modal}  params={this.props.params} />}
          {this.props.mode=='localization' && <SettingLocalizationForm screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={this.props.modal}  params={this.props.params} />}

          {/* {this.props.mode=='workflow' && <SettingWorkflowForm screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={this.props.modal}  params={this.props.params} />} */}
          {/* {this.props.mode=='automation' && <SettingAutomationForm screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={this.props.modal}  params={this.props.params} />} */}
          
          {/* {this.props.mode=='sla_timer' && <SettingSLATimerForm screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={this.props.modal}  params={this.props.params} />} */}
          {/* {this.props.mode=='sla_service' && <SettingSLAServiceForm screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={this.props.modal}  params={this.props.params} />} */}
          {/* {this.props.mode=='sla_customer' && <SettingSLACustomerForm screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={this.props.modal}  params={this.props.params} />} */}

          {/* {this.props.mode=='resolution' && <SettingResolutionForm screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={this.props.modal}  params={this.props.params} />} */}
          {/* {this.props.mode=='classification' && <SettingClassificationForm screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={this.props.modal}  params={this.props.params} />} */}
          {/* {this.props.mode=='severity' && <SettingSeverityForm screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={this.props.modal}  params={this.props.params} />} */}
          {/* {this.props.mode=='priority' && <SettingPriorityForm screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={this.props.modal}  params={this.props.params} />} */}
        </Container>
      )
    }
}

export default SettingBody
