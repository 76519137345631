import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Label, Dropdown } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import StatisticDashboard from '../../libraries/service/StatisticDashboard';

class StatisticBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        statisticId: 0,
      };
    }

    setMode(mode, id, params) {
      this.setState({mode: mode, statisticId: id, params})
    }

    setDashboard(v) {
      this.setState({
        dashboard: v,
      })
    }

    render() {
      const {mode, modal, params}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div>
          <StatisticDashboard dashboard={this.state.dashboard} mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />
        </div>
      )
    }
}

export default StatisticBody
