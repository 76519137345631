import { combineReducers } from 'redux'
// import { loadingBarReducer } from 'react-redux-loading-bar'
import { reducer as reduxFormReducer } from 'redux-form';

import authReducer from './auth/authReducer'

// import masterReducer from './halo/masterReducer'
import accountReducer from './halo/accountReducer'
import messageReducer from './halo/messageReducer'
import settingReducer from './halo/settingReducer'

// //RESOURCE
// import agentReducer from './halo/agentReducer'
// import workgroupReducer from './halo/workgroupReducer'
// import scriptReducer from './halo/scriptReducer'

// import billingReducer from './halo/billingReducer'
// import clientReducer from './halo/clientReducer'
// import contractReducer from './halo/contractReducer'

// import customerReducer from './halo/customerReducer'
// import visitorReducer from './halo/visitorReducer'
// import contactReducer from './halo/contactReducer'

import productReducer from './halo/productReducer'
// import appointmentReducer from './halo/appointmentReducer'

//ECOSYSTEM
import syslogReducer from './halo/syslogReducer'

// import bloggingReducer from './halo/bloggingReducer'
// import documentReducer from './halo/documentReducer'

// import waserverReducer from './halo/waserverReducer'
// import voipReducer from './halo/voipReducer'
// import trespassingReducer from './halo/trespassingReducer'
// import whitelistReducer from './halo/whitelistReducer'

// import postpaidReducer from './halo/postpaidReducer'
// import recordReducer from './halo/recordReducer'
// import prepaidReducer from './halo/prepaidReducer'
// import deductReducer from './halo/deductReducer'
// import ratesReducer from './halo/ratesReducer'

//MIDDLEWARE
import middlewareReducer from './middleware/middlewareReducer'

//SERVICE
import serviceReducer from './halo/serviceReducer'

// WEBSOCKET
import socketReducer from './socket/socketReducer'
import phoneReducer from './socket/phoneReducer'

// //FLOW - OMNI CHANNEL
// import omniwidgetReducer from './flow/omniwidgetReducer'
// import interactiveReducer from './flow/interactiveReducer'
// import omnichannelReducer from './flow/omnichannelReducer'

// //STREAM - OUTBOUND CAMPAIGN
// import bridgingReducer from './stream/bridgingReducer'
// import omniphoneReducer from './stream/omniphoneReducer'

// import statisticReducer from './halo/statisticReducer'
// import performanceReducer from './halo/performanceReducer'
// import reportReducer from './stream/reportReducer'

// import campaignReducer from './stream/campaignReducer'
// import targetReducer from './stream/targetReducer'
// import assignReducer from './stream/assignReducer'
// import transactReducer from './stream/transactReducer'

// import voiceReducer from './stream/voiceReducer'
// import messagingReducer from './stream/messagingReducer'
// import socialReducer from './stream/socialReducer'

// import dialerReducer from './stream/dialerReducer'

// //TALK - IN-APP VOICE
// import mauReducer from './talk/mauReducer'
// import tunnelReducer from './talk/tunnelReducer'
// import occupancyReducer from './talk/occupancyReducer'
// import conferenceReducer from './talk/conferenceReducer'
// import linkwidgetReducer from './talk/linkwidgetReducer'
// import linkReducer from './talk/linkReducer'

// // COLLABORATION
// import taskReducer from './collab/taskReducer'
// import todoReducer from './collab/todoReducer'
// import linkedReducer from './collab/linkedReducer'
// import groupsReducer from './collab/groupsReducer'
// import communityReducer from './collab/communityReducer'
// import summaryReducer from './collab/summaryReducer'
// import whiteboardReducer from './collab/whiteboardReducer'

// // HCM
// import sitesReducer from './hcm/sitesReducer'
// import organizationReducer from './hcm/organizationReducer'

// SERVICE / TICKETING MANAGEMENT
import masterReducer from './service/masterReducer'

import statisticReducer from './service/statisticReducer'
import historicalReducer from './service/historicalReducer'
import monitoringReducer from './service/monitoringReducer'

import ticketReducer from './service/ticketReducer'
import interactionReducer from './service/interactionReducer'

import tickethandlingReducer from './service/tickethandlingReducer'
import handlingReducer from './service/handlingReducer'
import segmentReducer from './service/segmentReducer'
import acknowledgeReducer from './service/acknowledgeReducer'

import peopleReducer from './service/peopleReducer'
import organizationReducer from './service/organizationReducer'
import workgroupReducer from './service/workgroupReducer'

import bucketReducer from './service/bucketReducer'
import macroReducer from './service/macroReducer'
import formsReducer from './service/formsReducer'
import fieldsReducer from './service/fieldsReducer'

import statusReducer from './service/statusReducer'
import resolutionReducer from './service/resolutionReducer'
import classificationReducer from './service/classificationReducer'
import reasoningReducer from './service/reasoningReducer'

import calendarReducer from './service/calendarReducer'
import routingReducer from './service/routingReducer'
import slaReducer from './service/slaReducer'
import automationReducer from './service/automationReducer'
import csatReducer from './service/csatReducer'

import requesterReducer from './service/requesterReducer'
import knowledgeReducer from './service/knowledgeReducer'
import reportReducer from './service/reportReducer'

import explorerReducer from './service/explorerReducer'
import reportingReducer from './service/reportingReducer'
import widgetReducer from './service/widgetReducer'
import analyticReducer from './service/analyticReducer'

// //CRM PLATFORM
// import dashboardReducer from './crm/dashboardReducer'

// import prospectReducer from './crm/prospectReducer'
// import closingReducer from './crm/closingReducer'
// import purchaseReducer from './crm/purchaseReducer'
// import subscribeReducer from './crm/subscribeReducer'
// import custbillReducer from './crm/custbillReducer'
// import custpaymentReducer from './crm/custpaymentReducer'
// import voucherReducer from './crm/voucherReducer'
// import discountReducer from './crm/discountReducer'
// import reachReducer from './crm/reachReducer'
// import acquisitionReducer from './crm/acquisitionReducer'
// import conversionReducer from './crm/conversionReducer'
// import retentionReducer from './crm/retentionReducer'
// import loyaltyReducer from './crm/loyaltyReducer'

// // COLLECT
// import collectingReducer from './collect/collectingReducer'
// import debtorReducer from './collect/debtorReducer'
// import interactionReducer from './collect/interactionReducer'
// import bucketReducer from './collect/bucketReducer'
// import allocationReducer from './collect/allocationReducer'
// import collectorReducer from './collect/collectorReducer'
// import worksheetReducer from './collect/worksheetReducer'
// import commissionReducer from './collect/commissionReducer'
// import incentiveReducer from './collect/incentiveReducer'
// import achievementReducer from './collect/achievementReducer'
// import inhouseReducer from './collect/inhouseReducer'
// import activityReducer from './collect/activityReducer'
// import repositoryReducer from './collect/repositoryReducer'


//SYSTEM
import backendReducer from './system/backendReducer'
import workflowReducer from './system/workflowReducer'
import schedulerReducer from './system/schedulerReducer'
import watchdogReducer from './system/watchdogReducer'
import disasterReducer from './system/disasterReducer'
import errorReducer from './system/errorReducer'
import audittrailReducer from './system/audittrailReducer'
import apilogReducer from './system/apilogReducer'

const ovisonereducers = combineReducers({

  authStore: authReducer,

  masterStore: masterReducer,
  accountStore: accountReducer,
  messageStore: messageReducer,
  settingStore: settingReducer,

  // //RESOURCE
  // agentStore: agentReducer,
  // workgroupStore: workgroupReducer,
  // scriptStore: scriptReducer,
  // contactStore: contactReducer,

  // billingStore: billingReducer,
  // clientStore: clientReducer,
  // contractStore: contractReducer,

  // customerStore: customerReducer,
  productStore: productReducer,
  // visitorStore: visitorReducer,
  // appointmentStore: appointmentReducer,

  //ECOSYSTEM
  syslogStore: syslogReducer,

  // bloggingStore: bloggingReducer,
  // documentStore: documentReducer,

  // waserverStore: waserverReducer,
  // voipStore: voipReducer,
  // trespassingStore: trespassingReducer,
  // whitelistStore: whitelistReducer,

  // postpaidStore: postpaidReducer,
  // recordStore: recordReducer,
  // prepaidStore: prepaidReducer,
  // deductStore: deductReducer,
  // ratesStore: ratesReducer,

  middlewareStore: middlewareReducer,

  // SERVICE
  serviceStore: serviceReducer,
  
  // WEBSOCKET
  socketStore: socketReducer,
  phoneStore: phoneReducer,

  // //FLOW - OMNI CHANNEL
  // omniwidgetStore: omniwidgetReducer,
  // interactiveStore: interactiveReducer,
  // omnichannelStore: omnichannelReducer,

  // //STREAM - OUTBOUND CAMPAIGN
  // bridgingStore: bridgingReducer,
  // omniphoneStore: omniphoneReducer,

  // statisticStore: statisticReducer,
  // performanceStore: performanceReducer,
  // reportStore: reportReducer,

  // campaignStore: campaignReducer,
  // targetStore: targetReducer,
  // assignStore: assignReducer,
  // transactStore: transactReducer,
  
  // voiceStore: voiceReducer,
  // messagingStore: messagingReducer,
  // socialStore: socialReducer,

  // dialerStore: dialerReducer,
  
  // //TALK - IN-APP VOICE
  // mauStore: mauReducer,
  // tunnelStore: tunnelReducer,
  // occupancyStore: occupancyReducer,
  // conferenceStore: conferenceReducer,
  // linkwidgetStore: linkwidgetReducer,
  // linkStore: linkReducer,

  // // COLLABORATION
  // taskStore: taskReducer,
  // todoStore: todoReducer,
  // linkedStore: linkedReducer,
  // groupsStore: groupsReducer,
  // communityStore: communityReducer,
  // summaryStore: summaryReducer,
  // whiteboardStore: whiteboardReducer,

  // // HCM
  // sitesStore: sitesReducer,
  // organizationStore: organizationReducer,

  // TICKET
  statisticStore: statisticReducer,
  historicalStore: historicalReducer,
  monitoringStore: monitoringReducer,

  ticketStore: ticketReducer,
  interactionStore: interactionReducer,
  tickethandlingStore: tickethandlingReducer,
  handlingStore: handlingReducer,
  segmentStore: segmentReducer,
  acknowledgeStore: acknowledgeReducer,

  peopleStore: peopleReducer,
  organizationStore: organizationReducer,
  workgroupStore: workgroupReducer,

  bucketStore: bucketReducer,
  macroStore: macroReducer,
  formsStore: formsReducer,
  fieldsStore: fieldsReducer,
  
  statusStore: statusReducer,
  resolutionStore: resolutionReducer,
  classificationStore: classificationReducer,
  reasoningStore: reasoningReducer,

  calendarStore: calendarReducer,
  routingStore: routingReducer,
  slaStore: slaReducer,
  automationStore: automationReducer,
  csatStore: csatReducer,

  requesterStore: requesterReducer,
  knowledgeStore: knowledgeReducer,

  reportStore: reportReducer,

  explorerStore: explorerReducer,
  reportingStore: reportingReducer,
  widgetStore: widgetReducer,
  analyticStore: analyticReducer,

  // //CRM PLATFORM
  // dashboardStore: dashboardReducer,
  // prospectStore: prospectReducer,
  // closingStore: closingReducer,
  // purchaseStore: purchaseReducer,
  // subscribeStore: subscribeReducer,
  // custbillStore: custbillReducer,
  // custpaymentStore: custpaymentReducer,
  // voucherStore: voucherReducer,
  // discountStore: discountReducer,
  // reachStore: reachReducer,
  // acquisitionStore: acquisitionReducer,
  // conversionStore: conversionReducer,
  // retentionStore: retentionReducer,
  // loyaltyStore: loyaltyReducer,

  // // COLLECT
  // collectingStore: collectingReducer,
  // debtorStore: debtorReducer,
  // interactionStore: interactionReducer,
  // bucketStore: bucketReducer,
  // allocationStore: allocationReducer,
  // collectorStore: collectorReducer,
  // worksheetStore: worksheetReducer,
  // commissionStore: commissionReducer,
  // incentiveStore: incentiveReducer,
  // achievementStore: achievementReducer,
  // inhouseStore: inhouseReducer,
  // activityStore: activityReducer,
  // repositoryStore: repositoryReducer,

  //SYSTEM
  backendStore: backendReducer,
  workflowStore: workflowReducer,
  schedulerStore: schedulerReducer,
  watchdogStore: watchdogReducer,
  disasterStore: disasterReducer,
  errorStore: errorReducer,
  audittrailStore: audittrailReducer,
  apilogStore: apilogReducer,

  // loadingBar: loadingBarReducer,
  form: reduxFormReducer,
})

export default ovisonereducers
