import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import RptTicketSearch from '../../libraries/service/RptTicketSearch'
import RptTicketTimeline from '../../libraries/service/RptTicketTimeline'
import RptTicketPerformance from '../../libraries/service/RptTicketPerformance';
import RptTicketClassification from '../../libraries/service/RptTicketClassification';
import RptTicketService from '../../libraries/service/RptTicketService';
import RptTicketResolution from '../../libraries/service/RptTicketResolution';

class RptTicketBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        ticketId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, ticketId: id, clientId: clientId})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        ticketId: 0,
      });
    }

    render() {
      const {mode, ticketId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div>
          <RptTicketSearch setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} mode={mode} menu={this.props.menu} />
          {this.props.menu=='performance' && <RptTicketPerformance setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
          {this.props.menu=='activity' && <RptTicketClassification setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
          {this.props.menu=='timeline' && <RptTicketTimeline setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
          
          {this.props.menu=='classification' && <RptTicketClassification setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
          {this.props.menu=='service' && <RptTicketService setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
          {this.props.menu=='resolution' && <RptTicketResolution setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
        </div>
      )
    }
}

export default RptTicketBody
