import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { groupOptions, conditionOptions, explorerOptions }  from '../../modules/service/StaticMaster'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, SearchField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetExplorer, getExplorer,updateExplorer, createExplorer } from "../../actions/service/explorerAction"
import { listUser, listWorkgroup } from "../../actions/service/masterAction"


//CONNECT REDUX STORE
const selector = formValueSelector('ExplorerForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    explorerStore: state.explorerStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listUser:(objParam) => dispatch(listUser(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),

    resetExplorer:(objParam) => dispatch(resetExplorer(objParam)),
    getExplorer:(objParam) => dispatch(getExplorer(objParam)),
    createExplorer:(objParam) => dispatch(createExplorer(objParam)),
    updateExplorer:(objParam) => dispatch(updateExplorer(objParam)),
  }
}

class ExplorerForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      rowsWidget: null,
    }
  }

  componentDidMount(){
    this.props.listUser()
    this.props.listWorkgroup()

    this.props.resetExplorer(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getExplorer({explorerId: this.props.explorerId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.explorerStore.updateExplorer!=undefined ? this.props.explorerStore.updateExplorer : {}) : (this.props.explorerStore.createExplorer!=undefined ? this.props.explorerStore.createExplorer : {}))

    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
    if (!this.state.rowsWidget && this.props.rowsWidget) {
      this.setState({
        rowsWidget: this.props.rowsWidget,
      })
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateExplorer({...values, rowsWidget: this.state.rowsWidget}) : this.props.createExplorer({...values, rowsWidget: this.state.rowsWidget})
  }

  setColumnWidget(i, j) {
    const rowsWidget= this.state.rowsWidget || [];
    rowsWidget[i]= j;
    this.setState({
      rowsWidget: rowsWidget,
    })
  }

  incrRowQty(isIncrease, kind) {
    console.log(kind, this.props[kind +'ExploreQty'])
    if (isIncrease) {
      if (this.props[kind +'ExploreQty']<10) {
        this.props.dispatch(change('ExplorerForm', kind +'ExploreQty', ((isNaN(this.props[kind +'ExploreQty']) ? 0 : parseInt(this.props[kind +'ExploreQty'])) +1)))
      }
    } else {
      if (this.props[kind +'ExploreQty']>1) {
        this.props.dispatch(change('ExplorerForm', kind +'ExploreQty', ((isNaN(this.props[kind +'ExploreQty']) ? 0 : parseInt(this.props[kind +'ExploreQty'])) -1)))
      }
    }
  }

  drawExplores(qty=1, kind) {
    const {rowsWidget}= this.state
    const tickets= this.props[kind +'Tickets'] || []
    
    // console.log('!!! CONDITIONS DRAW', conditionsQty)
    const items= [];
    for (var i=0; i<qty; i++) {
      items[i]= {
        item: tickets[i] ? tickets[i].item : null,
      };
    }
    return (<>
      {
        items.map((item, i)=>{
          return(
            <Segment key={i}>
              <Button.Group size='mini'>
                <Popup content='Single column'  position= 'bottom center'
                trigger={
                  <Button onClick={this.setColumnWidget.bind(this, i, 1)} color={(rowsWidget && rowsWidget[i]==1) ? 'yellow' : null} type='button' style={{width: '5em', padding: '.1em'}} icon><Icon color='orange' name='circle' /></Button>
                }
                inverted />
                <Button.Or></Button.Or>
                <Popup content='Two (2) columns'  position= 'bottom center'
                trigger={
                  <Button onClick={this.setColumnWidget.bind(this, i, 2)} color={(rowsWidget && rowsWidget[i]==2) ? 'yellow' : null} type='button' style={{width: '5em', padding: '.1em'}} icon><Icon color='orange' name='circle' /><Icon color='blue' name='circle' /></Button>
                }
                inverted />
                <Button.Or></Button.Or>
                <Popup content='Three (3) columns'  position= 'bottom center'
                trigger={
                  <Button onClick={this.setColumnWidget.bind(this, i, 3)} color={(!rowsWidget || (rowsWidget && !rowsWidget[i]) || (rowsWidget && rowsWidget[i]==3)) ? 'yellow' : null} type='button' style={{width: '5em', padding: '.1em'}} icon><Icon color='orange' name='circle' /><Icon color='blue' name='circle' /><Icon color='green' name='circle' /></Button>
                }
                inverted />
              </Button.Group>
              {rowsWidget && rowsWidget[i]==1 && <Form.Group widths='equal' style={{marginTop: '.5em'}}>
                <Field name={'columnWidgetA_'+ i} component={SelectField}
                options={explorerOptions('widget')}
                validate= {[required]}
                placeholder='Select widget here' />
              </Form.Group>}
              {rowsWidget && rowsWidget[i]==2 && <Form.Group widths='equal' style={{marginTop: '.5em'}}>
                <Field name={'columnWidgetA_'+ i} component={SelectField}
                options={explorerOptions('widget')}
                validate= {[required]}
                placeholder='Select widget here' />
                <Field name={'columnWidgetB_'+ i} component={SelectField}
                options={explorerOptions('widget')}
                validate= {[required]}
                placeholder='Select widget here' />
              </Form.Group>}
              {(rowsWidget && rowsWidget[i]==3) && <Form.Group widths='equal' style={{marginTop: '.5em'}}>
                <Field name={'columnWidgetA_'+ i} component={SelectField}
                options={explorerOptions('widget')}
                validate= {[required]}
                placeholder='Select widget here' />
                <Field name={'columnWidgetB_'+ i} component={SelectField}
                options={explorerOptions('widget')}
                validate= {[required]}
                placeholder='Select widget here' />
                <Field name={'columnWidgetC_'+ i} component={SelectField}
                options={explorerOptions('widget')}
                validate= {[required]}
                placeholder='Select widget here' />
              </Form.Group>}
            </Segment>
          )
        })
      }
    </>)
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.explorerStore.getExplorer!=undefined && this.props.explorerStore.getExplorer.progress=='1' ? this.props.explorerStore.getExplorer : {})
    const { progress, code, text }= (mode==2 ? (this.props.explorerStore.updateExplorer!=undefined ? this.props.explorerStore.updateExplorer : {}) : (this.props.explorerStore.createExplorer!=undefined ? this.props.explorerStore.createExplorer : {}))
    
    const listUser= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])
    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    
    return (
      <div style={{padding: '1em 1.5em'}}>
        <div style={{position: 'absolute', marginTop: '-3em', right: '1.7em', display: 'inline-block', background: '#222', padding: '.2em .5em .6em .6em', borderRadius: '.5em'}}><Icon name='window close' color='yellow' style={{cursor: 'pointer', padding: '0', margin: '0', fontSize: '2em'}} onClick={this.props.closeModal.bind(this, pristine)}/></div>
      
        <Header as='h4' style={{margin: '0 0 2em 0'}}>
          <Icon name='gamepad' style={{fontSize: '1.3em', transform: 'rotate(30deg)'}} />
          <Header.Content>
            Dashboard Composer / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
          </Header.Content>
        </Header>

        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Grid stackable doubling>
            <Grid.Row columns={16}>
              <Grid.Column width={6}>
                <Header as='h5'>
                  <Header.Content>
                    Dashboard Title
                    <Header.Subheader>Short description of dashboard scheme.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='name' component={InputField}
                    validate= {[required]}
                    label='Name'
                    placeholder='Name' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='descript' component={TextAreaField}
                    label='Description'
                    placeholder='Additional information' />
                  </Form.Group>
                </Segment>

                <Header as='h5'>
                  <Header.Content>
                    Availability & Access
                    <Header.Subheader>Applied dashboard scheme on certain privilege.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='workgroups' component={SelectField}
                    multiple
                    options={listWorkgroup}
                    // validate= {[required]}
                    label='Teams / Workgroups'
                    placeholder='Available for certain teams' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='groups' component={SelectField}
                    multiple
                    options={groupOptions()}
                    // validate= {[required]}
                    label='Roles / Groups'
                    placeholder='Available for certain groups' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='users' component={SelectField}
                    multiple
                    options={listUser}
                    // validate= {[required]}
                    label='Users / Accounts'
                    placeholder='Available for certain accounts' />
                  </Form.Group>
                </Segment>
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as='h5'>
                  <Header.Content>
                    Dashboard Layout
                    <Header.Subheader>Set dashboard layout for widget.</Header.Subheader>
                  </Header.Content>
                </Header>

                {this.drawExplores(this.props.rowExploreQty, 'row')}
                <div style={{textAlign: 'center', marginTop: '2em'}}>
                  <Button.Group size='mini'>
                    <Popup content='Remove / less widget rows'  position= 'bottom center'
                    trigger={
                      <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, false, 'row')}><Icon color='red' name='arrow up' size='large' /></Button>
                    }
                    inverted />
                    <Button.Or></Button.Or>
                    <Popup content='Add more widget rows'  position= 'bottom center'
                    trigger={
                      <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, true, 'row')}><Icon color='blue' name='arrow down' size='large' /></Button>
                    }
                    inverted />
                  </Button.Group>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Dashboard' : 'Insert Dashboard') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.explorerStore.getExplorer!=undefined && state.explorerStore.getExplorer.progress==1 ? state.explorerStore.getExplorer.data : {rowExploreQty: 1}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      rowsWidget: selector(state, 'rowsWidget'),
      rowExploreQty: selector(state, 'rowExploreQty'),
    }),
    { load: getExplorer }
  )
  (reduxForm({
    form: 'ExplorerForm',	// a unique identifier for this form
  })(ExplorerForm)
  )
)
