export default function monitoringReducer (state = {}, action) {
  switch (action.type) {
    case 'MONITORING_RESET': {
        return({
            ...state,
            ticketMonitoring: {},
            segmentationMonitoring: {},
            occupancyMonitoring: {},
        })
    }

    case 'MONITORING_TICKET_PENDING': {
        return({...state, ticketMonitoring: {progress: 0}})
    }
    case 'MONITORING_TICKET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, ticketMonitoring: {progress: 2, payload: action.payload}})
        } else {
            return({...state, ticketMonitoring: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MONITORING_TICKET_REJECTED': {
        return({...state, ticketMonitoring: {progress: 2, payload: action.payload}})
    }

    case 'MONITORING_SATISFACTION_PENDING': {
      return({...state, satisfactionMonitoring: {progress: 0}})
    }
    case 'MONITORING_SATISFACTION_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, satisfactionMonitoring: {progress: 2, payload: action.payload}})
        } else {
            return({...state, satisfactionMonitoring: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MONITORING_SATISFACTION_REJECTED': {
        return({...state, satisfactionMonitoring: {progress: 2, payload: action.payload}})
    }

    case 'MONITORING_COMPLIANCE_PENDING': {
      return({...state, complianceMonitoring: {progress: 0}})
    }
    case 'MONITORING_COMPLIANCE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, complianceMonitoring: {progress: 2, payload: action.payload}})
        } else {
            return({...state, complianceMonitoring: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MONITORING_COMPLIANCE_REJECTED': {
        return({...state, complianceMonitoring: {progress: 2, payload: action.payload}})
    }

    case 'MONITORING_OCCUPANCY_PENDING': {
      return({...state, complianceMonitoring: {progress: 0}})
    }
    case 'MONITORING_OCCUPANCY_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, occupancyMonitoring: {progress: 2, payload: action.payload}})
        } else {
            return({...state, occupancyMonitoring: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MONITORING_OCCUPANCY_REJECTED': {
        return({...state, occupancyMonitoring: {progress: 2, payload: action.payload}})
    }

    case 'MONITORING_SEGMENTATION_PENDING': {
      return({...state, segmentationMonitoring: {progress: 0}})
    }
    case 'MONITORING_SEGMENTATION_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, segmentationMonitoring: {progress: 2, payload: action.payload}})
        } else {
            return({...state, segmentationMonitoring: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MONITORING_SEGMENTATION_REJECTED': {
        return({...state, segmentationMonitoring: {progress: 2, payload: action.payload}})
    }

    case 'MONITORING_POPULATION_PENDING': {
      return({...state, populationMonitoring: {progress: 0}})
    }
    case 'MONITORING_POPULATION_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, populationMonitoring: {progress: 2, payload: action.payload}})
        } else {
            return({...state, populationMonitoring: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MONITORING_POPULATION_REJECTED': {
        return({...state, populationMonitoring: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}