import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Statistic,
} from 'semantic-ui-react'

import numeral from "numeral"

import { format, parse } from "date-fns"
import { periodOptions}  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, SelectField, DatePickerField, LabelSelectField, LabelDatePickerField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { listClient } from "../../actions/service/masterAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,

    monitoringStore: state.monitoringStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listClient:(objParam) => dispatch(listClient(objParam)),
  }
}

const dummy={
  summary: {
    open: 30,
    progress: 25,
    closed: 10,
  },
  tickets: [

  ],
}

class HistoricalSearch extends Component {
  constructor(props) {
    super(props)
    this.state= {
      searchMode: true,
      lastChange: null,
    }
  }

  componentDidMount() {
    // this.props.listClient({search: {clientId: this.props.clientId}})
  }

  submitForm(values) {
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeDate(key, v) {
    var values= []
    values[key]= v
  }

  onChangeInput(e, v) {
    const { search, paging }= (this.props.monitoringStore.ticketMonitoring!==undefined ? this.props.monitoringStore.ticketMonitoring : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        // parent.props.ticketMonitoring({search: {...search, keyword: v}})
      }
    }, 500)
  }
  
  onChangeSelect(e, v) {
  }

  setMode(v) {
    this.props.setMode(v)
  }
  
  render() {
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode, stream, agent, vendor, backend, product }=this.props

    return (
      <div style={{padding: '1em', background: 'rgba(230, 230, 230, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
        <div style={{margin: '0', border: '0px solid rgba(100,100,100,.2)'}}>
          <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Form.Group widths='equal'>
                  <Field name='keyword' component={LabelInputField} onChange= {this.onChangeInput.bind(this)}
                  width={12} 
                  label='Keyword'
                  placeholder= 'Search by keyword ... <ENTER>' />
                  <Field name='groupingDate' component={LabelDatePickerField} onChange= {this.onChangeDate.bind(this, 'groupingDate')}
                  width={4} 
                  label='Date'
                  placeholder= 'Period Date' />
                </Form.Group>
              </div>
            </div>
          </Form>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.monitoringStore.ticketMonitoring!=undefined && state.monitoringStore.ticketMonitoring.progress==1 ? state.monitoringStore.ticketMonitoring.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'HistoricalSearch',	// a unique identifier for this form
  })(HistoricalSearch)
  )
)