import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Placeholder } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import ServiceDashboardBody from "./ServiceDashboardBody"
import ServiceTicketBody from "./ServiceTicketBody"
import ServiceReportsBody from "./ServiceReportsBody"

import ServiceSettingBody from "./ServiceSettingBody"
import ServiceWorkspaceBody from './ServiceWorkspaceBody';
import ServiceCustomerBody from './ServiceCustomerBody';

import ProfileBody from '../halo/ProfileBody'
import AdminMessageBody from '../halo/MessageBody'
import AgentMessageBody from '../agent/MessageBody'

class ServiceBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { mode, module, screenWidth, screenHeight, mini, modal, params, token }= this.props

    console.log(token)
    if (token.groupId=='Client') {
      return (
        <div>
          {module=='dashboard' && <ServiceDashboardBody token={token} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} mini={mini} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
          {/* {module=='ticket' && <ServiceTicketBody token={token} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} mini={mini} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />} */}
          {module=='mypage' && <ServiceWorkspaceBody token={token} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} mini={mini} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
          {/* {module=='customer' && <ServiceCustomerBody token={token} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} mini={mini} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />} */}
          {module=='reports' && <ServiceReportsBody token={token} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} mini={mini} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
          {module=='setting' && <ServiceSettingBody token={token} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} mini={mini} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
          
          {modal==='profile' && <ModalContainer size='small' content={<ProfileBody token={token} screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
          {modal==='message' && <ModalContainer size='small' content={<AdminMessageBody screenWidth={screenWidth} />} closeModal={this.props.closeModal} /> }
        </div>
      )
    } else if (token.groupId=='Supervisor' || token.groupId=='Team Leader') {
      return (
        <div>
          {module=='dashboard' && <ServiceDashboardBody token={token} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} mini={mini} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
          {/* {module=='ticket' && <ServiceTicketBody token={token} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} mini={mini} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />} */}
          {module=='mypage' && <ServiceWorkspaceBody token={token} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} mini={mini} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
          {/* {module=='customer' && <ServiceCustomerBody token={token} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} mini={mini} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />} */}
          {module=='reports' && <ServiceReportsBody token={token} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} mini={mini} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
          {/* {module=='setting' && <ServiceSettingBody token={token} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} mini={mini} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />} */}
          
          {modal==='profile' && <ModalContainer size='small' content={<ProfileBody token={token} screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
          {modal==='message' && <ModalContainer size='small' content={<AdminMessageBody screenWidth={screenWidth} />} closeModal={this.props.closeModal} /> }
        </div>
      )
    } else if (token.groupId=='Agent') {
      return (
        <div>
          <ServiceWorkspaceBody token={token} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} mini={mini} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />

          {modal==='profile' && <ModalContainer size='small' content={<ProfileBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
          {modal==='message' && <ModalContainer size='small' content={<AgentMessageBody screenWidth={screenWidth} />} closeModal={this.props.closeModal} /> }
        </div>
      )
    } else {
      return(<>{token.groupId}</>)
    }
  }
}

export default ServiceBody