import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import RptOJKSearch from '../../libraries/service/RptOJKSearch';
import RptOJKService from '../../libraries/service/RptOJKService';
import RptOJKChannel from '../../libraries/service/RptOJKChannel';
import RptOJKReasoning from '../../libraries/service/RptOJKReasoning';
import RptOJKDuration from '../../libraries/service/RptOJKDuration';

class RptOJKBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        peopleId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, peopleId: id, clientId: clientId})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        peopleId: 0,
      });
    }

    render() {
      const {mode, peopleId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div>
          <RptOJKSearch setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} menu={this.props.menu} mode={mode} />
          {this.props.menu==='product' && <RptOJKService setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
          {this.props.menu==='channel' && <RptOJKChannel setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
          {this.props.menu==='reasoning' && <RptOJKReasoning setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
          {this.props.menu==='duration' && <RptOJKDuration setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
        </div>
      )
    }
}

export default RptOJKBody
