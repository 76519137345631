
import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import ExplorerBrowse from '../../libraries/service/ExplorerBrowse'
import ExplorerForm from '../../libraries/service/ExplorerForm'
import ExplorerSearch from '../../libraries/service/ExplorerSearch'



class ExplorerBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        explorerId: 0,
      };
    }

    setMode(mode, id, param) {
      this.setState({mode: mode, explorerId: id, param: param})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        explorerId: 0,
      });
    }

    render() {
      const {mode, explorerId, param}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div>
          <ExplorerSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
        
          {<ExplorerBrowse mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} openModal={this.props.openModal} closeModal={this.closeModal.bind(this)} />}
          {/* {mode==2 && <ExplorerForm mode={mode} explorerId={explorerId} param={param} setMode={this.setMode.bind(this)} />}
          {mode==3 && <ExplorerForm mode={mode} explorerId={0} param={param} setMode={this.setMode.bind(this)} />} */}
          {mode==2 && <ModalContainer headless size='large' content={<ExplorerForm mode={mode} explorerId={explorerId} param={param} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
          {mode==3 && <ModalContainer headless size='large' content={<ExplorerForm mode={mode} explorerId={0} param={param} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
        </div>
      )
    }
}

export default ExplorerBody
