import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Statistic, Card } from 'semantic-ui-react'
import ModalContainer from '../../libraries/common/ModalContainer';

import TicketBody from '../service/TicketBody'
import HandlingBody from '../service/HandlingBody'
import SegmentBody from '../service/SegmentBody'
import AcknowledgeBody from '../service/AcknowledgeBody'

import MenuLeft from '../../libraries/service/MenuLeftTicket'
import TicketWidget from '../../libraries/widget/service/TicketWidget';
import SLAWidget from '../../libraries/widget/service/SLAWidget';
import TicketeventBody from './TicketeventBody';
import AssignmentBody from './AssignmentBody';
import ActionBody from './ActionBody';

import PipelineBody from './PipelineBody';


class ServiceTicketBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { mode, module, screenWidth, screenHeight, mini, modal, params, groupId }= this.props

    return (
      <div>
        <div style={{marginTop: '3.2em'}}>
          <div style={{display: 'flex'}}>
            <div style={{background: 'linear-gradient(0deg, rgb(186, 168, 255) 0%, rgb(87, 197, 255) 65%, rgb(128 188 255) 100%)', width: '3.5em', paddingTop: '5em', textAlign: 'center'}}>
              <MenuLeft mode={this.props.mode} screenHeight={screenHeight-75} screenWidth={screenWidth} module={module} groupId={groupId} openModule={this.props.openModule} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}/>
            </div>
            <div style={{flex: '1', padding: '1em .5em'}}>
              {/* Recent Ticket | Alert Warning Ticket | Closed, In Progress Ticket | Escalated | Dispositioned / Dispatched | */}
              {!mode && <PipelineBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} />}
              {mode==='ticket' && <TicketBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} />}
              {mode==='handling' && <HandlingBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} />}
              {mode==='acknowledge' && <AcknowledgeBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} />}
              {mode==='segment' && <SegmentBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} />}
            </div>
            <div style={{background: '#f8f8f8', padding: '2em 1.5em 2em 1.5em'}}>
              <SLAWidget />
              <Divider />
              <TicketWidget />
            </div>
          </div>
        </div>
        {modal==='event' && <ModalContainer size='small' content={<TicketeventBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
        {modal && modal.substring(0, 8)==='assigned' && <ModalContainer size='small' modal={modal && modal.substring(8)} content={<AssignmentBody screenWidth={screenWidth} screenHeight={screenHeight} params={params} closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
        {modal && modal.substring(0, 6)==='action' && <ModalContainer size='small' modal={modal && modal.substring(7)} content={<ActionBody screenWidth={screenWidth} screenHeight={screenHeight}  params={params} closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
      </div>
    )
  }
}

export default ServiceTicketBody