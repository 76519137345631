import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table, Feed, Label } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import DismissableMessage from '../../common/DismissableMessage'
import { personalOptions, ticketOptions }  from '../../../modules/service/StaticMaster'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, LabelSelectField } from '../../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../../validation/validation'

import { connect } from "react-redux"
import { checkToken } from "../../../actions/auth/authAction"
import { resetTicket, getTicket,updateTicket, createTicket } from "../../../actions/service/ticketAction"
import { resetInteraction, getInteraction,updateInteraction, createInteraction } from "../../../actions/service/interactionAction"
import { listUser, listWorkgroup, listTicket } from "../../../actions/service/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('CompositionBox')

const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
    masterStore: state.masterStore,
    ticketStore: state.ticketStore,
    interactionStore: state.interactionStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),

    listUser:(objParam) => dispatch(listUser(objParam)),
    listTicket:(objParam) => dispatch(listTicket(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),

    resetTicket:(objParam) => dispatch(resetTicket(objParam)),
    getTicket:(objParam) => dispatch(getTicket(objParam)),
    createTicket:(objParam) => dispatch(createTicket(objParam)),
    updateTicket:(objParam) => dispatch(updateTicket(objParam)),

    resetInteraction:(objParam) => dispatch(resetInteraction(objParam)),
    getInteraction:(objParam) => dispatch(getInteraction(objParam)),
    createInteraction:(objParam) => dispatch(createInteraction(objParam)),
    updateInteraction:(objParam) => dispatch(updateInteraction(objParam)),
  }
}

class CompositionBox extends Component {
  constructor(props) {
    super(props)
    this.state= {
      box: 'summary',
    }
  }

  componentDidMount(){
    console.log('!!! DID MOUNT', this.props.ticket, this.props.action)

    this.props.resetInteraction(this.props.mode)
    this.props.listUser()
    this.props.listTicket()
    this.props.listWorkgroup()
  }

  componentDidUpdate() {
    const { progress, code, text }= (this.props.interactionStore.createInteraction!=undefined ? this.props.interactionStore.createInteraction : {})

    if (progress==1 && code=='00') {
      // setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    values['action']= ((this.props.split && 'split') || 'merge');
    values['owner']= this.checkRole();
    values['privacy']= this.state.privacy;
    values['ticketId']= (this.props.ticket && this.props.ticket.ticketId);

    this.props.createInteraction({...values});
  }

  checkRole() {
    const token= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken.data : {}
    const ticket= this.props.ticket
    if (ticket) {
      //requesterUserId, requesterWorkgroupId, dispatcherUserId, assigneeUserId, assigneeWorkgroupId, assigneeUsers, assigneeWorkgroups, 
      // escalationUserId, escalationWorkgroupId, escalationUsers,escalationWorkgroups, mentionUsers, mentionWorkgroups
      return(
        (ticket.escalationUserId==token.userId && 'escalation') ||  
        (ticket.escalationUsers && ticket.escalationUsers.indexOf(token.userId)>=0 && 'escalation') || 
        (ticket.assigneeUserId==token.userId && 'assignee') || 
        (ticket.requesterUserId==token.userId && 'requester') || 
        (ticket.dispatcherUserId==token.userId && 'dispatcher') || 
        (ticket.mentionUsers && ticket.mentionUsers.indexOf(token.userId)>=0 && 'follower') || 'follower'
      )
    }
  }

  drawSplitTicket(qty) {
    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    const splits= [];

    for (var i=0;i<qty;i++) {
      console.log(i)
      splits.push(i);
    }
    console.log(splits)
    return (<>
      {splits.map(i=>{
        return(<>
          <Segment attached='top'>
            <Header as='h5'>
              <Label color='orange' size='large' circular style={{margin: '0 .5em 0 0', width: '2.2em'}}>{i+1}</Label>
              <Header.Content>
                <b>New Split Ticket</b>
                <Header.Subheader>New ticket as result of split process.</Header.Subheader>
              </Header.Content>
            </Header>
            <Form.Group widths='equal' style={{marginTop: '1em'}}>
              <Field name={'subject'+ i} component={InputField}
              label={<b>Subject</b>}
              placeholder='Subject' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name={'activity'+ i} component={TextAreaField}
              label={<b>Message</b>}
              placeholder='Message / Description' />
            </Form.Group>
          </Segment>
          <Segment attached='bottom'>
            <Form.Group widths='equal'>
              <Field name={'assigneeWorkgroups'+ i} component={SelectField}
              multiple
              options={listWorkgroup}
              label={<b>Assignee</b>}
              placeholder='Assignee' />
            </Form.Group>
          </Segment>
        </>)
      })}
    </>)
  }
  
  drawMergeTicket(qty) {
    const listTicket= (this.props.masterStore.listTicket!=undefined && this.props.masterStore.listTicket.progress=='1' ? this.props.masterStore.listTicket.data : [])
    const merges= [];

    for (var i=0;i<qty;i++) {
      console.log(i)
      merges.push(i);
    }
    console.log(merges)
    return (<>
      {merges.map(i=>{
        return(<>
          <Segment attached='top'>
            <Header as='h5'>
              <Label color='teal' size='large' circular style={{margin: '0 .5em 0 0', width: '2.2em'}}>{i+1}</Label>
              <Header.Content>
                <b>Merge From This Ticket</b>
                <Header.Subheader>Use selected ticket as child of merging process.</Header.Subheader>
              </Header.Content>
            </Header>
            
            <Form.Group widths='equal'>
              <Field name='code' component={SelectField}
              options={listTicket}
              label={<b>Code</b>}
              placeholder='Ticket Code' />
            </Form.Group>
          </Segment>
          <Segment attached='bottom'>
            <Form.Group widths='equal'>
              <Field name={'subject'+ i} component={InputField}
              label={<b>Subject</b>}
              placeholder='Subject' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name={'activity'+ i} component={TextAreaField}
              label={<b>Description</b>}
              placeholder='Description' />
            </Form.Group>
          </Segment>
        </>)
      })}
    </>)
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (this.props.interactionStore.createInteraction!=undefined ? this.props.interactionStore.createInteraction : {})
    
    const token= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken.data : {}
    const listUser= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])
    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    
    return (
      <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Header as='h5'>
            <Icon name={(this.props.split && 'window restore outline') || (this.props.merge && 'cube')} circular inverted color='red' style={{fontSize: '1.1em'}} />
            <Header.Content>
              {(this.props.split && 'Split') || (this.props.merge && 'Merge')} Ticket
              <Header.Subheader>Composition or decomposition of ticket.</Header.Subheader>
            </Header.Content>
          </Header>
          {this.props.split && <>
            <Segment>
              <Header as='h5'>
                <Header.Content>
                  By <b>{token.name}</b>, {token.groupId}
                  <Header.Subheader>As <b>{this.checkRole()}</b> at <b>{format(new Date(), 'HH:mm')}</b> {format(new Date(), 'dd/MMM yyyy')}</Header.Subheader>
                </Header.Content>
              </Header>
              <Divider />
              <Form.Group widths='equal'>
                <Field name='splitQty' component={LabelSelectField}
                options={ticketOptions('sequence')}
                label='Split Ticket Into'
                placeholder='Qty of Ticket' />
              </Form.Group>
              <Divider />
              <div>
                <Header as='h5'>
                  Split current ticket into <b>{this.props.splitQty || '0'}</b> new tickets <b><i>exclude</i></b> current ticket.
                </Header>
              </div>
            </Segment>
            {this.drawSplitTicket(this.props.splitQty)}
          </>}
          {this.props.merge && <>
            <Segment>
              <Header as='h5'>
                <Header.Content>
                  By <b>{token.name}</b>, {token.groupId}
                  <Header.Subheader>As <b>{this.checkRole()}</b> at <b>{format(new Date(), 'HH:mm')}</b> {format(new Date(), 'dd/MMM yyyy')}</Header.Subheader>
                </Header.Content>
              </Header>
              <Divider />
              <Form.Group widths='equal'>
                <Field name='mergeQty' component={LabelSelectField}
                options={ticketOptions('sequence')}
                label='Merge Ticket From'
                placeholder='Qty of Child Ticket' />
              </Form.Group>
              <Divider />
              <div>
                <Header as='h5'>
                  Merge from selected <b>{this.props.mergeQty || '0'}</b> tickets into <b>current ticket</b> as parent ticket.
                </Header>
              </div>
            </Segment>
            {this.drawMergeTicket(this.props.mergeQty)}
          </>}

        <Segment>
          {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Ticket' : 'Insert Ticket') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
          
          <div style={{textAlign: 'right'}}>
            <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
            <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
          </div>
        </Segment>

      </Form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: {},
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      splitQty: selector(state, 'splitQty'),
      mergeQty: selector(state, 'mergeQty'),
    }),
    { load: getTicket }
  )
  (reduxForm({
    form: 'CompositionBox',	// a unique identifier for this form
  })(CompositionBox)
  )
)
