import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { format, parse, addSeconds } from "date-fns"
import {  } from "../../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { browseTicket, statusTicket } from "../../../actions/service/ticketAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    ticketStore: state.ticketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseTicket:(objParam) => dispatch(browseTicket(objParam)),
    statusTicket:(objParam) => dispatch(statusTicket(objParam)),
  }
}

class ConversationBox extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.ticketStore.browseTicket!==undefined ? this.props.ticketStore.browseTicket : {})
    this.props.browseTicket(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.ticketStore.browseTicket!==undefined ? this.props.ticketStore.browseTicket : {})
    this.props.browseTicket({search: {...search}, paging: paging})
  }

  render() {
    //console.log(this.props.ticketStore.browseTicket)
    const { screenWidth, screenHeight, channel }= this.props
    const { data, search, paging }= (this.props.ticketStore.browseTicket!==undefined ? this.props.ticketStore.browseTicket : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
        <div style={{flex: '1', padding: '0'}}>
          <Header as='h5'>
            {this.props.mode=='request' && 
              <Icon color='red' inverted circular name={(this.props.channel=='chat' && 'comments') || (this.props.channel=='twitter' && 'twitter') || (this.props.channel=='instagram' && 'instagram') || (this.props.channel=='facebook' && 'facebook') || (this.props.channel=='whatsapp' && 'whatsapp') || 'phone volume'} />
            }
            {this.props.mode=='conversation' && 
              <Icon name={'comments'} />
            }
            <Header.Content>
              {(this.props.mode=='request' && <b>Public Conversation</b>) || <b>Internal Conversation</b>}
              <Header.Subheader>Request conversation or side conversation is available.</Header.Subheader>
            </Header.Content>
          </Header>
          <Segment style={{minHeight: '100%'}}>
            {this.props.mode=='request' && <>
              {channel=='voice' && <Header as='h5'>
                Phone Call
                <Header.Subheader>10:00 23/Apr 2023, play</Header.Subheader>
              </Header>}
              {channel=='messaging' && <Header as='h5'>
                Email Messaging
                <Header.Subheader>10:00 23/Apr 2023, play</Header.Subheader>
              </Header>}
              {channel=='social' && <Header as='h5'>
                Twitter Direct Message
                <Header.Subheader>10:00 23/Apr 2023, play</Header.Subheader>
              </Header>}
            </>}
            {this.props.mode=='conversation' && <>
              <Header as='h5'>
                Side Conversation
                <Header.Subheader>10:00 23/Apr 2023, play</Header.Subheader>
              </Header>
            </>}
            <Divider />
            <div className='noscroll' style={{height: '300px', overflowY:'auto'}}>
              {this.props.mode=='conversation' && <>
                <div style={{display: 'flex'}}>
                  <div style={{flex: 1}}>
                    <Header as='h5'>
                      <Header.Content>
                        To: <b>Jack Agent</b>
                        <Header.Subheader>Public / By Agent XXXX</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div style={{padding: '.6em 0 0 1em'}}>
                    <Icon name='play' color='blue' style={{cursor: 'pointer'}}/>
                  </div>
                </div>
              </>}
            </div>
          </Segment>
        </div>
        {/* {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        } */}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationBox)
