export default function handlingReducer (state = {}, action) {
  switch (action.type) {
    case 'HANDLINGTICKET_BROWSE_PENDING': {
      return({...state, browseHandlingTicket: {progress: 0}})
    }
    case 'HANDLINGTICKET_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseHandlingTicket: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseHandlingTicket: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'HANDLINGTICKET_BROWSE_REJECTED': {
        return({...state, browseHandlingTicket: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}