import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'

import { groupOptions, ratingOptions }  from '../../modules/service/StaticMaster'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, SearchField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetCsat, getCsat,updateCsat, createCsat } from "../../actions/service/csatAction"
import { listChannel } from "../../actions/service/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('CsatForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    csatStore: state.csatStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listChannel:(objParam) => dispatch(listChannel(objParam)),

    resetCsat:(objParam) => dispatch(resetCsat(objParam)),
    getCsat:(objParam) => dispatch(getCsat(objParam)),
    createCsat:(objParam) => dispatch(createCsat(objParam)),
    updateCsat:(objParam) => dispatch(updateCsat(objParam)),
  }
}

class CsatForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remarkResult: null,

      tab: 0,
    }
  }

  componentDidMount(){
    this.props.resetCsat(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getCsat({csatId: this.props.csatId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.csatStore.updateCsat!=undefined ? this.props.csatStore.updateCsat : {}) : (this.props.csatStore.createCsat!=undefined ? this.props.csatStore.createCsat : {}))

    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateCsat({...values, feedCsatQty: this.props.feedCsatQty}) : this.props.createCsat({...values, feedCsatQty: this.props.feedCsatQty})
  }

  incrRowQty(isIncrease, kind) {
    console.log(kind, this.props[kind +'CsatQty'])
    if (isIncrease) {
      if (this.props[kind +'CsatQty']<10) {
        this.props.dispatch(change('CsatForm', kind +'CsatQty', ((isNaN(this.props[kind +'CsatQty']) ? 0 : parseInt(this.props[kind +'CsatQty'])) +1)))
      }
    } else {
      if (this.props[kind +'CsatQty']>1) {
        this.props.dispatch(change('CsatForm', kind +'CsatQty', ((isNaN(this.props[kind +'CsatQty']) ? 0 : parseInt(this.props[kind +'CsatQty'])) -1)))
      }
    }
  }

  drawCsat(qty=1, kind) {
    const csats= this.props[kind +'Csats'] || []
    
    // console.log('!!! CONDITIONS DRAW', conditionsQty)
    const items= [];
    for (var i=0; i<qty; i++) {
      items[i]= {
        item: csats[i] ? csats[i].item : null,
      };
    }
    return (<>
      {
        items.map((item, i)=>{
          return(<Segment>
            <Form.Group widths='16'>
              <Field name={'feedTitle_'+ i} component={InputField}
              width={6}
              placeholder='Commentary title' />
              <Field name={'feedComment_'+ i} component={InputField}
              width={10}
              placeholder='Commentary feedback' />
            </Form.Group>
          </Segment>)
        })
      }
    </>)
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.csatStore.getCsat!=undefined && this.props.csatStore.getCsat.progress=='1' ? this.props.csatStore.getCsat : {})
    const { progress, code, text }= (mode==2 ? (this.props.csatStore.updateCsat!=undefined ? this.props.csatStore.updateCsat : {}) : (this.props.csatStore.createCsat!=undefined ? this.props.csatStore.createCsat : {}))

    const listChannel= (this.props.masterStore.listChannel!=undefined && this.props.masterStore.listChannel.progress=='1' ? this.props.masterStore.listChannel.data : [])


    const levels= [];
    for (var i=0; i<this.props.maxLevel; i++) {
      levels.push(1);
    }
    return (
      <div style={{padding: '1em 1.5em'}}>
        <div style={{position: 'absolute', marginTop: '-3em', right: '1.7em', display: 'inline-block', background: '#222', padding: '.2em .5em .6em .6em', borderRadius: '.5em'}}><Icon name='window close' color='yellow' style={{cursor: 'pointer', padding: '0', margin: '0', fontSize: '2em'}} onClick={this.props.closeModal.bind(this, pristine)}/></div>
        
        <Header as='h4' style={{margin: '0 0 2em 0'}}>
          <Icon name='star outline' style={{fontSize: '1em'}}/>
          <Header.Content>
            Cust. Satisfaction (CSAT) / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
          </Header.Content>
        </Header>

        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Grid stackable doubling>
            <Grid.Row columns={16}>
              <Grid.Column width={6}>
                <Header as='h5'>
                  <Header.Content>
                    CSAT Title
                    <Header.Subheader>Short description of macro.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='name' component={InputField}
                    validate= {[required]}
                    label='Name'
                    placeholder='Name' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='descript' component={TextAreaField}
                    label='Description'
                    placeholder='Additional information' />
                  </Form.Group>
                </Segment>

                <Header as='h5'>
                  <Header.Content>
                    Comm. Channels
                    <Header.Subheader>Related channel communication.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='channels' component={SelectField}
                    multiple
                    validate= {[required]}
                    options={listChannel}
                    label='Comm. Chanel / Stream'
                    placeholder='Channel contact socmed, phone etc' />
                  </Form.Group>
                </Segment>

                <Header as='h5'>
                  <Header.Content>
                    Rating Scores
                    <Header.Subheader>Rating score feedback.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='maxLevel' component={SelectField}
                    options={ratingOptions('level')}
                    validate= {[required]}
                    label='Max. Rating Level'
                    placeholder='List of rating level' />
                    <Field name='maxScore' component={SelectField}
                    options={ratingOptions('score')}
                    validate= {[required]}
                    label='Max. Rating Score'
                    placeholder='Max. score rating' />
                  </Form.Group>
                </Segment>
                
                {this.props.maxLevel && <>
                  <Header as='h5'>
                    <Header.Content>
                      Rating Labels
                      <Header.Subheader>Rating icon and label or name.</Header.Subheader>
                    </Header.Content>
                  </Header>
                  <Segment>
                    {levels.map((item, i)=>{
                      return(
                        <Form.Group widths='equal'>
                          <Field name={'ratingIcon_'+ i} component={SelectField}
                          options={ratingOptions('icon')}
                          validate= {[required]}
                          placeholder='Rating icon' />
                          <Field name={'ratingName_'+ i} component={InputField}
                          validate= {[required]}
                          placeholder='Rating title' />
                        </Form.Group>)
                    })}
                  </Segment>
                </>}
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as='h5'>
                  <Header.Content>
                    Commentary Feedbacks
                    <Header.Subheader>Set commentary feedbacks from customer.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='16'>
                    <Field name='feedScore' component={SelectField}
                    width={6}
                    options={ratingOptions('score-min')}
                    label='Show On Min. Scoring'
                    placeholder='Show Only Less Than Min Scoring' />
                  </Form.Group>
                </Segment>
                {this.drawCsat(this.props.feedCsatQty, 'feed')}

                <div style={{textAlign: 'center', marginTop: '2em'}}>
                  <Button.Group size='mini'>
                    <Popup content='Remove / less commentaries'  position= 'bottom center'
                    trigger={
                      <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, false, 'feed')}><Icon color='red' name='arrow up' size='large' /></Button>
                    }
                    inverted />
                    <Button.Or></Button.Or>
                    <Popup content='Add more commentaries'  position= 'bottom center'
                    trigger={
                      <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, true, 'feed')}><Icon color='blue' name='arrow down' size='large' /></Button>
                    }
                    inverted />
                  </Button.Group>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Csat' : 'Insert Csat') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.csatStore.getCsat!=undefined && state.csatStore.getCsat.progress==1 ? state.csatStore.getCsat.data : {feedCsatQty: 1}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      maxLevel: selector(state, 'maxLevel'),
      feedCsatQty: selector(state, 'feedCsatQty'),
    }),
    { load: getCsat }
  )
  (reduxForm({
    form: 'CsatForm',	// a unique identifier for this form
  })(CsatForm)
  )
)
