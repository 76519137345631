import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Statistic,
} from 'semantic-ui-react'

import numeral from "numeral"

import { format, parse } from "date-fns"
import { periodOptions}  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, SelectField, DatePickerField, LabelSelectField, LabelDatePickerField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { listClient } from "../../actions/halo/masterAction"
import { ticketMonitoring, satisfactionMonitoring, complianceMonitoring, occupancyMonitoring, segmentationMonitoring, populationMonitoring } from "../../actions/service/monitoringAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,

    monitoringStore: state.monitoringStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listClient:(objParam) => dispatch(listClient(objParam)),

    ticketMonitoring:(objParam) => dispatch(ticketMonitoring(objParam)),
    satisfactionMonitoring:(objParam) => dispatch(satisfactionMonitoring(objParam)),
    complianceMonitoring:(objParam) => dispatch(complianceMonitoring(objParam)),
    occupancyMonitoring:(objParam) => dispatch(occupancyMonitoring(objParam)),
    segmentationMonitoring:(objParam) => dispatch(segmentationMonitoring(objParam)),
    populationMonitoring:(objParam) => dispatch(populationMonitoring(objParam)),
  }
}

const dummy={
  summary: {
    open: 30,
    progress: 25,
    closed: 10,
  },
  tickets: [

  ],
}

class DashboardSearch extends Component {
  constructor(props) {
    super(props)
    this.state= {
      searchMode: true,
      lastChange: null,
    }
  }

  componentDidMount() {
    this.props.listClient({search: {clientId: this.props.clientId}})
    
    const { search }= (this.props.monitoringStore.ticketMonitoring!==undefined ? this.props.monitoringStore.ticketMonitoring : {})
    this.props.ticketMonitoring({search: {grouping: 'Monthly', groupingDate: format(new Date(), 'yyyy-MM-dd'), ...search}})
    // this.props.satisfactionMonitoring({search: {...search}})
    // this.props.complianceMonitoring({search: {...search}})
    // this.props.occupancyMonitoring({search: {...search}})
    // this.props.segmentationMonitoring({search: {...search}})
    // this.props.populationMonitoring({search: {...search}})
  }

  submitForm(values) {
    const { search, paging }= (this.props.monitoringStore.ticketMonitoring!==undefined ? this.props.monitoringStore.ticketMonitoring : {})
    this.props.ticketMonitoring({search: {...values, ...search}})
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeDate(key, v) {
    const { search, paging }= (this.props.monitoringStore.ticketMonitoring!==undefined ? this.props.monitoringStore.ticketMonitoring : {})
    var values= []
    values[key]= v
    this.props.ticketMonitoring({search: {...search, ...values}})
    // this.props.satisfactionMonitoring({search: {...search, ...values}})
    // this.props.complianceMonitoring({search: {...search, ...values}})
    // this.props.occupancyMonitoring({search: {...search, ...values}})
    // this.props.segmentationMonitoring({search: {...search, ...values}})
    // this.props.populationMonitoring({search: {...search, ...values}})
  }

  onChangeInput(e, v) {
    const { search, paging }= (this.props.monitoringStore.ticketMonitoring!==undefined ? this.props.monitoringStore.ticketMonitoring : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        parent.props.ticketMonitoring({search: {...search, keyword: v}})
      }
    }, 500)
  }
  
  onChangeSelect(e, v) {
    const { search, paging }= (this.props.monitoringStore.ticketMonitoring!==undefined ? this.props.monitoringStore.ticketMonitoring : {})
    this.props.ticketMonitoring({search: {...search, grouping: v}})
    // this.props.satisfactionMonitoring({search: {...search, grouping: v}})
    // this.props.complianceMonitoring({search: {...search, grouping: v}})
    // this.props.occupancyMonitoring({search: {...search, grouping: v}})
    // this.props.segmentationMonitoring({search: {...search, grouping: v}})
    // this.props.populationMonitoring({search: {...search, grouping: v}})
  }

  setMode(v) {
    this.props.setMode(v)
  }
  
  render() {
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode, stream, agent, vendor, backend, product }=this.props

    const listClient= (this.props.masterStore.listClient!=undefined && this.props.masterStore.listClient.progress=='1' ? this.props.masterStore.listClient.data : [])

    var summTickets= [];
    
    for(var status in dummy.summary){
      // console.log(status + ': ' + dummy.summary[status]);
      summTickets.push({
        key: status,
        value: dummy.summary[status],
      })
    }
    
    return (
      <div style={{position: 'static', zIndex: '1000'}}>
        <div style={{padding: '0', background: 'rgba(230, 230, 230, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
          {/* <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              <Header as='h5'>
                <Icon name='chart area' style={{fontSize: '1em'}}/>
                <Header.Content>
                  Ticket Monitoring
                </Header.Content>
              </Header>
            </div>
            <div>
            </div>
          </div> */}

          <div style={{margin: '0', border: '0px solid rgba(100,100,100,.2)'}}>
            <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Form.Group widths='equal'>
                    <Field name='keyword' component={LabelInputField} onChange= {this.onChangeInput.bind(this)}
                    width={7} 
                    label='Keyword'
                    placeholder= 'Search ticket ... <ENTER>' />
                    {(agent || backend)&& <Field name='workgroup' component={LabelSelectField} onChange= {this.onChangeSelect.bind(this)}
                    options= {periodOptions('ticket')}
                    width={3} 
                    label='Group'
                    placeholder= 'Workgroup' />}
                    {(stream || vendor || product) && <Field name='service' component={LabelSelectField} onChange= {this.onChangeSelect.bind(this)}
                    options= {periodOptions('ticket')}
                    width={3} 
                    label='Service'
                    placeholder= 'Service' />}
                    <Field name='groupingDate' component={LabelDatePickerField} onChange= {this.onChangeDate.bind(this, 'groupingDate')}
                    width={3} 
                    label='Date'
                    placeholder= 'Period Date' />
                    <Field name='grouping' component={LabelSelectField} onChange= {this.onChangeSelect.bind(this)}
                    options= {periodOptions('ticket')}
                    width={3} 
                    label='Cycle'
                    placeholder= 'Cycle Period' />
                  </Form.Group>
                </div>
              </div>
              {/* <div style={{display: 'flex'}}>
                {summTickets.map((item, i)=>{
                  return(<div style={{flex: item.value || 1, background: '#fff', margin: '1px'}}>
                    <Button type='button' fluid 
                    onClick={this.setMode.bind(this, item.key)}
                    >({item.value}) {item.key.toUpperCase()}</Button>
                  </div>)
                })}
              </div> */}
            </Form>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.monitoringStore.ticketMonitoring!=undefined && state.monitoringStore.ticketMonitoring.progress==1 ? state.monitoringStore.ticketMonitoring.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'DashboardSearch',	// a unique identifier for this form
  })(DashboardSearch)
  )
)