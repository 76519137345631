import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Dropdown, Statistic } from 'semantic-ui-react'

import { parse, format, endOfMonth } from "date-fns"
import PieRechart from '../../chart/PieRechart';

import { connect } from "react-redux"
import { ticketMonitoring } from "../../../actions/service/monitoringAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    monitoringStore: state.monitoringStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ticketMonitoring:(objParam) => dispatch(ticketMonitoring(objParam)),
  }
}

class TicketWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      stamp: '',
      pieData: [
        {name: 'Response OK', value: 1},
        {name: 'Response NOK', value: 2},
        {name: 'Resolve OK', value: 3},
        {name: 'Resolve NOK', value: 4},
      ],
    };
  }
  
  componentDidUpdate() {
    const { pieData, stamp}= this.state
    const { data, search }= (this.props.monitoringStore.ticketMonitoring!==undefined ? this.props.monitoringStore.ticketMonitoring : {})

    const _stamp= (search && search.groupingDate && (search.grouping=='Daily' ? search.grouping +'-'+ search.groupingDate.substr(8,2) : search.grouping +'-'+ search.groupingDate.substr(5,2))) || '';

    // console.log('!!! DATA - SEARCH', _stamp, data, search)
    const values={
      stamp: '',
      resolution_NOK: 0,
      resolution_OK: 0,
      response_NOK: 0,
      response_OK: 0,
    }

    if (this.state.stamp!=_stamp) {
      if (search && data && data.compliances) {
        if (search && search.grouping=='Daily') {
          Object.entries(data.compliances).forEach(([dd, value]) => {
            // console.log('!!! DAILY', search.groupingDate && search.groupingDate.substr(8,2), dd, value)
            if (search.groupingDate && search.groupingDate.substr(8,2)==dd) {
              values.resolution_NOK+= parseInt(value.resolution_NOK);
              values.resolution_OK+= parseInt(value.resolution_OK);
              values.response_NOK+= parseInt(value.response_NOK);
              values.response_OK+= parseInt(value.response_OK);
            }
          });
        } else if (search && search.grouping=='Monthly') {
          Object.entries(data.compliances).forEach(([dd, value]) => {
            // console.log('!!! MONTHLY', dd, value)
            values.resolution_NOK+= parseInt(value.resolution_NOK);
            values.resolution_OK+= parseInt(value.resolution_OK);
            values.response_NOK+= parseInt(value.response_NOK);
            values.response_OK+= parseInt(value.response_OK);
          });
          
          // console.log('!!! MONTHLY VALUES', values)
        }
      }

      this.setState({
        stamp: _stamp,
        pieData: [
          {name: 'Response OK', value: values.response_OK},
          {name: 'Response NOK', value: values.response_NOK},
          {name: 'Resolve OK', value: values.resolution_OK},
          {name: 'Resolve NOK', value: values.resolution_NOK},
        ]
      });
    }
  }
  
  render() {
    const { screenWidth,  screenHeight}= this.props
    const { pieData}= this.state
    
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em', width: '9em'}}>
        <Header as='h5'>
          <Icon name='fire' style={{fontSize: '1em'}} />
          <Header.Content>
            <b><i>Live !</i></b> Ticket
            {/* <Header.Subheader>Ticket response and resolve time based on SLA.</Header.Subheader> */}
          </Header.Content>
        </Header>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em', width: '9em', textAlign: 'center'}}>
        <Statistic size='mini' style={{margin: '.5em 0', width: '6em'}}>
          <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
            <b>8</b>
          </Statistic.Value>
          <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>OPEN</Statistic.Label>
        </Statistic>
        <Statistic size='mini' style={{margin: '.5em 0', width: '6em'}}>
          <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
            <b>14</b>
          </Statistic.Value>
          <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>CLOSED</Statistic.Label>
        </Statistic>
        <Statistic size='mini' style={{margin: '.5em 0', width: '6em'}}>
          <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
            <b>2</b>
          </Statistic.Value>
          <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>OVERDUE</Statistic.Label>
        </Statistic>
        <Statistic size='mini' style={{margin: '.5em 0', width: '6em'}}>
          <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
            <b>4</b>
          </Statistic.Value>
          <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>PROGRESS</Statistic.Label>
        </Statistic>
        <Statistic size='mini' style={{margin: '.5em 0', width: '6em'}}>
          <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
            <b>2</b>
          </Statistic.Value>
          <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>NEW TICKET</Statistic.Label>
        </Statistic>
      </Segment>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TicketWidget)