export default function handlingReducer (state = {}, action) {
  switch (action.type) {
    case 'HANDLING_RESET': {
        return({
            ...state,
            getHandling: {},
            updateHandling: {},
            createHandling: {},
        })
    }

    case 'HANDLING_BROWSE_PENDING': {
      return({...state, browseHandling: {progress: 0}})
    }
    case 'HANDLING_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseHandling: {...api.browseHandling.response, progress: 1, payload: action.payload}})
            return({...state, browseHandling: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseHandling: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'HANDLING_BROWSE_REJECTED': {
        return({...state, browseHandling: {progress: 2, payload: action.payload}})
    }
    
    case 'HANDLING_GET_PENDING': {
        return({...state, getHandling: {progress: 0}})
    }
    case 'HANDLING_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getHandling: {...api.getHandling.response, progress: 1, payload: action.payload}})
            return({...state, getHandling: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getHandling: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'HANDLING_GET_REJECTED': {
        return({...state, getHandling: {progress: 2, payload: action.payload}})
    }

    case 'HANDLING_STATUS_PENDING': {
      return({...state, statusHandling: {progress: 0}})
    }
    case 'HANDLING_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusHandling: {...api.statusHandling.response, progress: 1, payload: action.payload}})
            return({...state, statusHandling: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusHandling: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'HANDLING_STATUS_REJECTED': {
        return({...state, statusHandling: {progress: 2, payload: action.payload}})
    }

    case 'HANDLING_CREATE_PENDING': {
      return({...state, createHandling: {progress: 0}})
    }
    case 'HANDLING_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createHandling: {...api.createHandling.response, progress: 1, payload: action.payload}})
            return({...state, createHandling: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createHandling: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'HANDLING_CREATE_REJECTED': {
        return({...state, createHandling: {progress: 2, payload: action.payload}})
    }

    case 'HANDLING_UPDATE_PENDING': {
      return({...state, updateHandling: {progress: 0}})
    }
    case 'HANDLING_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateHandling: {...api.updateHandling.response, progress: 1, payload: action.payload}})
            return({...state, updateHandling: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateHandling: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'HANDLING_UPDATE_REJECTED': {
        return({...state, updateHandling: {progress: 2, payload: action.payload}})
    }

    case 'HANDLING_REMOVE_PENDING': {
      return({...state, removeHandling: {progress: 0}})
    }
    case 'HANDLING_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeHandling: {...api.removeHandling.response, progress: 1, payload: action.payload}})
            return({...state, removeHandling: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeHandling: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'HANDLING_REMOVE_REJECTED': {
        return({...state, removeHandling: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}