import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'

import { groupOptions, conditionOptions }  from '../../modules/service/StaticMaster'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, SearchField, InsertableDropdownField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetClassification, getClassification,updateClassification, createClassification } from "../../actions/service/classificationAction"
import { listWorkgroup, listProduct, listForm, listMacro, listActivity } from "../../actions/service/masterAction"


//CONNECT REDUX STORE
const selector = formValueSelector('ClassificationForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    classificationStore: state.classificationStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    listProduct:(objParam) => dispatch(listProduct(objParam)),
    listForm:(objParam) => dispatch(listForm(objParam)),
    listMacro:(objParam) => dispatch(listMacro(objParam)),
    listActivity:(objParam) => dispatch(listActivity(objParam)),

    resetClassification:(objParam) => dispatch(resetClassification(objParam)),
    getClassification:(objParam) => dispatch(getClassification(objParam)),
    createClassification:(objParam) => dispatch(createClassification(objParam)),
    updateClassification:(objParam) => dispatch(updateClassification(objParam)),
  }
}

class ClassificationForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount(){
    this.props.listWorkgroup()
    this.props.listProduct()
    this.props.listForm()
    this.props.listMacro()
    this.props.listActivity()

    this.props.resetClassification(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getClassification({classificationId: this.props.classificationId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.classificationStore.updateClassification!=undefined ? this.props.classificationStore.updateClassification : {}) : (this.props.classificationStore.createClassification!=undefined ? this.props.classificationStore.createClassification : {}))

    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateClassification({...values}) : this.props.createClassification({...values})
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.classificationStore.getClassification!=undefined && this.props.classificationStore.getClassification.progress=='1' ? this.props.classificationStore.getClassification : {})
    const { progress, code, text }= (mode==2 ? (this.props.classificationStore.updateClassification!=undefined ? this.props.classificationStore.updateClassification : {}) : (this.props.classificationStore.createClassification!=undefined ? this.props.classificationStore.createClassification : {}))

    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    const listProduct= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])
    const listForm= (this.props.masterStore.listForm!=undefined && this.props.masterStore.listForm.progress=='1' ? this.props.masterStore.listForm.data : [])
    const listMacro= (this.props.masterStore.listMacro!=undefined && this.props.masterStore.listMacro.progress=='1' ? this.props.masterStore.listMacro.data : [])
    const listActivity= (this.props.masterStore.listActivity!=undefined && this.props.masterStore.listActivity.progress=='1' ? this.props.masterStore.listActivity.data : [])

    return (
      <div style={{padding: '1em 1.5em'}}>
        <div style={{position: 'absolute', marginTop: '-3em', right: '1.7em', display: 'inline-block', background: '#222', padding: '.2em .5em .6em .6em', borderRadius: '.5em'}}><Icon name='window close' color='yellow' style={{cursor: 'pointer', padding: '0', margin: '0', fontSize: '2em'}} onClick={this.props.closeModal.bind(this, pristine)}/></div>
        
        <Header as='h4' style={{margin: '0 0 2em 0'}}>
          <Icon name='folder' style={{fontSize: '1em'}}/>
          <Header.Content>
            Ticket Classification / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
          </Header.Content>
        </Header>

        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Grid stackable doubling>
            <Grid.Row columns={16}>
              <Grid.Column width={6}>
                <Header as='h5'>
                  <Header.Content>
                    Classification Title
                    <Header.Subheader>Short description of classification.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='name' component={InputField}
                    validate= {[required]}
                    label='Name'
                    placeholder='Name' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='descript' component={TextAreaField}
                    label='Description'
                    placeholder='Additional information' />
                  </Form.Group>
                </Segment>

                <Header as='h5'>
                  <Header.Content>
                    Service Association
                    <Header.Subheader>Related service / product / brand and customer segmentation.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='products' component={SelectField}
                    multiple
                    options={listProduct}
                    // validate= {[required]}
                    label='Associated Brand'
                    placeholder='Related brand, service or product' />
                  </Form.Group>
                </Segment>

                <Header as='h5'>
                  <Header.Content>
                    Forms and Macros
                    <Header.Subheader>Default loaded forms and macros.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='forms' component={SelectField}
                    multiple
                    options={listForm}
                    label='Loaded Forms'
                    placeholder='List of loaded forms' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='macros' component={SelectField}
                    multiple
                    options={listMacro}
                    label='Loaded Macros'
                    placeholder='List of loaded macros' />
                  </Form.Group>
                </Segment>
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as='h5'>
                  <Header.Content>
                    Dispatch and Escalation
                    <Header.Subheader>Set teams for ticket dispatching and escalation.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='dispatches' component={SelectField}
                    multiple
                    options={listWorkgroup}
                    label='Dispatch Teams / Workgroup'
                    placeholder='List of agent when classification was defined such from bot' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='escalations' component={SelectField}
                    multiple
                    options={listWorkgroup}
                    label='Escalation Teams / Workgroup'
                    placeholder='List of backend and support for escalated solution' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='executives' component={SelectField}
                    multiple
                    options={listWorkgroup}
                    label='Executive Teams / Workgroup'
                    placeholder='List of executive group when required higher escalation' />
                  </Form.Group>
                </Segment>

                <Header as='h5'>
                  <Header.Content>
                    Solving Actions and Activities
                    <Header.Subheader>Set actions and activities for current ticket classification.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='skillsets' component={InsertableDropdownField}
                    label='Competency Skill Set'
                    placeholder='List of skill which required for ticket solving' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='activities' component={SelectField}
                    options={listActivity}
                    label='Actions and Activities'
                    placeholder='List of action and activities which refer to knowledge base' />
                  </Form.Group>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Classification' : 'Insert Classification') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.classificationStore.getClassification!=undefined && state.classificationStore.getClassification.progress==1 ? state.classificationStore.getClassification.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      openStamp: selector(state, 'openStamp'),
    }),
    { load: getClassification }
  )
  (reduxForm({
    form: 'ClassificationForm',	// a unique identifier for this form
  })(ClassificationForm)
  )
)
