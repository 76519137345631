import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Feed, Divider, Label, Button } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import MyBucketBrowse from '../../libraries/service/MyBucketBrowse'
// import MyBucketForm from '../../libraries/service/MyBucketForm'
import MyBucketMenu from '../../libraries/service/MyBucketMenu';
import MyBucketAssigned from '../../libraries/service/MyBucketAssigned';
import MyBucketNew from '../../libraries/service/MyBucketNew';
import MyBucketOpen from '../../libraries/service/MyBucketOpen';
import MyBucketPending from '../../libraries/service/MyBucketPending';
import MyBucketUnsolved from '../../libraries/service/MyBucketUnsolved';
import MyBucketSolved from '../../libraries/service/MyBucketSolved';
import MyBucketClosed from '../../libraries/service/MyBucketClosed';
import MyBucketAlert from '../../libraries/service/MyBucketAlert';


class MyBucketMenuBody extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      wide: true,
    };
  }

  setLayout() {
    this.setState({
      wide: !this.state.wide
    })
  }

  render() {
    const {wide}= this.state
    const {mode, layout, ticketId, param, bucket, filter, workspace, stamp}= this.props
    const {screenWidth, screenHeight}= this.props
    
    // console.log(filter)
    return(
        <div style={{padding: '0 .6em'}}>
          <div style={{display: 'flex'}}>
            <div style={{width: 250, background: '#f5f5f5', padding: '1em .5em 0 .5em', display: wide ? 'block' : 'none', borderRadius: '.5em', border: '1px solid #eaeaea'}}>
              <MyBucketMenu bucket={bucket} filter={{...filter, scope: workspace}} workspace={workspace} setBucket={this.props.setBucket} setMode={this.props.setMode} setFilter={this.props.setFilter} screenWidth={screenWidth} screenHeight={screenHeight-125} openModal={this.props.openModal} closeModal={this.props.closeModal} />
            </div>
            <div style={{flex: 1, background: '#fafafa', padding: '1em .5em 0 1em'}}>
              <div style={{position: 'fixed', marginTop: '2.5em', marginLeft: '-2em'}}>
                <Button as='a' size='small' color='black' icon onClick={this.setLayout.bind(this)}><Icon name={wide ? 'arrows alternate horizontal' : 'arrows alternate vertical'} /></Button>
              </div>
              {filter && (
                (filter.title=='New' && <MyBucketNew stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                (filter.title=='Open' && <MyBucketOpen stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                
                (filter.title=='Pending' && <MyBucketPending stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                (filter.title=='On Hold' && <MyBucketPending stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||

                (filter.title=='Unsolved' && <MyBucketUnsolved stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                (filter.title=='Solved' && <MyBucketSolved stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                (filter.title=='Closed' && <MyBucketClosed stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                (filter.title=='Warning' && <MyBucketAlert stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                
                (filter.title=='Assigned' && <MyBucketAssigned stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                (filter.title=='Unassigned' && <MyBucketAssigned stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                
                (filter.title=='SLA Breach' && <MyBucketAssigned stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                (filter.title=='SLA Complied' && <MyBucketAssigned stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||

                (filter.title && <MyBucketBrowse stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />)
              )}
            </div>
          </div>
        </div>
    )
  }
}

export default MyBucketMenuBody
