export default function statusReducer (state = {}, action) {
  switch (action.type) {
    case 'STATUS_RESET': {
        return({
            ...state,
            getStatus: {},
            updateStatus: {},
            createStatus: {},
        })
    }

    case 'STATUS_BROWSE_PENDING': {
      return({...state, browseStatus: {progress: 0}})
    }
    case 'STATUS_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseStatus: {...api.browseStatus.response, progress: 1, payload: action.payload}})
            return({...state, browseStatus: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseStatus: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATUS_BROWSE_REJECTED': {
        return({...state, browseStatus: {progress: 2, payload: action.payload}})
    }
    
    case 'STATUS_GET_PENDING': {
        return({...state, getStatus: {progress: 0}})
    }
    case 'STATUS_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getStatus: {...api.getStatus.response, progress: 1, payload: action.payload}})
            return({...state, getStatus: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getStatus: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATUS_GET_REJECTED': {
        return({...state, getStatus: {progress: 2, payload: action.payload}})
    }

    case 'STATUS_STATUS_PENDING': {
      return({...state, statusStatus: {progress: 0}})
    }
    case 'STATUS_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusStatus: {...api.statusStatus.response, progress: 1, payload: action.payload}})
            return({...state, statusStatus: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusStatus: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATUS_STATUS_REJECTED': {
        return({...state, statusStatus: {progress: 2, payload: action.payload}})
    }

    case 'STATUS_CREATE_PENDING': {
      return({...state, createStatus: {progress: 0}})
    }
    case 'STATUS_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createStatus: {...api.createStatus.response, progress: 1, payload: action.payload}})
            return({...state, createStatus: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createStatus: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATUS_CREATE_REJECTED': {
        return({...state, createStatus: {progress: 2, payload: action.payload}})
    }

    case 'STATUS_UPDATE_PENDING': {
      return({...state, updateStatus: {progress: 0}})
    }
    case 'STATUS_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateStatus: {...api.updateStatus.response, progress: 1, payload: action.payload}})
            return({...state, updateStatus: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateStatus: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATUS_UPDATE_REJECTED': {
        return({...state, updateStatus: {progress: 2, payload: action.payload}})
    }

    case 'STATUS_REMOVE_PENDING': {
      return({...state, removeStatus: {progress: 0}})
    }
    case 'STATUS_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeStatus: {...api.removeStatus.response, progress: 1, payload: action.payload}})
            return({...state, removeStatus: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeStatus: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'STATUS_REMOVE_REJECTED': {
        return({...state, removeStatus: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}