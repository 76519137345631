import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import AudittrailBrowse from '../../libraries/system/AudittrailBrowse'
import AudittrailForm from '../../libraries/system/AudittrailForm'
import AudittrailSearch from '../../libraries/system/AudittrailSearch'


class AudittrailBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        loggerId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, loggerId: id})
    }

    closeModal() {
      this.setState({
        mode: 1,
        backendId: 0,
      });
      this.props.closeModal() 
    }

    render() {
      const {mode, loggerId}= this.state
      const {screenWidth}= this.props
      
      return(
        <Container fluid>
          <div>
            <AudittrailSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {/* {mode==1 && <AudittrailBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} />} */}
              {<AudittrailBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} />}
              {/* {mode==2 && <AudittrailForm mode={mode} loggerId={loggerId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <AudittrailForm mode={mode} loggerId={0} setMode={this.setMode.bind(this)} />} */}
              {mode==2 && <ModalContainer size='large' content={<><AudittrailForm mode={mode} loggerId={loggerId} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} /></>} closeModal={this.closeModal.bind(this)} /> }
              {mode==3 && <ModalContainer size='large' content={<><AudittrailForm mode={mode} loggerId={loggerId} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} /></>} closeModal={this.closeModal.bind(this)} /> }
            </div>
          </div>
        </Container>
      )
    }
}

export default AudittrailBody
