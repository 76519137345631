import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import RptSlaSearch from '../../libraries/service/RptSlaSearch'
import RptSlaPerformance from '../../libraries/service/RptSlaPerformance'
import RptSlaBreach from '../../libraries/service/RptSlaBreach';

class RptSlaBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        peopleId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, peopleId: id, clientId: clientId})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        peopleId: 0,
      });
    }

    render() {
      const {mode, peopleId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <RptSlaSearch setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} mode={mode} menu={this.props.menu} />
          {this.props.menu==='performance' && <RptSlaPerformance setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
          {this.props.menu==='breach' && <RptSlaBreach setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
        </Container>
      )
    }
}

export default RptSlaBody
