import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Item,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Table,
  Label,
  Progress,
  Pagination,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"
import { connect } from "react-redux"
import { ticketMonitoring } from "../../actions/service/monitoringAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    monitoringStore: state.monitoringStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    ticketMonitoring:(objParam) => dispatch(ticketMonitoring(objParam)),
  }
};

class MonitoringBrowse extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        userId: 0,
      };
    }
    
    componentDidMount() {
      const { search, paging }= (this.props.monitoringStore.ticketMonitoring!==undefined ? this.props.monitoringStore.ticketMonitoring : {})
      this.props.ticketMonitoring({search: {...search}})
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      const { data, search, paging }= (this.props.monitoringStore.ticketMonitoring!==undefined ? this.props.monitoringStore.ticketMonitoring : {})
      let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
      
      return(
        <div style={{display: 'flex', flexDirection: 'column', padding: '1em'}}>
          <div style={{flex: '1'}}>
            <Segment>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Header as='h4'>
                    <Icon name='ticket' style={{}}/>
                    <Header.Content>
                      Recent Ticket
                      <Header.Subheader>Click more to get all tickets.</Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div>
                  <Button type='button' color='blue'>More Tickets</Button>
                </div>
              </div>
              
              <Divider style={{margin: '1em 0 3em 0'}}/>
              <Card.Group itemsPerRow={3} stackable doubling>
                { data && data.length==0 &&<Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label>}
                { 
                  data ? data.map((item,key)=>{
                    return(
                      <Card as='a'>
                        {/* <Label circular color='red' floating>22</Label> */}
                        <Card.Content extra>
                          <Card.Meta>
                            {item.openStamp!=null && format(parse(item.openStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm')} {item.openStamp!=null && format(parse(item.openStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd/MMM')}
                          </Card.Meta>
                          <Card.Meta style={{margin: '0 0 .5em 0'}}>
                            {item.ticketNo} / <b>{item.segment}</b>
                          </Card.Meta>
                        </Card.Content>
                        <Card.Content style={{minHeight: '15em'}}>
                          <Card.Header>
                            {item.subject}
                          </Card.Header>
                          <Card.Description>
                            {item.information}
                          </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                          <Card.Description>
                            <Progress size='tiny' percent={item.progress}/>

                            <Header as='h5'>
                              {/* <Image avatar circular src='https://react.semantic-ui.com/images/avatar/large/matthew.png' /> */}
                              <Header.Content>
                                {item.custName}
                                <Header.Subheader>{item.custMobile}<br />{item.custEmail}</Header.Subheader>
                              </Header.Content>
                            </Header>
                          </Card.Description>
                        </Card.Content>
                        {/* <Card.Content extra>
                          <Icon name='file alternate outline' /> 22 Views
                          <span style={{float: 'right'}}>
                            <Icon name='bug' />
                            22 Actions
                          </span>
                        </Card.Content> */}
                      </Card>
                    )
                  }) : <Segment><Icon name='circle notch' loading />loading ...</Segment>
                }
              </Card.Group>
            </Segment>
          </div>
        </div>
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringBrowse)
