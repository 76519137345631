import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';
// import ActionBody from './ActionBody'

import UpdatedBrowse from '../../libraries/service/UpdatedBrowse'
// import UpdatedForm from '../../libraries/service/UpdatedForm'
import UpdatedSearch from '../../libraries/service/UpdatedSearch'



class UpdatedBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        ticketId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, ticketId: id, clientId: clientId})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        ticketId: 0,
      });
    }

    render() {
      const {mode, ticketId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              <UpdatedSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
              <div>
                {(mode==1 || mode==4) && <UpdatedBrowse setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
                {/* {mode==2 && <UpdatedForm mode={mode} ticketId={ticketId} clientId={clientId} setMode={this.setMode.bind(this)} />}
                {mode==3 && <UpdatedForm mode={mode} ticketId={0} clientId={0} setMode={this.setMode.bind(this)} />}
                {mode==4 && <ModalContainer size='large' content={<ActionBody screenWidth={screenWidth}  closeModal={this.closeModal.bind(this)} ticketId={ticketId} clientId={clientId} />} closeModal={this.closeModal.bind(this)} /> } */}
              </div>
            </div>
          </div>
        </Container>
      )
    }
}

export default UpdatedBody
