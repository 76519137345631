import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import KnowledgeBrowse from '../../libraries/service/KnowledgeBrowse'
import KnowledgeForm from '../../libraries/service/KnowledgeForm'
import KnowledgeSearch from '../../libraries/service/KnowledgeSearch'

import ConfirmationModal from '../../libraries/service/ConfirmationModal';


class KnowledgeDocumentBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
      };
    }

    render() {
      const {screenWidth, screenHeight, mode, knowledgeId, param, modal, layout}= this.props
      
      return(
        <div>
          <div style={{display: 'flex'}}>
            <div style={{flex: 1}}>
              {<KnowledgeBrowse mode={mode} setMode={this.props.setMode} screenWidth={screenWidth} screenHeight={screenHeight-125} openModal={this.props.openModal} closeModal={this.props.closeModal} />}
              {/* {mode==2 && <KnowledgeForm mode={mode} knowledgeId={knowledgeId} param={param} setMode={this.props.setMode} />} */}
              {/* {mode==3 && <KnowledgeForm mode={mode} knowledgeId={0} param={param} setMode={this.props.setMode} />} */}
              {mode==2 && <ModalContainer headless size='large' content={<KnowledgeForm mode={mode} knowledgeId={knowledgeId} param={param} setMode={this.props.setMode} closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} />}
              {mode==3 && <ModalContainer headless size='large' content={<KnowledgeForm mode={mode} knowledgeId={0} param={param} setMode={this.props.setMode} closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} />}

              {modal==1 && <ModalContainer headless style={{marginTop: screenHeight/4}} size='tiny' content={<ConfirmationModal screenWidth={screenWidth} screenHeight={screenHeight-125} closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} />}
            </div>
          </div>
        </div>
      )
    }
}

export default KnowledgeDocumentBody
