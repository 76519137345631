import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'
import MenuTabular from '../common/MenuTabular'
import { ticketOptions, progressOptions, scopeOptions, impactOptions, resultOptions, priorityOptions, channelOptions, yesnoOptions, ratingOptions }  from '../common/StaticMasterData'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetTicket, getTicket,updateTicket, createTicket } from "../../actions/service/ticketAction"
import { listWorkgroup, listSegment, listProduct, listCustomer } from "../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('TicketForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,

    ticketStore: state.ticketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listSegment:(objParam) => dispatch(listSegment(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    listProduct:(objParam) => dispatch(listProduct(objParam)),
    listCustomer:(objParam) => dispatch(listCustomer(objParam)),

    resetTicket:(objParam) => dispatch(resetTicket(objParam)),
    getTicket:(objParam) => dispatch(getTicket(objParam)),
    createTicket:(objParam) => dispatch(createTicket(objParam)),
    updateTicket:(objParam) => dispatch(updateTicket(objParam)),
  }
}

const panes = (param)=>{
  return([
    {
      menuItem: { key: '0', icon: 'building outline', content: 'Customer' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '1', icon: 'wizard', content: 'Actions' },
      render: () => 
      <></>
    },
  ])
};

class TicketForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remarkResult: null,

      tab: 0,
    }
  }

  componentDidMount(){
    this.props.resetTicket(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getTicket({ticketId: this.props.ticketId})
    }

    this.props.listSegment({search: {clientId: this.props.clientId}})
    this.props.listWorkgroup({search: {clientId: this.props.clientId}})
    this.props.listProduct({search: {clientId: this.props.clientId}})
    this.props.listCustomer({search: {clientId: this.props.clientId}})
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.ticketStore.updateTicket!=undefined ? this.props.ticketStore.updateTicket : {}) : (this.props.ticketStore.createTicket!=undefined ? this.props.ticketStore.createTicket : {}))

    const listSegment= (this.props.masterStore.listSegment!=undefined && this.props.masterStore.listSegment.progress=='1' ? this.props.masterStore.listSegment.data : [])
    // const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])

    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }

    if (mode==3) {
      this.props.dispatch(change('TicketForm', 'openStamp', format(new Date(), 'yyyy-MM-dd hh:mm:ss')));
      this.props.dispatch(change('TicketForm', 'remark', 'Open'));
    } 

    // console.log('SEGMENTID', this.props.segmentId)
    if (this.props.segmentId) {
      listSegment && listSegment.map(item=>{
        if (item.value==this.props.segmentId) {
          this.props.dispatch(change('TicketForm', 'segmentParams', item.params));
          (!this.props.dispositions || this.props.dispositions.length==0) && this.props.dispatch(change('TicketForm', 'dispositions', item.dispositions));
        }
      })
    } else {
      this.props.dispatch(change('TicketForm', 'segmentParams', []));
      this.props.dispatch(change('TicketForm', 'dispositions', []));
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateTicket({...values}) : this.props.createTicket({...values})
    
  }

  onChangeResult(v) {
    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})

    this.props.dispatch(change('TicketForm', 'progress', listRemark.progress[v]));
  }

  setMode(e, d) {
    // console.log(d.activeIndex)
    this.setState({tab: d.activeIndex})
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.ticketStore.getTicket!=undefined && this.props.ticketStore.getTicket.progress=='1' ? this.props.ticketStore.getTicket : {})
    const { progress, code, text }= (mode==2 ? (this.props.ticketStore.updateTicket!=undefined ? this.props.ticketStore.updateTicket : {}) : (this.props.ticketStore.createTicket!=undefined ? this.props.ticketStore.createTicket : {}))

    const listSegment= (this.props.masterStore.listSegment!=undefined && this.props.masterStore.listSegment.progress=='1' ? this.props.masterStore.listSegment.data : [])
    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    const listProduct= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])
    const listCustomer= (this.props.masterStore.listCustomer!=undefined && this.props.masterStore.listCustomer.progress=='1' ? this.props.masterStore.listCustomer.data : [])
    // const dispatchOptions= [];
    
    // this.props.segmentId && this.state.dispositions && listWorkgroup && listWorkgroup.map((item, i)=>{
    //   if (this.state.dispositions.includes()) {

    //   }
    // })
    return (
      <div style={{padding: '1em 1.5em'}}>
        <Header as='h4' style={{marginBottom: '2em'}}>
          <Icon name='ticket' style={{fontSize: '1em'}}/>
          <Header.Content>
            Ticket / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
          </Header.Content>
        </Header>
        
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            {/* <Form.Group widths='16'>
            
              <Form.Field width={8} />
              <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' />
            </Form.Group> */}
            <Form.Group widths='16'>
              <Field name='ticketNo' component={InputField}
              width={8}
              validate= {[required]}
              label='Ticket No'
              placeholder='Ticket No' />
              <Field name='priority' component={SelectField}
              width={4}
              validate= {[required]}
              options={priorityOptions()}
              label='Priority'
              placeholder='Priority' />
              <Field name='remark' component={SelectField}
              width={4}
              readOnly
              options={resultOptions('ticket')}
              label='Status'
              placeholder='Status / Remark' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='customerId' component={SelectField}
              width={8}
              validate= {[required]}
              options= {listCustomer}
              label='Client / Customer'
              placeholder='Client Ref.' />
              <Field name='productId' component={SelectField}
              width={8}
              validate= {[required]}
              options= {listProduct}
              label='Product / Service'
              placeholder='Product Ref.' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='openStamp' component={DatePickerField}
              width={4}
              readOnly
              showTimeSelect={true}
              dateFormat={'hh:mm aa dd/MMM, yyyy'}
              label='Ticket Stamp'
              placeholder='Open Stamp' />
              <Field name='actionStamp' component={DatePickerField}
              width={4}
              readOnly
              showTimeSelect={true}
              dateFormat={'hh:mm aa dd/MMM, yyyy'}
              label='Recent Action'
              placeholder='Recent Action' />
              <Field name='channelType' component={SelectField}
              width={4}
              validate= {[required]}
              options={channelOptions('ticket')}
              label='Incoming'
              placeholder='Channel' />
            </Form.Group>
          </Segment>
          
          <Segment>
            <Header as='h5'>
              <Icon name='bookmark outline' style={{fontSize: '1em'}}/>
              <Header.Content>Ticket Info</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='subject' component={InputField}
              width={8}
              validate= {[required]}
              label='Subject / Title'
              placeholder='Subject' />
              <Field name='impact' component={SelectField}
              width={4}
              validate= {[required]}
              options={impactOptions('ticket')}
              label='Ticket Impact'
              placeholder='Impact' />
              <Field name='scope' component={SelectField}
              width={4}
              validate= {[required]}
              options={scopeOptions('ticket')}
              label='Ticket Scope'
              placeholder='Scope' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='information' component={TextAreaField}
              validate= {[required]}
              label='Information'
              placeholder='Information' />
            </Form.Group>
          </Segment>

          <Segment style={{background: '#f7f6ea'}}>
            <Header as='h5'>
              <Icon name='bookmark outline' style={{fontSize: '1em'}}/>
              <Header.Content>Assignment</Header.Content>
            </Header>
            {this.props.segmentParams && this.props.segmentParams.length>0 && <>
              {/* <Divider style={{marginTop: '2em'}} /> */}
              <Header as='h4'>
                <Icon name='edit outline' style={{fontSize: '1em' }} />
                <Header.Content> Ticket Assessment</Header.Content>
              </Header>
            </>}

            <Form.Group widths='16'>
              <Field name='segmentId' component={SelectField}
              width={16}
              validate= {[required]}
              options={listSegment}
              label='Segmentation'
              placeholder='Segmentation' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='dispositions' component={SelectField}
              width={16}
              multiple
              options={listWorkgroup}
              label='Ticket Disposition / Handlers'
              placeholder='Left it blank for default dispoositioning workgroups for handling' />
            </Form.Group>
            {this.props.segmentParams && this.props.segmentParams.map((item, idx)=>{
              if (idx>0 && (idx+1)%4==0) {
                // console.log('SEGMENT PARAMS', this.props.segmentParams);

                return(
                  <Form.Group key={idx}>
                    {
                      [3, 2, 1, 0].map(i=>{
                        if (this.props.segmentParams[idx-i]) {
                          return(
                            <Field key={idx-i} name={'segmentParams_'+ this.props.segmentParams[idx-i].key.replace(/ /g, '__')} 
                            component={this.props.segmentParams[idx-i].value=='Y/N' ? SelectField : InputField}
                            options={this.props.segmentParams[idx-i].value=='Y/N' ? yesnoOptions() : ratingOptions('quality')}
                            width={4}
                            validate= {[required]}
                            label={this.props.segmentParams[idx-i].key}
                            placeholder='Assessment value here' />
                          )
                        } else {
                          return(
                            <Form.Field key={idx-i} width={4} />
                          )
                        }
                      })
                    }
                  </Form.Group>
                )
              } else if ((idx+1)==this.props.segmentParams.length) {
                return(
                  <Form.Group key={idx}>
                    {
                      [0, 1, 2, 3].map(i=>{
                        // console.log(this.props.segmentParams.length - (idx%4) + i, this.props.segmentParams.length)
                        if ((this.props.segmentParams.length - (idx%4) + i)<=this.props.segmentParams.length && this.props.segmentParams[this.props.segmentParams.length - (idx%4) + i - 1]) {
                          return(
                            <Field key={idx-i} name={'segmentParams_'+ this.props.segmentParams[idx-i].key.replace(/ /g, '__')} 
                            component={this.props.segmentParams[idx-i].value=='Y/N' ? SelectField : InputField}
                            options={this.props.segmentParams[idx-i].value=='Y/N' ? yesnoOptions() : ratingOptions('quality')}
                            width={4}
                            validate= {[required]}
                            label={this.props.segmentParams[idx-i].key}
                            placeholder='Assessment value here' />
                          )
                        } else {
                          return(
                            <Form.Field key={idx-i} width={4} />
                          )
                        }
                      })
                    }
                  </Form.Group>
                )
              }
            })}
          </Segment>

          <MenuTabular panes={panes({})} style={{width: '100%', marginBottom: '-1.2em', marginTop: '1em'  }} renderActiveOnly={true} onTabChange={this.setMode.bind(this)}/>
          {this.state.tab==0 && <>
            <Segment>
              <Header as='h5'>
                <Icon name='user outline' style={{fontSize: '1em'}}/>
                <Header.Content>Contact</Header.Content>
              </Header>
              <Form.Group widths='16'>
                <Field name='custName' component={InputField}
                width={8}
                validate= {[required]}
                label='Name'
                placeholder='Full Name' />
                <Field name='custMobile' component={InputField}
                width={4}
                validate= {[required, phoneNumber]}
                label='Mobile'
                placeholder='Handphone' />
                <Field name='custPhone' component={InputField}
                width={4}
                label='Phone'
                placeholder='Home / Office Phone' />
              </Form.Group>
              <Form.Group widths='16'>
                <Field name='custEmail' component={InputField}
                width={4}
                validate= {[required, email]}
                label='Email'
                placeholder='Email' />
                <Field name='custTwitter' component={InputField}
                width={4}
                label='Twitter'
                placeholder='Twitter' />
                <Field name='custFacebook' component={InputField}
                width={4}
                label='Facebook'
                placeholder='Facebook' />
                <Field name='custInstagram' component={InputField}
                width={4}
                label='Instagram'
                placeholder='Instagram' />
              </Form.Group>
            </Segment>

            <Segment>
              <Header as='h5'>
                <Icon name='building outline' style={{fontSize: '1em'}}/>
                <Header.Content>Customer Ref.</Header.Content>
              </Header>
              <Form.Group widths='16'>
                <Field name='company' component={InputField}
                readOnly
                width={8}
                label='Corporate / Name'
                placeholder='Company' />
                <Field name='brand' component={InputField}
                readOnly
                width={4}
                label='Brand / Code'
                placeholder='Brand / Code' />
                <Field name='business' component={InputField}
                readOnly
                width={4}
                label='Business Stream'
                placeholder='Business Stream' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='address' component={InputField}
                readOnly
                label='Address'
                placeholder='Address' />
              </Form.Group>
              <Form.Group widths='16'>
                <Field name='city' component={InputField}
                readOnly
                width={4}
                placeholder='City' />
                <Field name='province' component={InputField}
                readOnly
                width={4}
                placeholder='Province' />
                <Field name='country' component={InputField}
                readOnly
                width={4}
                placeholder='Country' />
                <Field name='zipcode' component={InputField}
                readOnly
                width={4}
                placeholder='Zipcode' />
              </Form.Group>
            </Segment>
          </>}
          {this.state.tab==1 && <>
            <Segment attached='top'>
              <Header as='h5'>
                <Icon name='wizard' style={{fontSize: '1em'}}/>
                <Header.Content>Last Action</Header.Content>
              </Header>
              <Table unstackable selectable columns='16' celled striped basic='very'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>#</Table.HeaderCell>
                    <Table.HeaderCell width='8'>
                      <Header as='h5' color='blue'>
                        ACTION
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='4'>
                      <Header as='h5' color='blue'>
                        USER / HANDLER
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='4'>
                      <Header as='h5' color='blue'>
                        RESULT
                      </Header>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  { data && data.handlings ? data.handlings.map((item,i)=>{
                      return(
                        <Table.Row key={++i}>
                          <Table.Cell>{i}</Table.Cell>
                          <Table.Cell>
                            <Header as='h5'>
                              <Header.Content>
                                <Header.Subheader><b>{item.actionType}</b> / {item.handlingType || 'Information'}</Header.Subheader>
                                {item.actionType=='Handling' && <>{item.handlingPerson}, {item.handlingActivity}</>}
                                {item.actionType!='Handling' && <>{item.actionMessage}</>}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell>
                            {item.actionType=='Handling' &&
                            <Header as='h5'>
                              <Header.Content>
                                <Header.Subheader>{item.actionStamp!=null && format(parse(item.actionStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm, dd-MMM / yyyy')}</Header.Subheader>
                                {item.handlingPerson}
                              </Header.Content>
                            </Header>}
                          </Table.Cell>
                          <Table.Cell>
                            {item.actionType=='Handling' &&
                            <Header as='h5'>
                              <div style={{background: (item.status=='1' && '#84dbff') || (item.status=='0' && '#ffb3b3') || '#ddd', textAlign: 'right', display: 'inline-block', width: '4em', padding: '.5em', marginRight: '.5em'}}>{item.progress || '-'} %</div>
                              <Header.Content>
                                <b>{item.remark}</b>
                                <Header.Subheader>{(item.reason && item.reason.length>30 ? item.reason.substring(0,20)+' ...' : item.reason) || '-'}</Header.Subheader>
                              </Header.Content>
                            </Header>}
                          </Table.Cell>
                        </Table.Row>
                      )
                    }) : <Table.Row><Table.Cell colSpan={3}></Table.Cell></Table.Row>
                  }
                </Table.Body>
              </Table>
            </Segment>
          </>}
          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Ticket' : 'Insert Ticket') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.ticketStore.getTicket!=undefined && state.ticketStore.getTicket.progress==1 ? state.ticketStore.getTicket.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      openStamp: selector(state, 'openStamp'),
      remark: selector(state, 'remark'),

      segmentId: selector(state, 'segmentId'),
      segmentParams: selector(state, 'segmentParams'),

      dispositions: selector(state, 'dispositions'),
    }),
    { load: getTicket }
  )
  (reduxForm({
    form: 'TicketForm',	// a unique identifier for this form
  })(TicketForm)
  )
)
