//LOCALHOST DEVELOPMENT
// module.exports.urlAdmin= 'http://localhost:1115/admin/api/v1/';
// module.exports.urlRealm= 'http://localhost:1115/realm/api/v1/';
// module.exports.urlAgent= 'http://localhost:1115/agent/api/v1/';
// module.exports.urlSocket= 'ws://127.0.0.1:4001';

//HALOPAGI #1 DEVELOPMENT
// module.exports.urlAdmin= 'https://crm.halopagi.com/proxy/admin/api/v1/';
// module.exports.urlRealm= 'https://crm.halopagi.com/proxy/realm/api/v1/';
// module.exports.urlAgent= 'https://crm.halopagi.com/proxy/agent/api/v1/';
// module.exports.urlSocket= 'wss://crm.halopagi.com/';

//HALOPAGI #2 STREAM CLOUD
module.exports.urlAdmin= 'https://streamapp.halopagi.com/proxy/admin/api/v1/';
module.exports.urlRealm= 'https://streamapp.halopagi.com/proxy/realm/api/v1/';
module.exports.urlAgent= 'https://streamapp.halopagi.com/proxy/agent/api/v1/';
module.exports.urlSocket= 'wss://streamapp.halopagi.com/';

//TUNAIKU AMAR BANK
// module.exports.urlAdmin= 'https://streamapp.tunaiku.com/proxy/admin/api/v1/';
// module.exports.urlRealm= 'https://streamapp.tunaiku.com/proxy/realm/api/v1/';
// module.exports.urlAgent= 'https://streamapp.tunaiku.com/proxy/agent/api/v1/';
// module.exports.urlSocket= 'wss://streamapp.tunaiku.com/';