export default function pipelineReducer (state = {}, action) {
    switch (action.type) {

        case 'HISTORICAL_TICKET_PENDING': {
            return({...state, ticketHistorical: {progress: 0}})
        }
        case 'HISTORICAL_TICKET_FULFILLED': {
            if (action.payload==undefined) {
                //return({...state, ticketHistorical: {...api.ticketHistorical.response, progress: 1, payload: action.payload}})
                return({...state, ticketHistorical: {progress: 2, payload: action.payload}})
            } else {
                return({...state, ticketHistorical: {...action.payload.data, progress: 1, payload: action.payload}})
            }
        }
        case 'HISTORICAL_TICKET_REJECTED': {
            return({...state, ticketHistorical: {progress: 2, payload: action.payload}})
        }
        
            
        case 'HISTORICAL_PRIORITY_PENDING': {
            return({...state, priorityHistorical: {progress: 0}})
        }
        case 'HISTORICAL_PRIORITY_FULFILLED': {
            if (action.payload==undefined) {
                //return({...state, priorityHistorical: {...api.priorityHistorical.response, progress: 1, payload: action.payload}})
                return({...state, priorityHistorical: {progress: 2, payload: action.payload}})
            } else {
                return({...state, priorityHistorical: {...action.payload.data, progress: 1, payload: action.payload}})
            }
        }
        case 'HISTORICAL_PRIORITY_REJECTED': {
            return({...state, priorityHistorical: {progress: 2, payload: action.payload}})
        }
        
        case 'HISTORICAL_SEVERITY_PENDING': {
            return({...state, severityHistorical: {progress: 0}})
        }
        case 'HISTORICAL_SEVERITY_FULFILLED': {
            if (action.payload==undefined) {
                //return({...state, severityHistorical: {...api.severityHistorical.response, progress: 1, payload: action.payload}})
                return({...state, severityHistorical: {progress: 2, payload: action.payload}})
            } else {
                return({...state, severityHistorical: {...action.payload.data, progress: 1, payload: action.payload}})
            }
        }
        case 'HISTORICAL_SEVERITY_REJECTED': {
            return({...state, severityHistorical: {progress: 2, payload: action.payload}})
        }
        
        case 'HISTORICAL_SLA_PENDING': {
            return({...state, slaHistorical: {progress: 0}})
        }
        case 'HISTORICAL_SLA_FULFILLED': {
            if (action.payload==undefined) {
                //return({...state, slaHistorical: {...api.slaHistorical.response, progress: 1, payload: action.payload}})
                return({...state, slaHistorical: {progress: 2, payload: action.payload}})
            } else {
                return({...state, slaHistorical: {...action.payload.data, progress: 1, payload: action.payload}})
            }
        }
        case 'HISTORICAL_SLA_REJECTED': {
            return({...state, slaHistorical: {progress: 2, payload: action.payload}})
        }
        
        case 'HISTORICAL_REMARK_PENDING': {
            return({...state, remarkHistorical: {progress: 0}})
        }
        case 'HISTORICAL_REMARK_FULFILLED': {
            if (action.payload==undefined) {
                //return({...state, remarkHistorical: {...api.remarkHistorical.response, progress: 1, payload: action.payload}})
                return({...state, remarkHistorical: {progress: 2, payload: action.payload}})
            } else {
                return({...state, remarkHistorical: {...action.payload.data, progress: 1, payload: action.payload}})
            }
        }
        case 'HISTORICAL_REMARK_REJECTED': {
            return({...state, remarkHistorical: {progress: 2, payload: action.payload}})
        }
    
        case 'HISTORICAL_CLUSTER_PENDING': {
            return({...state, clusterHistorical: {progress: 0}})
        }
        case 'HISTORICAL_CLUSTER_FULFILLED': {
            if (action.payload==undefined) {
                //return({...state, clusterHistorical: {...api.clusterHistorical.response, progress: 1, payload: action.payload}})
                return({...state, clusterHistorical: {progress: 2, payload: action.payload}})
            } else {
                return({...state, clusterHistorical: {...action.payload.data, progress: 1, payload: action.payload}})
            }
        }
        case 'HISTORICAL_CLUSTER_REJECTED': {
            return({...state, clusterHistorical: {progress: 2, payload: action.payload}})
        }
    
        case 'HISTORICAL_CATEGORY_PENDING': {
            return({...state, categoryHistorical: {progress: 0}})
        }
        case 'HISTORICAL_CATEGORY_FULFILLED': {
            if (action.payload==undefined) {
                //return({...state, categoryHistorical: {...api.categoryHistorical.response, progress: 1, payload: action.payload}})
                return({...state, categoryHistorical: {progress: 2, payload: action.payload}})
            } else {
                return({...state, categoryHistorical: {...action.payload.data, progress: 1, payload: action.payload}})
            }
        }
        case 'HISTORICAL_CATEGORY_REJECTED': {
            return({...state, categoryHistorical: {progress: 2, payload: action.payload}})
        }
        
        case 'HISTORICAL_SERVICE_PENDING': {
            return({...state, serviceHistorical: {progress: 0}})
        }
        case 'HISTORICAL_SERVICE_FULFILLED': {
            if (action.payload==undefined) {
                //return({...state, serviceHistorical: {...api.serviceHistorical.response, progress: 1, payload: action.payload}})
                return({...state, serviceHistorical: {progress: 2, payload: action.payload}})
            } else {
                return({...state, serviceHistorical: {...action.payload.data, progress: 1, payload: action.payload}})
            }
        }
        case 'HISTORICAL_SERVICE_REJECTED': {
            return({...state, serviceHistorical: {progress: 2, payload: action.payload}})
        }
        
        case 'HISTORICAL_CLASSIFICATION_PENDING': {
            return({...state, classificationHistorical: {progress: 0}})
        }
        case 'HISTORICAL_CLASSIFICATION_FULFILLED': {
            if (action.payload==undefined) {
                //return({...state, classificationHistorical: {...api.classificationHistorical.response, progress: 1, payload: action.payload}})
                return({...state, classificationHistorical: {progress: 2, payload: action.payload}})
            } else {
                return({...state, classificationHistorical: {...action.payload.data, progress: 1, payload: action.payload}})
            }
        }
        case 'HISTORICAL_CLASSIFICATION_REJECTED': {
            return({...state, classificationHistorical: {progress: 2, payload: action.payload}})
        }
        
        case 'HISTORICAL_RESOLUTION_PENDING': {
            return({...state, resolutionHistorical: {progress: 0}})
        }
        case 'HISTORICAL_RESOLUTION_FULFILLED': {
            if (action.payload==undefined) {
                //return({...state, resolutionHistorical: {...api.resolutionHistorical.response, progress: 1, payload: action.payload}})
                return({...state, resolutionHistorical: {progress: 2, payload: action.payload}})
            } else {
                return({...state, resolutionHistorical: {...action.payload.data, progress: 1, payload: action.payload}})
            }
        }
        case 'HISTORICAL_RESOLUTION_REJECTED': {
            return({...state, resolutionHistorical: {progress: 2, payload: action.payload}})
        }
    
        case 'HISTORICAL_REASON_PENDING': {
            return({...state, reasonHistorical: {progress: 0}})
        }
        case 'HISTORICAL_REASON_FULFILLED': {
            if (action.payload==undefined) {
                //return({...state, reasonHistorical: {...api.reasonHistorical.response, progress: 1, payload: action.payload}})
                return({...state, reasonHistorical: {progress: 2, payload: action.payload}})
            } else {
                return({...state, reasonHistorical: {...action.payload.data, progress: 1, payload: action.payload}})
            }
        }
        case 'HISTORICAL_REASON_REJECTED': {
            return({...state, reasonHistorical: {progress: 2, payload: action.payload}})
        }
    
        case 'HISTORICAL_INTERACTION_PENDING': {
            return({...state, interactionHistorical: {progress: 0}})
        }
        case 'HISTORICAL_INTERACTION_FULFILLED': {
            if (action.payload==undefined) {
                //return({...state, interactionHistorical: {...api.interactionHistorical.response, progress: 1, payload: action.payload}})
                return({...state, interactionHistorical: {progress: 2, payload: action.payload}})
            } else {
                return({...state, interactionHistorical: {...action.payload.data, progress: 1, payload: action.payload}})
            }
        }
        case 'HISTORICAL_INTERACTION_REJECTED': {
            return({...state, interactionHistorical: {progress: 2, payload: action.payload}})
        }
    
        case 'HISTORICAL_CHANNEL_PENDING': {
            return({...state, channelHistorical: {progress: 0}})
        }
        case 'HISTORICAL_CHANNEL_FULFILLED': {
            if (action.payload==undefined) {
                //return({...state, channelHistorical: {...api.channelHistorical.response, progress: 1, payload: action.payload}})
                return({...state, channelHistorical: {progress: 2, payload: action.payload}})
            } else {
                return({...state, channelHistorical: {...action.payload.data, progress: 1, payload: action.payload}})
            }
        }
        case 'HISTORICAL_CHANNEL_REJECTED': {
            return({...state, channelHistorical: {progress: 2, payload: action.payload}})
        }
        
        case 'HISTORICAL_SATISFACTION_PENDING': {
            return({...state, satisfactionHistorical: {progress: 0}})
        }
        case 'HISTORICAL_SATISFACTION_FULFILLED': {
            if (action.payload==undefined) {
                //return({...state, satisfactionHistorical: {...api.satisfactionHistorical.response, progress: 1, payload: action.payload}})
                return({...state, satisfactionHistorical: {progress: 2, payload: action.payload}})
            } else {
                return({...state, satisfactionHistorical: {...action.payload.data, progress: 1, payload: action.payload}})
            }
        }
        case 'HISTORICAL_SATISFACTION_REJECTED': {
            return({...state, satisfactionHistorical: {progress: 2, payload: action.payload}})
        }
        
        case 'HISTORICAL_WORKLOAD_PENDING': {
            return({...state, workloadHistorical: {progress: 0}})
        }
        case 'HISTORICAL_WORKLOAD_FULFILLED': {
            if (action.payload==undefined) {
                //return({...state, workloadHistorical: {...api.workloadHistorical.response, progress: 1, payload: action.payload}})
                return({...state, workloadHistorical: {progress: 2, payload: action.payload}})
            } else {
                return({...state, workloadHistorical: {...action.payload.data, progress: 1, payload: action.payload}})
            }
        }
        case 'HISTORICAL_WORKLOAD_REJECTED': {
            return({...state, workloadHistorical: {progress: 2, payload: action.payload}})
        }
    
        case 'HISTORICAL_PERFORMANCE_PENDING': {
            return({...state, performanceHistorical: {progress: 0}})
        }
        case 'HISTORICAL_PERFORMANCE_FULFILLED': {
            if (action.payload==undefined) {
                //return({...state, performanceHistorical: {...api.performanceHistorical.response, progress: 1, payload: action.payload}})
                return({...state, performanceHistorical: {progress: 2, payload: action.payload}})
            } else {
                return({...state, performanceHistorical: {...action.payload.data, progress: 1, payload: action.payload}})
            }
        }
        case 'HISTORICAL_PERFORMANCE_REJECTED': {
            return({...state, performanceHistorical: {progress: 2, payload: action.payload}})
        }
        
        case 'HISTORICAL_LIVEFEED_PENDING': {
            return({...state, livefeedHistorical: {progress: 0}})
        }
        case 'HISTORICAL_LIVEFEED_FULFILLED': {
            if (action.payload==undefined) {
                //return({...state, livefeedHistorical: {...api.livefeedHistorical.response, progress: 1, payload: action.payload}})
                return({...state, livefeedHistorical: {progress: 2, payload: action.payload}})
            } else {
                return({...state, livefeedHistorical: {...action.payload.data, progress: 1, payload: action.payload}})
            }
        }
        case 'HISTORICAL_LIVEFEED_REJECTED': {
            return({...state, livefeedHistorical: {progress: 2, payload: action.payload}})
        }
    
        default: {
            return state
        }
    }
  }