import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import RptPeopleSearch from '../../libraries/service/RptPeopleSearch'
import RptPeopleWorkload from '../../libraries/service/RptPeopleWorkload'
import RptPeoplePerformance from '../../libraries/service/RptPeoplePerformance';
import RptPeopleResponses from '../../libraries/service/RptPeopleResponses';
import RptPeopleResolves from '../../libraries/service/RptPeopleResolves';

class RptPeopleBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        peopleId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, peopleId: id, clientId: clientId})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        peopleId: 0,
      });
    }

    render() {
      const {mode, peopleId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <RptPeopleSearch setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} mode={mode} menu={this.props.menu}  />

          {this.props.menu==='workload' && <RptPeopleWorkload setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
          {this.props.menu==='performance' && <RptPeoplePerformance setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
          {this.props.menu==='responses' && <RptPeopleResponses setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
          {this.props.menu==='resolves' && <RptPeopleResolves setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
        </Container>
      )
    }
}

export default RptPeopleBody
