import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse, addSeconds } from "date-fns"

import { personalOptions, ticketOptions }  from '../../modules/service/StaticMaster'

import TicketHistorical from '../../libraries/widget/service/TicketHistorical'
import ServiceHistorical from '../widget/service/ServiceHistorical'
import ChannelHistorical from '../widget/service/ChannelHistorical'

import ComplianceWidget from '../../libraries/widget/service/ComplianceWidget'
import OccupancyWidget from '../../libraries/widget/service/OccupancyWidget'
import SatisfactionWidget from '../../libraries/widget/service/SatisfactionWidget'
import SegmentationWidget from '../../libraries/widget/service/SegmentationWidget'
import StatisticWidget from '../../libraries/widget/service/StatisticWidget'


import { connect } from "react-redux"
import { listRemark } from "../../actions/service/masterAction"
import SatisfactionHistorical from '../widget/service/SatisfactionHistorical'
import SeverityHistorical from '../widget/service/SeverityHistorical'
import PriorityHistorical from '../widget/service/PriorityHistorical'
import ClassificationHistorical from '../widget/service/ClassificationHistorical'
import ResolutionHistorical from '../widget/service/ResolutionHistorical'
import ClusterHistorical from '../widget/service/ClusterHistorical'

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listRemark:(objParam) => dispatch(listRemark(objParam)),
  }
}

class HistoricalDashboard extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }
  
  componentDidMount() {
    // this.props.listRemark();
  }

  render() {
    const { expand }= this.state
    const { screenWidth, screenHeight, params }= this.props

    return (
      <div style={{padding: '2em 1.5em 1.5em'}}>
        {(!params || (params && !params.mode) || (params && params.mode=='ticket')) &&
          <Grid>
            <Grid.Row columns={16}>
              <Grid.Column width={16} style={{padding: '.5em'}}>
                <TicketHistorical chart='bar' />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={16}>
              <Grid.Column width={16} style={{padding: '.5em'}}>
                <PriorityHistorical chart='line' />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={16}>
              <Grid.Column width={16} style={{padding: '.5em'}}>
                <SeverityHistorical chart='line' />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
        {params && params.mode=='classification' &&
          <Grid>
            <Grid.Row columns={16}>
              <Grid.Column width={16} style={{padding: '.5em'}}>
                <ClassificationHistorical chart='bar' />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={16}>
              <Grid.Column width={16} style={{padding: '.5em'}}>
                <ResolutionHistorical chart='line' />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
        {params && params.mode=='csat' &&
          <Grid>
            <Grid.Row columns={16}>
              <Grid.Column width={16} style={{padding: '.5em'}}>
                <SatisfactionHistorical chart='line' />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
        {params && params.mode=='cluster' &&
          <Grid>
            <Grid.Row columns={16}>
              <Grid.Column width={16} style={{padding: '.5em'}}>
                <ClusterHistorical chart='bar' />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={16}>
              <Grid.Column width={16} style={{padding: '.5em'}}>
                <ChannelHistorical chart='bar' />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoricalDashboard)
