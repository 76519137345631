import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { groupOptions, ticketOptions, conditionOptions, actionOptions, yesnoOptions, commentOptions }  from '../../modules/service/StaticMaster'

import { SubmissionError, Field, reduxForm, change, formValueSelector, getFormValues } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, SearchField, LabelSelectField, InsertableDropdownField, WysiwygField, LabelNumberField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetAutomation, getAutomation,updateAutomation, createAutomation } from "../../actions/service/automationAction"
import { listUser, listWorkgroup, listOrganization, listProduct, listForm, listMacro, listRemark, listChannel } from "../../actions/service/masterAction"


//CONNECT REDUX STORE
const selector = formValueSelector('AutomationForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    automationStore: state.automationStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listUser:(objParam) => dispatch(listUser(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    listOrganization:(objParam) => dispatch(listOrganization(objParam)),
    listProduct:(objParam) => dispatch(listProduct(objParam)),
    listForm:(objParam) => dispatch(listForm(objParam)),
    listMacro:(objParam) => dispatch(listMacro(objParam)),
    listRemark:(objParam) => dispatch(listRemark(objParam)),
    listChannel:(objParam) => dispatch(listChannel(objParam)),

    resetAutomation:(objParam) => dispatch(resetAutomation(objParam)),
    getAutomation:(objParam) => dispatch(getAutomation(objParam)),
    createAutomation:(objParam) => dispatch(createAutomation(objParam)),
    updateAutomation:(objParam) => dispatch(updateAutomation(objParam)),
  }
}

class AutomationForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount(){
    this.props.listUser()
    this.props.listWorkgroup()
    this.props.listOrganization()
    this.props.listProduct()
    this.props.listForm()
    this.props.listMacro()
    this.props.listRemark()
    this.props.listChannel()

    this.props.resetAutomation(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getAutomation({automationId: this.props.automationId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.automationStore.updateAutomation!=undefined ? this.props.automationStore.updateAutomation : {}) : (this.props.automationStore.createAutomation!=undefined ? this.props.automationStore.createAutomation : {}))

    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateAutomation({...values}) : this.props.createAutomation({...values})
  }

  onChangeSelect(v) {
    this.props.dispatch(change('AutomationForm', v.replace('{{PARAM}}', 'Operator'), null));
    this.props.dispatch(change('AutomationForm', v.replace('{{PARAM}}', 'Value'), null));
  }

  incrRowQty(isIncrease, kind) {
    console.log(kind, this.props[kind +'AutomationQty'])
    if (isIncrease) {
      if (this.props[kind +'AutomationQty']<10) {
        this.props.dispatch(change('AutomationForm', kind +'AutomationQty', ((isNaN(this.props[kind +'AutomationQty']) ? 0 : parseInt(this.props[kind +'AutomationQty'])) +1)))
      }
    } else {
      if (this.props[kind +'AutomationQty']> (kind=='any' ? 0 : 1)) {
        this.props.dispatch(change('AutomationForm', kind +'AutomationQty', ((isNaN(this.props[kind +'AutomationQty']) ? 0 : parseInt(this.props[kind +'AutomationQty'])) -1)))
      }
    }
  }

  drawAutomations(qty=1, kind, components, options) {
    const automations= this.props[kind +'Automations'] || []
    
    // console.log('!!! CONDITIONS DRAW', conditionsQty)
    const items= [];
    for (var i=0; i<qty; i++) {
      items[i]= {
        item: automations[i] ? automations[i].item : null,
      };
    }

    if (kind=='all') {
      return (
        items.map((item, i)=>{
          return(
            <Form.Group key={i} widths='16'>
              <Field name={'allParam_'+ i} component={SelectField} onChange={this.onChangeSelect.bind(this, 'all{{PARAM}}_'+ i)}
              width={6}
              options={this.props.isTrigger==1 ? conditionOptions('trigger-param') : conditionOptions('automation-param')}
              validate= {[required]}
              placeholder='Cond. Param' />
              <Field name={'allOperator_'+ i} component={SelectField}
              width={4}
              options={conditionOptions('ops', this.props.formValues && components && (components.get(this.props.formValues['allParam_'+ i])||{}).input)}
              validate= {[required]}
              placeholder='Operator' />
              <Field name={'allValue_'+ i} component={
                (this.props.formValues && components && (components.get(this.props.formValues['allParam_'+ i])||{}).input=='select' && SelectField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['allParam_'+ i])||{}).input=='selects' && SelectField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['allParam_'+ i])||{}).input=='contain' && SelectField) ||
                (this.props.formValues && components && (components.get(this.props.formValues['allParam_'+ i])||{}).input=='contains' && SelectField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['allParam_'+ i])||{}).input=='duration' && LabelNumberField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['allParam_'+ i])||{}).input=='taggings' && InsertableDropdownField) || 
                InputField
              }
              label={(this.props.formValues && components && (components.get(this.props.formValues['allParam_'+ i])||{}).input=='duration' && NumberField) && 'Minutes'}
              multiple={(((this.props.formValues && components && (components.get(this.props.formValues['allParam_'+ i])||{}).input=='selects') || (this.props.formValues && components && (components.get(this.props.formValues['allParam_'+ i])||{}).input=='contains')) && SelectField)}
              options={options && options[(this.props.formValues && components && (components.get(this.props.formValues['allParam_'+ i])||{}).options)]}
              width={6}
              validate= {[required]}
              placeholder='Cond. Value' />
            </Form.Group>
          )
        })
      )
    } else if (kind=='any') {
      return (
        items.map((item, i)=>{
          return(
            <Form.Group key={i} widths='16'>
              <Field name={'anyParam_'+ i} component={SelectField} onChange={this.onChangeSelect.bind(this, 'any{{PARAM}}_'+ i)}
              width={6}
              options={this.props.isTrigger==1 ? conditionOptions('trigger-param') : conditionOptions('automation-param')}
              validate= {[required]}
              placeholder='Cond. Param' />
              <Field name={'anyOperator_'+ i} component={SelectField}
              width={4}
              options={conditionOptions('ops', this.props.formValues && components && (components.get(this.props.formValues['anyParam_'+ i])||{}).input)}
              validate= {[required]}
              placeholder='Operator' />
              <Field name={'anyValue_'+ i} component={
                (this.props.formValues && components && (components.get(this.props.formValues['anyParam_'+ i])||{}).input=='select' && SelectField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['anyParam_'+ i])||{}).input=='selects' && SelectField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['anyParam_'+ i])||{}).input=='contain' && SelectField) ||
                (this.props.formValues && components && (components.get(this.props.formValues['anyParam_'+ i])||{}).input=='contains' && SelectField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['anyParam_'+ i])||{}).input=='duration' && LabelNumberField) || 
                (this.props.formValues && components && (components.get(this.props.formValues['anyParam_'+ i])||{}).input=='taggings' && InsertableDropdownField) || 
                InputField
              }
              label={(this.props.formValues && components && (components.get(this.props.formValues['anyParam_'+ i])||{}).input=='duration' && NumberField) && 'Minutes'}
              multiple={(((this.props.formValues && components && (components.get(this.props.formValues['anyParam_'+ i])||{}).input=='selects') || (this.props.formValues && components && (components.get(this.props.formValues['anyParam_'+ i])||{}).input=='contains')) && SelectField)}
              options={options && options[(this.props.formValues && components && (components.get(this.props.formValues['anyParam_'+ i])||{}).options)]}
              width={6}
              validate= {[required]}
              placeholder='Cond. Value' />
            </Form.Group>
          )
        })
      )
    } else {
      return (<>
        {
          items.map((item, i)=>{
            return(<Segment key={i}>
              <Form.Group widths='16'>
                <Field name={'actionParam_'+ i} component={SelectField} onChange={this.onChangeSelect.bind(this, 'action{{PARAM}}_'+ i)}
                width={6}
                options={this.props.isTrigger==1 ? actionOptions('trigger-param') : actionOptions('automation-param')}
                validate= {[required]}
                // label='Action Type'
                placeholder='Action Type' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name={'actionValue_'+ i} component={
                  (this.props.formValues && components && (components.get(this.props.formValues['actionParam_'+ i])||{}).input=='select' && SelectField) || 
                  (this.props.formValues && components && (components.get(this.props.formValues['actionParam_'+ i])||{}).input=='selects' && SelectField) || 
                  (this.props.formValues && components && (components.get(this.props.formValues['actionParam_'+ i])||{}).input=='textarea' && TextAreaField) || 
                  (this.props.formValues && components && (components.get(this.props.formValues['actionParam_'+ i])||{}).input=='wysiwyg' && WysiwygField) || 
                  (this.props.formValues && components && (components.get(this.props.formValues['actionParam_'+ i])||{}).input=='taggings' && InsertableDropdownField) || 
                  (this.props.formValues && components && (components.get(this.props.formValues['actionParam_'+ i])||{}).input=='duration' && NumberField) || 
                  InputField
                }
                mode={'wysiwyg'}
                multiple={(this.props.formValues && components && (components.get(this.props.formValues['actionParam_'+ i])||{}).input=='selects' && SelectField)}
                options={options && options[(this.props.formValues && components && (components.get(this.props.formValues['actionParam_'+ i])||{}).options)]}
                rows={(this.props.formValues && components && (components.get(this.props.formValues['actionParam_'+ i])||{}).input=='textarea' ? 6 : null)}
                width={9}
                validate= {[required]}
                // label='Set Value'
                placeholder='Set Value' />
              </Form.Group>
            </Segment>)
          })
        }
      </>)
    }
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.automationStore.getAutomation!=undefined && this.props.automationStore.getAutomation.progress=='1' ? this.props.automationStore.getAutomation : {})
    const { progress, code, text }= (mode==2 ? (this.props.automationStore.updateAutomation!=undefined ? this.props.automationStore.updateAutomation : {}) : (this.props.automationStore.createAutomation!=undefined ? this.props.automationStore.createAutomation : {}))

    const components= new Map();
    const options= {};

    [...(conditionOptions('trigger-param') || []), ...(conditionOptions('automation-param') || []), ...(actionOptions('trigger-param') || []), ...(actionOptions('automation-param') || [])].map(item=>{
      item.value && components.set(item.value, item);
    })
    
    options['privacyOptions']= ticketOptions('privacy');
    options['categoryOptions']= ticketOptions('category');
    options['priorityOptions']= ticketOptions('priority');
    options['severityOptions']= ticketOptions('severity');
    options['groupOptions']= groupOptions();
    options['yesnoOptions']= yesnoOptions();
    options['commentOptions']= commentOptions();

    options['listWorkgroup']= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    options['listUser']= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])
    options['listOrganization']= (this.props.masterStore.listOrganization!=undefined && this.props.masterStore.listOrganization.progress=='1' ? this.props.masterStore.listOrganization.data : [])
    options['listProduct']= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])
    options['listForm']= (this.props.masterStore.listForm!=undefined && this.props.masterStore.listForm.progress=='1' ? this.props.masterStore.listForm.data : [])
    options['listMacro']= (this.props.masterStore.listMacro!=undefined && this.props.masterStore.listMacro.progress=='1' ? this.props.masterStore.listMacro.data : [])
    options['listRemark']= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : [])
    options['listChannel']= (this.props.masterStore.listChannel!=undefined && this.props.masterStore.listChannel.progress=='1' ? this.props.masterStore.listChannel.data : [])

    const listChannel= (this.props.masterStore.listChannel!=undefined && this.props.masterStore.listChannel.progress=='1' ? this.props.masterStore.listChannel.data : [])

    return (
      <div style={{padding: '1em 1.5em'}}>
        <div style={{position: 'absolute', marginTop: '-3em', right: '1.7em', display: 'inline-block', background: '#222', padding: '.2em .5em .6em .6em', borderRadius: '.5em'}}><Icon name='window close' color='yellow' style={{cursor: 'pointer', padding: '0', margin: '0', fontSize: '2em'}} onClick={this.props.closeModal.bind(this, pristine)}/></div>

        <Header as='h4' style={{margin: '0 0 2em 0'}}>
          <Icon name='sync alternate' style={{fontSize: '1em'}}/>
          <Header.Content>
            {this.props.isTrigger==1 ? 'Tigger' : 'Automation'} / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
          </Header.Content>
        </Header>

        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Grid stackable doubling>
            <Grid.Row columns={16}>
              <Grid.Column width={5}>
                <Header as='h5'>
                  <Header.Content>
                    {this.props.isTrigger==1 ? 'Tigger' : 'Automation'} Title
                    <Header.Subheader>Trigger or automation information.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='16'>
                    <Field name='isTrigger' component={LabelSelectField}
                    width={16}
                    options={yesnoOptions()}
                    validate= {[required]}
                    label='Trigger'
                    placeholder='Yes / No' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='16'>
                    <Field name='name' component={InputField}
                    width={16}
                    validate= {[required]}
                    label='Name'
                    placeholder='Name' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='descript' component={TextAreaField}
                    label='Description'
                    placeholder='Additional information' />
                  </Form.Group>
                </Segment>

                {/* <Header as='h5'>
                  <Header.Content>
                    Comm. Channels
                    <Header.Subheader>Related channel communication.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='channels' component={SelectField}
                    multiple
                    validate= {[required]}
                    options={listChannel}
                    label='Comm. Chanel / Stream'
                    placeholder='Channel contact socmed, phone etc' />
                  </Form.Group>
                </Segment> */}
              </Grid.Column>
              <Grid.Column width={11}>
                <Header as='h5'>
                  <Header.Content>
                    Meet ALL of Conditions
                    <Header.Subheader>Action will be executed when all condition is met.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  {this.drawAutomations(this.props.allAutomationQty, 'all', components, options)}

                  <div style={{textAlign: 'center', marginTop: '2em'}}>
                    <Button.Group size='mini'>
                      <Popup content='Remove / less automations'  position= 'bottom center'
                      trigger={
                        <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, false, 'all')}><Icon color='red' name='arrow up' size='large' /></Button>
                      }
                      inverted />
                      <Button.Or></Button.Or>
                      <Popup content='Add more automations'  position= 'bottom center'
                      trigger={
                        <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, true, 'all')}><Icon color='blue' name='arrow down' size='large' /></Button>
                      }
                      inverted />
                    </Button.Group>
                  </div>
                </Segment>

                <Header as='h5'>
                  <Header.Content>
                    Meet ANY of Conditions
                    <Header.Subheader>Action wil be executed when one of condition is met.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  {this.drawAutomations(this.props.anyAutomationQty, 'any', components, options)}

                  <div style={{textAlign: 'center', marginTop: '2em'}}>
                    <Button.Group size='mini'>
                      <Popup content='Remove / less automations'  position= 'bottom center'
                      trigger={
                        <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, false, 'any')}><Icon color='red' name='arrow up' size='large' /></Button>
                      }
                      inverted />
                      <Button.Or></Button.Or>
                      <Popup content='Add more automations'  position= 'bottom center'
                      trigger={
                        <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, true, 'any')}><Icon color='blue' name='arrow down' size='large' /></Button>
                      }
                      inverted />
                    </Button.Group>
                  </div>
                </Segment>

                <Header as='h5'>
                  <Header.Content>
                    Actions To Do
                    <Header.Subheader>Set ticket attribute and properties.</Header.Subheader>
                  </Header.Content>
                </Header>

                {this.drawAutomations(this.props.actionAutomationQty, 'action', components, options)}

                <div style={{textAlign: 'center', marginTop: '2em'}}>
                  <Button.Group size='mini'>
                    <Popup content='Remove / less automations'  position= 'bottom center'
                    trigger={
                      <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, false, 'action')}><Icon color='red' name='arrow up' size='large' /></Button>
                    }
                    inverted />
                    <Button.Or></Button.Or>
                    <Popup content='Add more automations'  position= 'bottom center'
                    trigger={
                      <Button type='button' style={{width: '3em'}} icon onClick={this.incrRowQty.bind(this, true, 'action')}><Icon color='blue' name='arrow down' size='large' /></Button>
                    }
                    inverted />
                  </Button.Group>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Automation' : 'Insert Automation') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.automationStore.getAutomation!=undefined && state.automationStore.getAutomation.progress==1 ? state.automationStore.getAutomation.data : {allAutomationQty: 1, anyAutomationQty: 1, actionAutomationQty: 1}),
      formValues: getFormValues('AutomationForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
      
      isTrigger: selector(state, 'isTrigger'),

      allAutomationQty: selector(state, 'allAutomationQty'),
      anyAutomationQty: selector(state, 'anyAutomationQty'),
      actionAutomationQty: selector(state, 'actionAutomationQty'),
    }),
    { load: getAutomation }
  )
  (reduxForm({
    form: 'AutomationForm',	// a unique identifier for this form
  })(AutomationForm)
  )
)
