import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse, addSeconds } from "date-fns"


import { connect } from "react-redux"
import { browseKnowledge, statusKnowledge } from "../../actions/service/knowledgeAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    knowledgeStore: state.knowledgeStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseKnowledge:(objParam) => dispatch(browseKnowledge(objParam)),
    statusKnowledge:(objParam) => dispatch(statusKnowledge(objParam)),
  }
}

class KnowledgeBrowse extends Component {
  constructor(props) {
    super(props)
    this.state= {
      expand: false,
    }
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.knowledgeStore.browseKnowledge!==undefined ? this.props.knowledgeStore.browseKnowledge : {})
    this.props.browseKnowledge({search: {...search, contentType: 'document'}, paging: paging})
  }

  componentDidUpdate(prevProps, prevStates) {
    const { search, paging }= (this.props.knowledgeStore.browseKnowledge!==undefined ? this.props.knowledgeStore.browseKnowledge : {})

    if (this.props.mode==1 && prevProps && prevProps.mode!=this.props.mode) {
      this.props.browseKnowledge({search: {...search}, paging: paging})
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.knowledgeStore.browseKnowledge!==undefined ? this.props.knowledgeStore.browseKnowledge : {})
    this.props.browseKnowledge(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }

  onChangeKnowledge(id, knowledge, e) {
    // console.log(id, knowledge, e)
    this.props.statusKnowledge({knowledgeId:id, isEnabled: knowledge})
  }

  render() {
    //console.log(this.props.knowledgeStore.browseKnowledge)
    const { expand }= this.state
    const { screenWidth, screenHeight, style }= this.props
    const { data, search, paging }= (this.props.knowledgeStore.browseKnowledge!==undefined ? this.props.knowledgeStore.browseKnowledge : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight, ...style}}>
        <div style={{flex: '1', padding: '0'}}>
          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell width={16}>
                  <Header as='h5' color='blue'>KNOWLEDGE</Header>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Header as='h5' color='blue' style={{width: 3*screenWidth/5}}>CONTENT</Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      <Table.Cell textAlign='center'>
                        <Popup position= 'top left' inverted content='Edit'
                          trigger= {<Icon style={{margin: '0', cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2', item.knowledgeId, item)}/>}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Popup position='right center' content={item.descript || '-'} inverted trigger={
                          <Header as='h5'>
                            <Header.Content>
                              <b>{item.title}</b>
                              <Header.Subheader>{item.category}</Header.Subheader>
                            </Header.Content>
                          </Header>
                        } />
                      </Table.Cell>
                      <Table.Cell className='noscroll' style={{overflowX: 'scroll', }}>
                        <div style={{minHeight: 200, width: 3*screenWidth/5}}>
                          <div style={{width: ((item.contents && item.contents.length) || 3) * 225}}>
                            <div style={{display: 'flex'}}>
                              {item.contents && item.contents.map(item=>{
                                return(
                                  <div style={{width: 200, height: 200, overflow: 'hidden', marginRight: '3em', background: '#fafafa', borderRadius: '.5em', padding: '1em', border: '1px solid #ddd'}} >
                                    <Header as='h5'>
                                      <Header.Content>
                                        <b>{item.title}</b>
                                        <Header.Subheader>{item.descript || 'No description'}</Header.Subheader>
                                      </Header.Content>
                                      
                                      <Divider />
                                      <div style={{height: 150}} dangerouslySetInnerHTML={{__html: item.contentHtml.replace(/img/g, 'div').replace(/[0-9][0-9]px/g, '1.2em').replace(/style/g, '_style')}} />
                                    </Header>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={5}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeBrowse)
