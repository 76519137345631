import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination
} from 'semantic-ui-react'

import numeral from "numeral"

import { connect } from "react-redux"
import { browseProduct } from "../../actions/halo/productAction"
import ProductSearch from './ProductSearch';


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    productStore: state.productStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseProduct:(objParam) => dispatch(browseProduct(objParam)),
  }
};


class ProductBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.productStore.browseProduct!==undefined ? this.props.productStore.browseProduct : {})
    this.props.browseProduct({search: search, paging: paging})
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.productStore.browseProduct!==undefined ? this.props.productStore.browseProduct : {})
    this.props.browseProduct(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.productStore.browseProduct!==undefined ? this.props.productStore.browseProduct : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1'}}>
          <Segment>
            <Card.Group itemsPerRow={5} stackable doubling>
              {data!=undefined ? data.map(item=>{
              return(
              <Card key={seq++} as='a' onClick={this.props.setMode.bind(this,2, item.productId)} style={{opacity: item.isEnabled=="1" ? '1' : '.7'}}>
                <div style={{height: '15em', overflow: 'hidden', textAlign: 'center'}} >
                  {item.avatar!=null ?  <Image src={item.avatar} wrapped ui={false} /> : <Icon  style={{fontSize: '10em', paddingTop: '.6em'}} name='cube' color='yellow' />}
                </div>
                <Card.Content>
                  <Header as='h3' color={(item.isEnabled=='0' && 'brown') || 'black'}>
                    <Header.Content>
                      <span style={item.isEnabled!='1'? {fontSize:'1.15em', textDecoration: 'line-through', color: '#A5673F'} : {}}>{item.name}</span>
                    </Header.Content>
                  </Header>
                  {false &&
                  <Card.Description>{item.descript}</Card.Description>
                  }
                </Card.Content>
                <Card.Content style={{textAlign: 'right'}}>
                  <sup>{item.curr}</sup>
                  {item.grossAmount!=item.nettAmount &&
                    <span style={{fontSize: '1em', fontWeight: '400', color: 'rgba(100,100,100,.6)'}}><strike>{numeral(item.grossAmount).format('0,0')}</strike></span>
                  }
                  {' '}
                  <span style={{fontSize: '1.2em', fontWeight: 'bold', color: 'rgba(100,100,100,1)'}}>{numeral(item.nettAmount).format('0,0')}</span>
                </Card.Content>
                <Card.Content extra style={{padding: '1em'}}>
                  <Header as='h5'>
                    Has {item.qaQty} of <i>Questions / Answers</i>
                    <Header.Subheader>{item.client || 'Posted by unknown'}</Header.Subheader>
                  </Header>
                </Card.Content>
              </Card>
              )}) : <div style={{padding: '1em'}}><Icon name='circle notch' loading /></div>
              }
            </Card.Group>
          </Segment>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductBrowse)
