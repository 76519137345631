import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Dropdown } from 'semantic-ui-react'

import color from "color-generator"
import { parse, format, endOfMonth } from "date-fns"
import PieRechart from '../../chart/PieRechart';
import RadialRechart from '../../chart/RadialRechart';
import RadarRechart from '../../chart/RadarRechart';

import BarRechart from '../../chart/BarRechart';
import LineRechart from '../../chart/LineRechart';
import AreaRechart from '../../chart/AreaRechart';

import ComposedRechart from '../../chart/ComposedRechart';

import { connect } from "react-redux"
import { slaStatistic } from "../../../actions/service/statisticAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    statisticStore: state.statisticStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    slaStatistic:(objParam) => dispatch(slaStatistic(objParam)),
  }
}

class ComplianceWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      chart: 'pie', //pie, radial, radar, bar, line, area, composed
    };
  }

  componentDidMount() {
    this.props.slaStatistic();

    if (this.props.chart && this.props.chart!=this.state.chart) {
      this.setState({
        chart: this.props.chart,
      })
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    const pieData=[], radialData= [], barData= [], lineData=[], composedData=[];
    const { data }= (this.props.statisticStore.slaStatistic && this.props.statisticStore.slaStatistic.progress==1 ? this.props.statisticStore.slaStatistic : {})

    if (!prevProps && this.props.chart && this.props.chart!=this.state.chart) {
      this.setState({
        chart: this.props.chart,
      })
    }
    if (data && data.data && data.stamp && (!this.state.stamp || this.state.stamp!=data.stamp)) {
      data.data.map(item=>{
        Object.keys(item).forEach(e => {
          this.state.chart=='pie' && pieData.push({
            ...item[e], fill: color((Math.random() * .5), (Math.random() * .9)).hexString()
          })
          this.state.chart=='radial' && radialData.push({
            ...item[e], fill: color((Math.random() * .5), (Math.random() * .9)).hexString()
          })
          this.state.chart=='bar' && barData.push({
            ...item[e], fill: color((Math.random() * .5), (Math.random() * .9)).hexString()
          })
          this.state.chart=='line' && lineData.push({
            ...item[e], fill: color((Math.random() * .5), (Math.random() * .9)).hexString()
          })
          this.state.chart=='area' && lineData.push({
            ...item[e], fill: color((Math.random() * .5), (Math.random() * .9)).hexString()
          })
          this.state.chart=='composed' && composedData.push({
            ...item[e], fill: color((Math.random() * .5), (Math.random() * .9)).hexString()
          })
        })
      })

      this.setState({
        stamp: data.stamp,
        pieData: pieData,
        radialData: radialData,
        barData: barData,
        lineData: lineData,
        composedData: composedData,
        legend: data.legend || [],
      });
    }
  }
  
  render() {
    const { height, width, params }= this.props
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <Header as='h5'>
          <Icon name='chart bar' style={{fontSize: '1em'}} />
          <Header.Content>
            SLA Compliance
            <b style={{paddingLeft: '.5em'}}><i>{this.state.stamp && format(new Date(this.state.stamp),'dd/MMM')}</i></b>
            <Header.Subheader>Ticket response and resolve time based on SLA.</Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em', minHeight: (this.props.height || 250) + 50}}>
        {this.state.chart=='pie' && <PieRechart mode={'simple'} data={this.state.pieData} label={true} width={this.props.width || 350} height={this.props.height || 250} />}
        {this.state.chart=='radial' && <RadialRechart data={this.state.radialData} legend={this.state.legend} width={this.props.width || 350} height={this.props.height || 250} />}
        {this.state.chart=='radar' && <RadarRechart data={this.state.pieData} legend={this.state.legend} width={this.props.width || 350} height={this.props.height || 250} />}

        {this.state.chart=='bar' && <BarRechart mode='simple' height={this.props.height || 250} yAxis={false} dashed={true} label={true} data={this.state.barData} legend={this.state.legend}/>}
        {this.state.chart=='line' && <LineRechart mode={'simple'} height={this.props.height || 250} yAxis={false} dashed={true} label={true} data={this.state.lineData} legend={this.state.legend}/>}
        {this.state.chart=='area' && <AreaRechart mode={'simple'} height={this.props.height || 250} yAxis={false} dashed={true} label={true} data={this.state.lineData} legend={this.state.legend}/>}

        {this.state.chart=='composed' && <ComposedRechart height={this.props.height || 250} yAxis={false} label={true} data={this.state.lineData} legend={this.state.legend}/>}
      </Segment>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComplianceWidget)