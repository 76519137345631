import React from 'react'
import { Container, Segment, Label, Icon, List, Header } from 'semantic-ui-react'

import ModalMessage from '../libraries/common/ModalMessage'

class AuthenticateOvisoneFooter extends React.Component {
    constructor(props) {
      super(props)
      this.state= {
        content: undefined,
      }
    }
  
    closeModal() {
      this.setState({open: false, content: undefined })
    }
  
    openModal(key) {
      this.setState({open: true, content: key,})
    }

    render() {
      const { open, provider }= this.state
      
      return(
        <div style={{textAlign: 'center', padding: '1.5em 1em 2em 1em', background: 'rgba(255, 255, 255, .0)'}}>
          <Header as='h5'>
            <b>Ovisone!</b> Digital Workplace 2.3.01
            <Header.Subheader>
              Trademarks and Copyrights by @Ovisone! 2023
            </Header.Subheader>
          </Header>
        </div>
      )
    }
} export default AuthenticateOvisoneFooter
