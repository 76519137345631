export default function acknowledgeReducer (state = {}, action) {
  switch (action.type) {
    case 'ACKNOWLEDGE_RESET': {
        return({
            ...state,
            getAcknowledge: {},
            updateAcknowledge: {},
            createAcknowledge: {},
        })
    }

    case 'ACKNOWLEDGE_BROWSE_PENDING': {
      return({...state, browseAcknowledge: {progress: 0}})
    }
    case 'ACKNOWLEDGE_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseAcknowledge: {...api.browseAcknowledge.response, progress: 1, payload: action.payload}})
            return({...state, browseAcknowledge: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseAcknowledge: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ACKNOWLEDGE_BROWSE_REJECTED': {
        return({...state, browseAcknowledge: {progress: 2, payload: action.payload}})
    }
    
    case 'ACKNOWLEDGE_GET_PENDING': {
        return({...state, getAcknowledge: {progress: 0}})
    }
    case 'ACKNOWLEDGE_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getAcknowledge: {...api.getAcknowledge.response, progress: 1, payload: action.payload}})
            return({...state, getAcknowledge: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getAcknowledge: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ACKNOWLEDGE_GET_REJECTED': {
        return({...state, getAcknowledge: {progress: 2, payload: action.payload}})
    }

    case 'ACKNOWLEDGE_STATUS_PENDING': {
      return({...state, statusAcknowledge: {progress: 0}})
    }
    case 'ACKNOWLEDGE_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusAcknowledge: {...api.statusAcknowledge.response, progress: 1, payload: action.payload}})
            return({...state, statusAcknowledge: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusAcknowledge: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ACKNOWLEDGE_STATUS_REJECTED': {
        return({...state, statusAcknowledge: {progress: 2, payload: action.payload}})
    }

    case 'ACKNOWLEDGE_CREATE_PENDING': {
      return({...state, createAcknowledge: {progress: 0}})
    }
    case 'ACKNOWLEDGE_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createAcknowledge: {...api.createAcknowledge.response, progress: 1, payload: action.payload}})
            return({...state, createAcknowledge: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createAcknowledge: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ACKNOWLEDGE_CREATE_REJECTED': {
        return({...state, createAcknowledge: {progress: 2, payload: action.payload}})
    }

    case 'ACKNOWLEDGE_UPDATE_PENDING': {
      return({...state, updateAcknowledge: {progress: 0}})
    }
    case 'ACKNOWLEDGE_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateAcknowledge: {...api.updateAcknowledge.response, progress: 1, payload: action.payload}})
            return({...state, updateAcknowledge: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateAcknowledge: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ACKNOWLEDGE_UPDATE_REJECTED': {
        return({...state, updateAcknowledge: {progress: 2, payload: action.payload}})
    }

    case 'ACKNOWLEDGE_REMOVE_PENDING': {
      return({...state, removeAcknowledge: {progress: 0}})
    }
    case 'ACKNOWLEDGE_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeAcknowledge: {...api.removeAcknowledge.response, progress: 1, payload: action.payload}})
            return({...state, removeAcknowledge: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeAcknowledge: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ACKNOWLEDGE_REMOVE_REJECTED': {
        return({...state, removeAcknowledge: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}