import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { groupOptions, conditionOptions }  from '../../modules/service/StaticMaster'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, SearchField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetReasoning, getReasoning,updateReasoning, createReasoning } from "../../actions/service/reasoningAction"
import { listProduct, listClassification } from "../../actions/service/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('ReasoningForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    reasoningStore: state.reasoningStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listProduct:(objParam) => dispatch(listProduct(objParam)),
    listClassification:(objParam) => dispatch(listClassification(objParam)),
    
    resetReasoning:(objParam) => dispatch(resetReasoning(objParam)),
    getReasoning:(objParam) => dispatch(getReasoning(objParam)),
    createReasoning:(objParam) => dispatch(createReasoning(objParam)),
    updateReasoning:(objParam) => dispatch(updateReasoning(objParam)),
  }
}

class ReasoningForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount() {
    this.props.listProduct()
    this.props.listClassification()

    this.props.resetReasoning(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getReasoning({reasoningId: this.props.reasoningId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.reasoningStore.updateReasoning!=undefined ? this.props.reasoningStore.updateReasoning : {}) : (this.props.reasoningStore.createReasoning!=undefined ? this.props.reasoningStore.createReasoning : {}))

    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateReasoning({...values}) : this.props.createReasoning({...values})
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.reasoningStore.getReasoning!=undefined && this.props.reasoningStore.getReasoning.progress=='1' ? this.props.reasoningStore.getReasoning : {})
    const { progress, code, text }= (mode==2 ? (this.props.reasoningStore.updateReasoning!=undefined ? this.props.reasoningStore.updateReasoning : {}) : (this.props.reasoningStore.createReasoning!=undefined ? this.props.reasoningStore.createReasoning : {}))

    const listProduct= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])
    const listClassification= (this.props.masterStore.listClassification!=undefined && this.props.masterStore.listClassification.progress=='1' ? this.props.masterStore.listClassification.data : [])

    return (
      <div style={{padding: '1em 1.5em'}}>
        <div style={{position: 'absolute', marginTop: '-3em', right: '1.7em', display: 'inline-block', background: '#222', padding: '.2em .5em .6em .6em', borderRadius: '.5em'}}><Icon name='window close' color='yellow' style={{cursor: 'pointer', padding: '0', margin: '0', fontSize: '2em'}} onClick={this.props.closeModal.bind(this, pristine)}/></div>
        
        <Header as='h4' style={{margin: '0 0 2em 0'}}>
          <Icon name='clipboard outline' style={{fontSize: '1em'}}/>
          <Header.Content>
            Ticket Reasoning / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
          </Header.Content>
        </Header>

        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Grid stackable doubling>
            <Grid.Row columns={16}>
              <Grid.Column width={16}>
                <Header as='h5'>
                  <Header.Content>
                    Reasoning Title
                    <Header.Subheader>Short description of reasoning.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='name' component={InputField}
                    validate= {[required]}
                    label='Name'
                    placeholder='Name' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='descript' component={TextAreaField}
                    label='Description'
                    placeholder='Additional information' />
                  </Form.Group>
                </Segment>
              {/* </Grid.Column>
              <Grid.Column width={10}> */}
                <Header as='h5'>
                  <Header.Content>
                  Associated Service
                    <Header.Subheader>Reasoning / closing title which relates to brands.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='products' component={SelectField}
                    multiple
                    options={listProduct}
                    // validate= {[required]}
                    label='Associated Brand'
                    placeholder='Related brand, service or product' />
                  </Form.Group>
                </Segment>

                <Header as='h5'>
                  <Header.Content>
                    Associated Classification
                    <Header.Subheader>Reasoning / closing title which relates to classifications.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='classifications' component={SelectField}
                    multiple
                    options={listClassification}
                    // validate= {[required]}
                    label='Ticket Classifications'
                    placeholder='List of related ticket classifications' />
                  </Form.Group>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Reasoning' : 'Insert Reasoning') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.reasoningStore.getReasoning!=undefined && state.reasoningStore.getReasoning.progress==1 ? state.reasoningStore.getReasoning.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      openStamp: selector(state, 'openStamp'),
    }),
    { load: getReasoning }
  )
  (reduxForm({
    form: 'ReasoningForm',	// a unique identifier for this form
  })(ReasoningForm)
  )
)
