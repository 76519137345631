import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Card, Modal, Tab, Label } from 'semantic-ui-react'

import MenuHorizontal from '../../libraries/common/MenuHorizontal'

import CollectionForm from './CollectionForm'
import RestructuringForm from './RestructuringForm'
import TelesalesForm from './TelesalesForm'
import VerificationForm from './VerificationForm'

import TargetForm from './TargetForm'
import CustomerForm from './CustomerForm'
import VisitorForm from './VisitorForm'

import EngagementBrowse from './EngagementBrowse'
import KnowledgeBrowse from './KnowledgeBrowse'

import { connect } from "react-redux"

import { loggingError } from "../../actions/system/errorAction"
import { getSetting } from "../../actions/halo/settingAction"
import { contactOmniphone, remarkOmniphone, knowledgeOmniphone } from "../../actions/stream/omniphoneAction"
import { listRemark, listProduct } from "../../actions/halo/masterAction"
import { browseTarget } from "../../actions/stream/targetAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    omniphoneStore: state.omniphoneStore,
    settingStore: state.settingStore,
    targetStore: state.targetStore,
    errorStore: state.errorStor
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listRemark:(objParam) => dispatch(listRemark(objParam)),
    listProduct:(objParam) => dispatch(listProduct(objParam)),

    contactOmniphone:(objParam) => dispatch(contactOmniphone(objParam)),
    remarkOmniphone:(objParam) => dispatch(remarkOmniphone(objParam)),
    knowledgeOmniphone:(objParam) => dispatch(knowledgeOmniphone(objParam)),

    getSetting:(objParam) => dispatch(getSetting(objParam)),
    browseTarget:(objParam) => dispatch(browseTarget(objParam)),
    loggingError:(objParam) => dispatch(loggingError(objParam)),
  }
}

class PopupWindow extends Component {
  constructor(props){
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    window.open(this.props.url, '_blank');
  }

  render() {
    return(
      <div>
      </div>
    )
  }
}

const options= (props)=>{
  const menus= []
  
  props.campaign=='Collection' && 
  menus.push(
    {
      key: 1,
      icon: 'copy outline',
      caption: 'Collection',
    }
  )
  props.campaign=='Restructuring' && 
  menus.push(
    {
      key: 1,
      icon: 'cubes',
      caption: 'Restructuring',
    }
  )
  props.campaign=='Telesales' && 
  menus.push(
    {
      key: 1,
      icon: 'closed captioning outline',
      caption: 'Telesales',
    }
  )
  props.campaign=='Verification' && 
  menus.push(
    {
      key: 1,
      icon: 'lab',
      caption: 'Verification',
    }
  )
  false && 
  menus.push(
    {
      key: 1,
      icon: 'hotel',
      caption: 'Tourism',
    }
  )
  false && 
  menus.push(
    {
      key: 1,
      icon: 'stethoscope',
      caption: 'Insurance',
    }
  )

  !props.campaign && !props.customer && menus.push(
    {
      key: 1,
      icon: 'id badge outline',
      caption: 'Visitor',
    }
  )
  props.customer && menus.push(
    {
      key: 2,
      icon: 'user circle outline',
      caption: 'Customer',
    }
  )
  (props.campaign || props.customer || props.visitor) && menus.push(
    {
      key: 3,
      icon: 'ellipsis vertical',
      caption: 'Engagement',
    }
  )
  menus.push(
    {
      key: 4,
      icon: 'folder outline',
      caption: 'Knowledge',
    }
  )
  props.campaign && menus.push(
    {
      key: 5,
      icon: 'user circle outline',
      caption: 'Contact Info',
    }
  )

  return menus;
}

class OmniphoneContentOutbound extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      collapsed: false,
      key: 1,

      targetId: null,
      clientId: null,

      isPopup: null,
      isPrevPopup: null,
    };
  }

  componentDidMount() {
    const { status, conversation }= this.props;
    
    this.props.listProduct();
    this.props.knowledgeOmniphone();

    if (conversation) {
      // console.log('DIDMOUNT', status, 'visitorId: ', conversation.visitorId, 'targetId: ', conversation.targetId);

      conversation.campaignId>0 && this.props.listRemark({campaignId: conversation.campaignId});
      this.props.contactOmniphone({targetId: conversation.targetId, visitorId: conversation.visitorId, customerId: conversation.customerId, contactId: conversation.contactId});

      this.setState({
        isPopup: new Date(),
        isPrevPopup: this.state.isPopup,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { status, conversation }= this.props;

    // console.log('DIDUPDATE', status, 'visitorId: ', conversation.visitorId, 'targetId: ', conversation.targetId, 'prevProps: ', prevProps.conversation);

    if (status=='occupied' && prevProps && prevProps.conversation && conversation && 
      (conversation.targetId!=0 || conversation.visitorId!=0 || conversation.customerId!=0 || conversation.contactId!=0) &&
      ((prevProps.conversation.targetId!=conversation.targetId) || (prevProps.conversation.visitorId!=conversation.visitorId) || (prevProps.conversation.customerId!=conversation.customerId) || (prevProps.conversation.contactId!=conversation.contactId))      
    ) {
      this.setState({
        isPopup: new Date(),
        isPrevPopup: this.state.isPopup,
      })
      conversation.campaignId>0 && this.props.listRemark({campaignId: conversation.campaignId});
      this.props.contactOmniphone({targetId: conversation.targetId, visitorId: conversation.visitorId, customerId: conversation.customerId, contactId: conversation.contactId});
    }
  }

  componentWillUnmount() {
    const { search, paging }= (this.props.targetStore.browseTarget ? this.props.targetStore.browseTarget : {})
    this.props.browseTarget({search: search, paging: paging})
  }

  textCollapse() {
    const { collapsed }= this.state
    this.setState({
      collapsed: !collapsed,
    })
  }

  setMenu(key, id) {
    this.setState({key: key})
  }

  setMode(mode, id, clientId) {
    this.setState({mode: mode, ticketId: id, clientId: clientId})
  }

  render() {
    const { collapsed, key }= this.state
    const { mode, status, conversation, screenWidth,  screenHeight}= this.props
    const { target, customer, setting }= (this.props.omniphoneStore.contactOmniphone && this.props.omniphoneStore.contactOmniphone.progress=='1' && this.props.omniphoneStore.contactOmniphone.data ? this.props.omniphoneStore.contactOmniphone.data : {})

    const { data }= (this.props.settingStore.getSetting ? this.props.settingStore.getSetting : {})

    const vars= ['custRef', 'contractRef'];
    let apiFrontend;

    try {
      if (data && data.apiFrontend && target) {
          apiFrontend= data.apiFrontend.replace(/{custRef}/g, target.custRef).replace(/{contractRef}/g, target.contractRef);
      }

      let script=  target && target.script && target.script.script; ///'Selamat datang, bagaimana kabarnya, kami informasikan tagihan Anda sebesar ... silahkan lakukan pembayaran melalui bank atau pembayaran terdekat sebelum tanggal 01/Jan agar tidak dikenakan denda.'
      if (collapsed && script) {
        script= script.split(' ').slice(0,10).join(' ')
      }
      // console.log('!!! RENDERED WEBPHONE CONTENT', conversation)
      return(
        <div>
          {(script || script=='') &&
            <Segment style={{padding: '1em', background: '#eaeaea', borderRadius: '0em', overflow: 'hidden', color: '#666', border: 'none', margin: '1em -1em'}}>
              <Header as='h5' color='red' style={{marginBottom: '.5em'}}>
                <Icon name={collapsed ? 'angle double down' : 'angle double up'}  style={{fontSize: '1em', margin: '0', cursor: 'pointer'}} color={!collapsed ? 'yellow' : 'yellow'} onClick={this.textCollapse.bind(this)} circular inverted/>
                <Header.Content style={{paddingLeft: '1em'}}>
                  <p style={{fontSize: '1.1em', color: '#dd0000', textShadow: '0 1px 0 #fff', fontWeight: 'bold'}}>
                    <sup><Icon name='quote left' color='blue' style={{marginRight: '.5em'}}/></sup>
                    {script}
                    <sub><Icon name='quote right' color='blue' style={{marginLeft: '.5em'}}/></sub>
                  </p> 
                </Header.Content>
              </Header>
            </Segment>
          }
          <div style={{margin: '1.5em 0 0 0'}}>
            <MenuHorizontal onClick={this.setMenu.bind(this)} options= {options({campaign: (target && target.campaignType)})} color='red' simple />
          </div>
          
            <div style={{paddingTop: '1.53em', background: '#fff'}}>
              {data && data.apiFrontend && data.apiFrontend!='' && target && target.dataSource!='F' && ((!this.state.isPrevPopup && this.state.isPopup) || (this.state.isPrevPopup!=this.state.isPopup)) && <>
                {/* {!data.isPopup && data.apiFrontend && data.apiFrontend.substring(0,5)=='https' ?
                  <Segment style={{display: key==1 && 'block' || 'none'}}>
                    <iframe src={apiFrontend} title="Iframe Example" style={{width: '100%', height: screenHeight/2}} ></iframe>
                  </Segment> : <></>
                } */}
                {data.isPopup && data.apiFrontend ?
                  <Segment style={{background: '#fff', height: screenHeight/2, display: key==1 && 'block' || 'none'}}>
                    <Header as='h1' style={{margin: '2em 1em 1.5em 1em'}}>
                      <Icon name='window restore outline' />
                      <Header.Content>
                        <Label style={{padding: '.3em .5em'}} color='black'>POPUP !</Label>
                        <Header.Subheader style={{fontSize: '1.1em', color: '#444'}}>
                          <b>Check your popup customer form on browsers tab or Window !</b>
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                    <PopupWindow url={apiFrontend} />
                  </Segment> : <></>
                }
              </>}
              {(!data || !data.apiFrontend || (target && target.dataSource=='F') || (data && data.apiFrontend && data.apiFrontend=='')) && <>
                {target && target.campaignType==='Collection' && 
                  <CollectionForm screenWidth={screenWidth} screenHeight={screenHeight}
                  popup= {setting && setting.popup && setting.popup.popupCollection}
                  media={conversation.media} targetId={conversation.targetId} visitorId={conversation.visitorId} agentId={conversation.userId} clientId={conversation.clientId} campaignId={conversation.campaignId} voiceId={conversation.voiceId} messagingId={conversation.messagingId}
                  hasSaved={this.props.hasSaved.bind(this)} 
                  endConversation={this.props.endConversation.bind(this)}
                  style={{display: key==1 && 'block' || 'none'}} />
                }
                {target && target.campaignType==='Restructuring' && 
                  <RestructuringForm screenWidth={screenWidth} screenHeight={screenHeight}
                  popup= {setting && setting.popup && setting.popup.popupCollection}
                  media={conversation.media} targetId={conversation.targetId} visitorId={conversation.visitorId} agentId={conversation.userId} clientId={conversation.clientId} campaignId={conversation.campaignId} voiceId={conversation.voiceId} messagingId={conversation.messagingId}
                  hasSaved={this.props.hasSaved.bind(this)} 
                  endConversation={this.props.endConversation.bind(this)}
                  style={{display: key==1 && 'block' || 'none'}} />
                }
                {target && target.campaignType==='Telesales' && 
                  <TelesalesForm screenWidth={screenWidth} screenHeight={screenHeight} 
                  popup= {setting && setting.popup && setting.popup.popupTelesales}
                  media={conversation.media} targetId={conversation.targetId} visitorId={conversation.visitorId} agentId={conversation.userId} clientId={conversation.clientId} campaignId={conversation.campaignId} voiceId={conversation.voiceId} messagingId={conversation.messagingId}
                  hasSaved={this.props.hasSaved.bind(this)} 
                  endConversation={this.props.endConversation.bind(this)}
                  style={{display: key==1 && 'block' || 'none'}} />
                }
                {target && target.campaignType==='Verification' && 
                  <VerificationForm screenWidth={screenWidth} screenHeight={screenHeight} 
                  popup= {setting && setting.popup && setting.popup.popupVerification}
                  media={conversation.media} targetId={conversation.targetId} visitorId={conversation.visitorId} agentId={conversation.userId} clientId={conversation.clientId} campaignId={conversation.campaignId} voiceId={conversation.voiceId} messagingId={conversation.messagingId}
                  hasSaved={this.props.hasSaved.bind(this)} 
                  endConversation={this.props.endConversation.bind(this)}
                  style={{display: key==1 && 'block' || 'none'}} />
                }
              </>}
              {!target && !customer && 
                <VisitorForm setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} 
                media={conversation.media} visitorId={conversation.visitorId} agentId={conversation.userId} clientId={conversation.clientId} voiceId={conversation.voiceId} messagingId={conversation.messagingId} visitor={this.props.visitor} 
                hasSaved={this.props.hasSaved.bind(this)} 
                endConversation={this.props.endConversation.bind(this)}
                style={{display: key==1 && 'block' || 'none'}} />
              }
              {customer && 
                <CustomerForm setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} 
                media={conversation.media} customerId={conversation.customerId} visitorId={conversation.visitorId} agentId={conversation.userId} clientId={conversation.clientId} voiceId={conversation.voiceId} messagingId={conversation.messagingId} 
                hasSaved={this.props.hasSaved.bind(this)} 
                endConversation={this.props.endConversation.bind(this)}
                style={{display: key==2 && 'block' || 'none'}} />
              }
              <EngagementBrowse screenWidth={screenWidth} screenHeight={screenHeight} targetId={conversation.targetId} customerId={conversation.customerId} clientId={conversation.clientId} style={{display: key==3 && 'block' || 'none'}}/>
              <KnowledgeBrowse screenWidth={screenWidth} screenHeight={screenHeight} style={{display: key==4 && 'block' || 'none'}}/>
              {target && <TargetForm screenWidth={screenWidth} screenHeight={screenHeight} mode={2} targetId={conversation.targetId} customerId={conversation.customerId} clientId={conversation.clientId} style={{display: key==5 && 'block' || 'none'}}/>}
            </div>
        </div>
      )
    } catch(err) {
      this.props.loggingError({level: 'error', actor: 'APP HALO', scope: 'OmniphoneContentOutbound', event: 'On render', message: err });
      return<>ERROR UX RENDERING</>
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(OmniphoneContentOutbound)
