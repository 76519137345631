export default function automationReducer (state = {}, action) {
  switch (action.type) {
    case 'AUTOMATION_RESET': {
        return({
            ...state,
            getAutomation: {},
            updateAutomation: {},
            createAutomation: {},
        })
    }

    case 'AUTOMATION_BROWSE_PENDING': {
      return({...state, browseAutomation: {progress: 0}})
    }
    case 'AUTOMATION_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseAutomation: {...api.browseAutomation.response, progress: 1, payload: action.payload}})
            return({...state, browseAutomation: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseAutomation: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'AUTOMATION_BROWSE_REJECTED': {
        return({...state, browseAutomation: {progress: 2, payload: action.payload}})
    }
    
    case 'AUTOMATION_GET_PENDING': {
        return({...state, getAutomation: {progress: 0}})
    }
    case 'AUTOMATION_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getAutomation: {...api.getAutomation.response, progress: 1, payload: action.payload}})
            return({...state, getAutomation: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getAutomation: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'AUTOMATION_GET_REJECTED': {
        return({...state, getAutomation: {progress: 2, payload: action.payload}})
    }

    case 'AUTOMATION_STATUS_PENDING': {
      return({...state, statusAutomation: {progress: 0}})
    }
    case 'AUTOMATION_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusAutomation: {...api.statusAutomation.response, progress: 1, payload: action.payload}})
            return({...state, statusAutomation: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusAutomation: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'AUTOMATION_STATUS_REJECTED': {
        return({...state, statusAutomation: {progress: 2, payload: action.payload}})
    }

    case 'AUTOMATION_CREATE_PENDING': {
      return({...state, createAutomation: {progress: 0}})
    }
    case 'AUTOMATION_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createAutomation: {...api.createAutomation.response, progress: 1, payload: action.payload}})
            return({...state, createAutomation: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createAutomation: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'AUTOMATION_CREATE_REJECTED': {
        return({...state, createAutomation: {progress: 2, payload: action.payload}})
    }

    case 'AUTOMATION_UPDATE_PENDING': {
      return({...state, updateAutomation: {progress: 0}})
    }
    case 'AUTOMATION_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateAutomation: {...api.updateAutomation.response, progress: 1, payload: action.payload}})
            return({...state, updateAutomation: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateAutomation: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'AUTOMATION_UPDATE_REJECTED': {
        return({...state, updateAutomation: {progress: 2, payload: action.payload}})
    }

    case 'AUTOMATION_REMOVE_PENDING': {
      return({...state, removeAutomation: {progress: 0}})
    }
    case 'AUTOMATION_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeAutomation: {...api.removeAutomation.response, progress: 1, payload: action.payload}})
            return({...state, removeAutomation: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeAutomation: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'AUTOMATION_REMOVE_REJECTED': {
        return({...state, removeAutomation: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}