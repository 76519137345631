export default function reasoningReducer (state = {}, action) {
  switch (action.type) {
    case 'REASONING_RESET': {
        return({
            ...state,
            getReasoning: {},
            updateReasoning: {},
            createReasoning: {},
        })
    }

    case 'REASONING_BROWSE_PENDING': {
      return({...state, browseReasoning: {progress: 0}})
    }
    case 'REASONING_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseReasoning: {...api.browseReasoning.response, progress: 1, payload: action.payload}})
            return({...state, browseReasoning: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseReasoning: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REASONING_BROWSE_REJECTED': {
        return({...state, browseReasoning: {progress: 2, payload: action.payload}})
    }
    
    case 'REASONING_GET_PENDING': {
        return({...state, getReasoning: {progress: 0}})
    }
    case 'REASONING_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getReasoning: {...api.getReasoning.response, progress: 1, payload: action.payload}})
            return({...state, getReasoning: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getReasoning: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REASONING_GET_REJECTED': {
        return({...state, getReasoning: {progress: 2, payload: action.payload}})
    }

    case 'REASONING_STATUS_PENDING': {
      return({...state, statusReasoning: {progress: 0}})
    }
    case 'REASONING_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusReasoning: {...api.statusReasoning.response, progress: 1, payload: action.payload}})
            return({...state, statusReasoning: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusReasoning: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REASONING_STATUS_REJECTED': {
        return({...state, statusReasoning: {progress: 2, payload: action.payload}})
    }

    case 'REASONING_CREATE_PENDING': {
      return({...state, createReasoning: {progress: 0}})
    }
    case 'REASONING_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createReasoning: {...api.createReasoning.response, progress: 1, payload: action.payload}})
            return({...state, createReasoning: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createReasoning: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REASONING_CREATE_REJECTED': {
        return({...state, createReasoning: {progress: 2, payload: action.payload}})
    }

    case 'REASONING_UPDATE_PENDING': {
      return({...state, updateReasoning: {progress: 0}})
    }
    case 'REASONING_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateReasoning: {...api.updateReasoning.response, progress: 1, payload: action.payload}})
            return({...state, updateReasoning: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateReasoning: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REASONING_UPDATE_REJECTED': {
        return({...state, updateReasoning: {progress: 2, payload: action.payload}})
    }

    case 'REASONING_REMOVE_PENDING': {
      return({...state, removeReasoning: {progress: 0}})
    }
    case 'REASONING_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeReasoning: {...api.removeReasoning.response, progress: 1, payload: action.payload}})
            return({...state, removeReasoning: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeReasoning: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REASONING_REMOVE_REJECTED': {
        return({...state, removeReasoning: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}