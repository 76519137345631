import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Label, Popup } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import PipelineSearch from '../../libraries/service/PipelineSearch'
import PipelineDashboard from '../../libraries/service/PipelineDashboard';



class PipelineBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        pipelineId: 0,
      };
    }

    setMode(mode, id, params) {
      this.setState({mode: mode, pipelineId: id, params: params})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        pipelineId: 0,
      });
    }

    render() {
      const {mode, pipelineId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div>
          <div style={{display: 'flex', margin: '1.5em 0 0 1em'}}>
            <div style={{padding: '0 .1em 0 0'}}>
              <Popup content='Switch to Dashboard' position='top left' inverted trigger={
                <Label as='a' onClick={this.props.openWindow.bind(this, 'dashboard', 'mypage')} >
                  SHOW DASHBOARD
                </Label>
              } />
            </div>
            <div style={{padding: '0 .1em'}}>
              <Popup content='Pipeline on Priority' position='top left' inverted trigger={
                <Label as='a' onClick={this.props.openWindow.bind(this, 'pipeline', {mode: 'priority'})} color={(!this.props.params || (this.props.params && !this.props.params.mode) || (this.props.params && this.props.params.mode=='priority')) ? 'yellow' : null}>
                  PRIORITY LEVEL
                </Label>
              } />
            </div>
            <div style={{padding: '0 .1em'}}>
              <Popup content='Pipeline on Severity' position='top left' inverted trigger={
                <Label as='a' onClick={this.props.openWindow.bind(this, 'pipeline', {mode: 'severity'})} color={this.props.params && this.props.params.mode=='severity' ? 'yellow' : null}>
                  SEVERITY LEVEL
                </Label>
              } />
            </div>
            <div style={{flex: 1, padding: '0 0 0 0.1em'}}>
              <Popup content='Pipeline on SLA' position='top left' inverted trigger={
                <Label as='a' onClick={this.props.openWindow.bind(this, 'pipeline', {mode: 'sla'})} color={this.props.params && this.props.params.mode=='sla' ? 'yellow' : null}>
                  SLA BREACH
                </Label>
              } />
            </div>
          </div>
          <PipelineSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} params={this.props.params} />
          <PipelineDashboard mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} params={this.props.params} />
        </div>
      )
    }
}

export default PipelineBody
