export default function formsReducer (state = {}, action) {
  switch (action.type) {
    case 'FORMS_RESET': {
        return({
            ...state,
            getForms: {},
            updateForms: {},
            createForms: {},
        })
    }

    case 'FORMS_BROWSE_PENDING': {
      return({...state, browseForms: {progress: 0}})
    }
    case 'FORMS_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseForms: {...api.browseForms.response, progress: 1, payload: action.payload}})
            return({...state, browseForms: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseForms: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'FORMS_BROWSE_REJECTED': {
        return({...state, browseForms: {progress: 2, payload: action.payload}})
    }
    
    case 'FORMS_GET_PENDING': {
        return({...state, getForms: {progress: 0}})
    }
    case 'FORMS_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getForms: {...api.getForms.response, progress: 1, payload: action.payload}})
            return({...state, getForms: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getForms: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'FORMS_GET_REJECTED': {
        return({...state, getForms: {progress: 2, payload: action.payload}})
    }

    case 'FORMS_STATUS_PENDING': {
      return({...state, statusForms: {progress: 0}})
    }
    case 'FORMS_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusForms: {...api.statusForms.response, progress: 1, payload: action.payload}})
            return({...state, statusForms: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusForms: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'FORMS_STATUS_REJECTED': {
        return({...state, statusForms: {progress: 2, payload: action.payload}})
    }

    case 'FORMS_CREATE_PENDING': {
      return({...state, createForms: {progress: 0}})
    }
    case 'FORMS_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createForms: {...api.createForms.response, progress: 1, payload: action.payload}})
            return({...state, createForms: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createForms: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'FORMS_CREATE_REJECTED': {
        return({...state, createForms: {progress: 2, payload: action.payload}})
    }

    case 'FORMS_UPDATE_PENDING': {
      return({...state, updateForms: {progress: 0}})
    }
    case 'FORMS_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateForms: {...api.updateForms.response, progress: 1, payload: action.payload}})
            return({...state, updateForms: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateForms: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'FORMS_UPDATE_REJECTED': {
        return({...state, updateForms: {progress: 2, payload: action.payload}})
    }

    case 'FORMS_REMOVE_PENDING': {
      return({...state, removeForms: {progress: 0}})
    }
    case 'FORMS_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeForms: {...api.removeForms.response, progress: 1, payload: action.payload}})
            return({...state, removeForms: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeForms: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'FORMS_REMOVE_REJECTED': {
        return({...state, removeForms: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}