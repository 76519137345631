import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse, addSeconds } from "date-fns"

import { personalOptions, ticketOptions }  from '../../modules/service/StaticMaster'

import { connect } from "react-redux"
import { listRemark } from "../../actions/service/masterAction"
import { priorityPipeline, severityPipeline, slaPipeline } from "../../actions/service/statisticAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    statisticStore: state.statisticStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listRemark:(objParam) => dispatch(listRemark(objParam)),

    priorityPipeline:(objParam) => dispatch(priorityPipeline(objParam)),
    severityPipeline:(objParam) => dispatch(severityPipeline(objParam)),
    slaPipeline:(objParam) => dispatch(slaPipeline(objParam)),
  }
}

class PipelineDashboard extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }
  
  componentDidMount() {
    this.props.listRemark();
  }

  render() {
    //console.log(this.props.statisticStore.priorityPipeline)
    const { expand }= this.state
    const { screenWidth, screenHeight, params }= this.props
    
    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : [])

    const priorityPipeline= (this.props.statisticStore.priorityPipeline!=undefined && this.props.statisticStore.priorityPipeline.progress=='1' ? this.props.statisticStore.priorityPipeline.data : [])
    const severityPipeline= (this.props.statisticStore.severityPipeline!=undefined && this.props.statisticStore.severityPipeline.progress=='1' ? this.props.statisticStore.severityPipeline.data : [])
    const slaPipeline= (this.props.statisticStore.slaPipeline!=undefined && this.props.statisticStore.slaPipeline.progress=='1' ? this.props.statisticStore.slaPipeline.data : [])

    return (
      <div style={{display: 'flex', margin: '0 1em', padding: '1em 0'}}>
        {(listRemark||[]).map(item=>{
          return(
            <div className={'color '+ item.color} style={{flex: 1, minHeight: screenHeight, padding: '1em 1em 1em 1em'}}>
              <Header as='h5' style={{marginBottom: '1.5em'}}>
                <Header.Content>
                  <Label size='large' color={item.color} style={{margin: '0'}}>
                  <b>{item.text.toUpperCase()}</b>
                  </Label>
                  <Header.Subheader><b>{item.description}</b></Header.Subheader>
                </Header.Content>
              </Header>

              <Divider style={{marginBottom: '2em'}}/>
              {(!params || (params && !params.mode) || (params && params.mode=='priority')) &&
                <Card.Group itemsPerRow={1}>
                  {ticketOptions('priority').map(item=>{
                    if (item.value) {
                      return(
                        <Card as='a' style={{padding: '0'}}>
                          <Card.Content style={{padding: '1em'}}>
                            <Card.Header>
                              <Divider horizontal><Label color={item.color} size='large' style={{margin: '0', padding: '.5em .5em', minWidth: '5em'}}><b>{item.text}</b></Label></Divider>
                            </Card.Header>
                            <div style={{display: 'flex'}}>
                              <div style={{flex: 1, color: '#000', fontSize: '1.2em', padding: '.2em 1em', textAlign: 'center', background: '#eeeeeecc', fontWeight: 'bold', borderRight: '1px solid #999999aa'}}>
                                <Label size='big' circular>{Math.floor(Math.random() * 100)}</Label>
                              </div>
                              <div style={{flex: 1, color: '#000', fontSize: '1.2em', padding: '.8em 1em 0 1em', textAlign: 'center', background: '#ddddddaa', fontWeight: 'bold', borderLeft: '1px solid #ffffffcc'}}>
                                {Math.floor(Math.random() * 100)}<sup>%</sup>
                              </div>
                            </div>
                          </Card.Content>
                        </Card>
                      )
                    }
                  })}
                </Card.Group>
              }

              {params && params.mode=='severity' && 
                <Card.Group itemsPerRow={1}>
                  {ticketOptions('severity').map(item=>{
                    if (item.value) {
                      return(
                        <Card as='a' style={{padding: '0'}}>
                          <Card.Content style={{padding: '1em'}}>
                            <Card.Header>
                              <Divider horizontal><Label color={item.color} size='large' style={{margin: '0', padding: '.5em .5em', minWidth: '5em'}}><b>{item.text}</b></Label></Divider>
                            </Card.Header>
                            <div style={{display: 'flex'}}>
                              <div style={{flex: 1, color: '#000', fontSize: '1.2em', padding: '.2em 1em', textAlign: 'center', background: '#eeeeeecc', fontWeight: 'bold', borderRight: '1px solid #999999aa'}}>
                                <Label size='big' circular>{Math.floor(Math.random() * 100)}</Label>
                              </div>
                              <div style={{flex: 1, color: '#000', fontSize: '1.2em', padding: '.8em 1em 0 1em', textAlign: 'center', background: '#ddddddaa', fontWeight: 'bold', borderLeft: '1px solid #ffffffcc'}}>
                                {Math.floor(Math.random() * 100)}<sup>%</sup>
                              </div>
                            </div>
                          </Card.Content>
                        </Card>
                      )
                    }
                  })}
                </Card.Group>
              }

              {params && params.mode=='sla' &&
                <Card.Group itemsPerRow={1}>
                  {ticketOptions('slabreach').map(item=>{
                    if (item.value) {
                      return(
                        <Card as='a' style={{padding: '0'}}>
                          <Card.Content style={{padding: '1em'}}>
                            <div style={{display: 'flex'}}>
                              <div style={{flex: 1, color: '#000', padding: '1em 1em', background: item.value=='OK' ? '#0091b8' : '#f47c00', fontWeight: 'bold', borderRight: '1px solid #666666aa'}}>
                                <Header as='h5' inverted>
                                  <Header.Content>
                                    <b>SLA {item.value}</b>
                                    <Header.Subheader><b>{item.text}</b></Header.Subheader>
                                  </Header.Content>
                                </Header>
                              </div>
                              <div style={{flex: 1, color: '#000', fontSize: '1.2em', padding: '.5em 1em', textAlign: 'center', background: '#ddddddaa', fontWeight: 'bold', borderLeft: '1px solid #ffffffcc'}}>
                                {Math.floor(Math.random() * 100)}
                                <Divider />
                                {Math.floor(Math.random() * 100)}<sup>%</sup>
                              </div>
                            </div>
                          </Card.Content>
                        </Card>
                      )
                    }
                  })}
                </Card.Group>
              }
            </div>
          )
        })}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PipelineDashboard)
