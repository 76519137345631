import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'

import { format, parse, addSeconds, differenceInSeconds, differenceInMinutes, startOfDay, subDays } from "date-fns"

import { recentOptions, personalOptions, ticketOptions }  from '../../modules/service/StaticMaster'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, SelectField, DatePickerField, LabelDatePickerField, LabelSelectField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { priorityPipeline, severityPipeline, slaPipeline } from "../../actions/service/statisticAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    statisticStore: state.statisticStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    priorityPipeline:(objParam) => dispatch(priorityPipeline(objParam)),
    severityPipeline:(objParam) => dispatch(severityPipeline(objParam)),
    slaPipeline:(objParam) => dispatch(slaPipeline(objParam)),
  }
}


class HistoricalSearch extends Component {
  constructor(props) {
    super(props)
    this.state= {
      searchMode: true,
    }
  }

  submitForm(values) {
    const { search }= this.state
    this.props.priorityPipeline({search: {...values, ...search}})
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeDate(key, v) {
    var values= []
    values[key]= v
    if (this.props.params && this.props.params.mode=='severity') {
      const { search }= (this.props.statisticStore.severityPipeline!==undefined ? this.props.statisticStore.severityPipeline : {})
      this.props.severityPipeline({search: {...search, ...values}})
    } else if (this.props.params && this.props.params.mode=='sla') {
      const { search }= (this.props.statisticStore.slaPipeline!==undefined ? this.props.statisticStore.slaPipeline : {})
      this.props.slaPipeline({search: {...search, ...values}})
    } else {
      const { search }= (this.props.statisticStore.priorityPipeline!==undefined ? this.props.statisticStore.priorityPipeline : {})
      this.props.priorityPipeline({search: {...search, ...values}})
    }
  }

  onChangeInput(e, v) {
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        if (parent.props.params && parent.props.params.mode=='severity') {
          const { search }= (parent.props.statisticStore.severityPipeline!==undefined ? parent.props.statisticStore.severityPipeline : {})
          parent.props.severityPipeline({search: {...search, keyword: v}})
        } else if (parent.props.params && parent.props.params.mode=='sla') {
          const { search }= (parent.props.statisticStore.slaPipeline!==undefined ? parent.props.statisticStore.slaPipeline : {})
          parent.props.slaPipeline({search: {...search, keyword: v}})
        } else {
          const { search }= (parent.props.statisticStore.priorityPipeline!==undefined ? parent.props.statisticStore.priorityPipeline : {})
          parent.props.priorityPipeline({search: {...search, keyword: v}})
        }
      }
    }, 500)
  }

  onChangeSelect(e, v) {
    const filters= {};
    if (e=='recent') {
      switch (v) {
        case 'today':
          filters['ticketDate']= format(new Date(), 'yyyy-MM-dd 00:00:00');
        break;
        case '24H':
          filters['ticketDate']= format(subDays(new Date(), 1), 'yyyy-MM-dd HH:mm:ss');
        break;
        case '10D':
          filters['ticketDate']= format(startOfDay(subDays(new Date(), 10)), 'yyyy-MM-dd 00:00:00');
        break;
        case '30D':
          filters['ticketDate']= format(startOfDay(subDays(new Date(), 30)), 'yyyy-MM-dd 00:00:00');
        break;
        default:
      }
    } else {
      filters[e]= v; 
    }
    if (this.props.params && this.props.params.mode=='severity') {
      const { search }= (this.props.statisticStore.severityPipeline!==undefined ? this.props.statisticStore.severityPipeline : {})
      this.props.severityPipeline({search: {...search, ...filters, status: (v=='' ? null : v)}})
    } else if (this.props.params && this.props.params.mode=='sla') {
      const { search }= (this.props.statisticStore.slaPipeline!==undefined ? this.props.statisticStore.slaPipeline : {})
      this.props.slaPipeline({search: {...search, ...filters, status: (v=='' ? null : v)}})
    } else {
      const { search }= (this.props.statisticStore.priorityPipeline!==undefined ? this.props.statisticStore.priorityPipeline : {})
      this.props.priorityPipeline({search: {...search, ...filters, status: (v=='' ? null : v)}})
    }
  }
  
  render() {
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode }=this.props
    
    return (
      <div style={{position: 'static', width: '100%', zIndex: '1000'}}>
        <div style={{padding: '1em 1em', background: 'rgba(230, 230, 230, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
          {mode==1 && searchMode && 
          <div style={{margin: '0 0 0 0', border: '0px solid rgba(100,100,100,.2)'}}>
            <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Form.Group widths='16'>
                    <Field name='keyword' component={LabelInputField} onChange= {this.onChangeInput.bind(this)}
                    width={6} 
                    label='Keyword'
                    placeholder= 'Search ticket ... <ENTER>' />
                    <Field name='severity' component={LabelSelectField} onChange= {this.onChangeSelect.bind(this, 'severity')}
                    options={ticketOptions('severity')}
                    label='Severity'
                    width={4} 
                    placeholder= 'Status' />
                    <Field name='priority' component={LabelSelectField} onChange= {this.onChangeSelect.bind(this, 'priority')}
                    options={ticketOptions('priority')}
                    label='Priority'
                    width={4} 
                    placeholder= 'Priority' />
                    {/* <Field name='ticketDate' component={LabelDatePickerField} onChange= {this.onChangeDate.bind(this, 'ticketDate')}
                    width={4} 
                    label='Created'
                    type='date'
                    placeholder= 'Created Date' /> */}
                    <Field name='recent' component={LabelSelectField} onChange= {this.onChangeSelect.bind(this, 'recent')}
                    options={recentOptions()}
                    width={4} 
                    label='Recent'
                    placeholder= 'Recent Period' />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.statisticStore.priorityPipeline!=undefined && state.statisticStore.priorityPipeline.progress==1 ? state.statisticStore.priorityPipeline.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'HistoricalSearch',	// a unique identifier for this form
  })(HistoricalSearch)
  )
)