import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  Workgroup,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
} from 'semantic-ui-react'

import { connect } from "react-redux"
import { browseWorkgroup } from "../../actions/service/workgroupAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    workgroupStore: state.workgroupStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseWorkgroup:(objParam) => dispatch(browseWorkgroup(objParam)),
  }
};


class WorkgroupBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.workgroupStore.browseWorkgroup!==undefined ? this.props.workgroupStore.browseWorkgroup : {})
    this.props.browseWorkgroup({search: search, paging: paging})
  }

  componentDidUpdate(prevProps, prevStates) {
    const { search, paging }= (this.props.workgroupStore.browseWorkgroup!==undefined ? this.props.workgroupStore.browseWorkgroup : {})

    if (this.props.mode==1 && prevProps && prevProps.mode!=this.props.mode) {
      this.props.browseWorkgroup({search: {...search}, paging: paging})
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.workgroupStore.browseWorkgroup!==undefined ? this.props.workgroupStore.browseWorkgroup : {})
    this.props.browseWorkgroup(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const {data, searching, paging} = (this.props.workgroupStore.browseWorkgroup!==undefined ? this.props.workgroupStore.browseWorkgroup : {})
    let seq= 0
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1'}}>
          <Segment>
            <Card.Group itemsPerRow={4} stackable doubling>

              {data!=undefined ? data.map(item=>{
              return(
              <Card key={seq++} as='a' onClick={this.props.setMode.bind(this,2, item.workgroupId)}>
                <Card.Content style={{textAlign: 'center'}}>
                  <Header as='h4' icon color={(item.isEnabled=='0' && 'brown') || 'black'}>
                    <Icon name='id badge outline' style={{fontSize: '2em', margin: '.5em 0'}} color='grey'/>
                    {item.name}
                    <Header.Subheader>{item.client || 'Nobody'}</Header.Subheader>
                  </Header>
                </Card.Content>
                <Card.Content style={{textAlign: 'center'}}>
                  <Header as='h4'>
                    <Label color='red'>{item.tagger || 'No tagger'}</Label>
                  </Header>
                  {item.leaders}
                </Card.Content>
                <Card.Content extra style={{textAlign: 'center', padding: '1em'}}>
                  <Header as='h3'>
                    {item.agents.length}
                    <Header.Subheader><Label style={{padding: '.3em .5em'}}>Allocated Agent</Label></Header.Subheader>
                  </Header>
                </Card.Content>
              </Card>
              )}) : <div style={{padding: '1em'}}><Icon name='circle notch' loading /></div>
              }
            </Card.Group>
          </Segment>
        </div>

        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkgroupBrowse)
