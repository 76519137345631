import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import TicketHeader from '../../libraries/service/TicketHeader'
import ActionBrowse from '../../libraries/service/ActionBrowse'
import ActionSearch from '../../libraries/service/ActionSearch'
import ActionForm from '../../libraries/service/ActionForm'

class ActionBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        handlingId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, handlingId: id, clientId: clientId})
    }

    render() {
      const {mode, handlingId}= this.state
      const {screenWidth, screenHeight, params}= this.props
      
      return(
        <Container fluid>
          <div style={{padding: '0 .5em'}}>
            <TicketHeader modal setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} ticketId={this.props.ticketId} params={params}/>
            <ActionSearch modal mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} params={params} />
            
            {mode==1 && <ActionBrowse modal setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} ticketId={this.props.ticketId} clientId={this.props.clientId} params={params} />}
            {mode==2 && <ActionForm modal setMode={this.setMode.bind(this)} handlingId={handlingId} ticketId={this.props.ticketId} clientId={this.props.clientId} params={params} />}
            {mode==3 && <ActionForm modal setMode={this.setMode.bind(this)} handlingId={0} ticketId={this.props.ticketId} clientId={this.props.clientId} params={params} />}
          </div>
        </Container>
      )
    }
}

export default ActionBody
