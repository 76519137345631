import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse, addSeconds } from "date-fns"
import {  } from "../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { browseAutomation, statusAutomation } from "../../actions/service/automationAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    automationStore: state.automationStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseAutomation:(objParam) => dispatch(browseAutomation(objParam)),
    statusAutomation:(objParam) => dispatch(statusAutomation(objParam)),
  }
}

class AutomationBrowse extends Component {
  constructor(props) {
    super(props)
    this.state= {
      expand: false,
    }
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.automationStore.browseAutomation!==undefined ? this.props.automationStore.browseAutomation : {})
    this.props.browseAutomation({search: {...search}, paging: paging})
  }

  componentDidUpdate(prevProps, prevStates) {
    const { search, paging }= (this.props.automationStore.browseAutomation!==undefined ? this.props.automationStore.browseAutomation : {})

    if (this.props.mode==1 && prevProps && prevProps.mode!=this.props.mode) {
      this.props.browseAutomation({search: {...search}, paging: paging})
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.automationStore.browseAutomation!==undefined ? this.props.automationStore.browseAutomation : {})
    this.props.browseAutomation(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }

  onChangeAutomation(id, automation, e) {
    // console.log(id, automation, e)
    this.props.statusAutomation({automationId:id, isEnabled: automation})
  }

  render() {
    //console.log(this.props.automationStore.browseAutomation)
    const { expand }= this.state
    const { screenWidth, screenHeight, style }= this.props
    const { data, search, paging }= (this.props.automationStore.browseAutomation!==undefined ? this.props.automationStore.browseAutomation : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight, ...style}}>
        <div style={{flex: '1', padding: '0'}}>
          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell width={5}>
                  <Header as='h5' color='blue'>AUTOMATION</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={6} colSpan={2}>
                  <Header as='h5' color='blue'>CONDITIONS</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={5}>
                  <Header as='h5' color='blue'>ACTIONS TO DO</Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      <Table.Cell textAlign='center'>
                        <Popup position= 'top left' inverted content='Edit'
                          trigger= {<Icon style={{margin: '0', cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2', item.automationId, item)}/>}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <b>
                          {item.name} 
                          {/* / {item.isTrigger=='1' ? 'Trigger' : 'Automation'} */}
                        </b>
                      </Table.Cell>
                      <Table.Cell>
                        <Label color='yellow' size='medium'>{(item.andConditions && item.andConditions.length) || '0'}</Label> AND Conditions
                      </Table.Cell>
                      <Table.Cell>
                        <Label color='teal' size='medium'>{(item.orConditions && item.orConditions.length) || '0'}</Label> OR Conditions, 
                      </Table.Cell>
                      <Table.Cell>
                        <Label color='red' size='medium'>{(item.actions && item.actions.length) || '0'}</Label> Actions / Automations
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={5}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutomationBrowse)
