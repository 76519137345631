import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetPipeline(objParam) {
  return {
      type: 'PIPELINE_RESET',
      payload: objParam
  }
}

export function priorityPipeline(objParam) {
  return {
      type: 'PIPELINE_PRIORITY',
      payload:
        axios.post(
          urlAPI + 'service/pipeline/priority',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function severityPipeline(objParam) {
  return {
      type: 'PIPELINE_SEVERITY',
      payload:
        axios.post(
          urlAPI + 'service/pipeline/severity',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function slaPipeline(objParam) {
  return {
      type: 'PIPELINE_SLA',
      payload:
        axios.post(
          urlAPI + 'service/pipeline/sla',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function priorityStatistic(objParam) {
  return {
      type: 'STATISTIC_PRIORITY',
      payload:
        axios.post(
          urlAPI + 'service/statistic/priority',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function severityStatistic(objParam) {
  return {
      type: 'STATISTIC_SEVERITY',
      payload:
        axios.post(
          urlAPI + 'service/statistic/severity',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function slaStatistic(objParam) {
  return {
      type: 'STATISTIC_SLA',
      payload:
        axios.post(
          urlAPI + 'service/statistic/sla',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function remarkStatistic(objParam) {
  return {
      type: 'STATISTIC_REMARK',
      payload:
        axios.post(
          urlAPI + 'service/statistic/remark',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function clusterStatistic(objParam) {
  return {
      type: 'STATISTIC_CLUSTER',
      payload:
        axios.post(
          urlAPI + 'service/statistic/cluster',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function categoryStatistic(objParam) {
  return {
      type: 'STATISTIC_CATEGORY',
      payload:
        axios.post(
          urlAPI + 'service/statistic/category',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function serviceStatistic(objParam) {
  return {
      type: 'STATISTIC_SERVICE',
      payload:
        axios.post(
          urlAPI + 'service/statistic/service',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function classificationStatistic(objParam) {
  return {
      type: 'STATISTIC_CLASSIFICATION',
      payload:
        axios.post(
          urlAPI + 'service/statistic/classification',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function resolutionStatistic(objParam) {
  return {
      type: 'STATISTIC_RESOLUTION',
      payload:
        axios.post(
          urlAPI + 'service/statistic/resolution',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function reasonStatistic(objParam) {
  return {
      type: 'STATISTIC_REASON',
      payload:
        axios.post(
          urlAPI + 'service/statistic/reason',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function interactionStatistic(objParam) {
  return {
      type: 'STATISTIC_INTERACTION',
      payload:
        axios.post(
          urlAPI + 'service/statistic/interaction',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function channelStatistic(objParam) {
  return {
      type: 'STATISTIC_CHANNEL',
      payload:
        axios.post(
          urlAPI + 'service/statistic/channel',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function satisfactionStatistic(objParam) {
  return {
      type: 'STATISTIC_SATISFACTION',
      payload:
        axios.post(
          urlAPI + 'service/statistic/satisfaction',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function workloadStatistic(objParam) {
  return {
      type: 'STATISTIC_WORKLOAD',
      payload:
        axios.post(
          urlAPI + 'service/statistic/workload',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function performanceStatistic(objParam) {
  return {
      type: 'STATISTIC_PERFORMANCE',
      payload:
        axios.post(
          urlAPI + 'service/statistic/performance',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function workspaceStatistic(objParam) {
  return {
      type: 'STATISTIC_WORKSPACE',
      payload:
        axios.post(
          urlAPI + 'service/statistic/workspace',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function livefeedStatistic(objParam) {
  return {
      type: 'STATISTIC_LIVEFEED',
      payload:
        axios.post(
          urlAPI + 'service/statistic/livefeed',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
