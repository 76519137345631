export default function workgroupReducer (state = {}, action) {
  switch (action.type) {
    case 'WORKGROUP_RESET': {
        return({
            ...state,
            getWorkgroup: {},
            updateWorkgroup: {},
            createWorkgroup: {},
        })
    }

    case 'WORKGROUP_BROWSE_PENDING': {
      return({...state, browseWorkgroup: {progress: 0}})
    }
    case 'WORKGROUP_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseWorkgroup: {...api.browseWorkgroup.response, progress: 1, payload: action.payload}})
            return({...state, browseWorkgroup: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseWorkgroup: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WORKGROUP_BROWSE_REJECTED': {
        return({...state, browseWorkgroup: {progress: 2, payload: action.payload}})
    }
    
    case 'WORKGROUP_GET_PENDING': {
        return({...state, getWorkgroup: {progress: 0}})
    }
    case 'WORKGROUP_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getWorkgroup: {...api.getWorkgroup.response, progress: 1, payload: action.payload}})
            return({...state, getWorkgroup: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getWorkgroup: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WORKGROUP_GET_REJECTED': {
        return({...state, getWorkgroup: {progress: 2, payload: action.payload}})
    }

    case 'WORKGROUP_STATUS_PENDING': {
      return({...state, statusWorkgroup: {progress: 0}})
    }
    case 'WORKGROUP_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusWorkgroup: {...api.statusWorkgroup.response, progress: 1, payload: action.payload}})
            return({...state, statusWorkgroup: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusWorkgroup: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WORKGROUP_STATUS_REJECTED': {
        return({...state, statusWorkgroup: {progress: 2, payload: action.payload}})
    }

    case 'WORKGROUP_CREATE_PENDING': {
      return({...state, createWorkgroup: {progress: 0}})
    }
    case 'WORKGROUP_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createWorkgroup: {...api.createWorkgroup.response, progress: 1, payload: action.payload}})
            return({...state, createWorkgroup: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createWorkgroup: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WORKGROUP_CREATE_REJECTED': {
        return({...state, createWorkgroup: {progress: 2, payload: action.payload}})
    }

    case 'WORKGROUP_UPDATE_PENDING': {
      return({...state, updateWorkgroup: {progress: 0}})
    }
    case 'WORKGROUP_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateWorkgroup: {...api.updateWorkgroup.response, progress: 1, payload: action.payload}})
            return({...state, updateWorkgroup: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateWorkgroup: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WORKGROUP_UPDATE_REJECTED': {
        return({...state, updateWorkgroup: {progress: 2, payload: action.payload}})
    }

    case 'WORKGROUP_REMOVE_PENDING': {
      return({...state, removeWorkgroup: {progress: 0}})
    }
    case 'WORKGROUP_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeWorkgroup: {...api.removeWorkgroup.response, progress: 1, payload: action.payload}})
            return({...state, removeWorkgroup: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeWorkgroup: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WORKGROUP_REMOVE_REJECTED': {
        return({...state, removeWorkgroup: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}