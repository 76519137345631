import React from 'react'

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';
import Service from "./Service"

class ServiceContainer extends React.Component {
  render() {
    return(
      <WebSocketProvider>
        <Service />
      </WebSocketProvider>
    )
  }
} 
export default ServiceContainer
