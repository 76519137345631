export default function reportingReducer (state = {}, action) {
  switch (action.type) {
    case 'REPORTING_RESET': {
        return({
            ...state,
            getReporting: {},
            updateReporting: {},
            createReporting: {},
        })
    }

    case 'REPORTING_BROWSE_PENDING': {
      return({...state, browseReporting: {progress: 0}})
    }
    case 'REPORTING_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseReporting: {...api.browseReporting.response, progress: 1, payload: action.payload}})
            return({...state, browseReporting: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseReporting: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REPORTING_BROWSE_REJECTED': {
        return({...state, browseReporting: {progress: 2, payload: action.payload}})
    }
    
    case 'REPORTING_GET_PENDING': {
        return({...state, getReporting: {progress: 0}})
    }
    case 'REPORTING_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getReporting: {...api.getReporting.response, progress: 1, payload: action.payload}})
            return({...state, getReporting: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getReporting: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REPORTING_GET_REJECTED': {
        return({...state, getReporting: {progress: 2, payload: action.payload}})
    }

    case 'REPORTING_STATUS_PENDING': {
      return({...state, statusReporting: {progress: 0}})
    }
    case 'REPORTING_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusReporting: {...api.statusReporting.response, progress: 1, payload: action.payload}})
            return({...state, statusReporting: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusReporting: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REPORTING_STATUS_REJECTED': {
        return({...state, statusReporting: {progress: 2, payload: action.payload}})
    }

    case 'REPORTING_CREATE_PENDING': {
      return({...state, createReporting: {progress: 0}})
    }
    case 'REPORTING_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createReporting: {...api.createReporting.response, progress: 1, payload: action.payload}})
            return({...state, createReporting: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createReporting: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REPORTING_CREATE_REJECTED': {
        return({...state, createReporting: {progress: 2, payload: action.payload}})
    }

    case 'REPORTING_UPDATE_PENDING': {
      return({...state, updateReporting: {progress: 0}})
    }
    case 'REPORTING_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateReporting: {...api.updateReporting.response, progress: 1, payload: action.payload}})
            return({...state, updateReporting: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateReporting: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REPORTING_UPDATE_REJECTED': {
        return({...state, updateReporting: {progress: 2, payload: action.payload}})
    }

    case 'REPORTING_REMOVE_PENDING': {
      return({...state, removeReporting: {progress: 0}})
    }
    case 'REPORTING_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeReporting: {...api.removeReporting.response, progress: 1, payload: action.payload}})
            return({...state, removeReporting: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeReporting: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REPORTING_REMOVE_REJECTED': {
        return({...state, removeReporting: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}