import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import SegmentBrowse from '../../libraries/service/SegmentBrowse'
import SegmentForm from '../../libraries/service/SegmentForm'
import SegmentSearch from '../../libraries/service/SegmentSearch'


class SegmentBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        segmentId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, segmentId: id, clientId: clientId})
    }

    closeModal() {
      this.setState({
        mode: 1,
      })
      this.props.closeModal();
    }

    render() {
      const {mode, segmentId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div>
          <SegmentSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />

          {<SegmentBrowse setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
          {mode==2 && <ModalContainer size='large' content={<SegmentForm mode={mode} segmentId={segmentId} clientId={clientId} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
          {mode==3 && <ModalContainer size='large' content={<SegmentForm mode={mode} segmentId={0} clientId={0} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
        </div>
      )
    }
}

export default SegmentBody
