import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider } from 'semantic-ui-react'
import ModalContainer from '../../libraries/common/ModalContainer';

import DashboardSearch from '../../libraries/service/DashboardSearch'
import MonitoringBrowse from '../../libraries/service/MonitoringBrowse'
import ComplianceWidget from '../../libraries/widget/service/ComplianceWidget'
import OccupancyWidget from '../../libraries/widget/service/OccupancyWidget'
import TicketHistorical from '../../libraries/widget/service/TicketHistorical'
import SatisfactionWidget from '../../libraries/widget/service/SatisfactionWidget'
import SegmentationWidget from '../../libraries/widget/service/SegmentationWidget'
import StatisticWidget from '../../libraries/widget/service/StatisticWidget'

class DashboardVendorBody extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      userId: 0,
    };
  }

  setMode(mode) {
    this.setState({mode: mode})
  }

  closeModal(params) {
    this.setState({mode: ''})
  }

  render() {
    const {mode}= this.state
    const {screenWidth, screenHeight}= this.props
    
    return(
      <Container fluid>
        <div style={{display: 'flex'}}>
          <div style={{flex: '1', padding: '1em 1em 1em 1em'}}>
            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
              <div style={{flex: '1'}}>
                  <DashboardSearch vendor setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>

        <div style={{padding: '1em'}}>
          VENDOR PERFORMANCE | VENDOR PRODUCT RELATED PERFORMANCE
        </div>

        {/* {(mode==='open' || mode==='progress' || mode==='closed') && <ModalContainer size='small' content={<MonitoringBrowse screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> } */}

      </Container>
    )
  }
}

export default DashboardVendorBody
