import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse, addSeconds } from "date-fns"
import {  } from "../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { browseReporting, statusReporting } from "../../actions/service/reportingAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    reportingStore: state.reportingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseReporting:(objParam) => dispatch(browseReporting(objParam)),
    statusReporting:(objParam) => dispatch(statusReporting(objParam)),
  }
}

class ReportingBrowse extends Component {
  constructor(props) {
    super(props)
    this.state= {
      expand: false,
    }
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.reportingStore.browseReporting!==undefined ? this.props.reportingStore.browseReporting : {})
    this.props.browseReporting({search: {...search}, paging: paging})
  }

  componentDidUpdate(prevProps, prevStates) {
    const { search, paging }= (this.props.reportingStore.browseReporting!==undefined ? this.props.reportingStore.browseReporting : {})

    if (this.props.mode==1 && prevProps && prevProps.mode!=this.props.mode) {
      this.props.browseReporting({search: {...search}, paging: paging})
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.reportingStore.browseReporting!==undefined ? this.props.reportingStore.browseReporting : {})
    this.props.browseReporting(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }

  onChangeReporting(id, reporting, e) {
    // console.log(id, reporting, e)
    this.props.statusReporting({reportId:id, isEnabled: reporting})
  }

  render() {
    //console.log(this.props.reportingStore.browseReporting)
    const { expand }= this.state
    const { screenWidth, screenHeight, style }= this.props
    const { data, search, paging }= (this.props.reportingStore.browseReporting!==undefined ? this.props.reportingStore.browseReporting : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight, ...style}}>
        <div style={{flex: '1', padding: '0'}}>
          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell width={4}>
                  <Header as='h5' color='blue'>DASHBOARD SCHEME</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={5}>
                  <Header as='h5' color='blue'>ACCESS PRIVILEGE</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={7}>
                  <Header as='h5' color='blue'>LAYOUT PREFERENCES</Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      <Table.Cell textAlign='center'>
                        <Popup position= 'top left' inverted content='Edit'
                          trigger= {<Icon style={{margin: '0', cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2', item.reportId, item)}/>}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Popup position='top left' content={item.descript} inverted trigger={
                          <b>
                            {item.name}
                          </b>
                        } />
                      </Table.Cell>
                      <Table.Cell>
                          {(item.groups||[]).join(', ')}
                      </Table.Cell>
                      <Table.Cell>
                        {item.rowType=='sheet' ? <><Label color='orange' size='medium'>{(item.presentations && item.presentations.columns && item.presentations.columns.length) || '0'}</Label> Sources</> : <><Label color='orange' size='medium'>{(item.presentations && item.presentations.metrics && item.presentations.metrics.length) || '0'}</Label> Metrics, <Label color='teal' size='medium'>{(item.presentations && item.presentations.groups && item.presentations.groups.length) || '0'}</Label> Groups, <Label color='blue' size='medium'>{(item.presentations && item.presentations.drills && item.presentations.drills.length) || '0'}</Label> Drills</> }
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={5}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportingBrowse)
