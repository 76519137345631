import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetStatus(objParam) {
  return {
      type: 'STATUS_RESET',
      payload: objParam
  }
}

export function getStatus(objParam) {
  return {
      type: 'STATUS_GET',
      payload:
        axios.post(
          urlAPI + 'service/status/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusStatus(objParam) {
  return {
      type: 'STATUS_STATUS',
      payload:
        axios.post(
          urlAPI + 'service/status/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseStatus(objParam) {
  return {
      type: 'STATUS_BROWSE',
      payload:
        axios.post(
          urlAPI + 'service/status/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createStatus(objParam) {
  return {
      type: 'STATUS_CREATE',
      payload:
        axios.post(
          urlAPI + 'service/status/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateStatus(objParam) {
  return {
      type: 'STATUS_UPDATE',
      payload:
        axios.post(
          urlAPI + 'service/status/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeStatus(objParam) {
  return {
      type: 'STATUS_REMOVE',
      payload:
        axios.post(
          urlAPI + 'service/status/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
