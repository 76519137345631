import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetReporting(objParam) {
  return {
      type: 'REPORTING_RESET',
      payload: objParam
  }
}

export function getReporting(objParam) {
  return {
      type: 'REPORTING_GET',
      payload:
        axios.post(
          urlAPI + 'service/reporting/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusReporting(objParam) {
  return {
      type: 'REPORTING_STATUS',
      payload:
        axios.post(
          urlAPI + 'service/reporting/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseReporting(objParam) {
  return {
      type: 'REPORTING_BROWSE',
      payload:
        axios.post(
          urlAPI + 'service/reporting/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createReporting(objParam) {
  return {
      type: 'REPORTING_CREATE',
      payload:
        axios.post(
          urlAPI + 'service/reporting/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateReporting(objParam) {
  return {
      type: 'REPORTING_UPDATE',
      payload:
        axios.post(
          urlAPI + 'service/reporting/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeReporting(objParam) {
  return {
      type: 'REPORTING_REMOVE',
      payload:
        axios.post(
          urlAPI + 'service/reporting/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
