export default function peopleReducer (state = {}, action) {
  switch (action.type) {
    case 'PEOPLE_RESET': {
        return({
            ...state,
            getPeople: {},
            updatePeople: {},
            createPeople: {},
        })
    }

    case 'PEOPLE_BROWSE_PENDING': {
      return({...state, browsePeople: {progress: 0}})
    }
    case 'PEOPLE_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browsePeople: {...api.browsePeople.response, progress: 1, payload: action.payload}})
            return({...state, browsePeople: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browsePeople: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PEOPLE_BROWSE_REJECTED': {
        return({...state, browsePeople: {progress: 2, payload: action.payload}})
    }
    
    case 'PEOPLE_GET_PENDING': {
        return({...state, getPeople: {progress: 0}})
    }
    case 'PEOPLE_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getPeople: {...api.getPeople.response, progress: 1, payload: action.payload}})
            return({...state, getPeople: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getPeople: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PEOPLE_GET_REJECTED': {
        return({...state, getPeople: {progress: 2, payload: action.payload}})
    }

    case 'PEOPLE_STATUS_PENDING': {
      return({...state, statusPeople: {progress: 0}})
    }
    case 'PEOPLE_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusPeople: {...api.statusPeople.response, progress: 1, payload: action.payload}})
            return({...state, statusPeople: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusPeople: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PEOPLE_STATUS_REJECTED': {
        return({...state, statusPeople: {progress: 2, payload: action.payload}})
    }

    case 'PEOPLE_CREATE_PENDING': {
      return({...state, createPeople: {progress: 0}})
    }
    case 'PEOPLE_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createPeople: {...api.createPeople.response, progress: 1, payload: action.payload}})
            return({...state, createPeople: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createPeople: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PEOPLE_CREATE_REJECTED': {
        return({...state, createPeople: {progress: 2, payload: action.payload}})
    }

    case 'PEOPLE_UPDATE_PENDING': {
      return({...state, updatePeople: {progress: 0}})
    }
    case 'PEOPLE_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updatePeople: {...api.updatePeople.response, progress: 1, payload: action.payload}})
            return({...state, updatePeople: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updatePeople: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PEOPLE_UPDATE_REJECTED': {
        return({...state, updatePeople: {progress: 2, payload: action.payload}})
    }

    case 'PEOPLE_REMOVE_PENDING': {
      return({...state, removePeople: {progress: 0}})
    }
    case 'PEOPLE_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removePeople: {...api.removePeople.response, progress: 1, payload: action.payload}})
            return({...state, removePeople: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removePeople: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PEOPLE_REMOVE_REJECTED': {
        return({...state, removePeople: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}