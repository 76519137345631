import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import WidgetBrowse from '../../libraries/service/WidgetBrowse'
import WidgetForm from '../../libraries/service/WidgetForm'
import WidgetSearch from '../../libraries/service/WidgetSearch'



class WidgetBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        widgetId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, widgetId: id, clientId: clientId})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        widgetId: 0,
      });
    }

    render() {
      const {mode, widgetId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div>
          <WidgetSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
        
          {<WidgetBrowse mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} openModal={this.props.openModal} closeModal={this.closeModal.bind(this)} />}
          {/* {mode==2 && <WidgetForm mode={mode} widgetId={widgetId} clientId={clientId} setMode={this.setMode.bind(this)} />}
          {mode==3 && <WidgetForm mode={mode} widgetId={0} clientId={0} setMode={this.setMode.bind(this)} />} */}
          {mode==2 && <ModalContainer headless size='large' content={<WidgetForm mode={mode} widgetId={widgetId} clientId={clientId} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
          {mode==3 && <ModalContainer headless size='large' content={<WidgetForm mode={mode} widgetId={0} clientId={0} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
        </div>
      )
    }
}

export default WidgetBody
