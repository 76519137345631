import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider } from 'semantic-ui-react'

import MessageBrowse from '../../libraries/halo/MessageBrowse'
import MessageForm from '../../libraries/halo/MessageForm'


class MessageBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        messageId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, messageId: id})
    }

    render() {
      const {mode, messageId}= this.state
      const {screenWidth}= this.props
      
      return(
        <Container fluid>
          <div>
            <div style={{padding: '1em 1em', border: '0px solid rgba(100,100,100,.2)'}}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                <Header as='h4' style={{marginTop: '.25em'}}>
                  <Icon name='bell' style={{fontSize: '1em'}}/>
                  <Header.Content>
                    Messaging / Notification
                  </Header.Content>
                </Header>
                </div>
              </div>
            </div>
            
            <div style={{padding: '1em 1em', display: 'flex'}}>
              <div style={{width: '350px', paddingRight: '2em'}}>
                <MessageForm mode={mode} notificationId={0} onBack={this.setMode.bind(this)} />
              </div>
              <div style={{flex: '1'}}>
                <MessageBrowse />
              </div>
            </div>
          </div>
        </Container>
      )
    }
}

export default MessageBody