export default function analyticReducer (state = {}, action) {
  switch (action.type) {
    case 'ANALYTIC_RESET': {
        return({
            ...state,
            getAnalytic: {},
            updateAnalytic: {},
            createAnalytic: {},
        })
    }

    case 'ANALYTIC_BROWSE_PENDING': {
      return({...state, browseAnalytic: {progress: 0}})
    }
    case 'ANALYTIC_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseAnalytic: {...api.browseAnalytic.response, progress: 1, payload: action.payload}})
            return({...state, browseAnalytic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseAnalytic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ANALYTIC_BROWSE_REJECTED': {
        return({...state, browseAnalytic: {progress: 2, payload: action.payload}})
    }
    
    case 'ANALYTIC_GET_PENDING': {
        return({...state, getAnalytic: {progress: 0}})
    }
    case 'ANALYTIC_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getAnalytic: {...api.getAnalytic.response, progress: 1, payload: action.payload}})
            return({...state, getAnalytic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getAnalytic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ANALYTIC_GET_REJECTED': {
        return({...state, getAnalytic: {progress: 2, payload: action.payload}})
    }

    case 'ANALYTIC_STATUS_PENDING': {
      return({...state, statusAnalytic: {progress: 0}})
    }
    case 'ANALYTIC_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusAnalytic: {...api.statusAnalytic.response, progress: 1, payload: action.payload}})
            return({...state, statusAnalytic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusAnalytic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ANALYTIC_STATUS_REJECTED': {
        return({...state, statusAnalytic: {progress: 2, payload: action.payload}})
    }

    case 'ANALYTIC_CREATE_PENDING': {
      return({...state, createAnalytic: {progress: 0}})
    }
    case 'ANALYTIC_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createAnalytic: {...api.createAnalytic.response, progress: 1, payload: action.payload}})
            return({...state, createAnalytic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createAnalytic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ANALYTIC_CREATE_REJECTED': {
        return({...state, createAnalytic: {progress: 2, payload: action.payload}})
    }

    case 'ANALYTIC_UPDATE_PENDING': {
      return({...state, updateAnalytic: {progress: 0}})
    }
    case 'ANALYTIC_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateAnalytic: {...api.updateAnalytic.response, progress: 1, payload: action.payload}})
            return({...state, updateAnalytic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateAnalytic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ANALYTIC_UPDATE_REJECTED': {
        return({...state, updateAnalytic: {progress: 2, payload: action.payload}})
    }

    case 'ANALYTIC_REMOVE_PENDING': {
      return({...state, removeAnalytic: {progress: 0}})
    }
    case 'ANALYTIC_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeAnalytic: {...api.removeAnalytic.response, progress: 1, payload: action.payload}})
            return({...state, removeAnalytic: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeAnalytic: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ANALYTIC_REMOVE_REJECTED': {
        return({...state, removeAnalytic: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}