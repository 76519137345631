import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import RptCsatSearch from '../../libraries/service/RptCsatSearch'
import RptCsatSatisfaction from '../../libraries/service/RptCsatSatisfaction'
import RptCsatFeedback from '../../libraries/service/RptCsatFeedback';

class RptCsatBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        peopleId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, peopleId: id, clientId: clientId})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        peopleId: 0,
      });
    }

    render() {
      const {mode, peopleId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <RptCsatSearch setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} mode={mode} menu={this.props.menu} />
          
          {this.props.menu==='satisfaction' && <RptCsatSatisfaction setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
          {this.props.menu==='feedbacks' && <RptCsatFeedback setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
        </Container>
      )
    }
}

export default RptCsatBody
