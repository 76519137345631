import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Table } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'
import MenuTabular from '../common/MenuTabular'
import { ticketOptions, progressOptions, durationOptions, resultOptions, priorityOptions, actionOptions, yesnoOptions, ratingOptions }  from '../common/StaticMasterData'

import numeral from "numeral"
import { format, parse, addSeconds } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetHandling, getHandling,updateHandling, createHandling } from "../../actions/service/handlingAction"
import { listWorkgroup, listSegment, listParams } from "../../actions/halo/masterAction"
import { handlingTicket } from "../../actions/service/ticketAction"

//CONNECT REDUX STORE
const selector = formValueSelector('ActionForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    ticketStore: state.ticketStore,

    handlingStore: state.handlingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listSegment:(objParam) => dispatch(listSegment(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    listParams:(objParam) => dispatch(listParams(objParam)),

    handlingTicket:(objParam) => dispatch(handlingTicket(objParam)),

    resetHandling:(objParam) => dispatch(resetHandling(objParam)),
    getHandling:(objParam) => dispatch(getHandling(objParam)),
    createHandling:(objParam) => dispatch(createHandling(objParam)),
    updateHandling:(objParam) => dispatch(updateHandling(objParam)),
  }
}

const panes = (param)=>{
  return([
    {
      menuItem: { key: '0', icon: 'ticket', content: 'Ticket' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '1', icon: 'wizard', content: 'Actions' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '2', icon: 'building outline', content: 'Customer' },
      render: () => 
      <></>
    },
  ])
};

class ActionForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remarkResult: null,

      tab: 0,
    }
  }

  componentDidMount(){
    this.props.listParams({clientId: this.props.clientId!=undefined ? this.props.clientId : null})

    this.props.resetHandling(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getHandling({handlingId: this.props.handlingId})
    }

    // this.props.listSegment({search: {clientId: this.props.clientId}})
    this.props.listWorkgroup({search: {clientId: this.props.clientId}})
  }

  componentDidUpdate(prevProps) {
    const { mode } = this.props
    const { header }= (this.props.ticketStore.handlingTicket!==undefined ? this.props.ticketStore.handlingTicket : {})
    const { progress, code, text }= (mode==2 ? (this.props.handlingStore.updateHandling!=undefined ? this.props.handlingStore.updateHandling : {}) : (this.props.handlingStore.createHandling!=undefined ? this.props.handlingStore.createHandling : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }

    if (header && (!this.props.ticketDuration || !this.props.openStamp || !this.props.closeStamp)) {
      !this.props.ticketDuration && this.props.dispatch(change('ActionForm', 'ticketDuration', header.ticketDuration));
      !this.props.openStamp && this.props.dispatch(change('ActionForm', 'openStamp', header.openStamp));
      !this.props.closeStamp && this.props.dispatch(change('ActionForm', 'closeStamp', format(new Date(), 'yyyy-MM-dd HH:mm:ss')));
    }
    
    if (this.props.handlingType) {
      this.props.segment && this.props.segment.segmentHandlings && this.props.segment.segmentHandlings.map((item, idx)=>{
        if (this.props.handlingType==item.key) {
          this.props.dispatch(change('ActionForm', 'handlingParams', item.params));
        }
      })
    } else {
      this.props.dispatch(change('ActionForm', 'handlingParams', []));
    }

    // if (this.props.segment) {
      if (this.props.remark=='Dispatch' && (!this.props.dispatches || this.props.dispatches.length==0)) {
        this.props.dispatch(change('ActionForm', 'dispatches', this.props.segment.dispatches));
      }
      if (this.props.remark=='Disposition' && (!this.props.dispositions || this.props.dispositions.length==0)) {
        this.props.dispatch(change('ActionForm', 'dispositions', this.props.segment.dispositions));
      }
      if (this.props.remark=='Escalation' && (!this.props.escalations || this.props.escalations.length==0)) {
        this.props.dispatch(change('ActionForm', 'escalations', this.props.segment.escalations));
      }
    // }

    //UPDATE DURATION
    if (this.props.actionDuration) {
      if (!this.props.actionStamp) {
        this.props.dispatch(change('ActionForm', 'actionStamp', format(new Date(), 'yyyy-MM-dd HH:mm:ss')));
        this.props.dispatch(change('ActionForm', 'completeStamp', format(addSeconds(new Date(), this.props.actionDuration * 3600), 'yyyy-MM-dd HH:mm:ss')));
      } else {
        this.props.dispatch(change('ActionForm', 'completeStamp', format(addSeconds(parse(this.props.actionStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), this.props.actionDuration * 3600), 'yyyy-MM-dd HH:mm:ss')));
      }
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { header }= (this.props.ticketStore.handlingTicket!==undefined ? this.props.ticketStore.handlingTicket : {})
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateHandling({...values}) : this.props.createHandling({...(!values.ticketId ? {ticketId: header.ticketId} : {}), ...values})
    
  }

  onChangeResult(v) {
    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})

    this.props.dispatch(change('ActionForm', 'progress', listRemark.progress[v]));
  }

  setMode(e, d) {
    // console.log(d.activeIndex)
    this.setState({tab: d.activeIndex})
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.handlingStore.getHandling!=undefined && this.props.handlingStore.getHandling.progress=='1' ? this.props.handlingStore.getHandling : {})
    const { header }= (this.props.ticketStore.handlingTicket!==undefined ? this.props.ticketStore.handlingTicket : {})
    const { progress, code, text }= (mode==2 ? (this.props.handlingStore.updateHandling!=undefined ? this.props.handlingStore.updateHandling : {}) : (this.props.handlingStore.createHandling!=undefined ? this.props.handlingStore.createHandling : {}))

    // const listSegment= (this.props.masterStore.listSegment!=undefined && this.props.masterStore.listSegment.progress=='1' ? this.props.masterStore.listSegment.data : [])
    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])

    const { svcCampaigns, svcRatios, svcChannels }= this.props.masterStore.listParams && this.props.masterStore.listParams.data ? this.props.masterStore.listParams.data : {}

    const channelOptions= svcChannels
    
    var handlingOptions= [], handlingParams= []
    // if (data && data.segment && data.segment.handlings) {
    //   data && data.segment && data.segment.handlings.map((item, i)=>{
    //     handlingOptions.push({
    //       key: i,
    //       value: item,
    //       text: item,
    //     })
    //   })

    //   this.props.handlingParams && this.props.handlingParams.map((item, idx)=>{
    //     handlingParams.push(item)
    //   })
    if (data && data.segment && data.segment.segmentHandlings) {
      data.segment.segmentHandlings.map((item, i)=>{
        handlingOptions.push({
          key: i,
          value: item.key,
          text: item.key,
          params: item.params,
        })
        if (item.key==this.props.handlingType) {
          item.params.map(item=>{
            handlingParams.push(item)
          })
        }
      })
    } else if (header && header.segmentHandlings) {
      header.segmentHandlings.map((item, i)=>{
        handlingOptions.push({
          key: i,
          value: item.key,
          text: item.key,
          params: item.params,
        })
        if (item.key==this.props.handlingType) {
          item.params.map(item=>{
            handlingParams.push(item)
          })
        }
      })
    }
    

    // // if (this.props.handlingParams) {
    //   this.props.handlingParams && this.props.handlingParams.map((item, idx)=>{
    //     handlingParams.push(item)
    //   })
    // // } else {
    //   // this.props.segment && this.props.segment.segmentHandlings && this.props.segment.segmentHandlings.map((item, idx)=>{
    //   //   if (this.props.handlingType==item.key) {
    //   //     handlingParams= item.params
    //   //   }
    //   // })
    // // }

    

    return (
      <div style={{padding: '1em 1em', ...style}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          {/* <Segment>
            <Form.Group widths='16'>
              <Form.Field width={8} />
              <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' />
            </Form.Group>
          </Segment> */}
          
          <Segment>
            <Form.Group widths='16'>
              <Field name='actionType' component={SelectField}
              width={4}
              options={actionOptions('ticket')}
              label='Action Type'
              placeholder='Action Type' />
              {(!this.props.actionType || this.props.actionType=='Handling') && <>
              <Field name='actionDuration' component={SelectField}
              options={durationOptions('ticket')}
              width={4}
              label='Duration'
              placeholder='Duration' />
              <Field name='actionStamp' component={DatePickerField}
              readOnly
              width={4}
              showTimeSelect={true}
              dateFormat={'HH:mm - dd/MMMM, yyyy'}
              label='Action Stamp'
              placeholder='Action Stamp' />
              <Field name='completeStamp' component={DatePickerField}
              readOnly
              width={4}
              showTimeSelect={true}
              dateFormat={'HH:mm - dd/MMMM, yyyy'}
              label='Complete Stamp'
              placeholder='Complete Stamp' />
              </>}

              {this.props.actionType=='Dispatch' &&
              <Field name='dispatches' component={SelectField}
              width={12}
              options={listWorkgroup}
              multiple
              label='Dispatches'
              placeholder='Dispatches' />}
              {this.props.actionType=='Dispose' &&
              <Field name='dispositions' component={SelectField}
              width={12}
              options={listWorkgroup}
              multiple
              label='Dispositions'
              placeholder='Dispositions' />}
              {this.props.actionType=='Escalate' &&
              <Field name='escalations' component={SelectField}
              width={12}
              options={listWorkgroup}
              multiple
              label='Escalations'
              placeholder='Escalations' />}
              {this.props.actionType=='Response' &&
              <Field name='actionChannels' component={SelectField}
              width={12}
              options={channelOptions}
              multiple
              label='Channels'
              placeholder='Channels' />}
            </Form.Group>
            {(this.props.actionType=='Dispatch' || this.props.actionType=='Dispose' || this.props.actionType=='Escalate' || this.props.actionType=='Response') &&
            <Form.Group widths='16'>
              <Field name='actionMessage' component={TextAreaField}
              width={16}
              label='Action Message'
              placeholder='Message / Information' />
            </Form.Group>}
          </Segment>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Handling' : 'Insert Handling') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.handlingStore.getHandling!=undefined && state.handlingStore.getHandling.progress==1 ? state.handlingStore.getHandling.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      ticketDuration: selector(state, 'ticketDuration'),
      openStamp: selector(state, 'openStamp'),
      closeStamp: selector(state, 'closeStamp'),
      
      actionType: selector(state, 'actionType'),

      handlingType: selector(state, 'handlingType'),
      handlingParams: selector(state, 'handlingParams'),
      
      actionDuration: selector(state, 'actionDuration'),
      actionStamp: selector(state, 'actionStamp'),
      completeStamp: selector(state, 'completeStamp'),

      remark: selector(state, 'remark'),

      segment: selector(state, 'segment'),
      segmentParams: selector(state, 'segmentParams'),

      dispatches: selector(state, 'dispatches'),
      dispositions: selector(state, 'dispositions'),
      escalations: selector(state, 'escalations'),
    }),
    { load: getHandling }
  )
  (reduxForm({
    form: 'ActionForm',	// a unique identifier for this form
  })(ActionForm)
  )
)
