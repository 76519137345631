import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse, addSeconds } from "date-fns"

import { personalOptions, ticketOptions }  from '../../modules/service/StaticMaster'

import {  } from "../../libraries/common/StaticMasterData"
import { listRemark } from "../../actions/service/masterAction"


import { connect } from "react-redux"
import { resetBucket, ticketBucket } from "../../actions/service/bucketAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    bucketStore: state.bucketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listRemark:(objParam) => dispatch(listRemark(objParam)),
    ticketBucket:(objParam) => dispatch(ticketBucket(objParam)),
    resetBucket:(objParam) => dispatch(resetBucket(objParam)),
  }
}

class MyBucketPipeline extends Component {
  constructor(props) {
    super(props)
    this.state= {
      expand: false,
    }
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.bucketStore.ticketBucket!==undefined ? this.props.bucketStore.ticketBucket : {});
    this.props.ticketBucket({search: {...search}, paging: paging, param: {scope: 'user'}});
    this.props.listRemark();
  }

  componentDidUpdate(prevProps) {
    const { search, paging }= (this.props.bucketStore.ticketBucket!==undefined ? this.props.bucketStore.ticketBucket : {})
    if (this.props.stamp!=prevProps.stamp) {
      this.props.ticketBucket({search: {...search}, paging: paging, param: this.props.filter})
    }
  }

  componentWillUnmount() {
    this.props.resetBucket();
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.bucketStore.ticketBucket!==undefined ? this.props.bucketStore.ticketBucket : {})
    this.props.ticketBucket(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  formatSeconds(seconds) {
    // console.log('!!! SECONDS', seconds)
    const date= addSeconds(new Date(0, 0, 0, 0, 0, 0), seconds);
    // console.log('!!! DATE', date)
    return (Math.floor(seconds/(24 * 60 * 60)) +'D / '+ format(date, 'HH:mm'));
  }

  render() {
    //console.log(this.props.bucketStore.ticketBucket)
    const { expand }= this.state
    const { screenWidth, screenHeight, style }= this.props
    const { data, search, paging }= (this.props.bucketStore.ticketBucket!==undefined ? this.props.bucketStore.ticketBucket : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : [])

    const priority= ticketOptions('priority', true);
    const severity= ticketOptions('severity', true);

    // console.log(bucket, filter, workspace)
    return(
      <div style={{display: 'flex', margin: '0 1em', padding: '1em 0'}}>
        {(listRemark||[]).map(item=>{
          return(
            <div className={'color '+ item.color} style={{flex: 1, minHeight: screenHeight, padding: '1em 1em 1em 1em'}}>
              <Header as='h5' style={{marginBottom: '1.5em'}}>
                <Header.Content>
                  <Label size='large' color={item.color} style={{margin: '0'}}>
                  <b>{item.text.toUpperCase()}</b>
                  </Label>
                  <Header.Subheader><b>{item.description}</b></Header.Subheader>
                </Header.Content>
              </Header>

              <Divider style={{marginBottom: '2em'}}/>
              <Card.Group itemsPerRow={1}>
                { data!==undefined ? data.map((ticket,key)=>{
                  if (ticket.remark==item.value) {
                    return(<Card as='a' style={{padding: '0'}} onClick={this.props.setMode.bind(this, '4', ticket.ticketId, ticket)}>
                      <Popup position= 'top left' inverted header={ticket.subject} content={<>
                        <Divider style={{margin: '.3em 0'}} />
                        {ticket.descript}
                        <Divider style={{margin: '.3em 0'}} />
                        <b>{ticket.code || '00000'}</b> / {((priority.get(ticket.priority)||{}).text) || 'Normal'}, {((severity.get(ticket.severity)||{}).text) || 'Low Impact'}
                        <Divider />
                        <Header as='h5' inverted style={{margin: '0'}}>
                          <Icon name={
                            (ticket.channelType=='Voice' && 'microphone') || (ticket.channelType=='IVR' && 'volume down') || (ticket.channelType=='SMS' && 'sticky note outline')
                            || (ticket.channelType=='Email' && 'envelope outline') || (ticket.channelType=='Whatsapp' && 'whatsapp') || (ticket.channelType=='Telegram' && 'telegram plane')
                            || (ticket.channelType=='Twitter' && 'twitter') || (ticket.channelType=='Facebook' && 'facebook') || (ticket.channelType=='Instagram' && 'instagram') || 'share alternate'
                            } />
                          <Header.Content>
                            <b>{ticket.name || 'No Name'}</b>
                            <Header.Subheader><b>{ticket.channelType || 'Walk In'} - {ticket.channelMethod || 'Input by Agent'}</b></Header.Subheader>
                          </Header.Content>
                        </Header>
                        </>}
                        trigger= {
                          <Card.Content style={{padding: '1em'}}>
                            <Card.Header style={{fontSize: '1em'}}>
                              {ticket.subject.substring(0, 20) + ' ...'}
                            </Card.Header>
                            <Divider style={{margin: '.3em 0'}} />
                            <Card.Description>
                              {ticket.descript.substring(0, 40) + ' ...'}
                            </Card.Description>
                            {/* <Divider style={{margin: '.3em 0'}} />
                            <Card.Meta>
                              <Header as='h5'>
                                <Icon name={
                                  (ticket.channelType=='Voice' && 'microphone') || (ticket.channelType=='IVR' && 'volume down') || (ticket.channelType=='SMS' && 'sticky note outline')
                                  || (ticket.channelType=='Email' && 'envelope outline') || (ticket.channelType=='Whatsapp' && 'whatsapp') || (ticket.channelType=='Telegram' && 'telegram plane')
                                  || (ticket.channelType=='Twitter' && 'twitter') || (ticket.channelType=='Facebook' && 'facebook') || (ticket.channelType=='Instagram' && 'instagram') || 'share alternate'
                                  } />
                                <Header.Content>
                                  <b>{ticket.name || 'No Name'}</b>
                                  <Header.Subheader><b>{ticket.channelType || 'Walk In'} - {ticket.channelMethod || 'Input by Agent'}</b></Header.Subheader>
                                </Header.Content>
                              </Header>
                            </Card.Meta> */}
                            {/* <Divider style={{margin: '.3em 0'}} />
                            <Card.Meta style={{fontSize: '.8em', fontWeight: 'bold'}}>
                              <b>{ticket.code || '00000'}</b> / {((priority.get(ticket.priority)||{}).text) || 'Normal'}, {((severity.get(ticket.severity)||{}).text) || 'Low Impact'}
                            </Card.Meta> */}
                          </Card.Content>
                      } />
                    </Card>)
                  }
                }) : <></>}
              </Card.Group>
            </div>
          )
        })}
      </div>
    )

    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyBucketPipeline)
