import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import AcknowledgeBrowse from '../../libraries/service/AcknowledgeBrowse'
import AcknowledgeForm from '../../libraries/service/AcknowledgeForm'
import AcknowledgeSearch from '../../libraries/service/AcknowledgeSearch'


class AcknowledgeBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        automationId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, automationId: id, clientId: clientId})
    }

    render() {
      const {mode, automationId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div>
          <AcknowledgeSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} openModal={this.props.openModal} closeModal={this.props.closeModal} />
          
          {mode==1 && <AcknowledgeBrowse setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} openModal={this.props.openModal} closeModal={this.props.closeModal} />}
          {mode==2 && <AcknowledgeForm mode={mode} automationId={automationId} clientId={clientId} setMode={this.setMode.bind(this)} />}
          {mode==3 && <AcknowledgeForm mode={mode} automationId={0} clientId={0} setMode={this.setMode.bind(this)} />}
        </div>
      )
    }
}

export default AcknowledgeBody
